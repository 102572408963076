import type { IPointOfInterest } from '../pointsOfInterest/actionTypes';
import type { IRegionOfInterest } from '../regionsOfInterest/actionTypes';
import type { IPayloadModeConfiguration, IPlatformModules } from './types';
import type { ISelectedPayloads } from '../moduleData/types';

export const UPDATE_MODULE_SELECTION = 'UPDATE_MODULE_SELECTION';
export const ADD_DEFAULT_SATELLITE_PAYLOAD_MODE =
  'ADD_DEFAULT_SATELLITE_PAYLOAD_MODE';
export const REMOVE_DEFAULT_SATELLITE_PAYLOAD_MODE =
  'REMOVE_DEFAULT_SATELLITE_PAYLOAD_MODE';
export const ADD_SATELLITE_OPERATIONAL_MODE = 'ADD_SATELLITE_OPERATIONAL_MODE';
export const UPDATE_SATELLITE_OPERATIONAL_MODE =
  'UPDATE_SATELLITE_OPERATIONAL_MODE';
export const REMOVE_SATELLITE_OPERATIONAL_MODE =
  'REMOVE_SATELLITE_OPERATIONAL_MODE';
export const UPDATE_SELECTED_SATELLITE_PAYLOAD =
  'UPDATE_SELECTED_SATELLITE_PAYLOAD';
export const UPDATE_SELECTED_SATELLITE_PLATFORM =
  'UPDATE_SELECTED_SATELLITE_PLATFORM';
export const UPLOAD_SATELLITE_CONFIGURATION = 'UPLOAD_SATELLITE_CONFIGURATION';

export interface IUpdateModuleSelection {
  type: typeof UPDATE_MODULE_SELECTION;
  moduleType: string;
  selectedNames: string[];
  allModuleData: ISelectedPayloads;
}

export interface IAddDefaultSatellitePayloadModeAction {
  type: typeof ADD_DEFAULT_SATELLITE_PAYLOAD_MODE;
  name: string;
}

export interface IRemoveDefaultSatellitePayloadModeAction {
  type: typeof REMOVE_DEFAULT_SATELLITE_PAYLOAD_MODE;
  name: string;
}

export interface IAddSatelliteOperationalMode {
  type: typeof ADD_SATELLITE_OPERATIONAL_MODE;
  values: {
    name: string;
  } & IPayloadModeConfiguration;
}

export interface IUpdateSatelliteOperationalMode {
  type: typeof UPDATE_SATELLITE_OPERATIONAL_MODE;
  values: {
    name: string;
    oldName: string;
  } & IPayloadModeConfiguration;
}

export interface IRemoveSatelliteOperationalMode {
  type: typeof REMOVE_SATELLITE_OPERATIONAL_MODE;
  name: string;
  POIs: IPointOfInterest[];
  ROIs: IRegionOfInterest[];
}

export interface IUpdateSelectedPayloads {
  type: typeof UPDATE_SELECTED_SATELLITE_PAYLOAD;
  names: string[];
  allModuleData: ISelectedPayloads;
}

export interface IUpdateSelectedPlatform {
  type: typeof UPDATE_SELECTED_SATELLITE_PLATFORM;
  names: IPlatformModules;
}

export interface IUploadSatelliteConfiguration {
  type: typeof UPLOAD_SATELLITE_CONFIGURATION;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sallite: any;
}

export type IPayloadActionTypes =
  | IUpdateModuleSelection
  | IAddDefaultSatellitePayloadModeAction
  | IRemoveDefaultSatellitePayloadModeAction
  | IAddSatelliteOperationalMode
  | IUpdateSatelliteOperationalMode
  | IRemoveSatelliteOperationalMode
  | IUpdateSelectedPayloads
  | IUpdateSelectedPlatform
  | IUploadSatelliteConfiguration;
