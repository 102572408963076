export const GEOMETRY_TYPES = {
  TLE: 'TLE',
  KEPLERIAN: 'KEPLERIAN',
  WALKER: 'WALKER',
  LAUNCH: 'LAUNCH',
  SYNCHRONOUS: 'SYNCHRONOUS',
  STATIONARY: 'STATIONARY',
  POLAR: 'POLAR',
};

export const ORBIT_TYPES = {
  SYNCHRONOUS: 'SYNCHRONOUS',
  STATIONARY: 'STATIONARY',
};

export const ELEVATION_MASK = {
  CONSTANT: 'CONSTANT',
};

export enum ASTRUMS {
  EARTH = 'EARTH',
  MOON = 'MOON',
  MARS = 'MARS',
  SUN = 'SUN',
}

export const SECONDS_PER_DAY = 86400;
