import classNames from 'classnames';
import type { CSSProperties } from 'react';
import type { ColumnProps } from 'ui/ColumnLayout/Column';

export type ColumnLayoutProps = {
  children: React.ReactElement<ColumnProps>[] | React.ReactElement<ColumnProps>;
  cols: number;
  colRatio: number[];
  horizontalGap?: number;
  style?: CSSProperties;
};

/**
 * Stylized column grid layout. Can be used to easily separate the screen in horizontal segments of a given width and a given number.
 * To be used with the Column component.
 * Number of cols must be equal to the length of the colRatio array
 * @param cols number - total number of columns to display
 * @param colRatio number array - width of each column in fr
 * @param horizontalGap number - gap between colum items
 *
 * @example <ColumnLayout cols={2} colRatio={[2,1]}><Column>{CONTENT}</Column></ColumnLayout>
 */
export const ColumnLayout = ({
  children,
  cols,
  colRatio,
  horizontalGap,
  style,
}: ColumnLayoutProps) => {
  if (cols !== colRatio.length) {
    throw new Error(
      'Column ratio array must be the same length as column number'
    );
  }

  return (
    <div
      className={classNames(
        'h-full w-full overflow-y-auto bg-surface dark:bg-surface-dark'
      )}
      style={{
        ...style,
        display: 'grid',
        columnGap: horizontalGap ?? 0,
        gridTemplateColumns: colRatio.map((r) => `${r}fr`).join(' '),
      }}
    >
      {children}
    </div>
  );
};
