import React, { useRef, useState } from 'react';

import { useTelemetryDefinitions } from 'services/api/telemetry/TelemetryDefinitionsProvider';

import LibraryTelemetryHeader from './LibraryTelemetryHeader';
import LibraryTelemetryDefinitionsTable from './LibraryTelemetryDefinitionsTable';

import s from './index.module.scss';

interface IProps {
  connectWithAlerts?: boolean;
}

const LibraryTelemetryDefinitions = (props: IProps) => {
  const ref = useRef(null);

  const { telemetryDefinitions, handleFilterChange } =
    useTelemetryDefinitions();

  const [isShowNewDefinition, setShowNewDefinition] = useState(false);

  return (
    <div ref={ref} className={s.libraryTelemetryDefinition}>
      <LibraryTelemetryHeader
        title="Telemetry Definitions"
        telemetryDefinitions={telemetryDefinitions}
        handleFilterChange={handleFilterChange}
      />
      <LibraryTelemetryDefinitionsTable
        definitionList={telemetryDefinitions}
        isShowNewDefinition={isShowNewDefinition}
        setShowNewDefinition={setShowNewDefinition}
        connectWithAlerts={props.connectWithAlerts}
      />
    </div>
  );
};

export default LibraryTelemetryDefinitions;
