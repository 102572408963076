import type { ToastProps } from '@blueprintjs/core';
import { Button } from 'opencosmos-ui';
import React, { useEffect, useState } from 'react';

type Props = {
  /**
   * Short message shown at all times in the toast
   */
  title: string;
  /**
   * Long, explanatory message that is shown once the user click the expand button
   */
  description: string;
  /**
   * Whether or not to show the copy description button
   */
  showCopyButton?: boolean;
} & Omit<ToastProps, 'message'>;

/**
 * CompositeToast component represents a toast consisting of a title and a description.
 * The purpouse of the component is to expand on the existing toast by giving the user more detials about an action they might have performed.
 * It is integrated as a part of the existing `toaster` component and is to be used by calling `toaster.showCompositeToast()`.
 *
 * This component is not expected to be used standalone.
 * @example toaster.showCompositeToast({title: "Short message", description: "Long message", ...otherProps})
 */
const CompositeToast = ({ title, description, showCopyButton }: Props) => {
  const [opened, setOpened] = useState<boolean>(false);

  const [hasCopied, setHasCopied] = useState<boolean>(false);

  const copyDescriptionToClipboard = () => {
    void navigator.clipboard.writeText(description).then(() => {
      setHasCopied(true);
    });
  };

  useEffect(() => {
    if (hasCopied) {
      setTimeout(() => setHasCopied(false), 1000);
    }
  }, [hasCopied]);
  return (
    <div>
      <div className="flex items-center justify-between">
        <span>{title}</span>
        <Button
          icon={opened ? 'ChevronUp' : 'ChevronDown'}
          isMinimal
          size="md"
          isTransparent
          onPress={() => {
            setOpened(!opened);
          }}
        />
      </div>

      {opened && (
        <div className="flex items-center gap-2">
          <p className="mt-2">{description}</p>
          {showCopyButton && (
            <Button
              icon={hasCopied ? 'tick' : 'Clipboard'}
              isMinimal
              size="md"
              isTransparent
              onPress={() => {
                copyDescriptionToClipboard();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CompositeToast;
