import {
  getSingleImageCovarianceMatrix,
  type CovarianceMatrixData,
} from '_api/sampling/service';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useLayerTools } from 'datacosmos/stores/LayerToolsProvider';
import { Button } from 'opencosmos-ui';
import { useState } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

export type CovarianceTableProps = {
  data: CovarianceMatrixData;
  isMaximized: boolean;
};

export const CovarianceTable = ({
  data,
  isMaximized,
}: CovarianceTableProps) => {
  const { translate } = useLocalisation();

  const noValuesAvailable = !data?.covariance_matrix?.value?.length;
  const noValuesAvailableMessage = translate(
    'datacosmos.catalogAndItems.covarianceMatrixModal.noValuesAvailableMessage'
  );

  const matrix = data?.covariance_matrix?.value ?? [];

  const variables = data?.covariance_matrix?.metadata?.variables ?? [];

  if (noValuesAvailable) {
    return (
      <div>
        <span>{noValuesAvailableMessage}</span>
      </div>
    );
  }

  return (
    <div
      className="m-2 flex flex-col justify-center items-center"
      style={
        isMaximized ? { height: '85dvh' } : { height: '40dvh', width: '30dvw' }
      }
    >
      <div className="overflow-auto h-full w-full">
        <table className="table-fixed text-center">
          <thead className="sticky -top-1 color-surface">
            <tr>
              <th></th>
              {variables.map((label) => (
                <th key={label} className="text-center">
                  {label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {matrix.map((row, rowIndex) => (
              <tr key={row[0]}>
                <td className="p-1 font-bold sticky left-0 color-surface">
                  {variables[rowIndex]}
                </td>
                {row.map((value) => (
                  <td key={value} className="p-1">
                    {value.toFixed(2)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

type ToolProps = {
  layerItem: SingleBandSTACLayer;
};

export const CovarianceForm = ({ layerItem }: ToolProps) => {
  const [status, setStatus] = useState<'success' | 'loading' | 'error'>(
    'success'
  );
  const { setData } = useLayerTools();
  const { translate } = useLocalisation();

  const handleGetCovarianceData = async () => {
    if (!layerItem?.item?.collection) {
      return;
    }
    setStatus('loading');
    const { data, status: responseStatus } =
      await getSingleImageCovarianceMatrix({
        params: {
          collectionId: layerItem?.item?.collection,
          itemId: layerItem?.item?.id,
        },
      });

    if (!data || responseStatus !== 200) {
      setStatus('error');
      return;
    }

    setData(data);
    setStatus('success');
  };
  return (
    <div className="flex flex-col w-full gap-4">
      <Button
        onPress={handleGetCovarianceData}
        className={`w-full ${status === 'loading' ? 'cursor-not-allowed' : ''}`}
        loading={status === 'loading'}
        intent="primary"
      >
        {translate('datacosmos.layers.tools.buttons.submit')}
      </Button>
      {status === 'error' && (
        <span className="text-warning">
          {translate('datacosmos.layers.tools.errors.request')}
        </span>
      )}
    </div>
  );
};
