import type { IStacItemGroup, StacItem } from 'datacosmos/types/stac-types';
import type { Dictionary } from 'lodash';
import { useMemo } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

export const useViewItem = (resultsGrouped: Dictionary<StacItem[]>) => {
  const { translate } = useLocalisation();

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const filteredResultsForSpecificItemFromURL: IStacItemGroup = {};
  let noMatchAvailableMessage: string | undefined;
  if (urlParams.get('view-item')) {
    const collectionAndStacIDofItem = urlParams.get('view-item');
    Object.entries(resultsGrouped).forEach(([key, value]) => {
      const filteredValues = value.filter(
        (item) =>
          item.collection === collectionAndStacIDofItem?.split('/')[0] &&
          item.id === collectionAndStacIDofItem?.split('/')[1]
      );
      if (!filteredValues.length) {
        noMatchAvailableMessage = translate(
          'datacosmos.catalogAndItems.errors.nothingMatchesSearchedItem'
        );
      }
      if (filteredValues.length > 0) {
        filteredResultsForSpecificItemFromURL[key] = filteredValues;
      }
    });
  }

  const isViewingSingleItem =
    window.location.search.includes('view-item') &&
    Object.keys(filteredResultsForSpecificItemFromURL).length > 0;

  const isSingleStacItemNotAvailable =
    window.location.search.includes('view-item') &&
    Object.keys(filteredResultsForSpecificItemFromURL).length === 0;

  return {
    isViewingSingleItem,
    isSingleStacItemNotAvailable,
    noMatchAvailableMessage,
  };
};
