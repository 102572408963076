import type { IMapTypeOptions } from '../registerMapType';
import { ASTRUMS } from '../../../constants/API/constant';
import astrumsJSON from '../../../__mock__/astrums.json';

export const moon: IMapTypeOptions = {
  planet: ASTRUMS.MOON,
  name: ASTRUMS.MOON,
  mapOption: {
    minZoom: 3,
    maxZoom: 6,
  },
  text: 'Moon',
  icon: 'moon',

  url: 'moon_256',
  tileSize: { x: 256, y: 128 },
  radius: astrumsJSON.MOON.maximumRadius,
  alt: 'Tile not found',
};
