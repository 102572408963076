import { LayerSourceType } from 'datacosmos/entities/layer';
import { MapMarkerLayer } from 'datacosmos/entities/mapMarkerLayer';

export const generateMarkerLayer = (
  latLng: { lat: number; lng: number },
  pixelData: string,
  showPopup: boolean
) => {
  return new MapMarkerLayer(
    LayerSourceType.PIXEL_MODE_MARKER,
    '',
    [latLng.lng, latLng.lat],
    pixelData,
    { enablePopup: showPopup }
  );
};
