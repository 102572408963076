import { useCallback, useEffect, useRef, useState } from 'react';
import { toaster } from 'toaster';
import type { WebSocketHandler } from './websocketClient';

/**
 * useWebsocket hook is a generic custom hook that connects to a websocket
 * server and returns the socket object. It returns methods to send data,
 * close the connection and the current connection state.
 * @param socketClient websocketClient instance
 * @param options optional parameters for the websocketClient
 * @returns object containing the socket object and the isConnected state
 */
export const useWebsocket = <Data, Params>(
  socketClient: (options: {
    /**
     * Parameters for the websocketClient. Used for connecting to the
     * websocket server. These are dynamic url parameters, such as
     * ids, names, etc.
     */
    params: Params;
    /**
     * Callback function that is called when a message is received from the
     * websocket server. It takes the message and the event as parameters.
     * @param message the message received from the websocket server
     * @param event the event object containing the message
     */
    onMessage?:
      | ((message: Data, event: MessageEvent<string>) => void)
      | undefined;
  }) => Promise<WebSocketHandler>,
  options?: {
    /**
     * Parameters for the websocketClient. Used for connecting to the
     * websocket server. These are dynamic url parameters, such as
     * ids, names, etc.
     */
    params: Params;
    /**
     * Callback function that is called when a message is received from the
     * websocket server. It takes the message and the event as parameters.
     * @param message the message received from the websocket server
     * @param event the event object containing the message
     */
    onMessage?: (message: Data, event: MessageEvent<string>) => void;
    skip?: boolean;
  }
) => {
  const [socket, setSocket] = useState<WebSocketHandler | null>(null);

  /**
   * connect function connects to the websocket server using the
   * socketClient instance and the options provided.
   */
  const connect = useCallback(async () => {
    if (socket) {
      return;
    }

    const client = await socketClient(options!);
    if (!client.socket) {
      return;
    }

    client.socket.onmessage = (ev: MessageEvent<string>) => {
      if (options?.onMessage) {
        options.onMessage(JSON.parse(ev.data) as Data, ev);
      }
    };

    setSocket(client);
  }, [socket, socketClient, options]);

  // Leading edge debounce
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (options?.skip) {
      return;
    }
    if (debounceTimeoutRef.current) {
      return;
    }
    void connect();
    debounceTimeoutRef.current = setTimeout(() => {
      debounceTimeoutRef.current = null;
    }, 1000);
  }, [connect, options?.skip, socket]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket?.close();
        setSocket(null);
      }
    };
  }, [socket]);

  useEffect(() => {
    if (
      socket?.socket?.readyState === WebSocket.CLOSED ||
      socket?.socket?.readyState === WebSocket.CLOSING
    ) {
      toaster.show({
        message:
          'Lost connection to the terminal session. Please refresh the page.',
        intent: 'warning',
      });
    }
  }, [socket?.socket?.readyState]);

  return { ...socket };
};
