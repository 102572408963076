import React, { useCallback } from 'react';

import type { ISatelliteStorageApi } from 'services/api/ops/satelliteStorage';
import { SATELLITE_FILE, CLOUD_FILE } from 'constants/dragAndDrop/constants';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { saveSatelliteStoragePath } from 'actions/operate/actions';
import { useMission } from 'services/Missions';
import {
  FTP_SATELLITE_SERVICES,
  SFTP_SATELLITE_SERVICES,
} from 'constants/fileExplorer/constants';
import type {
  ISendUnarmedCommand,
  ISendUnarmedCommandResponse,
} from 'services/api/ops/realtimeTerminalSession';

import LibraryFileExplorer from './LibraryFileExplorer';
import ServiceSelector from './ServiceSelector';
import type { CommandDefinition } from '_api/telecommands/types';

interface IProps {
  satelliteApi: ISatelliteStorageApi;
  isRefreshingSatelliteStorage: boolean;
  availableCommands: CommandDefinition[];
  cancelSentCommand: Function;
  setRightSideHidden: (hidden: boolean) => void;
  sendUnarmedCommand: (
    value: ISendUnarmedCommand
  ) => ISendUnarmedCommandResponse;
  setRefreshingSatelliteStorage: (isRefreshing: boolean) => void;
  setRefreshingAllowedSatelliteStorage: (isRefreshingAllowed: boolean) => void;
  handleLibraryDropItem: Function;
}

const SatelliteStorage = (props: IProps) => {
  const {
    state: { satelliteStorageSavedPath },
    dispatch,
  } = useOperate();

  const { currentMissionId } = useMission();

  const setSatelliteStoragePath = (path: string) => {
    dispatch(saveSatelliteStoragePath(path));
  };

  const setContentPath = (path: string) => {
    setSatelliteStoragePath(path);
    props.satelliteApi.setContentPath(path);
  };

  const getAvailableSatelliteServices = useCallback(() => {
    const groups = [
      ...new Set(props.availableCommands.map((c) => c.group)),
    ].filter((g) => g?.toLowerCase() === 'ftp' || g?.toLowerCase() === 'sftp');

    if (groups.length === 2) {
      return FTP_SATELLITE_SERVICES.concat(SFTP_SATELLITE_SERVICES);
    }

    if (groups[0] === 'Ftp') {
      return FTP_SATELLITE_SERVICES;
    }

    if (groups[0] === 'SFTP') {
      return SFTP_SATELLITE_SERVICES;
    }

    return [];
  }, [props.availableCommands]);

  return (
    <ServiceSelector
      name="satellite"
      currentService={props.satelliteApi.currentService}
      isFetching={props.satelliteApi.isFetching}
      failedToFetch={props.satelliteApi.failedToFetch}
      options={getAvailableSatelliteServices()}
      setCurrentService={props.satelliteApi.setCurrentService}
      showDefaultAsNone
      currentPath={props.satelliteApi.contentPath}
      setFilePath={setContentPath}
    >
      <LibraryFileExplorer
        simplifiedExplorer
        prependRootSlash
        missionId={currentMissionId ?? 0}
        setRefreshing={props.setRefreshingSatelliteStorage}
        isRefreshing={props.isRefreshingSatelliteStorage}
        setRefreshingAllowed={props.setRefreshingAllowedSatelliteStorage}
        defaultPath={['/home/']}
        storedPath={satelliteStorageSavedPath}
        setStoredPath={setSatelliteStoragePath}
        dragType={SATELLITE_FILE}
        acceptedDropType={CLOUD_FILE}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        onDropFile={props.handleLibraryDropItem('FtpDownload')}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        onDropFolder={props.handleLibraryDropItem('FtpDownloadFolderContents')}
        prefixSlashOnFolder
        {...props.satelliteApi}
      />
    </ServiceSelector>
  );
};

export default SatelliteStorage;
