import classNames from "classnames";
import type { DetailedHTMLProps } from "react";

export type InputProps = {
  type: "text" | "number" | "textarea" | "email";
  label?: {
    text: string;
    position: "top" | "left" | "right";
    wrapLabelText?: boolean;
  };
  className?: string;
  errorMsg?: string;
  fill?: boolean;
} & DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Input = (props: InputProps) => {
  return (
    <div className={classNames("flex-col gap-2", { "w-full": props.fill })}>
      <div
        className={classNames({
          "flex !shadow-none grid-cols-2 gap-4 items-center":
            props.label &&
            (props.label.position === "left" ||
              props.label.position === "right"),
          "flex-col gap-1": props.label && props.label.position === "top",
        })}
      >
        {props.label && (
          <label
            htmlFor={props.id}
            className={classNames(" text-sm", {
              "order-1": props.label.position === "left",
              "order-2": props.label.position === "right",
              "whitespace-nowrap": !props.label?.wrapLabelText,
            })}
          >
            {props.label.text}
          </label>
        )}
        {props.type === "textarea" ? (
          <textarea
            placeholder={props.placeholder}
            onChange={
              props.onChange as unknown as React.ChangeEventHandler<HTMLTextAreaElement>
            }
            onClick={
              props.onClick as unknown as React.MouseEventHandler<HTMLTextAreaElement>
            }
            className={classNames(
              props.className,
              "pl-2 pr-2 text-sm h-32 w-full border-2",
              "border-item bg-item dark:bg-item-dark",
              {
                "order-2": props.label && props.label.position === "left",
                "order-1": props.label && props.label.position === "right",
                block: props.label && props.label.position === "top",
              }
            )}
            tabIndex={1}
            name={props.name}
            disabled={props.disabled}
            value={props.value}
            onBlur={
              props.onBlur as unknown as React.FocusEventHandler<HTMLTextAreaElement>
            }
            readOnly={props.readOnly}
          />
        ) : (
          <input
            autoComplete="off"
            id={props.id}
            data-testid={props.id}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onClick={props.onClick}
            name={props.name}
            className={classNames(
              props.className,
              "w-full border-2 pl-2 pr-2 h-8 text-sm",
              "border-item bg-item dark:bg-item-dark",
              {
                "order-2": props.label && props.label.position === "left",
                "order-1": props.label && props.label.position === "right",
                block: props.label && props.label.position === "top",
              }
            )}
            value={props.value}
            tabIndex={1}
            readOnly={props.readOnly}
            disabled={props.disabled}
            ref={props.ref}
          />
        )}
      </div>
      {props.errorMsg && (
        <small className="text-warning">{props.errorMsg}</small>
      )}
    </div>
  );
};

export default Input;
