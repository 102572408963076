/*
 *
 * Definitions for hotkeys. Should have a descriptive name
 * that matches the function that it is used in.
 *
 * const toggleLeftPanelShortcut = (): KeyboardShortcut => {
 *    const action = () => {...}
 *     return [TOGGLE_LEFT_PANEL, action];
 * }
 */

export const HOTKEY_TOGGLE_LEFT_PANEL: string[] = ['SHIFT + ALT + LEFTARROW'];
export const HOTKEY_TOGGLE_RIGHT_PANEL: string[] = ['SHIFT + ALT + RIGHTARROW'];
export const HOTKEY_TOGGLE_COMMAND_HISTORY_COLLAPSE: string[] = [
  'SHIFT + ALT + UPARROW',
];

export const HOTKEY_NAVIGATE_CMD_PILE_UP: string[] = ['UPARROW'];
export const HOTKEY_NAVIGATE_CMD_PILE_DOWN: string[] = ['DOWNARROW'];

export const HOTKEY_SEND_COMMAND_FOCUS_NEXT: string[] = ['SHIFT + ALT + ENTER'];

export const HOTKEY_SEND_COMMAND_FOCUS_REPLY: string[] = ['SHIFT + ENTER'];

export const HOTKEY_PASTE_COMMAND: string[] = ['ALT + V'];
export const HOTKEY_COPY_COMMAND: string[] = ['ALT + C'];
export const HOTKEY_COPY_COMMAND_PILE: string[] = ['SHIFT + ALT + C'];
export const HOTKEY_PASTE_COMMAND_PILE: string[] = ['SHIFT + ALT + V'];
