import { SATELLITE_ID_LIST } from '_api/satellites/types';
import type { CondataSatellite, Satellite } from '_api/satellites/types';

// Intended for satellites which are owned by Open Cosmos, tasked via DataCosmos,
// and available for tasking in test but not in production
export const INTERNAL_SATELLITES: Satellite[] = [];

// Intended for condata satellites
export const CONDATA_SATELLITES: CondataSatellite[] = [
  {
    description: '',
    uiName: 'Terra',
    gsdData: 90.0,
    COSPAR: '1999-068A',
  },
  {
    description: '',
    uiName: 'Cbers-2b',
    gsdData: 20.0,
    COSPAR: '2007-042A',
  },
  {
    description: '',
    uiName: 'Cbers-4a',
    gsdData: 8.0,
    COSPAR: '2019-093E',
  },
  {
    description: '',
    uiName: 'Cosmo-skymed-4',
    gsdData: 2.5,
    COSPAR: '2010-060A',
  },
  {
    description: '',
    uiName: 'Kazeosat-1',
    gsdData: 4,
    COSPAR: '2014-024A',
  },
  {
    description: '',
    uiName: 'Kompsat-3',
    gsdData: 3.279,
    COSPAR: '2012-025B',
  },
  {
    description: '',
    uiName: 'Landsat-8',
    gsdData: 30.0,
    COSPAR: '2013-008A',
  },
  {
    description: '',
    uiName: 'Noaa14',
    gsdData: 1050.0,
    COSPAR: '1994-089A',
  },
  {
    description: '',
    uiName: 'Noaa16',
    gsdData: 1050.0,
    COSPAR: '2000-055A',
  },
  {
    description: '',
    uiName: 'Noaa19',
    gsdData: 1050.0,
    COSPAR: '2009-005A',
  },
  {
    description: '',
    uiName: 'Perusat-1',
    gsdData: 3.6,
    COSPAR: '2016-058A',
  },
  {
    description: '',
    uiName: 'Pleiades-1a',
    gsdData: 0.7875692556046401,
    COSPAR: '2011-076F',
  },
  {
    description: '',
    uiName: 'Pleiades-1b',
    gsdData: 0.7086981314065767,
    COSPAR: '2012-068A',
  },
  {
    description: '',
    uiName: 'Sentinel-2a',
    gsdData: 60.0,
    COSPAR: '2015-028A',
  },
  {
    description: '',
    uiName: 'Sentinel-2b',
    gsdData: 10.0,
    COSPAR: '2017-013A',
  },
  {
    description: '',
    uiName: 'Spot-6',
    gsdData: 10.0278436384,
    COSPAR: '2012-047A',
  },
  {
    description: '',
    uiName: 'Spot-7',
    gsdData: 11.8892692542,
    COSPAR: '2014-034A',
  },
  {
    description: '',
    uiName: 'Terrasar-x',
    gsdData: 8.25,
    COSPAR: '2007-026A',
  },
];

// Intended for satellites which are not owned by Open Cosmos, tasked via DataCosmos,
// and available for tasking in both test and production
export const TASKED_FROM_DATACOSMOS: Satellite[] = [
  {
    uiName: 'GEOSAT-2',
    satellite: SATELLITE_ID_LIST.GEOSAT_2,
    description:
      'Geosat-2 is a Spanish remote sensing Earth observation satellite built for Elecnor Deimos under an agreement with Satrec Initiative',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.75,
    COSPAR: '2014-033D',
    taskable: true,
  },
  {
    uiName: 'SUPERVIEW-1-01',
    satellite: SATELLITE_ID_LIST.SUPERVIEW_01,
    description:
      'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.5,
    COSPAR: '2016-083A',
    taskable: true,
  },
  {
    uiName: 'SUPERVIEW-1-02',
    satellite: SATELLITE_ID_LIST.SUPERVIEW_02,
    description:
      'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.5,
    COSPAR: '2016-083B',
    taskable: true,
  },
  {
    uiName: 'SUPERVIEW-1-03',
    satellite: SATELLITE_ID_LIST.SUPERVIEW_03,
    description:
      'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.5,
    COSPAR: '2018-002A',
    taskable: true,
  },
  {
    uiName: 'SUPERVIEW-1-04',
    satellite: SATELLITE_ID_LIST.SUPERVIEW_04,
    description:
      'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.5,
    COSPAR: '2018-002B',
    taskable: true,
  },
  {
    uiName: 'MANTIS',
    satellite: SATELLITE_ID_LIST.MANTIS,
    description:
      'MANTIS is an Open Cosmos high-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 2.5,
    COSPAR: '2023-174B',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.MENUT,
    description:
      'Menut is an Open Cosmos medium-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'MENUT',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2023-001B',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.COGNISAT_6,
    description:
      'HAMMER is an Open Cosmos medium-resolution hyperspectral Earth observation satellite with an Inter-Satellite Link',
    backend: 'OPEN_COSMOS',
    uiName: 'HAMMER',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2024-043BC',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.COGNISAT_6_EM,
    description: 'Engineering Model (EM) for HAMMER, for test purposes only',
    backend: 'OPEN_COSMOS',
    uiName: 'HAMMER-EM',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: 'COSPAR-HAMMER-EM-TBD',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.PERUSAT_1,
    description:
      'Perusat-1 is owned and operated by the Peruvian government department CONIDA',
    backend: 'OPEN_COSMOS',
    uiName: 'PERUSAT-1',
    dataType: ['Optical'],
    gsdData: 0.7,
    COSPAR: '2016-058A',
    taskable: false,
  },
  {
    satellite: SATELLITE_ID_LIST.ALISIO,
    description:
      'Alisio is an Open Cosmos medium-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'Alisio',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2023-185M',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.PLATERO,
    description:
      'Platero is an Open Cosmos medium-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'Platero',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2023-174G',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.PHISAT2,
    description:
      'PHISAT-2 is an Open Cosmos medium-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'PHISAT-2',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2024-149C',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.PHISAT2_EM,
    description: 'Engineering Model (EM) for PHISAT-2, for test purposes only',
    backend: 'OPEN_COSMOS',
    uiName: 'PHISAT-2 EM',
    dataType: ['Optical'],
    gsdData: 4.75,
    // No need to upload TLE for this satellite so we can use a placeholder
    COSPAR: 'COSPAR-PHISAT2-EM-TBD',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.SENTINEL_2A,
    description: 'Sentinel-2A is an ESA Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'Sentinel-2A',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2015-028A',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.SENTINEL_2B,
    description: 'Sentinel-2B is an ESA Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'Sentinel-2B',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2017-013A',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.SENTINEL_2C,
    description: 'Sentinel-2C is an ESA Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'Sentinel-2C',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2024-157A',
    taskable: true,
  },
];

export const ALL_SATELLITES: Satellite[] = [
  ...INTERNAL_SATELLITES,
  ...TASKED_FROM_DATACOSMOS,
].sort((a, b) => a.uiName.localeCompare(b.uiName));

export const SATELLITES_FOR_PRODUCTION: Satellite[] = [
  ...TASKED_FROM_DATACOSMOS,
  ...INTERNAL_SATELLITES,
].sort((a, b) => a.uiName.localeCompare(b.uiName));

export const getTaskableSats = (satelliteList: Satellite[]) => {
  return satelliteList.filter(({ taskable }) => taskable);
};
