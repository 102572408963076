import type { ReactElement } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';

export interface IStateProps {
  isLoading: boolean;
}

export default function Loading({ isLoading }: IStateProps): ReactElement {
  const { loading } = useAuth();
  return <div className={isLoading || loading ? 'page-loader' : ''} />;
}
