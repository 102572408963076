import { Spinner } from '@blueprintjs/core';
import Input from '_molecules/Input/Input';
import type { InputProps } from '_molecules/Input/Input';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

type Props = InputProps & {
  /**
   * After how many milliseconds will the `onChange` callback fire
   */
  debounceTimeMs: number;
};

/**
 * DebouncedInput represents an `<Input/>` component with a built in debouncer on the `onClick` function.
 * Shows a loading `<Spinner/>` for the duration of debounce until the `onClick` gets executed
 * Extends `InputProps`
 */
const DebouncedInput = ({ debounceTimeMs, ...inputProps }: Props) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const debounceFn = () => {
      setLoading(true);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        timeout.current = undefined;
        setLoading(false);
        inputProps.onChange?.(e);
      }, debounceTimeMs);
    };

    debounceFn();
  };
  return (
    <div className="relative">
      {loading && (
        <Spinner
          size={20}
          className={classNames('absolute', {
            'top-7 right-2': inputProps.label?.position === 'top',
            'top-1 right-14': inputProps.label?.position === 'right',
            'top-1 right-1':
              !inputProps.label || inputProps.label?.position === 'left',
          })}
        />
      )}
      <Input {...inputProps} onChange={debouncedOnChange}></Input>
    </div>
  );
};

export default DebouncedInput;
