import { useQuery } from '@tanstack/react-query';
import { getScripts, getTriggers, postRunScript } from '_api/scripts/service';
import type { Script } from '_api/scripts/types';
import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';
import { useMission } from 'services/Missions';

export const useScriptsData = () => {
  const { currentMissionId } = useMission();

  const fuseOptions = useMemo<Fuse.FuseOptions<Script>>(
    () => ({
      threshold: 0.3,
    }),
    []
  );

  const { data: scripts } = useQuery({
    queryKey: ['scripts', currentMissionId],
    queryFn: () => {
      return getScripts({
        params: {
          missionId: currentMissionId ?? '',
        },
      });
    },
    enabled: Boolean(currentMissionId),
  });

  const { data: triggers } = useQuery({
    queryKey: ['triggers', currentMissionId],
    queryFn: () => {
      return getTriggers({
        params: {
          missionId: currentMissionId ?? '',
        },
      });
    },
    enabled: Boolean(currentMissionId),
  });

  const [filteredScripts, setFilteredScripts] = useState<Script[] | undefined>(
    scripts?.data
  );

  const [selectedScript, setSelectedScript] = useState<Script | undefined>();

  const [scriptBody, setScriptBody] = useState<string | undefined>();

  const [nameSearchQuery, setNameSearchQuery] = useState<string | undefined>();
  const [triggerSearchQuery, setTriggerSearchQuery] = useState<
    string | undefined
  >();

  const [isPublishingScript, setIsPublishingScript] = useState<boolean>(false);

  const toggleSelectScript = (script: Script) => {
    setSelectedScript((prev) => (prev === script ? undefined : script));
  };

  const publishSelectedScript = async () => {
    if (!selectedScript) {
      return;
    }

    setIsPublishingScript(true);

    await postRunScript({
      params: {
        missionId: currentMissionId ?? '',
        scriptName: selectedScript.name,
      },
      body: {
        type: 'manual',
      },
    });

    setIsPublishingScript(false);
  };

  useEffect(() => {
    if (selectedScript) {
      setScriptBody(selectedScript?.body);
    }
  }, [selectedScript]);

  useEffect(() => {
    if (!scripts?.data) {
      return;
    }

    if (!nameSearchQuery && !triggerSearchQuery) {
      setFilteredScripts(scripts.data);
      return;
    }

    let results: Script[] = scripts.data;

    if (nameSearchQuery) {
      const fuse = new Fuse(scripts.data, { ...fuseOptions, keys: ['name'] });

      const res = fuse
        .search(nameSearchQuery)
        .map((result) => result as Script);
      results = res;
    }

    if (triggerSearchQuery) {
      const fuse = new Fuse(results, {
        ...fuseOptions,
        keys: ['triggers.type', 'triggers.event_type'],
      });
      const res = fuse
        .search(triggerSearchQuery)
        .map((result) => result as Script);

      results = res;
    }

    setFilteredScripts(results);
  }, [fuseOptions, nameSearchQuery, scripts?.data, triggerSearchQuery]);

  return {
    scripts: filteredScripts ?? [],
    triggers: triggers?.data ?? [],
    toggleSelectScript,
    selectedScript,
    scriptBody,
    nameSearchQuery,
    setNameSearchQuery,
    triggerSearchQuery,
    setTriggerSearchQuery,
    isPublishingScript,
    publishSelectedScript,
  };
};
