import React from 'react';
import s from './index.module.scss';
import { Elevation, Card, Button, Intent } from '@blueprintjs/core';

interface IProps {
  createNewInstance?: () => void;
  isCreating: boolean;
}

const NoNodeREDInstance = (props: IProps) => {
  const createNewInstance = () => {
    props.createNewInstance?.();
  };
  return (
    <div className={s.libraryCardContainer}>
      <Card interactive={true} elevation={Elevation.TWO}>
        <span>No scripting instance currently running.</span>
        <Button
          icon="saved"
          className={s.librarySaveBtn}
          onClick={() => {
            createNewInstance();
          }}
          intent={Intent.PRIMARY}
          loading={props.isCreating}
        >
          Enable
        </Button>
      </Card>
    </div>
  );
};

export default NoNodeREDInstance;
