import type { ReactNode } from 'react';
import React from 'react';
import s from '../../../shared/layout.module.scss';
import { OpenappNav } from 'opencosmos-ui';
import classNames from 'classnames';
import { useOpenappNav } from 'pages/shared/context/OpenappNavProvider';

export interface IDefaultPortalLayoutProps {
  children?: ReactNode;
  header?: ReactNode;
  leftHeader?: ReactNode;
  rightHeader?: ReactNode;
  light?: boolean;
  offLight?: boolean;
  className?: string;
  onClickLogo?: (event: React.MouseEvent<HTMLDivElement>) => void;
  fill?: boolean;
  centerContent?: boolean;
}

const DefaultPortalLayout = (props: IDefaultPortalLayoutProps) => {
  const {
    portalNavMain,
    portalNavFooter,
    selectedItemKey,
    isNavCollapsed,
    setIsNavCollapsed,
  } = useOpenappNav();

  const { children, className, fill } = props;

  const containerStyles = [className, s.main];

  if (fill) {
    containerStyles.push(s.main_fullHeightContainer);
  }

  return (
    <OpenappNav
      header={{
        left: <div className="flex items-center gap-2">{props.leftHeader}</div>,
        center: props.header,
        right: props.rightHeader,
      }}
      navigationItems={{ footerItems: portalNavFooter, items: portalNavMain }}
      startCollapsed={isNavCollapsed}
      selectedNavigationItemKey={selectedItemKey}
      onToggleCollapse={(isCollapsed) => {
        setIsNavCollapsed(isCollapsed);
      }}
    >
      <div
        className={classNames(
          'mission-page-center',
          'h-full',
          containerStyles.join(' '),
          {
            [s.main_fullHeightContainer]: fill,
            'flex-container': props.centerContent,
          }
        )}
      >
        {children}
      </div>
    </OpenappNav>
  );
};

export default DefaultPortalLayout;
