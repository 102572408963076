import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import type { NextPassData } from './types';

export type GetNextPassParams = {
  missionId: number;
};

export const getNextPass = apiFetchHandler<NextPassData, GetNextPassParams>({
  host: hosts.portal.v0,
  method: 'GET',
  endpoint: ({ missionId }) =>
    `/gs/mission/${missionId}/passes/next?mission=${missionId}`,
  errorMessage: 'Could not get next pass',
  onError: ({ state: { errors } }) => {
    const noPassScheduled = errors.some(
      (e) => e === 'No current/upcoming pass has been computed yet.'
    );
    if (noPassScheduled) {
      return;
    }
  },
});
