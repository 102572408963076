import React from 'react';
import { Pre } from '@blueprintjs/core';
import s from './ExecuteTaskReplyPre.module.scss';

interface IProps {
  preList: string[];
}

const ExecuteTaskReplyPre = (props: IProps) => (
  <Pre className={s.wrapper}>
    {props.preList.map((item) => (
      <span key={item}>{item}</span>
    ))}
  </Pre>
);

export default ExecuteTaskReplyPre;
