import { useEffect, useMemo } from 'react';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useTasking } from 'datacosmos/stores/TaskingProvider';
import { sortBy } from 'lodash';
import { Steps } from 'datacosmos/components/Tasking/helpers';
import { ConfirmedSwathCard } from 'datacosmos/components/Tasking/ConfirmedSwathCard';
import { ConfirmRequestStatistics } from 'datacosmos/components/Tasking/ConfirmRequestStatistics';
import type { Layer } from 'datacosmos/entities/layer';
import type { SwathLayer } from 'datacosmos/entities/SwathLayer';
import { getSwathPrice, type CommonOpportunity } from '_api/tasking/helpers';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { isDevMode } from 'utils/common/CommonUtils';
import { Dialog } from 'opencosmos-ui';

const getRegionsOfInterest = (layers: Layer[]) => {
  return layers.filter(
    ({ sourceType }) => sourceType === LayerSourceType.TASKING_REGIONS
  ) as GeoJSONLayer<unknown>[];
};

type ConfirmRequestProps = {
  setSwitchStep: (value: Steps) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleSwitchToExistingPanel: (key: string) => void;
};

const price = (ss: SwathLayer<CommonOpportunity>[]): number | undefined => {
  const prices = ss
    .map((s) => {
      const p = s.metadata.AvailablePriorities?.base_price_per_km2_gbp;
      if (
        p === undefined ||
        s.metadata.Area === undefined ||
        !s.metadata.AvailablePriorities
      ) {
        return undefined;
      }
      return Number(
        getSwathPrice(
          s.metadata.Area,
          s.metadata.Priority?.priority_level,
          s.metadata.AvailablePriorities
        )
      );
    })
    .filter((p) => p !== undefined);
  if (prices.length !== ss.length) {
    return undefined;
  }

  const p = prices.reduce((a: number, value) => (value ? a + value : a), 0);
  return p;
};

export const ConfirmManualRequest = ({
  setSwitchStep,
  isOpen,
  setIsOpen,
  handleSwitchToExistingPanel,
}: ConfirmRequestProps) => {
  const { confirmedSwaths } = useTasking();
  const { layers, setLayers } = useMapLayers();
  const { requestActivities, requestNotes, setRequestNotes } = useTasking();

  const { translate } = useLocalisation();

  const regionsOfInterest = useMemo(
    () =>
      layers.filter(
        (l) => l.sourceType === LayerSourceType.TASKING_REGIONS
      ) as GeoJSONLayer<unknown>[],
    [layers]
  );

  useEffect(() => {
    setLayers((prev) => {
      const regions = getRegionsOfInterest(prev);
      return prev.map((layer) => {
        const region = regions.find(({ id }) => id === layer.id);
        if (region) {
          return region.cloneWithModifiers({ uneditable: true });
        }
        return layer;
      });
    });
  }, [setLayers]);

  return (
    <Dialog
      isOpen={isOpen}
      buttons={[
        {
          shown: true,
          onPress: async () => {
            const response = await requestActivities();
            if (response) {
              //TODO: Remove this after prod is setup for stripe payments
              if (isDevMode() && response?.checkout_redirect_url?.length) {
                window.open(response?.checkout_redirect_url);
              }
              setIsOpen(false);
              handleSwitchToExistingPanel('Existing');
            }
          },
          text:
            isDevMode() && price(confirmedSwaths) !== 0
              ? translate('datacosmos.buttons.continueToPayment')
              : translate('datacosmos.buttons.request'),
        },
      ]}
      onClose={() => {
        setIsOpen(false);
        setSwitchStep(Steps.ListOfOpportunities);
      }}
    >
      <div className="grid !shadow-none grid-cols-2 gap-4 h-[450px]">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <span className="text-[16px] block" data-testid="title">
              {translate(
                'datacosmos.tasking.purchaseDialog.thisRequestIsComposed',
                { count: confirmedSwaths.length }
              )}
            </span>
            <span className="block text-sm">
              {translate(
                'datacosmos.tasking.purchaseDialog.youCanFindTheDetailed'
              )}
            </span>
          </div>
          <ConfirmRequestStatistics
            confirmedSwaths={confirmedSwaths}
            regionsOfInterest={regionsOfInterest}
          />
          <span className="confirm-request-subtitle">
            {translate(
              'datacosmos.tasking.purchaseDialog.statistics.additionalNotes'
            )}
          </span>

          <div>
            <textarea
              style={{ width: '100%', height: '150px', resize: 'none' }}
              value={requestNotes}
              onChange={(e) => setRequestNotes(e.target.value)}
              className="dark:bg-item-dark dark:text-item-dark-contrast bg-item"
              aria-label="textarea-notes"
            />
          </div>
        </div>

        <div style={{ marginBottom: '25px', marginTop: '10px' }}>
          <div className="flex flex-col gap-1 h-[400px] overflow-auto">
            {sortBy(
              confirmedSwaths,
              (swath) => new Date(swath.metadata.Start)
            ).map((swath) => (
              <ConfirmedSwathCard key={swath.id} confirmedSwath={swath} />
            ))}
          </div>

          <div className="text-right p-5">
            <span className="text-lg font-bold">
              {translate(
                'datacosmos.tasking.purchaseDialog.statistics.totalPrice',
                {
                  price:
                    price(confirmedSwaths) !== undefined
                      ? '$' + price(confirmedSwaths)?.toFixed(2)
                      : 'contact us',
                }
              )}
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
