import type { IPoint } from '../constants/regionsOfInterest/actionTypes';

interface IOption {
  vertex?: number;
  edge?: number;
  full: boolean;
}

interface IVector {
  '0': IPoint;
  '1': IPoint;
}

interface IEdgesObject {
  origin: IVector;
  targets: IVector[];
}

const normalize360 = (value: number): number => {
  if (value > 180) {
    return value - 360;
  }
  if (value < -180) {
    return value + 360;
  }
  return value;
};

function getByModule(mod: number, value: number): number {
  mod = mod < 0 ? -mod : mod;
  while (value < 0) {
    value += mod;
  }
  return value % mod;
}

function isLinesCross(vector1: IVector, vector2: IVector): boolean {
  const toPositiveValue = 180;
  const x11 = vector1[0]?.lng ?? 0 + toPositiveValue;
  const x12 = vector1[1]?.lng ?? 0 + toPositiveValue;
  const x21 = vector2[0]?.lng ?? 0 + toPositiveValue;
  const x22 = vector2[1]?.lng ?? 0 + toPositiveValue;
  const y11 = vector1[0].lat + toPositiveValue;
  const y12 = vector1[1].lat + toPositiveValue;
  const y21 = vector2[0].lat + toPositiveValue;
  const y22 = vector2[1].lat + toPositiveValue;

  const div =
    normalize360(y22 - y21) * normalize360(x12 - x11) -
    normalize360(x22 - x21) * normalize360(y12 - y11);
  const uaUp =
    normalize360(x22 - x21) * normalize360(y11 - y21) -
    normalize360(y22 - y21) * normalize360(x11 - x21);
  const ubUp =
    normalize360(x12 - x11) * normalize360(y11 - y21) -
    normalize360(y12 - y11) * normalize360(x11 - x21);

  const compareA = uaUp / div;
  const compareB = ubUp / div;
  return compareA > 0 && compareA < 1 && compareB > 0 && compareB < 1;
}

function getEdgesForVertex(
  vertex: number,
  path: IPoint[]
): IEdgesObject | undefined {
  const maxNumEdges = path.length - 3;
  const mod = getByModule.bind(null, path.length);
  if (maxNumEdges < 1) {
    return undefined;
  }
  const edgesObject: IEdgesObject = {
    origin: [path[vertex], path[mod(vertex + 1)]],
    targets: [],
  };
  for (let i = 0; i < maxNumEdges; i++) {
    const targetEdge = {
      0: path[mod(vertex + 2 + i)],
      1: path[mod(vertex + 3 + i)],
    };
    edgesObject.targets.push(targetEdge);
  }
  return edgesObject;
}

export default function polygonSelfIntersecting(
  option: IOption,
  path: IPoint[]
): boolean | undefined {
  let insideVertex;
  const mod = getByModule.bind(null, path.length);
  if (option.vertex !== undefined) {
    insideVertex = mod(option.vertex);
  } else if (option.edge !== undefined) {
    insideVertex = mod(option.edge + 1);
  } else {
    return undefined;
  }

  const causes: (IEdgesObject | undefined)[] = [
    getEdgesForVertex(insideVertex, path),
  ];
  if (!option.full) {
    causes?.push(getEdgesForVertex(mod(insideVertex - 1), path));
  }
  if (!causes[0]) {
    return false;
  }
  for (const cause of causes) {
    for (const target of cause?.targets ?? []) {
      if (cause?.origin && isLinesCross(cause?.origin, target)) {
        return true;
      }
    }
  }
  return false;
}
