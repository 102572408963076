import classNames from 'classnames';

export type TextProps = {
  children?: string;
  className?: string;
  bold?: boolean;
  padding?: 0 | 1 | 2;
  breakLine?: boolean;
  inherit?: boolean;
};

export const Text = ({
  children,
  bold,
  inherit = true,
  breakLine = false,
  padding = 2,
  className,
}: TextProps) => {
  if (!children) return null;
  return (
    <span
      className={classNames(className, `p-${padding}`, {
        'dark:text-item-contrast': inherit,
        'whitespace-pre-wrap': breakLine,
        'whitespace-pre': !breakLine,
        'font-bold': bold,
      })}
    >
      {children}
    </span>
  );
};
