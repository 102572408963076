import type {
  LaunchOpportunitiesActionTypes,
  ILOState,
} from '../../../../../constants/constraintsTable/actionTypesLaunchOpportunity';
import { LO_UPLOAD } from '../../../../../constants/constraintsTable/actionTypesLaunchOpportunity';

export function uploadLaunchOpportunities(
  launchOpportunities: ILOState
): LaunchOpportunitiesActionTypes {
  return {
    type: LO_UPLOAD,
    launchOpportunities,
  };
}
