import React from "react";
import { Header } from "react-aria-components";

const MyHeader: typeof Header = React.forwardRef((props, ref) => {
  return (
    <Header
      {...props}
      ref={ref}
      className={"px-1 bg-item-hover text-sm " + props.className}
    />
  );
});

export default MyHeader;
