import type { IMapContext } from 'datacosmos/stores/MapProvider';
import { useState, useEffect, useMemo } from 'react';

const useMapCoordinates = (map: IMapContext | null) => {
  const [latLng, setLatLng] = useState<string>('');

  useEffect(() => {
    if (!map) return;
    map.onMapMouseMove((_, coords) => {
      const newLatLng = Object.values(coords);
      setLatLng(newLatLng[0].toFixed(6) + ',' + newLatLng[1].toFixed(6));
    });
  }, [map]);

  return useMemo(() => ({ latLng }), [latLng]);
};

export default useMapCoordinates;
