import { NumberInput } from 'opencosmos-ui';
import React, { useEffect, useState } from 'react';
import type { EventProperty } from '../utils/schema';

type Props = {
  schema: EventProperty;
  propertyKey: string;
  populateEventToSubmit: (value: unknown, propertyKey: string) => void;
};

const EventSchemaInt = ({
  schema,
  propertyKey,
  populateEventToSubmit,
}: Props) => {
  const [val, setVal] = useState<number | null | undefined>(
    schema.default ? Number(schema.default) : null
  );

  useEffect(() => {
    populateEventToSubmit(val, propertyKey);
  }, [populateEventToSubmit, propertyKey, val]);

  useEffect(() => {
    setVal(schema.default ? Number(schema.default) : null);
  }, [schema.default]);

  return (
    <div>
      <div className="flex gap-1 items-center">
        <span>{schema.title}</span>
        {schema.isRequired && <span className="text-warning">*</span>}
      </div>
      <NumberInput
        fill
        value={val as unknown as number | undefined}
        onChange={(n) => {
          if (isNaN(n)) {
            setVal(null);
            return;
          }
          setVal(n);
        }}
      />
    </div>
  );
};

export default EventSchemaInt;
