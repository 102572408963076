/* eslint-disable @typescript-eslint/restrict-template-expressions */
import type { IOptimisationObjective } from '../constants/objectivesTable/constants';
import { OBJECTIVE_TYPES } from '../constants/objectivesTable/constants';
import type { IGoal } from '../constants/goals/interfaceas';

export const getObjectiveName = (
  objective: IOptimisationObjective | IGoal,
  t: Function
): (() => string) => {
  const { type, object, metric, event, scope } = objective;
  let returnFunction;
  switch (type) {
    case OBJECTIVE_TYPES.MISSION_METRIC: {
      returnFunction = (): string =>
        `${t(`objectives.types.${type}`)} - ${t(
          `objectives.metric.${metric}`
        )}`;
      break;
    }
    case OBJECTIVE_TYPES.GROUND_SEGMENT_EVENT: {
      returnFunction = (): string =>
        `${t(`objectives.types.${type}`)} - ${t(
          `objectives.object.${object}`
        )} - ${t(`objectives.event.${event}`)} - ${t(
          `objectives.scope.${scope}`
        )} - ${t(`objectives.metric.${metric}`)}`;
      break;
    }
    case OBJECTIVE_TYPES.POINT_OF_INTEREST_EVENT:
    case OBJECTIVE_TYPES.REGION_OF_INTEREST_EVENT: {
      returnFunction = (): string =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        `${t(`objectives.types.${type}`)} - ${objective.object.name} - ${t(
          `objectives.event.${event}`
        )} - ${t(`objectives.scope.${scope}`)} - ${t(
          `objectives.metric.${metric}`
        )}`;
      break;
    }
    default: {
      returnFunction = (): string => 'Error name';
    }
  }
  return returnFunction;
};
