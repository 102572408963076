import * as L from 'leaflet';
export default L.Control.extend({
  options: {
    position: 'topleft',
    labels: '',
    legendClassName: '',
  },

  onAdd: function () {
    var options = this.options;
    var container = L.DomUtil.create(
      'div',
      `bg-surface dark:bg-surface-dark p-2 flex gap-2 dark:text-neutral flex-wrap ${options.legendClassName}`
    );
    container.innerHTML = options.labels;
    return container;
  },
  onRemove: function (map) {
    delete map.legend;
  },
});
