import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Button, Tooltip } from 'opencosmos-ui';
import type { IconSize } from 'opencosmos-ui/src/icons/Icon';

interface IProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  cardContent: JSX.Element | JSX.Element[];
  info: {
    text?: string;
    iconSize: IconSize;
  };
  showInfoOn: 'hover' | 'click';
  className?: string;
  absoluteInfoPosition?: true;
}

const InfoCard = (props: IProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={classNames(
        props.className,
        'flex flex-col mb-px p-2 cursor-pointer group',
        'hover:bg-item-hover hover:dark:bg-item-dark-hover hover:dark:text-item-dark-contrast',
        {
          relative: props.absoluteInfoPosition,
        }
      )}
      onClick={props.onClick}
    >
      <Tooltip content={props.info.text}>
        <div className="flex gap-1 items-center" ref={containerRef}>
          <div>{props.cardContent}</div>
          <Button
            icon="Info"
            isMinimal
            isTransparent
            size={props.info.iconSize === 24 ? 'lg' : 'base'}
            onPress={() => {
              props.showInfoOn === 'click' && setIsExpanded(!isExpanded);
            }}
            onHoverStart={() => {
              props.showInfoOn === 'hover' && setIsExpanded(true);
            }}
            onHoverEnd={() => {
              props.showInfoOn === 'hover' && setIsExpanded(false);
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default InfoCard;
