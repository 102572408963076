import type { IOptimisationObjective } from './constants';

export const OBJECTIVES_UPDATE = 'OBJECTIVES_UPDATE';
export const OBJECTIVES_REMOVE = 'OBJECTIVES_REMOVE';

interface IOUpdateAction {
  type: typeof OBJECTIVES_UPDATE;
  objectives: IOptimisationObjective[];
}

interface IORemoveAction {
  type: typeof OBJECTIVES_REMOVE;
}

export type IObjectivesActionTypes = IOUpdateAction | IORemoveAction;
