import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import type { Subject } from '_api/subjects/types';

export type GetSubjectParams = {
  subject: string;
};

export const getSubject = apiFetchHandler<Subject, GetSubjectParams>({
  host: hosts.portal.v2,
  endpoint: ({ subject }) => `/subjects/${subject}`,
  method: 'GET',
  errorMessage: 'Could not get subject',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});
