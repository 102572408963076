import { useState } from 'react';
import { getBillingPortalURL } from '_api/orders/service';
import {
  Button,
  Input,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
  TextField,
} from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import type {
  Organisation,
  OrganisationJoinPolicy,
} from '_api/administration/types';
import { updateOrganisation } from '_api/organisations/service';

interface IProps {
  organisation?: Organisation;
  disableBilling: boolean;
  refetchOrganisation: () => void;
}

const AdministrationOrganisationDetails = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleManageBillingClick = async () => {
    if (!props.organisation) {
      return;
    }
    setLoading(true);
    const { data } = await getBillingPortalURL({
      params: {
        organisationId: props?.organisation.id.toString(),
      },
    });
    if (data?.portal_link) {
      window.open(data?.portal_link, '_blank');
    }
    setLoading(false);
  };

  return (
    <div>
      <h2 className="text-lg mt-6">Details</h2>
      <div className="pl-2">
        <h3 className="font-bold mt-4">Organisation discoverability</h3>
        <Select
          className="mt-2 w-full"
          label="Join policy"
          description="The join policy determines if users can discover the organisation and how they can join it."
          selectedKey={props.organisation?.join_policy}
          fill
          onSelectionChange={async (policy) => {
            const res = await updateOrganisation({
              params: {
                id: props.organisation?.id.toString()!,
              },
              body: {
                join_policy: policy as unknown as OrganisationJoinPolicy,
              },
            });

            if (res.status === 200) {
              props.refetchOrganisation();
            }
          }}
        >
          <Button icon="ChevronDown" iconPlacement="right" className="w-full">
            <SelectValue />
          </Button>
          <Popover
            className="shadow-lg bg-neutral-100 overflow-auto"
            maxHeight={250}
          >
            <ListBox>
              <ListBoxItem id="JOIN">
                Join - users on the same domain can join without approval
              </ListBoxItem>
              <ListBoxItem id="REQUEST">
                Request - users on the same domain can request to be approved to
                join
              </ListBoxItem>
              <ListBoxItem id="INVITE">
                Invite - users require an invite to see this organisation
              </ListBoxItem>
            </ListBox>
          </Popover>
        </Select>
        <TextField
          className="mt-2"
          label="Domain"
          value={props.organisation?.domain}
          isDisabled
        >
          <Input type="text" />
        </TextField>

        <h3 className="font-bold mt-4">Billing</h3>
        <p className="text-sm">
          Billing administrators can manage the billing details and list the
          invoices by clicking the button below.
        </p>
        <Button
          intent="secondary"
          icon="DollarSign"
          iconPlacement="left"
          size="sm"
          className="mt-2"
          onPress={handleManageBillingClick}
          isDisabled={loading && !props.disableBilling}
        >
          Go to billing page
          {loading && <Spinner size={'sm'} />}
        </Button>
      </div>
    </div>
  );
};

export default AdministrationOrganisationDetails;
