import classNames from 'classnames';
import React from 'react';
import type { IListItemProps } from '_atoms/ListItem/ListItem';

export enum ListOrientation {
  Horizontal,
  Vertical,
  HorizontalTwoColumns,
}

type IProps = {
  orientation: ListOrientation;
  children:
    | React.ReactElement<IListItemProps>
    | React.ReactElement<IListItemProps>[];
};

const UnorderedList = (props: IProps) => {
  return (
    <div className="text-sm">
      <ul
        className={classNames('gap-2', {
          'flex flex-col': props.orientation === ListOrientation.Vertical,
          flex: props.orientation === ListOrientation.Horizontal,
          '!shadow-none grid grid-flow-col w-32 grid-rows-2':
            props.orientation === ListOrientation.HorizontalTwoColumns,
        })}
      >
        {props.children}
      </ul>
    </div>
  );
};

export default UnorderedList;
