import { isDevMode } from 'utils/common/CommonUtils';

export const SATELLITE_ID_LIST_TEST = {
  ALISIO: '60',
  COGNISAT_6: '57',
  COGNISAT_6_EM: '67',
  GEOSAT_2: '41',
  MANTIS: '63',
  MENUT: '55',
  PERUSAT_1: 'PERUSAT-1',
  PHISAT2: '56',
  PHISAT2_EM: '68',
  PLATERO: '64',
  SENTINEL_2A: '70',
  SENTINEL_2B: '71',
  SENTINEL_2C: '72',
  SUPERVIEW_01: '51',
  SUPERVIEW_02: '52',
  SUPERVIEW_03: '53',
  SUPERVIEW_04: '54',
  JILIN_1_03_B: '42',
  JILIN_1_04_B: '43',
  JILIN_1_05_B: '44',
  JILIN_1_06_B: '45',
  JILIN_1_07_B: '46',
  JILIN_1_08_B: '47',
  JILIN_1_GaoFen_03_C_03: '50',
  MARGARET: '30',
} as const;

export type SatelliteIdTest =
  (typeof SATELLITE_ID_LIST_TEST)[keyof typeof SATELLITE_ID_LIST_TEST];

export const SATELLITE_ID_LIST_PROD = {
  ALISIO: '49',
  COGNISAT_6: '48',
  COGNISAT_6_EM: '56',
  GEOSAT_2: '30',
  MANTIS: '29',
  MENUT: '23',
  PERUSAT_1: 'PERUSAT-1',
  PHISAT2: '35',
  PHISAT2_EM: '51',
  PLATERO: '37',
  SENTINEL_2A: '87',
  SENTINEL_2B: '88',
  SENTINEL_2C: '89',
  SUPERVIEW_01: '31',
  SUPERVIEW_02: '32',
  SUPERVIEW_03: '33',
  SUPERVIEW_04: '34',
  MARGARET: '21',
} as const;

export type SatelliteIdProd =
  (typeof SATELLITE_ID_LIST_PROD)[keyof typeof SATELLITE_ID_LIST_PROD];

export type SatelliteId = SatelliteIdTest | SatelliteIdProd;

export const SATELLITE_ID_LIST = isDevMode()
  ? SATELLITE_ID_LIST_TEST
  : SATELLITE_ID_LIST_PROD;

export const SATELLITE_DATA_TYPE_LIST = [
  'Optical',
  'SAR',
  'Radiometric',
] as const;

export type SatelliteDataType = (typeof SATELLITE_DATA_TYPE_LIST)[number];

export type SATELLITE_BACKEND_API = 'OPEN_COSMOS';

export type Satellite = {
  satellite: SatelliteId;
  description: string;
  uiName: string;
  backend: SATELLITE_BACKEND_API;
  dataType: SatelliteDataType[];
  gsdData: number;
  COSPAR: string;
  taskable: boolean;
};

export type CondataSatellite = {
  description: string;
  uiName: string;
  gsdData: number;
  COSPAR: string;
};
