import type { CSSProperties } from 'react';
import classNames from 'classnames';

export type ColumnProps = {
  children: React.ReactNode;
  justify?: React.CSSProperties['justifyContent'];
  'data-testid'?: string;
  style?: CSSProperties;
  align?: CSSProperties['alignItems'];
};

/**
 * Stylized flex-column layout. Can be used on it's own, if a flex container with a column orientation is needed,
 * or in combination with ColumnLayout component
 */
export const Column = ({ children, justify, ...props }: ColumnProps) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-col gap-y-2 h-full bg-surface dark:bg-surface-dark',
        {
          'min-w-[350px]': props.style && !props.style.minWidth,
        }
      )}
      style={{ ...props.style, justifyContent: justify }}
      data-testid={props['data-testid']}
    >
      {children}
    </div>
  );
};
