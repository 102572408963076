import React, { useEffect } from 'react';
import Editor from '@monaco-editor/react';
import { IconNames } from '@blueprintjs/icons';

import type { IFolder } from 'services/api/ops/cloudStorage';
import { TelemetryPrinter } from 'utils/telemetryLogParser/printer';

import type { IModeProps, IMode } from '..';

const HEADER_SIZE = 16;

const parseNewFile = (file: Uint8Array | undefined): string => {
  if (!file) {
    return '';
  }

  let data = '';
  // Transform the base64 file into a uint8array
  const log_file = file;
  const view = new DataView(log_file.buffer);

  const printer = new TelemetryPrinter(view);

  data += `${printer.printHeader()}\n`;

  let point = printer.printNextPoint();
  while (point !== false) {
    data += `${point}\n`;
    point = printer.printNextPoint();
  }

  return data;
};

const TelemetryLog: IMode<string> = ({
  fileDetails,
  renderedContent,
  setRenderedContent,
}: IModeProps<string>) => {
  useEffect(() => {
    if (fileDetails) {
      const { file } = fileDetails;

      const newRenderedContent = parseNewFile(file);
      setRenderedContent(newRenderedContent);
    }
  }, [fileDetails, setRenderedContent]);

  return (
    <Editor
      options={{
        lineNumbersMinChars: 5,
        readOnly: true,
      }}
      value={renderedContent}
    />
  );
};

TelemetryLog.isValid = (fileDetails: IFolder) => {
  if (fileDetails?.size && fileDetails?.size < HEADER_SIZE) {
    // TODO: Check why fileDetails is null on the RTI and not in the library
    return false;
  }
  return true;
};

// TM files usually have the following form: /mission/2/tm/1/193/193_1_1_1573298098
const filePattern = /[0-9]+_[0-9]+_[0-9]+_[0-9]+/;

TelemetryLog.isPreferred = (fileDetails?: IFolder) => {
  return Boolean(fileDetails && filePattern.test(fileDetails.name));
};

// Not in use because it is not editable
TelemetryLog.saveContent = (_: string) => new Uint8Array();
TelemetryLog.isEditable = false;

TelemetryLog.icon = IconNames.HISTORY;

TelemetryLog.id = 'tm-log';

export default TelemetryLog;
