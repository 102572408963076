import type {
  DateRangePickerProps,
  DateValue,
  ValidationResult,
} from "react-aria-components";
import {
  Button,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DateRangePicker,
  DateSegment,
  Dialog,
  FieldError,
  Group,
  Heading,
  Label,
  Popover,
  RangeCalendar,
  Text,
} from "react-aria-components";
import { Icon } from "../../../icons/Icon";
import classNames from "classnames";
import { default as CustomBtn } from "../../../core/Button/Button";
interface MyDateRangePickerProps<T extends DateValue>
  extends DateRangePickerProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  showClearButton?: boolean;
}

const MyDateRangePicker = <T extends DateValue>({
  label,
  description,
  errorMessage,
  showClearButton = false,
  ...props
}: MyDateRangePickerProps<T>) => {
  return (
    <DateRangePicker
      {...props}
      className={
        "flex p-1 gap-1 text-sm border-2 border-item w-full focus-within:border-accent-400 focus-within:shadow-md"
      }
    >
      {({ state }) => {
        return (
          <>
            <Label>{label}</Label>
            <Group className={"flex items-center justify-between w-full"}>
              <div className="flex gap-1 overflow-hidden">
                <DateInput
                  slot="start"
                  className={"flex w-[inherit] overflow-ellipsis"}
                >
                  {(segment) => (
                    <DateSegment
                      className="text-xs flex items-center"
                      segment={segment}
                    />
                  )}
                </DateInput>
                <span aria-hidden="true">–</span>
                <DateInput slot="end" className="flex">
                  {(segment) => (
                    <DateSegment
                      className="text-xs flex items-center"
                      segment={segment}
                    />
                  )}
                </DateInput>
              </div>
              <div className="flex items-center">
                {showClearButton && (
                  <CustomBtn
                    slot={null}
                    onPress={() => {
                      state.setValue(null);
                    }}
                    icon="Cross"
                    isTransparent={true}
                    isMinimal={true}
                  />
                )}
                <Button>
                  <Icon
                    icon="ChevronDown"
                    className="stroke-item-contrast dark:stroke-item-dark-contrast"
                  />
                </Button>
              </div>
            </Group>
            {description && <Text slot="description">{description}</Text>}
            <FieldError>{errorMessage}</FieldError>
            <Popover
              className={
                "!z-[9999999] bg-surface dark:bg-surface-dark dark:text-item-dark-contrast absolute border-2 border-item"
              }
            >
              <Dialog className="p-2">
                <RangeCalendar>
                  <header className="flex justify-between p-1">
                    <Button slot="previous">
                      <Icon icon="ChevronLeft" />
                    </Button>
                    <Heading className="text-sm font-bold" />
                    <Button slot="next">
                      <Icon icon="ChevronRight" />
                    </Button>
                  </header>
                  <CalendarGrid className="text-center">
                    {(date) => (
                      <CalendarCell
                        date={date}
                        className={({ isSelected }) =>
                          classNames(
                            "w-[26px] h-fit max-h-20 min-h-full min-w-full p-1 flex flex-col text-[12px] hover:text-accent-900 dark:hover:text-accent hover:bg-item-selected dark:hover:bg-item-dark-selected aria-disabled:text-contrast-inactive aria-disabled:opacity-50 aria-disabled:pointer-events-none",
                            {
                              "bg-item-selected dark:bg-item-dark-selected":
                                isSelected,
                            }
                          )
                        }
                      />
                    )}
                  </CalendarGrid>
                </RangeCalendar>
              </Dialog>
            </Popover>
          </>
        );
      }}
    </DateRangePicker>
  );
};

export default MyDateRangePicker;
