import React, { useContext, useReducer } from 'react';
import type { IOperateState } from 'reducers/operate';
import operateReducer from 'reducers/operate';
import type { IOperateActionTypes } from 'constants/ops/rti/oparate/actionTypes';

export interface IProps {
  children: JSX.Element;
}

export interface IOperateContext {
  state: IOperateState;
  dispatch: (action: IOperateActionTypes) => void;
}

const initialState: IOperateState = {
  frozenPile: true,
  procedureName: 'Manual',
  commandPayload: {},
  isSendCommandDisabled: false,
  commandPileWorkingSpace: [],
  isCommandPreview: false,
  isCommandChanging: false,
  exploringStorageName: '',
  areCommandsFetching: true,
  cloudStorageSavedPath: '/',
  isLeftSidePanelHidden: false,
  isRightSidePanelHidden: true,
  isCommandHistoryCollapsed: false,
  shouldFocusLastSentCommand: true,
};

export const OperateContext = React.createContext<IOperateContext>(
  null as unknown as IOperateContext
);
export const useOperate = () => useContext<IOperateContext>(OperateContext);

export const OperateProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(operateReducer, initialState);

  return (
    <OperateContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </OperateContext.Provider>
  );
};
