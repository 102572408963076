import React from 'react';
import { isUndefined } from 'lodash/fp';
import { HTMLSelect } from '@blueprintjs/core';

export interface IProps {
  canSetDefaultValue: boolean;
  fullPath: string;
  value: string;
  disabled: boolean;
  enumValue: string[];
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  setTooltipOpen: (open: boolean) => void;
  className?: string;
}

const CommandStringEnumInput = (props: IProps) => {
  const {
    value,
    onChange,
    fullPath,
    enumValue,
    disabled,
    canSetDefaultValue,
    handleBlur,
    handleFocus,
    className,
  } = props;

  if (!value && canSetDefaultValue && !isUndefined(canSetDefaultValue)) {
    onChange(enumValue[0], fullPath);
  }

  return (
    <HTMLSelect
      fill
      disabled={disabled}
      value={value}
      onChange={(e) => {
        onChange(e.target.value, fullPath);
      }}
      className={className}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onMouseOut={() => {
        if (disabled) {
          return;
        }

        handleBlur();
      }}
    >
      {!value && <option value={undefined}>Select</option>}
      {enumValue.map((item: string) => (
        <option key={item}>{item}</option>
      ))}
    </HTMLSelect>
  );
};

export default CommandStringEnumInput;
