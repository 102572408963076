import React from 'react';
import { Button, Icon, Intent } from '@blueprintjs/core';
import type { IconName } from '@blueprintjs/icons';
import { IconNames } from '@blueprintjs/icons';
import TagHeader from '../../../../../../components/common/TagHeader';
import { preventDefaultPropagation } from '../../../../../../utils/common/CommonUtils';
import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode;
  tagTitle: string;
  tagIcon: IconName;
  tagContent?: React.ReactNode;
  expanded?: boolean | undefined;
  collapsedCount: number;
  maxHeight?: number;
  handleItemCollapse: (tagIcon: string) => void;
  handleRefreshClick: (() => void) | undefined;
}

const CollapseLibrary = (props: IProps) => {
  const { sendInfo } = useAnalytics();
  const handleCollapsedItem = () => {
    sendInfo({
      type: `Collapse library ${props.tagTitle}`,
      action: 'Click',
      item: 'Collapse library button',
      module: 'OPS',
      additionalParams: {
        collapsedTab: props.tagTitle,
      },
    });
    props.handleItemCollapse(props.tagIcon);
  };

  return (
    <div
      className={classNames({
        [s.libraryContainer]: props.expanded,
      })}
      style={{ maxHeight: props.maxHeight }}
    >
      <TagHeader
        className={s.folderTag}
        icon={props.tagIcon}
        intent={Intent.PRIMARY}
        onClick={handleCollapsedItem}
        title={
          <div className={s.folderTagBody}>
            <span className={s.folderTagBodyTitle}>{props.tagTitle}</span>
            <span>{props.tagContent}</span>
            <div className={s.folderTagBodyItemContainer}>
              {props.handleRefreshClick && (
                <Button
                  small
                  minimal
                  icon={IconNames.REFRESH}
                  onClick={(e) => {
                    sendInfo({
                      type: 'Refresh library',
                      action: 'Click',
                      item: 'Refresh library button',
                      module: 'OPS',
                      additionalParams: {
                        refreshedTab: props.tagTitle,
                      },
                    });
                    preventDefaultPropagation(props.handleRefreshClick)()(e);
                  }}
                />
              )}
              <Icon
                icon={
                  props.expanded
                    ? IconNames.CHEVRON_DOWN
                    : IconNames.CHEVRON_LEFT
                }
              />
            </div>
          </div>
        }
      />
      <div
        className={props.expanded ? s.libraryContents : s.libraryContentsHidden}
      >
        {props.children}
      </div>
    </div>
  );
};

export default CollapseLibrary;
