import { PERMISSION_TYPE_LIST } from '_api/administration/permissions';
import type { PermissionType } from '_api/administration/permissions';
import type { IRole } from 'services/api/portal/administration/api/types';
import ResourceAndRoleAllocation from '../../../../shared/components/ResourceAndRoleAllocation';
import { toaster } from 'toaster';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import { useState } from 'react';
interface IProps {
  getAvailableRolesByType: (type: PermissionType) => IRole[];
  setResourceId: (resourceId: string | number) => void;
  setSelectedPermission: (permission: PermissionType) => void;
  selectedPermission?: PermissionType;
  resourceId: string | number;
  assignRole: (roleId: number) => Promise<void>;
}

const AddRoleAttachment = ({
  getAvailableRolesByType,
  setResourceId,
  setSelectedPermission,
  selectedPermission,
  resourceId,
  assignRole,
}: IProps) => {
  const [selectedRole, setSelectedRole] = useState<IRole | null | undefined>(
    null
  );

  const permissionTypes = PERMISSION_TYPE_LIST as unknown as PermissionType[];

  const handleAdd = async () => {
    if (selectedPermission !== 'global' && resourceId === '') {
      toaster.show({
        message: clientTranslate(
          'userPage.assignedPermissions.errors.notEmpty'
        ),
        intent: 'danger',
        icon: 'error',
      });
      return;
    }

    if (!selectedRole) {
      toaster.show({
        message: clientTranslate('userPage.assignedPermissions.selectARole'),
        intent: 'danger',
        icon: 'error',
      });
      return;
    }

    await assignRole(selectedRole.id);
    toaster.show({
      message: clientTranslate('userPage.assignedPermissions.success.added'),
      intent: 'success',
    });
    return;
  };

  return (
    <div>
      <ResourceAndRoleAllocation
        getAvailableRolesByType={getAvailableRolesByType}
        setResourceId={setResourceId}
        setSelectedPermission={setSelectedPermission}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
        selectedPermission={selectedPermission}
        resourceId={resourceId}
        handleAdd={handleAdd}
        permissionTypes={permissionTypes}
      />
    </div>
  );
};

export default AddRoleAttachment;
