import { hosts } from '_api/hosts';
import type {
  MissionConfigurationData,
  PassSlot,
} from '_api/groundStation/types';
import { apiFetchHandler } from '_api/clientFetch';

export * from '_api/groundStation/types';

export type GetGroundStationsOpportunitiesParams = {
  missionId: number;
  start: string;
  end: string;
  sites: string;
};

export const getGroundStationsOpportunities = apiFetchHandler<
  PassSlot[],
  GetGroundStationsOpportunitiesParams
>({
  host: hosts.portal.v0,
  endpoint: ({ missionId, start, end, sites }) =>
    `/gs/mission/${missionId}/opportunities?start=${start}&end=${end}&sites=${sites}`,
  method: 'GET',
  errorMessage: 'Could not get ground station opportunities',
  errorDescription: (e) => e.join('; '),
});

export type GetGroundStationsParams = {
  missionId: number;
};

export const getGroundStations = apiFetchHandler<
  MissionConfigurationData,
  GetGroundStationsParams
>({
  host: hosts.portal.v0,
  endpoint: ({ missionId }) => `/gs/mission/${missionId}`,
  method: 'GET',
  errorMessage: 'Could not get ground stations',
  errorDescription: (e) => e.join('; '),
});
