import useStacInfo from '../../utils/hooks/useStacInfo';
import type { StacItem } from 'datacosmos/types/stac-types';
import utils from 'datacosmos/utils/utils';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { QAButton } from './QAButton';

type TooltipContentProps = {
  data: StacItem;
  hasQAPermission?: boolean;
};

export const TooltipContent = ({
  data,
  hasQAPermission,
}: TooltipContentProps) => {
  const { observationZenithAngle, sensors } = useStacInfo(data);

  const { translate } = useLocalisation();

  return (
    <ul className="flex flex-col gap-2 dark:text-item-dark-contrast">
      <li className="flex items-center gap-2">
        <span>{translate('datacosmos.catalogAndItems.metadata.oza')} </span>
        <span>{observationZenithAngle}</span>
      </li>
      <li className="flex items-center gap-2">
        <span>{translate('datacosmos.catalogAndItems.metadata.sensors')} </span>
        <span>{sensors}</span>
      </li>
      <li className="flex items-center gap-2">
        <span>
          {translate('datacosmos.catalogAndItems.metadata.resolution')}{' '}
        </span>
        <span>
          {data.properties.gsd ? utils.gsdToUnit(data.properties.gsd) : '-'}
        </span>
      </li>
      {hasQAPermission && (
        <li>
          <QAButton item={data} />
        </li>
      )}
    </ul>
  );
};
