import React from 'react';

import { AlertsProvider } from 'services/api/telemetry/AlertsProvider';
import { TelemetryDefinitionsProvider } from 'services/api/telemetry/TelemetryDefinitionsProvider';

import LibraryAlerts from '../LibraryAlerts/LibraryAlerts';
import LibraryTelemetryDefinitions from './LibraryTelemetryDefinitions';

import s from './index.module.scss';

const LibraryTelemetry = () => {
  return (
    <AlertsProvider>
      <TelemetryDefinitionsProvider>
        <div className={s.libraryContainer}>
          <LibraryTelemetryDefinitions connectWithAlerts />
          <LibraryAlerts />
        </div>
      </TelemetryDefinitionsProvider>
    </AlertsProvider>
  );
};

export default LibraryTelemetry;
