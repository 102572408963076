import type { ChangeEvent, FormEvent } from 'react';
import React, { useEffect, useState } from 'react';
import {
  Button,
  ControlGroup,
  FormGroup,
  H3,
  InputGroup,
  Pre,
  Spinner,
} from '@blueprintjs/core';
import {
  DRAGGABLE,
  DRAGGABLE_HEADER,
} from '../../constants/draggable/constants';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { dragElement } from '../../services/draggableService/dragElement';
import API from '../../services/api/msd/commonMsdApi';
import type { IOSMFetchResult } from '../../declarations/osm';
import { convertGeoJSONtoPaths } from '../../utils/OSM/convertGeoJSONtoPaths';
import { isAreaEnabled } from '../../utils/OSM/isAreaEnabled';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import type { IPointOfInterest } from '../../constants/pointsOfInterest/actionTypes';
import type { IRegionOfInterest } from '../../constants/regionsOfInterest/actionTypes';
import { useStore } from 'react-redux';
import { generateId } from '../../utils/common/generateId';
import type { AppState } from '../../store';
import { showErrorMessage } from '../../utils/common/CommonUtils';
import { FocusTypes } from '../../constants/focus/actionTypes';

const ALLOWED_OSM_CLASSES = ['boundary', 'waterway', 'place', 'natural'];

interface IFetchRegionProps extends WithTranslation {
  secondProps: IPopUp;
  resetPopUp: Function;
  onSubmit: Function;
  onClose: Function;
  type: string;
}

const renderByType = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  data: IPointOfInterest | IRegionOfInterest;
  functions: Record<string, Function>;
  otherProps: Record<string, unknown>;
  onBack: Function;
  t: Function;
  resetPopUp: Function;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { component: Component, data, functions, otherProps, onBack } = props;
  if (Component) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const initialValues = { ...data };
    return (
      <Component
        {...otherProps}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        initialValues={initialValues}
        {...functions}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        onBack={onBack}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        resetPopUp={props.resetPopUp}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        t={props.t}
      />
    );
  }
  return null;
};

const AddressField = (props: IFetchRegionProps) => {
  useEffect(() => dragElement(document.getElementById(DRAGGABLE)!), []);

  const [address, setAddress] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [initData, setInitData] = useState(
    null as unknown as IPointOfInterest | IRegionOfInterest
  );
  const [geocodeData, setGeocodeData] = useState(
    undefined as unknown as IOSMFetchResult[]
  );
  const reduxStore = useStore();

  const handleOnChange: React.FormEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAddress(event.target.value);
  };
  const handleOnClick = async (event: FormEvent) => {
    event.preventDefault();
    const params = {
      q: address,
      limit: 50,
      format: 'json',

      /* eslint-disable */
      polygon_geojson: 1,
      polygon_threshold: 0.3,
      /* eslint-enable */
    };
    setLoading(true);
    try {
      const res: IOSMFetchResult[] = (await API.fetch.get({
        params,
      })) as IOSMFetchResult[];
      const filteredResults = res
        .filter((o: IOSMFetchResult) => ALLOWED_OSM_CLASSES.includes(o.class))
        .filter((o: IOSMFetchResult) => {
          switch (props.type) {
            case 'Region': {
              const geojson = o.geojson;
              const isCorrectType =
                geojson && ['Polygon', 'MultiPolygon'].includes(geojson.type);
              if (!isCorrectType) {
                return false;
              }

              const paths = convertGeoJSONtoPaths(o);
              return paths?.find((path) => isAreaEnabled(path));
            }
            default: {
              return true;
            }
          }
        });
      setGeocodeData(filteredResults);
    } catch (e) {
      showErrorMessage('Something went wrong, please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSetInitData = (res: IOSMFetchResult) => {
    switch (props.type) {
      case 'Region': {
        const id = generateId(FocusTypes.RI_SUB_ID);
        props.onSubmit(res, id);
        // @ts-expect-error
        const state: AppState = reduxStore.getState();
        const regionOfInterest = state.regionsOfInterest.list.find(
          (p) => p.id === id
        );
        if (regionOfInterest) {
          setInitData(regionOfInterest);
        }
        break;
      }
      case 'Point': {
        const id = generateId(FocusTypes.PI_SUB_ID);
        props.onSubmit(res, id);
        // @ts-expect-error
        const state: AppState = reduxStore.getState();
        const pointOfInterest = state.pointsOfInterest.list.find(
          (p) => p.id === id
        );
        if (pointOfInterest) {
          setInitData(pointOfInterest);
        }
        break;
      }
    }
  };

  const handleOnBack = () => {
    props.secondProps.functions?.onClose?.(initData);
    setInitData(undefined as unknown as IPointOfInterest | IRegionOfInterest);
  };
  if (initData) {
    //@ts-expect-error
    return renderByType({
      ...props.secondProps,
      onBack: handleOnBack,
      t: props.t,
      resetPopUp: props.resetPopUp,
      data: initData,
    });
  } else {
    return (
      <form
        className={initData ? `hidden` : ''}
        onSubmit={handleOnClick}
        key={props.type}
      >
        <div className="bp4-dialog dialog-margin-set-out">
          <div id={DRAGGABLE_HEADER} className="bp4-dialog-header">
            <H3>{`Fetch ${props.type}`}</H3>
            <button
              type="button"
              className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
          <div className="bp4-dialog-body">
            <FormGroup label="Address:" labelFor="address">
              <InputGroup
                id="address"
                placeholder={'Address'}
                onChange={handleOnChange}
                rightElement={
                  <Button
                    type={'submit'}
                    text={isLoading ? <Spinner size={5} /> : 'Find'}
                    intent={'primary'}
                  />
                }
              />
            </FormGroup>
            {geocodeData?.length ? (
              <Pre>
                <div className="scrolled-body">
                  {geocodeData.map((g: IOSMFetchResult) => (
                    <ControlGroup
                      className="address-object"
                      fill={true}
                      key={JSON.stringify({
                        name: g.display_name,
                        type: g.type,
                      })}
                    >
                      <div className="bp4-input-group text-field">
                        {g.icon ? (
                          <img
                            className="bp4-icon"
                            alt={g.type}
                            title={g.type}
                            src={g.icon}
                          />
                        ) : (
                          <span
                            title={g.type ? g.type : 'unknown'}
                            className="bp4-icon bp4-icon-geosearch"
                          />
                        )}
                        <div title={g.display_name} className="bp4-input text">
                          {g.display_name}
                        </div>
                      </div>
                      <Button
                        type="submit"
                        className="button"
                        text={'Load'}
                        intent={'success'}
                        onClick={() => handleSetInitData(g)}
                      />
                    </ControlGroup>
                  ))}
                </div>
              </Pre>
            ) : null}
            {address && geocodeData ? (
              <Pre>Your search did not match any results.</Pre>
            ) : null}
          </div>
        </div>
      </form>
    );
  }
};

export default withTranslation()(AddressField);
