import type { StacItem } from 'datacosmos/types/stac-types';
import type { BBox } from 'rbush';
import RBush from 'rbush';

/**
 * transformRBushBBox transforms a GeoJSON BBox into an RBush BBox
 */
export function transformRBushBBox(bbox: GeoJSON.BBox): BBox {
  return { minX: bbox[0], minY: bbox[1], maxX: bbox[2], maxY: bbox[3] };
}

/**
 * ItemBush extends RBush to use StacItems directly
 */
export class ItemBush extends RBush<StacItem> {
  toBBox(item: StacItem) {
    return transformRBushBBox(item.bbox);
  }
  compareMinX(a: StacItem, b: StacItem) {
    return a.bbox[0] - b.bbox[0];
  }
  compareMinY(a: StacItem, b: StacItem) {
    return a.bbox[1] - b.bbox[1];
  }
}
