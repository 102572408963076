import React from 'react';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';

interface IProps {
  disabled: boolean;
  value: number;
  onChange: (newValue: number) => void;
}

/**
 * OpacityControl slider ranging from 0 to 1 that is shown when the user clicks
 * on an opacity button.
 */
const OpacityControl = ({ disabled, value, onChange }: IProps) => {
  if (isNaN(value)) return null;
  return (
    <RangeSlider
      minValue={0}
      maxValue={1}
      step={0.05}
      numberOfHandles={1}
      onChange={(values) => {
        onChange(values[0]);
      }}
      showValuesAboveHandles
      value={[value]}
      showScale={false}
      isDisabled={disabled}
    />
  );
};

export default OpacityControl;
