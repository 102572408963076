import type { IPayloadActionTypes } from 'constants/satellite/actionTypes';
import {
  ADD_SATELLITE_OPERATIONAL_MODE,
  REMOVE_SATELLITE_OPERATIONAL_MODE,
  UPDATE_SATELLITE_OPERATIONAL_MODE,
  UPDATE_MODULE_SELECTION,
  UPDATE_SELECTED_SATELLITE_PAYLOAD,
  UPDATE_SELECTED_SATELLITE_PLATFORM,
  UPLOAD_SATELLITE_CONFIGURATION,
} from 'constants/satellite/actionTypes';
import { showInfo } from 'services/mapService/alert/services';
import type {
  ISatelliteModeConfiguration,
  ISatelliteModules,
} from 'constants/satellite/types';
import {
  PAYLOAD_DATA,
  POWER_DATA,
  EPS_DATA,
  COMMS_DATA,
  AOCS_DATA,
  OBDH_DATA,
} from 'constants/moduleData/constants';

const initialState: IPayloadState = {
  moduleNames: {
    [PAYLOAD_DATA]: [],
    [POWER_DATA]: ['Battery Pack 1P4S'],
    [EPS_DATA]: ['Open Cosmos EPS v4'],
    [COMMS_DATA]: ['Open Cosmos COMMS v1'],
    [AOCS_DATA]: ['Open Cosmos ADCS v1'],
    [OBDH_DATA]: ['Open Cosmos OBDH v1'],
  },
  currentModeConfigurations: {},
};

export interface IPayloadState {
  moduleNames: ISatelliteModules;
  currentModeConfigurations: ISatelliteModeConfiguration;
}

export default function payloadReducer(
  state: IPayloadState = initialState,
  action: IPayloadActionTypes
): IPayloadState {
  switch (action.type) {
    case UPDATE_SELECTED_SATELLITE_PAYLOAD: {
      const newlySelectedNames = action.names;
      const newModulesNames = {
        ...state.moduleNames,
        [PAYLOAD_DATA]: newlySelectedNames,
      };

      return {
        ...state,
        moduleNames: newModulesNames,
      };
    }

    case UPDATE_SELECTED_SATELLITE_PLATFORM: {
      return {
        ...state,
        moduleNames: {
          ...state.moduleNames,
          ...action.names,
        },
      };
    }

    case UPDATE_MODULE_SELECTION: {
      const newlySelectedNames = action.selectedNames;
      const newModulesNames = {
        ...state.moduleNames,
        [action.moduleType]: newlySelectedNames,
      };

      // For all other modules
      return {
        ...state,
        moduleNames: newModulesNames,
      };
    }

    // Creating, editing, and removing operational modes in Modes page
    case ADD_SATELLITE_OPERATIONAL_MODE: {
      // Removing name from mode values
      const payloadModes = { ...action.values };
      //@ts-expect-error
      delete payloadModes.name;

      return {
        ...state,
        currentModeConfigurations: {
          ...state.currentModeConfigurations,
          [action.values.name]: payloadModes,
        },
      };
    }
    case UPDATE_SATELLITE_OPERATIONAL_MODE: {
      // Removing name from mode values
      const changedPayloadModes = { ...action.values };
      //@ts-expect-error
      delete changedPayloadModes.name;

      // Removing previous mode in case name changed
      const newModeConfigurations = state.currentModeConfigurations;
      delete newModeConfigurations[action.values.oldName];

      return {
        ...state,
        currentModeConfigurations: {
          ...state.currentModeConfigurations,
          [action.values.name]: changedPayloadModes,
        },
      };
    }
    case REMOVE_SATELLITE_OPERATIONAL_MODE: {
      const newCurrentModeConfigurations = {
        ...state.currentModeConfigurations,
      };
      delete newCurrentModeConfigurations[action.name];

      const POIsByThisMode = action.POIs.filter(
        (POI) => POI.satelliteMode && POI.satelliteMode === action.name
      );

      const ROIsByThisMode = action.ROIs.filter(
        (ROI) => ROI.satelliteMode && ROI.satelliteMode === action.name
      );

      const cantDelete = POIsByThisMode.length || ROIsByThisMode.length;

      if (cantDelete) {
        const objectNamesJoined = POIsByThisMode.map((object) => object.name)
          .concat(ROIsByThisMode.map((object) => object.name))
          .join(', ');

        const objectNameText = cantDelete ? objectNamesJoined.concat(' ') : '';

        showInfo(
          `The object(s) ${objectNameText} are still using this mode. Please choose a different mode for them before deleting this one.`
        );
        return state;
      }

      return {
        ...state,
        currentModeConfigurations: newCurrentModeConfigurations,
      };
    }

    case UPLOAD_SATELLITE_CONFIGURATION: {
      return action.sallite as IPayloadState;
    }

    default: {
      return state;
    }
  }
}
