import type { IPopoverProps } from '_atoms/Popover/Popover';
import Popover, { Direction } from '_atoms/Popover/Popover';
import React from 'react';
import classNames from 'classnames';
import { Icon } from 'opencosmos-ui';
import type { IconName, IconSize } from 'opencosmos-ui/src/icons/Icon';

interface IProps<T> extends IPopoverProps {
  children: React.ReactNode;
  items: T[];
  itemRenderer: (item: T, handleClick: (item: T) => void) => JSX.Element | null;
  handleClick: (item: T) => void;
  noBg?: true;
  iconProps?: {
    size?: IconSize;
    icon?: IconName;
  };
  chevronPosition?: 'left' | 'right';
  className?: string;
}

/**
 *
 * Generic Select component wrapped with popover
 * To specify the item type used in the dropdown, initialize the component the following way:
 * @example <Select<string> {...props}>...</Select>
 */
const Select = <T,>(props: IProps<T>) => {
  const {
    items,
    itemRenderer,
    handleClick,
    disabled,
    chevronPosition = 'left',
  } = props;
  return (
    <Popover
      content={
        <div>{items.map((item) => itemRenderer(item, handleClick))}</div>
      }
      direction={Direction.BottomRight}
      {...props}
      className={`p-px bg-item dark:bg-surface-dark dark:text-item-dark-contrast max-h-72 overflow-auto outline-none`}
      disabled={disabled}
      closeOnContentClick
    >
      <div
        className={classNames(
          props.className,
          'w-full flex items-center gap-2 p-1',
          {
            'color-item': !props.noBg,
          }
        )}
      >
        {chevronPosition === 'left' && (
          <Icon
            icon={props.iconProps?.icon ? props.iconProps.icon : 'ChevronDown'}
            size={props.iconProps?.size ? props.iconProps.size : 16}
            className={classNames({
              'stroke-contrast-inactive': disabled,
              'stroke-item-contrast': !disabled,
            })}
          />
        )}

        {props.children}

        {chevronPosition === 'right' && (
          <Icon
            icon={props.iconProps?.icon ? props.iconProps.icon : 'ChevronDown'}
            size={props.iconProps?.size ? props.iconProps.size : 16}
            className={classNames({
              'stroke-contrast-inactive': disabled,
              'stroke-item-contrast': !disabled,
            })}
          />
        )}
      </div>
    </Popover>
  );
};

export default Select;
