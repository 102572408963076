import { assoc, find } from 'lodash/fp';
import { Button, ButtonGroup, HTMLSelect, Intent } from '@blueprintjs/core';
import s from './index.module.scss';

import { v1 as uuid } from 'uuid';
import CommandArguments from '../../../RTI/Operate/components/CommandArguments';
import type {
  RadioTransformer,
  Schema,
  TransformerBody,
} from '_api/gateway/types';
import { Tooltip } from 'opencosmos-ui';

interface IProps {
  defaultSelect: string;
  schemas: Schema[];
  cancelCreation: () => void;
  handleSaveClick: () => void;
  handleNewItemChange: (item: TransformerBody) => void;
  payload?: RadioTransformer;
}

const LibraryNewTransformer = (props: IProps) => {
  const currentTransformer = props.payload?.transformer;

  const handlePayloadChange = (path: string, value: unknown) => {
    if (!props.payload) {
      return;
    }

    const newPayload = assoc(`options.${path}`, value, props.payload);
    if (!newPayload) {
      return;
    }

    props.handleNewItemChange({
      ...newPayload,
    });
  };

  const handleTransformerChange = (transformer: string) => {
    props.handleNewItemChange({
      transformer: transformer,
      options: {},
      id: uuid(),
    });
  };

  return (
    <div className={s.libraryNewTransformer}>
      <div className={s.libraryNewTransformerWrapper}>
        <HTMLSelect
          value={currentTransformer ?? props.defaultSelect}
          onChange={(e) => handleTransformerChange(e.target.value)}
        >
          <option disabled>{props.defaultSelect}</option>
          {props.schemas?.map((it: Schema) => (
            <option key={it.name}>{it.name}</option>
          ))}
        </HTMLSelect>
        <ButtonGroup>
          <Tooltip
            content={
              props.payload?.transformer
                ? 'Confirm adding transformer'
                : 'Select a transformer'
            }
          >
            <Button
              icon="confirm"
              intent={Intent.PRIMARY}
              onClick={props.handleSaveClick}
              disabled={!props.payload?.transformer}
            />
          </Tooltip>
          <Tooltip content="Cancel adding transformer">
            <Button icon="undo" onClick={props.cancelCreation} />
          </Tooltip>
        </ButtonGroup>
      </div>
      {props.schemas && currentTransformer && (
        <CommandArguments
          commandProperties={
            find(['name', currentTransformer], props.schemas)?.schema
          }
          commandPayload={props.payload?.options}
          handleCommandPayloadChange={handlePayloadChange}
          handleCommandPayloadBlur={() => {}}
        />
      )}
    </div>
  );
};

export default LibraryNewTransformer;
