import { useRef } from 'react';
import { useCalendar, useLocale, VisuallyHidden } from 'react-aria';
import { useCalendarState } from 'react-stately';
import type { CalendarDate } from '@internationalized/date';
import { createCalendar, parseDate } from '@internationalized/date';
import type { CalendarStateOptions } from '@react-stately/calendar';
import moment from 'moment';
import CalendarGrid from '_molecules/Calendars/Common/CalendarGrid';
import { getMonthName } from 'utils/common/dateUtils';
import { Button } from 'opencosmos-ui';

export type CellItemsByDate = {
  id: string | number;
  date: Date;
  item: React.ReactNode;
};

interface ICalendarProps
  extends Omit<Omit<CalendarStateOptions, 'locale'>, 'createCalendar'> {
  highligtedDates: Date[];
  cellItemsByDate?: CellItemsByDate[];
  onMonthChange?: (date: Date) => void;
}

const Calendar = ({ onMonthChange, ...props }: ICalendarProps) => {
  const { locale } = useLocale();

  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
    visibleDuration: { months: 1 },
  });

  const ref = useRef<HTMLDivElement>(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state
  );
  let parsedHighlightedDates: CalendarDate[] = [];
  try {
    parsedHighlightedDates = props.highligtedDates.map((d) =>
      parseDate(moment(d).format('YYYY-MM-DD'))
    );
  } catch (error) {
    parsedHighlightedDates = [];
  }

  return (
    <div {...calendarProps} ref={ref} className="h-full">
      <div>
        <div className="flex justify-between py-2 border-b-[1px] border-b-contrast-inactive text-sm">
          <VisuallyHidden>
            <h2>{calendarProps['aria-label']}</h2>
          </VisuallyHidden>
          <div className="flex justify-between w-full">
            <Button
              onPress={(event) => {
                prevButtonProps.onPress?.(event);

                onMonthChange?.(
                  moment(state.visibleRange.start.toDate('Etc/Utc'))
                    .subtract(1, 'month')
                    .toDate()
                );
              }}
              icon="ChevronLeft"
              size="lg"
              data-testid="btn-prev"
              isMinimal
              isTransparent
            />
            <span aria-hidden>
              {getMonthName(state.visibleRange.start.month, locale)},{' '}
              {state.visibleRange.start.year}
            </span>
            <Button
              onPress={(event) => {
                nextButtonProps.onPress?.(event);
                onMonthChange?.(
                  moment(state.visibleRange.start.toDate('Etc/Utc'))
                    .add(1, 'month')
                    .toDate()
                );
              }}
              icon="ChevronRight"
              size="lg"
              data-testid="btn-next"
              isMinimal
              isTransparent
            />
          </div>
        </div>
      </div>
      <div className="flex m-1 h-full">
        <CalendarGrid
          state={state}
          highlightedDates={parsedHighlightedDates}
          cellItemsByDate={props.cellItemsByDate}
        />
      </div>
    </div>
  );
};

export default Calendar;
