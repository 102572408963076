import { useState, useMemo, useCallback } from 'react';
import { head } from 'lodash/fp';
import { useAuth } from 'services/auth/AuthWrapper';
import userApi from 'services/api/portal/administration/api/userApi';
import type { IUser } from 'services/api/portal/administration/api/types';
import { BACKEND_BASE_URL } from 'env';
import { getUser } from '_api/users/service';

const PORTAL_URL = `${BACKEND_BASE_URL}/portal/v0`;

const useUser = () => {
  const [isFetching, setFetching] = useState(false);
  const [user, setUser] = useState<IUser | undefined>();
  const { token } = useAuth();
  const userInstance = useMemo(() => userApi(PORTAL_URL, token), [token]);

  const getUsersByEmail = useCallback(
    async (userEmail: string) => {
      setFetching(true);
      const data = await userInstance.getUsersByEmail(userEmail);
      setUser(head(data) ?? undefined);
      setFetching(false);
      return data;
    },
    [userInstance]
  );

  const getUsersById = useCallback(async (userId: string) => {
    setFetching(true);
    const { data } = await getUser({ params: { subject: userId } });
    setUser(data);
    setFetching(false);
    return data;
  }, []);

  return {
    isFetching,
    user,
    getUsersByEmail,
    getUsersById,
  };
};

export default useUser;
