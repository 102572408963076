import React from 'react';
import { Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import s from './index.module.scss';

interface IProps {
  message: string;
  width: number;
}

const ErrorMessage = (props: IProps) => (
  <div
    className={[
      Classes.CALLOUT,
      Classes.INTENT_DANGER,
      s.errorMessageContainer,
    ].join(' ')}
    style={{ width: props.width }}
  >
    <Icon icon={IconNames.ERROR} />
    {props.message}
  </div>
);

export default ErrorMessage;
