import {
  useProjects,
  type ScenarioWithPermission,
} from 'datacosmos/stores/ProjectProvider';
import DatacosmosSelectDialog, {
  type DialogItemDetailsRenderer,
  type DialogListRenderer,
} from './DatacosmosSelectDialog/DatacosmosSelectDialog';
import { AddScenarioModal } from './AddScenarioModal';
import { useState } from 'react';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import ProjectDetails from './Header/ProjectDetails';
import type { Scenario } from '_api/scenarios/types';
import { rerouteHandler } from 'datacosmos/utils/useRerouteHandler';
import { useHistory } from 'react-router';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useAuth } from 'services/auth/AuthWrapper';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { EditScenarioModal } from './EditScenarioModal';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface ManageScenarioModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  canEscapeKeyClose: boolean;
  isCloseButtonShown: boolean;
  canOutsideClickClose: boolean;
  fromProjectRedirect?: boolean;
}
const ManageScenarioModal = ({
  isOpen,
  setIsOpen,
  title,
  canEscapeKeyClose,
  isCloseButtonShown,
  fromProjectRedirect,
  canOutsideClickClose,
}: ManageScenarioModalProps) => {
  const history = useHistory();
  const { sendInfo } = useAnalytics();
  const { translate } = useLocalisation();

  const { user } = useAuth();
  const [currentProject, setCurrentProject] = useLocalStorage<
    Scenario | undefined
  >('currentProject', undefined);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const [selectedScenario, setSelectedScenario] =
    useState<ScenarioWithPermission>();

  const {
    setCurrentScenario,
    userScenarios,
    setScenario,
    loadUserScenarios,
    isLoading,
  } = useProjects();

  const handleEditProject = () => {
    setIsEditModalOpen(true);
    setIsOpen(false);
  };

  const handleScenarioSelection = (item?: ScenarioWithPermission) => {
    if (item) {
      setSelectedScenario(item);
    }
  };

  const renderProjects: DialogListRenderer<ScenarioWithPermission> = (
    item,
    select
  ) => {
    return (
      <div
        onClick={() => select()}
        className="p-1 dark:text-item-dark-contrast"
      >
        <span>{item.title}</span>
      </div>
    );
  };

  const renderProjectDetails: DialogItemDetailsRenderer<
    ScenarioWithPermission
  > = (proj, open) => {
    return (
      <ProjectDetails
        project={proj}
        handler={open}
        handleEditProject={handleEditProject}
        fromProjectRedirect={fromProjectRedirect}
        selectedScenario={selectedScenario}
      />
    );
  };

  const handleProjectClick = (scenario: Scenario) => {
    sendInfo({
      type: `Project select: ${scenario.id} - ${scenario.title}`,
      action: 'Click',
      item: 'Project',
      module: 'DataCosmos',
      additionalParams: {
        project: scenario,
      },
    });
    setCurrentScenario(scenario);
    setCurrentProject(scenario);
    setIsOpen(false);
    history.push({
      pathname: rerouteHandler(scenario).pathname,
    });
  };

  const handleAddProject = () => {
    setIsAddModalOpen(true);
    setIsOpen(false);
  };

  const { hasPermission: isAllowedToCreateScenario } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'data:scenario:create',
    },
  });

  const handleCreateProject = () => {
    handleAddProject();
    sendInfo({
      type: 'Project creation',
      action: 'Click',
      item: 'Create project button',
      module: 'DataCosmos',
    });
  };

  return (
    <>
      <DatacosmosSelectDialog<ScenarioWithPermission>
        isContentLoading={isLoading}
        title={title}
        items={userScenarios}
        listRenderer={renderProjects}
        listItemClickHandler={handleProjectClick}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        addItemTitle={translate('datacosmos.projectsDialog.createNewProject')}
        handleAddItemClick={
          isAllowedToCreateScenario ? handleCreateProject : undefined
        }
        handleItemDetailsRender={renderProjectDetails}
        selectedItem={userScenarios.find((p) => p.id === selectedScenario?.id)}
        setSelectedItem={handleScenarioSelection}
        sortListBy="title"
        filterListBy="title"
        canOutsideClickClose={canOutsideClickClose}
        canEscapeKeyClose={canEscapeKeyClose}
        isCloseButtonShown={isCloseButtonShown}
      />
      <AddScenarioModal
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
        setIsProjectListOpen={setIsOpen}
        setUserScenarios={(scenario) => {
          if (!currentProject) {
            handleProjectClick(scenario);
          }
          void loadUserScenarios();
        }}
        setSelectedScenario={setScenario}
        user={user}
        redirectAfterCreation={
          !location.pathname.includes('tasking') &&
          !location.pathname.includes('catalog')
        }
      />

      <EditScenarioModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        setUserScenarios={loadUserScenarios}
        detailedScenario={selectedScenario}
        setCurrentScenario={setCurrentScenario}
        setCurrentProject={setCurrentProject}
      />
    </>
  );
};

export default ManageScenarioModal;
