import type { IAnyKey } from '../getFormData';

const orders: IAnyKey = {};

export const generateOrder = (types: string[]) => {
  const sortedType = types.sort();
  const key = sortedType.join('-');
  if (orders[key] === undefined) {
    orders[key] = 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return orders[key];
  }
  return ++orders[key];
};
