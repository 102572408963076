import { apiFetchHandler } from '_api/clientFetch';
import type { ScriptingInstanceVersion } from './types';
import { hosts } from '_api/hosts';
import { toaster } from 'toaster';

export type GetScriptingInstanceVersionParams = {
  missionId: number;
};

export const getScriptingInstanceVersion = apiFetchHandler<
  ScriptingInstanceVersion,
  GetScriptingInstanceVersionParams
>({
  host: hosts.ops.v0,
  endpoint: ({ missionId }) =>
    `/node-red-operator/mission/${missionId}/updates`,
  method: 'GET',
  errorMessage: 'Could not get scripting instance version',
  errorDescription: (e) => e.join('; '),
});

export type PutScriptingInstanceVersionParams = {
  missionId: number;
};

export type PutScriptingInstanceVersionBody = {
  version: string;
};

export const putScriptingInstanceVersion = apiFetchHandler<
  string,
  PutScriptingInstanceVersionParams,
  PutScriptingInstanceVersionBody
>({
  host: hosts.ops.v0,
  endpoint: ({ missionId }) =>
    `/node-red-operator/mission/${missionId}/updates`,
  method: 'PUT',
  errorMessage: 'Could not put scripting instance version',
  errorDescription: (e) => e.join('; '),
  onSuccess: (data) => {
    toaster.show({
      message: data ?? 'Scripting instance version updated',
      icon: 'info-sign',
      intent: 'success',
    });
  },
});
