import { clientTranslate } from 'utils/hooks/useLocalisation';
import config from '../config';
import { EXCLUDE_STYLED_MAPS } from 'env';

const NonStyledMapLayers = [
  {
    id: 'osm',
    name: 'OpenStreetMap',
    url: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
];

const OCStyledMapLayers = [
  {
    id: 'light',
    name: clientTranslate('datacosmos.map.light'),
    url:
      'https://api.mapbox.com/styles/v1/pepoc/ckqnmf9d849wy18n9t4q9plro/tiles/{z}/{x}/{y}?access_token=' +
      config.map.mapbox.accessToken,
  },
  {
    id: 'dark',
    name: clientTranslate('datacosmos.map.dark'),
    url:
      'https://api.mapbox.com/styles/v1/pepoc/clpjnd7gv00qc01p93x2h2ckx/tiles/{z}/{x}/{y}?access_token=' +
      config.map.mapbox.accessToken,
  },
  {
    id: 'satellite',
    name: clientTranslate('datacosmos.map.satellite'),
    url:
      'https://api.mapbox.com/styles/v1/pepoc/clz6ski7000q801pfe0ml4hzk/tiles/{z}/{x}/{y}?access_token=' +
      config.map.mapbox.accessToken,
  },
];

export const allMapLayers = [...OCStyledMapLayers, ...NonStyledMapLayers];

const MapLayers = !EXCLUDE_STYLED_MAPS ? allMapLayers : NonStyledMapLayers;

export default MapLayers;
