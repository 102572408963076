// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const satelliteDefine = (
  google?: unknown,
  options?: google.maps.PolylineOptions
): google.maps.PolylineOptions => {
  return {
    editable: false,
    draggable: false,
    strokeColor: '#000000',
    strokeWeight: 1,
    geodesic: true,
    ...options,
  };
};
