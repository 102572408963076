import { useRef } from 'react';
import { Breadcrumbs } from '@blueprintjs/core';
import DefaultLayout from 'pages/ops/shared/components/DefaultLayout';
import { useMission } from 'services/Missions';
import { GRAFANA_DASHBOARD } from 'constants/externalUrls';
import NavbarTimers from 'components/Timer/NavbarTimers';
import mainS from 'pages/ops/RTI/index.module.scss';
import s from 'pages/ops/Telemetry/index.module.scss';

const isLocalHost = window.location.origin.includes('localhost');
const grafanaPathname = '/ops/grafana';

/**
 * Generates the FE pathname given a grafana fully qualified URL
 * @param grafanaUrl
 * @returns the front-end URL
 */
const generateApplicationPathname = (grafanaUrl: string) => {
  const partToFind = grafanaPathname;
  const index = grafanaUrl.indexOf(partToFind);
  return '/ops/telemetry' + grafanaUrl.substring(index + partToFind.length);
};

/**
 * Generates the grafana iframe URL given the front-end URL
 * @param href is the front-end url
 * @param origin
 * @param pathname
 * @returns the iframe URL
 */
const getIframeUrl = (href: string, origin: string) => {
  // Make localhost work with test cluster URL
  // TODO: Since we use a different npm command in localhost, we can actually use
  // an env variable to detect this. Doing this will also convert this into dead code
  // in the production build and hence it will be removed during the build
  // process.
  const nonLocalHostUrl = isLocalHost
    ? href.replace(origin, 'https://test.app.open-cosmos.com')
    : href;

  // Change the URL to point to grafana
  const adjustedUrl = nonLocalHostUrl.replace(
    '/ops/telemetry',
    grafanaPathname
  );

  // Add trailing slash (otherwise you get redirected to our front-end)
  return adjustedUrl.includes(grafanaPathname) &&
    !adjustedUrl.includes(grafanaPathname + '/')
    ? adjustedUrl.replace(grafanaPathname, grafanaPathname + '/')
    : adjustedUrl;
};

const Telemetry = () => {
  const { currentMissionId } = useMission();

  const iframeUrl = getIframeUrl(window.location.href, window.location.origin);

  const ref = useRef<HTMLIFrameElement>(null);

  const homeHandler = () => {
    if (!currentMissionId) return;
    const newIframeUrl = getIframeUrl(
      window.location.origin + GRAFANA_DASHBOARD(currentMissionId),
      window.location.origin
    );

    if (!ref.current) return;
    ref.current.src = newIframeUrl;
    window.history.pushState(
      '',
      'OpenApp',
      generateApplicationPathname(newIframeUrl)
    );
  };

  return (
    <DefaultLayout
      shouldHideClock
      showMissionSelector
      light
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs
            items={[
              {
                text: 'Telemetry',
                onClick: homeHandler,
              },
            ]}
          />
        </div>
      }
      header={<NavbarTimers showLinkButton />}
    >
      <iframe
        ref={ref}
        title="telemetry frame"
        className={s.grafana}
        src={iframeUrl}
        onLoad={() => {
          const gw = ref.current?.contentWindow;
          if (!gw) return;
          const pushState = gw.history.pushState;
          gw.history.pushState = function (_data, _unused, url) {
            const pathName = generateApplicationPathname(url as string);
            window.history.pushState('', 'OpenApp', pathName);
            //@ts-expect-error
            // eslint-disable-next-line prefer-rest-params
            pushState.apply(gw.history, arguments);
          };
        }}
      />
    </DefaultLayout>
  );
};

export default Telemetry;
