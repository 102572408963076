import React, { useState } from 'react';
import {
  isEqual,
  remove,
  sortBy,
  find,
  map,
  isEmpty,
  keys,
  pipe,
  isUndefined,
} from 'lodash/fp';
import { UL, Collapse, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import useLocalStorage from '../../../../../../utils/hooks/useLocalStorage';
import CommandListSingleBody from './CommandListSingleBody';
import CommandListGroupBody from './CommandListGroupBody';
import { FAVORITES_COMMANDS_GROUP } from '../../../../../../constants/ops/rti/oparate/constants';
import s from './index.module.scss';
import type { CommandDefinition } from '_api/telecommands/types';

export interface IProps {
  loading: boolean;
  availableCommands: CommandDefinition[];
  handleCommandClick: (cmd: CommandDefinition) => void;
}

export interface ICommandGroup {
  name: string;
  commands: (CommandDefinition | undefined)[]; //TODO: should these be allowed to be undefined?
}

interface IGroupOpen {
  [key: string]: boolean;
}

interface IGroup {
  [key: string]: CommandDefinition[];
}

const DEFAULT_GROUP = 'Others';

const organiseCommands = (
  commands: CommandDefinition[],
  featuredCommands: (CommandDefinition | undefined)[]
): ICommandGroup[] => {
  const groups = commands.reduce((all: IGroup, cmd) => {
    const currentGroup = cmd.group ? cmd.group : DEFAULT_GROUP;

    if (isUndefined(all[currentGroup])) {
      all[currentGroup] = [];
    }

    all[currentGroup].push(cmd);

    return all;
  }, {});

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const sortedCommands = pipe(
    keys,
    map((gName) => ({
      name: gName,
      commands: groups[gName],
    })),
    sortBy('name'),
    (it: ICommandGroup[]) => it
  )(groups);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [
    { name: FAVORITES_COMMANDS_GROUP, commands: featuredCommands },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...sortedCommands,
  ];
};

const getFeaturedCommands = (
  featuredList: string[],
  commandList: CommandDefinition[]
) => {
  if (isEmpty(commandList)) return [];

  const mappedCommands = map(
    (cmdName) => find(['name', cmdName], commandList),
    featuredList
  );

  return sortBy('name', mappedCommands);
};

export const CommandList = (props: IProps) => {
  const [localStorage, setLocalStorage] = useLocalStorage<{
    commandsFeatures: string[];
  }>('rti_commands', {
    commandsFeatures: [],
  });
  const [groupsOpenState, setGroupsOpenState] = useState<IGroupOpen>({});

  const { commandsFeatures } = localStorage;

  const featuredCommands = getFeaturedCommands(
    commandsFeatures,
    props.availableCommands
  );
  const groups = organiseCommands(props.availableCommands, featuredCommands);

  const setGroupOpenState = (groupName: string) => {
    const newState = {
      ...groupsOpenState,
      [groupName]: !groupsOpenState[groupName],
    };

    setGroupsOpenState(newState);
  };

  const handleFeatureClick = (cmdName: string) => {
    if (commandsFeatures.includes(cmdName)) {
      const list = remove(isEqual(cmdName), commandsFeatures);
      setLocalStorage({ commandsFeatures: [...list] });
      return;
    }

    setLocalStorage({ commandsFeatures: [...commandsFeatures, cmdName] });
  };

  if (props.loading) {
    return (
      <div className={s.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <UL className={s.listContainer}>
      {groups.map((group) => {
        const isOpen = Boolean(groupsOpenState[group.name]);

        return (
          <CommandListGroupBody
            key={group.name}
            groupName={group.name}
            isOpen={isOpen}
            defaultIcon={IconNames.KEY_COMMAND}
            setGroupOpenState={setGroupOpenState}
          >
            <Collapse isOpen={isOpen}>
              <UL className={s.listContainer}>
                {group.commands.map((cmd) => {
                  if (cmd === undefined) {
                    return null;
                  }
                  return (
                    <CommandListSingleBody
                      key={cmd.name}
                      cmd={cmd}
                      isFeature={commandsFeatures.includes(cmd.name ?? '')}
                      handleFeatureClick={handleFeatureClick}
                      handleCommandClick={props.handleCommandClick}
                    />
                  );
                })}
              </UL>
            </Collapse>
          </CommandListGroupBody>
        );
      })}
    </UL>
  );
};
