import { useEffect, useState } from 'react';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { Telemetry } from '_api/telemetry/types';

import s from './index.module.scss';

interface IProps {
  isLoading?: boolean;
  telemetryDefinition?: Telemetry;
  handleSaveButtonClick?: (id: Telemetry) => void;
  setShowNewDefinition?: (value: boolean) => void;
  highlightTelemetryDefinition?: (value: string | null) => void;
  handleDeleteClick?: (id: string | number) => void;
}

const LibraryTelemetryDefinitionsRow = (props: IProps) => {
  const [telemetry, setTelemetry] = useState<Telemetry>();
  const [isFiltering, setFiltering] = useState<boolean>(false);

  useEffect(() => {
    if (props.telemetryDefinition) {
      setTelemetry(props.telemetryDefinition);
    }
  }, [props.telemetryDefinition]);

  const renderEditableButtons = () => (
    <ButtonGroup>
      <Button
        minimal
        icon={IconNames.FILTER}
        onClick={() => {
          setFiltering(!isFiltering);
          props.highlightTelemetryDefinition?.(telemetry?.name ?? null);
        }}
        active={isFiltering}
      />
    </ButtonGroup>
  );

  const renderStaticFields = () => (
    <>
      <td className={s.tableIds}>
        <span>{telemetry?.frameId}</span>
      </td>
      <td className={s.tableNames}>
        <span>{telemetry?.frameName}</span>
      </td>
      <td className={s.tableLabels}>
        <span>{telemetry?.labels.join(', ')}</span>
      </td>
      <td>
        <span>{telemetry?.system}</span>
      </td>
    </>
  );

  if (props.isLoading) {
    return (
      <tr>
        <td className={s.tableIds}>
          <span className={Classes.SKELETON}>--</span>
        </td>
        <td className={s.tableNames}>
          <span className={Classes.SKELETON}>Dummy name</span>
        </td>
        <td className={s.tableLabels}>
          <span className={Classes.SKELETON}>Dummy label</span>
        </td>
        <td>
          <span className={Classes.SKELETON}>Dummy</span>
        </td>
        <td>
          <button className={Classes.BUTTON + ' ' + Classes.SKELETON} />
          <button className={Classes.BUTTON + ' ' + Classes.SKELETON} />
          <button className={Classes.BUTTON + ' ' + Classes.SKELETON} />
        </td>
      </tr>
    );
  }

  if (!telemetry) return null;

  return (
    <tr key={props.telemetryDefinition?.frameId}>
      {renderStaticFields()}
      <td>{renderEditableButtons()}</td>
    </tr>
  );
};

export default LibraryTelemetryDefinitionsRow;
