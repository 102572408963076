import React, { useState, useContext, createContext, useCallback } from 'react';
import type { IStacItem } from 'datacosmos/types/stac-types';
import { StacItem } from 'datacosmos/types/stac-types';
import type { ILayerProperty, Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { STACLayerFactory } from 'datacosmos/entities/stacLayer';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import type { IBandAlgebraLayerOptions } from 'datacosmos/entities/bandAlgebraLayer';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';

export type MapLayerItem = IStacItem | StacItem;

/**
 * IMapLayers provides storage and management for map layers
 */
export type IMapLayers = ReturnType<typeof useMapLayersHook>;

export const MapLayersContext = createContext<IMapLayers>(
  null as unknown as IMapLayers
);

export const useMapLayers = () => useContext<IMapLayers>(MapLayersContext);

/**
 * useMapLayersHook stores and manages the layers that will then be displayed
 * on a map.
 * Ideally, this hook would not include specific data from the map implementation
 * so that we can switch it if we want (for example, to mapboxGL or google maps)
 */
export const useMapLayersHook = () => {
  const [isLayersMenuOpen, setLayersMenuOpen] = useState<boolean>(false);
  const [layers, setLayers] = useState<Layer[]>([]);

  const removeLayer = useCallback((...layerIds: string[]) => {
    setLayers((prevLayers) =>
      prevLayers.filter((layer) => layerIds.some((lID) => layer.id !== lID))
    );
  }, []);

  const removeLayersBySourceType = useCallback((type: LayerSourceType) => {
    setLayers((prevLayers) =>
      prevLayers.filter((layer) => layer.sourceType !== type)
    );
  }, []);

  /**
   * Add new layer(s) on top of existing ones
   */
  const addLayer = useCallback((...newLayers: Layer[]) => {
    setLayers((prevLayers) => [...newLayers, ...prevLayers]);
  }, []);

  /** toggleAssetOnMap toggle an asset on or off on the map */
  const toggleAssetOnMap = useCallback(
    (
      item: MapLayerItem,
      assetKeyOrExpression: string,
      options?: IBandAlgebraLayerOptions,
      expressionId?: string
    ) => {
      let assetKey: string | undefined, expression: string | undefined;
      if (options) {
        expression = expressionId
          ? expressionId + '::' + assetKeyOrExpression
          : assetKeyOrExpression;
      } else {
        assetKey = assetKeyOrExpression;
      }

      const existingLayerForThisAsset = layers.find((layer) => {
        const containsSTACAsset = assetKey
          ? layer.containsSTACAsset(item, assetKey)
          : false;
        const containsBandAlgebra = expression
          ? layer.containsBandAlgebra(item, expression)
          : false;
        return containsSTACAsset || containsBandAlgebra;
      });

      if (existingLayerForThisAsset !== undefined) {
        removeLayer(existingLayerForThisAsset.id);
        return;
      }

      let layer = STACLayerFactory({
        item: new StacItem(item),
        assetKey: assetKey,
        expression: expression,
      });

      if (layer instanceof OutlineLayer) return;

      if (layer instanceof BandAlgebraSTACLayer && options) {
        layer = layer.cloneWithOptions(options);
      }

      addLayer(layer);
    },
    [addLayer, layers, removeLayer]
  );

  const removeAssetFromMap = useCallback(
    (
      item: IStacItem,
      assetKeyOrExpression: string,
      options?: IBandAlgebraLayerOptions,
      expressionId?: string
    ) => {
      let assetKey: string | undefined, expression: string | undefined;

      if (options) {
        expression = expressionId
          ? expressionId + '::' + assetKeyOrExpression
          : assetKeyOrExpression;
      } else {
        assetKey = assetKeyOrExpression;
      }

      const existingLayerForThisAsset = layers.find((layer) => {
        const containsSTACAsset = assetKey
          ? layer.containsSTACAsset(item, assetKey)
          : false;
        const containsBandAlgebra = expression
          ? layer.containsBandAlgebra(item, expression)
          : false;
        return containsSTACAsset || containsBandAlgebra;
      });

      if (existingLayerForThisAsset !== undefined) {
        removeLayer(existingLayerForThisAsset.id);
        return;
      }
    },
    [layers, removeLayer]
  );

  const removeOutline = useCallback(() => {
    removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);
  }, [removeLayersBySourceType]);

  const displayOutline = useCallback(
    (item: MapLayerItem) => {
      removeOutline();
      addLayer(
        STACLayerFactory({
          item: new StacItem(item),
          assetKey: undefined,
          expression: undefined,
        })
      );
    },
    [addLayer, removeOutline]
  );

  const removeEverythingFromMap = useCallback(() => {
    setLayers([]);
  }, []);

  /**
   * replaceLayer replaces a layer in the store that has the same id as the
   * layer provided as an argument.
   */
  const replaceLayer = useCallback(
    (layer: Layer | undefined, newIndex?: number) => {
      if (!layer) return;
      setLayers((prev) => {
        if (!layer.id) {
          throw new Error('Cannot update a layer if it does not have an id');
        }

        const index = prev.findIndex(({ id }) => id === layer.id);
        if (newIndex === undefined) {
          newIndex = index;
        }

        if (index === -1) {
          throw new Error(`Couldn't find layer with ID ${layer.id}`);
        }

        const newLayers = [...prev];
        newLayers.splice(index, 1);
        newLayers.splice(newIndex, 0, layer);
        return newLayers;
      });
    },
    []
  );

  /**
   * Completely removes target layer and adds a new one in it's place. Usefull when needing to update GeoJSON data of a layer.
   * @param newLayer Layer to replace with
   * @param targetLayer Layer to be replaced
   */
  const replaceSpecificLayerWithAnother = useCallback(
    (newLayer: Layer | undefined, targetLayer: Layer | undefined) => {
      if (newLayer && targetLayer) {
        setLayers((prev) => {
          const toReplaceWith = [...prev];
          const targetIndex = [...prev].findIndex(
            ({ id }) => id === targetLayer.id
          );
          toReplaceWith.splice(targetIndex, 1, newLayer);
          return toReplaceWith;
        });
      }
    },
    []
  );

  /**
   * Marks the desired layer as selected.
   *
   * If addToCurrentSelection is true, other selected layers will remain
   * selected. If addToCurrentSelection is false, the other layers that were
   * already selected will be unselected.
   */
  const selectLayer = useCallback(
    (layer: Layer, addToCurrentSelection: boolean) => {
      if (!addToCurrentSelection) {
        const selectedLayers = layers.filter((l) => l.options.isSelected);
        for (const l of selectedLayers) {
          replaceLayer(l.cloneWithOptions({ isSelected: false }));
        }
      }

      replaceLayer(
        layer.cloneWithOptions({ isSelected: !layer.options.isSelected })
      );
    },
    [layers, replaceLayer]
  );

  const replaceSelectedLayers = useCallback(
    (layer: Layer[], layerproperty?: ILayerProperty) => {
      const newLayers = [...layers];
      layer.forEach((l: GeoJSONLayer<Record<string, string>> | Layer) => {
        let updatedLayer: Layer;
        if (!l.id) {
          throw new Error('Cannot update a layer if it does not have an id');
        }

        const index = layers.findIndex((l1) => l1.id === l.id);

        if (index === -1) {
          throw new Error(`Couldn't find layer with ID ${l.id}`);
        }

        if (layerproperty?.name === 'opacity') {
          updatedLayer = l.cloneWithOptions({
            opacity: layerproperty.value as number,
          });
        } else if (layerproperty?.name === 'visible') {
          updatedLayer = l.cloneWithOptions({ visible: !l.options.visible });
        } else if (
          layerproperty?.name === 'color' &&
          typeof (l as { setColor?: () => void }).setColor !== 'undefined'
        ) {
          updatedLayer = l.cloneWithOptions({
            color: layerproperty.value as string,
          });
        } else if (
          layerproperty?.name === 'blendMode' &&
          (l.sourceType === LayerSourceType.ASSET ||
            l.sourceType === LayerSourceType.ASSET_OUTLINE ||
            l.sourceType === LayerSourceType.ALGEBRA_LAYER ||
            l.sourceType === LayerSourceType.OVERLAY)
        ) {
          updatedLayer = l.cloneWithOptions({
            blendMode: layerproperty.value as string,
          });
        } else {
          updatedLayer = l;
        }

        newLayers.splice(index, 1);
        newLayers.splice(index, 0, updatedLayer);
      });
      setLayers(newLayers);
    },
    [layers]
  );

  /**
   * isAssetShown returns true if the item and asset are currently shown on the
   * map
   */
  const isAssetShown = useCallback(
    (item: MapLayerItem, assetKey?: string) => {
      if (!assetKey) return false;
      return layers.some((l) => l.containsSTACAsset(item, assetKey));
    },
    [layers]
  );

  return {
    layers,
    toggleAssetOnMap,
    removeEverythingFromMap,
    isAssetShown,
    displayOutline,
    removeOutline,
    isLayersMenuOpen,
    setLayersMenuOpen,
    replaceLayer,
    addLayer,
    setLayers,
    removeLayer,
    replaceSelectedLayers,
    selectLayer,
    replaceSpecificLayerWithAnother,
    removeLayersBySourceType,
    removeAssetFromMap,
  };
};

export const MapLayersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <MapLayersContext.Provider value={useMapLayersHook()}>
      {children}
    </MapLayersContext.Provider>
  );
};
