const COLORS = {
  MAIN_GREEN: 'rgb(90, 190, 150)',
  MAIN_ORANGE: 'orange',
  MAIN_BLUE: 'rgb(7, 146, 243)',
  MAIN_RED: 'rgb(228, 105, 94)',
  MAIN_PRIMARY_HEX: '#FFCC55',
  MAIN_BLACK: 'rgb(25, 25, 25)',
  AOI_LAYER: '#53a4ea',
  AOI_SELECTED_LAYER: '#CCCC00',
  OPPORTUNITY_LAYER: '#D13913',
  OPPORTUNITY_SELECTED_LAYER: '#d6e00d',
};

export default COLORS;
