import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';

export const getUserInfo = apiFetchHandler<{ user: IUserWithPermissions }>({
  endpoint: '/me',
  method: 'GET',
  errorMessage: 'Could not get user information',
  host: hosts.authentication,
  includeCredentials: true,
});
