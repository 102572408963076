import { useDrop } from 'react-dnd';

export default (dropType: string, path: string) => {
  if (!dropType) {
    return {
      isOver: false,
      isOverCurrent: false,
      drop: null,
    };
  }

  const [{ isOver, isOverCurrent }, drop] = useDrop({
    accept: dropType,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      return didDrop ? undefined : { toPath: path };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  return {
    isOver,
    isOverCurrent,
    drop,
  };
};
