export const HISTORY_STATUS_COLOR_MAPPING = {
  sent: 'rgb(128, 128, 128)',
  pending: 'rgb(255, 204, 85)',
  error: 'rgb(228, 105, 94)',
  success: 'rgb(90, 190, 150)',
};

export const DOWNLOAD_COMMANDS = [
  'FtpDownload',
  'FtpDownloadFolderContents',
  'FtpDownloadArchiveOfSelectedFiles',
  'SFTPDownloadFile',
  'TransmitFile',
];

export const UPLOAD_COMMANDS = [
  'FtpUpload',
  'EpsUploadImage',
  'SFTPUploadFile',
];
