import { useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { SORTED_ITEM } from 'constants/dragAndDrop/constants';
import type { DropTargetMonitor } from 'react-dnd';

type SortedItem = {
  type: string;
  index: number;
  id?: string;
};

const shouldMoveItem = (
  dragIndex: number,
  hoverIndex: number,
  ref: React.RefObject<HTMLDivElement>,
  monitor: DropTargetMonitor
) => {
  const hoverBoundingRect = ref.current?.getBoundingClientRect();

  if (!hoverBoundingRect) return false;

  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

  const clientOffset = monitor.getClientOffset();

  if (!clientOffset) return false;

  const hoverClientY = clientOffset.y - hoverBoundingRect.top;

  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return false;
  }

  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return false;
  }

  return true;
};

export const dropTargetHandler = (
  ref: React.RefObject<HTMLDivElement>,
  itemIndex: number,
  handleMoveItem: Function,
  handleDropItem?: Function
) => ({
  accept: SORTED_ITEM,
  hover(item: SortedItem, monitor: DropTargetMonitor) {
    if (!ref.current) {
      return;
    }
    const dragIndex = item.index;
    const hoverIndex = itemIndex;

    if (dragIndex === hoverIndex) {
      return;
    }

    const moveItem = shouldMoveItem(dragIndex, hoverIndex, ref, monitor);

    if (!moveItem) {
      return;
    }

    handleMoveItem(dragIndex, hoverIndex);

    item.index = hoverIndex;
  },
  drop(item: SortedItem, monitor: DropTargetMonitor) {
    if (!handleDropItem) {
      return;
    }

    if (!ref.current) {
      return;
    }
    const dragIndex = item.index;
    const hoverIndex = itemIndex;

    const moveItem = shouldMoveItem(dragIndex, hoverIndex, ref, monitor);

    if (!moveItem) {
      return;
    }

    handleDropItem(dragIndex, hoverIndex);

    item.index = hoverIndex;
  },
});

type IDndSorting = (dragIndex: number, hoverIndex: number) => void;

const useDragAndDropSorting = (
  index: number,
  handleHoverItem: IDndSorting,
  handleDropItem?: IDndSorting
) => {
  const dndSortingRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop(
    dropTargetHandler(dndSortingRef, index, handleHoverItem, handleDropItem)
  );

  const [{ isDragging }, drag] = useDrag({
    type: SORTED_ITEM,
    item: { index: index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  useEffect(() => {
    drag(drop(dndSortingRef));
  }, [drag, drop]);

  return {
    dndSortingRef,
    opacity: isDragging ? 0 : 1,
  };
};

export default useDragAndDropSorting;
