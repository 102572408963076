import { Field } from 'redux-form';
import renderNumericField from './NumberField';
import type { ReactElement } from 'react';
import React from 'react';

export interface IRenderPoint {
  names: string[];
}

export default function RenderPoint(props: IRenderPoint): ReactElement {
  const { names } = props;
  return (
    <>
      <Field name={names[0]} placeholder="0" component={renderNumericField} />
      <Field name={names[1]} placeholder="0" component={renderNumericField} />
    </>
  );
}
