import { useContext, createContext } from 'react';
import type { UseApplicationCatalogProvider } from 'datacosmos/stores/ApplicationProvider';

export type IApplicationCatalogContext =
  ReturnType<UseApplicationCatalogProvider>;

export const ApplicationCatalogContext =
  createContext<IApplicationCatalogContext>(
    null as unknown as IApplicationCatalogContext
  );

export const useApplicationCatalog = () =>
  useContext<IApplicationCatalogContext>(ApplicationCatalogContext);
