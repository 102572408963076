import type {
  IPIState,
  pointsOfInterestTypes,
  IPointOfInterest,
} from '../../../../constants/pointsOfInterest/actionTypes';
import {
  PI_ADD,
  PI_UPDATE,
  PI_REMOVE,
  PI_UPLOAD,
} from '../../../../constants/pointsOfInterest/actionTypes';

const initialState: IPIState = {
  list: [],
};

export default function pointOfInterestReducer(
  state: IPIState = initialState,
  action: pointsOfInterestTypes
): IPIState {
  switch (action.type) {
    case PI_ADD: {
      return {
        list: [...state.list, action.pointOfInterest],
      };
    }
    case PI_UPDATE: {
      return {
        list: state.list.map((pointOfInterest): IPointOfInterest => {
          if (pointOfInterest.id === action.pointOfInterest.id) {
            return { ...action.pointOfInterest };
          }
          return pointOfInterest;
        }),
      };
    }
    case PI_REMOVE: {
      return {
        list: state.list.filter(
          (pointOfInterest): boolean => pointOfInterest.id !== action.id
        ),
      };
    }
    case PI_UPLOAD: {
      return action.pointsOfInterest;
    }
    default: {
      return state;
    }
  }
}
