import { useEffect } from 'react';
import moment from 'moment';
import { parseDate } from '@internationalized/date';
import DateField from '_molecules/DateField/DateField';
import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import type { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import type { CommonOpportunity } from '_api/tasking/helpers';
import RangeCalendar from '_molecules/Calendars/RangeCalendar/RangeCalendar';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type OpportunityCalendarProps = {
  dateTo?: Date;
  dateFrom?: Date;
  setDateTo: (DateTo: Date) => void;
  setDateFrom: (DateFrom: Date) => void;
  layers: Layer[];
};

export const OpportunityCalendar = ({
  dateTo,
  dateFrom,
  setDateFrom,
  setDateTo,
  layers,
}: OpportunityCalendarProps) => {
  const { translate } = useLocalisation();

  const getOppDates = () => {
    const opps = layers.filter(
      ({ sourceType }) => sourceType === LayerSourceType.TASKING_OPPORTUNITIES
    ) as OpportunityLayer<CommonOpportunity>[];

    return opps.map((o) => moment(o.metadata.Start).toDate());
  };

  //tasking default Start Date for filtering Tasking Oppertunities should be 48hrs into the future
  const minDate = moment(new Date()).add(48, 'hours');

  useEffect(() => {
    setDateFrom(dateFrom ? dateFrom : minDate.toDate());
    setDateTo(dateTo ? dateTo : moment(dateTo).add(1, 'week').toDate());
    return () => {
      setDateFrom(minDate.toDate());
      setDateTo(new Date());
    };
  }, [dateFrom, dateTo, setDateFrom, setDateTo, minDate]);

  return (
    <div className="flex flex-col gap-5 dark:bg-item-dark dark:text-item-dark-contrast">
      <div className="flex justify-between p-3">
        <DateField
          value={parseDate(moment(dateFrom).format('YYYY-MM-DD'))}
          label="Start"
          minValue={parseDate(moment(minDate).format('YYYY-MM-DD'))}
          maxValue={parseDate(
            moment(moment().endOf('day').add(3, 'months').toDate()).format(
              'YYYY-MM-DD'
            )
          )}
        />
        <div className="w-px bg-item-contrast" />
        <DateField
          value={parseDate(moment(dateTo).format('YYYY-MM-DD'))}
          label="End"
          minValue={parseDate(moment(new Date()).format('YYYY-MM-DD'))}
          maxValue={parseDate(
            moment(moment().endOf('day').add(3, 'months').toDate()).format(
              'YYYY-MM-DD'
            )
          )}
        />
      </div>

      <RangeCalendar
        isReadOnly={true}
        value={{
          start: parseDate(moment(dateFrom).format('YYYY-MM-DD')),
          end: parseDate(moment(dateTo).format('YYYY-MM-DD')),
        }}
        minValue={parseDate(moment(minDate).format('YYYY-MM-DD'))}
        maxValue={parseDate(
          moment(moment().endOf('day').add(3, 'months').toDate()).format(
            'YYYY-MM-DD'
          )
        )}
        highligtedDates={getOppDates()}
      />

      <div className="flex items-center gap-2 border-b-2 border-b-contrast-inactive p-2 text-sm">
        <div className="rounded-full h-3 w-3 bg-accent-900 dark:bg-item-dark-hover" />
        <span>
          {translate('datacosmos.tasking.new.calendar.availableOpps')}
        </span>
      </div>
    </div>
  );
};
