import { setPopUp } from 'pages/shared/state/actions/popUp/thunks';
import MarkerForm from 'components/popUp/MarkerForm';
import type { IPointOfInterest } from 'constants/pointsOfInterest/actionTypes';
import {
  addPI,
  removePI,
  updatePI,
} from 'pages/shared/state/actions/pointsOfInterest/helpers';
import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'pages/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IRegionOfInterest } from 'constants/regionsOfInterest/actionTypes';
import {
  addRI,
  removeRI,
  updateRI,
} from 'pages/shared/state/actions/regionsOfInterest/helpers';
import PolygonForm from 'components/popUp/PolygonForm';
import { setFocus } from 'pages/shared/state/actions/focus/thunks';
import type { IGroundStation } from 'constants/groundStations/actionTypes';
import {
  addGS,
  removeGS,
  updateGS,
} from 'pages/shared/state/actions/groundStations/thunks';
import { generateName } from 'utils/common/generateName';
import { GROUND_STATION_FORM_NAME } from 'constants/popUp/constants';
import { generateId } from 'utils/common/generateId';
import { generateOrder } from 'utils/common/generateOrderIndex';
import { selectLastSatelliteMode } from 'pages/shared/utils/selectors/satellite';
import { FocusTypes } from 'constants/focus/actionTypes';
import { DEFAULT_SATELLITE_MODE } from 'constants/satellite/constants';

export const setMarkerSpecify =
  (
    pointOfInterest?: IPointOfInterest,
    withPopUp = true
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, state) => {
    const stateData = state();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const orderIndex = generateOrder([
      FocusTypes.PI_SUB_ID,
      FocusTypes.RI_SUB_ID,
    ]);
    const astrum = stateData.astrum.current;

    const id = generateId(FocusTypes.PI_SUB_ID);

    const pointOfInterestData: IPointOfInterest = {
      astrum: astrum.planet,
      id,
      name: generateName(stateData.pointsOfInterest, 'Point'),
      lat: 0,
      lon: 0,
      altitude: 0,
      elevationAngle: 30,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      orderIndex,
      satelliteMode: DEFAULT_SATELLITE_MODE,
      ...pointOfInterest,
    };
    dispatch(addPI(pointOfInterestData));
    dispatch(
      setFocus({
        id,
        type: FocusTypes.PI_SUB_ID,
        needScroll: true,
        withPopUp: true,
      })
    );
    if (withPopUp) {
      dispatch(
        setPopUp({
          component: MarkerForm,
          visible: true,
          classNames: ['short-container'],
          data: pointOfInterestData,
          otherProps: {
            type: FocusTypes.PI_SUB_ID,
          },
          functions: {
            onSubmit: (poi: IPointOfInterest): void => dispatch(updatePI(poi)),
            onClose: (poi: IPointOfInterest): void =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              dispatch(removePI(String(poi.id ?? ''))),
          },
        })
      );
    }
  };

export const setPolygonSpecify =
  (
    drawingManager: google.maps.drawing.DrawingManager,
    regionOfInterest?: IRegionOfInterest,
    withPopUp = true
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, state) => {
    const stateData = state();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const orderIndex = generateOrder([
      FocusTypes.PI_SUB_ID,
      FocusTypes.RI_SUB_ID,
    ]);

    const id = generateId(FocusTypes.RI_SUB_ID);
    const point0 = { lat: 10, lng: 10 };
    const point1 = { lat: 10, lng: -10 };
    const point2 = { lat: -10, lng: -10 };
    const point3 = { lat: -10, lng: 10 };
    //@ts-expect-error
    const regionOfInterestData: IRegionOfInterest = {
      id,
      name: generateName(stateData.regionsOfInterest, 'Region'),
      paths: [[point0, point1, point2, point3]],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      orderIndex,
      satelliteMode: selectLastSatelliteMode(stateData),
      ...regionOfInterest,
      elevationAngle: 75,
    };
    dispatch(addRI(regionOfInterestData));
    dispatch(
      setFocus({
        id,
        type: FocusTypes.RI_SUB_ID,
        needScroll: true,
        withPopUp: true,
      })
    );
    if (withPopUp) {
      dispatch(
        setPopUp({
          component: PolygonForm,
          visible: true,
          classNames: ['short-container'],
          data: regionOfInterestData,
          otherProps: {
            type: FocusTypes.RI_SUB_ID,
          },
          functions: {
            onSubmit: (roi: IRegionOfInterest): void => {
              dispatch(updateRI(roi));
            },
            onClose: (roi: IRegionOfInterest): void =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              dispatch(removeRI(String(roi.id ?? ''))),
            addRegion: (): void => {
              drawingManager.setDrawingMode(
                google.maps.drawing.OverlayType.POLYGON
              );
            },
          },
        })
      );
    }
  };

export const setGroundStationSpecify =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, state) => {
    const stateData = state();
    const astrum = stateData.astrum.current;
    const id = generateId(FocusTypes.GS_SUB_ID);
    const groundStation: IGroundStation = {
      astrum: astrum.planet,
      id,
      name: generateName(stateData.groundStations, 'GS'),
      lat: 0,
      lon: 0,
      altitude: 0,
      elevationAngle: 30,
      custom: true,
      select: false,
      optimize: false,
    };
    dispatch(addGS(groundStation));
    dispatch(
      setFocus({
        id,
        type: FocusTypes.GS_SUB_ID,
        needScroll: true,
        withPopUp: true,
      })
    );
    dispatch(
      setPopUp({
        component: MarkerForm,
        visible: true,
        data: groundStation,
        classNames: ['short-container'],
        otherProps: {
          type: FocusTypes.GS_SUB_ID,
          form: GROUND_STATION_FORM_NAME,
        },
        functions: {
          onSubmit: (groundStationLocal: IGroundStation): void =>
            dispatch(updateGS({ ...groundStation, ...groundStationLocal })),
          onClose: (groundStationLocal: IPointOfInterest): void =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            dispatch(removeGS(String(groundStationLocal.id ?? ''))),
        },
      })
    );
  };
