import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash/fp';
import type { TrackingEvent } from './useAnalytics';
import { useAnalytics } from './useAnalytics';

export default (urlId: string, event: TrackingEvent) => {
  const params = useParams<{ id: string }>();

  const { startTracking, stopTracking } = useAnalytics();

  const id = get(urlId, params) as string;

  event.additionalParams = { [urlId]: id };

  useEffect(() => {
    if (id) {
      startTracking(event);
      return () => stopTracking(event);
    }
    return undefined;
  }, [event, id, startTracking, stopTracking]);
};
