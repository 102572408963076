import { MIN_AREA } from 'env';
import { computeArea } from 'utils/OSM/computeArea';

export const isAreaEnabled = (
  path: { lat: number; lng: number }[],
  area?: number
) => {
  area = area ? area : Number(MIN_AREA);
  return computeArea(path) > area;
};
