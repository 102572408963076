import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'pages/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IPolygonWrapper } from 'declarations/mapDeclarations/Polygon';
import type {
  IPoint,
  IRegionOfInterest,
} from 'constants/regionsOfInterest/actionTypes';
import { setPopUp } from 'pages/shared/state/actions/popUp/thunks';
import PolygonForm from 'components/popUp/PolygonForm';
import {
  removeRI,
  updateRI,
} from 'pages/shared/state/actions/regionsOfInterest/helpers';
import type { IMapWrapper } from 'declarations/mapDeclarations/Map';
import { setFocus } from 'pages/shared/state/actions/focus/thunks';
import { selectLastSatelliteMode } from 'pages/shared/utils/selectors/satellite';
import { FocusTypes } from 'constants/focus/actionTypes';

export const updateRegionOfInterest =
  (
    regionOfInterest: IPolygonWrapper,
    map: IMapWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, store) => {
    const storeData = store();
    const astrum = storeData.astrum.current;
    const regionOfInterestData: IRegionOfInterest = {
      id: regionOfInterest.id,
      name: regionOfInterest.name ?? '',
      astrum: astrum.planet,
      paths: regionOfInterest
        .getPaths()
        .getArray()
        .map((path): IPoint[] => {
          return path.getArray().map((LatLng): IPoint => {
            return {
              lat: LatLng.lat(),
              lng: LatLng.lng(),
            };
          });
        }),
      orderIndex: regionOfInterest.orderIndex ?? 0,
      satelliteMode: selectLastSatelliteMode(storeData),
      elevationAngle: regionOfInterest.elevationAngle ?? 0,
    };
    dispatch(
      setFocus({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        id: String(regionOfInterestData.id),
        type: FocusTypes.RI_SUB_ID,
        needScroll: true,
        withPopUp: true,
      })
    );
    dispatch(
      setPopUp({
        component: PolygonForm,
        visible: true,
        classNames: ['short-container'],
        data: regionOfInterestData,
        otherProps: {
          type: FocusTypes.RI_SUB_ID,
        },
        functions: {
          onSubmit: (roi: IRegionOfInterest): void => {
            dispatch(updateRI(roi));
          },
          onClose: (): void => {},
          addRegion: (): void => {
            map.drawingManager?.setDrawingMode(
              google.maps.drawing.OverlayType.POLYGON
            );
          },
          panToBounds: (bound: google.maps.LatLngBoundsLiteral): void => {
            map.panToBounds(bound, 0);
          },
        },
      })
    );
  };

export const removeRegionOfInterest =
  (
    regionOfInterest: IPolygonWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(removeRI(regionOfInterest.id ?? ''));
  };
