import React from 'react';
import EventSchemaEnum from './EventSchemaEnum';
import EventSchemaString from './EventSchemaString';
import EventSchemaInt from './EventSchemaInt';
import type { EventProperty } from '../utils/schema';

type Props = {
  schema: EventProperty;
  propertyKey: string;
  populateEventToSubmit: (value: unknown, propertyKey: string) => void;
};

const EventSchemaRenderer = (props: Props) => {
  const { schema, populateEventToSubmit } = props;

  if (schema.type === 'string' && !schema.enum) {
    return <EventSchemaString {...props} />;
  }

  if (schema.type === 'string' && schema.enum) {
    return <EventSchemaEnum {...props} />;
  }

  if (schema.type === 'array' && schema.items) {
    return (
      <div>
        <span>{schema.title}</span>
        <div className="pl-3">
          {Object.entries(schema.items ?? {}).map(([k, s]) => {
            const sch = s;
            return (
              <EventSchemaRenderer
                schema={sch}
                key={sch.title}
                propertyKey={`${props.propertyKey}.${k}`}
                populateEventToSubmit={populateEventToSubmit}
              />
            );
          })}
        </div>
      </div>
    );
  }

  if (schema.type === 'integer') {
    return <EventSchemaInt {...props} />;
  }

  return <div>NO RENDERER FOR THIS TYPE</div>;
};

export default EventSchemaRenderer;
