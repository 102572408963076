import type { IconName } from '@blueprintjs/core';
import { Icon, Tag } from '@blueprintjs/core';
import { getOr } from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
import {
  FAVORITES_COMMANDS_GROUP,
  GROUP_ICON_MAPPING,
} from 'constants/ops/rti/oparate/constants';
import s from './index.module.scss';

type CommandListGroupBodyProps = {
  groupName: string;
  isOpen: boolean;
  setGroupOpenState: (groupName: string) => void;
  defaultIcon: IconName;
  children: React.ReactNode;
};

const CommandListGroupBody = ({
  groupName,
  isOpen,
  defaultIcon,
  setGroupOpenState,
  children,
}: CommandListGroupBodyProps) => {
  const isFavoriteTagStyles =
    FAVORITES_COMMANDS_GROUP === groupName ? s.commandFeatureTag : null;

  return (
    <li className={isFavoriteTagStyles ?? undefined}>
      <Tag
        className={s.commandGroupTitle}
        fill
        large
        icon={getOr(defaultIcon, groupName, GROUP_ICON_MAPPING) as IconName}
        onClick={() => setGroupOpenState(groupName)}
      >
        <div className={s.commandGroupTitle_container}>
          <span>{groupName}</span>
          <Icon
            icon={isOpen ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_LEFT}
          />
        </div>
      </Tag>
      {children}
    </li>
  );
};

export default CommandListGroupBody;
