import classNames from "classnames";
import {
  Input,
  InputProps,
  Label,
  TextField,
  TextFieldProps,
  ValidationResult,
} from "react-aria-components";
import { FieldError, Text } from "react-aria-components";
import { tv } from "tailwind-variants";

const textField = tv({
  base: "dark:text-item-dark-contrast flex flex-col gap-1",
  variants: {
    fill: {
      true: "w-full",
      false: "",
    },
  },
});

interface MyTextFieldProps extends TextFieldProps {
  label?: React.ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  fill?: boolean;
  inputProps?: InputProps & {
    inputRef?: React.RefObject<HTMLInputElement> | undefined;
  };
}

function MyTextField({
  label,
  description,
  errorMessage,
  fill,
  ...props
}: MyTextFieldProps) {
  return (
    <TextField
      {...props}
      className={(params) => {
        const p = {
          ...params,
          fill,
        };
        return textField({
          ...p,
          class:
            typeof props.className === "function"
              ? props.className(params)
              : props.className,
        });
      }}
    >
      <Label>{label}</Label>
      <Input
        className={classNames(
          props.inputProps?.className as string,
          " dark:bg-item-dark bg-item border dark:border-item-dark-contrast p-1 focus:outline-none",
          {
            "bg-item-contrast-inactive dark:bg-item-dark-contrast-inactive":
              props.isDisabled,
          }
        )}
        {...props.inputProps}
        ref={props.inputProps?.inputRef}
      />
      {description && (
        <Text slot="description" className="text-xs">
          {description}
        </Text>
      )}
      <FieldError>{errorMessage}</FieldError>
    </TextField>
  );
}

export default MyTextField;
