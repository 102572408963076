import type { ILayerOptions, LayerSourceType } from './layer';
import { Layer } from './layer';

export type MarkerLayerOptions = ILayerOptions & {
  enablePopup?: boolean;
};

export class MapMarkerLayer extends Layer {
  declare sourceType: LayerSourceType;
  declare options: MarkerLayerOptions;
  declare layerClass: string;
  declare name: string;
  declare position: number[];
  declare description: string;
  popupContent: string = '';

  constructor(
    sourceType: LayerSourceType,
    name: string,
    position: number[],
    popupContent: string,
    options?: Partial<MarkerLayerOptions>
  ) {
    super(sourceType);

    this.name = name;
    this.position = position;
    this.popupContent = popupContent;
    this.options = {
      enablePopup: true,
      ...this.options,
      ...options,
    };
  }
  getName(): string {
    return this.name;
  }

  getDescription(): string | null {
    return '';
  }

  getPopupContent() {
    return this.popupContent;
  }

  containsSTACItem(): boolean {
    return false;
  }
  containsSTACAsset(): boolean {
    return false;
  }
  containsBandAlgebra(): boolean {
    return false;
  }
  sza(): Number | undefined {
    return undefined;
  }
  href(): string | undefined {
    return undefined;
  }
  cloneWithPopupContent(popupContenet: string) {
    const newObject = this.clone();
    newObject.popupContent = popupContenet;
    return newObject;
  }
}
