/**
 * https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
 */

const ICON_SIZE = 32;

const prepareIcon = (url: string): google.maps.Icon => ({
  url: url,
  size: new google.maps.Size(ICON_SIZE, ICON_SIZE),
  scaledSize: new google.maps.Size(ICON_SIZE, ICON_SIZE),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(ICON_SIZE * 0.5, ICON_SIZE * 0.75),
});

export function groundStationDefine(): google.maps.MarkerOptions {
  return {
    icon: prepareIcon('/images/ground_station_icon.svg'),
  };
}

export function groundStationOptimized(): google.maps.MarkerOptions {
  return {
    icon: prepareIcon('/images/ground_station_icon_optimized.svg'),
  };
}

export function groundStationSelected(): google.maps.MarkerOptions {
  return {
    icon: prepareIcon('/images/ground_station_icon_selected.svg'),
  };
}

export function groundStationFocused(): google.maps.MarkerOptions {
  return {
    icon: prepareIcon('/images/ground_station_icon_focused.svg'),
  };
}

export function supportingGroundStationDefine(): google.maps.MarkerOptions {
  return {
    icon: prepareIcon('/images/ground_station_icon_support.svg'),
  };
}
