import type { IPlatformConfiguratorActionTypes } from '../../../../../constants/ui/platformConfigurator/actionTypes';
import {
  PLATFORM_UPDATE_CONFIG,
  UPDATE_MODULE_SELECTION,
  LOAD_MODULE_SELECTION,
  UPLOAD_PLATFORM_CONFIGURATION,
} from '../../../../../constants/ui/platformConfigurator/actionTypes';
import type {
  IPlatformCharacteristicType,
  IPlatformCharacteristics,
} from '../../../../../constants/ui/platformConfigurator/types';
import {
  PLATFORM_SIZE,
  PLATFORM_SOLAR_PANEL_CONFIG,
  PLATFORM_SOLAR_PANEL_DEPLOYABLE,
  PLATFORM_SOLAR_PANEL_DIRECTION,
  PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE,
} from '../../../../../constants/ui/platformConfigurator/types';
import characteristicsDependancies from '../../../../../constants/ui/platformConfigurator/characteristicsDependancies.json';
import type { IPlatformModules } from '../../../../../constants/satellite/types';
import {
  POWER_DATA,
  EPS_DATA,
  COMMS_DATA,
  AOCS_DATA,
  OBDH_DATA,
} from '../../../../../constants/moduleData/constants';
import {
  PLATFORM_SIZE_TYPE,
  PLATFORM_SOLAR_PANEL_CONFIG_TYPE,
  PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE,
  PLATFORM_SOLAR_PANEL_DIRECTION_TYPE,
} from '../../../constants/platform';
import { isNil } from 'lodash';

export interface IPlatformConfigurator {
  order: IPlatformCharacteristicType[];
  characteristics: IPlatformCharacteristics;
  decisionTree: typeof characteristicsDependancies;
  moduleNames: IPlatformModules;
}

const initialState: IPlatformConfigurator = {
  order: [
    PLATFORM_SIZE,
    PLATFORM_SOLAR_PANEL_CONFIG,
    PLATFORM_SOLAR_PANEL_DEPLOYABLE,
    PLATFORM_SOLAR_PANEL_DIRECTION,
  ],
  characteristics: {
    [PLATFORM_SIZE]: PLATFORM_SIZE_TYPE.THREE_U,
    [PLATFORM_SOLAR_PANEL_CONFIG]: PLATFORM_SOLAR_PANEL_CONFIG_TYPE.PERP,
    [PLATFORM_SOLAR_PANEL_DEPLOYABLE]:
      PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE.SINGLE,
    [PLATFORM_SOLAR_PANEL_DIRECTION]: PLATFORM_SOLAR_PANEL_DIRECTION_TYPE.PLUS,
    [PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE]: '50%',
  },
  decisionTree: characteristicsDependancies,
  moduleNames: {
    [POWER_DATA]: [],
    [EPS_DATA]: [],
    [COMMS_DATA]: [],
    [AOCS_DATA]: [],
    [OBDH_DATA]: [],
  },
};

function platformConfiguratorReducer(
  state: IPlatformConfigurator = initialState,
  action: IPlatformConfiguratorActionTypes
): IPlatformConfigurator {
  const { order, characteristics } = state;

  switch (action.type) {
    case PLATFORM_UPDATE_CONFIG: {
      const newCharacteristics = { ...characteristics };

      newCharacteristics[action.payload.name] = action.payload.value;

      // TODO: Improve this once we get multiple types of solar panels

      // If solar panels changed to perpendicular, reset other options
      if (
        action.payload.name === PLATFORM_SOLAR_PANEL_CONFIG &&
        action.payload.value ===
          (PLATFORM_SOLAR_PANEL_CONFIG_TYPE.PERP as string)
      ) {
        newCharacteristics[PLATFORM_SOLAR_PANEL_DEPLOYABLE] =
          PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE.SINGLE;
        newCharacteristics[PLATFORM_SOLAR_PANEL_DIRECTION] =
          PLATFORM_SOLAR_PANEL_DIRECTION_TYPE.PLUS;
      }
      // If solar panel size changed, reset the direction
      else if (action.payload.name === PLATFORM_SOLAR_PANEL_DEPLOYABLE) {
        // We don't need to do anything... the direction should not be impacted by the multiplier changing
      } else {
        // Cascade if order is important
        if (order.includes(action.payload.name)) {
          const startCascadingFrom =
            order.findIndex(
              (characteristic) => characteristic === action.payload.name
            ) + 1;

          const characteristicsToCascase = order.slice(startCascadingFrom);

          characteristicsToCascase.forEach(
            (characteristic) => (newCharacteristics[characteristic] = undefined)
          );

          if (isNil(newCharacteristics[PLATFORM_SOLAR_PANEL_CONFIG])) {
            newCharacteristics[PLATFORM_SOLAR_PANEL_CONFIG] =
              PLATFORM_SOLAR_PANEL_CONFIG_TYPE.PERP;
            newCharacteristics[PLATFORM_SOLAR_PANEL_DIRECTION] =
              PLATFORM_SOLAR_PANEL_DIRECTION_TYPE.PLUS;
            newCharacteristics[PLATFORM_SOLAR_PANEL_DEPLOYABLE] =
              PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE.SINGLE;
          }
        }
      }
      return {
        ...state,
        characteristics: newCharacteristics,
      };
    }

    case UPDATE_MODULE_SELECTION: {
      const newlySelectedNames = action.selectedNames;
      const newModulesNames = {
        ...state.moduleNames,
        [action.moduleType]: newlySelectedNames,
      };

      return {
        ...state,
        moduleNames: newModulesNames,
      };
    }

    case LOAD_MODULE_SELECTION: {
      return {
        ...state,
        moduleNames: {
          [POWER_DATA]: action.moduleNames[POWER_DATA],
          [EPS_DATA]: action.moduleNames[EPS_DATA],
          [COMMS_DATA]: action.moduleNames[COMMS_DATA],
          [AOCS_DATA]: action.moduleNames[AOCS_DATA],
          [OBDH_DATA]: action.moduleNames[OBDH_DATA],
        },
      };
    }

    case UPLOAD_PLATFORM_CONFIGURATION: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return action.platformConfiguration;
    }

    default:
      return state;
  }
}

export default platformConfiguratorReducer;
