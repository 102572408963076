import { Breadcrumbs, NonIdealState } from '@blueprintjs/core';
import DefaultLayout from '../shared/components/DefaultLayout';
import mainS from 'pages/ops/RTI/index.module.scss';
import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { Column } from 'ui/ColumnLayout/Column';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import { Row } from 'ui/RowLayout/Row';
import {
  Button,
  DebouncedInput,
  Dialog,
  Header,
  Icon,
  ListBoxItem,
  Select,
  Table,
  Textarea,
  Tooltip,
} from 'opencosmos-ui';
import { useScriptsData } from './hooks/useScriptsData';
import type { Script, ScriptTrigger } from '_api/scripts/types';
import { useSubmitEvent } from './hooks/useSubmitEvent';
import EventSchemaRenderer from './components/EventSchemaRenderer';

const EDIT_SCRIPT_URL =
  'https://git.o-c.space/app/ops/scripting/-/tree/development/scripts?ref_type=heads';
const CONFIGURE_SCRIPT_URL =
  'https://git.o-c.space/app/ops/scripting/-/blob/development/sdk/events/triggers.py';

const Scripts = () => {
  const {
    scripts,
    toggleSelectScript,
    selectedScript,
    scriptBody,
    setNameSearchQuery,
    setTriggerSearchQuery,
    publishSelectedScript,
    triggers,
  } = useScriptsData();

  const {
    isSubmitDialogOpen,
    toggleSubmitDialogOpen,
    selectedEvent,
    setSelectedEvent,
    perEventParsedProperty,
    populateEventToSubmit,
    resetEventToSubmit,
    isSubmitButtonEnabled,
    submitNewEvent,
    getTriggersForSelectedEvent,
  } = useSubmitEvent();

  const withNoResults = (content: React.ReactNode) => {
    if (scripts.length === 0) {
      return (
        <NonIdealState
          icon="info-sign"
          description="No scripts match the search criteria"
          title="No scripts"
        />
      );
    }

    return <>{content}</>;
  };

  const renderScriptsTable = () => (
    <div className="w-full overflow-auto">
      <Table<Script>
        data={scripts}
        cellRenderer={(ctx, header) => {
          const rowScript = ctx.row.original;
          if (header === 'triggers') {
            return (
              <span className="p-2">
                {rowScript.triggers.length > 0
                  ? `${rowScript.triggers?.[0]?.type}.${rowScript.triggers?.[0]?.event_type}`
                  : 'None'}
              </span>
            );
          }

          if (header === 'name') {
            return (
              <div className="flex items-center gap-2 p-2">
                <Tooltip content={rowScript.description} className="max-w-lg">
                  <Icon icon="Info" />
                </Tooltip>
                <span>{rowScript.name}</span>
              </div>
            );
          }

          return <span className="p-2">{ctx.getValue() as string}</span>;
        }}
        hiddenColumns={['body', 'description']}
        capitalizeTableHeaders={true}
        enableRowSelection={true}
        onRowSelect={(script) =>
          // Enable multi-row selection is false, this is safe to cast to Script
          toggleSelectScript(script as Script)
        }
        enableSorting={true}
      />
    </div>
  );

  const withNoScriptSelected = (content: React.ReactNode) => {
    if (!selectedScript) {
      return (
        <NonIdealState
          icon="info-sign"
          description="Select a script to view it"
          title="No script selected"
        />
      );
    }

    return <>{content}</>;
  };

  const withNoScriptTrigger = (content: React.ReactNode) => {
    if (selectedScript?.triggers?.length === 0) {
      return (
        <NonIdealState
          icon="info-sign"
          description="No triggers are configured for this script"
          title="No triggers"
        />
      );
    }

    return <>{content}</>;
  };

  const renderScriptEditor = () => (
    <RowLayout rows={3} rowRatio={[0.06, 0.2, 1]}>
      <Row>
        <></>
      </Row>
      <Row align="start">
        <Column>
          <div className="flex flex-col gap-11">
            <div className="flex items-center justify-between">
              <Header className="bg-transparent text-xl !px-0 break-all min-w-[80px]">
                {selectedScript?.name}
              </Header>
              <div className="flex items-center gap-6">
                <Button
                  icon="share"
                  iconPlacement="right"
                  className={'w-32'}
                  onPress={() => {
                    window.open(CONFIGURE_SCRIPT_URL, '_blank');
                  }}
                >
                  Configure
                </Button>
                <Button
                  className={'w-32'}
                  onPress={async () => {
                    await publishSelectedScript();
                  }}
                >
                  Manual run
                </Button>
                <Button
                  icon="share"
                  iconPlacement="right"
                  className={'w-32'}
                  onPress={() => {
                    window.open(EDIT_SCRIPT_URL, '_blank');
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
            <p>{selectedScript?.description}</p>
          </div>
          <div className="flex flex-col gap-2">
            <Header className="bg-transparent text-xl !px-0">Triggers</Header>
            {withNoScriptTrigger(
              <>
                <p>
                  These are the triggers that have been configured for this
                  script on this mission
                </p>

                <Table<ScriptTrigger>
                  data={selectedScript?.triggers ?? []}
                  cellRenderer={(ctx) => (
                    <span className="p-2">{ctx.getValue() as string}</span>
                  )}
                  hiddenColumns={[
                    'mission',
                    'trigger.type',
                    'trigger.event_type',
                  ]}
                />
              </>
            )}
          </div>
        </Column>
      </Row>
      <Row align="start">
        <Textarea
          className="h-full font-mono"
          draggable={false}
          value={scriptBody}
          readOnly={true}
        />
      </Row>
    </RowLayout>
  );

  return (
    <DefaultLayout
      showMissionSelector
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'Scripts' }]} />
        </div>
      }
      className="p-10"
    >
      <ColumnLayout colRatio={[1, 1]} cols={2}>
        <Column style={{ maxWidth: '45vw' }}>
          <RowLayout rows={3} rowRatio={[0.06, 0.08, 1]}>
            <Row align="start">
              <Header className="bg-transparent text-xl !px-0">
                Scripting
              </Header>
            </Row>
            <Row align="start">
              <div className="flex items-center  w-full gap-6">
                <DebouncedInput
                  type="text"
                  debounceTimeMs={500}
                  className={'w-1/4'}
                  placeholder="Search Name"
                  onChange={(e) => setNameSearchQuery(e.target.value)}
                />
                <DebouncedInput
                  type="text"
                  debounceTimeMs={500}
                  className={'w-1/4'}
                  placeholder="Triggers"
                  onChange={(e) => setTriggerSearchQuery(e.target.value)}
                />
                <Button
                  onPress={() => {
                    toggleSubmitDialogOpen();
                  }}
                  className={'w-32'}
                >
                  Submit event
                </Button>
              </div>
            </Row>
            <Row align="start" style={{ maxWidth: '50vw' }}>
              {withNoResults(renderScriptsTable())}
            </Row>
          </RowLayout>
        </Column>

        <Column style={{ maxWidth: '45vw' }}>
          {withNoScriptSelected(renderScriptEditor())}
        </Column>
      </ColumnLayout>
      <Dialog
        isOpen={isSubmitDialogOpen}
        buttons={[]}
        hideCancelButton={true}
        onClose={() => {
          toggleSubmitDialogOpen();
        }}
        title="Submit event"
      >
        <div className="flex flex-col gap-2 mb-4" style={{ height: '40rem' }}>
          <div className="flex items-center gap-2">
            <Header className="bg-transparent text-xl !px-0 whitespace-nowrap">
              Event type
            </Header>
            <Select
              selectedKey={selectedEvent}
              onSelectionChange={(ev) => {
                resetEventToSubmit();
                setSelectedEvent(ev.toString());
              }}
              fill
            >
              {Object.keys(perEventParsedProperty ?? {}).map((ev) => (
                <ListBoxItem key={ev} id={ev}>
                  {ev}
                </ListBoxItem>
              ))}
            </Select>
            <Tooltip
              content="All required properties must be filled in"
              isDisabled={isSubmitButtonEnabled}
            >
              <Button
                intent="primary"
                isDisabled={!isSubmitButtonEnabled}
                onPress={async () => {
                  await submitNewEvent();
                }}
              >
                <span>Submit</span>
              </Button>
            </Tooltip>
          </div>
          <div className="flex flex-col gap-2 pb-6">
            {selectedEvent &&
              perEventParsedProperty &&
              Object.entries(perEventParsedProperty?.[selectedEvent]).map(
                ([propKey, schema]) => (
                  <EventSchemaRenderer
                    schema={schema}
                    key={schema.title}
                    propertyKey={propKey}
                    populateEventToSubmit={populateEventToSubmit}
                  />
                )
              )}
          </div>
          {getTriggersForSelectedEvent(triggers).length > 0 && (
            <div>
              <Header className="bg-transparent text-xl !px-0 whitespace-nowrap">
                Scripts to be triggered
              </Header>

              <div>
                {getTriggersForSelectedEvent(triggers).map((t) => {
                  return (
                    <div
                      className="h-8 odd:bg-item flex items-center"
                      key={t.script_name}
                    >
                      {t.script_name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </DefaultLayout>
  );
};

export default Scripts;
