import { useState, useCallback, useRef, useEffect } from 'react';
import {
  findIndex,
  remove,
  lowerCase,
  has,
  isNil,
  reject,
  filter,
} from 'lodash/fp';
import { get, isArray } from 'lodash';
import {
  DOWNLOAD_COMMANDS,
  UPLOAD_COMMANDS,
} from 'constants/rtiCommands/constants';
import {
  STATUS_ERROR,
  NO_ERROR_WHITELIST_TYPES,
  STATUS_PENDING,
  STATUS_SENT,
  STATUS_SUCCESS,
  FILE_TRANSFER_TYPE_TEXT,
  TELEMETRY_GET_SET_RESPONSE_TYPE,
  STATUS_ERROR_TELEMETRY_MATCH_PATTERN,
} from 'constants/ops/rti/oparate/constants';
import { useTransferProgress } from 'pages/ops/shared/context/TransferProgressProvider';
import { historyUpdateCompletedStatus } from 'actions/operate/actions';
import { useOperate } from '../../context/OperateProvider';

export type CommandHistoryPayload = {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | { [key: string]: CommandHistoryPayload }
    | { [key: string]: string }
    | undefined;
};

export interface ICommandHistory {
  command: string;
  status: string;
  payload?: CommandHistoryPayload;
  createdAt: string;
  sequenceId: number;
  responsesContent: IReply[];
  note: string;
  subjectID: string;
  procedureName: string;
  customCommandName: string;
  satelliteSequenceID: number;
  uuid?: string;
  progress?: number;
  commandCompleted?: boolean;
}

export type CmdReplyFile = {
  cloud_path: string;
  file_name: string;
  file_size: number;
  metadata: null;
  satellite_path: string;
  stage: string;
  stage_progress_percentage: number;
  stage_status: 'pending' | 'ongoing' | 'succeeded' | 'failed';
};

export interface IReply {
  sequenceId: number;
  status: string;
  data: {
    [key: string]:
      | string
      | number
      | number
      | CmdReplyFile
      | CmdReplyFile[]
      | string[];
  };
  links: null;
  meta: null;
  type: string;
  createdAt: string;
}

let FTP_STOP_STARTED: number | string | null = null;

//Exported for testing
export const updateCommandStatus = (
  newHistoryItem: ICommandHistory,
  reply: IReply
) => {
  const previousState = newHistoryItem.status;

  if (
    previousState === STATUS_SENT &&
    reply.type === 'commandAcknowledgement'
  ) {
    newHistoryItem.status = STATUS_PENDING;
  }

  if (
    previousState !== STATUS_ERROR &&
    reply.type !== 'commandAcknowledgement' &&
    reply.type !== STATUS_ERROR &&
    !has('data.ack', reply) &&
    reply.status !== STATUS_ERROR
  ) {
    newHistoryItem.status = STATUS_SUCCESS;
  }

  if (
    lowerCase(reply.type).includes(STATUS_ERROR) ||
    (has('data.error', reply) &&
      !NO_ERROR_WHITELIST_TYPES.includes(reply.type)) ||
    has('data.postMessage.errorMessage', reply) ||
    get(reply.data, 'is_error') ||
    (reply.type === TELEMETRY_GET_SET_RESPONSE_TYPE &&
      STATUS_ERROR_TELEMETRY_MATCH_PATTERN.test(
        get(reply.data, 'status') as string
      ))
  ) {
    newHistoryItem.status = STATUS_ERROR;
    reply.type = 'error';
  }

  if (reply.status === STATUS_ERROR) {
    newHistoryItem.status = STATUS_ERROR;
    reply.type = 'error';
  }
};

const useCommandHistory = () => {
  const { dispatchTransferUpdate } = useTransferProgress();
  const [commandHistory, setCommandHistory] = useState<ICommandHistory[]>([]);
  const unhandledResponses = useRef<IReply[]>([]);
  const { state, dispatch } = useOperate();

  const [newHistoryItem, setNewHistoryItem] = useState<ICommandHistory>();

  useEffect(() => {
    if (newHistoryItem && state.shouldFocusLastSentCommand) {
      dispatch(historyUpdateCompletedStatus(newHistoryItem));
    }
  }, [dispatch, newHistoryItem, state.shouldFocusLastSentCommand]);

  const checkAndHandleDownloadStart = useCallback(
    (newCommand: ICommandHistory, sequenceId: number | string) => {
      const startingDownload =
        DOWNLOAD_COMMANDS.includes(newCommand.command) &&
        isNil(newCommand.progress);
      const startingUpload =
        UPLOAD_COMMANDS.includes(newCommand.command) &&
        isNil(newCommand.progress);

      if (startingDownload || startingUpload) {
        const transferType = startingDownload
          ? FILE_TRANSFER_TYPE_TEXT.SATELLITE_TO_CLOUD
          : FILE_TRANSFER_TYPE_TEXT.CLOUD_TO_SATELLITE;

        dispatchTransferUpdate({
          id: `${sequenceId}`,
          type: 'start-transfer',
          name:
            typeof newCommand.payload?.source === 'string'
              ? newCommand.payload.source
              : '',
          destination:
            typeof newCommand.payload?.destination === 'string'
              ? newCommand.payload.destination
              : '',
          transferType,
          transferImplementation:
            newCommand.command === 'FtpDownloadArchiveOfSelectedFiles'
              ? 'Archive'
              : undefined,
          skipWarningAbortTransfer: true,
        });
      }
    },
    [dispatchTransferUpdate]
  );

  const getOldCmdFields = (reply: IReply) => {
    const replyHasUpdate = has('data.update', reply);
    const replyHasSuccess = has('data.success', reply);
    const replyHasList = has('data.list', reply);
    const replyHasError = has('data.error', reply);

    return {
      replyHasError,
      replyHasUpdate,
      replyHasList,
      replyHasSuccess,
      isOldCmd:
        replyHasError || replyHasUpdate || replyHasList || replyHasSuccess,
    };
  };

  const getNewCmdFields = (reply: IReply) => {
    const isPending = get(reply, 'data.status') === 'pending';
    const isOngoing = get(reply, 'data.status') === 'ongoing';
    const isSucceeded = get(reply, 'data.status') === 'succeeded';
    const isFailed = get(reply, 'data.status') === 'failed';
    const files = get(reply, 'data.files') as {
      cloud_path: string;
      file_name: string;
      file_size: number;
      metadata: null;
      satellite_path: string;
      stage: string;
      stage_progress_percentage: number;
      stage_status: 'pending' | 'ongoing' | 'succeeded' | 'failed';
    }[];
    const stages = get(reply, 'data.stages') as string[];

    const isNewCmd =
      has('data.status', reply) &&
      has('data.stages', reply) &&
      has('data.files', reply);

    const currentStage = isArray(files)
      ? files.reduce((acc, file) => {
          return (acc = file.stage);
        }, '')
      : '';

    return {
      isPending,
      isOngoing,
      isSucceeded,
      isFailed,
      files,
      stages,
      currentStage,
      isNewCmd,
    };
  };

  const handleNewCommandsDownloadProgress = useCallback(
    (newItem: ICommandHistory, reply: IReply, sequenceId: number) => {
      const {
        isPending,
        isFailed,
        isOngoing,
        isSucceeded,
        isNewCmd,
        currentStage,
        stages,
      } = getNewCmdFields(reply);

      const isAlreadySuccessful = newItem.responsesContent
        .map((rc) => get(rc, 'data.status') as string)
        .includes('succeeded');

      const isAlreadyFailed = newItem.responsesContent
        .map((rc) => get(rc, 'data.status') as string)
        .includes('failed');

      dispatchTransferUpdate({
        type: 'update-transfer-with-stages',
        id: `${sequenceId}`,
        stages,
      });

      if (!isNewCmd) return;

      if (isPending || isOngoing) {
        newItem.status = STATUS_PENDING;

        dispatchTransferUpdate({
          type: 'update-transfer-with-current-stage',
          id: `${sequenceId}`,
          currentStage: currentStage,
        });
      }

      if (
        isAlreadySuccessful ||
        (currentStage === stages?.[stages.length - 1] && isSucceeded)
      ) {
        newItem.status = STATUS_SUCCESS;
        newItem.progress = undefined;

        dispatchTransferUpdate({
          type: 'update-transfer-with-completed-percentage',
          id: `${sequenceId}`,
          reply,
        });
        return;
      }

      if (isFailed || isAlreadyFailed) {
        newItem.status = STATUS_ERROR;
        dispatchTransferUpdate({
          type: 'update-transfer-with-error',
          id: `${sequenceId}`,
          error: 'Could not complete transfer',
        });
        return;
      }

      dispatchTransferUpdate({
        type: 'update-new-cmd-transfer-status-through-reply',
        id: `${sequenceId}`,
        reply,
      });
    },
    [dispatchTransferUpdate]
  );

  //
  const handleOldCommandsDownloadProgress = useCallback(
    (newItem: ICommandHistory, reply: IReply, sequenceId: number) => {
      const {
        replyHasError,
        replyHasList,
        replyHasSuccess,
        replyHasUpdate,
        isOldCmd,
      } = getOldCmdFields(reply);

      if (!isOldCmd) return;

      if (replyHasUpdate || replyHasSuccess) {
        newItem.status = STATUS_PENDING;

        dispatchTransferUpdate({
          type: 'update-transfer-status-through-reply',
          id: `${sequenceId}`,
          reply,
        });
      }

      if (replyHasSuccess) {
        newItem.status = STATUS_SUCCESS;
        newItem.progress = undefined;
      }

      if (replyHasList) {
        const listFiles = get(reply, 'data.list.files', []) as {
          name: string;
        }[];
        const files = listFiles.map(({ name }) => name);

        dispatchTransferUpdate({
          type: 'update-transfer-with-list-of-files',
          id: `${sequenceId}`,
          files,
        });
      }

      if (replyHasError) {
        dispatchTransferUpdate({
          type: 'update-transfer-with-error',
          id: `${sequenceId}`,
          error: get(reply, 'data.error.message', 'Error') as string,
        });
      }
    },
    [dispatchTransferUpdate]
  );
  const checkAndHandleDownloadProgress = useCallback(
    (newItem: ICommandHistory, reply: IReply, sequenceId: number) => {
      const { isOldCmd } = getOldCmdFields(reply);
      const { isNewCmd } = getNewCmdFields(reply);

      if (isOldCmd) {
        handleOldCommandsDownloadProgress(newItem, reply, sequenceId);
        return;
      }

      if (isNewCmd) {
        handleNewCommandsDownloadProgress(newItem, reply, sequenceId);
        return;
      }
    },
    [handleNewCommandsDownloadProgress, handleOldCommandsDownloadProgress]
  );

  const handleFtpStopCommand = useCallback((sequenceId: number | string) => {
    FTP_STOP_STARTED = sequenceId;
  }, []);

  const handleFtpStopReply = useCallback(
    (reply: IReply) => {
      const succeeded = has('data.success', reply);
      const failed = has('data.error', reply);
      if (succeeded || failed) {
        dispatchTransferUpdate({
          type: 'stop-all-transfers-of-type',
          transferType: `${FILE_TRANSFER_TYPE_TEXT.SATELLITE_TO_CLOUD}`,
        });

        dispatchTransferUpdate({
          type: 'stop-all-transfers-of-type',
          transferType: `${FILE_TRANSFER_TYPE_TEXT.CLOUD_TO_SATELLITE}`,
        });

        FTP_STOP_STARTED = null;
      }
    },
    [dispatchTransferUpdate]
  );

  const getCommandHistoryWithNewCommand = useCallback(
    (prevHistory: ICommandHistory[], newCommand: ICommandHistory) => {
      const { sequenceId } = newCommand;

      const historyIndex = findIndex(['sequenceId', sequenceId], prevHistory);

      if (newCommand.command === 'FtpStop' && isNil(FTP_STOP_STARTED)) {
        handleFtpStopCommand(sequenceId);
      }

      checkAndHandleDownloadStart(newCommand, sequenceId);

      if (historyIndex >= 0) {
        const history = prevHistory[historyIndex];
        const responsesContent = [
          ...history.responsesContent,
          ...newCommand.responsesContent,
        ];

        responsesContent.push(
          ...filter(['sequenceId', sequenceId], unhandledResponses.current)
        );

        unhandledResponses.current = [
          ...reject(['sequenceId', sequenceId], unhandledResponses.current),
        ];

        let progress = history.progress;
        let status = history.status;
        if (status === STATUS_ERROR) {
          progress = undefined;
        }

        if (status === STATUS_SENT && newCommand.status === STATUS_PENDING) {
          status = STATUS_PENDING;

          checkAndHandleDownloadStart(newCommand, sequenceId);
        }

        if (newCommand.status === STATUS_ERROR) {
          status = STATUS_ERROR;
        }
        const command = newCommand.command
          ? newCommand.command
          : history.command;

        const cmd = Object.fromEntries(
          Object.keys(newCommand)
            .concat(Object.keys(history))
            .map((key) => {
              const k = key as keyof typeof newCommand;

              if (!newCommand[k] || newCommand[k] === '') {
                return [k, history[k]];
              }

              return [k, newCommand[k]];
            })
        ) as unknown as ICommandHistory;

        return [
          ...remove(['sequenceId', sequenceId], prevHistory),
          {
            ...cmd,
            responsesContent,
            status,
            progress,
            command,
          },
        ];
      } else {
        return [...prevHistory, newCommand];
      }
    },
    [checkAndHandleDownloadStart, handleFtpStopCommand]
  );

  const addCommandToHistory = useCallback(
    (newCommand: ICommandHistory) => {
      setCommandHistory((prevHistory) =>
        getCommandHistoryWithNewCommand(prevHistory, newCommand)
      );
    },
    [getCommandHistoryWithNewCommand]
  );

  const getNewReplyHistory = useCallback(
    (prevHistory: ICommandHistory[], reply: IReply) => {
      const { sequenceId } = reply;

      const historyIndex = findIndex(['sequenceId', sequenceId], prevHistory);

      if (sequenceId === FTP_STOP_STARTED) {
        handleFtpStopReply(reply);
      }

      if (historyIndex >= 0) {
        const newHistory = [...prevHistory];
        const responseContent = reply;

        const newItem: ICommandHistory = {
          ...newHistory[historyIndex],
        };

        if (reply.type === 'commandCompleted') {
          newItem.commandCompleted = true;

          // Setting the new history item state here
          // in order to trigger a re-render of the item
          // to show the command completed message correctly
          setNewHistoryItem(newItem);
        }

        newHistory[historyIndex] = newItem;

        updateCommandStatus(newItem, reply);

        checkAndHandleDownloadProgress(newItem, reply, sequenceId);

        if (reply.type !== 'commandCompleted') {
          newItem.responsesContent.push(responseContent);
        }

        return newHistory;
      } else {
        unhandledResponses.current.push(reply);
        return prevHistory;
      }
    },
    [checkAndHandleDownloadProgress, handleFtpStopReply]
  );

  const addReplyToCommand = useCallback(
    (reply: IReply) => {
      setCommandHistory((prevHistory) =>
        getNewReplyHistory(prevHistory, reply)
      );
    },
    [getNewReplyHistory]
  );

  // Batch updates for huge efficiency gain
  const handleNewHistoryCommandsAndReplies = useCallback(
    (newCommands: ICommandHistory[], newReplies: IReply[]) => {
      setCommandHistory((prevHistory) => {
        let newCommandHistory = newCommands.reduce(
          (acc, command) => getCommandHistoryWithNewCommand(acc, command),
          prevHistory
        );

        newCommandHistory = newReplies.reduce(
          (acc, reply) => getNewReplyHistory(acc, reply),
          newCommandHistory
        );

        return newCommandHistory;
      });
    },
    [getCommandHistoryWithNewCommand, getNewReplyHistory]
  );

  const replaceCommandNote = useCallback(
    (commandSequenceId: number | string, note: string) => {
      setCommandHistory((prevHistory) => {
        const newHistory = [...prevHistory];
        const commandIndex = newHistory.findIndex(
          (command) => command.sequenceId === commandSequenceId
        );

        newHistory[commandIndex] = { ...newHistory[commandIndex], note };

        return newHistory;
      });
    },
    []
  );

  return {
    commandHistory,
    addCommandToHistory,
    handleNewHistoryCommandsAndReplies,
    addReplyToCommand,
    setCommandHistory,
    replaceCommandNote,
  };
};

export default useCommandHistory;
