import type { ColumnProps } from 'ui/ColumnLayout/Column';

export type RowProps = ColumnProps;

export const Row = ({
  children,
  justify,
  style,
  align,
  ...props
}: RowProps) => {
  return (
    <div
      className="flex items-center flex-row h-full p-2 gap-2 color-surface"
      style={{ ...style, justifyContent: justify, alignItems: align }}
      data-testid={props['data-testid']}
    >
      {children}
    </div>
  );
};
