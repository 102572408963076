import { useEffect } from 'react';
import Editor from '@monaco-editor/react';
import type { IFolder } from '../../../services/api/ops/cloudStorage';
import type { IModeProps, IMode } from '..';
import { IconNames } from '@blueprintjs/icons';

const EpsLog: IMode<string> = ({
  fileDetails,
  renderedContent,
  setRenderedContent,
}: IModeProps<string>) => {
  useEffect(() => {
    if (fileDetails) {
      const { file } = fileDetails;

      let data = '';
      // Transform the base64 file into a uint8array
      const log_file = file;
      if (!log_file) {
        return;
      }
      const view = new DataView(log_file.buffer);
      // epslog entries are 32 bytes long
      for (let i = 0; i < log_file.length; i += 32) {
        // The first 4 bytes of an epslog entry are the timestamp
        const stamp = view.getUint32(i, true);
        // JS has a ms timestamp
        const date = new Date(stamp * 1000);
        data += date.toUTCString() + ' ';
        // the remaining bytes of the entry are a text string
        data +=
          new TextDecoder('ISO-8859-5').decode(log_file.slice(i + 4, i + 32)) +
          '\n';
      }
      setRenderedContent(data);
    }
  }, [fileDetails, setRenderedContent]);

  return (
    <Editor
      options={{
        lineNumbersMinChars: 5,
        readOnly: true,
      }}
      value={renderedContent}
    />
  );
};

EpsLog.isValid = (_: IFolder) => {
  return true; // TODO: How to know if it is valid?
};

EpsLog.isPreferred = (fileDetails?: IFolder) => {
  return Boolean(fileDetails?.name.includes('.epslog'));
};

// Not in use because it is not editable
EpsLog.saveContent = (_: string) => new Uint8Array();
EpsLog.isEditable = false;

EpsLog.icon = IconNames.HISTORY;

EpsLog.id = 'eps-log';

export default EpsLog;
