import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import classNames from 'classnames';
import { Tooltip, Button } from 'opencosmos-ui';

export type ResultCardButtonProps = {
  tooltip: string;
  isActive?: boolean;
  icon: IconName;
  onPress: () => void;
  ['data-testid']?: string;
  disabled?: boolean;
};

export const ResultCardButton = (props: ResultCardButtonProps) => {
  return (
    <Tooltip content={props.tooltip} placement="left">
      <Button
        icon={props.icon}
        size={'md'}
        isMinimal
        isTransparent
        className={classNames(
          {
            'stroke-neutral-900 bg-accent dark:bg-accent-dark hover:bg-accent dark:hover:bg-accent-dark':
              props.isActive,
            'stroke-neutral-900 dark:stroke-neutral-200 hover:bg-item-hover dark:hover:bg-item-dark-hover':
              !props.isActive && !props.disabled,
            'opacity-50 stroke-neutral-900 dark:stroke-neutral-200 bg-item dark:bg-item-dark cursor-not-allowed':
              props.disabled,
          },
          'w-8 h-8 flex justify-center items-center'
        )}
        onPress={props.onPress}
        data-testid={props['data-testid']}
        isDisabled={props.disabled}
      />
    </Tooltip>
  );
};
