export const DATE_FORMAT = 'DD/MM/YYYY';

export const TIME_FORMAT = 'HH:mm:ss';

export const TIME_FORMAT_NO_SECONDS = `HH:mm`;

export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATETIME_FORMAT_NO_SECONDS = `${DATE_FORMAT} HH:mm`;

export const ISO_FORMAT = `YYYY-MM-DD[T]HH:mm:ss.SSS[Z]`;
export const ISO_FORMAT_NO_MILLISECONDS = `YYYY-MM-DD[T]HH:mm:ss[Z]`;

export const DURATION_FORMAT = 'm[m] s[s]';

export const DURATION_FORMAT_WITH_HOURS = 'H[h] m[m] s[s]';
