import { Spinner } from '@blueprintjs/core';
import Button from '_molecules/Button/Button';
import LineChart from '_organisms/Charts/LineChart/LineChart';
import classNames from 'classnames';
import { usePixelMode } from 'datacosmos/stores/PixelModeProvider';
import { isSingleBandChartData } from 'datacosmos/utils/hooks/pixelModes/useLinePixels';
import React, { useState } from 'react';
import { json2csv } from 'json-2-csv';
import { downloadFilUrlAsExtension } from 'datacosmos/download/geojson';
import { screenshot } from 'utils/screenshot';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import OptionsDialog from '_organisms/OptionsDialog/OptionsDialog';
import { Tab, TabList, TabPanel, Tabs } from 'opencosmos-ui';

type Props = {
  rightMenuOpen: boolean;
};

const LinePixelModeChart = ({ rightMenuOpen }: Props) => {
  const {
    linePixelsChartData,
    isFetchingLinePixels,
    isEnabled,
    isLinePixelValuesModeToggled,
  } = usePixelMode();

  const { translate } = useLocalisation();

  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<'horizontal' | 'vertical'>(
    'horizontal'
  );

  const chartRefs = {
    horizontal: React.createRef<HTMLDivElement>(),
    vertical: React.createRef<HTMLDivElement>(),
  };

  const getLinesForPixelValues = (axis: 'horizontal' | 'vertical') => {
    if (!linePixelsChartData) {
      return [];
    }
    if (isSingleBandChartData(linePixelsChartData[axis])) {
      return [{ color: 'gray', key: 'value' }];
    }

    return [
      { color: 'red', key: 'rValue' },
      { color: 'green', key: 'gValue' },
      { color: 'blue', key: 'bValue' },
    ];
  };

  const withAbsoluteContainer = (children: React.ReactNode) => (
    <div
      className={classNames(
        'dark:bg-surface-dark bg-surface dark:text-item-dark-contrast',
        'p-2 flex flex-col h-fit w-fit min-w-[400px] absolute right-[5rem] top-[60px]'
      )}
      style={{ right: rightMenuOpen ? '460px' : '5rem' }}
    >
      {children}
    </div>
  );

  if (!isEnabled || !isLinePixelValuesModeToggled) {
    return null;
  }

  if (isFetchingLinePixels) {
    return withAbsoluteContainer(<Spinner />);
  }

  if (!linePixelsChartData) {
    return withAbsoluteContainer(
      <div className="flex items-center w-full">
        {translate('datacosmos.layers.pixelOperationsChart.linePixels.noData')}
      </div>
    );
  }

  return withAbsoluteContainer(
    <>
      <Button
        text={translate(
          'datacosmos.layers.pixelOperationsChart.linePixels.download',
          { axis: selectedTab }
        )}
        className="relative mb-2"
        onPress={() => setIsDownloadModalOpen(true)}
      />
      <Tabs className="pb-0">
        <TabList>
          <Tab id={'1'} key={'1'}>
            <div onClick={() => setSelectedTab('horizontal')}>
              {translate(
                'datacosmos.layers.pixelOperationsChart.linePixels.horizontalPixels'
              )}
            </div>
          </Tab>

          <Tab id={'2'} key={'2'}>
            <div onClick={() => setSelectedTab('vertical')}>
              {translate(
                'datacosmos.layers.pixelOperationsChart.linePixels.verticalPixels'
              )}
            </div>
          </Tab>
        </TabList>

        <TabPanel id="1" key={'1'}>
          <LineChart
            lines={getLinesForPixelValues('horizontal')}
            data={linePixelsChartData.horizontal}
            lineDot={false}
            xAxisKey="longitude"
            height={250}
            showLegend={false}
            showGrid={false}
            xAxisLabelFormatter={(d: string) => Number(d).toFixed(2) + '°'}
            xLabel={translate(
              'datacosmos.layers.pixelOperationsChart.linePixels.xAxisLabelHorizontal'
            )}
            yLabel={translate(
              'datacosmos.layers.pixelOperationsChart.yAxisLabel'
            )}
            reference={chartRefs.horizontal}
          />
        </TabPanel>

        <TabPanel id="2" key={'2'}>
          <LineChart
            data={linePixelsChartData.vertical}
            lines={getLinesForPixelValues('vertical')}
            lineDot={false}
            xAxisKey="latitude"
            height={250}
            showLegend={false}
            showGrid={false}
            yValueRange={[0, 255]}
            yValueTicksValue={[0, 63, 127, 191, 255]}
            xAxisLabelFormatter={(d: string) => Number(d).toFixed(2) + '°'}
            xLabel={translate(
              'datacosmos.layers.pixelOperationsChart.linePixels.xAxisLabelVertical'
            )}
            yLabel={translate(
              'datacosmos.layers.pixelOperationsChart.yAxisLabel'
            )}
            reference={chartRefs.vertical}
          />
        </TabPanel>
      </Tabs>

      <OptionsDialog
        options={[
          {
            name: 'PNG',
            onClick: async () => {
              if (!chartRefs[selectedTab].current) {
                return;
              }

              await screenshot(chartRefs[selectedTab].current, {
                mimeType: 'image/png',
              }).then((imgString) => {
                downloadFilUrlAsExtension(
                  imgString,
                  `${selectedTab}_pixel_data_${new Date().toISOString()}`,
                  'png'
                );
              });
            },
          },
          {
            name: 'CSV',
            onClick: async () => {
              const csv = await json2csv(linePixelsChartData[selectedTab]);
              downloadFilUrlAsExtension(
                csv,
                `${selectedTab}_pixel_data_${new Date().toISOString()}`,
                'csv'
              );
            },
          },
        ]}
        title={translate(
          'datacosmos.layers.pixelOperationsChart.linePixels.downloadDataAs'
        )}
        isOpen={isDownloadModalOpen}
        setIsOpen={setIsDownloadModalOpen}
      />
    </>
  );
};

export default LinePixelModeChart;
