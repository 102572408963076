import moment from 'moment';
import {
  DATETIME_FORMAT,
  DATETIME_FORMAT_NO_SECONDS,
  DATE_FORMAT,
  ISO_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_NO_SECONDS,
} from 'constants/datetime';

/**
 * @description Fix for date/time pickers
 *              if `utc` is `true`, then current timezone will be subtracted from
 *              `date`
 *              if `utc` is `false`, then current timezone will be added to
 *              `date`
 * @return {function(): Date}
 * @param date
 * @param utc
 */
export const toTimezone = (date: Date, utc?: boolean): Date => {
  if (!date) return date;

  const newDate = new Date(date);

  const timeZone = newDate.getTimezoneOffset() * 60000;

  newDate.setTime(
    utc ? newDate.getTime() - timeZone : newDate.getTime() + timeZone
  );

  return newDate;
};

export const toFormattedDateTimeUTC = (date: string | Date) =>
  moment.utc(date).format(DATETIME_FORMAT);

export const toFormattedDateTime = (date: string | Date) =>
  moment(date).format(DATETIME_FORMAT);

export const toFormattedDate = (date: string | Date) =>
  moment(date).format(DATE_FORMAT);

export const toFormattedDateNoSeconds = (date: string | Date) =>
  moment.utc(date).format(DATETIME_FORMAT_NO_SECONDS);

export const toFormattedTime = (date: string | Date) =>
  moment.utc(date).format(TIME_FORMAT);

export const toFormattedTimeNoSeconds = (date: string | Date) =>
  moment.utc(date).format(TIME_FORMAT_NO_SECONDS);

export const getCurrentUtcDate = () => moment.utc().toISOString();

export const durationToReadableFormat = (duration: number) => {
  const date = moment.utc(duration * 1000);

  if (date.hours()) {
    return `${date.hours()} hour ${date.minutes()} min ${date.seconds()} sec`;
  }

  return date.minutes()
    ? `${date.minutes()} min ${date.seconds()} sec`
    : `${date.seconds()} sec`;
};

export const now = () => {
  return new Date();
};

export const dateAsUTCDateObject = (date: Date) => {
  return new Date(
    new Date(date).toLocaleString('en-US', { timeZone: 'Etc/UTC' })
  );
};

export const getMonthName = (month: number, locale?: string) => {
  const date = new Date();
  date.setMonth(month - 1);
  return date.toLocaleString(locale ?? 'en-US', { month: 'long' });
};

export const roundToNearestSecond = (toRound: moment.Moment) => {
  const ms = toRound.millisecond();

  if (ms >= 500) {
    return toRound.add(1, 'second').startOf('second');
  } else {
    return toRound.startOf('second');
  }
};

/**
 * isoStringToMomentObject converts ISO string to moment object
 * @param dateIsoString date in ISO format
 * @returns moment object
 */
export const isoStringToMomentObject = (
  dateIsoString: string
): moment.Moment => {
  return moment(dateIsoString);
};

/**
 * momentObjToISOString converts moment object to ISO string
 * @param momentObj date as moment object
 * @returns ISO string
 */
export const momentObjToISOString = (
  momentObj: moment.Moment,
  format?: string
) => {
  return momentObj.utc().format(format ?? ISO_FORMAT);
};

/**
 * jsDateToMomentObj converts js date to moment date object
 * @param date js date object
 * @returns moment date object
 */
export const jsDateToMomentObj = (date: Date | undefined) => {
  return moment(date);
};

/**
 * momentObjToJsDate converts moment date object to js date
 * @param momentObj moment date object
 * @returns js date object
 */
export const momentObjToJsDate = (momentObj: moment.Moment) => {
  return momentObj.toDate();
};

/**
 * momentObjToUnixMs converts moment date object to unix ms.
 * Does a valueOf() on a moment object
 * @param momentObj moment date object
 * @returns unix ms
 */
export const momentObjToUnixMs = (momentObj: moment.Moment) => {
  return momentObj.valueOf();
};

/**
 * unixMsToMomentObj converts unix ms to moment object
 * @param unixMs unix ms
 * @returns moment object
 */
export const unixMsToMomentObj = (unixMs: number) => {
  return moment(unixMs);
};
