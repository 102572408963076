/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IColorChangable } from './geojsonLayer';
import { getPopupContentWithArea } from './helpers';
import type { ILayerOptions, LayerSourceType } from './layer';
import { Layer } from './layer';

export function CircleLayerFactory(
  sourceType: LayerSourceType,
  name: string,
  data: GeoJSON.GeoJSON,
  areaInM2: number,
  radius: number,
  center: GeoJSON.Point,
  leafletLayerMetadata: unknown,
  options?: Partial<ILayerOptions & IGeoJSONLayerOptions>
) {
  return new CircleLayer(
    sourceType,
    name,
    data,
    areaInM2,
    radius,
    center,
    leafletLayerMetadata,
    options
  );
}

export interface IGeoJSONLayerOptions {
  color: string;
  weight: number;
  fillOpacity: number;
  visible: boolean;
}

export const DefaultGeometryLayerOptions = {
  color: '#e4695e',
  weight: 1,
  fillOpacity: 0.5,
  visible: true,
};

const KM2 = 1000000;
const ACCURACY_LIMIT = 10000000;

/**
 *Geometry layer is a GeoJSON layer for displaying user drawn shapes with measurements included
 */
export class CircleLayer extends Layer implements IColorChangable {
  declare options: ILayerOptions & IGeoJSONLayerOptions;
  declare layerClass: string;
  name: string;
  data: GeoJSON.GeoJSON;
  areaInM2: number;
  radiusInKm: number;
  center: GeoJSON.Point;
  description: string | undefined;
  editing?: unknown;
  leafletLayerMetadata: any;

  /**
   * newDataCosmosLayer creates a new datacosoms layer
   */
  constructor(
    sourceType: LayerSourceType,
    name: string,
    data: GeoJSON.GeoJSON,
    areaInM2: number,
    radiusInKm: number,
    center: GeoJSON.Point,
    leafletLayerMetadata: any,
    options?: Partial<ILayerOptions & IGeoJSONLayerOptions>
  ) {
    super(sourceType);

    this.name = name;
    this.data = data;
    this.areaInM2 = areaInM2;
    this.radiusInKm = radiusInKm;
    this.center = center;
    this.options = {
      ...this.options,
      ...DefaultGeometryLayerOptions,
      ...options,
    };
    this.leafletLayerMetadata = leafletLayerMetadata;
    this.layerClass = 'CircleLayer';
  }
  setColor(color: string): void {
    this.options.color = color;
  }

  getName() {
    return this.name || this.id;
  }

  getCenter() {
    return this.center;
  }

  getDescription() {
    if (this.description) {
      return this.description;
    }
    return null;
  }

  getRadius() {
    return `Radius ${Number(this.radiusInKm).toFixed(2)} km`;
  }

  getMeasurements() {
    return 'Area: ' + Number(this.areaInM2 / KM2).toFixed(2) + ' km²';
  }

  getLeafletLayerMetadata() {
    return this.leafletLayerMetadata;
  }

  isAccurate() {
    return this.areaInM2 / KM2 < ACCURACY_LIMIT;
  }

  getPopupContent() {
    return getPopupContentWithArea(
      this.name,
      Number(this.areaInM2 / KM2).toFixed(2)
    );
  }

  cloneWithGeoJSON(data: GeoJSON.Feature): this {
    const newObject = this.clone();
    newObject.data = data;
    return newObject;
  }

  cloneWithMeasurements(areaInM2: number): this {
    const newObject = this.clone();
    newObject.areaInM2 = areaInM2;
    return newObject;
  }

  cloneWithRadius(radiusInKm: number): this {
    const newObject = this.clone();
    newObject.radiusInKm = radiusInKm;
    return newObject;
  }

  containsSTACItem() {
    return false;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra() {
    return false;
  }

  sza(): Number | undefined {
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }
}
