import { drawingControllerManager } from './options';
import type { IMapWrapper } from '../../../declarations/mapDeclarations/Map';
import type { IDrawingManagerWrapper } from '../../../declarations/mapDeclarations/DrawinManager';

export const addDrawingManager = (map: IMapWrapper): IDrawingManagerWrapper => {
  const drawingManager = new google.maps.drawing.DrawingManager(
    drawingControllerManager(google)
  ) as IDrawingManagerWrapper;

  const setDrawingModeFunc = drawingManager.setDrawingMode.bind(drawingManager);
  drawingManager.setDrawingMode = (...args) => {
    setDrawingModeFunc(...args);
    map.onChangeDrawingMode?.(drawingManager.getDrawingMode());
  };

  drawingManager.setMap(map);
  drawingManager.isGroundStation = false;
  return drawingManager;
};
