import React, { useRef } from 'react';
import { useLocale, useRangeCalendar } from 'react-aria';
import { useRangeCalendarState } from 'react-stately';
import type { CalendarDate } from '@internationalized/date';
import { createCalendar, parseDate } from '@internationalized/date';

import type { RangeCalendarStateOptions } from '@react-stately/calendar';
import moment from 'moment';
import CalendarHeader from '../Common/CalendarHeader';
import CalendarGrid from '../Common/CalendarGrid';

interface IRangeCalendarProps
  extends Omit<Omit<RangeCalendarStateOptions, 'locale'>, 'createCalendar'> {
  highligtedDates: Date[];
}

const RangeCalendar = (props: IRangeCalendarProps) => {
  const { locale } = useLocale();

  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
    visibleDuration: { months: 2 },
  });

  const ref = useRef<HTMLDivElement>(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
    props,
    state,
    ref
  );
  let parsedHighlightedDates: CalendarDate[] = [];
  try {
    parsedHighlightedDates = props.highligtedDates.map((d) =>
      parseDate(moment(d).format('YYYY-MM-DD'))
    );
  } catch (error) {
    parsedHighlightedDates = [];
  }

  return (
    <div {...calendarProps} ref={ref}>
      <div>
        <CalendarHeader
          calendarProps={calendarProps}
          prevButtonProps={prevButtonProps}
          nextButtonProps={nextButtonProps}
          state={state}
        />
      </div>
      <div className="flex gap-2 ">
        <CalendarGrid state={state} highlightedDates={parsedHighlightedDates} />
        <CalendarGrid
          state={state}
          highlightedDates={parsedHighlightedDates}
          offset={{ months: 1 }}
        />
      </div>
    </div>
  );
};

export default RangeCalendar;
