import {
  getActivitiesByMissionId,
  getActivityParameters,
  patchActivity,
} from '_api/activities/service';
import type {
  Activity,
  ActivityParameters,
  ActivityStatus,
} from '_api/activities/types';
import type { TaskingRequest } from '_api/tasking/service';
import { getTaskingRequest } from '_api/tasking/service';
import { useQuery } from '_api/useQuery';
import { useQuery as useTanQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import getFieldsBySchema from 'pages/ops/RTI/Operate/utils/rtiCommands/getFieldsBySchema';
import parseRefDefinitions from 'pages/ops/RTI/Operate/utils/rtiCommands/parseRefDefinitions';
import type { CommandProperties } from '_api/gateway/types';

type Props = {
  children: React.ReactNode;
};

export type ViewMode = 'list' | 'calendar' | 'table';

export type FromToDate = {
  from: Date | null;
  to: Date | null;
};

export type UpdatedActivityValue = {
  status?: ActivityStatus;
  operator_notes?: string;
  parameters?: Partial<ActivityParameters>;
  status_reason?: string | undefined;
};

export type UpdatedActivityMap = {
  [activityId: string]: UpdatedActivityValue;
};

export type UpdateActivityOptions = {
  /**
   * If true, the state of the activity will be updated. Default is true
   */
  updateState?: boolean;
};

type ActivitiesProviderType = ReturnType<typeof useActivitiesProvider>;

const ActivitiesContext = createContext<ActivitiesProviderType>(
  null as unknown as ActivitiesProviderType
);

export const useActivities = () => useContext(ActivitiesContext);

const useActivitiesProvider = () => {
  const { search } = useLocation();
  const history = useHistory();
  const { pathname } = useLocation();

  const urlMatch = useRouteMatch<{ mission: string }>({
    path: '/ops/mission/:mission/:route*',
    exact: false,
  });

  const urlMatchWithActivity = useRouteMatch<{
    mission: string;
    activity: string;
  }>('/ops/mission/:mission/schedule/activity/:activity');

  const params = new URLSearchParams(search);

  const missionId = urlMatch ? urlMatch.params.mission : '55';

  const [viewMode, setViewMode] = useState<ViewMode>(
    (params.get('view') as ViewMode) ?? 'list'
  );
  const [fromToDate, setFromToDate] = useState<FromToDate>(() => {
    const from = params.get('from');
    const to = params.get('to');
    return from && to
      ? {
          from: moment.utc(from).toDate(),
          to: moment.utc(to).toDate(),
        }
      : {
          from: moment.utc().startOf('day').toDate(),
          to: moment.utc().startOf('day').add(7, 'days').toDate(),
        };
  });

  const [requests, setRequests] = useState<TaskingRequest[]>([]);
  const [isFetchingRequests, setIsFetchingRequests] = useState<boolean>(false);
  const [updatedActivitiesMap, setUpdatedActivitiesMap] =
    useState<UpdatedActivityMap>();

  const [isSaveChangesButtonEnabled, setIsSaveChangesButtonEnabled] =
    useState<boolean>(false);

  const [isUpdatingActivities, setIsUpdatingActivities] =
    useState<boolean>(false);

  const [shouldUpdateState, setShouldUpdateState] = useState<boolean>(true);

  const {
    data: activities,
    refetch,
    loading: isFetchingActivities,
  } = useQuery(getActivitiesByMissionId, {
    skip: !fromToDate.from || !fromToDate.to,
    params: {
      missionId: missionId,
      startDate: fromToDate?.from?.toISOString() ?? moment.utc().toISOString(),
      endDate:
        fromToDate?.to?.toISOString() ??
        moment.utc().add(7, 'days').toISOString(),
    },
    initialData: [],
  });

  const selectedActivity = useMemo(() => {
    return activities?.find(
      ({ id }) => id === urlMatchWithActivity?.params.activity
    );
  }, [activities, urlMatchWithActivity?.params.activity]);

  const activitiesRequestIds = useMemo(() => {
    return [...new Set(activities.map((a) => a.tasking_request_ids[0]))].filter(
      (id) => id !== undefined
    );
  }, [activities]);

  const loading = useMemo(
    () => isFetchingRequests || isFetchingActivities,
    [isFetchingActivities, isFetchingRequests]
  );

  const [modifiableActivities, setModifiableActivities] =
    useState<Activity[]>(activities);

  const { data: activityParams } = useTanQuery({
    queryKey: [missionId],
    queryFn: async () => {
      const { data: d } = await getActivityParameters({
        params: {
          missionId,
        },
      });

      return d;
    },
    enabled: Boolean(missionId),
  });

  const shouldDisableActivityUpdate = useMemo(
    () =>
      Object.values(updatedActivitiesMap ?? {})
        .filter((a) => a.status === 'CANCELLED' || a.status === 'FAILED')
        .some((a) => !a.status_reason),
    [updatedActivitiesMap]
  );

  const failureReasonSchema = useMemo(
    () =>
      getFieldsBySchema(
        parseRefDefinitions(
          activityParams?.status_reason?.IMAGE_ACQUISITION ?? {}
        ) as CommandProperties
      ),
    [activityParams?.status_reason]
  );

  const fetchTaskingRequestById = useCallback(async (id: string) => {
    const { data } = await getTaskingRequest({ params: { requestId: id } });
    if (!data) {
      return undefined;
    }
    return data;
  }, []);

  const fetchAllActivitiesRequests = useCallback(async () => {
    setIsFetchingRequests(true);
    const requestPromises = activitiesRequestIds.map((id) =>
      fetchTaskingRequestById(id)
    );

    const reqs = (await Promise.all(requestPromises)).filter(
      (r) => r !== undefined
    ) as TaskingRequest[];

    setRequests(reqs);

    setIsFetchingRequests(false);
  }, [activitiesRequestIds, fetchTaskingRequestById]);

  const updateActivityState = useCallback(() => {
    if (!updatedActivitiesMap) {
      return;
    }

    setModifiableActivities((prev) => {
      const updatedActivities = prev.map((activity) => {
        if (updatedActivitiesMap[activity.id]) {
          return {
            ...activity,
            ...updatedActivitiesMap[activity.id],
            parameters: {
              ...activity.parameters,
              ...updatedActivitiesMap[activity.id].parameters,
              session: {
                ...activity.parameters.session,
                ...updatedActivitiesMap[activity.id].parameters?.session,
              },
              metrics: {
                ...activity.parameters.metrics,
                ...updatedActivitiesMap[activity.id].parameters?.metrics,
              },
            },
          };
        }
        return activity;
      });

      return updatedActivities;
    });
  }, [updatedActivitiesMap]);

  const updateActivity = useCallback(
    (
      activityId: string,
      value: UpdatedActivityValue,
      options?: UpdateActivityOptions
    ) => {
      setUpdatedActivitiesMap((prev) => {
        return {
          ...prev,
          [activityId]: {
            ...prev?.[activityId],
            ...value,
          },
        };
      });

      setShouldUpdateState(options?.updateState ?? true);
      setIsSaveChangesButtonEnabled(true);
      setIsUpdatingActivities(false);
    },
    []
  );

  const batchSubmitUpdateActivities = useCallback(async () => {
    if (!updatedActivitiesMap) {
      return;
    }
    setIsUpdatingActivities(true);
    await Promise.all(
      Object.entries(updatedActivitiesMap).map(
        async ([activityId, value]) =>
          await patchActivity({
            params: {
              activityId,
              missionId,
            },
            body: value,
          })
      )
    );

    setShouldUpdateState(true);
    updateActivityState();

    setIsUpdatingActivities(false);
    setUpdatedActivitiesMap(undefined);
    setIsSaveChangesButtonEnabled(false);
  }, [missionId, updateActivityState, updatedActivitiesMap]);

  const refetchActivities = useCallback(async () => {
    await refetch();
    setIsSaveChangesButtonEnabled(false);
    setUpdatedActivitiesMap(undefined);
  }, [refetch]);

  const isActivityEditable = useCallback((activity: Activity) => {
    return (
      activity.status !== 'CANCELLED' &&
      activity.status !== 'FAILED' &&
      activity.status !== 'EXPIRED' &&
      activity.status !== 'SCHEDULED'
    );
  }, []);

  const resetStatusReason = () => {
    if (selectedActivity) {
      const withNoStatusReason =
        updatedActivitiesMap?.[selectedActivity.id] ?? {};

      delete withNoStatusReason.status_reason;

      setUpdatedActivitiesMap((prev) => {
        return {
          ...prev,
          [selectedActivity.id]: withNoStatusReason,
        };
      });
    }
  };

  useEffect(() => {
    void fetchAllActivitiesRequests();
  }, [fetchAllActivitiesRequests]);

  useEffect(() => {
    if (!fromToDate.from || !fromToDate.to) {
      return;
    }

    history.replace(
      `${pathname}?from=${fromToDate.from.toISOString()}&to=${fromToDate.to.toISOString()}&view=${viewMode}`
    );
  }, [history, pathname, fromToDate, viewMode]);

  useEffect(() => {
    setModifiableActivities(activities);
  }, [activities]);

  const dbounceTimeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!shouldUpdateState) {
      return;
    }

    if (dbounceTimeout.current) {
      clearTimeout(dbounceTimeout.current);
    }

    dbounceTimeout.current = setTimeout(() => {
      updateActivityState();
    }, 500);
  }, [shouldUpdateState, updateActivityState]);

  useEffect(() => {
    if (viewMode === 'table') {
      history.push(`/ops/mission/${missionId}/schedule${location.search}`);
    }
  }, [history, missionId, viewMode]);

  return {
    activities: modifiableActivities,
    viewMode,
    refetchActivities,
    isLoading: loading,
    selectedActivity,
    requests,
    fromToDate,
    setFromToDate,
    setViewMode,
    updateActivity,
    isSaveChangesButtonEnabled,
    batchSubmitUpdateActivities,
    updatedActivitiesMap,
    isUpdatingActivities,
    isActivityEditable,
    failureReasonSchema,
    resetStatusReason,
    shouldDisableActivityUpdate,
  };
};

const ActivitiesProvider = ({ children }: Props) => {
  return (
    <ActivitiesContext.Provider value={useActivitiesProvider()}>
      {children}
    </ActivitiesContext.Provider>
  );
};

export default ActivitiesProvider;
