import type { Store } from 'redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from 'pages/shared/state/reducers/rootReducer';
import * as Sentry from '@sentry/react';
import { MODE } from 'env';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export type AppState = ReturnType<typeof rootReducer>;
export let scopedStore: Store | null = null;
const defaultInitialState = {};
const configureStore = (initialState = defaultInitialState): Store => {
  let store: Store;
  if (MODE !== 'production') {
    store = createStore(
      rootReducer,
      initialState,
      composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer)
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      compose(applyMiddleware(thunk), sentryReduxEnhancer)
    );
  }
  scopedStore = store;
  return store;
};

export default configureStore;
