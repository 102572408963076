import { parseAsBbox, parseAsPoint, parseAsPolygon } from './coordinateParsers';
import { convertToEPSG4326 } from './coordinateConverter';
import type { EPSG } from './epsgCoordinates';
import {
  dmsCoordinatesStringToDecimalString,
  isGeodetic,
  numberArrayToCoordinateObject,
} from './coordinateHelpers';

export type Coordinates = {
  lng: number;
  lat: number;
};

const PARSERS = [parseAsPoint, parseAsBbox, parseAsPolygon];

/**
 * pruneCoordinateString takes a coordinate string and strips it of everything except dots, commas and numbers.
 * Useful if user inputs a coordinate array from a GeoJSON object, for example.
 * @param coord comma separated coordinate string
 * @returns pruned coordinate string
 */
const pruneCoordinateString = (coord: string) => {
  return coord
    .replace(/:/g, ' ')
    .replace(/[^.\-,0-9\s]/g, '')
    .replace(/(?<=\S)\s+(?=\S)/g, ',')
    .replace(/\s+/g, '')
    .replace(/(,)\1+/g, '$1');
};

/**
 * parseCoordinateString parses pairs of valid coordinates, in string form, into `Coordinate` object array
 * @param coord coordinate string
 * @returns `Coordinate` object array
 */
const parseCoordinateString = (coord: string, epsg: EPSG) => {
  let coordString = '';

  coordString = isGeodetic(epsg)
    ? dmsCoordinatesStringToDecimalString(coord)
    : coord;
  coordString = pruneCoordinateString(coordString);

  const coordArray = coordString.split(',').map((s) => parseFloat(s));

  const coordinates = numberArrayToCoordinateObject(coordArray);

  if (epsg) {
    const converted = convertToEPSG4326(coordinates, epsg);
    return numberArrayToCoordinateObject(converted);
  }

  return coordinates;
};

/**
 * coordinateStringToGeoJson takes a comma separated string of coordinates and parses them into a GeoJSON feature
 * @param coordStr comma separated coordinate string
 * @returns appropriate GeoJSON given the coordinates
 */
export const coordinateStringToGeoJson = (coordStr: string, epsg: EPSG) => {
  const coordinates: Coordinates[] = parseCoordinateString(coordStr, epsg);

  return PARSERS.map((parser) => {
    return parser(coordinates);
  }).filter((result) => result !== undefined)[0];
};
