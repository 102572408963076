import type { IPolygonWrapper } from '../../../../declarations/mapDeclarations/Polygon';
import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../pages/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import { editing } from './events';
import polygonSelfIntersecting from '../../../../utils/polygonSelfIntersecting';
import { toaster } from 'toaster';
type LatLng = google.maps.LatLng;
type LatLngLiteral = google.maps.LatLngLiteral;

export const setPathsEvents =
  (
    region: IPolygonWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch): void => {
    region.getPaths().forEach((path, index): void => {
      google.maps.event.addListener(
        path,
        'insert_at',
        (vertex: number): void => {
          const pathData = path
            .getArray()
            .map((point: LatLng): LatLngLiteral => point.toJSON());
          if (polygonSelfIntersecting({ vertex, full: false }, pathData)) {
            const message = 'Region is self intersecting!';
            toaster.show({ icon: 'delete', intent: 'danger', message });
            path.removeAt(vertex);
          } else {
            dispatch(editing(path, vertex, index, region));
          }
        }
      );
      google.maps.event.addListener(
        path,
        'remove_at',
        (vertex: number): void => {
          dispatch(editing(path, vertex, index, region));
        }
      );
      google.maps.event.addListener(
        path,
        'set_at',
        (vertex: number, previous: google.maps.LatLng): void => {
          const pathData = path
            .getArray()
            .map((point: LatLng): LatLngLiteral => point.toJSON());
          if (polygonSelfIntersecting({ vertex, full: false }, pathData)) {
            const message = 'Region is self intersecting!';
            toaster.show({ icon: 'delete', intent: 'danger', message });
            path.setAt(vertex, previous);
          } else {
            dispatch(editing(path, vertex, index, region));
          }
        }
      );
    });
  };
