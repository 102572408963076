import { apiFetchHandler } from '_api/clientFetch';
import { websocketHost, hosts } from '_api/hosts';
import { websocketClient } from '_api/websocketClient';
import type { ActiveSessionData, TerminalSession } from './types';

export type TerminalSessionClientParams = {
  sessionId: string | number;
};

export type TerminalSessionWSMessage = {
  sequenceId: number;
  type: string;
  data: {
    error: string;
    ack: string;
  };
};

export const terminalSessionWebsocket = websocketClient<
  TerminalSessionWSMessage,
  TerminalSessionClientParams
>({
  host: websocketHost.ops.v0,
  endpoint: ({ sessionId }) => `/terminal/sessions/ws/${sessionId}`,
  errorMessage: "Couldn't connect to terminal session",
});

export type GetTerminalSessionsParams = {
  missionId: number;
  isUserAccessible: boolean;
};

export const getTerminalSessions = apiFetchHandler<
  TerminalSession[],
  GetTerminalSessionsParams
>({
  host: hosts.ops.v0,
  endpoint: ({ isUserAccessible, missionId }) =>
    `/terminal/sessions?missionId=${missionId}&userAccessible=${isUserAccessible}`,
  errorMessage: 'Could not get terminal session',
  method: 'GET',
});

export type GetTerminalSessionParams = {
  sessionId: string | number;
};

export const getTerminalSession = apiFetchHandler<
  TerminalSession,
  GetTerminalSessionParams
>({
  host: hosts.ops.v0,
  endpoint: ({ sessionId }) => `/terminal/sessions/${sessionId}`,
  errorMessage: 'Could not get terminal session',
  method: 'GET',
});

export type GetActiveSessionsParams = {
  missionId: number;
};

export const getActiveSessions = apiFetchHandler<
  ActiveSessionData[],
  GetActiveSessionsParams
>({
  host: hosts.ops.v0,
  method: 'GET',
  endpoint: ({ missionId }) => `/terminal/missions/${missionId}/users`,
  errorMessage: 'Could not get active sessions',
});
