import { AnchorButton, Icon, IconSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { ShownCards } from 'pages/ops/Library/components/LibraryEncoding/types';

interface IProps {
  left: React.ReactNode;
  right: React.ReactNode;
  leftWidthFr: number;
  rightWidthFr: number;
  sectionGap: string;
  setShownCards: React.Dispatch<React.SetStateAction<ShownCards>>;
  middleElement?: React.ReactNode;
  backButton?: true;
}

const TwoColumnLayout = (props: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let innerStyle: any = {
    display: 'grid',
    gap: props.sectionGap,
    height: '100%',
    position: 'relative',
  };

  props.middleElement
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      (innerStyle = {
        ...innerStyle,
        gridTemplateColumns: `${props.leftWidthFr}fr 0.5fr ${props.rightWidthFr}fr`,
      })
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      (innerStyle = {
        ...innerStyle,
        gridTemplateColumns: `${props.leftWidthFr}fr ${props.rightWidthFr}fr`,
      });
  return (
    <div
      style={{
        width: '70%',
        minWidth: '900px',
        height: '70vh',
        position: 'relative',
      }}
    >
      {props.backButton && (
        <AnchorButton
          text="Back"
          style={{
            position: 'absolute',
            left: -95,
            fontSize: '18px',
            backgroundColor: 'white',
            display: 'flex',
            height: '95px',
            borderRadius: '10%',
          }}
          large
          minimal
          onClick={() => props.setShownCards(ShownCards.HOME_CARDS)}
          icon={<Icon icon={IconNames.ARROW_LEFT} iconSize={IconSize.LARGE} />}
        />
      )}
      <div style={innerStyle as object}>
        <div
          style={{
            backgroundColor: 'white',
            width: '100%',
            padding: '20px',
            maxHeight: '70vh',
            minHeight: '700px',
          }}
        >
          {props.left}
        </div>
        {props.middleElement && <div>{props.middleElement}</div>}
        <div
          style={{
            backgroundColor: 'white',
            width: '100%',
            padding: '20px',
            maxHeight: '70vh',
            minHeight: '700px',
          }}
        >
          {props.right}
        </div>
      </div>
    </div>
  );
};

export default TwoColumnLayout;
