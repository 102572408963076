import React from 'react';
import s from './index.module.scss';
import { Elevation, Card, Button, Intent } from '@blueprintjs/core';

interface IProps {
  handleSave: () => void;
  saving?: boolean;
  shouldDisableSaveButton: boolean;
}

const LibrarySaveCard = (props: IProps) => {
  return (
    <Card
      interactive={true}
      elevation={Elevation.TWO}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '30%',
        marginBottom: '20px',
        alignItems: 'center',
        fontSize: '14px',
        padding: '15px 25px',
      }}
    >
      <span>There are pending changes to save</span>
      <Button
        icon="saved"
        className={s.librarySaveBtn}
        onClick={props.handleSave}
        intent={Intent.PRIMARY}
        loading={props.saving ?? false}
        style={{ width: '150px' }}
        disabled={props.shouldDisableSaveButton}
      >
        Save
      </Button>
    </Card>
  );
};

export default LibrarySaveCard;
