import type {
  IUserInformation,
  userTypes,
} from '../../constants/user/actionTypes';
import { USER_SET } from '../../constants/user/actionTypes';

export function setUserInformation(
  userInformation: IUserInformation
): userTypes {
  return {
    type: USER_SET,
    userInformation: userInformation,
  };
}
