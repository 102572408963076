import { useViewsData } from './useViewsData';
import { Screen } from 'ui/Screen';
import { Navbar } from 'ui/Navbar';
import { Card, CardGrid } from 'ui/Card';
import { ItemTabContainer } from 'ui/ItemContainer';
import { TextInput } from 'ui/TextInput';
import { DateInput } from 'ui/DateInput';
import { Loader } from 'ui/Loader';
import { EmptyMessage } from 'ui/EmptyMessage';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Icon, ListBoxItem, Select } from 'opencosmos-ui';

export const ViewsScreen = () => {
  const {
    loading,
    filter,
    setFilter,
    date,
    views,
    sortByOptions,
    sortOrderOptions,
    projectOptions,
  } = useViewsData();

  const { translate } = useLocalisation();

  const sortOptions = sortByOptions
    .map((sortOption) =>
      sortOrderOptions.map((orderOption) => `${sortOption}+${orderOption}`)
    )
    .flat();
  const handleSort = (option: string) => {
    const [by, order] = option?.split('+') ?? [];
    setFilter.orderBy(by);
    setFilter.sortOrder(order);
  };

  return (
    <Screen>
      <Navbar />
      <ItemTabContainer className="gap-4">
        <Icon icon="Filter" />
        <Select
          row
          selectedKey={`${filter.orderBy}+${filter.sortOrder}`}
          onSelectionChange={(value) => handleSort(value as string)}
          label={translate('datacosmos.views.sortBy')}
        >
          {sortOptions.map((option) => (
            <ListBoxItem key={option} id={option}>
              {translate(
                `datacosmos.views.sortByOptions.${option}` as unknown as TemplateStringsArray
              )}
            </ListBoxItem>
          ))}
        </Select>
        <DateInput
          value={date.from}
          setValue={date.setFrom}
          minValue={date.range?.min}
          maxValue={date.range?.max}
          title={translate('datacosmos.views.from')}
        />
        <DateInput
          value={date.to}
          setValue={date.setTo}
          minValue={date.range?.min}
          maxValue={date.range?.max}
          title={translate('datacosmos.views.to')}
        />
        <Select
          row
          selectedKey={filter.project}
          onSelectionChange={(value) => setFilter.project(value as string)}
          label={translate('datacosmos.views.inProject')}
        >
          {projectOptions.map((option) => (
            <ListBoxItem key={option} id={option}>
              {option}
            </ListBoxItem>
          ))}
        </Select>
        <TextInput
          value={filter.search}
          setValue={setFilter.search}
          icon="MagnifyingGlass"
          placeholder={translate('datacosmos.views.search')}
          className="stroke-header-contrast"
        />
      </ItemTabContainer>

      {loading ? (
        <Loader />
      ) : (
        <>
          {views.length === 0 ? (
            <EmptyMessage
              title={translate('datacosmos.fetchErrors.views.noViewsAvailable')}
            />
          ) : (
            <CardGrid>
              {views.map((view, index) => (
                <Card
                  // eslint-disable-next-line react/no-array-index-key
                  key={`view-card-${view.title}-${index}`}
                  column="col4"
                  {...view}
                />
              ))}
            </CardGrid>
          )}
        </>
      )}
    </Screen>
  );
};
