import { useRouteMatch } from 'react-router';
import { Breadcrumbs } from '@blueprintjs/core';
import DefaultLayout from 'pages/ops/shared/components/DefaultLayout';
import mainS from 'pages/ops/RTI/index.module.scss';
import { ScheduleActivityList } from 'pages/ops/Scheduling/components/ScheduleActivityList';
import { SchedulingLayout } from 'pages/ops/Scheduling/components/SchedulingLayout';
import { ScheduleActivityDetails } from 'pages/ops/Scheduling/components/ScheduleActivityDetails';
import ThemeProvider from 'datacosmos/stores/ThemeProvider';
import ActivitiesProvider, {
  useActivities,
} from './context/ActivitiesProvider';

export const SchedulingInner = () => {
  const { viewMode } = useActivities();

  const urlMatchWithActivity = useRouteMatch<{
    mission: string;
    activity: string;
  }>('/ops/mission/:mission/schedule/activity/:activity');

  return (
    <DefaultLayout
      shouldHideClock
      showMissionSelector
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'Schedule' }]} />
        </div>
      }
    >
      <SchedulingLayout
        hideRight={
          viewMode === 'table' &&
          urlMatchWithActivity?.params.activity === undefined
        }
        left={<ScheduleActivityList />}
        right={<ScheduleActivityDetails />}
      />
    </DefaultLayout>
  );
};

export const Scheduling = () => {
  return (
    <ThemeProvider>
      <ActivitiesProvider>
        <SchedulingInner />
      </ActivitiesProvider>
    </ThemeProvider>
  );
};
