import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import type { CommandDefinition, CommandHistoryCommand } from './types';

export type GetCommandListParams = {
  missionId: number;
};

export const getCommandList = apiFetchHandler<
  CommandDefinition[],
  GetCommandListParams
>({
  host: hosts.ops.v0,
  endpoint: ({ missionId }) => `/commands/mission/${missionId}`,
  method: 'GET',
  errorMessage: 'Could not get command list',
  errorDescription: (e) => e.join('; '),
});

export type GetCommandHistoryParams = {
  currentMissionId: number;
  sessionId: number;
  timeCursor: number;
  timeCursorMilliseconds: number;
  limit?: number;
};

export const getCommandHistory = apiFetchHandler<
  CommandHistoryCommand[],
  GetCommandHistoryParams
>({
  host: hosts.ops.v0,
  endpoint: ({
    currentMissionId,
    sessionId,
    timeCursor,
    timeCursorMilliseconds,
    limit,
  }) =>
    `/commands/mission/${currentMissionId}/log?sessionId=${sessionId}&timeCursor=${timeCursor}&timeCursorNano=${
      timeCursorMilliseconds * 1000000
    }&limit=${limit ?? ''}&timeDirection=before`,
  method: 'GET',
  errorMessage: 'Could not get command history',
  errorDescription: (e) => e.join('; '),
});

export type PatchCommandHistoryCommandParams = {
  currentMissionId: number;
  commandUUID: string;
};

export type PatchCommandHistoryCommandBody = {
  note?: string;
};

export const patchCommandHistoryCommand = apiFetchHandler<
  CommandHistoryCommand[],
  PatchCommandHistoryCommandParams,
  PatchCommandHistoryCommandBody
>({
  host: hosts.ops.v0,
  endpoint: ({ commandUUID, currentMissionId }) =>
    `/commands/mission/${currentMissionId}/log/${commandUUID}`,
  method: 'GET',
  errorMessage: 'Could not patch command history command',
  errorDescription: (e) => e.join('; '),
});
