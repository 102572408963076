/**
 * formatGroupTitle is a helper function that takes a string and returns a formatted string.
 * It capitalizes the first letter of the string and replaces underscores with spaces.
 * @param str string to format
 * @returns formatted string
 */
export const formatGroupTitle = (str: string) => {
  const strPruned = str.toLowerCase().replace(/[_-]/g, " ");
  return strPruned.charAt(0).toUpperCase() + strPruned.slice(1);
};
