import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { StacItem } from 'datacosmos/types/stac-types';
import { useCallback, useMemo } from 'react';
import {
  getVisualAssetKey,
  getOverviewAssetKey,
  getThumbnailAssetKey,
} from '../stac';

export const useDisplayedStacItem = () => {
  const { layers, isAssetShown, setLayers } = useMapLayers();

  const stacLayers = useMemo(
    () =>
      layers.filter(
        (l) => l instanceof SingleBandSTACLayer
      ) as SingleBandSTACLayer[],
    [layers]
  );

  const displayedStacLayers = useMemo(
    () =>
      stacLayers.filter((l) => {
        return (
          isAssetShown(l.item, getVisualAssetKey(l.item)) ||
          isAssetShown(l.item, getOverviewAssetKey(l.item)) ||
          isAssetShown(l.item, getThumbnailAssetKey(l.item))
        );
      }),
    [isAssetShown, stacLayers]
  );

  /**
   * Searches for the stack item in the selected item layers
   * @param item The item to search for
   * @returns true if the item is selected, false otherwise
   */
  const isStacItemDisplayed = (item: StacItem) => {
    return stacLayers.some(
      (l) => l.item.id === item.id && l.item.collection === item.collection
    );
  };

  /**
   * Searches for the stack item asset in the selected item layers
   * @param item The item to search for
   * @param assetID The asset ID to search for, corresponding to the stac item
   * @returns true if the item is selected, false otherwise
   */
  const isStacItemAssetDisplayed = (item: StacItem, assetID: string) => {
    return stacLayers.some(
      (l) =>
        l.item.id === item.id &&
        l.item.collection === item.collection &&
        l.assetKey === assetID
    );
  };

  const removeLayersByStacItem = useCallback(
    (item: StacItem) => {
      setLayers(
        stacLayers.filter(
          (l) =>
            !(l.item.id === item.id && l.item.collection === item.collection)
        )
      );
    },
    [setLayers, stacLayers]
  );

  return {
    displayedStacLayers,
    isStacItemDisplayed,
    isStacItemAssetDisplayed,
    removeLayersByStacItem,
  };
};
