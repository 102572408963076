import { pileItemClose, pileItemOpen } from 'actions/operate/actions';
import type { CommandDefinition } from '_api/telecommands/types';
import type { IOperateActionTypes } from 'constants/ops/rti/oparate/actionTypes';
import { find } from 'lodash/fp';
import type { IOperateState } from 'reducers/operate';

const getNextPileItemIndex = (
  currentPileItemIndex: number | undefined,
  maxPossibleIndex: number
) => {
  if (currentPileItemIndex === undefined) {
    return 0;
  }

  if (currentPileItemIndex === maxPossibleIndex - 1) {
    return 0;
  }

  return (currentPileItemIndex ?? 0) + 1;
};

/**
 * focusNextPileCommand is a helper function that is used to focus the next pile item
 * when using a keyboard shortcut to send a command.
 * @param state operate state
 * @param dispatch reducer dispatch function
 * @param availableCommands list of available commands
 */
export const focusNextPileCommand = (
  state: IOperateState,
  dispatch: (action: IOperateActionTypes) => void,
  availableCommands: CommandDefinition[]
) => {
  const sentCmdIndex = state.commandPileWorkingSpace?.findIndex(
    (item) => item.id === state.selectedPileItem?.id
  );

  const nextCmdIndex = getNextPileItemIndex(
    sentCmdIndex,
    state.commandPileWorkingSpace?.length ?? 0
  );

  if (nextCmdIndex === 0) {
    dispatch(pileItemClose());
    return;
  }

  const command = find(
    ['name', state.commandPileWorkingSpace?.[nextCmdIndex].command],
    availableCommands
  );

  if (!command) {
    return;
  }

  if (state.commandPileWorkingSpace?.[nextCmdIndex]) {
    dispatch(
      pileItemOpen(state.commandPileWorkingSpace[nextCmdIndex], command)
    );
  }
};
