import React, { useEffect } from 'react';
import type { IFolder } from '../../../services/api/ops/cloudStorage';
import type { IModeProps, IMode } from '..';
import { IconNames } from '@blueprintjs/icons';
import s from './index.module.scss';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { isNil } from 'lodash';
import { byteArrayToUTF8String } from 'utils/common/binnaryUtils';

const MarkdownView: IMode<string> = ({
  fileDetails,
  renderedContent,
  setRenderedContent,
}: IModeProps<string>) => {
  useEffect(() => {
    if (fileDetails) {
      const { file } = fileDetails;

      if (!file) {
        return;
      }

      const data = byteArrayToUTF8String(file);
      const md = marked(data, { async: false }) as string;

      const clean = DOMPurify.sanitize(md);

      setRenderedContent(clean);
    }
  }, [fileDetails, setRenderedContent]);

  return (
    <div
      className={s.markdownView}
      dangerouslySetInnerHTML={{ __html: renderedContent }}
    />
  );
};

MarkdownView.isValid = (fileDetails: IFolder) => {
  if (fileDetails && !isNil(fileDetails.file)) {
    const data = byteArrayToUTF8String(fileDetails.file);
    if (typeof data !== 'undefined') {
      return true;
    }
  }
  return false;
};

MarkdownView.isPreferred = (fileDetails: IFolder) => {
  return Boolean(fileDetails?.name.includes('.md'));
};

// Not in use because it is not editable
MarkdownView.saveContent = (_: string) => new Uint8Array();
MarkdownView.isEditable = false;

MarkdownView.icon = IconNames.EDIT;

MarkdownView.id = 'markdown-view';

export default MarkdownView;
