import { Section } from "react-aria-components";

const MySection: typeof Section = (props) => {
  return (
    <Section
      {...props}
      className="my-1 border-0 border-solid border-b border-item-hover"
    />
  );
};

export default MySection;
