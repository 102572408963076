import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  measure: (addLineToMap: boolean) => Promise<number>;
}

export const DrawLine = (props: IProps) => {
  const { translate } = useLocalisation();
  return (
    <MenuItem
      text={translate('datacosmos.rightClickMenu.line')}
      icon={IconNames.DRAW}
      onClick={async () => {
        await props.measure(true);
      }}
      className="dark:hover:text-neutral hover:text-accent-900"
    />
  );
};
