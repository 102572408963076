import { chunk, compact, join, map, pipe, split } from 'lodash/fp';
import { PAYLOAD_TYPE, PAYLOAD_TYPE_DEFAULT } from 'constants/moduleData/types';
import { isCompanyEmail } from 'free-email-domains-list';
export const removeExcessSlash = (path: string) =>
  pipe(split('/'), compact, join('/'))(path);

export const removeFinalSlash = (path: string) => {
  if (path.endsWith('/')) {
    return path.substr(0, path.length - 1);
  }
  return path;
};

export const removeFirstSlash = (path: string) => {
  if (path === '/') {
    return '';
  }
  if (path.startsWith('/')) {
    return path.substr(1, path.length);
  }
  return path;
};

export const removeDoubleSlashes = (path: string) => {
  if (path.includes('//')) {
    return path.replace(/\/{2,}/g, '/');
  }
  return path;
};

export const formatPayloadType = (value: string): string | undefined => {
  if (!Object.keys(PAYLOAD_TYPE).includes(value)) return PAYLOAD_TYPE_DEFAULT;
  return PAYLOAD_TYPE[value as keyof typeof PAYLOAD_TYPE];
};

export const splitLongStringByChunk = (str: string, chunkSize: number = 7) => {
  const lines = split('\n', str);

  if (lines.join('') !== str) return lines;

  return pipe(split(' '), chunk(chunkSize), map(join(' ')))(str);
};

export const stringToHex = (str: string) =>
  pipe(
    split(''),
    map((char: string) => {
      const hex = char.charCodeAt(0).toString(16);

      return hex.length < 2 ? `0${hex}` : hex;
    }),
    join(' ')
  )(str);

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const isValidCompanyEmail = (emailDomain: string) => {
  const testEmail = `test@${emailDomain}`;
  return isCompanyEmail(testEmail);
};
