import type { Organisation } from '_api/administration/types';
import CreateOrganisationForm from 'components/OrganisationConfiguration/CreateOrganisationForm';
import { Button, Dialog, SearchInput } from 'opencosmos-ui';
import AdministrationLayout from 'pages/portal/shared/components/AdministrationLayout/AdministrationLayout';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'services/auth/AuthWrapper';

const OrganisationsList = () => {
  const {
    userOrganisations,
    checkPermissions,
    user,
    refetchUserOrganisations,
  } = useAuth();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [filteredOrganisations, setFilteredOrganisations] = useState<
    Organisation[]
  >([]);
  const [isCreateOrgOpen, setIsCreateOrgOpen] = useState<boolean>(false);

  const history = useHistory();

  const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean>(false);
  useEffect(() => {
    async function fetchData() {
      const res = await checkPermissions({
        actionScope: 'organisation:read',
        type: 'global',
      });

      setIsGlobalAdmin(res);
    }

    void fetchData();
  }, [checkPermissions]);

  useEffect(() => {
    if (!userOrganisations?.length) {
      return;
    }

    const modifyOrgsPermission = async () => {
      const permissionAddedOrgs = await Promise.all(
        userOrganisations?.map(async (org) => {
          return {
            ...org,
            isAdmin: await checkPermissions({
              actionScope: 'organisation:assignment:write',
              type: 'organisation',
              id: org?.id?.toString(),
            }),
          };
        })
      );

      setOrganisations(permissionAddedOrgs);
      setFilteredOrganisations(permissionAddedOrgs);
    };

    void modifyOrgsPermission();
  }, [checkPermissions, userOrganisations]);

  const handleSearchOrgs = (value: string) => {
    if (!value?.length) {
      setFilteredOrganisations(organisations);
      return;
    }
    setFilteredOrganisations(
      organisations?.filter((searchedOrg) =>
        searchedOrg?.name?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
  };

  return (
    <AdministrationLayout breadcrumbs={[{ text: 'Organisations' }]}>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <h4 className="font-bold text-lg">Organisations</h4>
          <Button intent="primary" onPress={() => setIsCreateOrgOpen(true)}>
            Create New
          </Button>
        </div>

        <SearchInput
          fullWidth
          placeholder="Search by name"
          onSubmit={handleSearchOrgs}
          onClear={() => handleSearchOrgs('')}
        />
        <table>
          <tr>
            {isGlobalAdmin && <th className="text-left">ID</th>}
            <th className="text-left">Name</th>
            <th className="text-left">Join Policy</th>
            <th className="text-left">Domain</th>
            <th></th>
          </tr>
          {filteredOrganisations?.map((userOrg) => (
            <tr key={userOrg.id}>
              {isGlobalAdmin && (
                <td className="max-w-xs overflow-hidden text-ellipsis border-b border-neutral-400 dark:border-neutral-700 pl-2">
                  {userOrg.id}
                </td>
              )}
              <td className="max-w-xs overflow-hidden text-ellipsis border-b border-neutral-400 dark:border-neutral-700 pl-2">
                {userOrg.name}
              </td>
              <td className="max-w-xs overflow-hidden text-ellipsis border-b border-neutral-400 dark:border-neutral-700">
                {userOrg.join_policy}
              </td>
              <td className="max-w-xs overflow-hidden text-ellipsis border-b border-neutral-400 dark:border-neutral-700">
                {userOrg.domain}
              </td>
              <td>
                <Button
                  isDisabled={!userOrg?.isAdmin}
                  onPress={() =>
                    history.push(`/portal/organisation/${userOrg?.id}`)
                  }
                  intent="secondary"
                >
                  Configure
                </Button>
              </td>
            </tr>
          ))}
        </table>
        <Dialog
          isOpen={isCreateOrgOpen}
          onClose={() => {
            setIsCreateOrgOpen(false);
          }}
          buttons={[]}
          hideCancelButton
        >
          <div>
            {user && (
              <CreateOrganisationForm
                handleSubmit={() => {
                  void refetchUserOrganisations();
                  setIsCreateOrgOpen(false);
                }}
                handleSkip={() => {}}
                submitLabel="Create organisation"
                user={user}
              />
            )}
          </div>
        </Dialog>
      </div>
    </AdministrationLayout>
  );
};

export default OrganisationsList;
