import React from 'react';
import s from './index.module.scss';

interface IProps {
  children: React.ReactNode;
}

const DndListCollapsed = (props: IProps) => {
  return <div className={s.collapsedItem}>{props.children}</div>;
};

export default DndListCollapsed;
