import type {
  IGSState,
  GroundStationsActionTypes,
  IGroundStation,
} from '../../../../constants/groundStations/actionTypes';
import {
  GS_ADD,
  GS_UPDATE,
  GS_REMOVE,
  GS_UPLOAD,
} from '../../../../constants/groundStations/actionTypes';

const initialState: IGSState = {
  list: [],
};

export default function groundStationReducer(
  state: IGSState = initialState,
  action: GroundStationsActionTypes
): IGSState {
  switch (action.type) {
    case GS_ADD: {
      if (action.groundStation.custom) {
        return {
          list: [action.groundStation, ...state.list],
        };
      } else {
        return {
          list: [...state.list, action.groundStation],
        };
      }
    }
    case GS_UPDATE: {
      return {
        list: state.list.map((groundStation): IGroundStation => {
          if (groundStation.id === action.groundStation.id) {
            return { ...action.groundStation };
          }
          return groundStation;
        }),
      };
    }
    case GS_REMOVE: {
      return {
        list: state.list.filter(
          (groundStation): boolean => groundStation.id !== action.id
        ),
      };
    }
    case GS_UPLOAD: {
      return action.groundStations;
    }
    default: {
      return state;
    }
  }
}
