import { useQuery } from '_api/useQuery';
import { getSatellitesForTasking } from '_api/tasking/service';

export const useTaskingPermissions = () => {
  const { data: taskingSatellites, loading } = useQuery(
    getSatellitesForTasking,
    {
      initialData: undefined,
      params: undefined,
    }
  );

  return {
    loading,
    taskingSatellites,
  };
};
