import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { uploadPointOfInterest } from 'pages/shared/state/actions/pointsOfInterest/action';
import type {
  IPIState,
  IPointOfInterest,
} from 'constants/pointsOfInterest/actionTypes';
import type { AppState } from 'pages/shared/state/reducers/rootReducer';
import { setMarkerSpecify } from 'services/mapService/contextMenu/map/events';
import { generateName } from 'utils/common/generateName';
import type { IOSMFetchResult } from 'declarations/osm';

export const uploadPI =
  (
    pointsOfInterest: IPIState
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(uploadPointOfInterest(pointsOfInterest));
  };

export const fetchPI =
  (
    result: IOSMFetchResult,
    withPopUp?: boolean,
    id?: string
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, state) => {
    const pointsOfInterest = {
      lat: Number(result.lat),
      lon: Number(result.lon),
      name: generateName(state().pointsOfInterest, result.display_name),
      id,
    };
    dispatch(setMarkerSpecify(pointsOfInterest as IPointOfInterest, withPopUp));
  };
