import type { IMapContext } from 'datacosmos/stores/MapProvider';
import React from 'react';

type Props = {
  map: IMapContext | null;
};

const BottomBarScale = ({ map }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <span style={{ whiteSpace: 'nowrap' }}>{map?.scale?.kmValue} km</span>
        <div className="flex items-center">
          <div
            style={{
              width: map?.scale?.scalePxWidth
                ? `${map.scale.scalePxWidth}px`
                : undefined,
            }}
            className="border-l border-b border-t border-data-dark dark:border-data-text h-2 bg-black text-[9px] flex items-end justify-between"
          >
            <span className="mb-2">0</span>
            <span className="mb-2">{(map?.scale?.kmValue ?? 0) / 2}</span>
          </div>
          <div
            style={{
              width: map?.scale?.scalePxWidth
                ? `${map.scale.scalePxWidth}px`
                : undefined,
            }}
            className="border border-data-dark dark:border-data-text h-2 bg-white text-[9px] flex items-end justify-end"
          >
            <span className="mb-2">{map?.scale?.kmValue}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomBarScale;
