import * as Sentry from '@sentry/react';
import type { IUserInformation } from '../../constants/user/actionTypes';

export const setSentryUser = (user: IUserInformation) => {
  Sentry.setUser({
    id: user.sub,
    username: user.name,
    email: user.email,
  });
};
