import type {
  ICurvePoints,
  ISVGPoint,
} from '../../constants/missionDesignPage/interfaces';
import { lerp } from './lerp';
import { getQuadraticBezierCurves } from './getQuadraticBezierCurves';

export const computeSeparatedCurve = (
  points: ICurvePoints,
  position: number,
  curveNum: number
) => {
  const { first, control, last } = points;
  const dt = 1 / curveNum;
  let tangent: [ISVGPoint, ISVGPoint] = [first, control];
  let helperPoint1: ISVGPoint;
  let helperPoint2: ISVGPoint;
  let resPoints: ICurvePoints;

  for (let i = 0; i <= position; i++) {
    helperPoint1 = lerp(first, control, (i + 1) * dt);
    helperPoint2 = lerp(control, last, (i + 1) * dt);
    resPoints = {
      first: getQuadraticBezierCurves(first, control, last, i * dt),
      control: lerp(tangent[0], tangent[1], (i + 1) * dt),
      last: getQuadraticBezierCurves(first, control, last, (i + 1) * dt),
    };
    tangent = [helperPoint1, helperPoint2];
  }
  //@ts-expect-error
  return resPoints;
};
