import React, { useState } from 'react';
import {
  Classes,
  ProgressBar,
  Button,
  Alert,
  NonIdealState,
  Intent,
  Callout,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import s from './index.module.scss';
import type { IActiveTransfer } from '../../utils/fileTransfer';
import { isTransferOngoing, transferIntent } from '../../utils/fileTransfer';
import { Tooltip } from 'opencosmos-ui';

export interface IProps {
  activeTransfers: IActiveTransfer[];
  removeTransfer: Function;
  skipWarningAbortTransfer?: boolean;
  navigateToFile?: INavigateToFile;
}

export type INavigateToFile = (
  file: IActiveTransfer,
  wasDestinationClicked: boolean
) => void;

const transferSizeUnknown = (transfer: IActiveTransfer) => {
  if (typeof transfer.percent === 'undefined') {
    return true;
  }
  return false;
};

interface IFileTransferRowProps {
  pathName: string;
  progress: number;
  isStopped: boolean;
  hasErrored: boolean;
}

const FileTransferRow = ({
  pathName,
  progress,
  isStopped,
  hasErrored,
}: IFileTransferRowProps) => {
  const inProgress = isTransferOngoing(progress, isStopped, hasErrored);

  return (
    <div className={s.fileRow}>
      <div>{pathName}</div>
      <div>
        <ProgressBar
          className={s.progressBar}
          value={progress / 100}
          stripes={inProgress}
          intent={transferIntent(progress, hasErrored)}
        />
      </div>
    </div>
  );
};

interface IGroupTransferRowProps {
  file: IActiveTransfer;
  removeTransfer: Function;
  handleNavigateToFile?: INavigateToFile;
}

const GroupTransferRow = ({
  file,
  removeTransfer,
  handleNavigateToFile,
}: IGroupTransferRowProps) => {
  const [showAbortAlert, setShowAbortAlert] = useState(false);
  const [showAllFiles, setShowAllFiles] = useState(true);

  const inProgress = isTransferOngoing(
    Number(file.percent),
    Boolean(file.stopped),
    Boolean(file.error)
  );

  const canShowIndividualFiles =
    file.files && Object.entries(file.files).length > 1;

  const shouldShowIndividualFiles = canShowIndividualFiles && showAllFiles;

  const handleAbortTransfer = () => {
    removeTransfer(file.id, file.transferType);
  };

  const handleStop = () => {
    if (!file.skipWarningAbortTransfer) {
      setShowAbortAlert(true);
    } else {
      handleAbortTransfer();
    }
  };

  const handleSourceClick =
    file.name && handleNavigateToFile
      ? () => handleNavigateToFile(file, false)
      : undefined;
  const handleDestinationClick = handleNavigateToFile
    ? () => handleNavigateToFile(file, true)
    : undefined;

  return (
    <li key={file.id} className={Classes.TREE_NODE}>
      <div className={s.transferItem}>
        <div className="flex w-full relative items-center">
          <ProgressBar
            className={s.progressBar}
            value={transferSizeUnknown(file) ? 1 : Number(file.percent) / 100}
            stripes={inProgress}
            intent={transferIntent(Number(file.percent), Boolean(file.error))}
          />
          <span className="absolute left-1/2 bottom-1">
            {file.percent?.toFixed(2)}%
          </span>
        </div>

        {file.stages &&
          (inProgress ? (
            <Tooltip content={file.currentStage ?? ''}>
              <div className="flex items-center gap-1 whitespace-nowrap w-full">
                {file.stages.findIndex((stage) => stage === file.currentStage) +
                  1}{' '}
                / {file.stages?.length}
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center gap-1 whitespace-nowrap">
              {file.stages.length} / {file.stages.length}
            </div>
          ))}
        {inProgress && (
          <Button icon={IconNames.DELETE} onClick={handleStop} minimal />
        )}
        {!inProgress && (
          <Button
            icon={IconNames.TRASH}
            onClick={() => {
              removeTransfer(file.id, file.transferType, true);
            }}
            minimal
          />
        )}
      </div>
      <div className={s.groupRowMain}>
        <div className={s.groupRowDescription}>
          <p
            className={s.descriptionText}
            style={handleSourceClick && { cursor: 'pointer' }}
          >
            <span
              className={handleSourceClick && s.clickableText}
              onClick={handleSourceClick}
            >
              {file.name ? file.name : 'Name not available'}
            </span>
          </p>
          {file.destination && (
            <p
              className={s.descriptionText}
              style={handleDestinationClick && { cursor: 'pointer' }}
            >
              <span
                className={handleSourceClick && s.clickableText}
                onClick={handleDestinationClick}
              >
                Destination: {file.destination}
              </span>
            </p>
          )}
          <p className={s.descriptionText}>{file.transferType}</p>
        </div>
        {canShowIndividualFiles && (
          <div>
            <Button
              icon={showAllFiles ? IconNames.CARET_UP : IconNames.CARET_DOWN}
              onClick={() => setShowAllFiles(!showAllFiles)}
              minimal
            />
          </div>
        )}
      </div>
      {Boolean(file.error) && (
        <Callout intent={Intent.DANGER}>{file.error}</Callout>
      )}
      {shouldShowIndividualFiles && (
        <div className={s.individualRowContainer}>
          {Object.entries(file.files ?? []).map(([pathName, progress]) => (
            <FileTransferRow
              key={pathName}
              pathName={pathName}
              progress={progress}
              isStopped={Boolean(file.stopped)}
              hasErrored={Boolean(file.error)}
            />
          ))}
        </div>
      )}
      <Alert
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        confirmButtonText="Abort transfer"
        cancelButtonText="Do nothing"
        isOpen={showAbortAlert}
        onClose={() => setShowAbortAlert(false)}
        onConfirm={handleAbortTransfer}
      >
        Are you sure you want to abort this transfer?
      </Alert>
    </li>
  );
};

const FileTransfer = ({
  activeTransfers,
  removeTransfer,
  navigateToFile,
}: IProps) => {
  if (!activeTransfers.length) {
    return (
      <NonIdealState icon={IconNames.EXCHANGE} title="No file transfers" />
    );
  }

  return (
    <div className={s.transferList}>
      <ul className={[Classes.TREE_NODE_LIST, Classes.TREE_ROOT].join(' ')}>
        {activeTransfers.map((file) => (
          <GroupTransferRow
            key={file.id}
            file={file}
            removeTransfer={removeTransfer}
            handleNavigateToFile={navigateToFile}
          />
        ))}
      </ul>
    </div>
  );
};

export default FileTransfer;
