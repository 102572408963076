import { useQuery } from '@tanstack/react-query';
import { getReadEvents, postPublishEvent } from '_api/scripts/service';
import { assoc } from 'lodash/fp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { EventProperty } from '../utils/schema';
import { getParsedSchemaProperties } from '../utils/schema';
import { useMission } from 'services/Missions';
import type { Trigger } from '_api/scripts/types';

export const useSubmitEvent = () => {
  const { currentMissionId } = useMission();
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState<boolean>(false);
  const [eventParameters, setEventParameters] = useState({});

  const [selectedEvent, setSelectedEvent] = useState<string | undefined>();

  const [eventToSubmit, setEventToSubmit] = useState<Record<string, unknown>>(
    {}
  );

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] =
    useState<boolean>(false);

  const { data: events } = useQuery({
    queryKey: ['getEventParameters'],
    queryFn: async () => {
      return getReadEvents({});
    },
  });

  const perEventParsedProperty = events?.data?.reduce((acc, ev) => {
    acc[ev.title ?? ''] = getParsedSchemaProperties(ev);
    return acc;
  }, {} as Record<string, Record<string, EventProperty>>);

  const selectedEventType = useMemo(() => {
    // We're expecting to always have a default value set for the selected event's type
    return perEventParsedProperty?.[selectedEvent ?? '']?.type.default;
  }, [perEventParsedProperty, selectedEvent]);

  const toggleSubmitDialogOpen = () => {
    setIsSubmitDialogOpen((prev) => !prev);
  };

  const handleEventParamsChange = (path: string, value: unknown) => {
    const params = assoc(path, value, eventParameters);
    if (eventParameters) {
      setEventParameters({ ...eventParameters, ...params });
      return;
    }
    setEventParameters({ ...params });
  };

  const formatNestedKeys = useCallback((ev: Record<string, unknown>) => {
    const output = {} as Record<string, unknown>;

    for (const key in ev) {
      const keys = key.split('.');
      keys.reduce((acc, part, index) => {
        if (index === keys.length - 1) {
          acc[part] = ev[key];
        } else {
          acc[part] = acc[part] || {};
        }
        return acc[part] as Record<string, unknown>;
      }, output as Record<string, unknown>);
    }

    return output;
  }, []);

  const populateEventToSubmit = useCallback(
    (value: unknown, propertyKey: string) => {
      if (!value) {
        setEventToSubmit((prev) => {
          const newEventToSubmit = { ...prev };
          delete newEventToSubmit[propertyKey];
          return newEventToSubmit;
        });
        return;
      }

      setEventToSubmit((prev) => {
        const ev = {
          ...prev,
          [propertyKey]: value,
        };
        return formatNestedKeys(ev);
      });
    },
    [formatNestedKeys]
  );

  const getNestedRequiredProperties = useCallback(
    (schema: Record<string, EventProperty>) => {
      const requiredProperties = Object.entries(schema).reduce(
        (acc, [key, value]) => {
          if (value.isRequired && value.type !== 'array') {
            acc.push(key);
          }

          if (value.type === 'array' && value.items) {
            const nestedRequiredProperties = getNestedRequiredProperties(
              value.items
            );
            acc.push(...nestedRequiredProperties);
          }

          return acc;
        },
        [] as string[]
      );

      return requiredProperties;
    },
    []
  );

  const getAllEventKeys = useCallback((ev: Record<string, unknown>) => {
    const keys = Object.keys(ev);
    return keys.reduce((acc, key) => {
      if (typeof ev[key] === 'object') {
        const nestedKeys = getAllEventKeys(ev[key] as Record<string, unknown>);
        acc.push(...nestedKeys.map((nestedKey) => `${nestedKey}`));
      } else {
        acc.push(key);
      }

      return acc;
    }, [] as string[]);
  }, []);

  const validateEventToSubmit = useCallback(() => {
    const selectedEventSchema = perEventParsedProperty?.[selectedEvent ?? ''];
    if (!selectedEventSchema) {
      return;
    }

    const requredProperties = getNestedRequiredProperties(selectedEventSchema);
    const allEventToSubmitKeys = getAllEventKeys(eventToSubmit);

    const canSubmit = requredProperties.every((prop) =>
      allEventToSubmitKeys.includes(prop)
    );

    setIsSubmitButtonEnabled(canSubmit);
  }, [
    eventToSubmit,
    getAllEventKeys,
    getNestedRequiredProperties,
    perEventParsedProperty,
    selectedEvent,
  ]);

  const resetEventToSubmit = () => {
    setEventToSubmit({});
  };

  const submitNewEvent = async () => {
    if (!currentMissionId) {
      return;
    }

    if (!eventToSubmit) {
      return;
    }

    await postPublishEvent({
      params: {
        missionId: currentMissionId,
      },
      body: eventToSubmit,
    });
  };

  const getTriggersForSelectedEvent = (triggerList: Trigger[]) => {
    return triggerList.filter(
      (trigger) => trigger.trigger.event_type === selectedEventType
    );
  };

  useEffect(() => {
    validateEventToSubmit();
  }, [validateEventToSubmit]);

  return {
    events: events?.data ?? [],
    isSubmitDialogOpen,
    toggleSubmitDialogOpen,
    handleEventParamsChange,
    eventParameters,
    perEventParsedProperty,
    setSelectedEvent,
    selectedEvent,
    populateEventToSubmit,
    resetEventToSubmit,
    isSubmitButtonEnabled,
    submitNewEvent,
    getTriggersForSelectedEvent,
  };
};
