import { useQueries, useQuery } from '@tanstack/react-query';
import {
  deleteAssignedDeviceFromMission,
  getAllDevices,
  postAssignDeviceToMission,
} from '_api/gse/service';
import type { Device } from '_api/gse/types';
import { getUser } from '_api/users/service';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMission } from 'services/Missions';

export const useGseData = () => {
  const { currentMissionId } = useMission();

  const [assignedDevicesPerMission, setAssignedDevicesPerMission] = useState<
    Record<string, Device[]>
  >({});

  const [isUpdatingAssignedDevices, setIsUpdatingAssignedDevices] =
    useState<boolean>(false);

  const [isConnectDialogOpen, setIsConnectDialogOpen] =
    useState<boolean>(false);
  const [isDisconnectDialogOpen, setIsDisconnectDialogOpen] =
    useState<boolean>(false);

  const [deviceToDisconnect, setDeviceToDisconnect] = useState<
    Device | undefined
  >();
  const [deviceToConnect, setDeviceToConnect] = useState<Device | undefined>();

  const [
    isInitializingAssignedDevicesPerMission,
    setIsInitializingAssignedDevicesPerMission,
  ] = useState<boolean>(true);

  const {
    data: devices,
    isLoading: isLoadingDevices,
    refetch: refetchDevices,
  } = useQuery({
    queryKey: ['devices', currentMissionId],
    queryFn: () => {
      return getAllDevices({});
    },
  });

  const deviceAssigneeIds = useMemo(
    () => [
      ...new Set(
        devices?.data
          ?.filter((d) => d.assignment)
          .map((d) => d.assignment?.created_by)
      ),
    ],
    [devices?.data]
  );

  const allDevices = useMemo(
    () =>
      (devices?.data ?? []).filter(
        (d) =>
          !assignedDevicesPerMission[currentMissionId ?? '']?.some(
            (ad) => ad.name === d.name
          )
      ),
    [assignedDevicesPerMission, currentMissionId, devices?.data]
  );

  const assigneeQueries = useQueries({
    queries: deviceAssigneeIds.map((a) => ({
      queryKey: [a],
      queryFn: async () => {
        const { data: user } = await getUser({
          params: {
            subject: a ?? '',
          },
        });

        return user;
      },
    })),
  });

  /**
   * getDeviceAssignee is a function that returns the assignee of a given device
   * @param device - The device to get the assignee for
   */
  const getDeviceAssignee = useCallback(
    (device: Device) => {
      if (assigneeQueries.length === 0) {
        return undefined;
      }

      const assignees = assigneeQueries.map((d) => d.data).flat();
      return assignees.find((a) => a?.id === device.assignment?.created_by);
    },
    [assigneeQueries]
  );

  /**
   * navigateToAssignedMission is a function that navigates to the mission page of the assigned mission
   * @param mission - The mission to navigate to
   */
  const navigateToAssignedMission = (mission: number | string) => {
    window.location.href = `/ops/mission/${mission}/gse`;
  };

  /**
   * removeAssignedDevice is a function that removes a device from the current mission
   * @param device
   */
  const removeAssignedDevice = async (device: Device) => {
    setIsUpdatingAssignedDevices(true);

    const { data: removedAssignedDevice } =
      await deleteAssignedDeviceFromMission({
        params: {
          deviceName: device.name,
          missionId: String(currentMissionId),
        },
      });

    if (!removedAssignedDevice) {
      setIsUpdatingAssignedDevices(false);
      return;
    }

    const deviceMissionId = device.assignment?.mission_id ?? '';

    setAssignedDevicesPerMission((prev) => ({
      ...prev,
      [deviceMissionId]: assignedDevicesPerMission[deviceMissionId]?.filter(
        (d) => d?.name !== removedAssignedDevice?.name
      ),
    }));

    setIsUpdatingAssignedDevices(false);
  };

  /**
   * assignDevice is a function that assigns a device to the current mission
   * @param device device to assign
   */
  const assignDevice = async (device: Device) => {
    setIsUpdatingAssignedDevices(true);

    const { data: newAssignedDevice } = await postAssignDeviceToMission({
      params: {
        deviceName: device.name,
        missionId: String(currentMissionId),
      },
    });

    if (!newAssignedDevice) {
      setIsUpdatingAssignedDevices(false);
      return;
    }

    setAssignedDevicesPerMission((prev) => ({
      ...prev,
      [currentMissionId ?? '']: [
        ...(assignedDevicesPerMission[currentMissionId ?? ''] ?? []),
        newAssignedDevice,
      ],
    }));

    setIsUpdatingAssignedDevices(false);
  };

  /**
   * getShouldDisableAssignmentForDevice is a function that returns whether a device should be disabled for assignment
   * @param device - The device to check
   */
  const getShouldDisableAssignmentForDevice = useCallback(
    (device: Device) => {
      const disabledDeviceTypes = (
        assignedDevicesPerMission[currentMissionId ?? ''] ?? []
      ).map((d) => d.device_type);

      const disabledDeviceNames = Object.values(assignedDevicesPerMission)
        .flat()
        .map((d) => d?.name);

      return Boolean(
        disabledDeviceTypes.includes(device.device_type) ||
          disabledDeviceNames.includes(device.name)
      );
    },
    [assignedDevicesPerMission, currentMissionId]
  );

  const initAssignedDevicesPerMission = useCallback(() => {
    if (!devices?.data) {
      return;
    }

    const assigned = devices?.data?.filter(
      (d) => d.device_state === 'assigned'
    );

    if (assigned && assigned?.length === 0) {
      return;
    }

    const assignedPerMission = assigned?.reduce((acc, d) => {
      if (!d.assignment?.mission_id) {
        return acc;
      }

      return {
        ...acc,
        [d.assignment?.mission_id]: [
          ...(acc[d.assignment?.mission_id ?? ''] ?? []),
          d,
        ],
      };
    }, {} as Record<string, Device[]>);

    if (assignedPerMission) {
      setAssignedDevicesPerMission(assignedPerMission);
    }
    setIsInitializingAssignedDevicesPerMission(false);
  }, [devices?.data]);

  useEffect(() => {
    if (isLoadingDevices) {
      return;
    }

    initAssignedDevicesPerMission();
  }, [initAssignedDevicesPerMission, isLoadingDevices]);

  return {
    allDevices,
    assignedDevices: assignedDevicesPerMission[currentMissionId ?? ''] ?? [],
    removeAssignedDevice,
    assignDevice,
    navigateToAssignedMission,
    loadingDevices: isLoadingDevices || isInitializingAssignedDevicesPerMission,
    getDeviceAssignee,
    isUpdatingAssignedDevices,
    getShouldDisableAssignmentForDevice,
    isConnectDialogOpen,
    isDisconnectDialogOpen,
    setIsConnectDialogOpen,
    setIsDisconnectDialogOpen,
    setDeviceToDisconnect,
    deviceToDisconnect,
    setDeviceToConnect,
    deviceToConnect,
    refetchDevices,
  };
};
