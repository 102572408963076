import type { CSSProperties } from 'react';
import classNames from 'classnames';
import { Text } from 'ui/Text';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { Icon } from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';

const columns = {
  col1: '100%',
  col2: 'calc((100% - 32px)/2)',
  col3: 'calc((100% - 50px)/3)',
  col4: 'calc((100% - 64px)/4)',
};

export type CardTag = { title?: string; icon?: IconName };

export type CardProps = {
  image?: string;
  title?: string;
  description?: string;
  url?: string;
  column?: keyof typeof columns;
  truncate?: number;
  tags?: CardTag[];
};

export const DEFAULT_TRUNCATE = 50;
export const CARD_IMAGE_HEIGHT = 90;

export const Card = (props: CardProps) => {
  const truncateOptions = {
    length: props.truncate ?? DEFAULT_TRUNCATE,
  };

  const content = (
    <>
      {props.image ? (
        <>
          <div
            style={{
              position: 'absolute',
              backgroundImage: props.image && `url("${props.image}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '100%',
              height: '100%',
              zIndex: -1,
            }}
          />
          <div
            className="bg-item-selected dark:hover:bg-item-dark-selected"
            style={{
              position: 'absolute',
              top: CARD_IMAGE_HEIGHT,
              width: '100%',
              height: '100%',
              opacity: 0.9,
              zIndex: -1,
            }}
          />
        </>
      ) : null}
      <div
        className="flex flex-col p-2"
        style={props.image ? { marginTop: CARD_IMAGE_HEIGHT } : undefined}
      >
        <Text inherit={false} breakLine padding={1}>
          {truncate(props.title, truncateOptions)}
        </Text>
        <Text inherit={false} breakLine padding={1}>
          {truncate(props.description, truncateOptions)}
        </Text>
      </div>
      {props.tags && props.tags.length > 0 ? (
        <TagContainer>
          {props.tags.map((tag) => (
            <Tag key={tag.title} {...tag} />
          ))}
        </TagContainer>
      ) : null}
    </>
  );

  const { isTablet, isPhone } = useWindowSize();
  let column = props.column ?? 'col3';
  if (isTablet) column = 'col2';
  if (isPhone) column = 'col1';

  const containerProps = {
    style: {
      width: columns[column],
      position: 'relative',
      zIndex: 1,
    } as CSSProperties,
    className: classNames(
      'text-item-contrast hover:text-item-contrast',
      'cursor-pointer flex flex-col m-2 select-none overflow-hidden hover:no-underline',
      'stroke-item-contrast hover:stroke-item-dark-hover'
    ),
  };

  return props.url ? (
    <Link to={props.url} {...containerProps}>
      {content}
    </Link>
  ) : (
    <div {...containerProps}>{content}</div>
  );
};

export const CardGrid = ({ children }: { children: JSX.Element[] }) => {
  return <div className="flex flex-wrap px-2 pb-2">{children}</div>;
};

const TagContainer = ({ children }: { children: JSX.Element[] }) => {
  return <div className="flex flex-wrap flex-row">{children}</div>;
};

const Tag = ({ title, icon }: CardTag) => {
  if (!title) return null;
  return (
    <div className="flex items-center text-xs mr-1 mt-1">
      {icon && <Icon inherit={false} icon={icon} className="ml-1" />}
      <Text inherit={false}>{title}</Text>
    </div>
  );
};
