import { useState, useMemo, useCallback } from 'react';
import { head } from 'lodash/fp';
import { useAuth } from 'services/auth/AuthWrapper';
import userApi from 'services/api/portal/administration/api/userApi';
import type { Subject } from '_api/subjects/types';
import { BACKEND_BASE_URL } from 'env';
import { getSubject } from '_api/subjects/service';

const PORTAL_URL = `${BACKEND_BASE_URL}/portal/v0`;

const useSubject = () => {
  const [isFetching, setFetching] = useState(false);
  const [subject, setSubject] = useState<Subject | undefined>();
  const { token } = useAuth();
  const userInstance = useMemo(() => userApi(PORTAL_URL, token), [token]);

  const getUsersByEmail = useCallback(
    async (userEmail: string) => {
      setFetching(true);
      const data = await userInstance.getUsersByEmail(userEmail);
      setSubject(head(data) ?? undefined);
      setFetching(false);
      return data;
    },
    [userInstance]
  );

  const getSubjectById = useCallback(async (subjectId: string) => {
    setFetching(true);
    const { data } = await getSubject({ params: { subject: subjectId } });
    setSubject(data);
    setFetching(false);
    return data;
  }, []);

  const getSubjectByIdOrEmail = useCallback(
    async (idOrEmail: string) => {
      if (isEmail(idOrEmail)) {
        return getUsersByEmail(idOrEmail);
      }
      return getSubjectById(idOrEmail);
    },
    [getSubjectById, getUsersByEmail]
  );

  return {
    isFetching,
    user: subject,
    getSubjectById,
    getSubjectByIdOrEmail,
  };
};

// isEmail returns true if the given string is an email address and not a client.
// Auth0 ends with @clients, so checking that after the `@` there is a dot is enough
// to determine if the given string is an email address.
const isEmail = (idOrEmail: string) => {
  if (!idOrEmail.includes('@')) {
    return false;
  }

  const parts = idOrEmail.split('@');

  if (parts.length !== 2) {
    return false;
  }

  if (parts[1].includes('.')) {
    return true;
  }

  return false;
};

export default useSubject;
