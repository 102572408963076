import { Icon, Intent, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import type { IUser } from 'services/api/portal/administration/api/types';

export const getPictureForNonStandardUser = (sub: string) => {
  if (sub.endsWith('@clients')) {
    return <Icon icon={IconNames.KEY} iconSize={30} />;
  } else {
    return <Icon icon={IconNames.ERROR} iconSize={30} intent={Intent.DANGER} />;
  }
};

export const getUserPicture = (
  isFetching: boolean,
  userId: string,
  pictureURL?: string
) => {
  if (isFetching) {
    return <Spinner size={30} />;
  }
  if (pictureURL) {
    return (
      <img
        className="rounded-full w-7 h-7 text-center text-surface-contrast self-center"
        src={pictureURL}
        alt="avatar"
      />
    );
  } else {
    return getPictureForNonStandardUser(userId);
  }
};

export const getTextForNonStandardUser = (isFetching: boolean, sub: string) => {
  if (isFetching) {
    return '';
  }

  if (sub.endsWith('@clients')) {
    return 'API Key';
  } else {
    return <i>{sub} (invalid user)</i>;
  }
};
