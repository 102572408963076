import { useState } from 'react';
import { Button, UL } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useHistory } from 'react-router';
import type { Organisation } from '_api/administration/service';
import CreateButton from 'components/OrganisationConfiguration/CreateButton';
import OrganisationEditableText from 'components/OrganisationConfiguration/OrganisationEditableText';
import s from 'components/OrganisationConfiguration/index.module.scss';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import { preventDefaultPropagation } from 'utils/common/CommonUtils';

interface IProps {
  chosenOrg: number;
  setChosenOrg: (value: number) => void;
  organisations: Organisation[];
  saveOrganisation: (value: string) => void;
}

const OrganisationList = (props: IProps) => {
  const history = useHistory();
  const [showNewOrganisation, setShowNewOrganisation] = useState(false);

  const handleEditClick = (organisationId: number) => {
    history.push(`/portal/organisation/${organisationId}`);
  };

  const { hasPermission: isAllowedToCreateOrganisation } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'organisation:create',
    },
  });

  const { hasPermission: isAllowedToUpdateOrganisation } = useCheckPermissions({
    permissions: props.organisations.map((org) => ({
      id: org.id,
      type: 'organisation',
      actionScope: 'organisation:update',
    })) as ICheckPermissions[],
  });

  return (
    <div className={s.organizationContainer}>
      <UL className={s.organizationListContainer}>
        <OrganisationEditableText
          text="New Organisation"
          showEditableText={showNewOrganisation}
          hideEditableText={() => setShowNewOrganisation(false)}
          handleSaveConfirm={props.saveOrganisation}
        />
        {props.organisations.map((item, i) => (
          <li
            key={item.id}
            className={
              props.chosenOrg === item.id ? s.highLightChosenItem : undefined
            }
          >
            <div
              className={s.organizationItem}
              role="button"
              tabIndex={0}
              onKeyDown={() => props.setChosenOrg(item.id)}
              onClick={() => props.setChosenOrg(item.id)}
            >
              {item.name}
              <Button
                minimal
                small
                disabled={!isAllowedToUpdateOrganisation?.[i]}
                icon={IconNames.WRENCH}
                onClick={preventDefaultPropagation(handleEditClick)(item.id)}
              />
            </div>
          </li>
        ))}
      </UL>
      <CreateButton
        disabled={!isAllowedToCreateOrganisation}
        text="Organisation"
        onClick={() => setShowNewOrganisation(true)}
      />
    </div>
  );
};

export default OrganisationList;
