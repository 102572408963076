import type { IconName } from '@blueprintjs/core';
import { Alignment, Icon, Navbar, Tab, Tabs } from '@blueprintjs/core';
import type {
  ActionScope,
  ICheckPermissions,
} from 'services/auth/useAuthorisation';
import { useMission } from 'services/Missions';
import { useWindowSize } from 'utils/hooks/useWindowSize';

import s from './index.module.scss';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import { Tooltip } from 'opencosmos-ui';

export interface IBottomBarTab {
  id: string;
  title: string;
  icon: IconName;
  notifications?: number;
  permission?: ActionScope;
}

interface IProps<TAB> {
  selectedTab: TAB;
  setSelectedTab: (value: TAB) => unknown;
  tabList: IBottomBarTab[];
  iconOnlyOnWidth?: number;
}

function BottomNavBar<TAB extends string>(props: IProps<TAB>) {
  const { currentMission } = useMission();
  const windowSize = useWindowSize();
  const isIconOnly =
    windowSize.width &&
    props.iconOnlyOnWidth &&
    windowSize.width < props.iconOnlyOnWidth;

  const { hasPermission } = useCheckPermissions({
    permissions: props.tabList.map((tab) => ({
      type: 'programme',
      actionScope: tab.permission,
      id: currentMission?.programme,
    })) as ICheckPermissions[],
  });

  if (typeof currentMission === 'undefined') {
    return null;
  }

  return (
    <div className={s.bottomBar}>
      <Navbar>
        <Navbar.Group align={Alignment.CENTER} className={s.center}>
          <Tabs
            id="BottomTabs"
            selectedTabId={props.selectedTab}
            onChange={(tab: TAB) => props.setSelectedTab(tab)}
          >
            {props.tabList.map((tab, i) => {
              const doesntHavePermissionIfRequired =
                tab.permission && !hasPermission[i];

              const tabInnerComponents = (
                <>
                  <Icon className={s.icon} icon={tab.icon} />
                  {!isIconOnly && <span className={s.title}>{tab.title}</span>}
                  {tab.notifications !== undefined && tab.notifications > 0 && (
                    <span>
                      <div className={s.notificationIcon}>
                        {tab.notifications}
                      </div>
                    </span>
                  )}
                </>
              );

              if (doesntHavePermissionIfRequired) {
                return (
                  <Tab
                    className="flex justify-center items-center"
                    key={tab.id}
                    id={tab.id}
                    disabled
                  >
                    <Tooltip content="Insufficient permissions">
                      {tabInnerComponents}
                    </Tooltip>
                  </Tab>
                );
              }

              return (
                <Tab
                  className="flex justify-center items-center"
                  key={tab.id}
                  id={tab.id}
                >
                  {tabInnerComponents}
                </Tab>
              );
            })}
          </Tabs>
        </Navbar.Group>
      </Navbar>
    </div>
  );
}

export default BottomNavBar;
