import type { IPointOfInterest } from '../constants/pointsOfInterest/actionTypes';
import type { IRegionOfInterest } from '../constants/regionsOfInterest/actionTypes';
import type { IGroundStation } from '../constants/groundStations/actionTypes';
import {
  MAP_ACTION_ADD,
  MAP_ACTION_REMOVE,
  MAP_ACTION_UPDATE,
} from '../constants/map/constants';
import type { ISatelliteData } from '../constants/satelliteOrbits/actionTypes';

export type IInstance =
  | IPointOfInterest
  | IRegionOfInterest
  | IGroundStation
  | ISatelliteData;
type IAction =
  | typeof MAP_ACTION_ADD
  | typeof MAP_ACTION_UPDATE
  | typeof MAP_ACTION_REMOVE;

export interface ICompareObject {
  action: IAction | string;
  essence: IInstance;
}

export function compare(
  oldArray: IInstance[],
  newArray: IInstance[]
): ICompareObject[] {
  const localOld = [...oldArray];
  const localNew = [...newArray];
  const resultArray = [];

  /**
   * remove equal
   */
  for (let i = 0; i < localOld.length; i++) {
    for (let j = 0; j < localNew.length; j++) {
      if (localOld[i] === localNew[j]) {
        localOld.splice(i, 1);
        localNew.splice(j, 1);
        i--;
        break;
      }
    }
  }

  let action = null;
  while (localNew.length) {
    action = MAP_ACTION_ADD;
    for (let j = 0; j < localOld.length; j++) {
      if (localNew[0].id === localOld[j].id) {
        action = MAP_ACTION_UPDATE;
        localOld.splice(j, 1);
        break;
      }
    }
    resultArray.push({ action, essence: { ...localNew[0] } });
    localNew.splice(0, 1);
  }

  action = null;
  while (localOld.length) {
    action = MAP_ACTION_REMOVE;
    resultArray.push({ action, essence: { ...localOld[0] } });
    localOld.splice(0, 1);
  }
  return resultArray;
}
