import React from 'react';
import type { IAsset } from '../../types/stac-types';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import AssetItem from './AssetItem';
import { Button } from 'opencosmos-ui';
import { downloadFileFromURL } from 'utils/common/CommonUtils';

interface Props {
  previewableAssets: [string, IAsset][];
  nonPreviewableAssets: [string, IAsset][];
  isAssetDisplayed: (id: string) => boolean;
  handleToggleDisplayAsset: (id: string) => void;
  itemID: string;
  collectionID?: string;
  isHighResPermissionGranted: boolean;
}
const AssetsList: React.FC<Props> = ({
  previewableAssets,
  nonPreviewableAssets,
  itemID,
  collectionID,
  isAssetDisplayed,
  handleToggleDisplayAsset,
  isHighResPermissionGranted,
}) => {
  const { sendInfo } = useAnalytics();
  const { translate } = useLocalisation();

  const referenceHrefToDownloadAllAssets = previewableAssets.length
    ? previewableAssets[0][1].href
    : nonPreviewableAssets[0][1].href;

  return (
    <div className="w-full pr-6">
      <div className="w-full pl-7 pr-2 border-r-2 dark:border-neutral-700 box-border">
        {!previewableAssets.length && !nonPreviewableAssets.length ? (
          <div className="w-full mt-1 p-1 flex justify-end gap-1 text-sm dark:text-neutral">
            {translate('datacosmos.catalogAndItems.errors.noAssetsAvailable')}
          </div>
        ) : (
          <Button
            fill
            isDisabled={!isHighResPermissionGranted}
            className={'my-4'}
            onPress={() =>
              downloadFileFromURL(
                `${
                  referenceHrefToDownloadAllAssets
                    .split('/')
                    .slice(0, -1)
                    .join('/') +
                  '/' +
                  `${itemID}_assets.zip`
                }?download-stac-item-assets-as=zip`,
                `${itemID}_assets.zip`
              )
            }
          >
            {translate('datacosmos.catalogAndItems.assets.downloadAllAssets')}
          </Button>
        )}
        {previewableAssets.length > 0 &&
          previewableAssets.map((a) => (
            <AssetItem
              previewable={true}
              data={a[1]}
              id={a[0]}
              itemID={itemID}
              collectionID={collectionID}
              sendInfo={sendInfo}
              key={a[0]}
              isAssetDisplayed={isAssetDisplayed}
              handleToggleDisplayAsset={handleToggleDisplayAsset}
              isHighResPermissionGranted={isHighResPermissionGranted}
            />
          ))}
        {nonPreviewableAssets.length > 0 &&
          nonPreviewableAssets.map((a) => (
            <AssetItem
              previewable={false}
              data={a[1]}
              id={a[0]}
              itemID={itemID}
              collectionID={collectionID}
              sendInfo={sendInfo}
              key={a[0]}
              isAssetDisplayed={isAssetDisplayed}
              handleToggleDisplayAsset={handleToggleDisplayAsset}
              isHighResPermissionGranted={isHighResPermissionGranted}
            />
          ))}
      </div>
    </div>
  );
};
export default AssetsList;
