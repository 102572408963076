import React, { useEffect } from 'react';
// import { get, head, pipe, sortBy } from 'lodash/fp';
import { Button, Callout, ControlGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { copyToClipBoard } from 'utils/common/CommonUtils';
import { getCurrentUtcDate, toFormattedDateTime } from 'utils/common/dateUtils';

import s from '../../index.module.scss';
import type { Ephemeris } from '_api/ephemeris/types';

interface IProps {
  className?: string;
  ephemerisList: Ephemeris[];
  getEphemeris: Function;
}

interface IGSTleHistoryItemProps {
  item: Ephemeris;
  handleCopyClick: Function;
}

const GSTleHistoryItem = (props: IGSTleHistoryItemProps) => {
  return (
    <ControlGroup>
      <Callout className={s.ephemerisHistoryCallout}>
        <pre>{props.item.line1}</pre>
        <pre>{props.item.line2}</pre>
      </Callout>
      <Button
        icon={IconNames.CLIPBOARD}
        //@ts-expect-error
        onClick={props.handleCopyClick}
      />
    </ControlGroup>
  );
};

const GSEphemerisHistory = (props: IProps) => {
  // const [ephemerisListLengthStore, setEphemerisListPassesLength] = useState<
  //   number
  // >();

  useEffect(() => {
    props.getEphemeris({
      direction: 'before',
      time: getCurrentUtcDate(),
      limit: 5,
    });
  }, [props]);

  const handleCopyClick = (ephemeris: Ephemeris) => {
    copyToClipBoard(`${ephemeris.line1}\n${ephemeris.line2}`);
  };

  // TODO: Enable once API becomes available
  // const infiniteScroll = (e: any) => {
  //   const { scrollHeight, scrollTop, clientHeight } = e.target;

  //   const bottom = scrollHeight - scrollTop - clientHeight < 5;
  //   const currentLength = props.ephemerisList.length;

  //   if (bottom && currentLength !== ephemerisListLengthStore) {
  //     setEphemerisListPassesLength(currentLength);

  //     const topItemCreatedAt = pipe(
  //       sortBy('creationTime'),
  //       head,
  //       get('creationTime')
  //     )(props.ephemerisList);

  //     props.getEphemeris({
  //       cursor: topItemCreatedAt,
  //       direction: 'before',
  //       limit: 5,
  //     });
  //   }
  // };

  return (
    <div
      //  onScroll={infiniteScroll}
      className={props.className}
    >
      {props.ephemerisList?.map((item) => (
        <div
          key={item.creationTime}
          className={s.ephemerisHistoryDialogContent}
        >
          <div className={s.ephemerisHistoryContentHeader}>
            <div>
              <b>Created at:</b> {toFormattedDateTime(item.creationTime)}
            </div>
          </div>
          {item.type === 'TLE' && (
            <GSTleHistoryItem
              item={item}
              handleCopyClick={() => handleCopyClick(item)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default GSEphemerisHistory;
