import type { ISatelliteBall } from '../../constants/satelliteBalls/actionTypes';
import { BALL_STATES } from '../../constants/satelliteBalls/constants';

export const computeBallsState = (
  balls: ISatelliteBall[]
): ISatelliteBall[] => {
  let result = balls.map((b) => b);
  let isChanged = false;
  do {
    isChanged = false;
    result = result.map((r): ISatelliteBall => {
      const isOneNotComplete = r.dependsOf.find((dIndex) => {
        try {
          const parentBall = result[dIndex];
          return parentBall.state !== BALL_STATES.COMPLETE;
        } catch (e) {
          return true;
        }
      });
      let state = r.state;
      if (isOneNotComplete === undefined) {
        switch (r.state) {
          case BALL_STATES.DISABLE: {
            state = BALL_STATES.CURRENT;
            isChanged = true;
            break;
          }
        }
      } else {
        switch (r.state) {
          case BALL_STATES.CURRENT: {
            state = BALL_STATES.DISABLE;
            isChanged = true;
            break;
          }
          case BALL_STATES.COMPLETE: {
            state = BALL_STATES.WARNING;
            isChanged = true;
            break;
          }
        }
      }
      return { ...r, state };
    });
  } while (isChanged);
  return result;
};
