import type { PropsWithChildren } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Dialog } from 'opencosmos-ui';

type Props = {
  isOpen: boolean;
  title: string;
  header?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmIsDisabled?: boolean;
};

export default function ConfirmDialog({
  isOpen,
  title,
  header,
  onConfirm,
  onCancel,
  confirmIsDisabled,
  children,
}: PropsWithChildren<Props>) {
  const { translate } = useLocalisation();

  return (
    <Dialog
      isOpen={isOpen}
      hideCancelButton
      onClose={onCancel}
      title={title}
      buttons={[
        {
          text: translate('datacosmos.buttons.confirm'),
          shown: true,
          onPress: onConfirm,
          intent: 'primary',
          isDisabled: confirmIsDisabled,
        },
        {
          text: translate('datacosmos.buttons.cancel'),
          shown: true,
          onPress: onCancel,
        },
      ]}
      style={{ maxWidth: '40rem' }}
    >
      <div className="flex flex-col gap-2">
        {header && <h4>{header}</h4>}
        {children}
      </div>
    </Dialog>
  );
}
