export interface IMeasurementType {
  [normalizeTo: string]: (value: number) => number;
}

export interface IMeasurementTypes {
  [measurementType: string]: {
    [measurement: string]: IMeasurementType;
  };
}

export const measurementTypes: IMeasurementTypes = {
  dist: {
    km: {
      kmTom: (value: number) => value * 1000,
    },
    m: {
      mTokm: (value: number) => Number((value / 1000).toFixed(3)),
    },
  },
  angle: {
    deg: {
      degTorad: (value: number) => value * (Math.PI / 180),
    },
    rad: {
      radTodeg: (value: number) => value * (180 / Math.PI),
    },
  },
  ltan: {
    h: {
      hTodeg: (value: number) => value * (360 / 24),
      hTorad: (value: number) => value * ((2 * Math.PI) / 24),
    },
    deg: {
      degTorad: (value: number) => value * (Math.PI / 180),
      degToh: (value: number) => value * (24 / 360),
    },
    rad: {
      radTodeg: (value: number) => value * (180 / Math.PI),
      radToh: (value: number) => value * (24 / (2 * Math.PI)),
    },
  },
};
