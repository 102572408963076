import React from 'react';
import Select2 from '_molecules/Select2/Select2';
import { Item } from 'react-stately';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
}

const BLEND_MODES = [
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'color-dodge',
  'color-burn',
  'hard-light',
  'soft-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity',
];

/**
 * BlendModeControls is used to control the blending mode on the layers that
 * support it.
 */
const BlendModeControl = ({ disabled, value, onChange }: IProps) => {
  const { translate } = useLocalisation();
  return (
    <Select2
      onSelectionChange={(item) => onChange(item as string)}
      isDisabled={disabled}
      defaultSelectedKey={value}
      label={translate('datacosmos.layers.blendMode')}
      fill
    >
      {BLEND_MODES.map((bm) => (
        <Item key={bm}>
          {translate(
            `datacosmos.layers.blendModes.${bm}` as unknown as TemplateStringsArray
          )}
        </Item>
      ))}
    </Select2>
  );
};

export default BlendModeControl;
