import type { IRegionOfInterest } from '../regionsOfInterest/actionTypes';
import type { IPointOfInterest } from '../pointsOfInterest/actionTypes';

export const OPTIMISATION_FORM_NAME = 'OPTIMISATION_FORM_NAME';

export const OBJECTIVE_TYPES = {
  MISSION_METRIC: 'mission_metric',
  GROUND_SEGMENT_EVENT: 'ground_segment_event',
  POINT_OF_INTEREST_EVENT: 'point_of_interest_event',
  REGION_OF_INTEREST_EVENT: 'region_of_interest_event',
};

export const MISSION_METRIC = {
  NUMBER_OF_SATELLITES: 'number_of_satellites',
  NUMBER_OF_PLANES: 'number_of_planes',
  NUMBER_OF_GROUND_STATIONS: 'number_of_ground_stations',
};

export const OBJECTIVE_METRIC = {
  INCIDENCE: 'incidence',
  AVG_DURATION: 'average',
  MAX_DURATION: 'maximum',
  MIN_DURATION: 'minimum',
  DURATION_STD_DEVIATION: 'std',
};

export const SCOPE = {
  COVERAGE: 'coverage',
  REVISIT: 'revisit',
};

export const GROUND_SEGMENT_EVENT = {
  OBJECT: 'all_ground_stations',
  EVENT: {
    VISIBILITY: 'visibility',
  },
};
export const POINT_OF_INTEREST_EVENT = {
  EVENT: {
    VISIBILITY: 'visibility',
    ILLUMINATED_VISIBILITY: 'illuminated_visibility',
  },
};
export const REGION_OF_INTEREST_EVENT = {
  EVENT: {
    VISIBILITY: 'visibility',
    ILLUMINATED_VISIBILITY: 'illuminated_visibility',
  },
};

interface IConstraints {
  criterion: -1 | 0 | 1;
  constraints?: {
    min: number;
    max: number;
  };
}

export type IObjectiveType =
  | typeof OBJECTIVE_TYPES.MISSION_METRIC
  | typeof OBJECTIVE_TYPES.GROUND_SEGMENT_EVENT
  | typeof OBJECTIVE_TYPES.POINT_OF_INTEREST_EVENT
  | typeof OBJECTIVE_TYPES.REGION_OF_INTEREST_EVENT;

interface IMissionMetric {
  metric:
    | typeof MISSION_METRIC.NUMBER_OF_SATELLITES
    | typeof MISSION_METRIC.NUMBER_OF_PLANES
    | typeof MISSION_METRIC.NUMBER_OF_GROUND_STATIONS;
}

interface IObjectMetric {
  metric:
    | typeof OBJECTIVE_METRIC.INCIDENCE
    | typeof OBJECTIVE_METRIC.AVG_DURATION
    | typeof OBJECTIVE_METRIC.MIN_DURATION
    | typeof OBJECTIVE_METRIC.MAX_DURATION
    | typeof OBJECTIVE_METRIC.DURATION_STD_DEVIATION;
}

interface IGroundSegmentEvent extends IObjectMetric {
  object?: typeof GROUND_SEGMENT_EVENT.OBJECT;
  event?: typeof GROUND_SEGMENT_EVENT.EVENT.VISIBILITY;
  scope?: typeof SCOPE.COVERAGE | typeof SCOPE.REVISIT;
}

interface IPointOfInterestEvent extends IObjectMetric {
  object?: IPointOfInterest;
  event?:
    | typeof POINT_OF_INTEREST_EVENT.EVENT.VISIBILITY
    | typeof POINT_OF_INTEREST_EVENT.EVENT.ILLUMINATED_VISIBILITY;
  scope?: typeof SCOPE.COVERAGE | typeof SCOPE.REVISIT;
}

interface IRegionOfInterestEvent extends IObjectMetric {
  object?: IRegionOfInterest;
  event?:
    | typeof REGION_OF_INTEREST_EVENT.EVENT.VISIBILITY
    | typeof REGION_OF_INTEREST_EVENT.EVENT.ILLUMINATED_VISIBILITY;
  scope?: typeof SCOPE.COVERAGE | typeof SCOPE.REVISIT;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type IOO =
  | (IMissionMetric &
      IGroundSegmentEvent &
      IPointOfInterestEvent &
      IRegionOfInterestEvent)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any;

export interface IOptimisationObjective extends IOO, IConstraints {
  name: () => string;
  type: IObjectiveType;
}
