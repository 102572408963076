import { useRef } from 'react';
import { useDatePickerState } from 'react-stately';
import type { DatePickerStateOptions } from '@react-stately/datepicker';
import type { CalendarDate } from '@internationalized/date';
import { useDatePicker } from 'react-aria';
import DateField from '_molecules/DateField/DateField';
import Button from '_molecules/Button/Button';
import Calendar from '_molecules/Calendars/Calendar/Calendar';
import classNames from 'classnames';
import Overlay from '_atoms/Overlay/Overlay';
import { zIndexValues } from 'opencosmos-ui/constants';

export interface IDatePickerProps extends DatePickerStateOptions<CalendarDate> {
  fill?: true;
  className?: string;
}

const DatePicker = (props: IDatePickerProps) => {
  const state = useDatePickerState(props);
  const ref = useRef<HTMLDivElement>(null);

  const {
    buttonProps,
    calendarProps,
    dialogProps,
    groupProps,
    labelProps,
    fieldProps,
  } = useDatePicker(props, state, ref);
  return (
    <div style={{ position: 'relative' }} className={props.className}>
      <span {...labelProps}>{props.label}</span>
      <div {...groupProps} ref={ref} style={{ display: 'flex' }}>
        <DateField
          {...fieldProps}
          className={classNames('gap-0', {
            'w-full': props.fill,
          })}
          fill={props.fill}
        />
        <Button
          {...buttonProps}
          onPress={() => state.setOpen(!state.isOpen)}
          icon="Calendar"
          size={24}
          className="border-2 border-item border-l-0 flex items-center justify-center"
          data-testid="open-calendar-btn"
        />
      </div>
      {state.isOpen && (
        <Overlay
          {...dialogProps}
          isOpen={state.isOpen}
          onClose={() => state.setOpen(false)}
          style={{ zIndex: zIndexValues.popover }}
        >
          <Calendar highligtedDates={[]} {...calendarProps} />
        </Overlay>
      )}
    </div>
  );
};

export default DatePicker;
