import React from 'react';
import { Button } from '@blueprintjs/core';

interface IProps {
  disabled?: boolean;
  onRemove?: () => void;
}

const GenericRemoveButton = (props: IProps) =>
  props.onRemove ? (
    <Button
      disabled={props.disabled}
      intent="danger"
      icon="remove"
      onClick={props.onRemove}
    />
  ) : null;

export default GenericRemoveButton;
