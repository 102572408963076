/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const mapAsync = (fn: Function) => async (items: any[]) => {
  const resp = [];

  for (const item of items) {
    const res = await fn(item);

    if (res) resp.push(res);
  }

  return resp;
};
