import moment from 'moment';
import { isEmpty, isUndefined } from 'lodash/fp';
import { isNull } from 'lodash';
import { DateInput } from '@blueprintjs/datetime';
import { Tag } from '@blueprintjs/core';
import { DATETIME_FORMAT, ISO_FORMAT } from 'constants/datetime';
import { toTimezone } from 'utils/common/dateUtils';

export interface IProps {
  handleBlur: () => void;
  handleFocus: () => void;
  handleChange: Function;
  disabled: boolean;
  fullPath: string;
  value: Date;
  setTooltipOpen: (open: boolean) => void;
}

const CommandStringDateInput = (props: IProps) => {
  const { handleChange, disabled, value, fullPath, setTooltipOpen } = props;

  const handleTimeInputChange = (newValue: Date | undefined | null) => {
    if (
      isUndefined(newValue) ||
      !moment(newValue).isValid() ||
      isNull(newValue)
    ) {
      if (isEmpty(newValue)) {
        handleChange(newValue, fullPath);
      }
      return;
    }
    const valueTZ = toTimezone(newValue, true);
    handleChange(valueTZ.toISOString(), fullPath);
  };

  return (
    <div
      onMouseEnter={() => {
        setTooltipOpen(true);
      }}
      onMouseLeave={() => {
        setTooltipOpen(false);
      }}
      className={'w-full'}
      data-testid="date-time-picker"
    >
      <DateInput
        fill
        rightElement={<Tag minimal>UTC</Tag>}
        disabled={disabled}
        formatDate={(date) =>
          moment(date, 'DD/MM/YYYY HH:mm:ss').format(ISO_FORMAT)
        }
        parseDate={(str) => moment(str, [DATETIME_FORMAT, ISO_FORMAT]).toDate()}
        placeholder={DATETIME_FORMAT}
        timePrecision="second"
        value={toTimezone(value)}
        onChange={(date) => {
          return handleTimeInputChange(date);
        }}
      />
    </div>
  );
};

export default CommandStringDateInput;
