import type { BASE64_MODES } from '../../../../../../../utils/common/base64Utils';
import { validate } from '../../../../../../../utils/common/base64Utils';

interface Base64InputValidatorOptions {
  stringBase64?: {
    mode?: BASE64_MODES;
  };
}

export default (
  value: string,
  params: Base64InputValidatorOptions
): string | undefined => {
  const mode = params?.stringBase64?.mode;

  if (!mode) {
    return undefined;
  }

  const validator = validate[mode];

  if (validator && !validator(value)) {
    return 'Value incorrectly formatted';
  }

  return undefined;
};
