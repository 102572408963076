import {
  useState,
  useContext,
  useEffect,
  useCallback,
  createContext,
  useMemo,
} from 'react';
import moment from 'moment';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentMissionThunk } from 'actions/missions/thunks';
import { DAYS_INPUT } from 'pages/shared/constants/geometry/constants';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import type { Mission as PortalMission } from '_api/administration/service';
import { useApiMissionSelectorService } from './api/portal/organisation/useApiMissionSelectorService';
import { useAuth } from './auth/AuthWrapper';

export interface IProps {
  children: JSX.Element;
}

export interface IMission {
  mission: number;
  programme: number;
  organisation?: number;
  name: string;
  date?: Date;
  days: number;
  current?: boolean;
}

const fromAPIMission = (mission: PortalMission): IMission => ({
  mission: mission.id,
  name: mission.name,
  programme: mission.programmeId,
  date: moment(mission.createdAt).toDate(),
  days: DAYS_INPUT.defaultValue,
});

export const MissionContext = createContext<IMissionContext>(
  null as unknown as IMissionContext
);
export const useMission = () => useContext<IMissionContext>(MissionContext);
export const withMission = (Component: React.ElementType) => {
  const ComponentWithMission = (props: object) => {
    return <Component {...props} {...useMission()} />;
  };
  return ComponentWithMission;
};

const useMissionsProvider = () => {
  const [currentMission, setCurrentMission] = useState<IMission>();
  const [missions, setMissions] = useState<IMission[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const urlMatch = useRouteMatch<{
    mission: string;
    route: string;
    // :route* doesn't match slashes under route property
    // so we need to add a separate property to match the rest of the url
    '0'?: string;
  }>({
    path: ['/ops/mission/:mission/:route*', '/ops/mission/:mission'],
    exact: false,
  });

  // Needed to avoid infinite loop in useEffect
  const urlSubpage = useMemo(() => {
    return urlMatch?.params[0] ?? '';
  }, [urlMatch?.params]);

  const { user, token } = useAuth();

  const missionIdInUrl = urlMatch ? urlMatch.params.mission : undefined;

  const [, setLocalStorage] = useLocalStorage<{
    currentMissionId: number | undefined;
  }>('missions', {
    currentMissionId: undefined,
  });

  const {
    organisationsList,
    programmesList,
    missionsList,
    ...organisationApi
  } = useApiMissionSelectorService(user?.sub);

  const handleSetMission = useCallback(
    (mission: IMission, redirect?: boolean) => {
      setLocalStorage({ currentMissionId: mission.mission });
      // @ts-expect-error
      dispatch(setCurrentMissionThunk(mission));
      setCurrentMission(mission);
      if (redirect) {
        const route = urlMatch?.params.route ?? '';
        history.push(`/ops/mission/${mission.mission}/${route}${urlSubpage}`);
      }
    },
    [dispatch, history, setLocalStorage, urlMatch?.params.route, urlSubpage]
  );

  const getMissionById = useCallback(
    (missionId: number) => {
      return missions.find(({ mission }) => mission === missionId);
    },
    [missions]
  );

  // Read the url and set the mission appropriately
  useEffect(() => {
    if (missionIdInUrl === undefined) return;
    // Make an exception for telemetry because it can analyse any mission
    const isGrafana = !window.location.href.includes('/ops/telemetry');
    if (currentMission === undefined) {
      const parsedMissionIdInUrl = parseInt(missionIdInUrl, 10);
      const mission = getMissionById(parsedMissionIdInUrl);
      if (mission) handleSetMission(mission, !isGrafana);
    } else if (currentMission.mission) {
      handleSetMission(currentMission, !isGrafana);
    }
  }, [currentMission, getMissionById, handleSetMission, missionIdInUrl]);

  useEffect(() => {
    const missionWithOrganisation = missionsList.map((mission) => {
      const prog = programmesList.find(({ id }) => id === mission.programmeId);
      const org = organisationsList.find(
        ({ id }) => id === prog?.organisationId
      );
      return { ...fromAPIMission(mission), organisation: org?.id };
    });
    setMissions(missionWithOrganisation);
  }, [missionsList, programmesList, organisationsList, token]);

  return {
    currentMission,
    missions,
    currentMissionId: currentMission?.mission,
    organisations: organisationsList,
    programmes: programmesList,
    setCurrentMission: handleSetMission,
    setMissions,
    getMissionById,
    organisationApi,
  };
};

export type IMissionContext = ReturnType<typeof useMissionsProvider>;

export const MissionsProvider = ({ children }: IProps) => {
  const missionProvider = useMissionsProvider();

  return (
    <MissionContext.Provider value={missionProvider}>
      {children}
    </MissionContext.Provider>
  );
};
