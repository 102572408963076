import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import { ops } from 'constants/mixpanelAnalytics';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { REQUEST_ERROR } from 'constants/errors';
import { BACKEND_BASE_URL } from 'env';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import {
  getTerminalSession,
  getTerminalSessions,
} from '_api/terminalSession/service';
import moment from 'moment';
import type { TerminalSession } from '_api/terminalSession/types';

const axiosConfig: AxiosRequestConfig = {
  baseURL: BACKEND_BASE_URL,
};

const axiosInst = axios.create(axiosConfig);

export function useApiOpsGetTerminalSessions(missionId: number | undefined) {
  const [sessions, setSessions] = useState<{
    [key: number]: TerminalSession[];
  }>({});
  const { token, checkPermissions } = useAuth();

  const sortTerminalSessions = async (
    sess: TerminalSession[]
  ): Promise<TerminalSession[]> => {
    return new Promise((resolve) => {
      const withConnectionsSorted = sess
        .filter((s) => Boolean(s.connectedUsers))
        .sort((a, b) => {
          const aLatest = a.connectedUsers?.sort((aU, bU) => {
            return moment(bU.lastActiveAt).diff(moment(aU.lastActiveAt));
          });

          const bLatest = b.connectedUsers?.sort((aU, bU) => {
            return moment(bU.lastActiveAt).diff(moment(aU.lastActiveAt));
          });

          if (!aLatest || !bLatest) {
            return 0;
          }

          return moment(bLatest[0].lastActiveAt).diff(
            moment(aLatest[0].lastActiveAt)
          );
        });

      const withoutConnections = sess.filter((s) => !s.connectedUsers);

      const sorted = withConnectionsSorted.concat(withoutConnections);

      resolve(sorted);
    });
  };

  const fetchTerminalSessions = useCallback(async () => {
    if (!token || missionId === undefined) return;

    const isUserAccessible = !(await checkPermissions({
      id: missionId,
      type: 'mission',
      actionScope: 'ops:rti:session:read',
    }));

    const { data } = await getTerminalSessions({
      params: {
        isUserAccessible: isUserAccessible,
        missionId,
      },
    });

    if (!data) {
      return;
    }

    const sorted = await sortTerminalSessions(data);

    setSessions((prev) => ({ ...prev, [missionId]: sorted }));
  }, [checkPermissions, missionId, token]);

  const fetchTerminalSession = useCallback(
    async (id: string | number) => {
      if (!token) return;

      const { data } = await getTerminalSession({ params: { sessionId: id } });

      return data;
    },
    [token]
  );

  const setMissionSessions = useCallback(
    (updatedSessions: TerminalSession[]) => {
      if (missionId === undefined) return;
      setSessions((prev) => ({ ...prev, [missionId]: updatedSessions }));
    },
    [missionId]
  );

  useEffect(() => {
    if (missionId) {
      void fetchTerminalSessions();
    }
  }, [fetchTerminalSessions, missionId]);

  const currentSessions = useMemo(() => {
    if (missionId === undefined) return [];
    const value = sessions[missionId] as TerminalSession[] | undefined;
    if (!value) return [];
    return value;
  }, [missionId, sessions]);

  return {
    sessions: currentSessions,
    fetchTerminalSessions,
    setMissionSessions,
    fetchTerminalSession,
  };
}

interface INewTerminalSession {
  name: string;
  missionId: string;
  userAccessible: boolean;
}

export function useApiPostOpsNewTerminalSessions() {
  const { token } = useAuth();
  const { sendError, sendInfo } = useAnalytics();

  return useCallback(
    async (session: INewTerminalSession) => {
      if (!token) return;

      try {
        const {
          data: { data },
        } = await axiosInst.post<{ data: TerminalSession }>(
          '/ops/v0/terminal/sessions/',
          session,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        sendInfo({
          type: ops.RTI.TERMINAL.CREATE.SUCCESS,
          action: 'Create terminal session',
          item: 'Terminal session',
          module: 'OPS',
          additionalParams: {
            session: data.id,
            mission: data.missionId,
          },
        });
        return data;
      } catch (e) {
        showErrorMessage(REQUEST_ERROR);

        sendError({
          type: ops.RTI.TERMINAL.CREATE.ERROR,
          error: {
            'Failure Type': 'Validation error',
            error: 'Something went wrong during terminal session creation.',
          },
          module: 'OPS',
          action: 'Create terminal session',
          item: 'Terminal session error',
        });
        return undefined;
      }
    },
    [sendError, sendInfo, token]
  );
}

export function useApiUpdateOpsTerminalSessions() {
  const { token } = useAuth();

  return useCallback(
    async (session: TerminalSession) => {
      if (!token) return;

      const response = await axiosInst.put(
        `/ops/v0/terminal/sessions/${session.id}`,
        session,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response?.data;
    },
    [token]
  );
}

export function useApiCloseOpsTerminalSessions() {
  const { token } = useAuth();

  return useCallback(
    async (session: TerminalSession) => {
      if (!token) return;
      session.open = false;

      const response = await axiosInst.put(
        `/ops/v0/terminal/sessions/${session.id}`,
        session,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response?.data;
    },
    [token]
  );
}
