/**
 * https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
 */
const ICON_SIZE = 64;

const prepareIcon = (url: string): google.maps.Icon => ({
  url: url,
  size: new google.maps.Size(ICON_SIZE, ICON_SIZE),
  scaledSize: new google.maps.Size(ICON_SIZE, ICON_SIZE),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(ICON_SIZE * 0.5, ICON_SIZE * 0.75),
});

export const pointOfInterestDefine = (): google.maps.MarkerOptions => {
  return {
    icon: prepareIcon('/images/point_of_interest_icon.svg'),
  };
};

export const pointOfInterestFocused = (): google.maps.MarkerOptions => {
  return {
    icon: prepareIcon('/images/point_of_interest_focused_icon.svg'),
  };
};

export const supportingPointDefine = (): google.maps.MarkerOptions => {
  return {
    icon: prepareIcon('/images/point_of_interest_supporting_icon.svg'),
  };
};
