import { byteArrayToBase16String } from '../common/binnaryUtils';

const toBytesInt32 = (num: number) =>
  new Uint8Array([
    (num & 0xff000000) >> 24,
    (num & 0x00ff0000) >> 16,
    (num & 0x0000ff00) >> 8,
    num & 0x000000ff,
  ]);

export const crc32ChecksumToHex = (checksum: number) => {
  return byteArrayToBase16String(toBytesInt32(checksum));
};
