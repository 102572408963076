export const LO_ADD = 'LO_ADD';
export const LO_UPDATE = 'LO_UPDATE';
export const LO_UPLOAD = 'LO_UPLOAD';

export interface ILaunchOpportunity {
  id: string;
  launcher: string;
  date: string;
  altitude: number;
  inclination: number;
  ltan: number;
  sso: boolean;
  select: boolean;
  optimize: boolean;
}

export interface ILOState {
  list: ILaunchOpportunity[];
}

interface ILOAddAction {
  type: typeof LO_ADD;
  launchOpportunity: ILaunchOpportunity;
}

interface ILOUpdateAction {
  type: typeof LO_UPDATE;
  launchOpportunity: ILaunchOpportunity;
}

interface ILOUploadAction {
  type: typeof LO_UPLOAD;
  launchOpportunities: ILOState;
}

export type LaunchOpportunitiesActionTypes =
  | ILOAddAction
  | ILOUpdateAction
  | ILOUploadAction;
