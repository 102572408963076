import React from 'react';
import { Classes, NonIdealState } from '@blueprintjs/core';
import LibraryTelemetryDefinitionsRow from './LibraryTelemetryDefinitionsRow';
import { IconNames } from '@blueprintjs/icons';
import { isNil } from 'lodash';

import type { Telemetry } from '_api/telemetry/types';
import { useAlerts } from 'services/api/telemetry/AlertsProvider';
import { useTelemetryDefinitions } from 'services/api/telemetry/TelemetryDefinitionsProvider';

import s from './index.module.scss';

interface IProps {
  definitionList: Telemetry[];
  isShowNewDefinition: boolean;
  setShowNewDefinition: (value: boolean) => void;
  connectWithAlerts?: boolean;
}

const LibraryTelemetryDefinitionsTable = (props: IProps) => {
  const { definitionList } = props;

  const { isFetching } = useTelemetryDefinitions();
  const { highlightTelemetryDefinition } = useAlerts();
  let handleClickHighlight: ((name: string | null) => void) | null;
  if (props.connectWithAlerts) {
    //@ts-expect-error
    //TODO: Fix properly down the line
    handleClickHighlight = highlightTelemetryDefinition;
  }

  const renderTableWithBody = (children: React.ReactNode) => (
    <table className={[Classes.HTML_TABLE, s.tableContainer].join(' ')}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Labels</th>
          <th>Subsystem</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );

  const renderSkeletonState = () => {
    const rows = [];
    for (let i = 0; i < 6; i++) {
      rows.push(<LibraryTelemetryDefinitionsRow isLoading />);
    }
    return renderTableWithBody(rows);
  };

  if (isFetching) {
    return renderSkeletonState();
  }

  if (isNil(definitionList)) {
    return (
      <NonIdealState
        icon={IconNames.SCATTER_PLOT}
        title="No telemetry definitions"
      />
    );
  }

  return renderTableWithBody(
    <>
      {definitionList.map((telemetry) => (
        <LibraryTelemetryDefinitionsRow
          key={telemetry.frameId}
          telemetryDefinition={telemetry}
          //@ts-expect-error
          //TODO: Fix properly down the line
          highlightTelemetryDefinition={handleClickHighlight}
        />
      ))}
    </>
  );
};

export default LibraryTelemetryDefinitionsTable;
