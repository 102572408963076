import colorPalette from 'constants/colorPalette';
import type { COLORMAP } from 'datacosmos/entities/bandAlgebraLayer';
import Input from '_molecules/Input/Input';

interface IProps {
  colormap: COLORMAP;
  scaleMin?: {
    value: string | undefined;
    setMin: (min: string) => void;
  };
  scaleMax?: {
    value: string | undefined;
    setMax: (max: string) => void;
  };
}

/**
 *
 * @param colormap Name of the colormap to display
 * @param scaleMin use if you want to display number scale under the color bar
 * @param scaleMax use if you want to display number scale under the color bar
 * @returns
 */
const ColormapPreviewBar = ({ colormap, scaleMin, scaleMax }: IProps) => {
  //calculate ticks for colormap scale
  const calculateScale = (min?: string, max?: string) => {
    if (min === undefined || max === undefined) {
      return [NaN, NaN, NaN, NaN];
    }
    const a = parseFloat(min);
    const b = parseFloat(max);
    return [
      undefined,
      Number((a + (b - a) / 4).toFixed(2)),
      Number((a + (b - a) / 2).toFixed(2)),
      Number((a + (3 * (b - a)) / 4).toFixed(2)),
    ];
  };
  return (
    <div className="w-full">
      <div
        style={{
          display: 'flex',
          width: '100%',
          position: 'relative',
          height: '30px',
        }}
      >
        {colorPalette[colormap].map((colorCode: string) => (
          <div
            key={colorCode}
            style={{
              background: `${colorCode}`,
              height: '25px',
              width: '100%',
              display: 'flex',
              top: 0,
            }}
          ></div>
        ))}
      </div>

      <div className="flex w-full gap-4  justify-between">
        {scaleMax && scaleMin && (
          <>
            <Input
              type="number"
              className="w-8 h-8 border-2 text-[10px] pr-1 pl-1"
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                scaleMin?.setMin(e.target.value);
              }}
              placeholder="Min"
            />

            {calculateScale(scaleMin.value, scaleMax.value).map((value) => (
              <small key={value}>{value && !isNaN(value) ? value : ''}</small>
            ))}

            <Input
              type="number"
              className="w-8 h-8 border-2 text-[10px] pr-1 pl-1"
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
              onChange={(e) => {
                scaleMax?.setMax(e.target.value);
              }}
              placeholder="Max"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ColormapPreviewBar;
