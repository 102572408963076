import React from 'react';
import type { IconName } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core';
import TagHeader from '../../../../../../components/common/TagHeader';
import s from './index.module.scss';

export interface IProps {
  children: React.ReactNode;
  title: string;
  icon: IconName;
  'data-testid'?: string;
}

const SideWrapper = (props: IProps) => (
  <>
    <div data-testid={props['data-testid']} className={s.variablesTitle}>
      <TagHeader
        icon={props.icon}
        title={props.title}
        intent={Intent.PRIMARY}
      />
    </div>
    {props.children}
  </>
);

export default SideWrapper;
