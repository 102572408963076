import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
  addGroupSatelliteBall,
  updateGroupSatelliteBall,
  removeGroupSatelliteBall,
} from './action';
import type { AppState } from '../../reducers/rootReducer';
import type { IGroup } from '../../../../../constants/groupSatelliteBalls/actionTypes';

export const addGroupSB =
  (group: IGroup): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(addGroupSatelliteBall(group));
  };

export const updateGroupSB =
  (group: IGroup): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(updateGroupSatelliteBall(group));
  };

export const removeGroupSB =
  (group: IGroup): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(removeGroupSatelliteBall(group));
  };
