import React from 'react';
import { flatten, get, map, pipe } from 'lodash/fp';

import { MapContainer } from 'components/eventsMap/MapContainer';
import { groundStationDrawingWithoutRedux } from 'services/mapService/mapDrawing';
import { STATION_ZOOM } from 'constants/ops/gs_scheduling/constants';
import { MAP_ID_GROUND_STATIONS, MAP_TYPE } from 'constants/map/constants';
import type { IGroundStation } from 'constants/groundStations/actionTypes';
import { ASTRUMS } from 'constants/API/constant';

import GSList from './GSList';
import GSCurrentEphemeris from '../GSTle';
import useGroundStationList from '../../hooks/useGroundStationList';

import s from '../../index.module.scss';
import type { GS } from '_api/groundStation/types';
import type { Ephemeris, EphemerisParams } from '_api/ephemeris/types';

interface IProps {
  groundStations: GS[];
  chosenStationIdList: number[];
  ephemeris: Ephemeris;
  ephemerisView: Ephemeris;
  ephemerisList: Ephemeris[];
  getEphemeris: (params: EphemerisParams) => void;
  getEphemerisById: (ephemerisId: number) => void;
  setChosenStationIdList: (value: number[]) => void;
  saveTLE: (line1: string, line2: string) => void | Promise<void>;
  isSavingTLE: boolean;
}

const toMapGS = (gs: GS, ids?: number[]): IGroundStation => ({
  id: gs.id,
  name: gs.name,
  lat: get('antennas[0].location.latitude', gs) as number,
  lon: get('antennas[0].location.longitude', gs) as number,
  altitude: get('antennas[0].location.altitude', gs) as number,
  elevationAngle: get('antennas[0].minElevation', gs) as number,
  select: Boolean(ids?.includes(gs.id)),
  optimize: false,
  custom: false,
  astrum: ASTRUMS.EARTH,
});

const GSMapListContainer = (props: IProps) => {
  const {
    chosenStationIdList,
    ephemeris,
    ephemerisList,
    groundStations,
    getEphemeris,
  } = props;

  const {
    focus,
    focusedCoordinate,
    currentZoom,
    currentFocusId,
    setCurrentFocusId,
    handleGSDetailZoomClick,
    handleProviderCheckBoxChange,
    handleGSCheckBoxChange,
    handleZoomChange,
    handleMapStationClick,
  } = useGroundStationList(props);

  const renderGroundStations = () => {
    const groundStationsList = groundStations.map((gs) => ({
      ...toMapGS(gs, chosenStationIdList),
      id: `${gs.id}_antenna_0`,
    }));

    const groundStationsAntennasList = groundStations.map((gs) =>
      gs.antennas.map((antenna) => ({
        ...gs,
        antennas: [antenna],
      }))
    );

    const fullGroundStationsList = pipe(
      map((list: GS[]) =>
        list.map((gs, index) => ({
          ...toMapGS(gs, chosenStationIdList),
          id: `${gs.id}_antenna_${index}`,
        }))
      ),
      flatten
    )(groundStationsAntennasList);

    return {
      list:
        currentZoom >= STATION_ZOOM
          ? fullGroundStationsList
          : groundStationsList,
    };
  };

  return (
    <div className={s.mapListContainer}>
      <GSList
        groundStations={groundStations}
        currentFocusId={Number(currentFocusId)}
        currentStationIdList={chosenStationIdList}
        currentZoom={currentZoom}
        setCurrentFocusId={setCurrentFocusId}
        handleGSDetailZoomClick={handleGSDetailZoomClick}
        handleProviderCheckBoxChange={handleProviderCheckBoxChange}
        handleGSCheckBoxChange={handleGSCheckBoxChange}
      />
      <MapContainer
        pageKey="GS-Scheduling"
        zoom={currentZoom}
        mapId={MAP_ID_GROUND_STATIONS}
        mapType={MAP_TYPE.GROUND_STATIONS}
        groundStations={renderGroundStations()}
        groundStationDrawing={groundStationDrawingWithoutRedux(
          handleMapStationClick,
          handleZoomChange
        )}
        coordinatesToFocus={focusedCoordinate}
        focus={focus}
      />
      <GSCurrentEphemeris
        ephemeris={ephemeris}
        ephemerisList={ephemerisList}
        getEphemeris={getEphemeris}
        saveTLE={props.saveTLE}
        isSavingTLE={props.isSavingTLE}
      />
    </div>
  );
};

export default GSMapListContainer;
