import { DATE_FORMAT } from 'constants/datetime';
import moment from 'moment';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useMission } from 'services/Missions';
import { ActivityCard } from 'pages/ops/Scheduling/components/ActivityCard';
import type {
  Activity,
  ActivityType,
  ActivityStatus,
  ActivityWithRequestData,
} from '_api/activities/service';
import ActivityCalendar from '_organisms/ActivityCalendar/ActivityCalendar';
import { useHistory, useRouteMatch } from 'react-router';
import { Spinner } from '@blueprintjs/core';
import SchedulingTable, {
  DEFAULT_HIDDEN_COLUMNS,
} from '../SchedulingTable/SchedulingTable';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useActivities } from '../../context/ActivitiesProvider';
import SchedulingHeader from '../SchedulingHeader';

export const ScheduleActivityList = () => {
  const routeMatch = useRouteMatch<{ mission: string; activity: string }>(
    '/ops/mission/:mission/schedule/activity/:activity'
  );

  const {
    activities,
    requests,
    fromToDate,
    setFromToDate,
    isLoading: loading,
    viewMode,
    setViewMode,
  } = useActivities();

  const history = useHistory();

  const { currentMissionId } = useMission();

  const [selectedStatuses, setSelectedStatuses] = useState<ActivityStatus[]>(
    []
  );

  const [selectedTypes, setSelectedTypes] = useState<ActivityType[]>([]);

  const [filtered, setFiltered] = useState<Activity[]>(activities);

  const [tableHiddenColumns, setTableHiddenColumns] = useLocalStorage(
    'table-hidden-columns',
    DEFAULT_HIDDEN_COLUMNS
  );

  // Used for displaying request notes in the table
  const activitiesWithRequestData: ActivityWithRequestData[] = useMemo(
    () =>
      filtered.map(
        (a) =>
          ({
            ...a,
            request_note:
              requests.find((r) => a.tasking_request_ids.includes(r.id))
                ?.notes ?? '',
            organisation_name: requests.find((r) =>
              a.tasking_request_ids.includes(r.id)
            )?.organisation_name,
            region_name: requests.find((r) =>
              a.tasking_request_ids.includes(r.id)
            )?.region_name,
          } as ActivityWithRequestData)
      ),
    [filtered, requests]
  );

  const groupedActivities = useMemo(() => {
    return filtered.reduce<{ [date: string]: Activity[] }>((acc, value) => {
      return {
        ...acc,
        [moment.utc(value.start_date).format(DATE_FORMAT)]: [
          ...(acc[moment.utc(value.start_date).format(DATE_FORMAT)] || []),
          value,
        ],
      };
    }, {});
  }, [filtered]);

  const toggleHiddenColumns = useCallback(
    (column: string) => {
      if (tableHiddenColumns.includes(column)) {
        setTableHiddenColumns(tableHiddenColumns.filter((c) => c !== column));
      } else {
        setTableHiddenColumns([...tableHiddenColumns, column]);
      }
    },
    [setTableHiddenColumns, tableHiddenColumns]
  );

  const handleActivityClick = useCallback(
    (activity: Activity | undefined) => {
      if (!currentMissionId) {
        return;
      }

      if (!activity) {
        history.push(
          `/ops/mission/${currentMissionId}/schedule${history.location.search}`
        );
        return;
      }

      history.push(
        `/ops/mission/${currentMissionId}/schedule/activity/${activity.id}${location.search}`
      );
    },
    [currentMissionId, history]
  );

  useEffect(() => {
    setFiltered(
      activities
        .filter((activity) =>
          selectedStatuses.length > 0
            ? selectedStatuses.some((status) => status === activity.status)
            : true
        )
        .filter((activity) =>
          selectedTypes.length > 0
            ? selectedTypes.some((type) => type === activity.type.toString())
            : true
        )
    );
  }, [selectedStatuses, selectedTypes, fromToDate, activities]);

  const renderWithHeader = (content: React.ReactNode) => {
    return (
      <>
        <div>
          <SchedulingHeader
            selectedStatuses={selectedStatuses}
            setActivityStatus={setSelectedStatuses}
            setSelectedTypes={setSelectedTypes}
            selectedTypes={selectedTypes}
            activities={activitiesWithRequestData}
            toggleHiddenColumns={toggleHiddenColumns}
            tableHiddenCols={tableHiddenColumns}
            isActivitySelected={routeMatch?.params.activity !== undefined}
          />
        </div>
        <div
          style={{
            height: '100%',
            overflowY: 'auto',
            flex: 1,
          }}
          data-testid="schedule-activity-list"
        >
          {content}
        </div>
      </>
    );
  };

  const renderWithLoading = (content: React.ReactNode) => {
    if (loading) {
      return <Spinner />;
    }
    return content;
  };

  const renderListMode = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          maxHeight: 'inherit',
        }}
      >
        {Object.keys(groupedActivities)
          .sort(
            (a, b) =>
              moment(b, 'DD-MM-YYYY').toDate().getTime() -
              moment(a, 'DD-MM-YYYY').toDate().getTime()
          )
          .reverse()
          .map((date) => (
            <div
              key={date}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <span style={{ marginLeft: '50px' }}>{date}</span>
              {groupedActivities[date]
                .sort(
                  (a, b) =>
                    moment(a.start_date).toDate().getTime() -
                    moment(b.start_date).toDate().getTime()
                )
                .map((activity) => (
                  <ActivityCard
                    key={activity.id}
                    activity={activity}
                    handleActivityClick={handleActivityClick}
                    routeMatch={routeMatch}
                  />
                ))}
            </div>
          ))}
      </div>
    );
  };

  const renderCalendarMode = () => {
    return (
      <ActivityCalendar
        activities={filtered}
        requests={requests}
        onActivityClick={(activity) => {
          handleActivityClick(activity);
        }}
        selectedActivityId={routeMatch?.params.activity}
        onCellClick={(date) => {
          setFromToDate({
            from: moment.utc(date.toString()).startOf('day').toDate(),
            to: moment
              .utc(date.toString())
              .add(1, 'day')
              .startOf('day')
              .toDate(),
          });
          setViewMode('list');
        }}
      />
    );
  };

  const renderTableMode = () => {
    return (
      <SchedulingTable
        activities={activitiesWithRequestData}
        onActivitySelect={(activity) => {
          handleActivityClick(activity);
        }}
        expanded={routeMatch?.params.activity === undefined}
        hiddenColumns={tableHiddenColumns}
      />
    );
  };

  if (viewMode === 'table') {
    return renderWithHeader(renderWithLoading(renderTableMode()));
  }

  if (viewMode === 'calendar') {
    return renderWithHeader(renderWithLoading(renderCalendarMode()));
  }

  return renderWithHeader(renderWithLoading(renderListMode()));
};
