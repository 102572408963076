import classNames from 'classnames';
import Button from './Button';
import type { ButtonProps } from './Button';

const PrimaryButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      textHoverClassName={{
        'group-hover:text-background dark:group-hover:text-background':
          !props.disabled,
      }}
      iconHoverClassName={{
        'group-hover:stroke-background dark:group-hover:stroke-background':
          !props.disabled,
      }}
      className={classNames('bg-accent border border-accent', {
        'hover:bg-accent-600 dark:hover:bg-accent-600': !props.disabled,
        [props.className!]: props.className !== undefined,
      })}
    />
  );
};

export default PrimaryButton;
