import { DATE_FORMAT } from 'constants/datetime';
import type { StacItem } from 'datacosmos/types/stac-types';
import { groupBy, reverse, sortBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';

export enum GroupableProperty {
  DateTime = 'datetime',
  GSD = 'gsd',
  Platform = 'platform',
  PlatformType = 'datacosmos:platform_type',
}

const useFeatureGroup = (items: StacItem[]) => {
  const [propertyToGroupBy, setPropertyToGroupBy] = useState<GroupableProperty>(
    GroupableProperty.DateTime
  );

  const getResultsGroupedByProperty = useCallback(() => {
    if ((propertyToGroupBy as string) === 'datetime') {
      return groupBy(
        reverse(sortBy(items, (o) => o.properties[propertyToGroupBy])),
        (result) =>
          moment.utc(result.properties[propertyToGroupBy]).format(DATE_FORMAT)
      );
    }
    return groupBy(
      sortBy(items, (o) => o.properties[propertyToGroupBy]),
      (result) => result.properties[propertyToGroupBy]
    );
  }, [items, propertyToGroupBy]);

  useEffect(() => {
    getResultsGroupedByProperty();
  }, [getResultsGroupedByProperty]);

  return {
    getResultsGroupedByProperty,
    setPropertyToGroupBy,
    propertyToGroupBy,
  };
};

export default useFeatureGroup;
