import type { IStacItem, StacItem } from 'datacosmos/types/stac-types';
import type { ILayerOptions } from './layer';
import { Layer, LayerSourceType } from './layer';

export interface IBrightness {
  r: number;
  g: number;
  b: number;
}

export type Contrast = IBrightness;
export type ContrastBias = Contrast;

export type Offset = {
  x: number;
  y: number;
};

export interface ISTACOptions {
  color_formula: string;
  brightness: IBrightness | number | null;
  isSettingBrightness: boolean;

  saturation: number;
  isSettingSaturation: boolean;

  contrast: Contrast | number | null;
  isSettingContrast: boolean;
  contrastBias: ContrastBias | number | null;

  offset?: Offset;
  isLowResolutionZoomAlertShown?: boolean;
}

/**
 * A single asset from a STAC catalogue being displayed on the map
 */
export class SingleBandSTACLayer extends Layer {
  declare options: ILayerOptions & ISTACOptions;
  declare layerClass: string;
  item: StacItem; // TODO: Is it enough to have the item? should we check item.id + collection?,
  assetKey: string;

  /**
   * @param renderer function that returns a React element with the UI that sets the specific options of this layer
   * @param item the STAC item containing the asset to be displayed
   * @param assetKey the key pointing to the asset in StacItem.assets
   */
  constructor(item: StacItem, assetKey: string) {
    super(LayerSourceType.ASSET);
    if (item.assets[assetKey] === undefined) {
      throw new Error(
        'Error creating layer, the asset key provided does not exist on this item'
      );
    }

    this.item = item;
    this.assetKey = assetKey;
    this.layerClass = 'SingleBandSTACLayer';
    this.options = {
      blendMode: 'normal',
      brightness: 1,
      color_formula: '',
      isSettingBrightness: false,
      opacity: 1,
      visible: true,
      saturation: 1,
      isSettingSaturation: false,
      contrast: 1,
      isSettingContrast: false,
      isLowResolutionZoomAlertShown: false,
      contrastBias: {
        b: 0.5,
        g: 0.5,
        r: 0.5,
      },
      offset: undefined,
    };
  }

  getAsset() {
    return this.item.assets[this.assetKey];
  }

  getName() {
    return (this.item.properties.title ?? this.item.id) || this.id;
  }

  getDescription() {
    return 'Asset: ' + this.assetKey;
  }

  getKey() {
    return this.assetKey;
  }

  containsSTACItem(item: IStacItem) {
    return item.id === this.item.id && item.collection === this.item.collection;
  }

  containsSTACAsset(item: IStacItem, assetKey: string) {
    return Boolean(
      assetKey && this.containsSTACItem(item) && this.assetKey === assetKey
    );
  }

  containsBandAlgebra() {
    return false;
  }

  cloneWithOptions(options: Partial<ILayerOptions & ISTACOptions>) {
    const newObject = this.clone();
    newObject.options = { ...newObject.options, ...options };
    return newObject;
  }

  private generateBrightnessFormula() {
    if (this.options.brightness instanceof Object) {
      return `Gamma R ${this.options.brightness.r} Gamma G ${this.options.brightness.g} Gamma B ${this.options.brightness.b}`;
    } else if (this.options.brightness && this.isBrightnessApplied()) {
      return `Gamma R ${this.options.brightness}`;
    }
    return '';
  }

  private generateContrastFormula() {
    let formula = '';
    if (this.options.contrast instanceof Object) {
      formula = `Sigmoidal R ${this.options.contrast.r} {rBias} Sigmoidal G ${this.options.contrast.g} {gBias} Sigmoidal B ${this.options.contrast.b} {bBias}`;
    } else if (this.options.contrast && this.isContrastApplied()) {
      formula = `Sigmoidal R ${this.options.contrast} {rBias}`;
    }

    if (this.options.contrastBias instanceof Object) {
      formula = formula.replace('{rBias}', `${this.options.contrastBias.r}`);
      formula = formula.replace('{gBias}', `${this.options.contrastBias.g}`);
      formula = formula.replace('{bBias}', `${this.options.contrastBias.b}`);
    } else if (this.options.contrastBias !== null) {
      formula = formula.replace('{rBias}', `${this.options.contrastBias}`);
      formula = formula.replace('{gBias}', `${this.options.contrastBias}`);
      formula = formula.replace('{bBias}', `${this.options.contrastBias}`);
    }

    return formula;
  }

  private generateSaturationFormula() {
    if (this.options.saturation && this.isSaturationApplied()) {
      return `Saturation ${this.options.saturation}`;
    }
    return '';
  }

  generateColorFormula() {
    const operations = [
      this.generateBrightnessFormula(),
      this.generateSaturationFormula(),
      this.generateContrastFormula(),
    ];

    const activeOperations = operations.filter((o) => o !== '');

    const color_formula =
      activeOperations.length > 1
        ? operations.join(' ').replace(/^\s+/, '')
        : operations.join('');

    //removes all extra spaces within words (this prevents titiler crash)
    this.options.color_formula = color_formula.replace(/\s+/g, ' ');
  }

  isSettingBrightness() {
    return this.options.isSettingBrightness;
  }

  setSettingBrightnessOn() {
    this.options.isSettingBrightness = true;
  }

  setSettingBrightnessOff() {
    this.options.isSettingBrightness = false;
  }

  isBrightnessApplied() {
    return (
      this.options.brightness !== undefined &&
      ((this.options.brightness instanceof Object &&
        (this.options.brightness.r !== 1 ||
          this.options.brightness.g !== 1 ||
          this.options.brightness.b !== 1)) ||
        (!isNaN(this.options.brightness as number) &&
          this.options.brightness !== 1))
    );
  }

  isSettingSaturation() {
    return this.options.isSettingSaturation;
  }

  setSettingSaturationOn() {
    this.options.isSettingSaturation = true;
  }

  setSettingSaturationOff() {
    this.options.isSettingSaturation = false;
  }

  isSaturationApplied() {
    return (
      this.options.saturation !== undefined &&
      !isNaN(this.options.saturation) &&
      this.options.saturation !== 1
    );
  }

  isSettingContrast() {
    return this.options.isSettingContrast;
  }

  setSettingContrastOn() {
    this.options.isSettingContrast = true;
  }

  setSettingContrastOff() {
    this.options.isSettingContrast = false;
  }

  isContrastApplied() {
    return (
      this.options.contrast !== undefined &&
      ((this.options.contrast instanceof Object &&
        (this.options.contrast.r !== 0.5 ||
          this.options.contrast.g !== 0.5 ||
          this.options.contrast.b !== 0.5)) ||
        (!isNaN(this.options.contrast as number) &&
          this.options.contrast !== 1))
    );
  }
  /**
   * Looks for the sun/solar zenith angle in three possible properties:
   * view:sun_elevation, sun_elevation, SolarZenith (note zenith = 90 - elevation)
   * @returns the zenith angle if one of the properties is defined, else undefined
   */
  sza(): Number | undefined {
    if (!this.item.properties) {
      return undefined;
    }
    if (this.item.properties['view:sun_elevation']) {
      return 90.0 - Number(this.item.properties['view:sun_elevation']);
    }
    // TODO: remove after data has been cleaned
    if (this.item.properties.sun_elevation) {
      return 90.0 - Number(this.item.properties.sun_elevation);
    }
    // TODO: remove after data has been cleaned
    if (this.item.properties.SolarZenith) {
      return Number(this.item.properties.SolarZenith);
    }
    return undefined;
  }

  href(): string | undefined {
    return this.item.assets[this.assetKey].href;
  }
}
