import { ASTRUMS } from '../API/constant';
import type { IAstrum } from './interfaces';

export const ASTRUM_UPDATE = 'ASTRUM_UPDATE';
export const ASTRUM_RESET = 'ASTRUM_RESET';

export const defaultAustrum: IAstrum = {
  planet: ASTRUMS.EARTH,
  name: 'default',
  mapOption: {},
};
