import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Spinner } from '@blueprintjs/core';
import { useTasking } from 'datacosmos/stores/TaskingProvider';
import { TaskingDate } from 'datacosmos/components/Tasking/TaskingDate/TaskingDate';
import { Instruments } from 'datacosmos/components/Tasking/Instruments';
import { AreaOfInterest } from 'datacosmos/components/Tasking/AreaOfInterest/AreaOfInterest';
import {
  SearchSteps,
  Steps,
  MAX_ALLOWED_AOI_SIZE_M2,
} from 'datacosmos/components/Tasking/helpers';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { TaskingType } from 'datacosmos/components/Tasking/TaskingType';
import { Parameters } from 'datacosmos/components/Tasking/ObjectivesAndConstraints/Parameters';
import bbox from '@turf/bbox';
import { OpportunityCalendar } from 'datacosmos/components/Tasking/TaskingDate/OpportunityCalendar';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import {
  type ActivitySchema,
  type TaskingRequest,
  type TaskingRequestInstrument,
  type TaskingSatellite,
} from '_api/tasking/types';
import moment from 'moment';
import { Button, Icon, ListBoxItem, Select, Tooltip } from 'opencosmos-ui';
import type { TaskingRequestPriority } from '_api/tasking/service';
import AdvancedSwathControl from '_organisms/SwathControl/AdvancedSwathControl';
import getFieldsBySchema from 'pages/ops/RTI/Operate/utils/rtiCommands/getFieldsBySchema';
import parseRefDefinitions from 'pages/ops/RTI/Operate/utils/rtiCommands/parseRefDefinitions';
import { type ActivityParameters } from '_api/activities/service';
import type { CommandProperties } from '_api/gateway/types';

type SearchOpportunityProps = {
  setSwitchStep: (step: Steps) => void;
  searchSteps: SearchSteps[];
  setSearchSteps: React.Dispatch<React.SetStateAction<SearchSteps[]>>;
  switchStep: Steps;
  satellitesForTasking: TaskingSatellite[];
  setIsRequestModalOpen: (value: boolean) => void;
  taskingRequestDetails?: TaskingRequest;
  prefillInstruments?: TaskingRequestInstrument[];
};

export const SearchOpportunity = ({
  setSearchSteps,
  searchSteps,
  switchStep,
  setSwitchStep,
  satellitesForTasking,
  setIsRequestModalOpen,
  taskingRequestDetails,
  prefillInstruments,
}: SearchOpportunityProps) => {
  const {
    searchOpportunities,
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    addSatellite,
    setAddSatellite,
    isFetching,
    SZAConstraints,
    setSZAConstraints,
    OZAConstraints,
    setOZAConstraints,
    isSZA,
    setIsSZA,
    setSZAError,
    hasSZAError,
    isOZAObjective,
    isSZAObjective,
    setIsOZAObjective,
    setIsSZAObjective,
    rollAngleConstraint,
    isRollAngle,
    setRollAngleConstraint,
    setIsRollAngle,
    setRollAngleError,
    hasRollAngleError,
    regions,
    requestPriority,
    setRequestPriority,
    isOZA,
    setIsOZA,
    getAdvancedOptionsSchema,
    setAutomatedRequestAdvancedOptions,
    automatedRequestAdvancedOptions,
  } = useTasking();

  const [
    maxDateAllowedForSelectionInCalendar,
    setMaxDateAllowedForSelectionInCalendar,
  ] = useState<Date>();

  const [
    minDateAllowedForSelectionInCalendar,
    setMinDateAllowedForSelectionInCalendar,
  ] = useState<Date>();

  const { layers, replaceLayer, addLayer, removeLayer } = useMapLayers();

  const { drawPolygon, editPolygon, disableEditing, setViewToFitBbox } =
    useMap();

  const { translate } = useLocalisation();

  const makeRegionsEditable = useCallback(() => {
    regions.forEach((region) => {
      if (region.modifiers?.uneditable) {
        replaceLayer(region.cloneWithModifiers({ uneditable: false }));
      }
    });
  }, [regions, replaceLayer]);

  const [isTaskingError, setIsTaskingError] = useState<boolean>(false);
  const [advancedOptionsSchema, setAdvancedOptionsSchema] =
    useState<ActivitySchema>();

  const isRegionValid = useMemo(
    () => !regions.some((region) => region.areaInM2 > MAX_ALLOWED_AOI_SIZE_M2),
    [regions]
  );

  const regionsForTasking = useMemo(
    () => regions.filter((reg) => reg.options.visible),
    [regions]
  );

  useEffect(() => {
    if (addSatellite && addSatellite.length > 0) {
      setIsTaskingError(false);
    }
  }, [addSatellite]);

  useEffect(() => {
    makeRegionsEditable();
  }, [makeRegionsEditable]);

  const includesDateAndInstruments = searchSteps.includes(
    SearchSteps.SelectTaskingDateAndInstruments
  );

  useEffect(() => {
    if (regions.length > 0 && isRegionValid) {
      if (!includesDateAndInstruments) {
        setSearchSteps((prev) => [...prev, SearchSteps.SelectTaskingType]);
      }
    } else {
      setSearchSteps([SearchSteps.AddAOI]);
      setSwitchStep(Steps.DataAcquisition);
    }
  }, [
    includesDateAndInstruments,
    isRegionValid,
    regions.length,
    setSearchSteps,
    setSwitchStep,
  ]);

  const centerRegion = useCallback(
    (region: PolygonLayer) => {
      setViewToFitBbox(bbox(region.data));
    },
    [setViewToFitBbox]
  );

  const shouldFetchAdvancedOptions =
    switchStep === Steps.AutomatedTasking &&
    addSatellite &&
    addSatellite?.length === 1;

  useEffect(() => {
    if (!shouldFetchAdvancedOptions) {
      return;
    }
    const fetchAdvancedOptions = async () => {
      const schema = await getAdvancedOptionsSchema(
        addSatellite[0]?.satellite?.mission_id
      );
      setAdvancedOptionsSchema(schema);
    };

    void fetchAdvancedOptions();
  }, [shouldFetchAdvancedOptions, addSatellite, getAdvancedOptionsSchema]);

  useEffect(() => {
    if (taskingRequestDetails) {
      const startDate = taskingRequestDetails.constraints.find(
        (aqDate) => aqDate.type === 'ACQUISITION_DATE'
      )?.min;
      const endDate = taskingRequestDetails.constraints.find(
        (aqDate) => aqDate.type === 'ACQUISITION_DATE'
      )?.max;
      setDateFrom(moment.unix(startDate!).toDate());
      setDateTo(moment.unix(endDate!).toDate());
      setMinDateAllowedForSelectionInCalendar(moment.unix(startDate!).toDate());
      setMaxDateAllowedForSelectionInCalendar(moment.unix(endDate!).toDate());
    }
  }, [taskingRequestDetails, setDateFrom, setDateTo]);
  return (
    <>
      <div className="search-opportunity">
        <div className="search-form">
          <div>
            {searchSteps.includes(SearchSteps.AddAOI) && (
              <AreaOfInterest
                disabled={
                  switchStep === Steps.ListOfOpportunities || isFetching
                }
                regions={regionsForTasking}
                replaceLayer={replaceLayer}
                drawPolygon={drawPolygon}
                addLayer={addLayer}
                removeLayer={removeLayer}
                editPolygon={editPolygon}
                disableEditing={disableEditing}
                isFetching={isFetching}
                centerRegion={centerRegion}
                isRegionValid={isRegionValid}
              />
            )}

            {searchSteps.includes(SearchSteps.SelectTaskingType) && (
              <TaskingType
                disabled={
                  switchStep === Steps.ListOfOpportunities || isFetching
                }
                setSearchSteps={setSearchSteps}
                setSwitchStep={setSwitchStep}
                switchStep={switchStep}
              />
            )}

            {searchSteps.includes(
              SearchSteps.SelectTaskingDateAndInstruments
            ) && (
              <div className="flex flex-col gap-4 w-full">
                <Parameters
                  disabled={
                    switchStep === Steps.ListOfOpportunities || isFetching
                  }
                  switchStep={switchStep}
                  constraintsProps={{
                    isRollAngle,
                    hasRollAngleError,
                    rollAngleConstraint,
                    setIsRollAngle,
                    setIsRollAngleError: setRollAngleError,
                    setRollAngleConstraint,
                    isSZA,
                    setIsSZA,
                    setSZAConstraints,
                    setSZAError,
                    SZAConstraints,
                    SZAError: hasSZAError,
                    isOZA,
                    setIsOZA,
                    setOZAConstraints,
                    OZAConstraints,
                  }}
                  objectivesProps={{
                    isOZAObjective,
                    isSZAObjective,
                    OZAConstraints,
                    setIsOZAObjective,
                    setIsSZAObjective,
                    setOZAConstraints,
                    setSZAConstraints,
                    SZAConstraints,
                  }}
                />

                {switchStep === Steps.ListOfOpportunities ? (
                  <OpportunityCalendar
                    setDateTo={setDateTo}
                    setDateFrom={setDateFrom}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    layers={layers}
                  />
                ) : (
                  <TaskingDate
                    setDateTo={setDateTo}
                    setDateFrom={setDateFrom}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    minDateAllowedForSelection={
                      minDateAllowedForSelectionInCalendar
                    }
                    maxDateAllowedForSelection={
                      maxDateAllowedForSelectionInCalendar
                    }
                  />
                )}

                {switchStep === Steps.AutomatedTasking && (
                  <div className="flex justify-between items-center w-full gap-4 px-2 py-1 color-item">
                    <div className="font-bold">Priority</div>
                    <Select
                      defaultSelectedKey={50}
                      selectedKey={requestPriority}
                      onSelectionChange={(p) => {
                        setRequestPriority(p as TaskingRequestPriority);
                      }}
                      placeholder="Select priority"
                      className="font-sm"
                      fill
                    >
                      <ListBoxItem id={50} key={50}>
                        Standard
                      </ListBoxItem>
                      <ListBoxItem id={40} key={40}>
                        Priority
                      </ListBoxItem>
                    </Select>
                  </div>
                )}
                {switchStep !== Steps.ListOfOpportunities && (
                  <Instruments
                    satellites={satellitesForTasking}
                    setAddSatellite={setAddSatellite}
                    addSatellite={addSatellite}
                    isTaskingError={isTaskingError}
                    setIsTaskingError={setIsTaskingError}
                    prefillInstruments={prefillInstruments}
                  />
                )}

                {switchStep === Steps.AutomatedTasking && (
                  <div className="flex items-center w-full gap-4 px-2 py-1 color-item">
                    <div className="font-bold">Advanced</div>

                    <AdvancedSwathControl
                      swathControlData={undefined}
                      handleOptionsChange={(item) => {
                        setAutomatedRequestAdvancedOptions(
                          item as ActivityParameters
                        );
                      }}
                      schema={
                        advancedOptionsSchema
                          ? getFieldsBySchema(
                              parseRefDefinitions(
                                advancedOptionsSchema.IMAGE_ACQUISITION
                              ) as CommandProperties
                            )
                          : []
                      }
                      defaultValues={automatedRequestAdvancedOptions}
                      disabled={!shouldFetchAdvancedOptions}
                      buttonText={'Add advanced options'}
                      isRequestTypeAutomated={true}
                    />

                    <Tooltip
                      content={
                        shouldFetchAdvancedOptions
                          ? translate(
                              'datacosmos.tasking.new.automated.advancedOptionsActive'
                            )
                          : translate(
                              'datacosmos.tasking.new.automated.advancedOptionsDisabled'
                            )
                      }
                    >
                      <Icon icon="Info" />
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tasking-footer">
        {searchSteps.includes(SearchSteps.SelectTaskingDateAndInstruments) &&
          switchStep !== Steps.ListOfOpportunities && (
            <div className="p-4">
              <Button
                className="flex bg-item w-full h-12 items-center justify-center dark:bg-item-dark dark:text-item-dark-contrast mb-4"
                onPress={async () => {
                  if (switchStep === Steps.AutomatedTasking) {
                    setIsRequestModalOpen(true);
                  } else {
                    regions.forEach((r) => {
                      disableEditing(r.getLeafletLayerMetadata());
                      replaceLayer(r.cloneWithModifiers({ uneditable: true }));
                    });
                    const hasWorked = await searchOpportunities(
                      taskingRequestDetails?.project_id
                    );
                    if (hasWorked) {
                      setSwitchStep(Steps.ListOfOpportunities);
                      setIsTaskingError(false);
                    } else {
                      makeRegionsEditable();
                      setIsTaskingError(true);
                    }
                  }
                }}
                isDisabled={!addSatellite?.length}
                intent="primary"
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  isFetching ? (
                    <Spinner className="float-spinner" size={20} />
                  ) : switchStep === Steps.AutomatedTasking ? (
                    translate('datacosmos.tasking.new.request')
                  ) : (
                    translate('datacosmos.tasking.new.search')
                  )
                }
              </Button>
            </div>
          )}
      </div>
    </>
  );
};
