import type { ToastProps } from '@blueprintjs/core';
import { Toaster } from '@blueprintjs/core';
import CompositeToast from '_molecules/CompositeToast/CompositeToast';

const realToaster = Toaster.create({
  position: 'top',
  autoFocus: false,
  className: '!z-[800]',
});

const toasterState = {
  ignoredMessages: [] as string[],
};

type ToasterProps = {
  message: string;
  icon?: ToastProps['icon'];
  intent?: ToastProps['intent'];
  timeout?: ToastProps['timeout'];
};

const show = (props: ToasterProps) => {
  if (toasterState.ignoredMessages.includes(props.message)) {
    return;
  }

  toasterState.ignoredMessages.push(props.message);

  setTimeout(() => {
    toasterState.ignoredMessages = toasterState.ignoredMessages.filter(
      (message) => message !== props.message
    );
  }, 500);

  realToaster.show(props);
};

const showCompositeToast = ({
  title,
  description,
  showCopyButton,
  ...toastArgs
}: {
  title: string;
  description: string;
  showCopyButton?: boolean;
} & Omit<ToastProps, 'message'>) => {
  realToaster.show({
    ...toastArgs,
    timeout: 0,
    className: '!z-[800]',
    message: (
      <CompositeToast
        title={title}
        description={description}
        showCopyButton={showCopyButton}
      />
    ),
  });
};

export const toaster = {
  ...realToaster,
  show,
  showCompositeToast,
};
