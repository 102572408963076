import { Layer, LayerSourceType } from './layer';

export class VideoLayer extends Layer {
  name: string;
  description: string;
  url: string[] | string;
  bounds: [number, number][];

  constructor(
    name: string,
    description: string,
    url: string[] | string,
    bounds: [number, number][]
  ) {
    super(LayerSourceType.OVERLAY);
    this.name = name;
    this.description = description;
    this.url = url;
    this.bounds = bounds;
  }

  getName(): string {
    return this.name;
  }

  getDescription(): string {
    return this.description;
  }

  getUrl(): string | string[] {
    return this.url;
  }

  getBounds(): [number, number][] {
    return this.bounds.map((pos) => [pos[1], pos[0]]);
  }

  containsSTACItem() {
    return false;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra() {
    return false;
  }

  sza(): Number | undefined {
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }
}
