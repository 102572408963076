import ReactDOM from 'react-dom';
import React from 'react';
import type { IAlertOption } from '../../../components/common/AlertRemove';
import AlertRemove from '../../../components/common/AlertRemove';
import AlertInfo from '../../../components/common/AlertInfo';

export function showAlert(
  onConfirm: Function,
  message: string,
  option?: IAlertOption
): void {
  const contextMenuElement = document.createElement('div');
  document.body.appendChild(contextMenuElement);
  ReactDOM.render(
    <AlertRemove
      onConfirm={onConfirm}
      message={message}
      removeDiv={(): void => {
        contextMenuElement.remove();
        document.getElementsByClassName('bp4-portal')[0].remove();
      }}
      option={option}
    />,
    contextMenuElement
  );
}

export function showInfo(message: string): void {
  const contextMenuElement = document.createElement('div');
  document.body.appendChild(contextMenuElement);
  ReactDOM.render(
    <AlertInfo
      message={message}
      removeDiv={(): void => {
        contextMenuElement.remove();
        document.getElementsByClassName('bp4-portal')[0].remove();
      }}
    />,
    contextMenuElement
  );
}
