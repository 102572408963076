import type { ProviderView } from 'datacosmos/stores/ViewsProvider';

//Add new ones as they get created
export enum ViewVersion {
  v0,
  v1,
  v2,
  v3,
}

// Increment this as new view versions get created
export const CURRENT_VIEWS_VERSION = ViewVersion.v0;

interface IViewMigration {
  versionFrom: ViewVersion;
  versionTo: ViewVersion;
  up(
    view: ProviderView & { [otherKey: string]: unknown }
  ): ProviderView & { [otherKey: string]: unknown };
  down(
    view: ProviderView & { [otherKey: string]: unknown }
  ): ProviderView & { [otherKey: string]: unknown };
}

//Add new migrations here as needed
export const VIEW_MIGRATIONS: IViewMigration[] = [];
