import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useQuery } from '_api/useQuery';
import { getTaskingRequestsByProjectId } from '_api/tasking/service';
import type { Activity } from '_api/activities/service';
import type {
  GetTaskingRequestsByProjectIdParams,
  Swath,
  TaskingRequest,
  TaskingRequests,
  TaskingRequestsByProjectIdQueryParams,
} from '_api/tasking/service';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { GeoJSONLayerFactory } from 'datacosmos/entities/geojsonLayer';
import bbox from '@turf/bbox';
import {
  generateEditedSwathForActivity,
  generateSwathsForActivity,
} from './displaySwaths';
import union from '@turf/union';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import area from '@turf/area';
import { useTasking } from 'datacosmos/stores/TaskingProvider';

/**
 * useTaskingRequestList expands on useTaskingRequestMap to automatically query
 * the list of tasking requests per project.
 *
 * @see {@link useTaskingRequestMap} for information about the map layers
 *
 * @param projectId is the project ID to get the tasking requests from
 * @returns the list of tasking request and function to show them on the map
 */
export const useTaskingRequestList = (projectId?: string) => {
  const [taskingRequests, setTaskingRequests] = useState<TaskingRequest[]>([]);
  const [nextPageCursor, setNextPageCursor] = useState<string | undefined>(
    undefined
  );
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { data: initialTaskingRequests, refetch } = useQuery(
    getTaskingRequestsByProjectId,
    {
      initialData: undefined,
      params: projectId ? { projectId } : undefined,
      skip: !projectId,
    }
  );

  const map = useTaskingRequestMap(initialTaskingRequests);

  const setTaskingRequestsPaginationParams = useCallback(
    (requestsData: TaskingRequests, rewriteExistingArray?: boolean) => {
      if (rewriteExistingArray) {
        setTaskingRequests(requestsData.data);
        setNextPageCursor(requestsData.meta.cursor);
        setHasMore(requestsData.meta.remaining > 0);
        setIsLoading(false);
        return;
      }
      setTaskingRequests((prevRequests) => [
        ...prevRequests,
        ...requestsData.data,
      ]);
      setNextPageCursor(requestsData.meta.cursor);
      setHasMore(requestsData.meta.remaining > 0);
      setIsLoading(false);
    },
    []
  );

  const fetchTaskingRequests = useCallback(
    async (
      cursor?: string,
      filters?: TaskingRequestsByProjectIdQueryParams
    ) => {
      if (projectId) {
        const params: GetTaskingRequestsByProjectIdParams = { projectId };
        if (cursor) {
          params.cursor = cursor;
        }
        if (filters) {
          params.filters = filters;
        }

        setIsLoading(true);
        const { data: nextPageTaskingRequests } =
          await getTaskingRequestsByProjectId({
            params: projectId ? params : undefined,
          });

        if (
          nextPageTaskingRequests?.data &&
          nextPageTaskingRequests?.data?.length > 0
        ) {
          setTaskingRequestsPaginationParams(
            nextPageTaskingRequests,
            cursor ? false : true
          );
        } else {
          setIsLoading(false);
        }
      }
    },
    [projectId, setTaskingRequestsPaginationParams]
  );

  useEffect(() => {
    if (initialTaskingRequests) {
      setTaskingRequestsPaginationParams(initialTaskingRequests, true);
    }
  }, [initialTaskingRequests, setTaskingRequestsPaginationParams]);

  return {
    loading: isLoading,
    refetch,
    taskingRequests,
    fetchTaskingRequests,
    nextPageCursor,
    hasMore,
    ...map,
  };
};

/**
 * useTaskingRequestMap manages showing swaths and AoIs from tasking requests
 * on the map. It also supports editing swaths via the updateSwathLayer.
 *
 * Users can use the arrays aoiLayersDisplayed and swathLayersDisplayed to check
 * which items are currently being displayed on the map. The arrays are memoised
 * and will only change if there is a change in their contents.
 *
 * @param taskingRequests are all the tasking requests in scope, generally from
 * a project.
 * @returns functions to interact with the layers
 */
const useTaskingRequestMap = (taskingRequests: TaskingRequests | undefined) => {
  const {
    addLayer,
    removeLayer,
    replaceLayer,
    replaceSpecificLayerWithAnother,
    layers,
  } = useMapLayers();

  const { regions } = useTasking();
  const { setViewToFitBbox } = useMap();

  const aoiLayersDisplayed = useMemo(
    () =>
      layers.filter(
        (layer) => layer.sourceType === LayerSourceType.STS_REQUEST_AOI
      ) as GeoJSONLayer<TaskingRequest>[],
    [layers]
  );

  const toggleDisplayAoIOnMap = (request: TaskingRequest) => {
    const shownLayer = aoiLayersDisplayed.find(
      (layer) => layer.metadata.id === request.id
    );

    if (typeof shownLayer !== 'undefined') {
      removeLayer(shownLayer.id);
      return;
    }

    const aoiLayer = GeoJSONLayerFactory<TaskingRequest>(
      LayerSourceType.STS_REQUEST_AOI,
      `${request.region_name}`,
      request.region,
      request,
      null
    );

    addLayer(aoiLayer);
    setViewToFitBbox(bbox(aoiLayer.data));
  };

  const swathLayersDisplayed = useMemo(
    () =>
      layers.filter(
        (layer) => layer.sourceType === LayerSourceType.STS_ACTIVITY_SWATH
      ) as GeoJSONLayer<Activity>[],
    [layers]
  );

  const centreMapToLayers = (activityLayers: GeoJSONLayer<Activity>[]) => {
    setViewToFitBbox(
      bbox(
        activityLayers
          .map((s) => s.data)
          .reduce<ReturnType<typeof union>>((acc, geo) => {
            if (!acc) acc = geo as unknown as typeof acc;
            return union(
              acc as unknown as GeoJSON.Polygon,
              geo as unknown as GeoJSON.Polygon
            );
          }, null)
      )
    );
  };

  const toggleDisplayActivitySwathOnMap = (activity: Activity) => {
    const displayedLayers = swathLayersDisplayed.filter(
      (s) => s.metadata.id === activity.id
    );

    if (displayedLayers.length > 0) {
      displayedLayers.forEach((s) => {
        removeLayer(s.id);
      });

      return;
    }

    if (!activity.parameters.physical) {
      return;
    }

    generateSwathsForActivity(activity).forEach((layer) => addLayer(layer));
    if (activity.parameters.physical.latest) {
      setViewToFitBbox(bbox(activity.parameters.physical.latest.geojson));
    }
  };

  const toggleDisplayAllRequestSwathsOnMap = (request: TaskingRequest) => {
    const swathLayersDisplayedRequest = swathLayersDisplayed.filter((l) =>
      request.activities.some((a) => a.id === l.metadata.id)
    );

    if (swathLayersDisplayedRequest.length > 0) {
      swathLayersDisplayedRequest.map((s) => removeLayer(s.id));
      return;
    }

    const swathLayers = request.activities
      .map(generateSwathsForActivity)
      .reduce((prev, current) => {
        return [...prev, ...current];
      });

    addLayer(...swathLayers);
    centreMapToLayers(swathLayers);
  };

  const toggleDisplayAllSwathsOnMap = () => {
    if (swathLayersDisplayed.length > 0) {
      swathLayersDisplayed.map((s) => removeLayer(s.id));
      return;
    }
    if (taskingRequests) {
      const swathLayers = taskingRequests.data
        .reduce<Activity[]>((activities, request) => {
          return [...activities, ...request.activities];
        }, [])
        .map(generateSwathsForActivity)
        .reduce((prev, current) => {
          return [...prev, ...current];
        });

      addLayer(...swathLayers);
      centreMapToLayers(swathLayers);
    }
  };

  /**
   * update swath is used to change the geometry of the swath being displayed,
   * ideal for editing the roll angle of a particular activity.
   */
  const updateSwathLayer = (activity: Activity, newSwath: Swath) => {
    const found = swathLayersDisplayed.find((l) =>
      l.getName()?.includes('target')
        ? l.getName()?.split('-')[0]
        : l.getName() === activity.id
    );
    const replacement = generateEditedSwathForActivity(
      activity,
      newSwath.footprint.geojson
    );

    if (found) {
      replaceSpecificLayerWithAnother(replacement, found);
    }
  };

  /**
   * remove swath edits will remove the current activity if it exists and it will add
   * it again. It removes any edits that have been added.
   */
  const removeSwathEdits = (activity: Activity) => {
    const displayedLayers = swathLayersDisplayed.filter(
      (s) => s.metadata.id === activity.id
    );

    if (displayedLayers.length > 0) {
      displayedLayers.forEach((s) => {
        removeLayer(s.id);
      });
    }

    if (!activity.parameters.physical?.latest) {
      return;
    }

    generateSwathsForActivity(activity).forEach((layer) => addLayer(layer));
    setViewToFitBbox(bbox(activity.parameters.physical.latest.geojson));
  };

  /**
   * show AoI as region displays the AoI as tasking request, so that it can be
   * used in new tasking request searches
   */
  const showAoIAsTaskingRegion = (request: TaskingRequest) => {
    regions.forEach((aoiLayer) => {
      if (aoiLayer.id !== request.id) {
        replaceLayer(
          aoiLayer.cloneWithOptions({
            visible: false,
          })
        );
      }
    });

    const aoiLayer = aoiLayersDisplayed.find(
      (layer) => layer.metadata.id === request.id
    );

    if (typeof aoiLayer !== 'undefined') {
      removeLayer(aoiLayer.id);
    }

    const aoi = PolygonLayerFactory(
      LayerSourceType.TASKING_REGIONS,
      `${request.region_name}`,
      request.region,
      area(request.region),
      null,
      { color: '#0000ff' }
    );

    addLayer(aoi);
    setViewToFitBbox(bbox(aoi.data));
  };

  return {
    aoiLayersDisplayed,
    swathLayersDisplayed,
    toggleDisplayAoIOnMap,
    toggleDisplayActivitySwathOnMap,
    toggleDisplayAllRequestSwathsOnMap,
    toggleDisplayAllSwathsOnMap,
    updateSwathLayer,
    removeSwathEdits,
    showAoIAsTaskingRegion,
  };
};

export type AoiLayersDisplayed = ReturnType<
  typeof useTaskingRequestMap
>['aoiLayersDisplayed'];
export type SwathLayersDisplayed = ReturnType<
  typeof useTaskingRequestMap
>['swathLayersDisplayed'];
export type ToggleDisplayAoIOnMap = ReturnType<
  typeof useTaskingRequestMap
>['toggleDisplayAoIOnMap'];
export type ToggleDisplayActivitySwathOnMap = ReturnType<
  typeof useTaskingRequestMap
>['toggleDisplayActivitySwathOnMap'];
export type ToggleDisplayAllRequestSwathsOnMap = ReturnType<
  typeof useTaskingRequestMap
>['toggleDisplayAllRequestSwathsOnMap'];
export type ToggleDisplayAllSwathsOnMap = ReturnType<
  typeof useTaskingRequestMap
>['toggleDisplayAllSwathsOnMap'];
export type UpdateSwathLayer = ReturnType<
  typeof useTaskingRequestMap
>['updateSwathLayer'];
export type RemoveSwathEdits = ReturnType<
  typeof useTaskingRequestMap
>['removeSwathEdits'];
export type ShowAoIAsTaskingRegion = ReturnType<
  typeof useTaskingRequestMap
>['showAoIAsTaskingRegion'];
