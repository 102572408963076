import { get, pipe, split, head } from 'lodash/fp';
import type { IMission } from '../../services/Missions';
import { BALL_STATES } from '../../constants/satelliteBalls/constants';
import type { ISatelliteBall } from '../../constants/satelliteBalls/actionTypes';
import type { IProps as IMissionDesignPageProps } from '../../pages/shared/MissionDesignPage';

export const getClearLocation = (
  location: Location | undefined,
  messPath: string
) => pipe(get('pathname'), split(messPath), head)(location) as string;

export const getCurrentMissionPath = (missions: IMission[], path: string) => {
  const currentMission = missions.find((m: IMission) => m.current);
  return currentMission
    ? `${path}/mission/${currentMission.mission}`
    : undefined;
};

export const modifySatelliteBallByState =
  (id: string, props: IMissionDesignPageProps, handleRedirect: Function) =>
  (satelliteBall: ISatelliteBall) => {
    if (satelliteBall.text !== id) {
      return { ...satelliteBall };
    }
    switch (satelliteBall.state) {
      case BALL_STATES.WARNING: {
        return { ...satelliteBall.resetFunction(props, satelliteBall) };
      }
      case BALL_STATES.COMPLETE:
      case BALL_STATES.CURRENT: {
        const result = satelliteBall.handleOnClick(props, satelliteBall);
        if (!result) {
          return satelliteBall;
        }
        switch (result.type) {
          case 'redirect': {
            handleRedirect(result.value);
            return { ...satelliteBall };
          }
          case 'external_redirect': {
            handleRedirect(result.value, true);
            return { ...satelliteBall };
          }
          case 'state': {
            return { ...satelliteBall, state: result.value };
          }
        }
      }
    }
    return { ...satelliteBall };
  };

export const handleOnBallClick = (
  props: IMissionDesignPageProps,
  ball: ISatelliteBall
):
  | { type: string; value: number | string | object | undefined }
  | undefined => {
  const currentMission = props.currentMissionId;

  return currentMission
    ? {
        type: 'redirect',
        value: ball.redirectPath ?? ball.redirectFn?.(currentMission),
      }
    : undefined;
};
