import classNames from 'classnames';
import React from 'react';
import type { IListItemProps } from '_atoms/ListItem/ListItem';
import type { ListOrientation } from '_molecules/UnorderedList/UnorderedList';
import UnorderedList from '_molecules/UnorderedList/UnorderedList';
import type { PressEvent } from '@react-types/shared';
import { Button, Icon } from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';

type IProps = {
  className?: string;
  title: {
    content: string;
    noTruncate?: boolean;
  };
  image: {
    src: string;
    position: 'left' | 'center';
    sizeInPx: number;
    previewImgUrl?: string;
    className?: string;
  };
  info: {
    orientation: ListOrientation;
    content:
      | React.ReactElement<IListItemProps>
      | React.ReactElement<IListItemProps>[]
      | null;
  };
  indicators?: {
    text?: string;
    icon?: IconName; //icon name needs to be a unique string as it is used as key for .map()
    shown: boolean;
  }[];
  actionElement?: JSX.Element | null;
  rightIcons?: {
    icon: IconName; //icon name needs to be a unique string as it is used as key for .map()
    onClick: (e: PressEvent) => void;
    shown: boolean;
    text?: string;
    isDisabled?: boolean;
  }[];
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const GridCard = (props: IProps) => {
  return (
    <div
      className={classNames(
        props.className,
        '!shadow-none grid gap-3 p-1 text-sm group hover:cursor-pointer text-item-contrast w-full dark:text-item-dark-contrast',
        {
          'before:content-none after:content-none':
            props.image.position === 'left',
        }
      )}
      style={{
        gridTemplateColumns: `0.5fr 3fr ${props.rightIcons ? '0.5fr' : ''}`,
      }}
    >
      <div
        className={`flex flex-col justify-between h-full w-full ${
          props.image.position === 'left' ? 'order-2' : 'order-1'
        }`}
      >
        <div className="w-full flex gap-4 items-center">
          <h3
            className={classNames({
              'font-normal m-0 text-inherit text-sm group-hover:text-hover-light':
                true,
              'truncate max-w-label overflow-ellipsis': !props.title.noTruncate,
            })}
          >
            {props.title.content}
          </h3>

          <div className="flex gap-2">
            {props.indicators?.map(
              (ind) =>
                ind.shown && (
                  <div className="flex gap-1" key={ind.icon}>
                    {ind.text && <span>{ind.text}</span>}
                    {ind.icon && (
                      <Icon
                        icon={ind.icon}
                        className="stroke-accent-900 fill-accent-900 dark:stroke-item-dark-hover dark:fill-item-dark-hover"
                      />
                    )}
                  </div>
                )
            )}
          </div>
        </div>

        {props.info.content && (
          <UnorderedList orientation={props.info.orientation}>
            {props.info.content}
          </UnorderedList>
        )}

        <div>{props.actionElement}</div>
      </div>

      <div
        className={classNames('bg-item relative dark:bg-item-dark', {
          'order-1': props.image.position === 'left',
          'order-2': props.image.position === 'center',
        })}
        onMouseLeave={(e) => {
          props.onMouseLeave?.(e);
        }}
        onMouseEnter={(e) => {
          props.onMouseEnter?.(e);
        }}
        onClick={(e) => {
          if (typeof props.onClick === 'undefined') return;
          props.onClick(e);
        }}
        style={{ width: props.image.sizeInPx, height: props.image.sizeInPx }}
      >
        <img
          alt="Result thumbnail"
          src={props.image.src}
          className={classNames(
            props.image.className,
            'block align-middle transform-cpu transition-all duration-300 ease-out hover:scale-105 object-cover w-full h-full'
          )}
        />
        {props.image.previewImgUrl && (
          <img
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
            onChange={(e) => (e.currentTarget.style.display = 'block')}
            src={props.image.previewImgUrl}
            className={`absolute top-0 left-0 block align-middle transform-cpu transition-all duration-300 ease-out hover:scale-105 object-cover w-full h-full bg-blend-overlay`}
          />
        )}
      </div>

      <div className="w-full flex flex-col items-end justify-between order-3">
        {props.rightIcons?.map(
          (icons) =>
            icons.shown && (
              <div className="flex gap-1 text-sm items-center" key={icons.icon}>
                {icons.text}
                <Button
                  icon={icons.icon}
                  onPress={icons.onClick}
                  isMinimal
                  isTransparent
                  size="base"
                  isDisabled={icons.isDisabled}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default GridCard;
