import type IGeometryObject from '../IGeometryObject';

export const PI_ADD = 'PI_ADD';
export const PI_UPDATE = 'PI_UPDATE';
export const PI_REMOVE = 'PI_REMOVE';
export const PI_UPLOAD = 'PI_UPLOAD';

export interface IPointOfInterest extends IGeometryObject {
  lat: number;
  lon: number;
  altitude: number;
  elevationAngle: number;
  orderIndex: number;
  type?: string;
  satelliteMode?: string;
}

export interface IPIState {
  list: IPointOfInterest[];
}

interface IPIAddAction {
  type: typeof PI_ADD;
  pointOfInterest: IPointOfInterest;
}

interface IPIUpdateAction {
  type: typeof PI_UPDATE;
  pointOfInterest: IPointOfInterest;
}

interface IPIRemoveAction {
  type: typeof PI_REMOVE;
  id: string;
}

interface IPIUploadAction {
  type: typeof PI_UPLOAD;
  pointsOfInterest: IPIState;
}

export type pointsOfInterestTypes =
  | IPIAddAction
  | IPIUpdateAction
  | IPIRemoveAction
  | IPIUploadAction;
