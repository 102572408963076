import Portal, { isRef } from '_atoms/Portal/Portal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

export interface IPopoverProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  fill?: true;
  /**
   * Specify container to which popover will be bound.
   * Useful for scrollable elements
   * Accepts target ref or target id
   */
  target?: string | React.MutableRefObject<HTMLElement>;
  isOpen?: boolean;

  /**
   * Set this if you need the popover to push content
   */
  relative?: true;
  /**
   * Whether or not to use portal. Default is true
   */
  usePortal?: boolean;
  disabled?: boolean;

  outsideClickClose?: true;

  /**
   * Whether or not to render a tooltip arrow
   */
  renderArrow?: true;

  /**
   * Action on which popup is show. Default is click
   */
  triggerOn?: 'hover' | 'click';
  /**
   * Whether or not the popup closes upon clicking inside it
   */
  closeOnContentClick?: true;
}

interface IProps extends IPopoverProps {
  children: React.ReactNode;
  content: JSX.Element | string;
  direction: Direction;
}

export enum Direction {
  Top,
  BottomRight,
  Left,
  Right,
  BottomLeft,
  Bottom,
}

interface ICoords {
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
}

const Popover = (props: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const targetElementRef = useRef<HTMLButtonElement>(null);

  const [coords, setCoords] = useState<ICoords>();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { direction, usePortal = true, triggerOn = 'click' } = props;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const calculatePopoverXY = useCallback(() => {
    if (!props.target) {
      const targetRect = targetElementRef.current?.getBoundingClientRect();
      return {
        x: targetRect?.x ?? 0,
        y: targetRect?.y ?? 0,
      };
    }

    const targetRect = targetElementRef.current?.getBoundingClientRect();

    const portalTargetRect = isRef(props.target)
      ? props.target.current.getBoundingClientRect()
      : document.getElementById(props.target)?.getBoundingClientRect();

    let x = 0;
    if (targetRect && portalTargetRect) {
      x = targetRect.x - portalTargetRect.x;
    }

    let y = 0;
    if (targetRect && portalTargetRect && targetElementRef.current) {
      y =
        targetRect.y -
        portalTargetRect.y +
        targetElementRef.current.clientHeight;
    }

    return {
      x,
      y,
    };
  }, [props.target]);

  //TODO: Bottom works, fix other directions

  useEffect(() => {
    const containerRefWidth = containerRef.current?.clientWidth ?? 0;
    const targetElementHeight = targetElementRef.current?.clientHeight ?? 0;
    const targetElementWidth = targetElementRef.current?.clientWidth ?? 0;
    const contentRefHeight = contentRef.current?.clientHeight ?? 0;
    const contentRefWidth = contentRef.current?.clientWidth ?? 0;

    direction === Direction.Top &&
      setCoords({
        top: calculatePopoverXY().y - contentRefHeight - targetElementHeight,
        left: calculatePopoverXY().x,
      });
    direction === Direction.Left &&
      setCoords({
        left: calculatePopoverXY().x - contentRefWidth,
        top: calculatePopoverXY().y - contentRefHeight / 2,
      });
    direction === Direction.Right &&
      setCoords({
        left: calculatePopoverXY().x + targetElementWidth,
        top: calculatePopoverXY().y - contentRefHeight / 2,
      });
    direction === Direction.BottomRight &&
      setCoords({
        top: usePortal
          ? calculatePopoverXY().y + (!props.target ? targetElementHeight : 0)
          : 0,
        left: usePortal ? calculatePopoverXY().x : 0,
      });
    direction === Direction.BottomLeft &&
      setCoords({
        top: calculatePopoverXY().y + (!props.target ? targetElementHeight : 0),
        left: calculatePopoverXY().x - contentRefWidth + targetElementWidth / 2,
      });
    direction === Direction.Bottom &&
      setCoords({
        top: usePortal
          ? calculatePopoverXY().y + (!props.target ? targetElementHeight : 0)
          : 0,
        left: usePortal
          ? calculatePopoverXY().x - contentRefWidth / 2 + containerRefWidth / 2
          : 0,
      });
  }, [calculatePopoverXY, direction, props.target, usePortal]);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!props.outsideClickClose) return;

      if (
        contentRef.current &&
        !contentRef.current.contains(e.target as HTMLElement) &&
        !targetElementRef.current?.contains(e.target as HTMLElement)
      ) {
        setIsOpen(false);
      }
    },
    [props.outsideClickClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [handleOutsideClick]);

  const renderPopoverContent = () => {
    return (
      <div
        data-testid="popover-content"
        ref={contentRef}
        style={{
          display: isOpen || props.isOpen ? 'flex' : 'none',
          ...coords,
          zIndex: 10000,
          minWidth: props.fill
            ? targetElementRef.current?.clientWidth
            : 'wrap-content',
        }}
        className={classNames(
          props.className,
          'text-sm before:-z-10 before:w-[10px] before:h-[10px] before:absolute',
          {
            relative: props.relative,
            absolute: !props.relative,
            'color-item p-2 flex flex-col w-fit': !props.className,
            'before:-bottom-[5px] before:left-[3px] before:bg-item before:dark:bg-item-dark  before:-z-10  before:-rotate-45':
              props.direction === Direction.Top && props.renderArrow,
            'before:-left-[5px] before:bottom-1/2 before:bg-item before:dark:bg-item-dark  before:-z-10  before:-rotate-45':
              props.direction === Direction.Right,
            'before:-top-[5px] before:left-[3px] before:bg-item before:dark:bg-item-dark  before:-z-10 translate-y-[5px] before:-rotate-45':
              props.direction === Direction.Bottom && props.renderArrow,
          }
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {React.Children.map(props.content, (item) => {
          if (!React.isValidElement(item)) return item;

          return React.cloneElement(item as JSX.Element, {
            onClick: () =>
              triggerOn === 'click' &&
              props.closeOnContentClick &&
              setIsOpen(false),
            onMouseOut: () => (triggerOn === 'hover' ? setIsOpen(false) : {}),
            className: 'w-full',
          });
        })}
      </div>
    );
  };

  return (
    <div
      ref={containerRef}
      className={classNames({
        'w-full': props.fill,
        'w-fit': !props.fill,
        'text-item-contrast-inactive dark:text-item-dark-contrast-inactive':
          props.disabled,
      })}
    >
      <button
        disabled={props.disabled}
        onClick={(e) => {
          triggerOn === 'click' && e.stopPropagation();
          if (props.isOpen !== undefined) {
            return;
          }
          setIsOpen(!isOpen);
        }}
        onMouseOver={() => {
          if (triggerOn === 'click') return;

          setIsOpen(true);
        }}
        onMouseOut={() => {
          if (triggerOn === 'click') return;

          setIsOpen(false);
        }}
        ref={targetElementRef}
        className="relative w-full"
      >
        {props.children}
      </button>

      {usePortal ? (
        <Portal target={props.target}>{renderPopoverContent()}</Portal>
      ) : (
        renderPopoverContent()
      )}
    </div>
  );
};

export default Popover;
