import React from "react";
import { GroupedNavItems } from "./types";
import SidebarItem from "./SidebarItem";
import { formatGroupTitle } from "./helpers";
import { Icon } from "../../icons/Icon";
import Divider from "./Divider";

type Props = {
  groupedNavItems: GroupedNavItems;
  collapsedItems: string[];
  toggleGroupHidden: (group: string) => void;
  isCollapsed?: boolean;
  selectedItemKey?: string;
};

const SidebarGroup = ({
  groupedNavItems,
  collapsedItems,
  toggleGroupHidden,
  isCollapsed,
  selectedItemKey,
}: Props) => {
  return (
    <div>
      {Object.entries(groupedNavItems).map(([group, item], i, arr) => {
        const isGroupHidden = collapsedItems.includes(group);
        if (group === "ungrouped") {
          return (
            <div key={group}>
              {item?.items.map((subItem) => {
                if (subItem?.hidden) return;
                return (
                  <SidebarItem
                    key={subItem.key}
                    item={subItem}
                    isCollapsed={isCollapsed}
                    isSelected={subItem.key === selectedItemKey}
                  />
                );
              })}
            </div>
          );
        }

        return (
          <div key={group}>
            {!isCollapsed && (
              <div
                className="flex items-center justify-between p-1 cursor-pointer mx-3"
                onClick={() => toggleGroupHidden(group)}
              >
                <span className="text-xs ">{formatGroupTitle(group)}</span>
                <Icon
                  icon={isGroupHidden ? "ChevronUp" : "ChevronDown"}
                  size={16}
                />
              </div>
            )}
            {!isGroupHidden && (
              <div>
                {item?.items.map((subItem) => {
                  if (subItem?.hidden) return;
                  return (
                    <SidebarItem
                      key={subItem.key}
                      item={subItem}
                      isCollapsed={isCollapsed}
                      isSelected={subItem.key === selectedItemKey}
                    />
                  );
                })}
              </div>
            )}
            {i !== arr.length - 1 && <Divider />}
          </div>
        );
      })}
    </div>
  );
};

export default SidebarGroup;
