import type React from "react";
import { useEffect, useRef } from "react";

/**
 * useEffect that triggers only on dependency array update. Emulates componentDidUpdate
 * @param effect function that triggers on deps update
 * @param deps trigger update when this is changed
 */
const useUpdateEffect = (effect: () => void, deps: React.DependencyList) => {
  const mounted = useRef<boolean>(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useUpdateEffect;
