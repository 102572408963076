import { useCallback, useEffect, useRef, useState } from 'react';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import type { IApplication } from 'datacosmos/types/applications';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import {
  Checkbox,
  Input,
  NumberInput,
  RangeSlider,
  Tooltip,
} from 'opencosmos-ui';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { postGeontransformImage } from '_api/sampling/service';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { hasVisualHighresRoleAsset } from 'datacosmos/utils/stac';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import { MapMarkerLayer } from 'datacosmos/entities/mapMarkerLayer';
import { CompositeDetailsItem } from 'pages/ops/shared/components/DetailsItem';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  app: IApplication;
}

export const ShiftImageApplication: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Shift image',
  description:
    'Shift an image by a given offset in the x and y directions. To use this app, click to select a full res image on the map, then adjust the x and y offsets.',
  inputs: [
    {
      field: 'imageId',
      example: '',
    },
    {
      field: 'xOffset',
      example: '10',
    },
    {
      field: 'yOffset',
      example: '10',
    },
  ],
  values: {
    imageId: { value: undefined, isError: false, message: '' },
    xOffset: { value: 0, isError: false, message: '' },
    yOffset: { value: 0, isError: false, message: '' },
  },
  provider: {
    id: 1,
    name: 'Open Cosmos',
    description: 'Making space accessible',
    url: 'https://www.open-cosmos.com',
    icon_url:
      'https://storage.googleapis.com/datacosmos-public/manual_outputs/AG/OpenCosmos.svg',
  },
  shortDescription:
    'Shift an image by a given offset in the x and y directions',
  renderer: (app: IApplication) => <ShiftImage app={app} />,
  appScreenshotUrl: '',
  tags: [],
};

/**
 * MeasureHeight application renderer
 */
export function ShiftImage({ app }: IProps) {
  const {
    layers,
    replaceLayer,
    replaceSpecificLayerWithAnother,
    removeLayersBySourceType,
    addLayer,
  } = useMapLayers();
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  // Needed for the checkbox
  const themeProvider = useTheme();

  const { translate } = useLocalisation();

  const { mapRef } = useMap();

  const [isClickToShiftEnabled, setIsClickToShiftEnabled] =
    useState<boolean>(false);

  const {
    clickedStacLayer,
    layersContainStacItems,
    clickedPoint: clickedImagePoint,
  } = useClickedStacItem({
    outlineClickedItem: !isClickToShiftEnabled,
    onImageClick: (img, clickedCoords) => {
      setValue('imageId', img.item.id);

      if (isClickToShiftEnabled) {
        return;
      }

      removeLayersBySourceType(LayerSourceType.SHIFT_IMAGE_POINT_MARKER);
      const marker = new MapMarkerLayer(
        LayerSourceType.SHIFT_IMAGE_POINT_MARKER,
        '',
        clickedCoords,
        '',
        { enablePopup: false }
      );

      addLayer(marker);
    },
  });

  const clickedStacLayerFromLayers = (
    layers.filter(
      (l) => l instanceof SingleBandSTACLayer
    ) as SingleBandSTACLayer[]
  ).find((l) => l.item.id === clickedStacLayer?.item.id);

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);

  const { activePage, setActivePage } = useActivePage();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [xOffset, setXOffset] = useState<number | undefined>();
  const [yOffset, setYOffset] = useState<number | undefined>();

  const setValue = useCallback(
    (key: string, value: string | number | undefined) => {
      setInputData(app.name, {
        ...app.values,
        [key]: {
          value: value === undefined ? '' : `${value}`,
          isError: false,
          message: '',
        },
      });
    },
    [app.name, app.values, setInputData]
  );

  const setError = (key: string, message: string) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value: '', isError: true, message: message },
    });
  };

  const offsetDebounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedShiftImageByOffset = useCallback(
    (offset: { x: number; y: number }) => {
      if (!clickedStacLayerFromLayers) {
        return;
      }

      if (offsetDebounceTimeoutRef.current) {
        clearTimeout(offsetDebounceTimeoutRef.current);
      }

      setValue('xOffset', offset.x);
      setValue('yOffset', offset.y);

      // Remove outline when changing offset
      removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);

      offsetDebounceTimeoutRef.current = setTimeout(() => {
        const newLayer = new SingleBandSTACLayer(
          clickedStacLayerFromLayers.item,
          clickedStacLayerFromLayers.assetKey
        ).cloneWithOptions({
          ...clickedStacLayerFromLayers.options,
          offset: {
            x: offset.x,
            y: offset.y,
          },
        });

        replaceSpecificLayerWithAnother(newLayer, clickedStacLayerFromLayers);
      }, 500);
    },
    [
      clickedStacLayerFromLayers,
      removeLayersBySourceType,
      replaceSpecificLayerWithAnother,
      setValue,
    ]
  );

  useEffect(() => {
    if (!layersContainStacItems) {
      setValue('imageId', undefined);
    }
  }, [layersContainStacItems, setValue]);

  useEffect(() => {
    const map = mapRef.current;

    mapRef.current?.on('mousedown', (e) => {
      if (!isClickToShiftEnabled) {
        return;
      }

      e.originalEvent.stopPropagation();

      // Get the clicked point on the map
      const clickedPoint = [e.latlng.lng, e.latlng.lat];

      if (!clickedImagePoint) {
        return;
      }

      // Get points relative to the image clicked point, on the map: one with the same x as the img clicked point and one with the same y
      // as the map clicked point
      const yOffsetPoint = [clickedImagePoint.lng, clickedPoint[1]];
      const xOffsetPoint = [clickedPoint[0], clickedImagePoint.lat];

      // Calculate the distance between the clicked point and the bbox center
      // This is the distance we want to shift the image by
      let yOff = Math.round(
        e.latlng.distanceTo({
          lat: xOffsetPoint[1],
          lng: xOffsetPoint[0],
        })
      );

      // If bbox latitude is greater than the clicked point latitude, the offset should be negative
      if (clickedImagePoint.lat > clickedPoint[1]) {
        yOff *= -1;
      }

      setYOffset(yOff);

      let xOff = Math.round(
        e.latlng.distanceTo({
          lat: yOffsetPoint[1],
          lng: yOffsetPoint[0],
        })
      );

      // If bbox longitude is greater than the clicked point longitude, the offset should be negative
      if (clickedImagePoint.lng > clickedPoint[0]) {
        xOff *= -1;
      }

      setXOffset(xOff);

      // Shift the image by the calculated offset
      debouncedShiftImageByOffset({
        x: xOff,
        y: yOff,
      });
    });

    return () => {
      if (map) {
        map.off('mousedown');
      }
    };
  }, [
    clickedImagePoint,
    debouncedShiftImageByOffset,
    isClickToShiftEnabled,
    mapRef,
  ]);

  const inputs = () => {
    return (
      <div className="flex flex-col gap-2">
        <Input
          type="text"
          readOnly
          value={clickedStacLayer?.item.id}
          placeholder={'Selected img Id will appear here'}
          label={{ position: 'top', text: 'Selected image Id' }}
          className="border-none"
        />
        {app.values.imageId.isError && (
          <small className="text-red-500 text-sm">
            {app.values.imageId.message}
          </small>
        )}
        <RangeSlider
          numberOfHandles={1}
          label={{ text: 'Opacity' }}
          showValuesAboveHandles={true}
          showScale={false}
          value={clickedStacLayerFromLayers?.options.opacity}
          minValue={0}
          maxValue={1}
          step={0.1}
          onChange={(val) => {
            if (clickedStacLayerFromLayers && val !== undefined) {
              replaceLayer(
                clickedStacLayerFromLayers.cloneWithOptions({
                  opacity: val as number,
                })
              );
            }
          }}
        />

        <CompositeDetailsItem
          title="Click to shift"
          element={
            <div className="w-full flex items-center justify-end">
              <Tooltip
                content={
                  !clickedStacLayerFromLayers
                    ? 'Select an image'
                    : 'When enabled, click on the map to shift the image to a specified point'
                }
              >
                <Checkbox
                  themeProvider={themeProvider}
                  isDisabled={!clickedStacLayerFromLayers}
                  onChange={(selected) => {
                    setIsClickToShiftEnabled(Boolean(selected));
                  }}
                  isSelected={isClickToShiftEnabled}
                />
              </Tooltip>
            </div>
          }
        />

        <NumberInput
          label="X offset"
          value={xOffset}
          fill
          onChange={(val) => {
            setXOffset(val);
            debouncedShiftImageByOffset({
              x: val,
              y: app.values.yOffset.value as number,
            });
          }}
        />
        <NumberInput
          label="Y offset"
          fill
          value={yOffset}
          onChange={(val) => {
            setYOffset(val);
            debouncedShiftImageByOffset({
              x: app.values.xOffset.value as number,
              y: val,
            });
          }}
        />
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (!clickedStacLayerFromLayers) {
            setError('imageId', 'Please select a full res image');
            return;
          }

          const isVisualAssetSelected =
            clickedStacLayerFromLayers?.assetKey === 'visual' ||
            clickedStacLayerFromLayers?.assetKey === 'TCI' ||
            hasVisualHighresRoleAsset(clickedStacLayerFromLayers.item);

          if (!isVisualAssetSelected) {
            setError('imageId', 'Please select a full res image');
            return;
          }

          setIsSubmitting(true);
          await postGeontransformImage({
            body: {
              x_offset: parseInt(app.values.xOffset.value as string),
              y_offset: parseInt(app.values.yOffset.value as string),
            },
            params: {
              collection: clickedStacLayerFromLayers.item.collection ?? '',
              items: app.values.imageId.value as string,
            },
          });

          setIsSubmitting(false);
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmitting}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(selectedApp) => {
        setSelectedInstalledApp(selectedApp);
        if (activePage === 'application') {
          setActivePage(undefined);
        }
      }}
    />
  );
}
