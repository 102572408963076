import { getGeoPdfFile, type PostGeoPDF } from '_api/geopdf/service';
import { getShpBase64 } from 'datacosmos/download/geojson';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { getStaticMapURLFromBoundingBox } from 'datacosmos/utils/utils';
import {
  DefaultDPI,
  DefaultPaperSize,
  PapersizesForGeoPDF,
} from 'datacosmos/utils/views';
import { HOST_URL, LOGO } from 'env';
import { Formik, type FormikProps, type FormikValues } from 'formik';
import type { BBox } from 'geojson';
import { isEmpty } from 'lodash';
import {
  Dialog,
  Icon,
  ListBoxItem,
  RichTextEditor,
  Select,
} from 'opencosmos-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import { downloadFile } from 'utils/common/CommonUtils';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface GeoPDFProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface shpURL {
  id: string;
  shp: string;
}
const GeoPDFDetails = ({ isOpen, setIsOpen }: GeoPDFProps) => {
  const { translate } = useLocalisation();
  const [selectedPaperSize, setSelectedPaperSize] =
    useState<string>(DefaultPaperSize);

  const [selectedOrientation, setSelectedOrientation] = useState<string>(
    translate('datacosmos.geopdfDialog.orientationValues.portrait')
  );

  interface FormValues {
    dpi: string;
    description: string;
  }

  const initialValues: FormValues = { dpi: DefaultDPI, description: '' };

  const formRef = useRef<FormikProps<FormValues>>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [shpURLsForVectors, setShpURLsForVectors] = useState<shpURL[]>();
  const { layers } = useMapLayers();
  const { user } = useAuth();
  const { getMapBounds } = useMap();

  const mapBbox = getMapBounds();

  const shouldDisplayWarning = useMemo(() => {
    const stacLayers = layers.filter(
      (l) => l instanceof SingleBandSTACLayer
    ) as SingleBandSTACLayer[];
    const nonTiffLayers = stacLayers.filter(
      (layer) => !layer.item.assets[layer.assetKey].type?.includes('image/tiff')
    );
    return !isEmpty(nonTiffLayers);
  }, [layers]);

  const adjustBoundsToAspectRatio = (
    [swLng, swLat, neLng, neLat]: BBox,
    targetAspectRatio: number
  ): BBox => {
    const currentWidth = neLng - swLng;
    const currentHeight = neLat - swLat;
    const currentAspectRatio = currentWidth / currentHeight;

    if (currentAspectRatio === targetAspectRatio) {
      return [swLng, swLat, neLng, neLat]; // No adjustment needed
    }

    // Calculate the desired height to match target aspect ratio
    const desiredHeight = currentWidth / targetAspectRatio;
    const heightDifference = desiredHeight - currentHeight;
    const adjustment = heightDifference / 2;

    // Adjust and clamp the latitude values within -85.0511 and 85.0511
    const adjustedNorthLat = Math.min(neLat + adjustment, 85.0511);
    const adjustedSouthLat = Math.max(swLat - adjustment, -85.0511);

    return [swLng, adjustedSouthLat, neLng, adjustedNorthLat];
  };

  //filters the layers that do not contain tiff assets
  const layersToBeAddedInGeoPDF = useMemo(() => {
    return layers.filter(
      (mapLayer) =>
        (mapLayer instanceof SingleBandSTACLayer &&
          mapLayer.item.assets[mapLayer.assetKey].type?.includes(
            'image/tiff'
          )) ||
        !(mapLayer instanceof SingleBandSTACLayer)
    );
  }, [layers]);

  useEffect(() => {
    if (!isOpen || !layersToBeAddedInGeoPDF.length) {
      return;
    }

    const vectorLayers = layersToBeAddedInGeoPDF.filter(
      (mapLayer) =>
        mapLayer instanceof PolygonLayer ||
        mapLayer instanceof CircleLayer ||
        mapLayer instanceof LineLayer ||
        mapLayer instanceof GeoJSONLayer
    ) as PolygonLayer[];

    void Promise.all(
      vectorLayers.map(async (addedLayer) => {
        return {
          id: addedLayer.id,
          shp: (await getShpBase64(
            addedLayer.data as GeoJSON.Feature
          )) as string,
        };
      })
    ).then((vectorURLArray) => {
      // vectorURLArray contains the resolved values
      setShpURLsForVectors(vectorURLArray);
    });
  }, [layersToBeAddedInGeoPDF, isOpen]);

  const getLayersForPayload = useCallback(
    (coords: BBox, width: number, height: number, values: FormikValues) => {
      const x1 = 0.025 * width;
      const y1 = 0.04 * height;

      const x2 = (3 * width) / 4.05;
      const y2 = height - y1;

      const adjustedWidth = x2 - x1;
      const adjustedHeight = y2 - y1;

      const adjustedBbox = adjustBoundsToAspectRatio(
        coords,
        adjustedWidth / adjustedHeight
      );

      const mapLayers = layersToBeAddedInGeoPDF.map((layer) => {
        if (layer instanceof SingleBandSTACLayer) {
          return {
            enabled: true,
            georeferencing: {
              bbox: adjustedBbox,
            },
            raster: tilingApi.generateSingleBandBboxURL(
              layer.item.assets[layer.assetKey].href,
              {
                bbox: adjustedBbox,
                width: width,
                height: height,
              },
              layer.options
            ),
          };
        }
        if (layer instanceof BandAlgebraSTACLayer && layer.item.collection) {
          const formattedExpression =
            layer.expression.split('::').length > 1
              ? layer.expression.split('::')[1]
              : layer.expression.split('::')[0];
          return {
            enabled: true,
            georeferencing: {
              bbox: adjustedBbox,
            },
            raster: tilingApi.generateBandAlgebraBboxURL(
              `/collections/${layer.item.collection}/items/${layer.item.id}`,
              formattedExpression,
              {
                bbox: adjustedBbox,
                width: width,
                height: height,
              },
              layer.options
            ),
          };
        }
        if (
          layer instanceof CircleLayer ||
          layer instanceof PolygonLayer ||
          layer instanceof LineLayer ||
          layer instanceof GeoJSONLayer
        ) {
          return {
            enabled: true,
            georeferencing: {
              bbox: adjustedBbox,
            },
            vector: shpURLsForVectors?.find((v) => v.id === layer.id)?.shp,
          };
        }
        return {
          enabled: true,
          georeferencing: {
            bbox: adjustedBbox,
          },
        };
      });

      const textLayer = {
        enabled: true,
        text: values.description as string,
      };

      const mapBaseLayer = {
        enabled: true,
        raster: getStaticMapURLFromBoundingBox(
          adjustedBbox,
          Math.floor(adjustedWidth)?.toString(),
          Math.floor(adjustedHeight)?.toString()
        ),
      };

      const logoURL = LOGO
        ? `${HOST_URL}/images/datacosmos/conida-logo.png`
        : `${HOST_URL}/images/oc-logo.png`;

      const logoLayer = {
        logo: true,
        enabled: true,
        raster: logoURL,
      };

      const marginY = 0.037 * Number(height);
      const mapHeight = Number(height) / 3 - 2 * marginY;

      const marginX = 0.02 * Number(width);
      const mapWidth = 0.23 * Number(width) - 2 * marginX;

      const minimapBbox = adjustBoundsToAspectRatio(
        LOGO
          ? [-87.363281, -22.024546, -64.599609, 2.569939]
          : [-180, -90, 180, 90],
        mapWidth / mapHeight
      );

      const minimapLayer = {
        minimap: true,
        enabled: true,
        raster: getStaticMapURLFromBoundingBox(
          minimapBbox,
          Math.floor(mapWidth)?.toString(),
          Math.floor(mapHeight)?.toString()
        ),
      };

      return [
        textLayer,
        mapBaseLayer,
        logoLayer,
        minimapLayer,
        ...mapLayers.reverse(),
      ];
    },
    [layersToBeAddedInGeoPDF, shpURLsForVectors]
  );

  return (
    <>
      <Dialog
        title={translate('datacosmos.geopdfDialog.geoPDFHeader')}
        isOpen={isOpen}
        buttons={[
          {
            text: translate('datacosmos.geopdfDialog.createGeoPDF'),
            onPress: () => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            },
            shown: true,
            showLoadingIndicator: loading,
            keepDialogOpenOnPress: true,
            intent: 'primary',
          },
        ]}
        onClose={() => {
          setIsOpen(!isOpen);
          setSelectedPaperSize(DefaultPaperSize);
          setSelectedOrientation(
            translate('datacosmos.geopdfDialog.orientationValues.portrait')
          );
        }}
        showButtonsInFooter
      >
        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          onSubmit={async (values, { setErrors, resetForm }) => {
            if (values.dpi === '') {
              setErrors({
                dpi: translate(
                  'datacosmos.addNewProjectDialog.errors.description'
                ),
              });
              return;
            }

            if (values.description === '') {
              setErrors({
                description: translate(
                  'datacosmos.addNewProjectDialog.errors.description'
                ),
              });
              return;
            }

            const width =
              PapersizesForGeoPDF[selectedPaperSize][values.dpi][
                selectedOrientation ===
                translate('datacosmos.geopdfDialog.orientationValues.portrait')
                  ? 0
                  : 1
              ];
            const height =
              PapersizesForGeoPDF[selectedPaperSize][values.dpi][
                selectedOrientation ===
                translate('datacosmos.geopdfDialog.orientationValues.portrait')
                  ? 1
                  : 0
              ];

            if (!user || !mapBbox) {
              return;
            }
            setLoading(true);

            const geoPDFRequestBody: PostGeoPDF = {
              metadata: {
                author: user.name,
              },
              pages: [
                {
                  settings: {
                    dpi: values.dpi,
                    width: width?.toString(),
                    height: height?.toString(),
                    has_logo: true,
                    has_minimaps: true,
                  },
                  content: {
                    layers: getLayersForPayload(mapBbox, width, height, values),
                  },
                },
              ],
            };

            await getGeoPdfFile({
              body: geoPDFRequestBody,
            })
              .then((res) => {
                if (res.status === 200) {
                  downloadFile(
                    res.data as BlobPart,
                    `geopdf-${values.dpi}-${width}x${height}.pdf`
                  );
                  resetForm();
                }
                setIsOpen(false);
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <>
              <div className="grid grid-cols-2 gap-2">
                <Select
                  selectedKey={selectedPaperSize}
                  onSelectionChange={(size) => {
                    setSelectedPaperSize(size as string);
                  }}
                  placeholder={translate(
                    'datacosmos.geopdfDialog.paperSizePlaceholder'
                  )}
                  fill
                  label={translate('datacosmos.geopdfDialog.paperSizeLabel')}
                  defaultSelectedKey={DefaultPaperSize}
                >
                  {Object.entries(PapersizesForGeoPDF).map((item) => (
                    <ListBoxItem id={item[0]} key={item[0]}>
                      {item[0]}
                    </ListBoxItem>
                  ))}
                </Select>

                <Select
                  selectedKey={selectedOrientation}
                  onSelectionChange={(or) => {
                    setSelectedOrientation(or as string);
                  }}
                  placeholder={translate(
                    'datacosmos.geopdfDialog.orientationPlaceholder'
                  )}
                  fill
                  label={translate('datacosmos.geopdfDialog.orientationLabel')}
                  defaultSelectedKey={translate(
                    'datacosmos.geopdfDialog.orientationValues.portrait'
                  )}
                >
                  <ListBoxItem
                    id={translate(
                      'datacosmos.geopdfDialog.orientationValues.portrait'
                    )}
                    key={translate(
                      'datacosmos.geopdfDialog.orientationValues.portrait'
                    )}
                  >
                    {translate(
                      'datacosmos.geopdfDialog.orientationValues.portrait'
                    )}
                  </ListBoxItem>
                  <ListBoxItem
                    id={translate(
                      'datacosmos.geopdfDialog.orientationValues.landscape'
                    )}
                    key={translate(
                      'datacosmos.geopdfDialog.orientationValues.landscape'
                    )}
                  >
                    {translate(
                      'datacosmos.geopdfDialog.orientationValues.landscape'
                    )}
                  </ListBoxItem>
                </Select>
              </div>

              <div className="mt-3">
                <Select
                  selectedKey={values.dpi}
                  onSelectionChange={(size) => {
                    void setFieldValue('dpi', size);
                  }}
                  placeholder={translate(
                    'datacosmos.geopdfDialog.dpiPlaceholder'
                  )}
                  fill
                  label="DPI"
                  isDisabled={!selectedPaperSize}
                  //by default 72 is set for all GeoPDF
                  defaultSelectedKey={DefaultDPI}
                >
                  {Object.keys(PapersizesForGeoPDF[selectedPaperSize]).map(
                    (dpi) => (
                      <ListBoxItem id={dpi} key={dpi}>
                        {dpi}
                      </ListBoxItem>
                    )
                  )}
                </Select>
                <small style={{ color: 'red' }}>{errors.dpi?.toString()}</small>
              </div>

              <div className="mt-3">
                <RichTextEditor
                  value={values.description}
                  label={{
                    text: translate('datacosmos.geopdfDialog.descriptionLabel'),
                    position: 'top',
                  }}
                  onChange={(val) => void setFieldValue('description', val)}
                  placeholder={translate(
                    'datacosmos.geopdfDialog.descriptionPlaceholder'
                  )}
                />

                <small style={{ color: 'red' }}>
                  {errors.description?.toString()}
                </small>
              </div>

              {shouldDisplayWarning && (
                <div className="flex items-center gap-2 bg-accent-300 dark:bg-accent-dark my-2 p-1 text-xs dark:text-item-contrast">
                  <Icon icon="Info" />
                  <span>
                    {translate('datacosmos.geopdfDialog.nonTiffWarning')}
                  </span>
                </div>
              )}
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default GeoPDFDetails;
