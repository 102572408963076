import moment from 'moment';
import { last, pipe, split } from 'lodash/fp';
import { EntryType } from 'constants/fileExplorer/constants';
import type { IFolder } from 'services/api/ops/cloudStorage';
import type {
  IFtpSatelliteFolder,
  ISftpSatelliteFolder,
} from 'services/api/ops/satelliteStorage';
import {
  removeDoubleSlashes,
  removeFinalSlash,
  removeFirstSlash,
} from 'utils/common/stringUtils';

export const splitLast = (value: string) => pipe(split('/'), last)(value);

export const computePath = (toRawPath: string, fromRawPath: string) => {
  let name;
  const isCopyingFolder = fromRawPath.endsWith('/');
  if (isCopyingFolder) {
    name = splitLast(removeFinalSlash(fromRawPath));
  } else {
    name = splitLast(fromRawPath);
  }
  name = removeFirstSlash(name ?? '');

  const toPath =
    '/' + removeDoubleSlashes(removeFinalSlash(removeFirstSlash(toRawPath)));
  const fromPath = '/' + removeDoubleSlashes(removeFirstSlash(fromRawPath));

  const isToPathRoot = toPath === '/';
  if (!isToPathRoot) {
    name = '/' + name;
  }

  return {
    toPath: toPath + name,
    fromPath,
  };
};

export const ftpToFolder =
  (fullPath: string) =>
  (file: IFtpSatelliteFolder): IFolder => ({
    name: `${file.name}/`,
    path: `${fullPath}${file.name}${file.isDir ? '/' : ''}`,
    type: file.isDir ? EntryType.FOLDER : EntryType.FILE,
    lastModified: moment.unix(file.lastModified).toISOString(),
    size: file.size,
    checksum: file.checksum,
  });

export const sftpToFolder =
  (fullPath: string) =>
  (file: ISftpSatelliteFolder): IFolder => ({
    name: `${file.Name}/`,
    path: `${fullPath}${file.Name}${file.Type === 'folder' ? '/' : ''}`,
    type: file.Type === 'folder' ? EntryType.FOLDER : EntryType.FILE,
    lastModified: moment(file.LastModified).toISOString(),
    size: file.Size,
  });
