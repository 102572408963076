import { useQuery } from '_api/useQuery';
import ResourceLayout from './ResourceLayout';
import { getView } from '_api/views/service';
import ResourceHeader from './ResourceHeader';
import DefaultResourceTypeLayout from 'layouts/datacosmos/DefaultResourceTypesLayout';

interface Props {
  resourceId: string;
  resourceType: string;
}
const ViewResources = (props: Props) => {
  const { data: viewData } = useQuery(getView, {
    initialData: undefined,
    params: { viewId: props.resourceId },
  });

  return (
    <DefaultResourceTypeLayout
      loading={!viewData?.id}
      middleTitle={<div className="text-lg font-bold">View</div>}
    >
      {viewData && (
        <ResourceHeader
          title={viewData?.name}
          icon="Menu"
          description={viewData?.description}
        />
      )}
      <div className="my-5 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      <ResourceLayout
        resourceId={props.resourceId}
        resourceType={props.resourceType}
      />
    </DefaultResourceTypeLayout>
  );
};

export default ViewResources;
