import React from 'react';
import { Button, Spinner } from '@blueprintjs/core';
import s from './SpinnerWithButton.module.scss';

interface IProps {
  buttonText: string;
  onButtonClick: () => void;
  spinnerSize?: number;
}

const SpinnerWithButton = (props: IProps) => (
  <div className={s.spinnerWrapper}>
    <Spinner />
    <Button text={props.buttonText} onClick={props.onButtonClick} />
  </div>
);

export default SpinnerWithButton;
