import type {
  ILOState,
  LaunchOpportunitiesActionTypes,
  ILaunchOpportunity,
} from '../../../../constants/constraintsTable/actionTypesLaunchOpportunity';
import {
  LO_ADD,
  LO_UPLOAD,
  LO_UPDATE,
} from '../../../../constants/constraintsTable/actionTypesLaunchOpportunity';

const initialState: ILOState = {
  list: [],
};

export default function launchOpportunitiesReducer(
  state: ILOState = initialState,
  action: LaunchOpportunitiesActionTypes
): ILOState {
  switch (action.type) {
    case LO_ADD: {
      return {
        list: [...state.list, action.launchOpportunity],
      };
    }
    case LO_UPDATE: {
      return {
        list: state.list.map((lo): ILaunchOpportunity => {
          if (lo.id === action.launchOpportunity.id) {
            return action.launchOpportunity;
          } else {
            return lo;
          }
        }),
      };
    }
    case LO_UPLOAD: {
      return action.launchOpportunities;
    }
    default:
      return state;
  }
}
