import React from 'react';
import { isEmpty } from 'lodash/fp';
import {
  Menu,
  MenuDivider,
  MenuItem,
  Position,
  Popover,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import s from './index.module.scss';

interface IProps {
  inputWidth: string | number | undefined;
  handleExampleClick: (value: unknown) => void;
  title?: string;
  examples?: string[];
  disabled: boolean;
  errors?: unknown;
  children: React.ReactNode;
  isOpen: boolean;
  isPropertyRequired?: boolean;
}

const CommandPrimitivePopover = ({
  inputWidth,
  handleExampleClick,
  examples,
  children,
  disabled,
  title,
  errors,
  isOpen,
  isPropertyRequired,
}: IProps) => {
  const choseTitleStyle = !isEmpty(errors)
    ? [s.examplesHeader, s.examplesHeaderError].join(' ')
    : s.examplesHeader;

  const interactionKind = examples
    ? PopoverInteractionKind.CLICK
    : PopoverInteractionKind.HOVER_TARGET_ONLY;

  return (
    <Popover
      interactionKind={interactionKind}
      openOnTargetFocus={false}
      position={Position.TOP}
      disabled={disabled}
      modifiers={{
        arrow: { enabled: false },
      }}
      isOpen={isOpen}
      enforceFocus={false}
      content={
        <div className={s.examplesPopoverContainer}>
          <Menu style={{ width: inputWidth ? inputWidth : undefined }}>
            {title && (
              <MenuDivider
                className={choseTitleStyle}
                title={
                  <div className="flex flex-col">
                    {isPropertyRequired && (
                      <span className="text-red-500">REQUIRED</span>
                    )}
                    <span>{title}</span>
                  </div>
                }
              />
            )}
            {examples?.map((example) => (
              <MenuItem
                key={example}
                text={example}
                onClick={() => handleExampleClick(example)}
              />
            ))}
          </Menu>
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default CommandPrimitivePopover;
