/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/no-string-refs */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useMap, type IMapBaseLayer } from 'datacosmos/stores/MapProvider';
import {
  Button,
  DialogTrigger,
  Icon,
  Popover,
  TextField,
  Tooltip,
} from 'opencosmos-ui';
import { Dialog } from 'react-aria-components';
import { toaster } from 'toaster';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { zIndexValues } from 'opencosmos-ui/constants';

function MapLayers() {
  const { baseLayer, setBaseLayer, mapBaseLayers, setMapBaseLayers } = useMap();

  const { translate } = useLocalisation();

  const [isAddingNewMapLayer, setIsAddingNewMapLayer] =
    useState<boolean>(false);

  const defaultbaseLayerValue: IMapBaseLayer = {
    id: Math.random().toFixed(4).toString(),
    name: '',
    url: '',
  };

  const [newBaseLayer, setNewBaseLayer] = useState<IMapBaseLayer>(
    defaultbaseLayerValue
  );

  const onLayerSelect = (layer: any, e: React.MouseEvent) => {
    e.preventDefault();
    setBaseLayer(layer);
  };

  const handleCloseAddNewLayer = () => {
    setIsAddingNewMapLayer(false);
    setNewBaseLayer(defaultbaseLayerValue);
  };

  const handleExtendBaseLayers = () => {
    if (
      !newBaseLayer.url.includes('{z}') ||
      !newBaseLayer.url.includes('{y}') ||
      !newBaseLayer.url.includes('{x}')
    ) {
      toaster.show({
        intent: 'warning',
        icon: 'warning-sign',
        message: translate('datacosmos.map.urlMessage'),
      });
      return;
    }
    const existingBaseLayers = [...mapBaseLayers];
    existingBaseLayers.push(newBaseLayer);
    setMapBaseLayers(existingBaseLayers);
    setBaseLayer(newBaseLayer);
    handleCloseAddNewLayer();
  };

  return (
    <span className="bttn-secondary map-menu-layers">
      <DialogTrigger onOpenChange={handleCloseAddNewLayer}>
        <Button
          isMinimal
          isTransparent
          size="sm"
          fill
          className={'flex justify-center items-center px-0'}
        >
          <Icon icon="Map" size={24} />
        </Button>

        <Popover placement="right" style={{ zIndex: zIndexValues.popover }}>
          <Dialog style={{ zIndex: zIndexValues.dialog }}>
            <div className="openapp-data basemap-selector">
              <div>
                <ul className="drop__menu drop__menu--select map-layers-list">
                  {!isAddingNewMapLayer &&
                    mapBaseLayers.map((o) => (
                      <li key={o.id}>
                        <MapLayerItem
                          selectedLayer={baseLayer}
                          layer={o}
                          onLayerSelect={onLayerSelect}
                        />
                      </li>
                    ))}
                  <li className="px-3">
                    {!isAddingNewMapLayer ? (
                      <div className=" pt-2">
                        <Button
                          icon="Plus"
                          iconPlacement="left"
                          fill
                          onPress={() => {
                            setIsAddingNewMapLayer(true);
                          }}
                        >
                          {translate('datacosmos.map.addNew.buttonTitle')}
                        </Button>
                      </div>
                    ) : (
                      <div className="w-full flex flex-col gap-2">
                        <TextField
                          type="string"
                          fill
                          autoFocus
                          inputProps={{
                            className: 'bg-surface dark:bg-surface-dark p-1',
                          }}
                          label={translate('datacosmos.map.addNew.name')}
                          onChange={(value) => {
                            setNewBaseLayer({
                              ...newBaseLayer,
                              name: value,
                            });
                          }}
                        />

                        <TextField
                          type="url"
                          fill
                          inputProps={{
                            className: 'bg-surface dark:bg-surface-dark p-1',
                          }}
                          label={
                            <div className="flex gap-1 items-center">
                              <div>
                                {translate('datacosmos.map.addNew.URL')}
                              </div>
                              <Tooltip
                                content={translate('datacosmos.map.urlMessage')}
                                placement="right"
                              >
                                <Icon icon="Info" />
                              </Tooltip>
                            </div>
                          }
                          onChange={(value) => {
                            setNewBaseLayer({
                              ...newBaseLayer,
                              url: value,
                            });
                          }}
                        />
                        <div className="flex justify-end items-center gap-2 w-full">
                          <Button
                            onPress={handleCloseAddNewLayer}
                            fill
                            intent="warning"
                            isMinimal
                            isTransparent
                          >
                            {translate('datacosmos.map.addNew.buttons.close')}
                          </Button>
                          <Button
                            onPress={handleExtendBaseLayers}
                            fill
                            intent="secondary"
                            isMinimal
                            isDisabled={
                              !newBaseLayer.name.length ||
                              !newBaseLayer.url.length
                            }
                          >
                            {translate('datacosmos.map.addNew.buttons.add')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </Dialog>
        </Popover>
      </DialogTrigger>
    </span>
  );
}

interface IPropsMapLayerItem {
  selectedLayer: any;
  layer: any;
  onLayerSelect: (layer: any, e: React.MouseEvent) => void;
}

// Each Map layer it's its own component because we need to be able to add the
// actual map on component mount.
class MapLayerItem extends React.Component<IPropsMapLayerItem> {
  static displayName = 'MapLayerItem';

  map: L.Map | null = null;

  componentDidMount() {
    this.map = window.L.map(this.refs.map as HTMLDivElement, {
      zoomControl: false,
    });
    this.map
      ?.addLayer(window.L.tileLayer(this.props.layer.url))
      .setView([9, 0], 0);

    // Disable drag and zoom handlers.
    this.map?.dragging.disable();
    this.map?.touchZoom.disable();
    this.map?.doubleClickZoom.disable();
    this.map?.scrollWheelZoom.disable();
    this.map?.keyboard.disable();

    // Disable tap handler, if present.
    if (this.map?.tap) this.map.tap.disable();
  }

  componentWillUnmount() {
    this.map?.remove();
  }

  render() {
    let c = 'drop__menu-item';
    c +=
      this.props.selectedLayer.id === this.props.layer.id
        ? ' drop__menu-item--active'
        : '';
    return (
      <a
        className={c}
        onClick={(e) => this.props.onLayerSelect(this.props.layer, e)}
        data-hook="dropdown:close"
      >
        <div className="map-layers-list__map" ref="map" />
        <span className="map-layers-list__text">{this.props.layer.name}</span>
      </a>
    );
  }
}

export default MapLayers;
