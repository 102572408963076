import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { Layer } from 'datacosmos/entities/layer';
import { Icon } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type IProps = {
  selectLayer: (l: Layer, addToCurrentSection: boolean) => void;
  layer: Layer | undefined;
};

const SelectLayer = ({ selectLayer, layer }: IProps) => {
  const { translate } = useLocalisation();
  if (!layer) return null;

  return (
    <MenuItem
      text={
        <div className="flex items-center gap-2">
          {translate('datacosmos.rightClickMenu.select')}
          {layer.options.isSelected ? <Icon icon="tick" /> : null}
        </div>
      }
      icon={IconNames.TICK_CIRCLE}
      onClick={() => {
        selectLayer(layer, false);
      }}
      className=" dark:hover:text-item-dark-hover hover:text-accent-900"
    />
  );
};

export default SelectLayer;
