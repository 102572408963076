import {
  PLATFORM_SIZE,
  PLATFORM_SOLAR_PANEL_DEPLOYABLE,
  PLATFORM_SOLAR_PANEL_DIRECTION,
  PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE,
  PLATFORM_SOLAR_PANEL_CONFIG,
} from '../../../constants/ui/platformConfigurator/types';

export enum PLATFORM_SIZE_TYPE {
  THREE_U = 'THREE_U',
  SIX_U_A = 'SIX_U_A',
  SIX_U_B = 'SIX_U_B',
  TWELVE_U = 'TWELVE_U',
}

export enum PLATFORM_SOLAR_PANEL_CONFIG_TYPE {
  NONE = 'NONE',
  PETAL = 'PETAL',
  DIAG = 'DIAG',
  PERP = 'PERP',
}

export enum PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  TRIPLE = 'TRIPLE',
}

export enum PLATFORM_SOLAR_PANEL_DIRECTION_TYPE {
  PLUS = 'PLUS',
  MINUS = 'MINUS',
}

export enum PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE_TYPE {
  FIFTY = 'FIFTY',
  ZERO = 'ZERO',
}

export const TYPE_TO_NAME: { [key: string]: { [key: string]: string } } = {
  [PLATFORM_SIZE]: {
    [PLATFORM_SIZE_TYPE.THREE_U]: '3U',
    [PLATFORM_SIZE_TYPE.SIX_U_A]: '6U (A)',
    [PLATFORM_SIZE_TYPE.SIX_U_B]: '6U (B)',
    [PLATFORM_SIZE_TYPE.TWELVE_U]: '12U',
  },
  [PLATFORM_SOLAR_PANEL_CONFIG]: {
    [PLATFORM_SOLAR_PANEL_CONFIG_TYPE.NONE]: 'None',
    [PLATFORM_SOLAR_PANEL_CONFIG_TYPE.PETAL]: 'Petal',
    [PLATFORM_SOLAR_PANEL_CONFIG_TYPE.DIAG]: 'Diagonal',
    [PLATFORM_SOLAR_PANEL_CONFIG_TYPE.PERP]: 'Perpendicular',
  },
  [PLATFORM_SOLAR_PANEL_DEPLOYABLE]: {
    [PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE.SINGLE]: 'Single',
    [PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE.DOUBLE]: 'Double',
    [PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE.TRIPLE]: 'Triple',
  },
  [PLATFORM_SOLAR_PANEL_DIRECTION]: {
    [PLATFORM_SOLAR_PANEL_DIRECTION_TYPE.PLUS]: 'Plus',
    [PLATFORM_SOLAR_PANEL_DIRECTION_TYPE.MINUS]: 'Minus',
  },
  [PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE]: {
    [PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE_TYPE.FIFTY]: '50%',
    [PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE_TYPE.ZERO]: '0%',
  },
};
