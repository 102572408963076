import classNames from 'classnames';
import React from 'react';

interface Props extends Pick<React.DOMAttributes<HTMLDivElement>, 'onClick'> {
  text: string | JSX.Element;
  active?: boolean;
}

const SelectItem = ({ text, active, ...props }: Props) => {
  return (
    <div
      {...props}
      className={classNames(
        'cursor-pointer hover:bg-item-selected mb-px mt-px p-1',
        'dark:hover:bg-item-dark-selected',
        {
          'bg-item-selected dark:bg-item-dark-selected dark:text-accent text-accent-900':
            active,
        }
      )}
    >
      <span>{text}</span>
    </div>
  );
};

export default SelectItem;
