import { DateRangeInput } from '@blueprintjs/datetime';
import { DATE_FORMAT } from 'constants/datetime';
import moment from 'moment';
import React, { useMemo } from 'react';
import type {
  ActivityType,
  ActivityStatus,
  ActivityWithRequestData,
} from '_api/activities/service';
import { toFormattedDate } from 'utils/common/dateUtils';
import SchedulingFilter from 'pages/ops/Scheduling/components/SchedulingFilter';
import s from './index.module.scss';
import { Button } from '@blueprintjs/core';
import {
  getLeafColumnIds,
  MultiSelect,
  Button as CustomButton,
  mergeObjects,
  Tooltip,
} from 'opencosmos-ui';
import { EXCLUDED_COLUMNS } from '../SchedulingTable/SchedulingTable';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import classNames from 'classnames';
import { useActivities } from '../../context/ActivitiesProvider';

type SchedulingHeaderProps = {
  selectedStatuses: ActivityStatus[];
  setActivityStatus: React.Dispatch<React.SetStateAction<ActivityStatus[]>>;
  selectedTypes: ActivityType[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<ActivityType[]>>;
  activities: ActivityWithRequestData[];
  toggleHiddenColumns: (column: string) => void;
  tableHiddenCols: string[];
  isActivitySelected: boolean;
};

const SchedulingHeader = ({
  selectedStatuses: activityStatus,
  setActivityStatus,
  selectedTypes: activityType,
  setSelectedTypes: setActivityType,
  activities,
  toggleHiddenColumns,
  tableHiddenCols,
  isActivitySelected,
}: SchedulingHeaderProps) => {
  const {
    batchSubmitUpdateActivities,
    isSaveChangesButtonEnabled,
    refetchActivities,
    isUpdatingActivities,
    viewMode,
    setViewMode,
    fromToDate,
    setFromToDate,
    shouldDisableActivityUpdate: shouldDisableSaveChangesBtn,
  } = useActivities();

  const selectColumns: { id: string; value: string }[] = useMemo(() => {
    return getLeafColumnIds(
      mergeObjects(activities) ?? {},
      EXCLUDED_COLUMNS
    ).map((c, i) => ({
      id: String(i),
      value: c,
    }));
  }, [activities]);

  const { sendInfo } = useAnalytics();
  return (
    <div className={s.container}>
      <h1>Activities</h1>
      <div
        className={classNames({
          'flex w-fit gap-12 items-center ml-auto':
            !isActivitySelected && viewMode === 'table',
          [s.filtersWrapper]: viewMode !== 'table',
          'grid shadow-none grid-rows-2 ml-auto gap-1':
            viewMode === 'table' && isActivitySelected,
        })}
      >
        <div className="flex items-center">
          {viewMode === 'table' && (
            <Tooltip
              content="You must chose a failure reason for CANCELLED and FAILED activities"
              isDisabled={!shouldDisableSaveChangesBtn}
            >
              <CustomButton
                intent="primary"
                onPress={async () => {
                  await batchSubmitUpdateActivities();
                }}
                icon="Save"
                size="base"
                loading={isUpdatingActivities}
                isDisabled={
                  (!isUpdatingActivities && !isSaveChangesButtonEnabled) ||
                  shouldDisableSaveChangesBtn
                }
              />
            </Tooltip>
          )}
          {viewMode === 'table' && (
            <div className={'w-[440px]'}>
              <MultiSelect
                isDismissible={true}
                splitItemsDelimiter="."
                fill
                defaultSelectedKeys={selectColumns
                  .filter((c) => {
                    return !tableHiddenCols.includes(c.value);
                  })
                  .map((c) => c.id)}
                items={selectColumns}
                label="Cols"
                onSelectionChange={(item) => {
                  toggleHiddenColumns(item.value as string);
                }}
                onRemove={(item) => {
                  toggleHiddenColumns(item.value as string);
                }}
              />
            </div>
          )}
        </div>

        <div className="flex items-center gap-3">
          <Button
            aria-label="calendar button"
            name="calendar"
            icon="calendar"
            onClick={() => {
              if (viewMode === 'calendar') {
                setViewMode('list');
                sendInfo({
                  action: 'click',
                  item: 'Calendar view button',
                  type: 'Calendar view close',
                });
              } else {
                setViewMode('calendar');
                sendInfo({
                  action: 'click',
                  item: 'Calendar view button',
                  type: 'Calendar view open',
                });
              }
            }}
            active={viewMode === 'calendar'}
          />
          <Button
            aria-label="table button"
            name="table"
            icon="panel-table"
            onClick={() => {
              if (viewMode === 'table') {
                setViewMode('list');
                sendInfo({
                  action: 'click',
                  item: 'Table view button',
                  type: 'Table view close',
                });
              } else {
                setViewMode('table');
                sendInfo({
                  action: 'click',
                  item: 'Table view button',
                  type: 'Table view open',
                });
              }
            }}
            active={viewMode === 'table'}
          />
          <SchedulingFilter
            setSelectedStatuses={setActivityStatus}
            selectedStatuses={activityStatus}
            selectedTypes={activityType}
            setSelectedTypes={setActivityType}
          />

          <Button
            aria-label="refresh activities"
            name="refresh"
            icon="refresh"
            onClick={async () => {
              await refetchActivities();
            }}
          />
          <DateRangeInput
            formatDate={toFormattedDate}
            parseDate={(str) => moment(str, DATE_FORMAT).toDate()}
            popoverProps={{
              position: 'bottom',
            }}
            value={[fromToDate.from, fromToDate.to]}
            onChange={(value) =>
              setFromToDate({ from: value[0], to: value[1] })
            }
            shortcuts={false}
            startInputProps={{ className: s.datePicker }}
            endInputProps={{ className: s.datePicker }}
            maxDate={moment().endOf('year').add(5, 'years').toDate()}
          />
        </div>
      </div>
    </div>
  );
};

export default SchedulingHeader;
