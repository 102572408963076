import ReactDOM from 'react-dom';
import React from 'react';

export function renderContextMenuButton(controlDiv: Element): void {
  const buttonUI = (
    <button
      title="Click to show context menu"
      type="button"
      className="bp4-button bp4-icon-menu context-menu"
    />
  );
  ReactDOM.render(buttonUI, controlDiv);
}
