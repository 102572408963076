import * as Sentry from '@sentry/react';

import s from './styles.module.scss';

export interface IProps {
  children: JSX.Element;
}

function FallbackComponent() {
  return <pre className={s.displayError}>Something went wrong.</pre>;
}

const CommandResponseErrorBoundary = (props: IProps) => (
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    {props.children}
  </Sentry.ErrorBoundary>
);

export default CommandResponseErrorBoundary;
