import type { IAstrumState } from '../../../../constants/austrum/interfaces';
import {
  ASTRUM_RESET,
  ASTRUM_UPDATE,
  defaultAustrum,
} from '../../../../constants/austrum/constant';
import type { IAstrumActionTypes } from '../../../../constants/austrum/actionTypes';

const initialState: IAstrumState = {
  ['current']: defaultAustrum,
};

export default function astrumReducer(
  state: IAstrumState = initialState,
  action: IAstrumActionTypes
): IAstrumState {
  switch (action.type) {
    case ASTRUM_UPDATE: {
      return {
        ...state,
        ['current']: action.astrum,
        [action.key]: action.astrum,
      };
    }
    case ASTRUM_RESET: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}
