// Warning: This will have to be updated in Front-end URL changes
const GRAFANA_BASE_URL = '/ops/telemetry/explore?orgId=1&left=';
const QUOTE_REGEX = /"/g;

export const getGrafanaUrlForAlert = (expression: string | undefined) => {
  if (!expression) {
    return undefined;
  }

  const grafanaOptions =
    '["now-1h","now","victoriametrics",{"expr":"' +
    expression.replace(QUOTE_REGEX, "'") +
    '"},{"mode":"Metrics"},{"ui":[true,true,true,"none"]}]';

  const encoded = GRAFANA_BASE_URL + encodeURIComponent(grafanaOptions);

  return encoded;
};

export const getExpressionsFromExpression = (expression: string) => {
  const blocks = expression.split(' ');

  const isSimpleExpression = blocks.length === 3;
  if (isSimpleExpression) {
    return [blocks[0], blocks[2]];
  } else {
    return undefined;
  }
};

export const addCommentsToExpressions = (expressions: string[] | undefined) => {
  if (expressions && expressions.length === 2) {
    const part1IsNaN = isNaN(parseInt(expressions?.[0]));
    const part2IsNaN = isNaN(parseInt(expressions?.[1]));

    if (part1IsNaN && part2IsNaN) {
      return expressions;
    }

    if (!part1IsNaN && !part2IsNaN) {
      return expressions;
    }

    if (!part1IsNaN && part2IsNaN) {
      return [
        expressions[0] + ' # Alert threshold',
        expressions[1] + ' # Metric',
      ];
    }

    if (part1IsNaN && !part2IsNaN) {
      return [
        expressions[0] + ' # Metric',
        expressions[1] + ' # Alert threshold',
      ];
    }
  }

  return expressions;
};

export const getBonusGrafanaUrlForAlert = (expression: string) => {
  let expressions = getExpressionsFromExpression(
    expression.replace(QUOTE_REGEX, "'")
  );
  expressions = addCommentsToExpressions(expressions);

  if (expressions) {
    const grafanaOptions =
      '["now-1h","now","victoriametrics",{"expr":"' +
      expressions[0] +
      '"},{"expr":"' +
      expressions[1] +
      '"},{"mode":"Metrics"},{"ui":[true,true,true,"none"]}]';

    const encoded = GRAFANA_BASE_URL + encodeURIComponent(grafanaOptions);

    return encoded;
  }
  return undefined;
};
