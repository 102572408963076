import { useEffect, useRef } from 'react';

export const usePreviousState = <T>(state: T) => {
  const prev: React.MutableRefObject<T | null> = useRef<T>(null);

  useEffect(() => {
    prev.current = state;
  }, [state]);

  return prev.current;
};
