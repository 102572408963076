import type { IconName } from '@blueprintjs/core';
import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';
import type { MaybeElement } from '@blueprintjs/core/src/common/props';
import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../pages/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IMapWrapper } from '../../../../declarations/mapDeclarations/Map';
import type { ReactElement } from 'react';
import React from 'react';
import type { IAnyKey } from '../../../../utils/getFormData';

export interface IContextMenu {
  text: string;
  subItems: IContextMenuSub[];
}

export interface IContextMenuSub {
  text: string;
  icon: IconName | string;
  clickEvent?: (event: React.MouseEvent<HTMLElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.Component | any;
  subItems?: IContextMenuSub[];
  childrenProps?: object;
}

export interface IMenuHandler {
  fn: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg: any[];
  dispatch: boolean;
}

export const addContextMenu =
  (
    map: IMapWrapper,
    menuHandlers: IMenuHandler[],
    collapse: false,
    googleMouseEvent: google.maps.MouseEvent & IAnyKey
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, store) => {
    if (map?.drawingManager?.getDrawingMode()) {
      map.drawingManager.isGroundStation = false;
      map.drawingManager.setDrawingMode(null);
      return;
    }
    const { popUp } = store();
    if (popUp.visible) {
      return;
    }

    let e = null;
    //NB: google map types doesnt have Mouse Event with ClientX|ClientY
    try {
      for (const key in googleMouseEvent) {
        if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          googleMouseEvent[key].clientX !== undefined &&
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          googleMouseEvent[key].clientY
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          e = googleMouseEvent[key];
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Event type: ', googleMouseEvent);
    }
    if (!e) {
      e = googleMouseEvent as unknown as MouseEvent;
    }
    const dispatchedMenuHandlers: IContextMenu[] = menuHandlers.map(
      (h): IContextMenu => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
        return h.dispatch ? dispatch(h.fn(...h.arg)) : h.fn(...h.arg);
      }
    );
    // dispatchedMenuHandlers = dispatchedMenuHandlers.map(item => {
    //   if (dispatchedMenuHandlers.length === 1) {
    //     return { ...item.subItems };
    //   }
    //   return item;
    // });
    const menuItems = dispatchedMenuHandlers.map((item): ReactElement => {
      const subItems = item.subItems.map((sItem, sIndex): ReactElement => {
        const icon = sItem.icon as IconName | MaybeElement;
        return (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={sIndex}
            icon={icon}
            text={sItem.text}
            onClick={sItem.clickEvent}
          >
            {sItem.subItems !== undefined
              ? sItem.subItems.map((s) => {
                  const icn = s.icon as IconName | MaybeElement;
                  return (
                    <MenuItem
                      key={s.text}
                      icon={icn}
                      text={s.text}
                      onClick={s.clickEvent}
                    />
                  );
                })
              : null}
            {/*{sItem.children ? <Children {...sItem.childrenProps} /> : null}*/}
          </MenuItem>
        );
      });
      if (collapse && dispatchedMenuHandlers.length === 1) {
        return <>{subItems}</>;
      }
      return (
        <MenuItem key={item.text} text={item.text}>
          {subItems}
        </MenuItem>
      );
    });
    ContextMenu.show(<Menu>{menuItems}</Menu>, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      left: e.clientX as number,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      top: e.clientY as number,
    });
  };
