import React from 'react';
import { useDropzone } from 'react-dropzone';
import s from './index.module.scss';

interface IProps {
  children: React.ReactNode;
  handleDropFile: (file: unknown) => void;
  disabled: boolean;
}

const FileDropzone = (props: IProps) => {
  const onDrop = (acceptedFiles: unknown) => {
    props.handleDropFile(acceptedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handlePreventClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const activeStyles = isDragActive ? s.activeDropzone : s.inactiveDropzone;

  if (props.disabled) return <>{props.children}</>;

  return (
    <div
      role="presentation"
      className={`${s.dropzoneStorage} ${activeStyles}`}
      {...getRootProps()}
      onClick={handlePreventClick}
    >
      <input {...getInputProps()} />
      {props.children}
    </div>
  );
};

export default FileDropzone;
