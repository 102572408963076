import { Tooltip } from 'opencosmos-ui';
import { useState } from 'react';
import useActiveSession from 'services/api/portal/administration/hook/useActiveSession';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

export interface IProps {
  mission?: string | number;
}

function ActiveSessions({ mission }: IProps) {
  const { activeSessions, fetchActiveSessions } = useActiveSession(
    mission ?? ''
  );
  const [canFetchActiveSessions, setCanFetchActiveSessions] = useState(true);

  const { sendInfo } = useAnalytics();

  const onMouseEnter = () => {
    sendInfo({
      type: 'Active session hover',
      action: 'Hover',
      item: 'Active session bubble',
      module: 'OPS',
    });
    if (!canFetchActiveSessions) return;
    setCanFetchActiveSessions(false);
    void fetchActiveSessions();
  };

  const onMouseLeave = () => {
    if (canFetchActiveSessions) return;
    setCanFetchActiveSessions(true);
  };

  if (activeSessions.length === 0) {
    return (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        No current users
      </div>
    );
  }

  return (
    <div
      className="flex items-center relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {activeSessions.map((session, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`active-session-${i}`}
          className="h-10 w-10 absolute"
          style={{ right: i * 25 }}
        >
          <Tooltip
            content={
              <div className="flex flex-col gap-2 text-sm">
                <div className="flex items-center gap-2">
                  {typeof session.user.pictureUrl === 'string' ? (
                    <img
                      src={session.user.pictureUrl}
                      className="h-8 w-8 rounded-full"
                    />
                  ) : (
                    <div className="flex h-8 w-8 rounded-full items-center justify-center">
                      {session.user.pictureUrl}
                    </div>
                  )}
                  <span>{session.user.name}</span>
                </div>

                <div className="flex items-center justify-between">
                  <span>Started at {session.startDate()}</span>
                </div>

                <div className="flex items-center justify-between">
                  <span>
                    {session.timeSinceLastActivity()} since last activity
                  </span>
                </div>
              </div>
            }
            placement="bottom"
          >
            {typeof session.user.pictureUrl === 'string' ? (
              <img
                src={session.user.pictureUrl}
                className="h-10 w-10 rounded-full"
              />
            ) : (
              <div className="flex h-10 w-10 rounded-full items-center justify-center bg-neutral-400">
                {session.user.pictureUrl}
              </div>
            )}
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

export default ActiveSessions;
