import React from 'react';
import InfoCard from '_molecules/InfoCard/InfoCard';
import { SearchSteps, Steps } from 'datacosmos/components/Tasking/helpers';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import classNames from 'classnames';

type TaskingTypeProps = {
  setSearchSteps: (value: React.SetStateAction<SearchSteps[]>) => void;
  setSwitchStep: (step: Steps) => void;
  switchStep: Steps;
  disabled: boolean;
};

export const TaskingType = ({
  setSearchSteps,
  setSwitchStep,
  switchStep,
  disabled,
}: TaskingTypeProps) => {
  const { translate } = useLocalisation();
  return (
    <div className="flex items-center color-item mb-2">
      <span className="ml-2 mr-4 w-full font-bold">
        {translate('datacosmos.tasking.new.type')}
      </span>
      <InfoCard
        showInfoOn="hover"
        absoluteInfoPosition
        cardContent={
          <div>{translate('datacosmos.tasking.new.manual.title')}</div>
        }
        info={{
          text: translate('datacosmos.tasking.new.manual.description'),
          iconSize: 16,
        }}
        onClick={() => {
          if (disabled) {
            return;
          }
          setSearchSteps((prev) => [
            ...prev,
            SearchSteps.SelectTaskingDateAndInstruments,
          ]);
          setSwitchStep(Steps.ManualTasking);
        }}
        className={classNames('text-sm w-full', {
          'text-item-contrast-inactive dark:text-item-dark-contrast-inactive !cursor-not-allowed':
            disabled,
          'bg-item-selected dark:bg-item-dark-selected':
            switchStep === Steps.ManualTasking,
        })}
      />
      <InfoCard
        showInfoOn="hover"
        absoluteInfoPosition
        cardContent={
          <div>{translate('datacosmos.tasking.new.automated.title')}</div>
        }
        info={{
          text: translate('datacosmos.tasking.new.automated.description'),
          iconSize: 16,
        }}
        onClick={() => {
          if (disabled) {
            return;
          }
          setSearchSteps((prev) => [
            ...prev,
            SearchSteps.SelectTaskingDateAndInstruments,
          ]);
          setSwitchStep(Steps.AutomatedTasking);
        }}
        className={classNames('text-sm w-full', {
          'text-item-contrast-inactive dark:text-item-dark-contrast-inactive !cursor-not-allowed':
            disabled,
          'bg-item-selected dark:bg-item-dark-selected':
            switchStep === Steps.AutomatedTasking,
        })}
      />
    </div>
  );
};
