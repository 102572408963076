import React, { useEffect, useState } from 'react';
import type { Moment, Duration } from 'moment';
import moment from 'moment';
import { find, get, pipe } from 'lodash/fp';
import { Link } from 'react-router-dom';
import { Alignment, Classes, Icon, Navbar, Spinner } from '@blueprintjs/core';
import { isNil } from 'lodash';

import COLORS from 'constants/colors';
import Clock from 'components/Clock';
import { MODE_COLORS_CONDITION } from 'constants/timer/constants';
import { useMission } from 'services/Missions';
import type { IUsePassState } from 'services/api/portal/groundStations/usePassState';
import { usePassState } from 'services/api/portal/groundStations/usePassState';

import Timer from './index';
import sTimer from './styles.module.scss';
import s from './NavbarTimers.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Tooltip } from 'opencosmos-ui';

interface IProps {
  showLinkButton?: boolean;
}

interface INavbarTimers {
  center: React.ReactNode;
  left: React.ReactNode;
  right: React.ReactNode;
}

interface IColorCondition {
  timeInterval: { min: number; max: number };
  color: string;
}

const changeColor = (duration: Duration) => (condition: IColorCondition) => {
  const {
    timeInterval: { min, max },
  } = condition;
  const hours = duration.asHours();
  const negative = hours < 0;
  const minutes = duration.minutes() * (negative ? -1 : 1);
  const hour = duration.hours();

  return minutes < max && minutes >= min && !hour;
};

// Inject getCurrentTime for easier time mocking in tests
export const useNavbarTimers = (
  usePass: IUsePassState,
  getCurrentTime: () => Moment
) => {
  const { passState, isFetching, getPassState } = usePass;

  const [color, setColor] = useState<string>();
  const [showButtonIntent, setShowButtonIntent] = useState<string>();

  const [nextPass, setNextPass] = useState<Moment>();
  const [lastPass, setLastPass] = useState<Moment>();
  const [inPassTimeLeft, setInPassTimeLeft] = useState<Moment>();
  const [inPassTimeUsed, setInPassTimeUsed] = useState<Moment>();

  const setInPassTime = (
    passStart: string | undefined,
    passEnd: string | undefined
  ) => {
    setInPassTimeLeft(moment.utc(passEnd));
    setInPassTimeUsed(moment.utc(passStart));
    setNextPass(undefined);
    setColor(COLORS.MAIN_BLACK);
    setShowButtonIntent(Classes.INTENT_PRIMARY);
  };

  useEffect(() => {
    if (!passState) {
      return;
    }
    if (passState.ongoing) {
      setInPassTime(
        passState.ongoing.window.start,
        passState.ongoing.window.end
      );
      return;
    }

    if (passState.upcoming) {
      setNextPass(moment.utc(passState.upcoming.window.start));
    }

    if (passState.completed) {
      setLastPass(moment.utc(passState.completed.window.end));
    }
    setInPassTimeLeft(undefined);
    setColor(undefined);
    setShowButtonIntent(undefined);
  }, [passState]);

  const handleInPassDurationChange = () => {
    if (isFetching) {
      return;
    }
    if (isNil(passState.upcoming)) {
      return;
    }
    if (getCurrentTime().isAfter(passState.upcoming.window.end)) {
      getPassState();
      setInPassTimeLeft(undefined);
      setColor(undefined);
      setShowButtonIntent(undefined);
    }
  };

  const updateDurationTextColor = (duration: Duration) => {
    const inRangeColor = pipe(
      find(changeColor(duration)),
      get('color')
    )(MODE_COLORS_CONDITION);

    if (inRangeColor && !color) {
      setColor(inRangeColor);
      setShowButtonIntent(Classes.INTENT_DANGER);
    }
  };

  const updateDurationIfInPass = () => {
    const inPass = getCurrentTime().isBetween(
      moment.utc(passState.upcoming?.window.start),
      moment.utc(passState.upcoming?.window.end)
    );
    if (inPass) {
      setInPassTime(
        passState.upcoming?.window.start,
        passState.upcoming?.window.end
      );
      return;
    }
  };

  const updateNextPassIfCurrentOneFinished = () => {
    const passFinished = getCurrentTime().isAfter(
      passState.upcoming?.window.end
    );
    if (passFinished) {
      getPassState();
    }
  };

  const handleNextPassDurationChange = (duration: Duration) => {
    if (isFetching) {
      return;
    }

    if (isNil(passState.upcoming)) {
      return;
    }

    updateDurationTextColor(duration);
    updateDurationIfInPass();
    updateNextPassIfCurrentOneFinished();
  };

  return {
    inPassTimeLeft,
    inPassTimeUsed,
    nextPass,
    lastPass,
    color,
    showButtonIntent,
    handleInPassDurationChange,
    handleNextPassDurationChange,
  };
};

const NavbarTimers = (props: IProps) => {
  const { currentMissionId } = useMission();

  const passState = usePassState(currentMissionId ?? 0);
  const { isFetching, getPassState } = passState;
  const { sendInfo } = useAnalytics();

  const {
    inPassTimeLeft,
    inPassTimeUsed,
    nextPass,
    lastPass,
    color,
    showButtonIntent,
    handleInPassDurationChange,
    handleNextPassDurationChange,
    //@ts-expect-error
  } = useNavbarTimers(passState, moment.utc);

  const renderEmptyTimer = (label: string) => (
    <div className={sTimer.container}>
      <div className={sTimer.timer}>-- : -- : --</div>
      <div className={sTimer.date}>{label} </div>
    </div>
  );

  const renderNavbar = ({ left, center, right }: INavbarTimers) => {
    return (
      <>
        <div
          tabIndex={0}
          onKeyDown={() => {
            sendInfo({
              type: 'Keyboard keydown',
              action: 'Keydown',
              item: 'Pass end timer button',
              module: 'OPS',
            });
            void getPassState();
          }}
          role="button"
          className={[s.headerTimer, s.headerLeft, s.headerAction].join(' ')}
          onClick={() => {
            sendInfo({
              type: 'Mouse onclick',
              action: 'Click',
              item: 'Pass end timer button',
              module: 'OPS',
            });
            void getPassState();
          }}
        >
          {left}
        </div>
        <div className={[s.headerTimer, s.headerCenter].join(' ')}>
          {center}
        </div>
        <div
          tabIndex={0}
          onKeyDown={() => {
            sendInfo({
              type: 'Keyboard keydown',
              action: 'Keydown',
              item: 'Next pass timer button',
              module: 'OPS',
            });
            void getPassState();
          }}
          role="button"
          style={{ color }}
          className={[s.headerTimer, s.headerAction].join(' ')}
          onClick={() => {
            sendInfo({
              type: 'Mouse onclick',
              action: 'Click',
              item: 'Next pass timer button',
              module: 'OPS',
            });
            void getPassState();
          }}
        >
          {right}
        </div>
      </>
    );
  };

  const renderLinkButton = () =>
    props.showLinkButton &&
    showButtonIntent && (
      <div className={s.actionButton}>
        <Link
          className={[Classes.BUTTON, showButtonIntent, s.headerButton].join(
            ' '
          )}
          to={currentMissionId ? `/ops/mission/${currentMissionId}/rti` : '/'}
        >
          <span>Go to RTI</span>
          <Icon icon="circle-arrow-right" />
        </Link>
      </div>
    );

  if ((!nextPass && !inPassTimeLeft) || isFetching) {
    return renderNavbar({
      left: isFetching ? (
        <Spinner className={s.headerSpinner} size={20} />
      ) : (
        renderEmptyTimer('Pass end')
      ),
      center: <Clock />,
      right: isFetching ? (
        <Spinner className={s.headerSpinner} size={20} />
      ) : (
        renderEmptyTimer('Next pass')
      ),
    });
  }

  if (inPassTimeLeft) {
    return (
      <Navbar.Group key="center" align={Alignment.CENTER}>
        {renderNavbar({
          left: (
            <Tooltip content="Click to refresh">
              <Timer label="Pass began" target={inPassTimeUsed ?? moment()} />
            </Tooltip>
          ),
          center: <Clock />,
          right: inPassTimeLeft && (
            <Tooltip content="Click to refresh">
              <Timer
                label="Pass end"
                target={inPassTimeLeft}
                handleTimerOnCertainTime={handleInPassDurationChange}
              />
            </Tooltip>
          ),
        })}
        {renderLinkButton()}
      </Navbar.Group>
    );
  }

  return (
    <Navbar.Group key="center" align={Alignment.CENTER}>
      {renderNavbar({
        left: (
          <Tooltip content="Click to refresh">
            <Timer label="Last pass" target={lastPass ?? moment()} />
          </Tooltip>
        ),
        center: <Clock />,
        right: nextPass && (
          <Tooltip content="Click to refresh">
            <Timer
              label="Next pass"
              target={nextPass}
              handleTimerOnCertainTime={handleNextPassDurationChange}
            />
          </Tooltip>
        ),
      })}
    </Navbar.Group>
  );
};

export default NavbarTimers;
