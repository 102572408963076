import type { Layer } from 'datacosmos/entities/layer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useCallback, useState } from 'react';

/**
 * useMapLayerStorage provides functions to save and load the currently added layers
 */
export const useMapLayerStorage = () => {
  const { layers, setLayers } = useMapLayers();

  const [storedLayers, setStoredLayers] = useState<Layer[]>([]);

  /**
   * saveCurrentLayers saves the currently added layers to the storedLayers state
   */
  const saveCurrentLayers = useCallback(() => {
    setStoredLayers(layers);
  }, [layers]);

  /**
   * loadSavedLayers loads the layers that were saved in the storedLayers state
   */
  const loadSavedLayers = useCallback(() => {
    if (storedLayers.length === 0) {
      return;
    }
    setLayers(storedLayers);
  }, [setLayers, storedLayers]);

  return { saveCurrentLayers, loadSavedLayers };
};
