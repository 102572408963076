import type { ICOGSingleBandMetadataStatistics } from 'datacosmos/services/tilingApi';
import { DetailsItem } from 'pages/ops/shared/components/DetailsItem';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  statistics: ICOGSingleBandMetadataStatistics;
  className?: string;
};

const StatisticsSummary = ({ statistics, className }: Props) => {
  const { translate } = useLocalisation();
  return (
    <div className={className}>
      <h3 className="text-data-dark border-b dark:text-data-text ml-1">
        {translate('datacosmos.catalogAndItems.metadata.summaryStatistics')}
      </h3>

      <div className="grid grid-flow-col auto-cols-max text-sm gap-x-2 overflow-auto">
        <div className="pr-2 border-r whitespace-nowrap">
          <DetailsItem
            title={translate(
              'datacosmos.catalogAndItems.metadata.minPixelValue'
            )}
            value={String(statistics.min) ?? 'N/A'}
            applyFlexStyle
          />
          <DetailsItem
            title={translate(
              'datacosmos.catalogAndItems.metadata.maxPixelValue'
            )}
            value={String(statistics.max) ?? 'N/A'}
            applyFlexStyle
          />
        </div>

        <div className="pr-2 border-r whitespace-nowrap">
          <DetailsItem
            title={translate('datacosmos.catalogAndItems.metadata.meanValue')}
            value={statistics.mean?.toFixed(2) ?? 'N/A'}
            applyFlexStyle
          />
          <DetailsItem
            title={translate('datacosmos.catalogAndItems.metadata.stdValue')}
            value={statistics.std?.toFixed(2) ?? 'N/A'}
            applyFlexStyle
          />
          <DetailsItem
            title={translate('datacosmos.catalogAndItems.metadata.variance')}
            value={(statistics.std * statistics.std)?.toFixed(2) ?? 'N/A'}
            applyFlexStyle
          />
        </div>

        <div className="pr-2 border-r whitespace-nowrap">
          <DetailsItem
            title={translate(
              'datacosmos.catalogAndItems.metadata.percentile25'
            )}
            value={String(statistics.percentile_25) ?? 'N/A'}
            applyFlexStyle
          />
          <DetailsItem
            title={translate(
              'datacosmos.catalogAndItems.metadata.percentile75'
            )}
            value={String(statistics.percentile_75) ?? 'N/A'}
            applyFlexStyle
          />
        </div>
      </div>
    </div>
  );
};

export default StatisticsSummary;
