import { useEffect, useRef, useState } from 'react';
import { Classes, Dialog, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { SearchInput, Icon } from 'opencosmos-ui';
import { filter, pipe, sortBy } from 'lodash/fp';
import classNames from 'classnames';
import s from './DatacosmosSelectDialog.module.scss';

export type DialogItem = {
  name: string;
  id: string;
};

export interface IItem {
  id: string | number;
}

export type DialogListRenderer<T extends IItem> = (
  item: T,
  selectItem: () => void
) => JSX.Element | null;

export type DialogItemDetailsRenderer<T extends IItem> = (
  item: T,
  onClickHandler: (item: T) => void
) => React.ReactNode | null;

interface DatacosmosSelectDialogProps<T extends IItem> {
  items: T[];
  listRenderer: DialogListRenderer<T>;
  listItemClickHandler: (item: T) => void;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  handleAddItemClick?: () => void;
  handleViewAllClick?: () => void;
  addItemTitle?: string;
  viewAllTitle?: string;
  title: string;
  isCloseButtonShown?: boolean;
  canOutsideClickClose?: boolean;
  canEscapeKeyClose?: boolean;
  handleItemDetailsRender: DialogItemDetailsRenderer<T>;
  selectedItem: T | undefined;
  setSelectedItem: (item: T | undefined) => void;
  sortListBy: keyof T;
  filterListBy?: keyof T;
  isContentLoading: boolean;
}

const DatacosmosSelectDialog = <T extends IItem>({
  title,
  items,
  listRenderer,
  listItemClickHandler,
  isOpen,
  setIsOpen,
  handleAddItemClick,
  handleItemDetailsRender,
  selectedItem,
  setSelectedItem,
  sortListBy,
  filterListBy,
  addItemTitle,
  isContentLoading,
  handleViewAllClick,
  viewAllTitle,
  isCloseButtonShown = true,
  canOutsideClickClose = true,
  canEscapeKeyClose = true,
}: DatacosmosSelectDialogProps<T>) => {
  const [filterValue, setFilterValue] = useState<string>('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  // Workaround for focusing the search input when the App Tour is displayed
  // on the background (and keeps stealing focus)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    searchInputRef.current?.focus();
  }, [items]);
  const filteredItems: T[] = pipe(
    filterValue && filterListBy
      ? filter((item: T) =>
          (item[filterListBy] as string)
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        )
      : (arr: T[]) => arr,
    sortBy((item: T) => (item[sortListBy] as string).toLowerCase())
  )(items);
  return (
    <Dialog
      autoFocus={false}
      enforceFocus={false}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface h-[95vh]"
      style={{
        width: selectedItem ? '70%' : '',
      }}
      canOutsideClickClose={canOutsideClickClose}
      canEscapeKeyClose={canEscapeKeyClose}
    >
      <div className="bp4-dialog-header dark:bg-surface-dark  bg-surface">
        <h5 className="dark:text-item-dark-contrast bp4-heading">{title}</h5>
        {isCloseButtonShown && (
          <button
            aria-label="Close"
            className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
            onClick={() => setIsOpen(false)}
          />
        )}
      </div>

      <div
        className="grid w-full"
        style={{
          gridTemplateColumns: selectedItem ? '2fr 2fr' : '2fr',
        }}
      >
        <div
          className={[Classes.DIALOG_BODY, s.dialogContainer, 'h-[85vh]'].join(
            ' '
          )}
          data-testid="datacosmos-select-modal"
        >
          <div
            className={[
              s.dialogListWrapper,
              'overflow-auto scrollbar-light dark:scrollbar-dark',
            ].join(' ')}
            data-testid="modal-items-container"
          >
            {filterListBy && (
              <SearchInput
                fullWidth
                autoFocus
                ref={searchInputRef}
                onChange={setFilterValue}
                searchButtonRenderer={() => <Icon icon="MagnifyingGlass" />}
                data-testid="modal-items-search"
              />
            )}
            {isContentLoading ? (
              <Spinner />
            ) : (
              filteredItems.map((item) => (
                <div
                  className={classNames(
                    'hover:bg-item-selected hover:text-accent-900',
                    'dark:hover:bg-item-dark-selected dark:hover:text-item-dark-hover',
                    'cursor-pointer'
                  )}
                  data-testid="modal-item"
                  key={item.id}
                >
                  {listRenderer(item, () => setSelectedItem(item))}
                </div>
              ))
            )}
          </div>
          <div className={[Classes.DIALOG_FOOTER, s.dialogFooter].join(' ')}>
            {handleAddItemClick && (
              <div
                className={s.dialogItemWrapper}
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={handleAddItemClick}
                data-testid="modal-add-item-button"
              >
                {addItemTitle ? addItemTitle : 'Add item'}
                <Icon icon={IconNames.PLUS} />
              </div>
            )}
            {handleViewAllClick && Boolean(filteredItems?.length) && (
              <div
                className={`${s.dialogItemWrapper} flex items-center justify-center gap-1`}
                onClick={handleViewAllClick}
              >
                {viewAllTitle}
                <Icon icon={IconNames.ChevronRight} />
              </div>
            )}
          </div>
        </div>
        {selectedItem && (
          <div>
            {handleItemDetailsRender(selectedItem, listItemClickHandler)}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DatacosmosSelectDialog;
