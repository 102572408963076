import type { ISeriesData } from 'components/charts/Chart';
import { MAXIMUM_POINT_IN_PLOTS } from 'env';

export const CHART_TYPE = {
  LINE_SQUARE: 'line',
  LINE_INTERPOLATION: 'line-smooth',
  SCATTER: 'scatter',
};

export const chartTypeHandler = [
  {
    idForSelect: CHART_TYPE.LINE_SQUARE,
    title: 'Line',
    type: 'line',
    smooth: false,
  },
  {
    idForSelect: CHART_TYPE.LINE_INTERPOLATION,
    title: 'Line [smooth]',
    type: 'line',
    smooth: true,
  },
  {
    idForSelect: CHART_TYPE.SCATTER,
    title: 'Scatter',
    type: 'scatter',
    smooth: false,
  },
];

export const GLOBAL_LIMIT = MAXIMUM_POINT_IN_PLOTS
  ? Number(MAXIMUM_POINT_IN_PLOTS)
  : 1000;
export const debounceDelay = 100;

/**
 * Symbol style
 * https://echarts.apache.org/en/option.html#series-scatter.symbol
 */
export const symbolStyle: ISeriesData = {
  symbolSize: 5,
  symbol: 'circle',
};

/**
 * Color schema
 * https://echarts.apache.org/en/option.html#color
 */
//TODO set color order
export const color = [
  '#D13913',
  '#D99E0B',
  '#8F398F',
  '#00B3A4',
  '#DB2C6F',
  '#9BBF30',
  '#96622D',
  '#7157D9',
  '#c23531',
  '#D99E0B',
  '#2f4554',
  '#61a0a8',
  '#d48265',
  '#91c7ae',
  '#749f83',
  '#ca8622',
  '#bda29a',
  '#6e7074',
  '#546570',
  '#c4ccd3',
  '#32c200',
  '#540411',
  '#090da8',
  '#d400c0',
  '#c7c700',
  '#009f89',
  '#ca003f',
  '#0077bd',
  '#000000',
  '#ff6b57',
  '#8ed300',
  '#2965CC',
  '#29A634',
];
