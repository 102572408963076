import axios from 'axios';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { REQUEST_ERROR } from 'constants/errors';
import type { ICreateRoleAttachment, IRoleAttachment } from './types';

export default (url: string, token: string) => {
  const createRoleAttachment = async (
    roleAttachment: ICreateRoleAttachment,
    parent_role_id: string
  ): Promise<IRoleAttachment | undefined> => {
    try {
      const {
        data: { data },
      } = (await axios.post(
        `${url}/roles/${parent_role_id}/attachments`,
        roleAttachment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )) as { data: { data: IRoleAttachment } };

      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      return undefined;
    }
  };

  const deleteRoleAttachment = async (
    attachmentId: number,
    parentRoleId: number
  ) => {
    try {
      await axios.delete(
        `${url}/roles/${parentRoleId}/attachments/${attachmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
    }
  };

  /**
   * Uses the API to obtain a list of roleAttachments.
   *
   * @param resourceType
   * @returns
   */
  const getRoleAttachments = async (
    parent_role_id: string
  ): Promise<IRoleAttachment[]> => {
    try {
      const {
        data: { data },
      } = (await axios.get(`${url}/roles/${parent_role_id}/attachments`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })) as { data: { data: IRoleAttachment[] } };
      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      return [];
    }
  };

  return {
    getRoleAttachments,
    createRoleAttachment,
    deleteRoleAttachment,
  };
};
