import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'pages/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IPointOfInterest } from 'constants/pointsOfInterest/actionTypes';
import { setPopUp } from 'pages/shared/state/actions/popUp/thunks';
import MarkerForm from 'components/popUp/MarkerForm';
import {
  removePI,
  updatePI,
} from 'pages/shared/state/actions/pointsOfInterest/helpers';
import type { IMarkerWrapper } from 'declarations/mapDeclarations/Marker';
import { setFocus } from 'pages/shared/state/actions/focus/thunks';
import { FocusTypes } from 'constants/focus/actionTypes';

export const updatePointOfInterest =
  (
    pointOfInterest: IMarkerWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, store) => {
    const astrum = store().astrum.current;
    const pointOfInterestData: IPointOfInterest = {
      id: pointOfInterest.id,
      name: pointOfInterest.name ?? '',
      lat: pointOfInterest?.getPosition()?.lat() ?? 0,
      lon: pointOfInterest?.getPosition()?.lng() ?? 0,
      altitude: pointOfInterest.altitude ?? 0,
      elevationAngle: pointOfInterest.elevationAngle ?? 0,
      orderIndex: pointOfInterest.orderIndex ?? 0,
      astrum: astrum.planet,
      satelliteMode: pointOfInterest.satelliteMode,
    };
    dispatch(
      setFocus({
        id: pointOfInterestData.id as string,
        type: FocusTypes.PI_SUB_ID,
        needScroll: true,
        withPopUp: true,
      })
    );
    dispatch(
      setPopUp({
        classNames: ['short-container'],
        component: MarkerForm,
        visible: true,
        data: pointOfInterestData,
        otherProps: {
          type: FocusTypes.PI_SUB_ID,
        },
        functions: {
          onSubmit: (poi: IPointOfInterest): void => dispatch(updatePI(poi)),
          onClose: (): void => {},
        },
      })
    );
  };
export const removePointOfInterest =
  (
    pointOfInterest: IMarkerWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(removePI(pointOfInterest.id));
  };
