import React, { useCallback, useState } from 'react';
import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { Input, ListBoxItem, Select } from 'opencosmos-ui';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { postUnsupervisedClassification } from '_api/sampling/service';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { isGeoreferencedWithGCP } from 'datacosmos/utils/stac';

type Props = {
  app: IApplication;
};

export const UnsupervisedClassificationApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate(
    'datacosmos.applications.unsupervisedClassification.title'
  ),
  description: clientTranslate(
    'datacosmos.applications.unsupervisedClassification.description'
  ),
  inputs: [
    {
      example: '',
      field: 'classes',
    },
    {
      field: 'imageId',
      example: '',
    },
    {
      field: 'algorithm',
      example: '',
    },
  ],
  values: {
    classes: {
      value: null,
      isError: false,
      message: '',
    },
    imageId: {
      value: '',
      isError: false,
      message: '',
    },
    algorithm: {
      value: '',
      isError: false,
      message: '',
    },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.unsupervisedClassification.shortDescription'
  ),
  renderer: (app: IApplication) => <UnsupervisedClassification app={app} />,
  appScreenshotUrl: '',
  tags: [],
};

const UnsupervisedClassification = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { translate } = useLocalisation();

  const { clickedStacLayer } = useClickedStacItem({
    outlineClickedItem: false,
    onImageClick: (img) => {
      const isNotValidImage = isGeoreferencedWithGCP(img.item);
      setInput(
        'imageId',
        img.item.id,
        isNotValidImage,
        isNotValidImage
          ? translate(
              'datacosmos.applications.global.errors.isGeoreferencedWithGCP'
            )
          : ''
      );
    },
  });

  const { currentScenario, fetchCurrentProjectItems } = useProjects();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const setInput = useCallback(
    (
      key: string,
      value: IAPIAppValue['value'],
      isError: boolean,
      message?: string
    ) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError, message: message ?? '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const setValue = (key: string, value: IAPIAppValue['value']) => {
    setInput(key, value, false, '');
  };

  const setError = (key: string, message: string) => {
    setInput(key, '', true, message);
  };

  const inputs = () => {
    return (
      <div className="flex flex-col gap-4 mb-4 mt-4">
        <div>
          <Input
            type="text"
            readOnly
            value={clickedStacLayer?.item.id}
            placeholder={translate(
              'datacosmos.applications.unsupervisedClassification.inputs.fullResImg.placeholder'
            )}
            label={{
              position: 'top',
              text: translate(
                'datacosmos.applications.unsupervisedClassification.inputs.fullResImg.title'
              ),
              wrapLabelText: true,
            }}
          />
          {app.values.imageId.isError && (
            <div>
              <small className="text-warning">
                {app.values.imageId.message}
              </small>
            </div>
          )}
        </div>

        <div>
          <span>
            {translate(
              'datacosmos.applications.unsupervisedClassification.inputs.algorithm.title'
            )}
          </span>
          <Select
            fill
            placeholder={translate(
              'datacosmos.applications.unsupervisedClassification.inputs.algorithm.placeholder'
            )}
            onSelectionChange={(val) => {
              setValue('algorithm', val);
            }}
            selectedKey={
              app.values.algorithm.value
                ? (app.values.algorithm.value as string)
                : ''
            }
          >
            <ListBoxItem id="k_means" key="k_means">
              {translate(
                'datacosmos.applications.unsupervisedClassification.inputs.algorithm.k_means'
              )}
            </ListBoxItem>
            <ListBoxItem
              id="gaussian_mixture_models"
              key="gaussian_mixture_models"
            >
              {translate(
                'datacosmos.applications.unsupervisedClassification.inputs.algorithm.gaussian_mixture_models'
              )}
            </ListBoxItem>
          </Select>
          {app.values.algorithm.isError && (
            <div>
              <small style={{ color: '#ff0000' }}>
                {app.values.algorithm.message}
              </small>
            </div>
          )}
        </div>

        <Input
          type="number"
          value={app.values.classes?.value as number}
          onChange={(e) => setValue('classes', parseInt(e.target.value))}
          step={1}
          min={1}
          label={{
            position: 'top',
            text: translate(
              'datacosmos.applications.unsupervisedClassification.inputs.classes.label'
            ),
          }}
        />

        {app.values.classes?.isError && (
          <div>
            <small className="text-warning">
              {app.values.classes?.message}
            </small>
          </div>
        )}
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          const { value: classes } = app.values.classes ?? {};
          if (typeof classes !== 'number' || classes <= 0) {
            setError(
              'classes',
              translate(
                'datacosmos.applications.unsupervisedClassification.errors.noClasses'
              )
            );
            return;
          }

          setIsSubmitting(true);
          const { data } = await postUnsupervisedClassification({
            params: {
              collection: clickedStacLayer?.item.collection ?? '',
              items: clickedStacLayer?.item.id ?? '',
            },
            body: {
              project_id: currentScenario?.id ?? '',
              classes,
              algorithm: app.values.algorithm.value as string,
            },
          });
          setIsSubmitting(false);

          if (data?.item_url) {
            await fetchCurrentProjectItems();
          }
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmitting}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default UnsupervisedClassification;
