export type KeyCodeLookup = {
  [key: string]: string;
};

/**
 * keyCodeLookup maps the keyCodes, representing physical keyboard keys
 * to the key names.
 */
export const keyCodeLookup: KeyCodeLookup = {
  Escape: "Escape",
  Digit1: "1",
  Digit2: "2",
  Digit3: "3",
  Digit4: "4",
  Digit5: "5",
  Digit6: "6",
  Digit7: "7",
  Digit8: "8",
  Digit9: "9",
  Digit0: "0",
  Minus: "Minus",
  Equal: "Equal",
  Backspace: "Backspace",
  Tab: "Tab",
  KeyQ: "Q",
  KeyW: "W",
  KeyE: "E",
  KeyR: "R",
  KeyT: "T",
  KeyY: "Y",
  KeyU: "U",
  KeyI: "I",
  KeyO: "O",
  KeyP: "P",
  BracketLeft: "BracketLeft",
  BracketRight: "BracketRight",
  Enter: "Enter",
  ControlLeft: "Control",
  KeyA: "A",
  KeyS: "S",
  KeyD: "D",
  KeyF: "F",
  KeyG: "G",
  KeyH: "H",
  KeyJ: "J",
  KeyK: "K",
  KeyL: "L",
  Semicolon: "Semicolon",
  Quote: "Quote",
  Backquote: "Backquote",
  ShiftLeft: "Shift",
  Backslash: "Backslash",
  KeyZ: "Z",
  KeyX: "X",
  KeyC: "C",
  KeyV: "V",
  KeyB: "B",
  KeyN: "N",
  KeyM: "M",
  Comma: "Comma",
  Period: "Period",
  Slash: "Slash",
  ShiftRight: "Shift",
  NumpadMultiply: "NumpadMultiply",
  AltLeft: "Alt",
  Space: "Space",
  CapsLock: "CapsLock",
  F1: "F1",
  F2: "F2",
  F3: "F3",
  F4: "F4",
  F5: "F5",
  F6: "F6",
  F7: "F7",
  F8: "F8",
  F9: "F9",
  F10: "F10",
  NumLock: "NumLock",
  ScrollLock: "ScrollLock",
  Numpad7: "Numpad7",
  Numpad8: "Numpad8",
  Numpad9: "Numpad9",
  NumpadSubtract: "NumpadSubtract",
  Numpad4: "Numpad4",
  Numpad5: "Numpad5",
  Numpad6: "Numpad6",
  NumpadAdd: "NumpadAdd",
  Numpad1: "Numpad1",
  Numpad2: "Numpad2",
  Numpad3: "Numpad3",
  Numpad0: "Numpad0",
  NumpadDecimal: "NumpadDecimal",
  IntlBackslash: "IntlBackslash",
  F11: "F11",
  F12: "F12",
  NumpadEnter: "NumpadEnter",
  ControlRight: "Control",
  NumpadDivide: "NumpadDivide",
  PrintScreen: "PrintScreen",
  AltRight: "Alt",
  Pause: "Pause",
  Home: "Home",
  ArrowUp: "ArrowUp",
  PageUp: "PageUp",
  ArrowLeft: "ArrowLeft",
  ArrowRight: "ArrowRight",
  End: "End",
  ArrowDown: "ArrowDown",
  PageDown: "PageDown",
  Insert: "Insert",
  Delete: "Delete",
  MetaLeft: "Meta",
  MetaRight: "Meta",
  ContextMenu: "ContextMenu",
};
