/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default (value: any, params: any, type: string) => {
  let errorMessage;
  const { min, max } = params;

  if (min && value < min) {
    errorMessage = `Must be ${min as number} or more`;
  }
  if (max && value > max) {
    errorMessage = `Must be ${max as number} or less`;
  }
  if (type === 'integer' && value % 1 !== 0) {
    errorMessage = `Allow any integer values`;
  }

  return errorMessage;
};
