import DefaultLayout from '../ops/shared/components/DefaultLayout';
import NotFound from '../../components/common/NotFound';
import type { ReactElement } from 'react';
import React from 'react';

const NotFoundPage = (): ReactElement => (
  <DefaultLayout centerContent>
    <NotFound />
  </DefaultLayout>
);

export default NotFoundPage;
