import type { InitOptions } from 'i18next';
import translation from './locales/en/translation';
import esTranslation from './locales/es/translation';
import { LANGUAGE } from 'env';

export const resources = {
  en: {
    translation,
  },
  es: {
    translation: esTranslation,
  },
} as const;

const config: InitOptions = {
  lng: `${LANGUAGE}`,
  preload: ['en', 'es'],
  fallbackLng: 'en',
  debug: false, // process.env.NODE_ENV !== 'development',
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: '/i18n/locales/{{lng}}/{{ns}}.ts',
  },
  detection: {
    // order and from where user language should be detected
    order: [
      'querystring',
      'cookie',
      'htmlTag',
      'localStorage',
      'navigator',
      'subdomain',
    ],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 525600, // a year
  },
  resources,
  ns: ['translation', 'esTranslation'],
};

export default config;
