import { useState, useMemo, useEffect, useCallback } from 'react';
import { Button as BPButton, NonIdealState, Pre } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type {
  ICommandHistory,
  IReply,
} from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import { SPECIFIC_RESPONSES_PARSERS } from 'constants/ops/rti/oparate/constants';
import s from './index.module.scss';
import CommandResponseErrorBoundary from 'components/CommandResponseErrorBoundary';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Tooltip, Button } from 'opencosmos-ui';
import { copyToClipBoard } from 'utils/common/CommonUtils';
import {
  AGGREGATABLE_RESPONSE_TYPES,
  getLatestResponse,
  isReplyOutdated,
} from 'utils/replyUtilities';

export const Response = ({
  isShowOriginRes,
  response,
}: {
  isShowOriginRes: boolean;
  response: IReply;
}) => {
  const specificResponseParser = SPECIFIC_RESPONSES_PARSERS.find(
    ({ shouldUse }) => shouldUse(response, response?.type)
  );

  // Remove sequenceId from response to avoid showing it in the UI
  let resNoSequenceId = response;
  if (response.sequenceId) {
    resNoSequenceId = {
      ...response,
      sequenceId: undefined as unknown as number,
    };
  }

  if (!specificResponseParser || isShowOriginRes) {
    const content = JSON.stringify(resNoSequenceId, null, 2);
    return <Pre>{content}</Pre>;
  }

  return (
    <CommandResponseErrorBoundary>
      {specificResponseParser.handler(resNoSequenceId)}
    </CommandResponseErrorBoundary>
  );
};

export type RepliesInfoProps = {
  commandHistory?: ICommandHistory;
};

const RepliesInfo = ({ commandHistory }: RepliesInfoProps) => {
  const [isShowOriginRes, setShowOriginRes] = useState(false);
  const [isReplyCopied, setIsReplyCopied] = useState<boolean>(false);

  const { sendInfo } = useAnalytics();

  const sorted = useMemo(
    () =>
      commandHistory?.responsesContent.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      ),
    [commandHistory]
  );

  const getSortedResponses = useCallback(() => {
    if (!sorted) {
      return [];
    }

    const aggregatableResponses = sorted.filter((sort) =>
      AGGREGATABLE_RESPONSE_TYPES.includes(sort.type)
    );

    const latestTransferResponse = [getLatestResponse(aggregatableResponses)];
    const nonTransferResponses = sorted.filter((sort) => {
      return !AGGREGATABLE_RESPONSE_TYPES.includes(sort.type);
    });

    if (isShowOriginRes) {
      return sorted;
    }
    return latestTransferResponse
      .concat(nonTransferResponses)
      .filter((r) => Boolean(r));
  }, [isShowOriginRes, sorted]);

  useEffect(() => {
    if (isReplyCopied) {
      setTimeout(() => {
        setIsReplyCopied(false);
      }, 800);
    }
  }, [isReplyCopied]);

  if (!commandHistory)
    return (
      <NonIdealState
        icon={IconNames.HISTORY}
        title="Choose item from history."
      />
    );

  return (
    <div className={s.commandHistoryView}>
      <div className={s.commandHistoryViewSequence}>
        {commandHistory?.commandCompleted && (
          <span className="text-sm text-success">Command completed</span>
        )}
      </div>
      {isReplyOutdated(sorted, commandHistory.createdAt) && (
        <div className={s.commandHistoryViewSequence}>
          <span className="text-sm text-warning">
            Warning: Response is outdated
          </span>
        </div>
      )}
      <div className={s.commandHistoryViewItem}>
        <div className={s.commandHistoryViewResponse}>
          <span>Responses:</span>
          <div className="flex items-center gap-2">
            <BPButton
              text={isShowOriginRes ? 'Show Parsed' : 'Show Origin'}
              onClick={() => {
                sendInfo({
                  type: isShowOriginRes
                    ? 'Command history show parsed'
                    : 'Command history show origin',
                  action: 'Click',
                  item: 'Command history show parsed/origin button',
                  module: 'OPS',
                });
                setShowOriginRes(!isShowOriginRes);
              }}
            />
            <Tooltip content="Copy replies">
              <Button
                data-testid="copy-replies-button"
                icon={isReplyCopied ? 'tick' : 'duplicate'}
                onPress={() => {
                  setIsReplyCopied(true);
                  copyToClipBoard(
                    JSON.stringify(getSortedResponses(), null, 2)
                  );
                }}
                isMinimal
                isTransparent
              />
            </Tooltip>
          </div>
        </div>
        {getSortedResponses().map((response, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`response.sequenceId-${index}`}
            className={s.repliesPreWrapper}
          >
            <Response isShowOriginRes={isShowOriginRes} response={response} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RepliesInfo;
