/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IconNames } from '@blueprintjs/icons';
import type { FtpTransferUpdateResponse } from 'pages/ops/RTI/Operate/components/CommandReplyInfo/FTPTransferUpdateInfo';
import { get } from 'lodash/fp';
import type { IBottomBarTab } from '../../../../pages/ops/Library/components/BottomNavBar';
import {
  piuExecuteTaskHandler,
  stringResponseNewLineHandler,
  telemetryRatesReportHandler,
  byteResponseHandler,
  sshReplyHandler,
  ftpTransferUpdateHandler,
  uftTransportReplyHandler,
  telemetryGetSetReplyHandler,
} from '../../../../pages/ops/RTI/Operate/utils/rtiCommands/repliesInfoUtils';
import type { UFTTransportReply } from 'pages/ops/RTI/Operate/components/CommandReplyInfo/UFTTransportInfoReplyInfo';
import type { TelemetryGetSetResponse } from 'pages/ops/RTI/Operate/components/CommandReplyInfo/TelemetryGetSetReply';

type IsUsable = (message: Record<string, any>, messageType: string) => boolean;

export interface ISpecificResponseParser {
  shouldUse: IsUsable;
  handler: Function;
}

function hasPath(path: string) {
  return (message: Record<string, any>) => {
    return Boolean(get(path, message));
  };
}

function isMessageType(expectedMessageType: string) {
  return (message: Record<string, any>, messageType: string) =>
    expectedMessageType === messageType;
}

/**
 * @description It's array of parsers for specific responses
 * @return array
 */
export const SPECIFIC_RESPONSES_PARSERS: ISpecificResponseParser[] = [
  /**
   * @description Parser for PiuExecuteTask/PiuExecuteBashTask commands
   */
  {
    shouldUse: hasPath(
      'Message.PostMessage.Message.ReportMessage.Message.StringReport.string_value'
    ),
    handler: (response: any) =>
      piuExecuteTaskHandler(
        response.Message.PostMessage.Message.ReportMessage.Message.StringReport
          .string_value
      ),
  },
  /**
   * @description Parser for PiuExecuteTask/PiuExecuteBashTask binary commands
   */
  {
    shouldUse: hasPath('data.postMessage.reportMessage.bytesReport.bytesValue'),
    handler: (response: any) =>
      byteResponseHandler(
        response.data.postMessage.reportMessage.bytesReport.bytesValue
      ),
  },
  /**
   * @description Parser for commands which contains `telemetryRatesReportMessage`
   */
  {
    shouldUse: hasPath('data.postMessage.telemetryRatesReportMessage'),
    handler: (response: any) =>
      telemetryRatesReportHandler(
        response.data.postMessage.telemetryRatesReportMessage
      ),
  },
  /**
   * @description Parser for commands which contains `stringReport.stringValue`
   *              splitting string by `\n`
   */
  {
    shouldUse: hasPath(
      'data.postMessage.reportMessage.stringReport.stringValue'
    ),
    handler: (response: any) =>
      stringResponseNewLineHandler(
        response.data.postMessage.reportMessage.stringReport.stringValue
      ),
  },
  /**
   * @description Parser for SSH commands
   */
  {
    shouldUse: isMessageType('FormattedMessageResponse'),
    handler: (response: any) => sshReplyHandler(response.data),
  },
  {
    shouldUse: isMessageType('FTPTransferUpdate'),
    handler: (response: { data: FtpTransferUpdateResponse }) =>
      ftpTransferUpdateHandler(response.data),
  },
  {
    shouldUse: isMessageType('UFTTransportReport'),
    handler: (response: { data: UFTTransportReply }) =>
      uftTransportReplyHandler(response.data),
  },
  {
    shouldUse: isMessageType('ocv2.telemetryrequest.TelemetryGetSetResponse'),
    handler: (response: { data: TelemetryGetSetResponse }) =>
      telemetryGetSetReplyHandler(response.data),
  },
];

export const LEFT_TAB_STATES = {
  CMD: 'cmd',
  FILE: 'file',
  TM: 'tm',
};

export const OPERATE_RIGHT_BOTTOM_NAVBAR_TABS: IBottomBarTab[] = [
  {
    id: 'parameters',
    title: 'Parameters',
    icon: IconNames.MANUALLY_ENTERED_DATA,
  },
  {
    id: 'repliesInfo',
    title: 'Replies Info',
    icon: IconNames.CHAT,
  },
  {
    id: 'metadata',
    title: 'Metadata',
    icon: IconNames.COMMENT,
  },
];

export const PARAMETERS_RIGHT_TAB_STATE = 'parameters';
export const REPLIES_RIGHT_TAB_STATE = 'repliesInfo';

export const MAP_TAG_TO_STORAGE = {
  'cloud-file': 'file',
  'cloud-directory': 'file',
  'satellite-file': 'file',
  'satellite-directory': 'file',
  'tm-id': 'tm',
};

export const STATUS_PENDING = 'pending';
export const STATUS_SENT = 'sent';
export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';
export const STATUS_ERROR_TELEMETRY_MATCH_PATTERN = /^STATUS_ERROR_.*$/;
export const NO_ERROR_WHITELIST_TYPES = [
  'AdcsBootLoaderV3CopyToIntFlashProgress233',
];

export const TELEMETRY_GET_SET_RESPONSE_TYPE =
  'ocv2.telemetryrequest.TelemetryGetSetResponse';

/**
 * This constant was misspelled initially and changing it now would be a breaking
 * change for our customers and operator and AIT scripts
 */
export const SEND_UNARMED_COMMAND = 'SEND_UNARMMED_COMMAND';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const FIRE_COMMAND = 'FIRE_COMMAND';

export const RTI_COMMANDS = {
  FTP_LIST: 'FtpList',
  FTP_LIST_FILE_RESPONSE: 'ftp.ListFileResponse',
  SFTP_LIST_FOLDER_ITEMS: 'SFTPListFolderItems',
  SFTP_LIST_FILE_RESPONSE: 'FolderItems',
};

export const GROUP_ICON_MAPPING = {
  AdcsCubeAcpV3: IconNames.DASHBOARD,
  AdcsCubeAcpV7: IconNames.DASHBOARD,
  AdcsBootLoaderV3: IconNames.DASHBOARD,
  Baseband: IconNames.CELL_TOWER,
  Comms: IconNames.FEED,
  Eps: IconNames.OFFLINE,
  Ftp: IconNames.DOCUMENT_SHARE,
  Network: IconNames.EXCHANGE,
  Ops: IconNames.CONTROL,
  Parse: IconNames.COMPARISON,
  Piu: IconNames.DESKTOP,
  Satellite: IconNames.SATELLITE,
  T: IconNames.TIMELINE_LINE_CHART,
  Tm: IconNames.TIMELINE_LINE_CHART,
  Favorites: IconNames.STAR,
};

export const FAVORITES_COMMANDS_GROUP = 'Favorites';

export enum FILE_TRANSFER_TYPE_TEXT {
  PC_TO_CLOUD = 'PC \u2192 Cloud',
  CLOUD_TO_PC = 'Cloud \u2192 PC',
  PC_TO_OPENKIT = 'PC \u2192 OpenKit',
  OPENKIT_TO_PC = 'OpenKit \u2192 PC',
  SATELLITE_TO_CLOUD = 'Satellite \u2192 Cloud',
  CLOUD_TO_SATELLITE = 'Cloud \u2192 Satellite',
}
