import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { find, has, isUndefined, get } from 'lodash/fp';
import { Button } from '@blueprintjs/core';
import { HeaderButton } from './HeaderButton';
import { useAuth } from 'services/auth/AuthWrapper';
import { useMission } from 'services/Missions';
import Clock from 'components/Clock';
import ActiveSessions from 'components/ActiveSessions';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import OrganisationConfiguration from 'components/OrganisationConfiguration';
import bigLogo from 'public/images/logo-centric-name-compact.svg';
import smallLogo from 'public/images/logo-monogram-black.svg';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import type { Organisation } from '_api/administration/service';
import MailToButton from '_organisms/MailToButton/MailToButton';
import { FEEDBACK_MAIL } from 'constants/contact';
import { ENABLE_SEND_FEEDBACK, LOGO } from 'env';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { UserProfileMenu } from 'ui/UserMenu';
import classNames from 'classnames';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

const DEFAULT_MISSION_NAME = 'Mission name';

interface IProps {
  showMissionSelector?: boolean;
  disableMissionSelector?: boolean;
  showReport?: boolean;
  header?: ReactNode;
  leftHeader?: ReactNode;
  rightHeader?: ReactNode;
  light?: boolean;
  shouldHideClock?: boolean;
  onClickLogo?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMissionClick?: () => void;
}

export const MissionPageHeader = (
  props: PropsWithChildren<IProps>
): ReactElement => {
  const [isOrganizationOpen, setOrganizationOpen] = useState(false);
  const [currentOrganisation, setCurrentOrganisation] =
    useState<Organisation>();
  const { user, isAllowedToOpenMissionSelector } = useAuth();
  const { currentMissionId } = useMission();
  const location = useLocation();

  const { sendInfo } = useAnalytics();

  const { hasPermission: isAllowedToUpdateOrganisation } = useCheckPermissions({
    permissions: {
      type: 'organisation',
      actionScope: 'organisation:update',
      id: String(get('id', currentOrganisation)),
    },
  });

  const isOps = location.pathname.split('/').includes('ops');
  const isMsd = location.pathname.split('/').includes('msd');

  const getFallbackEmail = () => {
    if (isOps) return 'openapp-help@open-cosmos.com';
    if (isMsd) return 'openlab-help@open-cosmos.com';
    return FEEDBACK_MAIL;
  };

  const getTeam = () => {
    if (isOps) return 'operations';
    if (isMsd) return 'openlab';
    return 'datacosmos';
  };

  const {
    currentMission,
    organisations,
    missions,
    programmes,
    organisationApi,
    setCurrentMission,
  } = useMission();

  const params = useParams<{ mission: string }>();

  const [localStorage] = useLocalStorage<{
    currentMissionId: number | undefined;
  }>('missions', {
    currentMissionId: undefined,
  });

  useEffect(() => {
    if (
      props.showMissionSelector &&
      isAllowedToOpenMissionSelector &&
      !currentMission &&
      !has('mission', params) &&
      !find(['mission', localStorage.currentMissionId], missions)
    ) {
      setOrganizationOpen(true);
    }
  }, [
    props.showMissionSelector,
    currentMission,
    isAllowedToOpenMissionSelector,
    params,
    localStorage.currentMissionId,
    missions,
  ]);

  useEffect(() => {
    if (currentMission) {
      const org = find(['id', currentMission.organisation], organisations);
      org && setCurrentOrganisation(org);
    }
  }, [currentMission, organisations]);

  useEffect(() => {
    const storedMissionId = localStorage.currentMissionId;

    if (!isUndefined(storedMissionId) && isUndefined(currentMission)) {
      const isOPS = location.pathname.split('/').includes('ops');
      const isExactlyOps = location.pathname === '/ops';
      const isGrafana = window.location.href.includes('/ops/telemetry');
      const shouldRedirect = isOPS && !isGrafana;
      const mission = find(['mission', storedMissionId], missions);
      mission && isExactlyOps && setCurrentMission(mission, shouldRedirect);
    }
  }, [
    currentMission,
    localStorage.currentMissionId,
    location.pathname,
    missions,
    setCurrentMission,
  ]);

  // TODO: item interface
  // const handleSetItemId = (): void => {};

  const windowSize = useWindowSize();
  const logo = windowSize.isTablet ? smallLogo : bigLogo;

  const getUrlTo = () => {
    const pathname = window.location.pathname;

    if (currentMissionId && pathname.startsWith('/ops')) {
      return `/ops/mission/${currentMissionId}/`;
    }

    if (!currentMissionId && pathname.startsWith('/ops')) {
      return `/ops`;
    }

    if (pathname.startsWith('/msd')) {
      return `/msd`;
    }

    return '/';
  };

  return (
    <div
      className={[
        `${
          LOGO
            ? 'mission-page-header bg-header-top text-header-top-contrast'
            : 'mission-page-header'
        }`,
        props.light && !LOGO?.length ? 'mission-page-header--light' : '',
      ].join(' ')}
    >
      <div className="left">
        <div
          className={classNames('flex items-center header-logo', {
            'header-logo-condata': LOGO?.length,
          })}
          role="presentation"
          onClick={(e) => {
            if (!LOGO?.length) {
              sendInfo({
                type: 'Logo click',
                action: 'Click',
                item: 'Open Cosmos logo',
                module: 'OPS',
              });
            }
            props.onClickLogo?.(e);
          }}
        >
          <Link to={getUrlTo()}>
            <img
              height={LOGO?.length ? 'auto' : '100%'}
              id="logo-image"
              className="summary-logo-img"
              src={LOGO?.length ? LOGO : logo}
              alt="logo-centric-name-compact.svg"
            />
          </Link>
        </div>
        {props.showMissionSelector ? (
          <Button
            minimal
            disabled={
              props.disableMissionSelector ?? !isAllowedToOpenMissionSelector
            }
            className="pl-3 pr-2 underline underline-offset-4"
            onClick={() => setOrganizationOpen(true)}
            text={currentMission ? currentMission.name : DEFAULT_MISSION_NAME}
          />
        ) : null}
        {props.leftHeader}
      </div>
      <div className="middle">
        {props.header}
        {props.showReport ? <HeaderButton>Report</HeaderButton> : null}
        {!props.shouldHideClock && <Clock dark={!props.light} />}
      </div>
      <div className="right">
        {props.rightHeader}
        {isOps && <ActiveSessions mission={currentMissionId} />}
        {ENABLE_SEND_FEEDBACK && (
          <MailToButton
            subject="Send feedback"
            fallbackEmail={getFallbackEmail()}
            team={getTeam()}
          />
        )}
        <UserProfileMenu
          showMissionSelector={props.showMissionSelector}
          currentMission={currentMission}
          currentOrganisation={currentOrganisation}
          isAllowedToUpdateOrganisation={isAllowedToUpdateOrganisation}
          hideThemesInUserMenu={true}
        />
        {/*TODO hide sheet and question mark icon*/}
        {/*<HeaderButton text={<Icon icon={'help'} iconSize={22} />} />*/}
        {/*<HeaderButton text={<Icon icon={'document'} iconSize={22} />} />*/}
      </div>
      {props.showMissionSelector && (
        <OrganisationConfiguration
          user={user}
          isOpen={isOrganizationOpen}
          organisationsList={organisations}
          programmesList={programmes}
          missionsList={missions}
          organisationApi={organisationApi}
          onClose={() => setOrganizationOpen(false)}
          onMissionClick={props.onMissionClick}
        />
      )}
    </div>
  );
};
