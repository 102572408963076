import type { DragSourceMonitor } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';

export default (
  dropType: string,
  path: string,
  dragType: string,
  handleDropItem: Function | null | undefined
) => {
  if (!dropType) {
    return {
      isOver: false,
      isOverCurrent: false,
      drop: null,
      drag: null,
    };
  }

  const [{ isOver, isOverCurrent }, drop] = useDrop({
    accept: dropType,
    drop: () => ({ toPath: path }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const [, drag] = useDrag({
    type: dragType,
    item: { fromPath: path },
    end: (
      item: { fromPath: string } | undefined,
      monitor: DragSourceMonitor
    ) => {
      const dropResult: { toPath: string } | null = monitor.getDropResult();
      if (item && dropResult && handleDropItem) {
        handleDropItem(item.fromPath, `${dropResult.toPath}`);
      }
    },
  });

  return {
    isOver,
    isOverCurrent,
    drop,
    drag,
  };
};
