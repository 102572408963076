import type { ITransformResponseMappingBody } from './index';
import { sortBy } from 'lodash';
import type { CommandDefinition } from '_api/telecommands/types';

export default (
  setAvailableCommands: (data: CommandDefinition[]) => void
): ITransformResponseMappingBody => ({
  validator: null,
  transformer: (data) => {
    const d = data as CommandDefinition[];

    return sortBy(
      d.reduce<CommandDefinition[]>(
        (acc, item) => [
          ...acc,
          {
            name: item.type ?? '',
            schema: item.schema,
            scope: item.scope,
            group: item.group,
            dangerous: item.dangerous,
            dangerousMessage: item.dangerousMessage,
          },
        ],
        [] as CommandDefinition[]
      ),
      ({ name }) => name
    );
  },
  handler: (data) => {
    const d = data as CommandDefinition[];
    return setAvailableCommands(d);
  },
});
