import { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Classes,
  Intent,
  Callout,
  ButtonGroup,
  Dialog,
  ControlGroup,
} from '@blueprintjs/core';
import { set, cloneDeep } from 'lodash';

import {
  NEW_DEFINITION,
  useAlerts,
} from '../../../../../services/api/telemetry/AlertsProvider';
import TagHeader from '../../../../../components/common/TagHeader';

import s from './index.module.scss';
import generalS from '../LibraryTelemetry/index.module.scss';
import { IconNames } from '@blueprintjs/icons';
import { getGrafanaUrlForAlert } from './utilities';
import { useAuth } from '../../../../../services/auth/AuthWrapper';
import type { AlertRule } from '_api/alerts/types';
import type { User, UserWithPermissions } from '_api/users/types';
import { Tooltip } from 'opencosmos-ui';
import useUser from 'services/api/portal/administration/hook/useUser';

const CreateAlert = () => {
  const {
    currentAlert,
    requestAlertRuleChange,
    createNewAlertRule,
    errorCreatingAlert,
    alertGroupDoesntExist,
  } = useAlerts();

  const { user } = useAuth();
  const { getUsersById } = useUser();

  const [localAlert, setLocalAlert] = useState<AlertRule | undefined>(
    NEW_DEFINITION
  );
  const [expression, setExpression] = useState(currentAlert?.expr);
  const [reviewing, setReviewing] = useState(false);
  const [reviewUser, setReviewUser] = useState<UserWithPermissions | User>();
  const [dirty, setDirty] = useState(false);

  const dirtyReviewUser = (newAlertRule: AlertRule) => {
    if (
      dirty &&
      (localAlert?.annotations.reviewed === 'accepted' ||
        localAlert?.annotations.reviewed === 'rejected')
    ) {
      newAlertRule.annotations.reviewed = 'changed since review';
    }
  };

  const handleValueChange = (field: string, value: string | string[]) => {
    const newAlertRule = cloneDeep(localAlert);

    if (!newAlertRule) {
      return;
    }

    set(newAlertRule, field, value);
    setLocalAlert(newAlertRule);
    setDirty(true);
  };

  const renderField = (text: string, name: string, value: string) => {
    return (
      <div className={s.createField}>
        <span>{text}</span>
        <input
          className={[Classes.INPUT, Classes.FILL].join(' ')}
          value={value}
          onChange={(e) => handleValueChange(name, e.target.value)}
          disabled={name === 'id' || alertGroupDoesntExist}
        />
      </div>
    );
  };

  const submitHandler = () => {
    const newAlert = cloneDeep(localAlert);

    if (!newAlert) {
      return;
    }

    newAlert.expr = expression ?? '';
    newAlert.id = newAlert.editing
      ? newAlert.id
      : (Math.random() * 10000000).toFixed(0);
    dirtyReviewUser(newAlert);
    setLocalAlert(newAlert);
    void requestAlertRuleChange(newAlert);
    setDirty(false);
  };

  const reviewHandler = () => {
    setReviewing(true);
  };

  const renderSelectField = (
    text: string,
    name: string,
    value: string,
    values: string[]
  ) => (
    <div className={s.createField}>
      <span>{text}</span>
      <div className="bp4-select" style={{ display: 'block' }}>
        <select
          value={value}
          onChange={(e) => handleValueChange(name, e.target.value)}
          disabled={alertGroupDoesntExist}
        >
          {values.map((nameValue) => (
            <option key={nameValue} value={nameValue}>
              {nameValue}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const encoded = getGrafanaUrlForAlert(expression);

  let reviewText = 'Not reviewed';
  if (localAlert?.annotations.reviewed === 'rejected') {
    reviewText = 'Rejected by';
  } else if (localAlert?.annotations.reviewed === 'accepted') {
    reviewText = 'Accepted by';
  } else if (localAlert?.annotations.reviewed === 'changed since review') {
    reviewText = 'Changed since last reviewed by';
  }

  const reviewCancelHandler = () => {
    setReviewing(false);
  };

  const reviewRejectHandler = () => {
    setReviewing(false);

    const newAlertRule = cloneDeep(localAlert);

    if (!newAlertRule) {
      return;
    }

    newAlertRule.annotations.reviewed = 'rejected';
    newAlertRule.annotations.last_reviewer = user?.sub ?? '';
    setLocalAlert(newAlertRule);

    setReviewUser(user);

    void requestAlertRuleChange(newAlertRule);
  };

  const reviewAcceptHandler = () => {
    setReviewing(false);

    const newAlertRule = cloneDeep(localAlert);
    if (!newAlertRule) {
      return;
    }

    newAlertRule.annotations.reviewed = 'accepted';
    newAlertRule.annotations.last_reviewer = user?.sub ?? '';
    setLocalAlert(newAlertRule);
    setReviewUser(user);

    void requestAlertRuleChange(newAlertRule);
  };

  const updateReviewUser = useCallback(async () => {
    if (currentAlert?.annotations.last_reviewer) {
      const lastReviewer = await getUsersById(
        currentAlert.annotations.last_reviewer
      );
      setReviewUser(lastReviewer);
    } else {
      setReviewUser(undefined);
    }
  }, [currentAlert?.annotations.last_reviewer, getUsersById]);

  const disableSave = alertGroupDoesntExist || (localAlert?.editing && !dirty);

  useEffect(() => {
    setLocalAlert(cloneDeep(currentAlert));
    void updateReviewUser();
    setDirty(false);
  }, [currentAlert, updateReviewUser]);

  return (
    <div>
      <Dialog
        title="Review Alert Rule"
        icon={IconNames.CONFIRM}
        isOpen={reviewing}
        onClose={reviewCancelHandler}
      >
        <div className={s.dialogueContainer}>
          <p className={s.textContainer}>
            Do you want to mark this alert rule as reviewed? You will not be
            able to undo this.
          </p>
          <div className={s.dialogueButtonContainer}>
            <Button onClick={reviewCancelHandler}>Cancel</Button>
            <div className={s.dialogueChoiceButtons}>
              <ButtonGroup>
                <Button intent={Intent.DANGER} onClick={reviewRejectHandler}>
                  Reject
                </Button>
                <Button intent={Intent.SUCCESS} onClick={reviewAcceptHandler}>
                  Accept
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </Dialog>
      <div className={generalS.variablesTitle}>
        <TagHeader
          intent={Intent.PRIMARY}
          title={
            <div className={generalS.libraryHeader}>
              <div className={generalS.filterContainer}>
                <span>
                  {localAlert?.editing
                    ? 'Editing Alert Rule'
                    : 'Creating Alert Rule'}
                </span>
              </div>
            </div>
          }
        />
      </div>
      <div className={s.createContainer}>
        <div className={`${s.createRow}`}>
          <ControlGroup
            className={`${s.expressionRow} invalid-label`}
            fill={true}
          >
            <Tooltip
              content="View Grafana graphs for this expression"
              className={Classes.FIXED}
            >
              <Button
                icon={IconNames.TIMELINE_LINE_CHART}
                onClick={() => window.open(encoded)}
              />
            </Tooltip>
            <input
              className={Classes.INPUT}
              placeholder="PromQL query"
              value={expression ?? localAlert?.expr}
              onChange={(e) => {
                setExpression(e.target.value);
              }}
            />
            {localAlert?.editing && (
              <Tooltip content="Cancel editing" className={Classes.FIXED}>
                <Button icon={IconNames.CROSS} onClick={createNewAlertRule} />
              </Tooltip>
            )}
            <Tooltip
              content={localAlert?.editing ? 'Save' : 'Create'}
              className={Classes.FIXED}
            >
              <Button
                icon={IconNames.SAVED}
                intent={Intent.SUCCESS}
                onClick={submitHandler}
                disabled={disableSave}
              >
                {localAlert?.editing ? 'Save' : 'Create'}
              </Button>
            </Tooltip>
          </ControlGroup>
        </div>
        <div className={s.createRow}>
          {renderField('ID', 'id', localAlert?.id ?? '')}
          {renderField('Name', 'alert', localAlert?.alert ?? '')}
          {renderField('Duration', 'for', localAlert?.for ?? '')}
        </div>
        <div className={s.createRow}>
          {renderSelectField(
            'Severity',
            'labels.severity',
            localAlert?.labels.severity ?? '',
            ['Warning', 'Major', 'Critical']
          )}
          {renderSelectField(
            'Subsystem',
            'labels.system',
            localAlert?.labels.system ?? '',
            ['ops', 'comms', 'eps', 'obdh']
          )}
          {renderSelectField(
            'Team',
            'labels.team',
            localAlert?.labels.team ?? '',
            ['flight-team', 'ait', 'ground-segment-ops']
          )}
        </div>
        <div className={s.createRow}>
          {renderField(
            'Description',
            'annotations.description',
            localAlert?.annotations.description ?? ''
          )}
          {renderField(
            'Summary',
            'annotations.summary',
            localAlert?.annotations.summary ?? ''
          )}
        </div>
        {localAlert?.editing && (
          <div className={s.reviewContainer}>
            <div className={s.reviewUserContainer}>
              {reviewUser ? (
                <Tooltip placement={'left'} content={reviewUser.email}>
                  <div className={s.userInfo}>
                    <span className={s.userName}>{reviewText}</span>
                    <img
                      className={s.userIcon}
                      src={reviewUser.picture}
                      alt="avatar"
                    />
                    <span className={s.userName}>{user?.name}</span>
                  </div>
                </Tooltip>
              ) : (
                <span>Not reviewed</span>
              )}
            </div>

            <Tooltip content="Review this alert rule">
              <Button
                icon={IconNames.CONFIRM}
                intent={Intent.PRIMARY}
                onClick={reviewHandler}
                disabled={dirty}
              >
                Review
              </Button>
            </Tooltip>
          </div>
        )}
        <div>
          {errorCreatingAlert && (
            <Callout intent={Intent.DANGER}>{errorCreatingAlert}</Callout>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateAlert;
