import type { ReactElement, ReactNode } from 'react';
import React from 'react';

export interface IDefaultLayoutProps {
  children: ReactNode;
}

class EmptyLayout extends React.PureComponent<IDefaultLayoutProps, {}> {
  public render(): ReactElement {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default EmptyLayout;
