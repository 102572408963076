import type { ILayerOptions, LayerSourceType } from './layer';
import { Layer } from './layer';

export type PointLayerOptions = ILayerOptions & {
  color?: string;
};

export class PointLayer extends Layer {
  declare sourceType: LayerSourceType;
  declare options: PointLayerOptions;
  declare layerClass: string;
  declare name: string;
  declare position: number[];
  declare description: string;

  constructor(
    sourceType: LayerSourceType,
    name: string,
    position: number[],
    options?: Partial<PointLayerOptions>
  ) {
    super(sourceType);

    this.name = name;
    this.position = position;
    this.options = {
      color: '#e4695e',
      ...this.options,
      ...options,
    };
  }
  getName(): string {
    return this.name;
  }

  getDescription(): string | null {
    return '';
  }

  containsSTACItem(): boolean {
    return false;
  }
  containsSTACAsset(): boolean {
    return false;
  }
  containsBandAlgebra(): boolean {
    return false;
  }
  sza(): Number | undefined {
    return undefined;
  }
  href(): string | undefined {
    return undefined;
  }
  cloneWithOptions(options: Partial<PointLayerOptions>) {
    const newObject = this.clone();
    newObject.options = { ...newObject.options, ...options };
    return newObject;
  }
}
