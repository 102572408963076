import { TIME_FORMAT } from 'constants/datetime';
import moment from 'moment';
import type { match } from 'react-router';
import type { Activity } from '_api/activities/service';
import s from './index.module.scss';
import classNames from 'classnames';

type ActivityCardProps = {
  activity: Activity;
  handleActivityClick: (activity: Activity) => void;
  routeMatch: match<{
    mission: string;
    activity: string;
  }> | null;
};

export const ActivityCard = ({
  activity,
  handleActivityClick,
  routeMatch,
}: ActivityCardProps) => {
  return (
    <div
      className={s.container}
      onClick={() => {
        handleActivityClick(activity);
      }}
      data-testid="activity-card"
    >
      <div className={s.timeWrapper}>
        <span>{moment.utc(activity.start_date).format(TIME_FORMAT)}</span>
        <span>{moment.utc(activity.end_date).format(TIME_FORMAT)}</span>
      </div>

      <div
        className={classNames(s.infoWrapper, {
          'bg-neutral-300 dark:bg-neutral-600':
            activity.status === 'PENDING' || activity.status === 'APPROVED',
          'bg-neutral-500 dark:bg-neutral-800':
            activity.status === 'CONFIRMED' ||
            activity.status === 'SCHEDULED' ||
            activity.status === 'PROCESSING' ||
            activity.status === 'ACQUIRED',
          'bg-success': activity.status === 'COMPLETED',
          'bg-warning':
            activity.status === 'CANCELLED' || activity.status === 'FAILED',
          'border-2 border-item-dark dark:border-item':
            routeMatch?.params.activity === activity.id,
        })}
      >
        <span className="text-[13px] w-full text-center">{activity.type}</span>
        <span className="text-[13px] w-full text-center whitespace-nowrap">
          {activity.id}
        </span>
        <span className="text-[13px] w-full text-center">
          {activity.status}
        </span>
      </div>
    </div>
  );
};
