import type { RunLog } from '_api/runs/types';
import { Table } from 'opencosmos-ui';
import React from 'react';

type Props = {
  logs: RunLog[];
};

const STRING_OVERFLOW_THRESHOLD = 250;

const LogsTable = ({ logs }: Props) => {
  const isLongerThanThreshold = (str: string) => {
    return str.length > STRING_OVERFLOW_THRESHOLD;
  };

  const renderSubComponent = (log: RunLog) => {
    const dataLog = <pre>{JSON.stringify(log.data, null, 2)}</pre>;
    const messageLog = <pre>{log.message.replace('/\n/g', '\n')}</pre>;

    return (
      <div className="max-h-96 overflow-auto">
        {Object.keys(log.data).length > 0 && dataLog}
        {log.message.includes('\n') &&
          isLongerThanThreshold(log.message) &&
          messageLog}
      </div>
    );
  };

  return (
    <Table<RunLog>
      cellRenderer={(ctx, header) => {
        if (header === 'message') {
          return (
            <div>
              {isLongerThanThreshold(ctx.getValue() as string) ? (
                <span className="p-2 flex-wrap">
                  {(ctx.getValue() as string).slice(
                    0,
                    STRING_OVERFLOW_THRESHOLD
                  )}
                  ...
                </span>
              ) : (
                <span className="p-2 flex-wrap">
                  {ctx.getValue() as string}
                </span>
              )}
            </div>
          );
        }

        return (
          <span className="p-2 flex-wrap">{ctx.getValue() as string}</span>
        );
      }}
      data={logs}
      excludeDataKeys={['data']}
      showFullHeaderTitle={true}
      getRowCanExpand={(row) => {
        return (
          Object.keys(row.original?.data ?? {}).length > 0 ||
          row.original.message.includes('\n')
        );
      }}
      subComponentRenderer={({ original }) => renderSubComponent(original)}
    />
  );
};

export default LogsTable;
