import type { IGoal } from './interfaceas';
import { OBJECTIVE_TYPES } from '../objectivesTable/constants';

export const GOAL_ADD = 'GOAL_ADD';
export const GOAL_UPDATE = 'GOAL_UPDATE';
export const GOAL_REMOVE = 'GOAL_REMOVE';
export const GOAL_RESET = 'GOAL_RESET';
export const GOAL_UPLOAD = 'GOAL_UPLOAD';

export const GOAL_SUB_ID = 'GOAL';

export const GOAL_TYPES = {
  MISSION_METRIC: 'mission_metric',
  POINT_OF_INTEREST_EVENT: 'point_of_interest_event',
  REGION_OF_INTEREST_EVENT: 'region_of_interest_event',
};

export const MISSION_METRIC = {
  NUMBER_OF_SATELLITES: 'number_of_satellites',
  NUMBER_OF_PLANES: 'number_of_planes',
  NUMBER_OF_GROUND_STATIONS: 'number_of_ground_stations',
};

export const OBJECTIVE_METRIC = {
  INCIDENCE: 'incidence',
  AVG_DURATION: 'average',
  MAX_DURATION: 'maximum',
  MIN_DURATION: 'minimum',
  DURATION_STD_DEVIATION: 'std',
};

export const SCOPE = {
  COVERAGE: 'coverage',
  REVISIT: 'revisit',
};

export const GROUND_SEGMENT_EVENT = {
  OBJECT: 'all_ground_stations',
  EVENT: {
    VISIBILITY: 'visibility',
  },
};
export const POINT_OF_INTEREST_EVENT = {
  EVENT: {
    VISIBILITY: 'visibility',
    ILLUMINATED_VISIBILITY: 'illuminated_visibility',
  },
};
export const REGION_OF_INTEREST_EVENT = {
  EVENT: {
    VISIBILITY: 'visibility',
    ILLUMINATED_VISIBILITY: 'illuminated_visibility',
  },
};

export const goalInitialValues: IGoal = {
  id: '',
  name: '',
  type: GOAL_TYPES.MISSION_METRIC,
  metric: MISSION_METRIC.NUMBER_OF_SATELLITES,
  criterion: 0,
};

export const goalsOption = {
  MISSION_METRIC: {
    value: OBJECTIVE_TYPES.MISSION_METRIC,
    text: 'objectives.types.mission_metric',
  },
  GROUND_SEGMENT_EVENT: {
    value: OBJECTIVE_TYPES.GROUND_SEGMENT_EVENT,
    text: 'objectives.types.ground_segment_event',
  },
  POINT_OF_INTEREST_EVENT: {
    value: OBJECTIVE_TYPES.POINT_OF_INTEREST_EVENT,
    text: 'objectives.types.point_of_interest_event',
  },
  REGION_OF_INTEREST_EVENT: {
    value: OBJECTIVE_TYPES.REGION_OF_INTEREST_EVENT,
    text: 'objectives.types.region_of_interest_event',
  },
};

export const missionMetricOptions = {
  NUMBER_OF_SATELLITES: {
    value: MISSION_METRIC.NUMBER_OF_SATELLITES,
    text: 'objectives.metric.number_of_satellites',
  },
  NUMBER_OF_PLANES: {
    value: MISSION_METRIC.NUMBER_OF_PLANES,
    text: 'objectives.metric.number_of_planes',
  },
  NUMBER_OF_GROUND_STATIONS: {
    value: MISSION_METRIC.NUMBER_OF_GROUND_STATIONS,
    text: 'objectives.metric.number_of_ground_stations',
  },
};
