import type { IconName } from '@blueprintjs/core';
import { Alert, Intent } from '@blueprintjs/core';
import type { ReactElement } from 'react';
import React, { useState } from 'react';

export interface IAlertOption {
  icon?: IconName;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

interface IAlertProps {
  removeDiv: Function;
  onConfirm: Function;
  message: string;
  option: IAlertOption | undefined;
}

export default function AlertRemove(props: IAlertProps): ReactElement {
  const [isOpen, setIsOpen] = useState(true);
  const onCancel = (): void => {
    setIsOpen(false);
    props.removeDiv();
  };
  const onConfirm = (): void => {
    setIsOpen(false);
    props.onConfirm();
    props.removeDiv();
  };
  const { option } = props;
  const icon = option?.icon ? option.icon : 'trash';
  const cancelButtonText = option?.cancelButtonText
    ? option.cancelButtonText
    : 'Cancel';
  const confirmButtonText = option?.confirmButtonText
    ? option.confirmButtonText
    : 'Remove';
  return (
    <Alert
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      icon={icon}
      isOpen={isOpen}
      intent={Intent.DANGER}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <p>{props.message}</p>
    </Alert>
  );
}
