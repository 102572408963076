export default {
  environment: 'production',
  map: {
    mapbox: {
      accessToken:
        'pk.eyJ1IjoicGVwb2MiLCJhIjoiY2txbm0waG0zMTN6bTJwbjRxMzM3MTQxOSJ9.JfQts1AgJc2wjwT8MIspJQ',
    },
    initialZoom: 3,
    minZoom: 2,
    maxZoom: 20,
    maxNativeZoomBasedOnReadPermission: 11,
    initialView: [-18.632, 18.479] as [number, number],
  },
};
