import type { GenericDetailsItem } from '_api/tasking/types';
import classNames from 'classnames';
import moment from 'moment';

export const DetailsItem = ({
  title,
  value,
  applyFlexStyle,
  ...aria
}: {
  title: string;
  value: string;
  applyFlexStyle?: boolean;
} & React.AriaAttributes) => {
  return (
    <div
      className={classNames(
        'dark:text-item-dark-contrast text-item-contrast pr-1 pl-1',
        {
          'flex gap-2': applyFlexStyle,
          'grid grid-cols-2': !applyFlexStyle,
        }
      )}
      {...aria}
    >
      <span className="break-all font-medium">{title}</span>
      <span className="break-all" style={{ textAlign: 'end' }}>
        {value}
      </span>
    </div>
  );
};

export const CompositeDetailsItem = ({
  title,
  element,
}: {
  title: string;
  element: React.ReactNode;
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
      }}
      className="dark:text-item-dark-contrast text-item-contrast pr-1 pl-1"
    >
      <span>{title}</span>
      <div className="text-end">{element}</div>
    </div>
  );
};

export const JSONDetailsItem = ({
  title,
  value,
  ...aria
}: {
  title: string;
  value: { [key: string]: unknown } | { [key: string]: unknown }[];
} & React.AriaAttributes) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
      }}
      className="dark:text-item-dark-contrast text-item-contrast"
      {...aria}
    >
      <span className="break-all font-medium">{title}</span>
      <div className="bg-item-dark-contrast dark:bg-item-contrast flex justify-center max-w-md">
        <pre className="overflow-y-auto w-full">
          {JSON.stringify(value, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export const TabularDetailsItem = ({
  title,
  value,
  isVertical,
  ...aria
}: {
  title: string;
  value: GenericDetailsItem[];
  isVertical?: boolean;
} & React.AriaAttributes) => {
  const headers = Object.keys(value[0]);

  const formatValue = (item: GenericDetailsItem, header: string) => {
    if (
      item.type?.toString()?.includes('DATE') &&
      (header === 'min' || header === 'max')
    ) {
      return moment.unix(item[header] as number).format('YYYY-MM-DD');
    }

    if (typeof item[header] === 'object' && item[header] !== null) {
      return JSON.stringify(item[header], null, 2);
    }

    return item[header]?.toString()?.replaceAll('_', ' ');
  };

  if (isVertical) {
    return (
      <div
        className="dark:text-item-dark-contrast text-item-contrast pr-1 pl-1"
        {...aria}
      >
        <div className="break-all font-semibold">{title}</div>
        <div className="bg-item-dark-contrast dark:bg-item-contrast mt-1">
          <div className="flex flex-col">
            {headers.map((header) => (
              <div
                key={header}
                className="grid grid-cols-2 border border-neutral-300 dark:border-neutral-850"
              >
                <div className="px-1 py-1 font-normal text-left capitalize whitespace-normal break-all border-r">
                  {header.replaceAll('_', ' ')}
                </div>
                {value.map((item) => (
                  <div key={item[header]} className="px-1 py-1 text-right">
                    {formatValue(item, header)?.toLowerCase()}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="dark:text-item-dark-contrast text-item-contrast pr-1 pl-1"
      {...aria}
    >
      <div className="break-all">{title}</div>
      <div className="bg-item-dark-contrast dark:bg-item-contrast mt-1">
        <table className="min-w-full border border-neutral-200">
          <thead className="text-left">
            <tr>
              {headers.map((header) => (
                <th
                  key={header}
                  className="px-1 py-1 font-normal border border-neutral-300 dark:border-neutral-850 bg-accent-100 dark:bg-header-top-dark text-left capitalize whitespace-normal break-all"
                >
                  {header?.replaceAll('_', ' ')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {value.map((item) => (
              <tr key={item[0]}>
                {headers.map((header) => (
                  <td
                    key={item[header]}
                    className="px-1 py-1 border border-neutral-300 dark:border-neutral-850 capitalize whitespace-normal break-all"
                  >
                    {formatValue(item, header)?.toLowerCase()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
