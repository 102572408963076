import classNames from 'classnames';
import { Button } from 'opencosmos-ui';
import type { ReactElement } from 'react';

interface IHeaderButtonProps {
  children: string | ReactElement;
  round?: boolean;
}

export const HeaderButton = ({
  children,
  round,
}: IHeaderButtonProps): ReactElement => {
  return (
    <Button
      className={classNames('header-button max-h-min w-max border-none', {
        'rounded-full': round,
      })}
    >
      {children}
    </Button>
  );
};
