import React, { useEffect, useRef, useState } from 'react';
import { EditableText, Icon } from '@blueprintjs/core';
import s from './index.module.scss';

interface IProps {
  showCreateFolderWindow: boolean;
  handleUploadClick: (folderName: string[]) => void;
}

const FolderEditableText = (props: IProps) => {
  const editableNameRef = useRef(null);
  const [newFolderName, setNewFolderName] = useState<string>('');

  useEffect(() => {
    if (props.showCreateFolderWindow) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      //@ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      editableNameRef.current?.handleFocus?.();
    }
  });

  const handleFolderNameChange = (name: string) => {
    setNewFolderName(name);
  };

  if (props.showCreateFolderWindow) {
    return (
      <div className={s.newFolderWrapper}>
        <Icon icon="folder-new" />
        <EditableText
          ref={editableNameRef}
          placeholder={'Untitled Folder'}
          multiline={false}
          value={newFolderName}
          onChange={handleFolderNameChange}
          onConfirm={() =>
            props.handleUploadClick(
              newFolderName ? [newFolderName] : ['Untitled Folder']
            )
          }
        />
      </div>
    );
  }

  return null;
};

export default FolderEditableText;
