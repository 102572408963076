import type { DateSegment } from '@react-stately/datepicker';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useDateSegment, useFocus } from 'react-aria';
import type { DateFieldState } from 'react-stately';

interface IDateSegmentProps {
  state: DateFieldState;
  segment: DateSegment;
}

const DateSegmentComponent = ({ segment, state }: IDateSegmentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { segmentProps } = useDateSegment(segment, state, ref);

  // Removes enterKeyHint since it's not a valid div prop
  if (Object.prototype.hasOwnProperty.call(segmentProps, 'enterKeyHint')) {
    delete (segmentProps as { enterKeyHint?: string }).enterKeyHint;
  }

  const { focusProps } = useFocus({
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
  });

  return (
    <div
      {...segmentProps}
      {...focusProps}
      ref={ref}
      className={classNames({
        'bg-item-selected dark:bg-item-dark-selected': isFocused,
        'text-contrast-inactive': state.isDisabled,
        'text-warning': state.validationState === 'invalid',
      })}
      data-testid="date-segment"
    >
      {segment.text}
    </div>
  );
};

export default DateSegmentComponent;
