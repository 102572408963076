/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import base64InputValidator from './base64InputValidator';

export default (value: any, params: any) => {
  let errorMessage;
  const { minLength, maxLength, meta, media } = params;

  if (minLength && value.length < minLength) {
    errorMessage = `Must be ${minLength as number} characters or more`;
  }
  if (maxLength && value.length > maxLength) {
    errorMessage = `Must be ${maxLength as number} characters or less`;
  }
  if ((media && media.binaryEncoding === 'base64') || meta === 'base64string') {
    errorMessage = base64InputValidator(value, params);
  }

  return errorMessage;
};
