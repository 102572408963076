import type { Coordinates } from './coordinatesToGeoJson';
import proj4 from 'proj4';
import type { EPSG } from './epsgCoordinates';
import { EPSG32630, EPSG4326, EPSG3857 } from './epsgCoordinates';

/**
 * convertToEPSG4326 converts coordinates to EPSG:4326 which is the default format that DataCosmos understands
 * @param coordinates list of coordinates to convert
 * @param from EPSG format to convert from
 * @returns number array of converted coordinates
 */
export const convertToEPSG4326 = (coordinates: Coordinates[], from: EPSG) => {
  proj4.defs(EPSG4326.proj4String);
  proj4.defs(from.proj4String);

  return proj4(
    from.proj4String,
    EPSG4326.proj4String,
    coordinates.map((c) => [c.lng, c.lat]).flat()
  );
};

/**
 * convertToEPSG32630 converts coordinates to EPSG:32630
 * @param coordinates list of coordinates to convert
 * @param from EPSG format to convert from
 * @returns number array of converted coordinates
 */
export const convertToEPSG32630 = (coordinates: Coordinates[], from: EPSG) => {
  proj4.defs(EPSG32630.proj4String);
  proj4.defs(from.proj4String);

  return proj4(
    from.proj4String,
    EPSG32630.proj4String,
    coordinates.map((c) => [c.lng, c.lat]).flat()
  );
};

/**
 * convertToEPSG4326 converts coordinates to EPSG:4326 which is the default format that DataCosmos understands
 * @param coordinates list of coordinates to convert
 * @param from EPSG format to convert from
 * @returns number array of converted coordinates
 */
export const convertToEPSG3857 = (coordinates: Coordinates[], from: EPSG) => {
  proj4.defs(EPSG3857.proj4String);
  proj4.defs(from.proj4String);

  return proj4(
    from.proj4String,
    EPSG3857.proj4String,
    coordinates.map((c) => [c.lng, c.lat]).flat()
  );
};
