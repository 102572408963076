export const USER_SET = 'USER_SET';

export interface IUserInformation {
  email?: string;
  name?: string;
  nickname?: string;
  picture?: string;
  updated_at?: string & Date;
  token?: string;
  sub?: string;
  given_name?: string;
  family_name?: string;
  locale?: string;
  email_verified?: boolean;
}

export type IUserState = IUserInformation;

interface IUserSet {
  type: typeof USER_SET;
  userInformation: IUserInformation;
}

export type userTypes = IUserSet;
