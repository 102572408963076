import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import { toaster } from 'toaster';
import { fromJsonTLE } from './helpers';
import type { Ephemeris, JSONEphemeris } from './types';

export type GetEphemerisParams = {
  missionId: number;
  format: string;
  type: string;
};

export const getEphemerisLatest = apiFetchHandler<
  JSONEphemeris,
  GetEphemerisParams,
  undefined,
  Ephemeris
>({
  host: hosts.portal.v0,
  method: 'GET',
  endpoint: ({ format, missionId, type }) =>
    `/ephemerides/${missionId}/latest?format=${format}&type=${type}`,
  errorMessage: 'Could not get latest ephemeris',
  errorDescription: (errors) => errors.join(';'),
  responseTransformer: (res, { missionId }) => {
    return fromJsonTLE(missionId)(res?.data);
  },
});

export const getEphemeris = apiFetchHandler<
  JSONEphemeris[],
  GetEphemerisParams,
  undefined,
  Ephemeris[]
>({
  host: hosts.portal.v0,
  method: 'GET',
  endpoint: ({ format, missionId, type }) =>
    `/ephemerides/${missionId}?format=${format}&type=${type}`,
  errorMessage: 'Could not get ephemeris',
  errorDescription: (errors) => errors.join(';'),
  responseTransformer: (res, { missionId }) => {
    return res?.map((data) => fromJsonTLE(missionId)(data.data));
  },
});

export type PostEphemerisParams = {
  cosparId: string;
  format: string;
};

export type PostEphemerisBody = {
  line1: string;
  line2: string;
};

export const postEphemeris = apiFetchHandler<
  undefined,
  PostEphemerisParams,
  PostEphemerisBody
>({
  host: hosts.portal.v0,
  method: 'POST',
  endpoint: ({ cosparId, format }) =>
    `/ephemerides/${cosparId}?format=${format}`,
  errorMessage: 'Could not post ephemeris',
  errorDescription: (errors) => errors.join(';'),
  onSuccess: () => {
    toaster.show({
      message: 'TLE added successfully',
      icon: 'tick',
      intent: 'success',
    });
  },
});
