import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { preventDefaultPropagation } from '../../../../../../utils/common/CommonUtils';
import type { CommandDefinition } from '_api/telecommands/types';
import s from './index.module.scss';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface IProps {
  cmd: CommandDefinition;
  isFeature: boolean;
  handleFeatureClick: Function;
  handleCommandClick: Function;
}

const CommandListSingleBody = (props: IProps) => {
  const { mission } = useParams<{ mission: string }>();
  const { cmd, isFeature, handleFeatureClick, handleCommandClick } = props;

  const { hasPermission: isAllowedToOpenCommand } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: cmd.scope,
      id: mission,
    },
  });

  const handleIconClick = useCallback(() => {
    if (!isAllowedToOpenCommand) return;
    handleFeatureClick(cmd.name);
  }, [cmd.name, handleFeatureClick, isAllowedToOpenCommand]);

  const commandStyles = useMemo(
    () =>
      isAllowedToOpenCommand
        ? s.commandListSingleBodyContainer
        : s.commandListSingleBodyContainerDisabled,
    [isAllowedToOpenCommand]
  );

  return (
    <li
      className={commandStyles}
      //@ts-expect-error
      name={'command-' + cmd.name}
    >
      <Icon
        className={s.commandFeature}
        icon={isFeature ? IconNames.STAR : IconNames.STAR_EMPTY}
        onClick={preventDefaultPropagation(handleIconClick)()}
      />
      <div
        className={s.command}
        role="button"
        tabIndex={0}
        onKeyDown={() => {
          isAllowedToOpenCommand && handleCommandClick(cmd);
        }}
        onClick={() => {
          isAllowedToOpenCommand && handleCommandClick(cmd);
        }}
      >
        {cmd.name}
      </div>
    </li>
  );
};

export default CommandListSingleBody;
