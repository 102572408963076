import { useState, useCallback } from 'react';

const MAX_ELEMENTS_IN_MSG_LOG = 100;

export interface IMessageLog {
  description: string;
  level: string;
  time: string;
  key: number;
}

export const useMessageLog = () => {
  const [messages, setMessages] = useState<IMessageLog[]>([]);

  const addMessage = useCallback((message: IMessageLog) => {
    setMessages((msgs) => {
      const data = [
        ...msgs,
        { ...message, key: Math.random() + new Date().getTime() },
      ];
      if (data.length > MAX_ELEMENTS_IN_MSG_LOG) {
        data.shift();
      }

      return data;
    });
  }, []);

  return {
    addMessage,
    messages,
  };
};
