import { toaster } from 'toaster';
import ColormapPreviewBar from 'datacosmos/components/ColormapPreviewBar';
import type { COLORMAP } from 'datacosmos/entities/bandAlgebraLayer';
import {
  BandAlgebraInputType,
  ColormapValues,
} from 'datacosmos/entities/bandAlgebraLayer';
import type { ICustomBandAlgebra } from 'datacosmos/services/localStorageBandAlgebra';
import type { IExpressionDetails } from 'datacosmos/utils/algebraExpressionFormatter';
import { COMMON_BAND_NAMES } from 'datacosmos/utils/algebraExpressionFormatter';
import { Formik } from 'formik';
import { v1 as uuid } from 'uuid';
import SelectItem from '_atoms/SelectItem/SelectItem';
import Button from '_molecules/Button/Button';
import Input from '_molecules/Input/Input';
import Select from '_molecules/Select/Select';
import SuggestionInput from '_molecules/SuggestionInput/SuggestionInput';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  saveCustomAlgebra: (algebra: ICustomBandAlgebra) => void;
  toggleSelectedIndex: (index: IExpressionDetails) => void;
}

const ExpressionBased = (props: IProps) => {
  const { translate } = useLocalisation();

  return (
    <Formik
      initialValues={{
        expression: '',
        selectedColor: ColormapValues[0],
        min: undefined as string | undefined,
        max: undefined as string | undefined,
        name: undefined as string | undefined,
        description: undefined as string | undefined,
        apply: false,
      }}
      onSubmit={(values, { setErrors, setValues }) => {
        if (values.expression === '') {
          setErrors({
            expression: translate(
              'datacosmos.layers.bandAlgebra.custom.index.errors.noExpression'
            ),
          });
          return;
        }

        if (values.max === undefined) {
          setErrors({
            max: translate(
              'datacosmos.layers.bandAlgebra.custom.index.errors.noMaximum'
            ),
          });
          return;
        }

        if (values.min === undefined) {
          setErrors({
            max: translate(
              'datacosmos.layers.bandAlgebra.custom.index.errors.noMinimum'
            ),
          });
          return;
        }

        if (values.name === undefined) {
          setErrors({
            name: translate(
              'datacosmos.layers.bandAlgebra.custom.index.errors.noName'
            ),
          });
          return;
        }

        props.saveCustomAlgebra({
          id: uuid(),
          colorMap: values.selectedColor,
          expression: values.expression,
          max: parseFloat(values.max ? values.max : '0'),
          min: parseFloat(values.min ? values.min : '0'),
          uiName: values.name ? values.name : '',
          description: values.description,
          algebraType: BandAlgebraInputType.SINGLEBAND,
        });

        if (values.apply) {
          props.toggleSelectedIndex({
            expression: values.expression,
            recommendedColormap: values.selectedColor,
            max: parseFloat(values.max ? values.max : '0'),
            min: parseFloat(values.min ? values.min : '0'),
            uiName: values.name ? values.name : '',
            description: values.description,
            algebraType: BandAlgebraInputType.SINGLEBAND,
          });
          void setValues({ ...values, apply: false });
        }

        toaster.show({
          message: translate('datacosmos.layers.bandAlgebra.custom.success'),
          intent: 'success',
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        setValues,
        errors,
        setFieldValue,
      }) => {
        return (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-4">
              <SuggestionInput
                type="text"
                label={{
                  text: translate(
                    'datacosmos.layers.bandAlgebra.custom.index.expression'
                  ),
                  position: 'left',
                }}
                name="expression"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder={translate(
                  'datacosmos.layers.bandAlgebra.custom.index.expressionPlaceholder'
                )}
                errorMsg={errors.expression?.toString()}
                value={values.expression}
                suggestionItems={COMMON_BAND_NAMES}
                onSuggestionClick={(suggestion) => {
                  void setFieldValue('expression', suggestion);
                }}
                popoverProps={{ target: 'right-menu' }}
              />
            </div>

            <div className="flex flex-col gap-4 border-b-2 border-b-contrast-inactive pb-8 mb-4">
              <div className="flex items-center gap-4 ">
                <span className="text-sm whitespace-nowrap">
                  {translate('datacosmos.layers.bandAlgebra.custom.pallet')}{' '}
                </span>
                <Select<COLORMAP>
                  items={ColormapValues}
                  fill
                  noBg
                  target="right-menu"
                  chevronPosition="right"
                  outsideClickClose
                  itemRenderer={(item, handleClick) => (
                    <SelectItem
                      key={item}
                      text={
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateRows: '0.5fr 0.5fr',
                            gap: '1px',
                          }}
                        >
                          <span>{item}</span>
                          <div style={{ width: '100%' }}>
                            <ColormapPreviewBar colormap={item} />
                          </div>
                        </div>
                      }
                      onClick={() => handleClick(item)}
                    />
                  )}
                  handleClick={(item) => {
                    void setValues({ ...values, selectedColor: item });
                  }}
                  iconProps={{ size: 24 }}
                >
                  <ColormapPreviewBar
                    colormap={values.selectedColor}
                    scaleMin={{
                      value: values.min, // TODO: was values.description, not sure if that's needed in future
                      setMin: (min) => {
                        void setValues({ ...values, min });
                      },
                    }}
                    scaleMax={{
                      value: values.max,
                      setMax: (max) => {
                        void setValues({ ...values, max });
                      },
                    }}
                  />
                </Select>
              </div>

              {errors.min && (
                <small className="text-warning ">{errors.min.toString()}</small>
              )}
              {errors.max && (
                <small className="text-warning ">{errors.max.toString()}</small>
              )}
            </div>

            <div className="flex flex-col gap-8">
              <Input
                label={{
                  text: translate('datacosmos.layers.bandAlgebra.custom.name'),
                  position: 'left',
                }}
                type="text"
                name="name"
                onChange={handleChange}
                errorMsg={errors.name?.toString()}
              />

              <Input
                label={{
                  text: translate(
                    'datacosmos.layers.bandAlgebra.custom.description'
                  ),
                  position: 'left',
                }}
                type="textarea"
                name="description"
                onChange={handleChange}
              />
            </div>

            <div className="flex w-full justify-end gap-4">
              <Button
                text={translate('datacosmos.layers.bandAlgebra.custom.save')}
                icon="Save"
                iconPlacement="left"
                onPress={() => handleSubmit()}
              />

              <Button
                text={translate(
                  'datacosmos.layers.bandAlgebra.custom.saveAndApply'
                )}
                icon="Save"
                iconPlacement="left"
                onPress={() => {
                  void setValues({ ...values, apply: true });
                  handleSubmit();
                }}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ExpressionBased;
