import React from 'react';
import CommandGroupArgument from './CommandGroupArgument';
import getFieldsBySchema from '../../utils/rtiCommands/getFieldsBySchema';
import CommandPrimitiveAutocompleteWrapper from './CommandPrimitiveAutocompleteWrapper';
import s from './index.module.scss';
import type { CommandProperties } from '_api/gateway/types';

export interface IProps {
  className?: string;
  commandProperties?: CommandProperties;
  handleCommandPayloadChange: (path: string, value: unknown) => void;
  handleCommandPayloadBlur?: () => void;
  commandPayload: unknown;
  handleDisable?: (value: boolean) => void;
  handleMetaButtonClick?: (value: string) => void;
  canBlur?: boolean;
  canSetDefaultValue?: boolean;
  disabled?: boolean;
}

export type OptionalField = {
  title?: string;
  description?: string;
  sortingPreference?: number;
  defaultValue?: string | boolean;
  format?: string;
  enumValue?: string[];
  meta?: string | null;
  media?: { binaryEncoding?: string };
  examples?: string[];
  maxItems?: number;
};

export type PropertyGroup = {
  type: string;
  optionalFields: OptionalField;
  groupPath: string[];
  path?: null | string;
  ocSortingPreference?: number;
  ocMeta?: string | null;
  ocSecret?: boolean;
};

export type IProperty = {
  path: string;
  groupPath: string[];
  type: string;
  optionalFields: OptionalField;
  group: PropertyGroup[];
  required: boolean;
  multiline: boolean;
};

const CommandArguments = (props: IProps) => {
  const { commandProperties } = props;

  if (!commandProperties) {
    return null;
  }

  return (
    <div className={[props.className, s.arguments].join(' ')}>
      <CommandPrimitiveAutocompleteWrapper>
        {getFieldsBySchema(commandProperties).map((property: IProperty) => (
          <CommandGroupArgument
            key={property.path}
            {...props}
            property={property}
            value={props.commandPayload}
            onBlur={() => props.handleCommandPayloadBlur?.()}
            onChange={(value, path) =>
              props.handleCommandPayloadChange(path, value)
            }
          />
        ))}
      </CommandPrimitiveAutocompleteWrapper>
    </div>
  );
};

export default CommandArguments;
