import type { IAsset } from 'datacosmos/types/stac-types';
import classNames from 'classnames';
import type { MouseEventHandler } from 'react';

export type ThumbnailProps = {
  displayAsSelected: boolean;
  thumbnailAsset?: IAsset;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
};

export const Thumbnail = (props: ThumbnailProps) => {
  return (
    <button
      className={classNames(
        'group overflow-hidden bg-item dark:bg-item-dark border-2 w-40 h-40 box-border flex justify-center items-center',
        {
          'border-accent': props.displayAsSelected,
          'border-item dark:border-item-dark': !props.displayAsSelected,
        }
      )}
      onClick={props.handleClick}
      data-testid="stac-item-card-thumbnail"
    >
      <img
        alt="Result thumbnail"
        src={
          props.thumbnailAsset?.href ?? '/images/datacosmos/img-placeholder.svg'
        }
        className="max-h-40 max-w-40 box-border p-1 group-hover:scale-105 transition"
      />
    </button>
  );
};
