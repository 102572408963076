import type { AppState } from 'pages/shared/state/reducers/rootReducer';
import type {
  IOperationalModesByPayload,
  ISatelliteModules,
  IPlatformModules,
  IHydratedSatelliteModules,
} from 'constants/satellite/types';

export const selectOperationalModesByPayload = (
  state: AppState
): IOperationalModesByPayload => {
  const operationalModesByPayload: IOperationalModesByPayload = {};

  Object.entries(state.satellite.currentModeConfigurations).forEach(
    ([payloadName, payload]) => {
      const operationalModeNames = Object.keys(payload);
      operationalModesByPayload[payloadName] = operationalModeNames;
    }
  );

  return operationalModesByPayload;
};

export const selectSatelliteModes = (state: AppState): string[] =>
  Object.keys(state.satellite.currentModeConfigurations);

export const selectLastSatelliteMode = (state: AppState): string => {
  const satelliteModes = Object.keys(state.satellite.currentModeConfigurations);
  return satelliteModes.length ? satelliteModes[0] : '';
};

export const selectSatelliteModuleNames = (state: AppState) =>
  state.satellite.moduleNames;

export const hydrateModulesFromNames = (
  state: AppState,
  modules: ISatelliteModules | IPlatformModules
) =>
  Object.entries(modules).map(([moduleType, selectedModuleNames]) =>
    (selectedModuleNames as string[]).map(
      (moduleName) =>
        state.moduleData[moduleType as keyof typeof state.moduleData][
          moduleName
        ]
    )
  );

export const hydrateModulesFromNamesObject = (
  state: AppState,
  modules: ISatelliteModules | IPlatformModules
): IHydratedSatelliteModules => {
  const hydratedModules = {} as IHydratedSatelliteModules;

  Object.entries(modules).forEach(([moduleType, selectedModuleNames]) => {
    hydratedModules[moduleType as keyof IHydratedSatelliteModules] = (
      selectedModuleNames as string[]
    ).map((moduleName) => {
      return state.moduleData[moduleType as keyof typeof state.moduleData][
        moduleName
      ];
    });
  });

  return hydratedModules;
};

export const selectSatelliteModules = (state: AppState) =>
  hydrateModulesFromNames(state, state.satellite.moduleNames);

export const selectHydratedSatelliteModules = (
  state: AppState
): IHydratedSatelliteModules =>
  hydrateModulesFromNamesObject(state, state.satellite.moduleNames);
