import React, { useCallback, useState } from 'react';
import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { submitWorkflow } from '_api/pdgs/service';
import { Input, ListBoxItem, Select } from 'opencosmos-ui';
import { useProjects } from 'datacosmos/stores/ProjectProvider';

type Props = {
  app: IApplication;
};

//TODO: Not sure if these are formatted correctly. Feedback needed.
const PROCESSING_LEVELS = {
  L1A: 'saocom-l1a',
};

type ProcessingLevels = typeof PROCESSING_LEVELS;

export const SAOCOMIngestionApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate('datacosmos.applications.satIngestion.title', {
    name: 'SAOCOM',
  }),
  description: clientTranslate(
    'datacosmos.applications.satIngestion.description',
    { name: 'SAOCOM' }
  ),
  inputs: [
    {
      field: 'saocom-foldername',
      example: '',
    },
    {
      field: 'level',
      example: '',
    },
  ],
  values: {
    'saocom-foldername': { value: '', isError: false, message: '' },
    level: { value: '', isError: false, message: '' },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.satIngestion.shortDescription',
    {
      name: 'SAOCOM',
    }
  ),
  renderer: (app: IApplication) => <SAOCOMIngestion app={app} />,
  appScreenshotUrl: '',
  tags: [],
  isTraceableWorkflow: true,
};

const SAOCOMIngestion = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const [isSubmittingWorkflow, setIsSubmittingWorkflow] =
    useState<boolean>(false);

  const { translate } = useLocalisation();

  const { currentScenario } = useProjects();

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const resetInputValues = useCallback(() => {
    setValue('saocom-foldername', '');
    setValue('level', '');
  }, [setValue]);

  const inputs = useCallback(() => {
    return (
      <div>
        <div className="flex flex-col gap-4 mt-4 mb-4">
          <Input
            type="text"
            value={app.values['saocom-foldername']?.value as string}
            onChange={(e) => {
              setValue('saocom-foldername', e.target.value);
            }}
            label={{
              position: 'top',
              text: translate(
                'datacosmos.applications.satIngestion.inputs.folderPath'
              ),
            }}
          />

          {app.values['saocom-foldername']?.isError && (
            <div>
              <small className="text-warning">
                {app.values['gaofen-foldername'].message}
              </small>
            </div>
          )}

          <Select
            fill
            name="processingLevel"
            label={translate('datacosmos.filters.processingLevel')}
            placeholder=" "
            selectedKey={app.values.level.value as unknown as string}
            onSelectionChange={(val) => setValue('level', val)}
          >
            {Object.keys(PROCESSING_LEVELS).map((level) => (
              <ListBoxItem key={level} id={level}>
                {level}
              </ListBoxItem>
            ))}
          </Select>
        </div>
      </div>
    );
  }, [app.values, setValue, translate]);

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (!currentScenario) {
            return;
          }
          setIsSubmittingWorkflow(true);
          await submitWorkflow({
            body: {
              name: PROCESSING_LEVELS[
                app.values.level.value as unknown as keyof ProcessingLevels
              ],
              inputs: {
                'product-filename': app.values['saocom-foldername']
                  ?.value as string,
              },
              labels: {
                'app.opencosmos.com/project_id': currentScenario?.id,
              },
            },
          });
          resetInputValues();
          setIsSubmittingWorkflow(false);
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmittingWorkflow}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default SAOCOMIngestion;
