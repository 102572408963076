import type { IPointOfInterest } from '../../../constants/pointsOfInterest/actionTypes';
import type { IMapWrapper } from '../../../declarations/mapDeclarations/Map';
import type { IMarkerWrapper } from '../../../declarations/mapDeclarations/Marker';

export const addPointOfInterest = (
  pointOfInterestData: IPointOfInterest,
  map: IMapWrapper,
  options?: google.maps.MarkerOptions
): IMarkerWrapper => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { id, lat, lon, name, altitude, elevationAngle } = pointOfInterestData;

  const pointOfInterest: IMarkerWrapper = new google.maps.Marker({
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    id,
    name,
    altitude,
    elevationAngle,
    position: { lat: lat, lng: lon },
    map,
    ...options,
  });
  if (map) {
    map.pointsOfInterest?.push(pointOfInterest);
    return pointOfInterest;
  }
  //@ts-expect-error
  return undefined;
};

export const updatePointOfInterest = (
  pointOfInterestData: IPointOfInterest,
  pointOfInterest: IMarkerWrapper,
  map: IMapWrapper,
  options?: google.maps.MarkerOptions
): boolean => {
  const { name, lat, lon, altitude, elevationAngle, orderIndex } =
    pointOfInterestData;
  pointOfInterest.name = name;
  pointOfInterest.altitude = altitude;
  pointOfInterest.elevationAngle = elevationAngle;
  pointOfInterest.orderIndex = orderIndex;
  const position = new google.maps.LatLng(lat, lon);
  pointOfInterest.setPosition(position);
  if (options) {
    pointOfInterest.setOptions(options);
  }
  return true;
};

export const removePointOfInterest = (
  pointOfInterestIndex: number,
  map: IMapWrapper
): void => {
  const pointOfInterest: IMarkerWrapper | undefined =
    map.pointsOfInterest?.[pointOfInterestIndex];
  pointOfInterest?.setMap(null);
  map.pointsOfInterest?.splice(pointOfInterestIndex, 1);
};
