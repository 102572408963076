import type { IGroundStation } from '../groundStations/actionTypes';
import type { ISatellite } from '../../components/optimizationPanel/constraints/genericConstellation/GenericConstellation';
import type {
  IWalkerConstellation,
  GENERIC_CONSTELLATION,
  LAUNCH_OPPORTUNITIES,
  WALKER_CONSTELLATION,
} from './constants';
import type { ILaunchOpportunity } from './actionTypesLaunchOpportunity';

export const CONSTRAINTS_UPDATE = 'CONSTRAINTS_UPDATE';
export const CONSTRAINTS_REMOVE = 'CONSTRAINTS_REMOVE';

export interface IConstraints {
  groundStations: IGroundStation[];

  satellites?: ISatellite[];
  walkerConstellation?: IWalkerConstellation;
  launchOpportunities?: ILaunchOpportunity[];
  tabId?:
    | typeof GENERIC_CONSTELLATION
    | typeof LAUNCH_OPPORTUNITIES
    | typeof WALKER_CONSTELLATION;
}

interface ICUpdateAction {
  type: typeof CONSTRAINTS_UPDATE;
  constraints: IConstraints;
}

interface ICRemoveAction {
  type: typeof CONSTRAINTS_REMOVE;
}

export type IConstraintsActionTypes = ICUpdateAction | ICRemoveAction;
