import { hosts } from '_api/hosts';
import type { CloudStorageContent } from '_api/cloudStorage/types';
import { apiFetchHandler } from '_api/clientFetch';
import { apiAxiosHandler } from '_api/clientAxios';

export type CloudStorageApiParams = {
  resourceId: number;
  path: string;
  resource: string;
};

export type DeleteFileOrFolderParams = {
  fileName: string;
};

export type CreateOrUpdateFileBody = {
  file: string;
};

export const getFolderContent = apiFetchHandler<
  CloudStorageContent[],
  CloudStorageApiParams
>({
  host: hosts.portal.v1,
  endpoint: ({ resourceId, path, resource }) =>
    `/cloud-storage/${resource}/${resourceId}/${path}/`,
  method: 'GET',
  errorMessage: 'Could not fetch folder contents',
  errorDescription: (e) => e.join('; '),
});

export const getFileContent = apiAxiosHandler<
  ArrayBuffer,
  CloudStorageApiParams
>({
  host: hosts.portal.v1,
  endpoint: ({ resourceId, path, resource }) =>
    `/cloud-storage/${resource}/${resourceId}/${path}?file=true`,
  method: 'GET',
  errorMessage: 'Could not fetch file contents',
  errorDescription: (e) => e.join('; '),
});

export const getFileMetadata = apiFetchHandler<
  CloudStorageContent,
  CloudStorageApiParams
>({
  host: hosts.portal.v1,
  endpoint: ({ resourceId, path, resource }) =>
    `/cloud-storage/${resource}/${resourceId}/${path}`,
  method: 'GET',
  errorMessage: 'Could not fetch file metadata',
  errorDescription: (e) => e.join('; '),
});

export const createOrUpdateFolder = apiFetchHandler<
  'ok',
  CloudStorageApiParams
>({
  host: hosts.portal.v1,
  endpoint: ({ resourceId, path, resource }) =>
    `/cloud-storage/${resource}/${resourceId}/${path}`,
  method: 'PUT',
  errorMessage: 'Could not create folder',
  errorDescription: (e) => e.join('; '),
});

export const createOrUpdateFile = apiAxiosHandler<
  'ok',
  CloudStorageApiParams,
  ArrayBuffer | string
>({
  host: hosts.portal.v1,
  endpoint: ({ resourceId, path, resource }) => {
    return `/cloud-storage/${resource}/${resourceId}/${path}?file=true`;
  },
  method: 'PUT',
  errorMessage: 'Could not put file contents',
  errorDescription: (e) => e.join('; '),
});

export const deleteFileOrFolder = apiFetchHandler<
  'ok',
  CloudStorageApiParams & DeleteFileOrFolderParams
>({
  host: hosts.portal.v1,
  endpoint: ({ resourceId, path, resource, fileName }) =>
    `/cloud-storage/${resource}/${resourceId}/${path}/${fileName}`,
  method: 'DELETE',
  errorMessage: 'Could not delete file or folder',
  errorDescription: (e) => e.join('; '),
});
