import React, { useEffect } from 'react';
import type { Moment } from 'moment';
import moment from 'moment';
import { isNil } from 'lodash';

import s from './styles.module.scss';

export function useNewTimer(targetDate: moment.Moment) {
  const now = moment().utc();
  const [duration, setDuration] = React.useState(
    moment.duration(now.diff(targetDate))
  );

  useEffect(() => {
    function tick() {
      const currentMoment = moment().utc();
      setDuration(moment.duration(currentMoment.diff(targetDate)));
    }

    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return duration;
}

export interface IProps {
  target: Moment;
  label: string;
  handleTimerOnCertainTime?: (timer: moment.Duration) => void;
}

export function format(duration: moment.Duration): string {
  let hours = duration.asHours();
  const negative = hours < 0;

  hours = hours * (negative ? -1 : 1);
  hours = Math.floor(hours);
  const minutes = duration.minutes() * (negative ? -1 : 1);
  const seconds = duration.seconds() * (negative ? -1 : 1);

  return `${negative ? '-' : '+'}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

function Timer(props: IProps) {
  const duration = useNewTimer(props.target);

  props.handleTimerOnCertainTime?.(duration);

  const noTarget = isNil(props.target);
  if (noTarget) {
    return <div />;
  }

  const targetText = props.target.calendar();
  return (
    <div className={s.container}>
      <div className={s.timer}>{format(duration)}</div>
      <div className={s.date}>
        {props.label} {targetText}
      </div>
    </div>
  );
}

export default Timer;
