import type { StacItem } from 'datacosmos/types/stac-types';
import { SingleBandSTACLayer } from './singleBandLayer';
import { BandAlgebraSTACLayer } from './bandAlgebraLayer';
import { OutlineLayer } from './outlineLayer';

/**
 * Input data required to create a new STAC Layer.
 * Either only assetKey, only expression or neither must be set.
 */
export interface ISTACLayerSpecification {
  item: StacItem;
  assetKey?: string;
  expression?: string;
  expressionId?: string | undefined;
}

/**
 * Create a new layer based on a STAC item
 * @param spec ths specification for which asset/combination to show
 * @returns a STACLayer created based on the provided specification
 */
export function STACLayerFactory(spec: ISTACLayerSpecification) {
  if (spec.assetKey) {
    return new SingleBandSTACLayer(spec.item, spec.assetKey);
  }
  if (spec.expression) {
    return new BandAlgebraSTACLayer(
      spec.item,
      spec.expressionId
        ? spec.expressionId + '::' + spec.expression
        : spec.expression
    );
  }
  return new OutlineLayer(spec.item);
}
