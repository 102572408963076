import { Icon, Tooltip } from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';

interface FilterTitleProps {
  tooltipContent?: string;
  title: string;
  icon: IconName;
  className?: string;
}

const FilterTitle = ({
  title,
  tooltipContent,
  icon,
  className,
}: FilterTitleProps) => {
  return (
    <h5
      className={`${
        className! as string
      } flex items-center gap-1 font-bold text-base my-0.5 dark:text-item-dark-contrast`}
    >
      <Icon icon={icon} />
      <span>{title}</span>

      {tooltipContent && (
        <Tooltip content={tooltipContent}>
          <Icon icon={'Info'} />
        </Tooltip>
      )}
    </h5>
  );
};

export default FilterTitle;
