import Fuse from 'fuse.js';

import type { Telemetry } from '_api/telemetry/types';

import { filterOptions } from './constants';

export const filterTelemetries = (
  filterString: string,
  telemetries: Telemetry[],
  setFilteredTelemetries: (telemetries: Telemetry[]) => void
) => {
  const fuse = new Fuse(telemetries, filterOptions);
  const result = fuse.search(filterString);
  setFilteredTelemetries(result as Telemetry[]);
};
