import { useState } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Dialog, Icon, Input } from 'opencosmos-ui';
import type { TaskingRequest } from '_api/tasking/types';
import { patchTaskingRequest } from '_api/tasking/service';

type CancelRequestProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  request: TaskingRequest;
  refetchData?: () => Promise<void>;
};

export const CancelRequest = ({
  isOpen,
  setIsOpen,
  request,
  refetchData,
}: CancelRequestProps) => {
  const [cancellationReason, setCancellationReason] = useState<string>('');

  const { translate } = useLocalisation();

  const handleInput = (text: string): void => {
    setCancellationReason(text);
  };

  const handleDelete = async () => {
    if (!request?.id) {
      return;
    }
    const success = await patchTaskingRequest({
      params: { requestId: request.id },
      body: { status: 'CANCELLED', status_reason: cancellationReason },
    });

    if (success) {
      setIsOpen(false);
      if (refetchData) {
        await refetchData();
      }
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      hideCancelButton
      onClose={() => setIsOpen(false)}
      title={translate('datacosmos.cancelRequestDialog.title')}
      buttons={[
        {
          text: translate('datacosmos.buttons.confirm'),
          shown: true,
          onPress: () => void handleDelete(),
          isDisabled: !cancellationReason?.length,
        },
        {
          text: translate('datacosmos.buttons.cancel'),
          shown: true,
          onPress: () => setIsOpen(false),
        },
      ]}
    >
      <div className="flex flex-col gap-2">
        <h4>
          {translate('datacosmos.cancelRequestDialog.areYouSure', {
            type: request?.type,
            name: request?.region_name,
          })}
        </h4>

        <Input
          type="text"
          placeholder={translate('datacosmos.cancelRequestDialog.placeholder')}
          onChange={(e) => handleInput(e.target.value)}
        />

        <div className="flex items-center gap-2 bg-accent-200 dark:bg-accent-dark my-2 p-1 text-xs dark:text-item-contrast">
          <Icon icon="Info" />
          <span>{translate('datacosmos.cancelRequestDialog.longWarning')}</span>
        </div>
      </div>
    </Dialog>
  );
};
