import React, { useMemo, useState } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  AreaChart,
  Area,
  ReferenceLine,
  Legend,
} from 'recharts';
import { Tabs, TabList, Tab, TabPanel, Dialog } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { DetailsItem } from 'pages/ops/shared/components/DetailsItem';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import type {
  ICOGMetadataForRange,
  IHistogramData,
} from 'datacosmos/services/tilingApi/cogMetadata';
import { screenshot } from 'utils/screenshot';
import { downloadFilUrlAsExtension } from 'datacosmos/download/geojson';

type Props = {
  /**
   * Whether or not the metadata is being fetched
   */
  isFetchingMetadata: boolean;
  /**
   * Whether or not the modal is open
   */
  isOpen: boolean;
  /**
   * Function to set the modal open state
   * @param isOpen state to set
   */
  setIsOpen: (isOpen: boolean) => void;
  /**
   * Histogram data to display
   */
  histogramData?: IHistogramData[];
  /**
   * Title of the modal
   */
  modalTitle: string;
  /**
   * Properties data to display. This is the data that is displayed below the histogram
   */
  data: ICOGMetadataForRange;
  /**
   * Function to get the color of the histogram based on the color in the histogram data.
   * Data will usually be labeled with a certain color, such as `redX`, `redY`, `greenX`, `greenY`, etc.
   * This function should return the color based on the label.
   * @param data label of the data e.g. `red`, `green`, `blue`, etc.
   * @returns color of the histogram
   */
  getHistogramColor: (
    data: string
  ) => '#FF695E' | '#5ABE96' | '#5a89be' | '#E79531';
  /**
   * Handler function to get the statistics for the AOI. This function should draw the AOI on the map and then call the `fetchAssetsMetadata` function.
   */
  getStatisticsForAoiHandler: () => Promise<void>;

  /**
   * Handler function to remove the statistics AOI from the map.
   * This function should remove the AOI from the map and then call the `fetchAssetsMetadata` function.
   */
  removeStatisticsAoiHandler: () => void;
  /**
   * Whether or not the statistics AOI is present on the map
   */
  isStatisticsAoiPresent: boolean;
  /**
   * Optional ref to set. Useful for screenshots
   */
  popupRef?: React.RefObject<HTMLDivElement>;
  /**
   * Optional function to call when the modal is closed
   */
  onClose?: () => void;
};

/**
 * Popup to display the statistics of the assets. This popup contains a histogram and some summary statistics.
 * It also contains a button to draw an AOI on the map to get the statistics for that AOI.
 */
const StatisticsPopup = ({
  histogramData,
  modalTitle,
  data,
  getHistogramColor,
  getStatisticsForAoiHandler,
  isStatisticsAoiPresent,
  isOpen,
  setIsOpen,
  removeStatisticsAoiHandler,
  isFetchingMetadata,
  popupRef,
  onClose,
}: Props) => {
  const { translate, translateWithExplicitFallback } = useLocalisation();

  const { isDarkmode } = useTheme();

  let isDrawingAoiForStatistics = false;

  const isLoading = useMemo(() => isFetchingMetadata, [isFetchingMetadata]);

  const [isCapturingScreenshot, setIsCapturingScreenshot] =
    useState<boolean>(false);

  const getTabLabels = () => {
    if (histogramData?.length && histogramData.length <= 0) {
      return [];
    }

    if (histogramData?.[0] === undefined) {
      return [];
    }

    return [
      ...new Set(
        Object.keys(histogramData[0]).map((k) => k.replace(/X|Y/, ''))
      ),
    ];
  };

  const withNoStatistics = (children: React.ReactNode) => {
    if (!histogramData?.length) {
      return (
        <NonIdealState
          title={
            <span>
              {translate(
                'datacosmos.catalogAndItems.metadata.noStatistics.title'
              )}
            </span>
          }
          description={translate(
            'datacosmos.catalogAndItems.metadata.noStatistics.description'
          )}
          icon="info-sign"
          className="text-surface-contrast dark:text-surface-dark-contrast"
        />
      );
    }

    return children;
  };

  const withLoadingSpinner = (children: React.ReactNode) => {
    if (isLoading) {
      return <Spinner size={36} />;
    }

    return children;
  };

  const getBandSpecificHistogram = (bandNumber: number, bandName: string) => {
    return (
      <>
        <div className="flex justify-center">
          <AreaChart
            data={histogramData ?? []}
            height={330}
            width={450}
            barSize={'25px'}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={`${bandName}X`}
              stroke={isDarkmode ? '#E6E1DC' : '#191919'}
              tickFormatter={(d: string) => Number(d).toFixed(2)}
              domain={[0, 'maxData']}
              type="number"
              allowDataOverflow={true}
              tickSize={3}
              tick={{ fontSize: 13 }}
              label={{
                value: translate(
                  'datacosmos.catalogAndItems.metadata.xAxisLabel'
                ),
                position: 'insideBottom',
                dy: 6,
                className: 'dark:fill-surface-dark-contrast',
              }}
            />
            <YAxis
              dataKey={`${bandName}Y`}
              stroke={isDarkmode ? '#E6E1DC' : '#191919'}
              tickSize={1}
              tick={{ fontSize: 13 }}
              label={{
                value: translate(
                  'datacosmos.catalogAndItems.metadata.yAxisLabel'
                ),
                position: 'insideLeft',
                angle: -90,
                dy: 20,
                dx: -5,
                className: 'dark:fill-surface-dark-contrast',
              }}
            />
            <Area
              dataKey={`${bandName}Y`}
              stroke={getHistogramColor(bandName)}
              fill={getHistogramColor(bandName)}
              legendType="none"
            />
            <>
              <ReferenceLine
                x={data.getBandStatistics(bandNumber).mean}
                stroke={isDarkmode ? '#ffffff' : '#17202A'}
              />

              <Area
                name={`${translate(
                  'datacosmos.catalogAndItems.metadata.meanValue'
                )} ${data.getBandStatistics(bandNumber).mean}`}
                dataKey="null"
                stroke={isDarkmode ? '#ffffff' : '#17202A'}
                legendType="plainline"
              />
            </>
            <>
              <ReferenceLine
                x={Number(data.getBandStatistics(bandNumber).percentile_25)}
                stroke={'#8E44AD'}
              ></ReferenceLine>

              <Area
                name={`${translate(
                  'datacosmos.catalogAndItems.metadata.percentile25'
                )} ${data.getBandStatistics(bandNumber).percentile_25}`}
                dataKey="null"
                stroke="#8E44AD"
                legendType="plainline"
              />
            </>
            <>
              <ReferenceLine
                x={Number(data.getBandStatistics(bandNumber).percentile_75)}
                stroke={'#FFCA33'}
              ></ReferenceLine>

              <Area
                name={`${translate(
                  'datacosmos.catalogAndItems.metadata.percentile75'
                )} ${data.getBandStatistics(bandNumber).percentile_75}`}
                dataKey="null"
                stroke="#FFCA33"
                legendType="plainline"
              />
            </>

            <Legend
              wrapperStyle={{
                paddingTop: '14px',
                fontSize: '12px',
              }}
            />
            <ChartTooltip />
          </AreaChart>
        </div>

        <h3 className="text-data-dark border-b dark:text-data-text">
          {translate('datacosmos.catalogAndItems.metadata.summaryStatistics')}
        </h3>

        <div className="flex justify-center items-center">
          <div className="grid gap-4 grid-flow-col auto-cols-max !shadow-none">
            <div className="pr-3 border-r border-primary">
              <DetailsItem
                title={translate(
                  'datacosmos.catalogAndItems.metadata.minPixelValue'
                )}
                value={String(data.getBandStatistics(bandNumber).min) ?? 'N/A'}
                applyFlexStyle
              />
              <DetailsItem
                title={translate(
                  'datacosmos.catalogAndItems.metadata.maxPixelValue'
                )}
                value={String(data.getBandStatistics(bandNumber).max) ?? 'N/A'}
                applyFlexStyle
              />
            </div>

            <div className="pr-3 border-r border-primary">
              <DetailsItem
                title={translate(
                  'datacosmos.catalogAndItems.metadata.meanValue'
                )}
                value={
                  data.getBandStatistics(bandNumber).mean?.toFixed(2) ?? 'N/A'
                }
                applyFlexStyle
              />
              <DetailsItem
                title={translate(
                  'datacosmos.catalogAndItems.metadata.stdValue'
                )}
                value={
                  data.getBandStatistics(bandNumber).std?.toFixed(2) ?? 'N/A'
                }
                applyFlexStyle
              />
              <DetailsItem
                title={translate(
                  'datacosmos.catalogAndItems.metadata.variance'
                )}
                value={
                  (
                    data.getBandStatistics(bandNumber).std *
                    data.getBandStatistics(bandNumber).std
                  )?.toFixed(2) ?? 'N/A'
                }
                applyFlexStyle
              />
            </div>

            <div className="pr-3 border-r border-primary">
              <DetailsItem
                title={translate(
                  'datacosmos.catalogAndItems.metadata.percentile25'
                )}
                value={
                  String(data.getBandStatistics(bandNumber).percentile_25) ??
                  'N/A'
                }
                applyFlexStyle
              />
              <DetailsItem
                title={translate(
                  'datacosmos.catalogAndItems.metadata.percentile75'
                )}
                value={
                  String(data.getBandStatistics(bandNumber).percentile_75) ??
                  'N/A'
                }
                applyFlexStyle
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const getHistogram = () => {
    const labels = getTabLabels();

    // If there's only one label, don't show the tabs
    if (labels.length === 1) {
      return getBandSpecificHistogram(1, labels[0]);
    }

    return (
      <Tabs>
        <TabList>
          {labels.map((element, i) => (
            <>
              <Tab id={element} key={element}>
                {translateWithExplicitFallback(
                  `datacosmos.catalogAndItems.metadata.bandColors.${element}` as unknown as TemplateStringsArray,
                  element
                )}
              </Tab>
              <TabPanel id={element}>
                {getBandSpecificHistogram(i + 1, element)}
              </TabPanel>
            </>
          ))}
        </TabList>
      </Tabs>
    );
  };

  const handleClose = () => {
    setIsOpen(false);
    if (onClose && !isDrawingAoiForStatistics) {
      onClose();
    }
  };

  return (
    <Dialog
      title={modalTitle}
      isOpen={isOpen}
      onClose={handleClose}
      showButtonsInFooter
      hideCancelButton
      buttons={[
        {
          text: translate('datacosmos.buttons.removeAoiForStatistics'),
          shown: isStatisticsAoiPresent,
          icon: 'Trash',
          onPress: () => removeStatisticsAoiHandler(),
          keepDialogOpenOnPress: true,
          className: 'remove-aoi-btn',
        },
        {
          text: translate('datacosmos.buttons.drawAoiForStatistics'),
          shown: !isStatisticsAoiPresent,
          icon: 'AoiAdd',
          onPress: async () => {
            isDrawingAoiForStatistics = true;
            setIsOpen(false);
            await getStatisticsForAoiHandler();
          },
          className: 'draw-aoi-btn',
        },
        {
          text: translate('datacosmos.buttons.takeScreenshot'),
          shown: (histogramData && histogramData?.length > 0) ?? false,
          icon: 'camera',
          keepDialogOpenOnPress: true,
          showLoadingIndicator: isCapturingScreenshot,
          onPress: async () => {
            if (!popupRef) {
              return;
            }
            setIsCapturingScreenshot(true);

            const imgString = await screenshot(popupRef.current, {
              mimeType: 'image/png',
              ignore: ['draw-aoi-btn', 'remove-aoi-btn', 'modal-title'],
            });

            downloadFilUrlAsExtension(
              imgString,
              `band_statistics_${new Date().toISOString()}`,
              'png'
            );
            setIsCapturingScreenshot(false);
          },
        },
      ]}
    >
      <div ref={popupRef} className="color-surface p-4">
        {withLoadingSpinner(withNoStatistics(getHistogram()))}
      </div>
    </Dialog>
  );
};

export default StatisticsPopup;
