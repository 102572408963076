import type { Layer } from 'datacosmos/entities/layer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import { useEffect, useState } from 'react';
import { range } from 'lodash';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button, DialogTrigger, Icon, Tooltip } from 'opencosmos-ui';
import Dialog from './ControlDialog';

interface IProps {
  disabled: boolean;
  brightnessSupportedLayers: SingleBandSTACLayer[];
  replaceLayer: (layer: Layer, newIndex?: number) => void;
}

const BrightnessControl = (props: IProps) => {
  const { translate } = useLocalisation();
  const [brightnessLock, setBrightnessLock] = useState(true);

  // TODO: Use statistics from selected layers, not from the latest image
  // that has been displayed
  const channelCount =
    tilingApi.statistics && Object.keys(tilingApi.statistics).length;

  const [brightness, setBrightness] = useState<number[]>([]);

  let r = 1;
  let g = 1;
  let b = 1;

  if (
    channelCount === 1 &&
    props.brightnessSupportedLayers[0]?.options.brightness &&
    typeof props.brightnessSupportedLayers[0]?.options.brightness !== 'number'
  ) {
    r = props.brightnessSupportedLayers[0].options.brightness.r;
    g = props.brightnessSupportedLayers[0].options.brightness.g;
    b = props.brightnessSupportedLayers[0].options.brightness.b;
  }

  useEffect(() => {
    if (
      typeof props.brightnessSupportedLayers[0]?.options.brightness ===
        'number' &&
      props.brightnessSupportedLayers[0]?.options.brightness === 1
    ) {
      setBrightness([1, 1, 1]);
    }
  }, [props.brightnessSupportedLayers]);

  useEffect(() => {
    setBrightness([r, g, b]);
  }, [channelCount, r, g, b]);

  const getLabelText = (i: number) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return '-';
    }
    if (i === 0) return 'R';
    if (i === 1) return 'G';
    if (i === 2) return 'B';
    return '';
  };

  const getIndexBasedBrightnessAmount = (
    mode: 'single' | 'multiple',
    i: number,
    sliderValue: number
  ) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return sliderValue;
    }

    if (mode === 'single') {
      return {
        r: sliderValue,
        g: sliderValue,
        b: sliderValue,
      };
    }

    if (i === 0) {
      return {
        r: sliderValue,
        g: brightness[1],
        b: brightness[2],
      };
    }
    if (i === 1) {
      return {
        r: brightness[0],
        g: sliderValue,
        b: brightness[2],
      };
    }
    if (i === 2) {
      return {
        r: brightness[0],
        g: brightness[1],
        b: sliderValue,
      };
    }

    return 0;
  };

  const saveBrightnessAmount = (
    mode: 'single' | 'multiple',
    index: number,
    val: number
  ) => {
    setBrightness((prev) => {
      const newArr = prev.slice();
      newArr[index] = val;
      if (mode === 'single') {
        newArr[0] = val;
        newArr[1] = val;
        newArr[2] = val;
      }
      return newArr;
    });

    const brightnessAmount = getIndexBasedBrightnessAmount(mode, index, val);
    props.replaceLayer(
      props.brightnessSupportedLayers[0].cloneWithOptions({
        brightness: brightnessAmount,
        isSettingBrightness: true,
      })
    );
  };

  const brightnessSlider = (mode: 'single' | 'multiple', index: number = 0) => {
    return (
      <RangeSlider
        className={mode === 'single' ? 'px-2' : ''}
        showScale={false}
        showValuesAboveHandles
        numberOfHandles={1}
        minValue={0}
        step={0.1}
        maxValue={5}
        value={[brightness[index]]}
        onChange={(val) => {
          setBrightness((prev) => {
            const newArr = prev.slice();
            newArr[index] = val[0];
            if (mode === 'single') {
              newArr[0] = val[0];
              newArr[1] = val[0];
              newArr[2] = val[0];
            }
            return newArr;
          });
          props.brightnessSupportedLayers[0].setSettingBrightnessOn();
        }}
        onChangeEnd={(val) => {
          saveBrightnessAmount(mode, index, val[0]);
        }}
      />
    );
  };

  const renderBrightnessSlider = () => {
    if (brightnessLock || channelCount === 1) {
      return brightnessSlider('single');
    }

    if (channelCount) {
      return range(channelCount).map((i) => (
        <div key={i} className="px-2 gap-2 flex items-center">
          <span className="w-2 -translate-y-[1px]">{getLabelText(i)}</span>
          {brightnessSlider('multiple', i)}
        </div>
      ));
    }

    return null;
  };

  return (
    <DialogTrigger>
      <Tooltip
        content={
          props.disabled
            ? `${translate('datacosmos.layers.brightness')} - ${translate(
                'datacosmos.layers.layerOperationDisabled'
              )}`
            : translate('datacosmos.layers.brightness')
        }
      >
        <Button
          icon="ImageBrightness"
          size="lg"
          className={`px-0 ${
            props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          }`}
          isDisabled={props.disabled}
          isMinimal
          isTransparent
          data-testid="brightness-button"
        />
      </Tooltip>
      <Dialog>
        <div data-testid="brightness-popup">
          {channelCount ? null : <span>Loading...</span>}
          <label className="flex px-2 gap-2 items-center">
            <Icon icon="ImageBrightness" />
            {translate('datacosmos.layers.brightness')}
            <div className="flex-1" />
            {channelCount > 1 && (
              <Button
                icon={brightnessLock ? 'lock' : 'unlock'}
                onPress={() => {
                  if (!brightnessLock) {
                    saveBrightnessAmount('single', 0, brightness[0]);
                  }

                  setBrightnessLock(!brightnessLock);
                }}
                isMinimal
                isTransparent
                data-testid="brightness-lock-button"
              />
            )}
          </label>
          {renderBrightnessSlider()}
        </div>
      </Dialog>
    </DialogTrigger>
  );
};

export default BrightnessControl;
