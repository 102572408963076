import { apiFetchHandler } from '_api/clientFetch';
import type { Device } from './types';
import { hosts } from '_api/hosts';

export const getAllDevices = apiFetchHandler<Device[]>({
  method: 'GET',
  endpoint: '/gse-management/gse',
  host: hosts.ops.noVersion,
  errorMessage: 'Could not fetch devices',
});

type AssignOrUnassignDeviceToMissionParams = {
  missionId: string;
  deviceName: string;
};

export const postAssignDeviceToMission = apiFetchHandler<
  Device,
  AssignOrUnassignDeviceToMissionParams
>({
  method: 'POST',
  endpoint: ({ deviceName, missionId }) =>
    `/gse-management/mission/${missionId}/gse/${deviceName}/assignment`,
  host: hosts.ops.noVersion,
  errorMessage: 'Could not assign device to mission',
  errorDescription: (error) => error.join(', '),
});

export const deleteAssignedDeviceFromMission = apiFetchHandler<
  Device,
  AssignOrUnassignDeviceToMissionParams
>({
  method: 'DELETE',
  endpoint: ({ deviceName, missionId }) =>
    `/gse-management/mission/${missionId}/gse/${deviceName}/assignment`,
  host: hosts.ops.noVersion,
  errorMessage: 'Could not unassign device from mission',
  errorDescription: (error) => error.join(', '),
});
