import type IGeometryObject from '../IGeometryObject';

export const RI_ADD = 'RI_ADD';
export const RI_UPDATE = 'RI_UPDATE';
export const RI_REMOVE = 'RI_REMOVE';
export const RI_UPLOAD = 'RI_UPLOAD';

export interface IPoint {
  lat: number;
  lng?: number;
  lon?: number;
}

type IPaths = IPoint[][];

export interface IRegionOfInterest extends IGeometryObject {
  paths: IPaths;
  orderIndex: number;
  type?: string;
  satelliteMode?: string;
  elevationAngle: number;
}

export interface IRIState {
  list: IRegionOfInterest[];
}

interface IRIAddAction {
  type: typeof RI_ADD;
  regionOfInterest: IRegionOfInterest;
}

interface IRIUpdateAction {
  type: typeof RI_UPDATE;
  regionOfInterest: IRegionOfInterest;
}

interface IRIRemoveAction {
  type: typeof RI_REMOVE;
  id: string;
}

interface IRIUploadAction {
  type: typeof RI_UPLOAD;
  regionsOfInterest: IRIState;
}

export type regionOfInterestTypes =
  | IRIAddAction
  | IRIUpdateAction
  | IRIRemoveAction
  | IRIUploadAction;
