import type {
  ICOGMetadataStatistics,
  ICOGMetadata,
  ICOGSingleBandMetadataStatistics,
} from '.';

export interface ICOGMetadataForRange {
  statistics: ICOGSingleBandMetadataStatistics[];
  getBandStatistics(bandNumber: number): ICOGSingleBandMetadataStatistics;
  getValueForAllBands: (
    key: keyof Omit<ICOGSingleBandMetadataStatistics, 'histogram'>
  ) => number[];
  histogramData: (bandNames?: string[]) => IHistogramData[];
}

export interface IHistogramData {
  [name: string]: number | number[] | null;
}

// Creates an empty COGMetadata object
export const emptyCOGMetadataForRange = function (): ICOGMetadataForRange {
  return {
    statistics: [],
    getBandStatistics: (_bandNumber: number) => ({
      count: 0,
      histogram: [],
      majority: 0,
      minority: 0,
      masked_pixels: 0,
      max: 0,
      mean: 0,
      median: 0,
      min: 0,
      percentile_2: 0,
      percentile_98: 0,
      std: 0,
      sum: 0,
      valid_percent: 0,
      valid_pixels: 0,
      percentile_1: 0,
      percentile_25: 0,
      percentile_75: 0,
      percentile_99: 0,
    }),
    getValueForAllBands: (
      _key: keyof Omit<ICOGSingleBandMetadataStatistics, 'histogram'>
    ) => [],
    histogramData: () => [],
  };
};

function parseStringToNumber(str: string) {
  const match = str.match(/\d+/);
  if (!match) {
    throw new Error('Invalid band name does not contain a number');
  }

  return parseInt(match[0], 10);
}

function convertStatistics(
  statistics: ICOGMetadataStatistics
): ICOGSingleBandMetadataStatistics[] {
  return Object.keys(statistics)
    .map((key) => {
      return {
        band: parseStringToNumber(key),
        ...statistics[key],
      };
    })
    .sort((a, b) => a.band - b.band);
}

export class COGMetadataForRange implements ICOGMetadataForRange {
  statistics: ICOGSingleBandMetadataStatistics[];
  metadata: ICOGMetadata;

  constructor(statistics: ICOGMetadataStatistics, metadata: ICOGMetadata) {
    this.statistics = convertStatistics(statistics);
    this.metadata = metadata;
  }

  getBandStatistics(bandNumber: number) {
    return this.statistics[bandNumber - 1];
  }

  // get value for all bands
  getValueForAllBands(
    key: keyof Omit<ICOGSingleBandMetadataStatistics, 'histogram'>
  ) {
    return this.statistics.map((s) => s[key]);
  }

  histogramData(bandNames?: string[]): IHistogramData[] {
    const histogramData: IHistogramData[] = Array(11)
      .fill(null)
      .map((_, i) => {
        return this.statistics
          .map((s, j) => {
            const key =
              bandNames?.at(j) ??
              `${this.metadata.colorinterp[j] || 'unknown'} (Asset band ${
                j + 1
              })`;
            return {
              [`${key} X`]: s.histogram[1][i],
              [`${key} Y`]: s.histogram[0][i],
            };
          })
          .reduce((acc, value) => {
            acc = { ...acc, ...value };
            return acc;
          }, {});
      });
    return histogramData.filter((d) =>
      Object.keys(d).some((k) => k !== 'unknown')
    );
  }
}
