import type { permissionTypes } from '../../../../constants/permission/actionTypes';
import { PERMISSION_SET } from '../../../../constants/permission/actionTypes';

const initialState = {};

export default function permissionReducer(
  state = initialState,
  action: permissionTypes
) {
  switch (action.type) {
    case PERMISSION_SET: {
      return {
        ...action.permissions,
      };
    }
    default: {
      return state;
    }
  }
}
