import React, { useCallback } from 'react';
import { isString } from 'lodash/fp';
import { Button, ControlGroup, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import s from '../index.module.scss';

export interface IProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  fullPath: string;
  value: string;
  disabled: boolean;
  path: string;
  meta?: string;
  showInfoMark?: boolean;
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  handleMetaButtonClick: ((value: string) => void) | undefined;
  validateChange: Function;
  setDefaultValue: Function;
  setTooltipOpen: (open: boolean) => void;
  multiline: boolean;
}

const CommandStringInput = (props: IProps) => {
  const {
    inputRef,
    className,
    value,
    onChange,
    fullPath,
    disabled,
    validateChange,
    handleBlur,
    handleFocus,
    path,
    meta,
    showInfoMark,
    handleMetaButtonClick,
    setDefaultValue,
    setTooltipOpen,
    multiline,
  } = props;

  setDefaultValue(!isString(value));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateChange(e);
    onChange(e.target.value, fullPath);
  };

  const handleMetaClick = useCallback(
    () => handleMetaButtonClick?.(meta!),
    [handleMetaButtonClick, meta]
  );

  const infoIconStyles = meta
    ? [s.iconWithInput, s.iconWithInputExplore].join(' ')
    : s.iconWithInput;

  return (
    <ControlGroup fill>
      {multiline ? (
        <textarea
          ref={inputRef as unknown as React.RefObject<HTMLTextAreaElement>}
          id={path || 'input_str'}
          name={path || 'input_str'}
          disabled={disabled}
          className={className}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onMouseOver={!disabled ? handleFocus : undefined}
          value={isString(value) ? value : ''}
          onChange={
            handleInputChange as unknown as React.ChangeEventHandler<HTMLTextAreaElement>
          }
          onMouseLeave={() => {
            setTooltipOpen(false);
          }}
          style={{
            height: '300px',
          }}
        />
      ) : (
        <input
          ref={inputRef}
          id={path || 'input_str'}
          name={path || 'input_str'}
          disabled={disabled}
          className={className}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onMouseOver={!disabled ? handleFocus : undefined}
          value={isString(value) ? value : ''}
          onChange={handleInputChange}
          onMouseLeave={() => {
            setTooltipOpen(false);
          }}
        />
      )}
      {showInfoMark && (
        <Icon className={infoIconStyles} icon={IconNames.INFO_SIGN} />
      )}
      {meta && <Button text="explore" onClick={handleMetaClick} />}
    </ControlGroup>
  );
};

export default CommandStringInput;
