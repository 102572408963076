import { useCallback, useState } from 'react';
import type { PixelSpectralSignature } from '_api/sampling/service';
import { getPixelSpectralSignatures } from '_api/sampling/service';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { LayerSourceType } from 'datacosmos/entities/layer';

export const useSpectralSignatures = () => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { removeLayersBySourceType } = useMapLayers();

  const [
    pixelSpectralSignaturesChartData,
    setPixelSpectralSignaturesChartData,
  ] = useState<PixelSpectralSignature[]>([]);

  const getPixelValuesSpectralSignatures = useCallback(
    async (
      drawnAoI: GeoJSON.Feature,
      collection: string | undefined,
      itemId: string | undefined
    ) => {
      if (!collection || !itemId) {
        return;
      }
      setIsFetching(true);

      const { data } = await getPixelSpectralSignatures({
        params: {
          collection,
          items: itemId,
        },
        body: {
          aoi: drawnAoI.geometry,
        },
      });
      setIsFetching(false);

      if (!data) {
        setIsFetching(false);

        return;
      }

      setPixelSpectralSignaturesChartData(data.values);
    },
    []
  );

  const togglePixelSpectralSignatures = useCallback(
    (toggle?: boolean) => {
      if (isToggled) {
        removeLayersBySourceType(LayerSourceType.GEOMETRY_LAYER);
        setPixelSpectralSignaturesChartData([]);
      }
      setIsToggled(toggle !== undefined ? toggle : !isToggled);
    },
    [isToggled, removeLayersBySourceType]
  );

  return {
    isToggled,
    isFetching,
    togglePixelSpectralSignatures,
    getPixelValuesSpectralSignatures,
    pixelSpectralSignaturesChartData,
  };
};
