import type { IPoint } from '../regionsOfInterest/actionTypes';
import type IGeometryObject from '../IGeometryObject';

export const ST_ADD = 'ST_ADD';
export const ST_UPDATE = 'ST_UPDATE';
export const ST_REMOVE = 'ST_REMOVE';
export const ST_REMOVE_ALL = 'ST_REMOVE_ALL';

export interface ISatelliteData extends IGeometryObject {
  isShow: boolean;
  path: IPoint[];
  visiblePath: IPoint[];
}

export interface ISTState {
  list: ISatelliteData[];
}

interface ISTAddAction {
  type: typeof ST_ADD;
  satellite: ISatelliteData;
}

interface ISTUpdateAction {
  type: typeof ST_UPDATE;
  satellite: ISatelliteData;
}

interface ISTRemoveAction {
  type: typeof ST_REMOVE;
  id: string;
}

interface ISTRemoveAllAction {
  type: typeof ST_REMOVE_ALL;
}

export type ISatelliteActionTypes =
  | ISTAddAction
  | ISTUpdateAction
  | ISTRemoveAction
  | ISTRemoveAllAction;
