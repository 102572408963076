import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useViewMode = () => {
  const location = useLocation();

  const urlParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const isMinimal = useMemo(
    () => urlParams.get('mode') === 'minimal',
    [urlParams]
  );

  return { isMinimal };
};
