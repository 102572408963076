import React, { useEffect, useState } from 'react';
import type { UpdatedActivityValue } from '../../context/ActivitiesProvider';

type Props = {
  updateActivity: (activityId: string, value: UpdatedActivityValue) => void;
  value: number | null;
  activityId: string;
};

const NumberInputCell = ({ updateActivity, value, activityId }: Props) => {
  const [val, setVal] = useState<number | null>(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberVal =
      e.currentTarget.value === '' ? null : Number(e.currentTarget.value);
    setVal(numberVal);

    updateActivity(activityId, {
      parameters: {
        session: {
          session_id: numberVal as number | undefined,
        },
      },
    });
  };

  return (
    <input
      onClick={(e) => e.stopPropagation()}
      type="number"
      className="h-8 px-2"
      placeholder="Number value..."
      value={val ?? ''}
      onChange={handleInputChange}
    />
  );
};

export default NumberInputCell;
