const TELEMETRY = {
  VISIT: 'OPS Telemetry Visit',
};

const RTI = {
  VISIT: 'OPS RTI Visit',
  COMMAND: {
    SEND: 'OPS RTI Command Send',
  },
  FILE_TAB: {
    VISIT: 'OPS RTI File Visit',
  },
  TELEMETRY_TAB: {
    VISIT: 'OPS RTI Telemetry Visit',
  },
  TERMINAL: {
    CREATE: {
      SUCCESS: 'OPS RTI Terminal Create Success',
      ERROR: 'OPS RTI Terminal Create Error',
    },
    CONNECT: 'OPS RTI Terminal Connect',
  },
};

const LIBRARY = {
  VISIT: 'OPS Library Visit',
  TELEMETRY: {
    DEFINITION: {
      CREATE: 'OPS Library Telemetry Definition Create',
      CHANGE: 'OPS Library Telemetry Definition Change',
    },
  },
  TRANSFORMER: {
    CHANGE: 'OPS Library Transformer Change',
  },
  PROTOCOL: {
    CHANGE: 'OPS Library Protocol Change',
  },
  ALERTS: {
    DEFINITION: {
      CHANGE: 'OPS Library Alert Change',
    },
  },
};

const GS_SCHEDULER = {
  VISIT: 'OPS GS_Scheduler Visit',
  PASS: {
    CANCEL: 'OPS GS_Scheduler Pass Cancel',
    BOOK: 'OPS GS_Scheduler Pass Book',
  },
  EPHEMERIS: {
    UPDATE: 'OPS GS_Scheduler EPHEMERIS Update',
  },
};

export default {
  TELEMETRY,
  RTI,
  LIBRARY,
  GS_SCHEDULER,
};
