import type { JsonSchema } from '_api/telecommands/types';
import { has, split, pipe, slice, get, omit } from 'lodash/fp';

const getRefPath = (path: string) => (schema: JsonSchema) =>
  pipe(get(path), split('/'), (it) => slice(1, it.length, it))(
    schema
  ) as string;

const dereference = (schema: JsonSchema) => {
  if (!has('$ref', schema) && !has('items', schema)) {
    return schema;
  }

  let refPath = '$ref';
  const { type } = schema;

  if (has('items', schema)) refPath = 'items.$ref';

  return pipe(
    getRefPath(refPath),
    (path) => {
      return get(path, schema) as JsonSchema;
    },
    (item) => {
      return type === 'array' ? { ...item, type } : item;
    },
    (items) => Object.assign({}, schema, items),
    omit('$ref')
  )(schema);
};

export default dereference;
