import React from "react";
import { Button } from "../../core";

type Props = {
  onActionBtnClick: () => void;
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
};

const Topbar = ({ onActionBtnClick, left, center, right }: Props) => {
  return (
    // Arbitrary height of 50px to match the existing opencosmos header
    <div className="flex items-center w-full color-header h-[50px] p-1 px-1">
      <Button
        icon="Hamburger"
        size="lg"
        isTransparent={true}
        onPress={() => {
          onActionBtnClick();
        }}
        data-testid="topbar-action-btn"
        className={"border-none"}
      />
      <div className="w-full grid grid-cols-3 px-2 items-center">
        <div className="text-left flex items-center justify-start">{left}</div>
        <div className="text-center flex items-center justify-center">
          {center}
        </div>
        <div className="text-right flex items-center justify-end">{right}</div>
      </div>
    </div>
  );
};

export default Topbar;
