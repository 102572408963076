import { apiFetchHandler } from '_api/clientFetch';
import type { EventJsonSchema, Script, Trigger } from './types';
import { hosts } from '_api/hosts';
import { toaster } from 'toaster';

type GetScriptsParams = {
  missionId: string | number;
};

export const getScripts = apiFetchHandler<Script[], GetScriptsParams>({
  method: 'GET',
  endpoint: ({ missionId }) => `/scripting/mission/${missionId}/scripts`,
  errorMessage: 'Could not fetch scripts',
  host: hosts.ops.v0,
});

type GetTriggersParams = {
  missionId: string | number;
};

export const getTriggers = apiFetchHandler<Trigger[], GetTriggersParams>({
  method: 'GET',
  endpoint: ({ missionId }) => `/scripting/mission/${missionId}/triggers`,
  errorMessage: 'Could not fetch triggers',
  host: hosts.ops.v0,
});

type PostPublishScriptEventParams = {
  missionId: string | number;
  scriptName: string;
};

type PostPublishScriptEventBody = {
  type: 'manual';
};

export const postRunScript = apiFetchHandler<
  undefined,
  PostPublishScriptEventParams,
  PostPublishScriptEventBody
>({
  method: 'POST',
  endpoint: ({ missionId, scriptName }) =>
    `/scripting/mission/${missionId}/scripts/${scriptName}/run`,
  errorMessage: 'Could not publish script',
  host: hosts.ops.v0,
  onSuccess: () => {
    toaster.show({
      message: 'Script run triggered',
      intent: 'success',
    });
  },
});

export const getReadEvents = apiFetchHandler<EventJsonSchema[]>({
  method: 'GET',
  endpoint: '/scripting/events',
  errorMessage: 'Could not fetch events',
  host: hosts.ops.v0,
});

type PostPublishEventParams = {
  missionId: string | number;
};

type PostPublishEventBody = Record<string, unknown>;

export const postPublishEvent = apiFetchHandler<
  undefined,
  PostPublishEventParams,
  PostPublishEventBody
>({
  method: 'POST',
  endpoint: ({ missionId }) => `/scripting/mission/${missionId}/events/publish`,
  errorMessage: 'Could not post event',
  host: hosts.ops.v0,
  onSuccess: () => {
    toaster.show({
      message: 'Event posted',
      intent: 'success',
    });
  },
});
