import { Intent } from '@blueprintjs/core';
import {
  routeParamProjectId,
  routeProjectItems,
} from 'datacosmos/components/routePath';
import type { IStacItem, StacItem } from 'datacosmos/types/stac-types';
import { ItemBush, transformRBushBBox } from 'datacosmos/utils/ItemBush';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { toaster } from 'toaster';
import extent from '@turf/bbox';
import { useStacAdapter } from 'datacosmos/services/stacAdapter';
import { ScenarioApi } from 'datacosmos/services/scenarioApi';
import { useAuth } from 'services/auth/AuthWrapper';
import type { Scenario } from '_api/scenarios/service';
import {
  getScenarios,
  getScenario,
  addItemToScenario,
  deleteItemFromScenario,
} from '_api/scenarios/service';
import { useQuery } from '_api/useQuery';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type ScenarioWithPermission = Scenario & {
  hasReadPermission: boolean;
  hasWritePermission: boolean;
  hasSharePermission: boolean;
};

export type IProjectProviderContext = ReturnType<typeof useProjectProvider>;

export const ProjectProviderContext = createContext<IProjectProviderContext>(
  null as unknown as IProjectProviderContext
);

export const useProjects = () =>
  useContext<IProjectProviderContext>(ProjectProviderContext);

const useProjectProvider = () => {
  const { token, checkPermissions, authBackend } = useAuth();
  const scenarioApi = useMemo(() => new ScenarioApi(token), [token]);
  const stacAdapter = useStacAdapter(scenarioApi);

  const {
    setStacSearchPageCursor,
    setIndexedScenarioItems,
    indexedScenarioItems,
    fetchImages,
    setIsFetching,
    setSearchResults,
    projectId,
  } = stacAdapter;

  const history = useHistory();

  const projectItemsRouteMatch = useRouteMatch<{ projectId: string }>(
    routeProjectItems
  );

  const [currentScenario, setCurrentScenario] = useState<Scenario>();
  const [canModifyCurrentScenario, setCanModifyCurrentScenario] =
    useState(false);

  const [userScenariosWithPermission, setUserScenariosWithPermission] =
    useState<ScenarioWithPermission[]>([]);

  const setScenario = useCallback(
    (scenario: string, redirect: boolean = true) => {
      setStacSearchPageCursor(undefined);
      setIndexedScenarioItems(new ItemBush(9));
      redirect &&
        history.push(
          routeProjectItems.replace(':' + routeParamProjectId, scenario)
        );
    },
    [history, setIndexedScenarioItems, setStacSearchPageCursor]
  );

  const fetchCurrentProjectItems = useCallback(async () => {
    if (!projectId) {
      return;
    }

    setIsFetching(true);
    const images = await fetchImages();
    setIsFetching(false);
    if (images) {
      setSearchResults(images.features);
    }
  }, [fetchImages, projectId, setIsFetching, setSearchResults]);

  const getItemsInFootprint = useCallback(
    (squareFootprint: GeoJSON.GeoJSON) => {
      if (!projectItemsRouteMatch?.isExact) {
        return [];
      }
      return indexedScenarioItems.search(
        transformRBushBBox(extent(squareFootprint))
      );
    },
    [indexedScenarioItems, projectItemsRouteMatch?.isExact]
  );

  const {
    data: userScenarios,
    refetch: loadUserScenarios,
    loading,
  } = useQuery(getScenarios, { initialData: [] });

  const handleAddToScenario = useCallback(
    async (scenario: Scenario, item: IStacItem) => {
      if (!item.collection) return;

      const { success } = await addItemToScenario({
        body: {
          scenario: scenario.id,
          collection: item.collection,
          item: item.id,
        },
      });

      if (!success) return;
      /*This is used to toggle the project icon without an API call or a refresh on the FE. 
      scenario.id is only a placement value and BE sets the correct rid*/
      item.properties['opencosmos:rid'] = scenario.id;

      toaster.show({
        intent: Intent.SUCCESS,
        message: clientTranslate(
          'datacosmos.tooltips.catalogAndItems.addedToProjectNotification',
          { item: item.id, project: scenario.title }
        ),
      });
    },
    []
  );

  const handleRemoveFromScenario = useCallback(
    async (scenario: Scenario, item: StacItem) => {
      const itemToRemove = item.properties['opencosmos:rid'];
      if (!itemToRemove) return;
      const { success } = await deleteItemFromScenario({
        params: { relationId: itemToRemove },
      });

      if (!success) return;

      toaster.show({
        intent: Intent.SUCCESS,
        message: clientTranslate(
          'datacosmos.tooltips.catalogAndItems.removedFromProjectNotification',
          { item: item.id, project: scenario.title }
        ),
      });

      const images = await fetchImages();
      if (images) {
        setSearchResults(images.features);
      }
    },
    [fetchImages, setSearchResults]
  );

  const { data: detailedScenario, refetch: loadDetailedScenario } = useQuery(
    getScenario,
    {
      initialData: undefined,
      params: projectId ? { scenarioId: projectId } : undefined,
      skip:
        !projectId ||
        projectId === 'tasking' ||
        projectId === 'catalog' ||
        projectId === 'items' ||
        projectId === 'application',
    }
  );

  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const loadItemsForProject = async () => {
      if (!projectItemsRouteMatch?.isExact || authBackend === undefined) {
        return;
      }

      try {
        setIsFetching(true);
        const images = await fetchImages();
        if (images) {
          setSearchResults([...images.features]);
        }
        setIsFetching(false);
      } catch (error) {
        setIsFetching(false);
      }
    };

    timeoutRef.current = setTimeout(() => {
      void loadItemsForProject();
    }, 200);
  }, [
    authBackend,
    fetchImages,
    projectItemsRouteMatch?.isExact,
    setIsFetching,
    setSearchResults,
  ]);

  useEffect(() => {
    if (authBackend === undefined || !projectId.includes('-')) {
      return;
    }

    /**
     * Sets the currently selected scenario to the one with the ID
     * given as an argument, if possible.
     */
    const foundScenarios = userScenariosWithPermission.filter(
      (scenario) => scenario.id === projectId
    );

    if (foundScenarios.length === 0) return;

    const foundScenario = foundScenarios[0];

    setCanModifyCurrentScenario(foundScenario.hasWritePermission);

    setCurrentScenario(foundScenario);
  }, [authBackend, projectId, userScenariosWithPermission]);

  const modifiableScenarios = useMemo(() => {
    return userScenariosWithPermission.filter(
      (scenario) => scenario.hasWritePermission
    );
  }, [userScenariosWithPermission]);

  useEffect(() => {
    if (userScenarios.length === 0) {
      return;
    }

    const getScenariosWithPermission = async () => {
      const hasReadPerms = await checkPermissions(
        userScenarios.map((scenario) => ({
          type: 'datacosmos_scenario',
          actionScope: 'data:scenario:read',
          id: scenario.id,
        }))
      );

      const hasWritePermission = await checkPermissions(
        userScenarios.map((scenario) => ({
          type: 'datacosmos_scenario',
          actionScope: 'data:scenario:write',
          id: scenario.id,
        }))
      );

      const hasSharePermission = await checkPermissions(
        userScenarios.map((scenario) => ({
          type: 'datacosmos_scenario',
          actionScope: 'data:scenario:assignment',
          id: scenario.id,
        }))
      );

      const scenariosWithPermission = userScenarios.map((scenario, index) => ({
        ...scenario,
        hasReadPermission: hasReadPerms[index],
        hasWritePermission: hasWritePermission[index],
        hasSharePermission: hasSharePermission[index],
      }));

      setUserScenariosWithPermission(scenariosWithPermission);
    };

    void getScenariosWithPermission();
  }, [checkPermissions, userScenarios]);

  const { hasPermission: isAllowedToPerformTaskingForCurrentProject } =
    useCheckPermissions({
      permissions: {
        type: 'datacosmos_scenario',
        actionScope: 'data:project:tasking:write',
        id: currentScenario?.id,
      },
      enabled: currentScenario !== undefined,
    });

  const isItemInProject = useCallback((itm: StacItem) => {
    const isRidPresent: string | undefined =
      itm?.properties?.['opencosmos:rid'];
    if (isRidPresent) {
      return true;
    }
    return false;
  }, []);
  return {
    setScenario,
    canModifyCurrentScenario,
    currentScenario,
    detailedScenario,
    handleAddToScenario,
    handleRemoveFromScenario,
    setCurrentScenario,
    userScenarios: userScenariosWithPermission,
    getItemsInFootprint,
    modifiableScenarios,
    loadUserScenarios,
    loadDetailedScenario,
    stacAdapter,
    isLoading: loading,
    isItemInProject,
    isAllowedToPerformTaskingForCurrentProject,
    fetchCurrentProjectItems,
  };
};

export const ProjectProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ProjectProviderContext.Provider value={useProjectProvider()}>
      {children}
    </ProjectProviderContext.Provider>
  );
};
