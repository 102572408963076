import type {
  Organisation,
  OrganisationJoinPolicy,
} from '_api/administration/types';
import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import type { Role } from '_api/users/types';

export type UpdateOrganisationParams = {
  id: string;
};

export type UpdateOrganisation = {
  name?: string;
  domain?: string;
  join_policy?: OrganisationJoinPolicy;
};

export const updateOrganisation = apiFetchHandler<
  Organisation,
  UpdateOrganisationParams,
  UpdateOrganisation
>({
  host: hosts.portal.v0,
  endpoint: ({ id }) => `/organisations/${id}`,
  method: 'PUT',
  errorMessage: 'Could update organisation',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});

export type JoinOrganisationParams = {
  organisationId: string;
};

export const addUserToOrganisation = apiFetchHandler<
  Role[],
  JoinOrganisationParams
>({
  host: hosts.portal.v0,
  endpoint: ({ organisationId }) => `/organisations/${organisationId}/join`,
  method: 'POST',
  errorMessage: 'Could not add user to organisation',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});

export const getOrganisationsUserCanJoin = apiFetchHandler<Organisation[]>({
  host: hosts.portal.v0,
  endpoint: `/organisations/matching-user-domain`,
  method: 'GET',
  errorMessage: 'Could not get organisations',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});
