import type IGeometryObject from '../IGeometryObject';

export const GS_ADD = 'GS_ADD';
export const GS_UPDATE = 'GS_UPDATE';
export const GS_REMOVE = 'GS_REMOVE';
export const GS_UPLOAD = 'GS_UPLOAD';

export interface IGroundStation extends IGeometryObject {
  lat: number;
  lon: number;
  altitude: number;
  elevationAngle: number;

  select: boolean;
  optimize: boolean;
  custom?: boolean;
}

export interface IGSService {
  name: string;
  minElevation: number;
  latitude: number;
  longitude: number;
  altitude: number;
}

export interface IGSState {
  list: IGroundStation[];
}

interface IGSAddAction {
  type: typeof GS_ADD;
  groundStation: IGroundStation;
}

interface IGSUpdateAction {
  type: typeof GS_UPDATE;
  groundStation: IGroundStation;
}

interface IGSRemoveAction {
  type: typeof GS_REMOVE;
  id: string;
}

interface IGSUploadAction {
  type: typeof GS_UPLOAD;
  groundStations: IGSState;
}

export type GroundStationsActionTypes =
  | IGSAddAction
  | IGSUpdateAction
  | IGSRemoveAction
  | IGSUploadAction;
