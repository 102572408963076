import { Intent } from '@blueprintjs/core';

export interface IFileTransferMap {
  [name: string]: number;
}

export interface IActiveTransfer {
  id: string;
  name?: string;
  destination?: string;
  transferType?: string;
  percent?: number;
  cancelCallback?: () => void;

  stopped?: boolean;
  transferImplementation?: string;
  files?: IFileTransferMap;
  error?: string;
  skipWarningAbortTransfer?: boolean;

  currentStage?: string | undefined;
  stages?: string[] | undefined;
}

export const transferIntentOnNumber = (
  percent: number,
  hasErrored: boolean
) => {
  if (percent === 100) {
    return Intent.SUCCESS;
  }

  if (hasErrored) {
    return Intent.DANGER;
  }

  return Intent.NONE;
};

export const transferIntent = (
  transferPercent: number,
  hasErrored: boolean
) => {
  return transferIntentOnNumber(transferPercent, hasErrored);
};

export const isTransferOngoing = (
  percent: number,
  isStopped: boolean,
  hasErrored: boolean
) => {
  if (isStopped || hasErrored) {
    return false;
  }

  return percent !== 100;
};
