import MapLayers from 'datacosmos/components/map-layers';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import DrawCircle from 'datacosmos/components/RightClickMenu/DrawCircle';
import { DrawPolygon } from 'datacosmos/components/RightClickMenu/DrawPolygon';
import { DrawLine } from 'datacosmos/components/RightClickMenu/DrawLine';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import { DrawRectangle } from './RightClickMenu/DrawRectangle';
import { DrawPolyline } from './RightClickMenu/DrawPolyline';
import UploadRegionMenu from './RightClickMenu/UploadRegionMenu';
import { useFilters } from 'datacosmos/stores/FiltersProvider';
import { Button, DialogTrigger, Icon, Popover } from 'opencosmos-ui';
import { useState } from 'react';
import { zIndexValues } from 'opencosmos-ui/constants';
import bbox from '@turf/bbox';

type HeaderProps = {
  leftMenuOpen: boolean;
};

export const Header = ({ leftMenuOpen }: HeaderProps) => {
  const [isAoiPopoverOpen, setIsAoiPopoverOpen] = useState<boolean>(false);
  const { addLayer, ...mapLayers } = useMapLayers();

  const filters = useFilters();
  const {
    drawCircle,
    drawPolygon,
    measure,
    drawRectangle,
    drawPolyline,
    setViewToFitBbox,
  } = useMap();
  const { isMinimal } = useViewMode();
  const closedSize = isMinimal ? '40px' : '100px';
  return (
    <header className="main-header" role="banner">
      <div className="main-header-wrapper">
        <div>
          <nav>
            <div>
              <ul
                className="app-menu"
                style={{
                  position: 'absolute',
                  left: leftMenuOpen ? '30.2rem' : closedSize,
                  top: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                {!isMinimal && (
                  <li className="bttn map-control-layers color-item">
                    <DialogTrigger>
                      <Button
                        isMinimal
                        isTransparent
                        size="sm"
                        fill
                        className={'flex justify-center items-center px-0'}
                        onPress={() => setIsAoiPopoverOpen((prev) => !prev)}
                      >
                        <Icon icon="AoiAdd" size={24} />
                      </Button>
                      <Popover
                        className="color-item w-fit p-2"
                        style={{ zIndex: zIndexValues.map }}
                        placement="right"
                        isOpen={isAoiPopoverOpen}
                        onOpenChange={(isOpen) => setIsAoiPopoverOpen(isOpen)}
                      >
                        <div
                          style={{
                            margin: 0,
                            textAlign: 'center',
                          }}
                        >
                          <ul
                            style={{
                              listStyle: 'none',
                              listStyleType: 'none',
                              margin: 0,
                              padding: '2px',
                            }}
                          >
                            <li
                              style={{ textAlign: 'left' }}
                              className="stroke-transparent"
                              onClick={() => setIsAoiPopoverOpen(false)}
                            >
                              <DrawCircle
                                addLayer={addLayer}
                                drawCircle={drawCircle}
                              />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className="stroke-transparent"
                              onClick={() => setIsAoiPopoverOpen(false)}
                            >
                              <DrawPolygon
                                addLayer={addLayer}
                                drawPolygon={drawPolygon}
                              />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className="stroke-transparent"
                              onClick={() => setIsAoiPopoverOpen(false)}
                            >
                              <DrawLine measure={measure} />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className="stroke-transparent"
                              onClick={() => setIsAoiPopoverOpen(false)}
                            >
                              <DrawRectangle
                                addLayer={addLayer}
                                drawRectangle={drawRectangle}
                              />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className="stroke-transparent"
                              onClick={() => setIsAoiPopoverOpen(false)}
                            >
                              <DrawPolyline
                                addLayer={addLayer}
                                drawPolyline={drawPolyline}
                              />
                            </li>
                            <li style={{ textAlign: 'left' }}>
                              <UploadRegionMenu
                                layers={{ ...mapLayers, addLayer }}
                                filters={filters}
                                onAddLayer={(layer) => {
                                  setIsAoiPopoverOpen(false);
                                  setViewToFitBbox(bbox(layer.data));
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </Popover>
                    </DialogTrigger>
                  </li>
                )}
                <li className="bttn map-control-layers color-item">
                  <MapLayers />
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};
