import AdministrationLayout from '../../shared/components/AdministrationLayout/AdministrationLayout';
import useRoles from 'services/api/portal/administration/hook/useRoles';
import AdministrationRolesList from 'pages/portal/Administration/Roles/components/AdministrationRolesList';
import { PERMISSION_TYPE_LIST } from '_api/administration/permissions';
import { useLocalisation } from 'utils/hooks/useLocalisation';

const RolesAdministration = () => {
  const {
    availableRolesByType,
    createRole,
    updateRole,
    getRoleAttachmentsByRoleId,
    createRoleAttachment,
    deleteRoleAttachment,
  } = useRoles();
  const { translate } = useLocalisation();
  return (
    <AdministrationLayout
      breadcrumbs={[{ text: translate('datacosmos.header.menu.rolesManager') }]}
    >
      {Object.values(PERMISSION_TYPE_LIST)
        .filter((type) => type !== 'mission')
        .map((type) => (
          <AdministrationRolesList
            key={type}
            title={`${(type as string).replace(/_/g, ' ')} Roles`}
            rolesList={availableRolesByType(type)}
            getAvailableRolesByType={availableRolesByType}
            resourceType={type}
            updateRole={updateRole}
            createRole={createRole}
            getRoleAttachments={(role) => {
              const roleAttachmentResults = getRoleAttachmentsByRoleId(
                role.id.toString()
              );
              return roleAttachmentResults;
            }}
            addRolesAttachment={async (roleAttachment, parentRoleId) => {
              const addResult = await createRoleAttachment(
                roleAttachment,
                parentRoleId
              );
              return addResult;
            }}
            deleteRolesAttachment={async (role) => {
              const deleteResult = await deleteRoleAttachment(
                role.id,
                role.parentRoleId
              );
              return deleteResult;
            }}
          />
        ))}
    </AdministrationLayout>
  );
};

export default RolesAdministration;
