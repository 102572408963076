import React from 'react';
import { startCase } from 'lodash/fp';
import { ControlGroup, HTMLSelect, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import TagHeader from '../../../../../../components/common/TagHeader';

import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Button, Tooltip } from 'opencosmos-ui';
import { useOperate } from '../../context/OperateProvider';
import { shouldFocusLastHistoryCommand } from 'actions/operate/actions';

interface IProps {
  handleHistoryTypeChange: (type: string) => void;
  historyCommandType?: string;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const CommandHistoryHeader = (props: IProps) => {
  const { sendInfo } = useAnalytics();

  const { state, dispatch } = useOperate();
  return (
    <TagHeader
      className={s.historyTagContainer}
      title="Command History"
      icon="history"
      intent={Intent.PRIMARY}
      autoAlignTitle={false}
    >
      <span className={s.commandHistoryHeaderButtons + ' flex items-center'}>
        <Tooltip
          content={
            state.shouldFocusLastSentCommand
              ? 'Disable last sent command focus'
              : 'Enable last sent command focus'
          }
        >
          <Button
            icon="Center"
            onPress={() => {
              dispatch(
                shouldFocusLastHistoryCommand(!state.shouldFocusLastSentCommand)
              );
            }}
            isActive={state.shouldFocusLastSentCommand}
            isMinimal={true}
            isTransparent={true}
            className={'h-6 w-6'}
          />
        </Tooltip>
        <ControlGroup>
          {!props.collapsed && (
            <HTMLSelect
              minimal
              value={startCase(props.historyCommandType ?? 'Session')}
              onChange={(e) => {
                sendInfo({
                  type: 'Command history type change',
                  action: 'Change',
                  item: 'Command history type',
                  module: 'OPS',
                  additionalParams: {
                    type: e.target.value.toLowerCase(),
                  },
                });
                props.handleHistoryTypeChange(e.target.value.toLowerCase());
              }}
            >
              <option>Session</option>
              <option>Mission</option>
            </HTMLSelect>
          )}
          <Button
            icon={
              props.collapsed ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN
            }
            isMinimal={true}
            isTransparent={true}
            onPress={() => {
              sendInfo({
                type: 'Command history collapse',
                action: 'Click',
                item: 'Command history collapse button',
                module: 'OPS',
              });
              props.setCollapsed(!props.collapsed);
            }}
            className={'hover:bg-transparent bg-transparent'}
          />
        </ControlGroup>
      </span>
    </TagHeader>
  );
};

export default CommandHistoryHeader;
