import { isNil } from 'lodash';
import type { Antennas, GS, MissionConfigurationData } from './types';

export const flattenGroundStationList = (
  data: MissionConfigurationData
): GS[] => {
  const flattenedGroundStations = data.providers.reduce<GS[]>(
    (groundStations, provider) => {
      const newGroundStations: GS[] = provider.sites.map((site) => {
        return {
          id: site.id,
          name: site.name,
          providerId: provider.id,
          providerName: provider.name,
          antennas: site.antennas,
        };
      });
      return [...groundStations, ...newGroundStations];
    },
    []
  );

  return flattenedGroundStations;
};

export const mutateAntennasFromGroundStation = (
  groundStation: GS,
  antennasToMutate: Antennas
) => {
  groundStation.antennas.forEach((antenna) => {
    const antennaDoesntExist = isNil(antennasToMutate[`${antenna.id}`]);
    if (antennaDoesntExist) {
      antennasToMutate[`${antenna.id}`] = {
        ...antenna,
        site: groundStation,
      };
    }
  });
};

export const getAntennasFromGroundStations = (groundStations: GS[]) =>
  groundStations.reduce((antennaAccumulator, groundStation) => {
    const antennas = { ...antennaAccumulator };
    mutateAntennasFromGroundStation(groundStation, antennas);
    return antennas;
  }, {} as Antennas);
