import type {
  ISatelliteActionTypes,
  ISatelliteData,
} from '../../../../../constants/satelliteOrbits/actionTypes';
import {
  ST_ADD,
  ST_REMOVE_ALL,
  ST_UPDATE,
} from '../../../../../constants/satelliteOrbits/actionTypes';

export function addSatelliteOrbit(
  satelliteOrbit: ISatelliteData
): ISatelliteActionTypes {
  return {
    type: ST_ADD,
    satellite: satelliteOrbit,
  };
}

export function updateSatelliteOrbit(
  satelliteOrbit: ISatelliteData
): ISatelliteActionTypes {
  return {
    type: ST_UPDATE,
    satellite: satelliteOrbit,
  };
}

export function removeAllSatelliteOrbit(): ISatelliteActionTypes {
  return {
    type: ST_REMOVE_ALL,
  };
}
