import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
  addGoalObject,
  removeAllGoalsObject,
  removeGoalObject,
  updateGoalObject,
  uploadGoals,
} from './action';
import type { AppState } from '../../reducers/rootReducer';
import type {
  IGoal,
  IGoalReduceState,
} from '../../../../../constants/goals/interfaceas';

export const addGoal =
  (goalObject: IGoal): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(addGoalObject(goalObject));
  };

export const updateGoal =
  (goalObject: IGoal): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(updateGoalObject(goalObject));
  };

export const removeGoal =
  (goalObject: IGoal): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(removeGoalObject(goalObject));
  };

export const removeAllGoals =
  (): ThunkAction<void, AppState, null, Action<string>> => (dispatch) => {
    dispatch(removeAllGoalsObject());
  };

export const uploadGoalsThunk =
  (
    goals: IGoalReduceState
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(uploadGoals(goals));
  };
