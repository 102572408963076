import type { StacItem } from 'datacosmos/types/stac-types';
import { Button, TextField } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import ConfirmDialog from '../ConfirmDialog';
import { useImageCatalog } from 'datacosmos/stores/ImageCatalogProvider';
import {
  createSingleFeature,
  deleteSingleFeature,
  updateSingleFeature,
} from '_api/stac/service';
import type { STACItem } from '_api/stac/types';
import { toaster } from 'toaster';
import { useActivePage } from '../Toolbar/ActivePageProvider';
import { useProjects } from 'datacosmos/stores/ProjectProvider';

type QAProps = {
  item: StacItem;
  onSuccess?: () => void;
  loading?: boolean;
};

const showToasterError = (message: string) => {
  toaster.show({
    message,
    intent: 'danger',
    timeout: 5000,
  });
};

export const getQACollection = (collection: string | undefined) => {
  if (!collection) return '';
  return collection.includes('--qa') ? collection : `${collection}--qa`;
};

export const getNominalCollection = (collection: string | undefined) => {
  if (!collection) return '';
  return collection.includes('--qa')
    ? collection.replace('--qa', '')
    : collection;
};

export const PublishButton = ({ item, onSuccess, loading }: QAProps) => {
  const { translate } = useLocalisation();

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: async () => {
      const nominalCollection = getNominalCollection(item.collection);
      const nominalItemProperties = Object.fromEntries(
        Object.entries(item.properties).filter(
          ([key]) => !key.startsWith('opencosmos:qa')
        )
      );
      const qaItemProperties = item.properties['opencosmos:qa:rejected']
        ? { ...item.properties, ['opencosmos:qa:rejected']: false }
        : item.properties;
      const createResponse = await createSingleFeature({
        params: {
          collection: nominalCollection,
        },
        body: {
          ...item,
          properties: {
            ...nominalItemProperties,
            ['opencosmos:collection_type']: 'nominal',
          },
          collection: nominalCollection,
        } as STACItem,
      });
      if (!createResponse.success || createResponse.status !== 200) {
        showToasterError(
          translate('datacosmos.catalogAndItems.qa.errors.publish')
        );
        throw new Error(
          translate('datacosmos.fetchErrors.stac.cannotPostItem')
        );
      }
      const updateResponse = await updateSingleFeature({
        params: {
          collection: item.collection ?? '',
          item: item.id,
        },
        body: { ...item, properties: qaItemProperties } as STACItem,
      });
      if (!updateResponse.success || updateResponse.status !== 200) {
        showToasterError(
          translate('datacosmos.catalogAndItems.qa.errors.publish')
        );
        throw new Error(translate('datacosmos.fetchErrors.stac.cannotPutItem'));
      }
    },
  });

  const handlePress = async () => {
    await mutateAsync();
    onSuccess?.();
  };

  return (
    <Button
      fill
      onPress={handlePress}
      className={isPending ? 'pointer-events-none cursor-not-allowed' : ''}
      loading={isPending || loading}
      isDisabled={isSuccess}
    >
      {translate('datacosmos.catalogAndItems.qa.publish')}
    </Button>
  );
};

export const UnpublishButton = ({ item, onSuccess, loading }: QAProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const { translate } = useLocalisation();

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: async () => {
      const qaCollection = getQACollection(item.collection);
      const deleteResponse = await deleteSingleFeature({
        params: { collection: item.collection ?? '', item: item.id },
      });
      if (!deleteResponse.success || deleteResponse.status !== 200) {
        showToasterError(
          translate('datacosmos.catalogAndItems.qa.errors.unpublish')
        );
        throw new Error(
          translate('datacosmos.fetchErrors.stac.cannotDeleteItem')
        );
      }
      const updateResponse = await updateSingleFeature({
        params: { collection: qaCollection, item: item.id },
        body: {
          ...item,
          properties: {
            ...item.properties,
            ['opencosmos:qa:rejected']: true,
            ['opencosmos:qa:rejected_reason']: reason ?? '',
            ['opencosmos:collection_type']: 'qa',
          },
          collection: qaCollection,
        } as STACItem,
      });
      if (!updateResponse.success || updateResponse.status !== 200) {
        showToasterError(
          translate('datacosmos.catalogAndItems.qa.errors.unpublish')
        );
        throw new Error(translate('datacosmos.fetchErrors.stac.cannotPutItem'));
      }
    },
  });

  const handleConfirm = async () => {
    setIsDialogOpen(false);
    await mutateAsync();
    onSuccess?.();
  };

  return (
    <>
      <Button
        fill
        onPress={() => setIsDialogOpen(true)}
        className={isPending ? 'pointer-events-none cursor-not-allowed' : ''}
        loading={isPending || loading}
        isDisabled={isSuccess}
      >
        {translate('datacosmos.catalogAndItems.qa.unpublish')}
      </Button>
      <ConfirmDialog
        isOpen={isDialogOpen}
        title={translate('datacosmos.catalogAndItems.qa.confirmDialog.title')}
        header={translate(
          'datacosmos.catalogAndItems.qa.confirmDialog.description',
          { item: item.id }
        )}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={handleConfirm}
        confirmIsDisabled={!reason}
      >
        <TextField
          autoFocus
          value={reason}
          onChange={setReason}
          label={translate(
            'datacosmos.catalogAndItems.qa.confirmDialog.rejectLabel'
          )}
        />
      </ConfirmDialog>
    </>
  );
};

export const QAButton = ({ item }: QAProps) => {
  const { stacAdapter: catalogStacAdapter } = useImageCatalog();
  const { stacAdapter: projectsStacAdapter } = useProjects();
  const { activePage } = useActivePage();
  const stacAdapter =
    activePage === 'catalog' ? catalogStacAdapter : projectsStacAdapter;

  const handleSuccess = async () => {
    const images = await stacAdapter.fetchImages();
    if (images) {
      stacAdapter.setSearchResults([...images.features]);
    }
  };

  const buttonProps = {
    item,
    onSuccess: handleSuccess,
    loading: stacAdapter.isFetching,
  };

  if (item.properties['opencosmos:collection_type'] === 'qa') {
    return <PublishButton {...buttonProps} />;
  }
  if (item.properties['opencosmos:collection_type'] === 'nominal') {
    return <UnpublishButton {...buttonProps} />;
  }
  return null;
};
