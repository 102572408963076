/*
  Important info:
  - Input to the component must start without a slash
  - Files are separated by a single '/', handle OS-specific
    formatting outside of the component
  - All files need to be appended with a '/' (eg 'home/')
*/

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import { compact } from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
import { crc32 } from '@allex/crc32';

import type { ICloudContentOutput } from 'services/api/ops/cloudStorage';
import type { IFolderTreeItem } from 'pages/ops/RTI/Operate/components/FileExplorer/FolderTree';
import {
  removeExcessSlash,
  removeFinalSlash,
  removeFirstSlash,
} from 'utils/common/stringUtils';
import { copyToClipBoard } from 'utils/common/CommonUtils';
import { EntryType } from 'constants/fileExplorer/constants';
import type { FILE_TRANSFER_TYPE_TEXT } from 'constants/ops/rti/oparate/constants';
import type { CloudStorageContent } from '_api/cloudStorage/types';

export type IFileExplorerContextOutput = ReturnType<
  typeof useInnerFileExplorer
>;

export const FileExplorerContext = createContext<
  IFileExplorerContextOutput | undefined
>(undefined);
export const useFileExplorer = () =>
  useContext<IFileExplorerContextOutput | undefined>(FileExplorerContext);

export type IFileExplorerContextInput = {
  errorMessage?: string;
  rootItemName?: string;
  missionId: number;
  defaultPath?: string[];
  dragType?: string;
  acceptedDropType?: string;
  onDropFile?: (fromPath: string, toPath: string) => void;
  onDropFolder?: (fromPath: string, toPath: string) => void;
  simplifiedExplorer?: boolean;
  previewFileHandler?: Function;
  storedPath?: string;
  setStoredPath?: (path: string) => void;
  isRefreshing?: boolean;
  setRefreshing?: (isRefreshing: boolean) => void;
  setRefreshingAllowed?: (isRefreshing: boolean) => void;
  prependRootSlash?: boolean;
  prefixSlashOnFolder?: boolean;
  uploadType?: FILE_TRANSFER_TYPE_TEXT;
  canCreateFolder?: boolean;
  canUploadFile?: boolean;
} & ICloudContentOutput;

type IPropsAndChildren = IFileExplorerContextInput & {
  children: JSX.Element;
};

export const useInnerFileExplorer = ({
  fileContent,
  putContent,
  getContent,
  contentPath,
  setContentPath,
  downloadFile,
  getFile,
  createServiceUrl,
  storedPath,
  setStoredPath,
  setRefreshingAllowed,
  isRefreshing,
  setRefreshing,
  previewFileHandler,
  defaultPath,
  dragType,
  acceptedDropType,
  onDropFile,
  onDropFolder,
  canUploadFile,
  uploadType,
  prependRootSlash,
  prefixSlashOnFolder,
  canCreateFolder,
}: IFileExplorerContextInput) => {
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isChecksumCalculation, setChecksumCalculation] = useState(false);
  const [calculatedChecksum, setCalculatedChecksum] = useState<number>();
  const [previewFileName, setPreviewFileName] = useState('');
  const [showPreviewWarnWindow, setPreviewWarnWindow] = useState(false);
  const [showedPath, setShowedPath] = useState<string[]>([]);

  const wasStoredPathSet = useRef(false);

  useEffect(() => {
    if (!storedPath || !wasStoredPathSet.current) return;
    setContentPath(storedPath);
    wasStoredPathSet.current = true;
  }, [storedPath, setContentPath]);

  useEffect(() => {
    setShowedPath(!contentPath ? [] : compact(contentPath.split('/')));
    setStoredPath?.(contentPath);
    if (setRefreshingAllowed) {
      setRefreshingAllowed(Boolean(contentPath));
    }
  }, [contentPath, setRefreshingAllowed, setStoredPath]);

  useEffect(() => {
    if (!isRefreshing) return;
    getContent?.();
    setRefreshing?.(false);
  }, [getContent, isRefreshing, setRefreshing]);

  useEffect(() => {
    if (fileContent && isChecksumCalculation) {
      if (!fileContent.file) {
        return;
      }
      const file = fileContent.file;
      setCalculatedChecksum(crc32(file) as number);
      setChecksumCalculation(false);
      return;
    }
    if (fileContent && previewFileHandler) {
      previewFileHandler(fileContent, contentPath);
    }
  }, [contentPath, fileContent, isChecksumCalculation, previewFileHandler]);

  const handleFolderClick = useCallback(
    (fileName: string) => {
      if (defaultPath && !contentPath) {
        setContentPath(fileName.concat(defaultPath.join('')));
        setShowedPath(defaultPath);
        return;
      }

      setContentPath(contentPath.concat('/').concat(fileName));
    },
    [contentPath, defaultPath, setContentPath]
  );

  const handleItemDownloadClick = useCallback(
    (fileName: string) => {
      downloadFile?.(`${showedPath.join('/')}/${fileName}`);
    },
    [downloadFile, showedPath]
  );

  const handleCalculateCRCClick = useCallback(
    (fileName: string) => {
      setChecksumCalculation(true);
      getFile?.([...showedPath, fileName].join('/'));
    },
    [getFile, showedPath]
  );

  const handleBreadcrumbsClick = useCallback(
    (path: React.ReactNode) => {
      const clearPath = removeExcessSlash(path as string);
      if (clearPath.length) {
        setContentPath(`/${clearPath}/`);
      } else {
        setContentPath(`/`);
      }
    },
    [setContentPath]
  );

  const handlePreviewWarnConfirm = useCallback(() => {
    if (!previewFileHandler) {
      setPreviewOpen(true);
    }
    getFile?.([...showedPath, previewFileName].join('/'), true);
  }, [getFile, previewFileHandler, previewFileName, showedPath]);

  const handleItemPreview = useCallback(
    (fileName: string) => {
      if (fileName.split('.').length !== 1) {
        if (!previewFileHandler) {
          setPreviewOpen(true);
        }
        getFile?.([...showedPath, fileName].join('/'), true);
        return;
      }

      setPreviewFileName(fileName);
      setPreviewWarnWindow(true);
    },
    [getFile, previewFileHandler, showedPath]
  );

  const handlePreviewDialogClose = useCallback(() => {
    setPreviewFileName('');
    setPreviewOpen(false);
    setCalculatedChecksum(undefined);
  }, []);

  const toBreadcrumbsTreeItems = useCallback(
    (content: CloudStorageContent[] | undefined): IFolderTreeItem[] => {
      if (!content) {
        return [];
      }
      return content.map((item): IFolderTreeItem => {
        const updated_at = item.updated_at
          ? moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss')
          : null;

        const commonItem = {
          updated_at,
          name: item.name,
          path: item.path,
          dragType: dragType,
          acceptedDropType: acceptedDropType,
        };

        return item.type === EntryType.FILE.toString()
          ? {
              ...commonItem,
              icon: IconNames.DOCUMENT,
              type: EntryType.FILE,
              size: item.size,
              checksum: item.crc32c,
              lastModified: item.updated_at,
              md5: item.md5,
              handleItemDownloadClick: putContent && handleItemDownloadClick,
              handleItemClick: putContent && handleItemPreview,
              handleItemPreview: putContent && handleItemPreview,
              handleDropItem: onDropFile,
            }
          : {
              ...commonItem,
              icon: IconNames.FOLDER_CLOSE,
              type: EntryType.FOLDER,
              lastModified: item.updated_at,
              handleItemClick: handleFolderClick,
              handleDropItem: onDropFolder,
            };
      });
    },
    [
      acceptedDropType,
      dragType,
      handleFolderClick,
      handleItemDownloadClick,
      handleItemPreview,
      onDropFile,
      onDropFolder,
      putContent,
    ]
  );

  const setUploadStatus = useCallback(
    (files: FileList) => {
      if (canUploadFile) {
        putContent?.(files, uploadType);
      }
    },
    [canUploadFile, putContent, uploadType]
  );

  const createBreadCrumbs = useCallback(() => {
    return showedPath;
  }, [showedPath]);

  const handleCopyFilePath = useCallback(
    (filePath: string) => {
      let newFilePath = filePath;
      if (prependRootSlash) {
        newFilePath = '/' + newFilePath;
      }

      newFilePath = '/' + removeFirstSlash(newFilePath);

      copyToClipBoard(newFilePath);
    },
    [prependRootSlash]
  );

  const handleDirectoryPathCopy = useCallback(() => {
    let newUrl = createServiceUrl
      ? createServiceUrl(showedPath.join('/')).fullClearPath
      : showedPath.join('/');

    newUrl = removeFinalSlash(newUrl);
    if (prefixSlashOnFolder) {
      newUrl = '/' + newUrl;
    }

    newUrl = '/' + removeFirstSlash(newUrl);

    copyToClipBoard(newUrl);
  }, [createServiceUrl, prefixSlashOnFolder, showedPath]);

  return {
    isPreviewOpen,
    calculatedChecksum,
    showPreviewWarnWindow,
    handleFolderClick,
    handleItemDownloadClick,
    handleCalculateCRCClick,
    handleBreadcrumbsClick,
    handlePreviewWarnConfirm,
    handleItemPreview,
    handlePreviewDialogClose,
    toBreadcrumbsTreeItems,
    setUploadStatus,
    createBreadCrumbs,
    handleCopyFilePath,
    handleDirectoryPathCopy,
    setCalculatedChecksum,
    setPreviewWarnWindow,
    canCreateFolder,
    canUploadFile,
  };
};

export const FileExplorerProvider = ({
  children,
  ...props
}: IPropsAndChildren) => {
  const innerFileExplorer = useInnerFileExplorer(props);

  return (
    <FileExplorerContext.Provider value={innerFileExplorer}>
      {children}
    </FileExplorerContext.Provider>
  );
};
