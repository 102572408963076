import PrimaryButton from '_molecules/Button/PrimaryButton';
import SecondaryButton from '_molecules/Button/SecondaryButton';
import MapCoveringModal from '_organisms/MapCoveringModal/MapCoveringModal';
import { useFilters } from 'datacosmos/stores/FiltersProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import s from '../Filters/filters.module.scss';
import type { useTaskingRequestList } from './RequestViewing/useTaskingRequestList';
import { Item } from 'react-stately';
import Select2 from '_molecules/Select2/Select2';
import type { Key } from 'react';
import RangeCalendar from '_molecules/Calendars/RangeCalendar/RangeCalendar';
import type { RequestStatus } from '_api/tasking/types';

const REQUEST_STATUS: RequestStatus[] = [
  'PENDING',
  'PREPARED',
  'ACQUIRING',
  'FULFILLED',
  'PARTIALLY_FULFILLED',
  'CANCELLED',
  'FAILED',
];

type TaskingFiltersProps = {
  taskingRequestsHook: ReturnType<typeof useTaskingRequestList>;
};

const TaskingFilters = ({ taskingRequestsHook }: TaskingFiltersProps) => {
  const {
    getNumberOfActiveFilters,
    areFiltersApplied,
    clearAllFilters,
    setTaskingDateRangeFilter,
    taskingStatusFilter,
    setTaskingStatusFilter,
  } = useFilters();

  const { translate } = useLocalisation();
  const { total: noOfActiveFilters } = getNumberOfActiveFilters();

  return (
    <MapCoveringModal
      mapContainerWidthPx={400}
      setHeightToContentHeight={true}
      getModalTrigger={(triggerRef, triggerProps, toggleModal) => (
        <div className="relative top-[2px]">
          <SecondaryButton
            icon="Filter"
            size={24}
            text={translate('datacosmos.buttons.filters')}
            data-testid="datacosmos-search-results-filter-toggle-button"
            {...triggerProps}
            onPress={() => {
              toggleModal();
            }}
            ref={triggerRef}
            className="tour-filters"
          />

          {noOfActiveFilters !== 0 && (
            <span
              style={{
                position: 'absolute',
                borderRadius: '50%',
                height: '19px',
                border: '1px solid rgba(115, 134, 148, 0.3)',
                width: '19px',
                textAlign: 'center',
                top: -11,
                right: -10,
                fontSize: '13px',
                cursor: 'pointer',
              }}
              className="color-item"
              onClick={toggleModal}
            >
              {noOfActiveFilters}
            </span>
          )}
        </div>
      )}
    >
      {() => (
        <div className="h-full w-full flex flex-col gap-4">
          <div className="filter-datepicker">
            <h5
              className={
                Classes.HEADING +
                ' ' +
                s.filterHeading +
                ' dark:text-item-dark-contrast'
              }
            >
              <Icon icon={IconNames.CALENDAR} />
              <span>{translate('datacosmos.filters.dates')}</span>
            </h5>
            <div className={'flex justify-between'}>
              <RangeCalendar
                onChange={(range) => {
                  setTaskingDateRangeFilter([
                    new Date(range.start.toString()),
                    new Date(range.end.toString()),
                  ]);
                  void taskingRequestsHook.fetchTaskingRequests(undefined, {
                    start_date: new Date(range.start.toString()),
                    end_date: new Date(range.end.toString()),
                  });
                }}
                highligtedDates={[]}
              />
            </div>
          </div>

          <div>
            <h5
              className={
                Classes.HEADING +
                ' ' +
                s.filterHeading +
                'dark:text-item-dark-contrast m-0'
              }
            >
              <Icon icon={IconNames.SETTINGS} />{' '}
              <span>{translate('datacosmos.tasking.filters.status')}</span>
            </h5>
            <Select2
              onSelectionChange={(item: Key) => {
                setTaskingStatusFilter(item as string);
                void taskingRequestsHook.fetchTaskingRequests(undefined, {
                  status: item as string,
                });
              }}
              selectedKey={taskingStatusFilter ?? ''}
              fill
              placeholder="Select status"
            >
              {REQUEST_STATUS.map((item) => (
                <Item key={item}>
                  {translate(
                    `datacosmos.tasking.existing.requestStatus.${item}.title`
                  )}
                </Item>
              ))}
            </Select2>
          </div>
          <div className="flex items-end justify-center">
            {areFiltersApplied && (
              <PrimaryButton
                icon="Cross"
                text={translate('datacosmos.filters.clearAll')}
                onPress={() => {
                  clearAllFilters();
                  void taskingRequestsHook.fetchTaskingRequests(undefined, {});
                }}
                className="w-full h-16 text-center"
              />
            )}
          </div>
        </div>
      )}
    </MapCoveringModal>
  );
};

export default TaskingFilters;
