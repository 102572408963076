import { hosts } from '_api/hosts';
import type {
  Mission,
  UserAssignments,
  Organisation,
  Programme,
  Intent,
  OrganisationJoinPolicy,
} from '_api/administration/types';
import { apiFetchHandler } from '_api/clientFetch';
import type { EmptyParams } from '_api/client';

export * from '_api/administration/types';

export type GetMissionsParams = {
  userId: string;
};

export const getMissions = apiFetchHandler<Mission[], GetMissionsParams>({
  host: hosts.portal.v0,
  endpoint: ({ userId }) => `/missions?user_id=${userId}`,
  method: 'GET',
  errorMessage: 'Could not get missions',
  errorDescription: (e) => e.join('; '),
});

export type GetRoleAssignmentsParams = {
  userId: string;
  resourceType?: string;
};

export const getRoleAssignments = apiFetchHandler<
  UserAssignments[],
  GetRoleAssignmentsParams
>({
  host: hosts.portal.v2,
  endpoint: ({ userId, resourceType }) =>
    `/users/${userId}/role-assignments?resourceType=${encodeURIComponent(
      resourceType ?? ''
    )}`,
  method: 'GET',
  errorMessage: 'Could not get user role assignments',
  errorDescription: (e) => e.join('; '),
});

export type GetOrganisationsParams = {
  userId: string;
};

export const getOrganisations = apiFetchHandler<
  Organisation[],
  GetOrganisationsParams
>({
  host: hosts.portal.v0,
  endpoint: ({ userId }) => `/organisations?user_id=${userId}`,
  method: 'GET',
  errorMessage: 'Could not fetch organisations',
  errorDescription: (e) => e.join('; '),
});

export type PostOrganisationBody = {
  name: string;
  domain: string;
  join_policy: OrganisationJoinPolicy;
};

export const postOrganisation = apiFetchHandler<
  Organisation,
  EmptyParams,
  PostOrganisationBody
>({
  host: hosts.portal.v0,
  endpoint: `/organisations`,
  method: 'POST',
  errorMessage: 'Could not post organisation',
  errorDescription: (e) => e.join('; '),
});

export type GetProgrammesParams = {
  userId: string;
};

export const getProgrammes = apiFetchHandler<
  Programme[],
  GetOrganisationsParams
>({
  host: hosts.portal.v0,
  endpoint: ({ userId }) => `/programmes?user_id=${userId}`,
  method: 'GET',
  errorMessage: 'Could not fetch programmes',
  errorDescription: (e) => e.join('; '),
});

export type GetAuthorisationResultsParams = {
  userId: string | undefined;
};

export type GetAuthorisationResultsBody = {
  subjectToken: string | undefined;
  intents: Intent[];
};

export const getAuthorisationResults = apiFetchHandler<
  boolean[],
  GetAuthorisationResultsParams,
  GetAuthorisationResultsBody
>({
  host: hosts.portal.v2,
  endpoint: ({ userId }) =>
    `/users/${encodeURIComponent(userId ?? '')}/rpc/getAuthorisationResult`,
  method: 'POST',
  errorMessage: 'Could not fetch authorisation results',
});
