import b64 from 'base64-arraybuffer';

export const base64ToByteArray = (input: string) => {
  const binary = atob(input);
  const array = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }

  return array;
};

export const byteArrayToBase64String = (input: Uint8Array) => {
  return b64.encode(input);
};

export const byteArrayToBaseXString = (input: Uint8Array, base: number) => {
  let out = '';

  const byteLength = Math.ceil(Math.log(256) / Math.log(base));

  for (const element of input) {
    out += element.toString(base).padStart(byteLength, '0') + ' ';
  }

  return out.trim();
};

export const baseXStringToByteArray = (input: string, base: number) => {
  // eslint-disable-next-line no-useless-escape
  const clean = input.replace(/\s|\'|\"|\x|\n|\r|0x/g, '');

  const byteLength = Math.ceil(Math.log(256) / Math.log(base));

  if (clean.length % byteLength !== 0) {
    throw new Error(
      `Parsing from string to byte array with base ${base}, however length of valid characters is not multiple of ${byteLength}`
    );
  }

  const words = clean.match(new RegExp(`.{1,${byteLength}}`, 'g'));

  if (!words) {
    return;
  }

  const out = new Uint8Array(words.length);

  for (let i = 0; i < words.length; i++) {
    out[i] = parseInt(words[i], base);
  }

  return out;
};

export const byteArrayToBase2String = (input: Uint8Array) => {
  return byteArrayToBaseXString(input, 2);
};
export const byteArrayToBase10String = (input: Uint8Array) => {
  return byteArrayToBaseXString(input, 10);
};
export const byteArrayToBase16String = (input: Uint8Array) => {
  return byteArrayToBaseXString(input, 16);
};

export const base16StringToByteArray = (input: string) => {
  return baseXStringToByteArray(input, 16);
};

export const byteArrayToUTF8String = (input: Uint8Array) => {
  return new TextDecoder('utf-8').decode(input);
};

export const UTF8StringToByteArray = (input: string) => {
  return new TextEncoder().encode(input);
};
