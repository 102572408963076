import type { PixelData } from '_api/sampling/service';
import { getLinePixelData } from '_api/sampling/service';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useCallback, useState } from 'react';
import type { ClickedPoint } from '../useClickedStacItem';
import { findClosestPointOnParallelogramEdgeFromClickedPoint } from 'datacosmos/utils/vector';

export type CommonChartData = {
  latitude: number;
  longitude: number;
};

export type MultiBandChartData = CommonChartData & {
  rValue: number | string;
  gValue: number | string;
  bValue: number | string;
};

export type SingleBandChartData = CommonChartData & {
  value: number | string;
};

export type LinePixelsChartData = {
  horizontal: MultiBandChartData[] | SingleBandChartData[];
  vertical: MultiBandChartData[] | SingleBandChartData[];
};

export const isSingleBandChartData = (
  data: MultiBandChartData[] | SingleBandChartData[]
): data is SingleBandChartData[] =>
  (data[0] as SingleBandChartData).value !== undefined;

export const useLinePixels = () => {
  const { addLayer, removeLayersBySourceType } = useMapLayers();

  const [isToggled, setIsToggled] = useState<boolean>(false);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [linePixelsChartData, setLinePixelsChartData] = useState<
    LinePixelsChartData | undefined
  >();

  const getLinesFromBBoxAndClickedPoint = (
    polygon: GeoJSON.Polygon,
    clickedPoint: ClickedPoint
  ) => {
    const northWestCorner = polygon.coordinates[0][3];
    const southWestCorner = polygon.coordinates[0][0];
    const northEastCorner = polygon.coordinates[0][2];
    const southEastCorner = polygon.coordinates[0][1];

    const horizontalLineStart =
      findClosestPointOnParallelogramEdgeFromClickedPoint({
        clickedPoint: [clickedPoint.lng, clickedPoint.lat],
        edgeStart: northWestCorner,
        edgeEnd: southWestCorner,
        adjacentPoint: northEastCorner,
      });

    const horizontalLineEnd =
      findClosestPointOnParallelogramEdgeFromClickedPoint({
        clickedPoint: [clickedPoint.lng, clickedPoint.lat],
        edgeStart: southEastCorner,
        edgeEnd: northEastCorner,
        adjacentPoint: southWestCorner,
      });

    const verticalLineStart =
      findClosestPointOnParallelogramEdgeFromClickedPoint({
        clickedPoint: [clickedPoint.lng, clickedPoint.lat],
        edgeStart: northEastCorner,
        edgeEnd: northWestCorner,
        adjacentPoint: southEastCorner,
      });

    const verticalLineEnd = findClosestPointOnParallelogramEdgeFromClickedPoint(
      {
        clickedPoint: [clickedPoint.lng, clickedPoint.lat],
        edgeStart: southWestCorner,
        edgeEnd: southEastCorner,
        adjacentPoint: northWestCorner,
      }
    );

    const horizontalLineGeoJSON: GeoJSON.LineString = {
      type: 'LineString',
      coordinates: [horizontalLineStart, horizontalLineEnd],
    };

    const verticalLineGeoJSON: GeoJSON.LineString = {
      type: 'LineString',
      coordinates: [verticalLineStart, verticalLineEnd],
    };

    return { horizontalLineGeoJSON, verticalLineGeoJSON };
  };

  const markClickedPointLines = useCallback(
    (polygon: GeoJSON.Polygon, clickedPoint: ClickedPoint) => {
      removeLayersBySourceType(LayerSourceType.PIXEL_MODE_MARKER);

      const { horizontalLineGeoJSON, verticalLineGeoJSON } =
        getLinesFromBBoxAndClickedPoint(polygon, clickedPoint);

      const horizontalLine = new LineLayer(
        LayerSourceType.PIXEL_MODE_MARKER,
        '',
        horizontalLineGeoJSON,
        0
      );
      const verticalLine = new LineLayer(
        LayerSourceType.PIXEL_MODE_MARKER,
        '',
        verticalLineGeoJSON,
        0
      );

      addLayer(horizontalLine, verticalLine);
    },
    [addLayer, removeLayersBySourceType]
  );

  const pixelDataToChartCompatiblePixelData = (
    data: PixelData[] | undefined
  ): MultiBandChartData[] | SingleBandChartData[] | undefined => {
    if (!data) {
      return;
    }

    if (data[0].value.length > 1) {
      return data.map((d) => ({
        latitude: d.latitude,
        longitude: d.longitude,
        rValue: d.value[0],
        gValue: d.value[1],
        bValue: d.value[2],
      }));
    }

    return data.map((d) => ({
      latitude: d.latitude,
      longitude: d.longitude,
      value: d.value[0],
    }));
  };

  const getSelectedLinePixelData = useCallback(
    async (
      clickedPoint: ClickedPoint,
      polygon: GeoJSON.Polygon | undefined,
      url: string | undefined
    ) => {
      if (!polygon || !url) {
        return;
      }

      markClickedPointLines(polygon, clickedPoint);

      const { horizontalLineGeoJSON, verticalLineGeoJSON } =
        getLinesFromBBoxAndClickedPoint(polygon, clickedPoint);

      setIsFetching(true);

      const horizontalPixels = await getLinePixelData({
        params: {
          url,
          start_latitude: horizontalLineGeoJSON.coordinates[0][1],
          start_longitude: horizontalLineGeoJSON.coordinates[0][0],
          end_latitude: horizontalLineGeoJSON.coordinates[1][1],
          end_longitude: horizontalLineGeoJSON.coordinates[1][0],
        },
      });

      if (!horizontalPixels.data) {
        setIsFetching(false);
        return;
      }

      const verticalPixels = await getLinePixelData({
        params: {
          url,
          start_latitude: verticalLineGeoJSON.coordinates[0][1],
          start_longitude: verticalLineGeoJSON.coordinates[0][0],
          end_latitude: verticalLineGeoJSON.coordinates[1][1],
          end_longitude: verticalLineGeoJSON.coordinates[1][0],
        },
      });

      if (!verticalPixels.data) {
        setIsFetching(false);
        return;
      }

      setLinePixelsChartData({
        horizontal:
          pixelDataToChartCompatiblePixelData(horizontalPixels.data) ?? [],
        vertical:
          pixelDataToChartCompatiblePixelData(verticalPixels.data) ?? [],
      });

      setIsFetching(false);
    },
    [markClickedPointLines]
  );

  const toggleLinePixelValuesMode = (toggle?: boolean) => {
    if (isToggled) {
      removeLayersBySourceType(LayerSourceType.PIXEL_MODE_MARKER);
    }
    setIsToggled(toggle !== undefined ? toggle : !isToggled);
  };

  return {
    getSelectedLinePixelData,
    isToggled,
    toggleLinePixelValuesMode,
    isFetching,
    linePixelsChartData,
  };
};
