import type { ReactElement } from 'react';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetPopUp } from '../../pages/shared/state/actions/popUp/thunks';
import { Overlay, H3 } from '@blueprintjs/core';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { DRAGGABLE } from '../../constants/draggable/constants';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import type { AppState } from '../../store';
import type { ThunkDispatch } from 'redux-thunk';
import type { Action } from 'redux';

interface IStateProps {
  popUp?: IPopUp;
}

interface IDispatchProps {
  resetPopUp?: (event: React.SyntheticEvent<HTMLElement>) => void;
}

type IProps = IStateProps & IDispatchProps & WithTranslation;

class PopUp extends Component<IProps> {
  public constructor(props: Readonly<IProps>) {
    super(props);
    this.renderByType = this.renderByType.bind(this);
  }

  private renderByType(): ReactElement {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      component: comp,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data,
      functions,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      otherProps,
    } = this.props.popUp!;
    if (comp) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const initialValues = { ...data };
      return (
        //@ts-expect-error
        <comp
          {...otherProps}
          // eslint-disable-next-line react/no-unknown-property, @typescript-eslint/no-unsafe-assignment
          initialValues={initialValues}
          {...functions}
          // eslint-disable-next-line react/no-unknown-property
          resetPopUp={this.props.resetPopUp}
          // eslint-disable-next-line react/no-unknown-property
          t={this.props.t}
        />
      );
    }
    return <></>;
  }

  public render(): ReactElement {
    //@ts-expect-error
    const { visible, classNames } = this.props.popUp;
    if (visible) {
      const component = this.renderByType();
      return (
        <Overlay
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          isOpen={visible}
          usePortal={false}
          hasBackdrop={false}
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          onClose={this.props.resetPopUp}
        >
          <div
            id={DRAGGABLE}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            className={`dialog-container ${classNames.join(' ')}`}
          >
            {component ? component : <H3>Form not found!</H3>}
          </div>
        </Overlay>
      );
    }
    return <></>;
  }
}

export default connect<IStateProps, IDispatchProps>(
  //@ts-expect-error
  (state: AppState): IStateProps => ({
    popUp: state.popUp,
  }),
  (
    dispatch: ThunkDispatch<AppState, null, Action<string>>
  ): IDispatchProps => ({
    resetPopUp: (): void => dispatch(resetPopUp()),
  })
)(withTranslation()(PopUp));
