import classNames from "classnames";
import type { ReactNode } from "react";
import { type CheckboxProps } from "react-aria-components";

type Props = {
  themeProvider: {
    isDarkmode: boolean;
    setTheme: (theme: "LIGHT" | "DARK" | "AUTO") => void;
    theme: "LIGHT" | "DARK" | "AUTO";
  };
} & CheckboxProps;

const CustomCheckbox = ({ children, ...props }: Props) => {
  const theme = props.themeProvider;

  const getCheckboxFill = () => {
    if (props.isSelected) {
      return theme?.isDarkmode ? "#FFCC55" : "#FFC848";
    }

    if (props.isDisabled) {
      return theme?.isDarkmode ? "#303030" : "#F2F0ED";
    }

    return theme?.isDarkmode ? "#535353" : "#ECE9E5";
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    props.onChange && props.onChange(event.target.checked);
  };

  const handleCheckboxClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation(); // Prevents the checkbox click event from bubbling up to the parent div
  };

  return (
    <div
      className={classNames("flex items-center gap-2", {
        "cursor-not-allowed": props.isDisabled,
      })}
    >
      <input
        type="checkbox"
        id="checkbox"
        className={classNames("cursor-pointer", {
          "cursor-not-allowed": props.isDisabled,
        })}
        checked={props.isSelected}
        disabled={props.isDisabled}
        style={{
          accentColor: getCheckboxFill(),
          width: "14px",
          height: "14px",
        }}
        onChange={(e) => {
          handleCheckboxChange(e);
        }}
        onClick={handleCheckboxClick}
      ></input>
      <label
        htmlFor="checkbox"
        className={classNames("cursor-pointer", {
          "cursor-not-allowed": props.isDisabled,
        })}
      >
        {children as ReactNode}
      </label>
    </div>
  );
};

export default CustomCheckbox;
