import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useRef } from 'react';
import { useColorbarData } from './useColorbarData';

interface IProps {
  layer: SingleBandSTACLayer;
}

const BottomColorBar = ({ layer }: IProps) => {
  const { pairedColormaps, title, units } = useColorbarData(layer);
  const colorbarRef = useRef<HTMLDivElement>(null);
  const segmentWidth =
    colorbarRef.current?.clientWidth ?? 0 / pairedColormaps.length;
  return (
    <div className="flex flex-col  gap-px">
      <div className="flex items-center gap-1 text-sm">
        <label data-testid="colorbar-title" className="block p-0 m-0">
          {title}
        </label>
        <label data-testid="colorbar-units" className="block p-0 m-0">
          ({units})
        </label>
      </div>

      <div data-testid="colorbar" className="w-full h-6 flex" ref={colorbarRef}>
        {pairedColormaps.map((pair, i) => (
          <div
            key={Math.random() * 1000000}
            style={{
              width: isNaN(segmentWidth) ? 5 : segmentWidth,
            }}
            className="flex flex-col gap-0"
          >
            <div
              data-testid="color-segment"
              className="w-full h-full"
              style={{
                background: `linear-gradient(to right, ${pair[0].colour}, ${pair[1].colour})`,
                width: isNaN(segmentWidth) ? 5 : segmentWidth,
              }}
            >
              &#8203;
            </div>

            <div className={`flex items-center w-full text-xs`}>
              <div className="self-end">|</div>
              {pairedColormaps.length - 1 === i && (
                <div className="ml-auto"> |</div>
              )}
            </div>

            <div className="flex items-center justify-start w-full text-left text-xs">
              <div className="self-end">{pair[0].value.toFixed(3)}</div>
              {pairedColormaps.length - 1 === i && (
                <div className="ml-auto">{pair[1].value.toFixed(3)}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomColorBar;
