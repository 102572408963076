import i18n from 'i18n/client';
import { useCallback } from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';

/**
 * clientTrasnlate exposes the translate function from the internationalisation library
 * directly. Usefull when translating strings outside of react components
 * @param parameters path to the requested trasnlation; other parameters of the internationalisation libary's translate fn
 * @returns translation at the given key
 */
export const clientTranslate: TFunction = (
  ...parameters: Parameters<TFunction>
) => i18n.t(...parameters);

export const useLocalisation = () => {
  const { t } = useTranslation();

  const translate: typeof t = useCallback(
    (...parameters: Parameters<typeof t>) => t(...parameters),
    [t]
  );
  const doesTranslationExist = (...keyPath: Parameters<typeof t>) => {
    return (t(...keyPath) as string) !== keyPath[0];
  };

  /**
   * translateWithExplicitPath returns the translation at the given path, if exists or a passed fallback value
   * if it doesn't
   * @param keyPath path to the translation as per defined translation.ts file
   * @param fallback value to fall back to if the translation at the given key does not exist
   * @returns translation at the given path or the passed fallback value
   */
  const translateWithExplicitFallback = (
    keyPath: Parameters<typeof t>[0],
    fallback: string
  ): string => {
    if (!doesTranslationExist(keyPath, undefined, {})) {
      return fallback;
    }

    return t(keyPath) as string;
  };

  return {
    translate,
    translateWithExplicitFallback,
  };
};
