import React from 'react';

interface IProps {
  minimum: number;
  maximum: number;
  stepSize: number;
  formatter?: (value: number) => string | number;
}

const NumberScale = ({ minimum, maximum, stepSize, formatter }: IProps) => {
  const split = (left: number, right: number, parts: number) => {
    const result = [],
      delta = (right - left) / parts;
    while (left < right) {
      result.push(left);
      left += delta;
    }
    result.push(right);
    return result;
  };

  const getScale = (min: number, max: number) => {
    const amountOfNumbers =
      (max - min) / stepSize > 10 ? 4 : (max - min) / stepSize;
    const scaleArray = new Array(amountOfNumbers).fill(null).map((item, i) => {
      if (amountOfNumbers === 4) {
        return Number(Number(split(min, max, 4)[i]).toFixed(2));
      }
      return Number(Number(min + i * stepSize).toFixed(2));
    });
    scaleArray[0] = min;
    scaleArray.push(max);
    return scaleArray;
  };
  return (
    <div
      className="flex justify-between text-xs pt-3 relative"
      data-testid="scale"
    >
      {getScale(minimum, maximum).map((no) => (
        <span key={no} data-testid="scale-number">
          {formatter ? formatter(no) : no}
        </span>
      ))}
    </div>
  );
};

export default NumberScale;
