import type { IMissionsActionTypes } from '../../../../constants/missions/actionTypes';
import {
  MISSION_LOAD,
  MISSION_ADD,
  MISSION_UPDATE,
  MISSION_SET_CURRENT,
} from '../../../../constants/missions/actionTypes';
import type { IMission } from '../../../../services/Missions';

type IMissionsState = IMission[];

const initialState: IMissionsState = [];

export default function missionReducer(
  state: IMissionsState = initialState,
  action: IMissionsActionTypes
): IMissionsState {
  switch (action.type) {
    case MISSION_LOAD: {
      return [...action.missions];
    }
    case MISSION_ADD: {
      return [...state, action.mission];
    }
    case MISSION_UPDATE: {
      if (state.length) {
        return [
          ...state.map((mission) => {
            if (mission.mission === action.mission.mission) {
              return action.mission;
            }
            return mission;
          }),
        ];
      }
      return [...state, action.mission];
    }
    case MISSION_SET_CURRENT: {
      if (state.length) {
        return state.map((mission) => {
          if (mission.mission === action.mission.mission) {
            return { ...mission, current: true };
          }
          return { ...mission, current: false };
        });
      }
      return [...state, { ...action.mission, current: true }];
    }
    default: {
      return state;
    }
  }
}
