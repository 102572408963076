import type { AxiosRequestConfig } from 'axios';
import Axios from 'axios';
import type {
  ApiHandlerDataType,
  ClientHeaders,
  ClientMethod,
  HandlerOptions,
} from './client';
import { genericApiHandler } from './client';

//TODO: This represents a solution to tracking uploading and downloading progress, since fetch doesn't support both of those functionalities.
//TODO: Remove when both become stable in fetch

const createAxiosClient = () => {
  const headers: ClientHeaders = {
    'Content-Type': 'application/json',
  };

  return {
    headers,
    fetch: async <
      Data,
      Params extends {} | undefined = {},
      Body extends {} | undefined = {}
    >(
      url: string,
      options?: {
        method?: ClientMethod;
        params?: Params;
        body?: Body;
        onDownloadProgress?: (event: ProgressEvent) => void;
        onUploadProgress?: (event: ProgressEvent) => void;
      } & Omit<AxiosRequestConfig, 'method'>
    ) => {
      const request: AxiosRequestConfig = {
        ...options,
        method: options?.method ?? 'GET',
        headers,
        onDownloadProgress: options?.onDownloadProgress,
        onUploadProgress: options?.onUploadProgress,
      };

      if (options?.body) {
        request.data = options.body;
      }

      const response = await Axios(url, request);

      return {
        response: (await response.data) as Data,
        status: response.status,
        ok: response.statusText === 'OK',
      };
    },
  };
};

export const axiosClient = createAxiosClient();

type AxiosHandlerArgs<Params, Body> = {
  params?: Params;
  body?: Body;
  onUploadProgress?: (e: ProgressEvent) => void;
  onDownloadProgress?: (e: ProgressEvent) => void;
};

const requestViaAxiosClient = async <
  Data extends ApiHandlerDataType,
  Params extends {},
  Body extends {} | undefined = undefined
>({
  url,
  options,
  method,
}: {
  url: string;
  method: ClientMethod;
  options: AxiosHandlerArgs<Params, Body> &
    Omit<
      AxiosRequestConfig,
      'onDownloadProgress' | 'onUploadProgress' | 'method'
    >;
}) => {
  return await axiosClient.fetch<Data, Params, Body>(url, {
    ...options,
    method: method,
  });
};

export function apiAxiosHandler<
  Data extends ApiHandlerDataType,
  Params extends {} = {},
  Body extends {} | undefined = undefined,
  TransformedData extends ApiHandlerDataType | undefined = undefined
>(options: HandlerOptions<Data, Params, Body, TransformedData>) {
  return genericApiHandler(options, requestViaAxiosClient<Data, Params, Body>);
}
