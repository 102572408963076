import React, { useEffect, useRef } from 'react';
import type { IMessageLog } from '../../hooks/useMessageLog';

export interface IProps {
  messages: IMessageLog[];
}

const Messages = ({ messages = [] }: IProps) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messages.length > 0 && container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  }, [messages.length]);

  return (
    <div className="h-full w-full overflow-auto" ref={container}>
      {messages.map((item) => (
        <div key={item.key} className="w-full">
          <span className="mx-2 my-4">{item.time}</span>
          <span className="mx-2 my-4">{item.level}</span>
          <span className="mx-2 my-4">{item.description}</span>
        </div>
      ))}
    </div>
  );
};

export default Messages;
