import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import configureStore from './store';
import { MODE, SENTRY_ENABLE, SENTRY_RELEASE } from 'env';

// TODO: Make it true by default after all effect dependencies are fixed
export const ENABLE_STRICT_MODE: boolean = MODE === 'development' && false;

// import { setUseWhatChange } from '@simbathesailor/use-what-changed';
// setUseWhatChange(MODE === 'development');

// Usage:
// import { useWhatChanged } from '@simbathesailor/use-what-changed';
// useWhatChanged([a, b, b], 'a, b, c', 'suffix');

export const history = createBrowserHistory({
  basename: '/',
});

// Sentry must be disabled in certain situations such as localhost and gitlab CI due to CORS
if (SENTRY_ENABLE) {
  Sentry.init({
    dsn: 'https://b66bae64bd644772b40f0da41aca412e@o279621.ingest.sentry.io/1502579',
    tunnel: '/api/portal/v0/s-relay/',
    release: SENTRY_RELEASE,
    ignoreErrors: [
      // This error happens on one tooltip on the RTI focus cmd button and is not critical nor does it affect anything.
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    beforeSend(event) {
      const messages = event.exception?.values;

      const is403Error =
        messages && messages.length > 0
          ? messages[0].value === 'Request failed with status code 403'
          : false;

      if (!is403Error && event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    tracesSampleRate: 0.005, // @TODO: Tune for performance (higher value, better performance)
  });
}

export const store = configureStore();
