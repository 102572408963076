import React from 'react';
import Ansi from 'ansi-to-react';
import { Pre } from '@blueprintjs/core';

export interface IFormattedSSHMessage {
  message: string;
  is_error: boolean;
  source?: string | undefined;
  metadata: unknown;
}

interface IProps {
  data: IFormattedSSHMessage;
}
const SSHReplyInfo = (props: IProps) => {
  const { is_error, message, source } = props.data;

  return (
    <div>
      {source && <span>{source}</span>}
      <Pre style={{ border: is_error ? '1px solid #FF695E' : 'none' }}>
        <Ansi>{message}</Ansi>
      </Pre>
    </div>
  );
};

export default SSHReplyInfo;
