import React from 'react';
import { Icon, Tag } from '@blueprintjs/core';
import { preventDefaultPropagation } from '../../utils/common/CommonUtils';
import useDragAndDropSorting from '../../utils/hooks/useDragAndDropSorting';
import s from './index.module.scss';

interface IProps {
  title: string;
  item: unknown;
  index: number;
  onTagClick: (item: unknown) => void;
  handleTagHeaderRightItemClick?: (index: number, item: unknown) => void;
  moveTagItem?: (dragIndex: number, hoverIndex: number) => void;
  tagHeaderRight?: string | React.ReactNode;
}

const DndListTag = (props: IProps) => {
  const { opacity, dndSortingRef } = useDragAndDropSorting(
    props.index,
    props.moveTagItem ?? (() => {})
  );

  return (
    <div ref={dndSortingRef}>
      <Tag
        style={{ opacity }}
        className={s.titleTagPointer}
        fill
        large
        onClick={() => props.onTagClick(props.index)}
      >
        <div className={s.tagListItem}>
          <Icon icon="drag-handle-vertical" />
          <div className={s.tagTitle}>
            <span>{props.title}</span>
            <span
              role="button"
              tabIndex={0}
              onKeyDown={preventDefaultPropagation(
                props.handleTagHeaderRightItemClick
              )(props.index, props.item)}
              onClick={preventDefaultPropagation(
                props.handleTagHeaderRightItemClick
              )(props.index, props.item)}
            >
              {props.tagHeaderRight}
            </span>
          </div>
        </div>
      </Tag>
    </div>
  );
};

export default DndListTag;
