import { Icon, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import s from './index.module.scss';
import { Tooltip } from 'opencosmos-ui';

interface IProps {
  sessionConnections: number;
}

const SessionConnectionTooltip = (props: IProps) => {
  const { sessionConnections } = props;

  const sessionStatusStyle =
    sessionConnections > 0 ? s.activity__active : s.activity__innactive;

  return (
    <Tooltip
      className={s.activity}
      content={`${sessionConnections} active connections`}
      placement={Position.RIGHT}
    >
      <Icon className={sessionStatusStyle} icon={IconNames.PULSE} />
    </Tooltip>
  );
};

export default SessionConnectionTooltip;
