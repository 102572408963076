import { useCallback, useEffect, useMemo, useState } from 'react';
import { has, find } from 'lodash/fp';
import type { ICloudContentOutput } from './cloudStorage';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { isNil } from 'lodash';

import { RTI_COMMANDS } from 'constants/ops/rti/oparate/constants';
import { ftpToFolder, sftpToFolder } from 'utils/fileExplorer/satelliteUtils';
import { pastCommandFromClipboard } from 'actions/operate/actions';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';

import type { UnarmedCommand } from './realtimeTerminalSession';
import { removeExcessSlash } from 'utils/common/stringUtils';
import type { CloudStorageContent } from '_api/cloudStorage/types';
import type { CommandDefinition } from '_api/telecommands/types';

export type IFtpSatelliteFolder = {
  name: string;
  lastModified: number;
  size?: number;
  isDir: boolean;
  checksum: number;
};

export type ISftpSatelliteFolder = {
  Name: string;
  LastModified: string;
  Size: number;
  Type: 'file' | 'folder';
};

export type ISatelliteStorageApi = ICloudContentOutput & {
  failedToFetch: boolean;
};

export type FtpData = {
  files: { files: IFtpSatelliteFolder[] | undefined };
};

export type SftpData = {
  FolderItems: ISftpSatelliteFolder[];
};

export type TerminalSessionMessage = {
  data: FtpData | SftpData;
  type: string;
  meta: null;
  links: null;
  sequenceId: number;
};

export const useApiSatelliteStorageContent = (
  sendCommand: UnarmedCommand,
  availableCommands: CommandDefinition[],
  setRightSideHidden: (hidden: boolean) => void,
  cancelSentCommand: Function,
  missionId: number
): ISatelliteStorageApi => {
  const { dispatch } = useOperate();

  const [folderContent, setFolderContent] = useState<CloudStorageContent[]>([]);
  const [timerId, setTimerId] = useState<number>();
  const [sequenceId, setSequenceId] = useState<number>();
  const [contentPath, setContentPath] = useState('');
  const [currentService, setCurrentService] = useState<string>();

  // Stored content path contains the path only after a successful callback
  const [storedCurrentService, setStoredCurrentService] = useState<
    string | undefined
  >();
  const [storedContentPath, setStoredContentPath] = useState('');

  // States
  const [isRequestCanceled, setRequestCanceled] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [failedToFetch, setFailedToFetch] = useState(false);

  const shouldUseSftp = useMemo(() => {
    return !availableCommands.some((c) => c.group === 'Ftp');
  }, [availableCommands]);

  const ftpSuccessHandler = useCallback(
    (data: TerminalSessionMessage) => {
      if (!has('data.files', data)) {
        return;
      }

      const res = (data.data as FtpData).files;

      if (!res.files) {
        setFolderContent([]);
        return;
      }

      setFolderContent(
        res.files.map((item) =>
          ftpToFolder(contentPath)(item)
        ) as CloudStorageContent[]
      );

      setFetching(false);
    },
    [contentPath]
  );

  const sftpSuccessHandler = useCallback(
    (data: TerminalSessionMessage) => {
      if (!has('data.FolderItems', data)) {
        return;
      }

      const res = (data.data as SftpData).FolderItems;

      if (!res) {
        setFolderContent([]);
        return;
      }

      setFolderContent(
        res.map((item) =>
          sftpToFolder(contentPath)(item)
        ) as CloudStorageContent[]
      );

      setFetching(false);
    },
    [contentPath]
  );

  const successCallback = useCallback(
    (data: TerminalSessionMessage) => {
      if (data.type === RTI_COMMANDS.FTP_LIST_FILE_RESPONSE && data.data) {
        ftpSuccessHandler(data);
      }

      if (data.type === RTI_COMMANDS.SFTP_LIST_FILE_RESPONSE && data.data) {
        sftpSuccessHandler(data);
      }

      setStoredContentPath(contentPath);
      setStoredCurrentService(currentService);
    },
    [contentPath, currentService, ftpSuccessHandler, sftpSuccessHandler]
  );

  const timeOutCallBack = useCallback(() => {
    showErrorMessage('Cannot retrieve list of files from satellite!');
    setFetching(false);
    if (
      contentPath === storedContentPath &&
      currentService === storedCurrentService
    ) {
      setFailedToFetch(true);
    } else {
      setContentPath(storedContentPath);
      setCurrentService(storedCurrentService);
    }
  }, [contentPath, currentService, storedContentPath, storedCurrentService]);

  const getContentViaFtp = useCallback(() => {
    return sendCommand({
      command: RTI_COMMANDS.FTP_LIST,
      payload: { satelliteService: currentService!, path: contentPath },
      cb: successCallback,
      responseType: RTI_COMMANDS.FTP_LIST_FILE_RESPONSE,
      timeoutCb: timeOutCallBack,
      getTimeOutId: (id) => setTimerId(id),
    });
  }, [
    contentPath,
    currentService,
    sendCommand,
    successCallback,
    timeOutCallBack,
  ]);

  const getContentViaSftp = useCallback(() => {
    return sendCommand({
      command: RTI_COMMANDS.SFTP_LIST_FOLDER_ITEMS,
      payload: { remote_server: currentService!, folder_path: contentPath },
      cb: successCallback,
      responseType: RTI_COMMANDS.SFTP_LIST_FILE_RESPONSE,
      timeoutCb: timeOutCallBack,
      getTimeOutId: (id) => setTimerId(id),
    });
  }, [
    contentPath,
    currentService,
    sendCommand,
    successCallback,
    timeOutCallBack,
  ]);

  const getContent = useCallback(() => {
    setFetching(true);
    setRequestCanceled(false);
    setFailedToFetch(false);
    try {
      if (!currentService) {
        setFetching(false);
        return false;
      }

      const { sequenceId: seqId } = shouldUseSftp
        ? getContentViaSftp()
        : getContentViaFtp();

      setSequenceId(seqId);

      return true;
    } catch (e) {
      setFetching(false);
      setFailedToFetch(true);

      return false;
    }
  }, [currentService, getContentViaFtp, getContentViaSftp, shouldUseSftp]);

  useEffect(() => {
    const requestCancelled =
      contentPath && storedContentPath === contentPath && isRequestCanceled;
    if (requestCancelled) {
      return;
    }

    if (!isNil(currentService)) {
      getContent();
    }
  }, [
    missionId,
    contentPath,
    currentService,
    storedContentPath,
    isRequestCanceled,
    getContent,
  ]);

  const adjustedSetCurrentService = (service: string | undefined) => {
    if (!service) {
      return;
    }
    setCurrentService(service);

    if (isNil(service)) {
      setStoredCurrentService(undefined);
    }

    if (isNil(currentService)) {
      setContentPath('/');
    }
  };

  const cancelRequest = () => {
    setFetching(false);
    setRequestCanceled(true);
    setContentPath(storedContentPath);
    timerId && cancelSentCommand(timerId, sequenceId);
    setTimerId(undefined);
  };

  // Create API here to keep state when switching tabs
  const handleLibraryDeleteItem = (_name: string, path: string | undefined) => {
    const command = find(['name', 'FtpDelete'], availableCommands);

    if (!path) {
      return;
    }

    if (!command) {
      return;
    }

    setRightSideHidden(false);
    dispatch(
      pastCommandFromClipboard(command, {
        satelliteService: currentService,
        path: '/' + removeExcessSlash(path),
      })
    );
  };

  return {
    folderContent,
    isFetching,
    contentPath,
    currentService: storedCurrentService,
    failedToFetch,
    setContentPath,
    getContent,
    cancelRequest,
    deleteContent: handleLibraryDeleteItem,
    setCurrentService: adjustedSetCurrentService,
  };
};
