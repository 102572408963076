import React from 'react';
import type { IconName } from '@blueprintjs/core';
import { Tag } from '@blueprintjs/core';
import type { Intent } from '@blueprintjs/core/src/common/intent';
import { SATELLITE_ID_LIST } from '_api/satellites/types';
import { useMission } from 'services/Missions';

interface IProps {
  icon?: IconName;
  title: string | React.ReactNode;
  className?: string;
  intent?: Intent;
  onClick?: () => void;
  children?: React.ReactNode;
  minimal?: boolean;
  autoAlignTitle?: boolean;
}

const getMissionStyle = (missionId?: number) => {
  if (String(missionId) === SATELLITE_ID_LIST.MENUT) {
    return {
      tagOverride: { backgroundColor: '#ACB4D8' },
      tagClassName: 'darkMissionColor',
    };
  }

  if (String(missionId) === SATELLITE_ID_LIST.MANTIS) {
    return {
      tagOverride: { backgroundColor: '#cdf7f3' },
      tagClassName: 'darkMissionColor',
    };
  }

  if (String(missionId) === SATELLITE_ID_LIST.PLATERO) {
    return {
      tagOverride: { backgroundColor: '#f794b5' },
      tagClassName: 'darkMissionColor',
    };
  }

  if (String(missionId) === SATELLITE_ID_LIST.ALISIO) {
    return {
      tagOverride: { backgroundColor: '#caebbc' },
      tagClassName: 'darkMissionColor',
    };
  }

  if (String(missionId) === SATELLITE_ID_LIST.PHISAT2) {
    return {
      tagOverride: { backgroundColor: '#6e0e21' },
      tagClassName: 'lightMissionColor',
    };
  }

  if (String(missionId) === SATELLITE_ID_LIST.PHISAT2_EM) {
    return {
      tagOverride: { backgroundColor: '#6e0e21' },
      tagClassName: 'lightMissionColor',
    };
  }

  if (String(missionId) === SATELLITE_ID_LIST.COGNISAT_6) {
    return {
      tagOverride: { backgroundColor: '#11085c' },
      tagClassName: 'lightMissionColor',
    };
  }

  if (String(missionId) === SATELLITE_ID_LIST.COGNISAT_6_EM) {
    return {
      tagOverride: { backgroundColor: '#11085c' },
      tagClassName: 'lightMissionColor',
    };
  }

  return {
    tagOverride: {},
    tagClassName: 'noSpecificMission',
  };
};

const TagHeader = ({
  className,
  icon,
  intent,
  onClick,
  minimal,
  title,
  children,
  autoAlignTitle = true,
}: IProps) => {
  const { currentMissionId } = useMission();

  // TODO: Retrieve color per mission from back-end
  const productionMissionStyle = getMissionStyle(currentMissionId);

  return (
    <Tag
      className={[
        className,
        'tagCommon',
        productionMissionStyle.tagClassName,
      ].join(' ')}
      fill
      large
      icon={icon}
      intent={intent}
      onClick={onClick}
      minimal={minimal}
      style={productionMissionStyle.tagOverride}
    >
      <div>
        <span style={autoAlignTitle ? { margin: 'auto' } : undefined}>
          {title}
        </span>
        {children}
      </div>
    </Tag>
  );
};

export default TagHeader;
