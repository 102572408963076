export type SortingOrder = 'desc' | 'asc';

interface ISortingOrder {
  ASCENDING: SortingOrder;
  DESCENDING: SortingOrder;
}

export const SORTING_ORDER: ISortingOrder = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};
