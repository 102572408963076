import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import type { IColorMap, IColorMapColor } from 'datacosmos/types/stac-types';
import { useMemo } from 'react';

export const useColorbarData = (layer: SingleBandSTACLayer) => {
  const properties = layer?.getAsset();

  const colormap = useMemo(
    () => (properties?.['opencosmos:colourmap'] ?? {}) as IColorMap,
    [properties]
  );

  const sortedColormaps = useMemo(
    () => colormap?.colours?.sort((a, b) => a.value - b.value),
    [colormap]
  );

  const pairedColormaps = sortedColormaps
    ? sortedColormaps
        .reduce<IColorMapColor[][]>((acc, cMap, i) => {
          if (acc[i - 1] && acc[i - 1].length < 2) {
            acc[i - 1] = [...acc[i - 1], cMap];
          }

          if (!acc[i] || acc[i]?.length === 0) {
            acc[i] = [cMap];
            return acc;
          }
          return acc;
        }, [])
        .filter((pairs) => pairs.length === 2)
    : [];

  return { pairedColormaps, title: colormap.title, units: colormap.units };
};
