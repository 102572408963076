export const MAP_ID = 'map';
export const MAP_ID_GROUND_STATIONS = 'gs_map';
export const MAP_ID_SIMULATE = 'mapSimulate';

export const MAP_ACTION_ADD = 'add';
export const MAP_ACTION_UPDATE = 'update';
export const MAP_ACTION_REMOVE = 'remove';

export const MAP_TYPE = {
  CONTROLLED: 'controlled',
  SIMULATED: 'simulated',
  GROUND_SEGMENT: 'ground-segment',
  GROUND_STATIONS: 'ground-station',
  MODES: 'modes',
  REAL_TIME: 'real-time',
};

export type IMapTypes = typeof MAP_TYPE.SIMULATED | typeof MAP_TYPE.CONTROLLED;

export const LATITUDE_NAME = 'Latitude';
export const LONGITUDE_NAME = 'Longitude';

export const COORDINATES_SUB = 'COORDINATES_SUB';
