import React from 'react';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import s from './index.module.scss';

interface IProps {
  disabled?: boolean;
  text: string;
  onClick: () => void;
}

const CreateButton = (props: IProps) => (
  <div className={s.createButton}>
    <div>
      <Button
        minimal
        disabled={props.disabled}
        text={props.text}
        rightIcon={IconNames.PLUS}
        onClick={props.onClick}
      />
    </div>
  </div>
);

export default CreateButton;
