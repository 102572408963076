export const GEOMETRY_FILE_NAME = 'geometryState.json';

export const GEOMETRY_ADD = 'GEOMETRY_ADD';
export const GEOMETRY_UPDATE = 'GEOMETRY_UPDATE';
export const GEOMETRY_REMOVE = 'GEOMETRY_REMOVE';
export const GEOMETRY_REMOVE_ALL = 'GEOMETRY_REMOVE_ALL';
export const GEOMETRY_UPLOAD = 'GEOMETRY_UPLOAD';

export const GEOMETRY_SUB_ID = 'GEOMETRY';

export const GEOMETRY_TYPE = {
  KEPLERIAN: 'KEPLERIAN',
  TLE: 'TLE',
  WALKER_CONSTELLATION: 'WALKER_CONSTELLATION',
  LAUNCH_OPPORTUNITIES: 'LAUNCH_OPPORTUNITIES',
  SYNCHRONOUS: 'SYNCHRONOUS',
  STATIONARY: 'STATIONARY',
  POLAR: 'POLAR',
};

export const geometryOptions = {
  KEPLERIAN: {
    value: GEOMETRY_TYPE.KEPLERIAN,
    text: `geometry.${GEOMETRY_TYPE.KEPLERIAN}`,
  },
  TLE: {
    value: GEOMETRY_TYPE.TLE,
    text: `geometry.${GEOMETRY_TYPE.TLE}`,
  },
  WALKER_CONSTELLATION: {
    value: GEOMETRY_TYPE.WALKER_CONSTELLATION,
    text: `geometry.${GEOMETRY_TYPE.WALKER_CONSTELLATION}`,
  },
  SYNCHRONOUS: {
    value: GEOMETRY_TYPE.SYNCHRONOUS,
    text: `geometry.${GEOMETRY_TYPE.SYNCHRONOUS}`,
  },
  STATIONARY: {
    value: GEOMETRY_TYPE.STATIONARY,
    text: `geometry.${GEOMETRY_TYPE.STATIONARY}`,
  },
  POLAR: {
    value: GEOMETRY_TYPE.POLAR,
    text: `geometry.${GEOMETRY_TYPE.POLAR}`,
  },
};

export const DAYS_INPUT = {
  min: 0.01,
  max: 150,
  warn: 30,
  defaultValue: 1,
  stepSize: 0.1,
  minorStepSize: 0.01,
};

export const RESULTS_PATH = 'msd.missionGeometryPage';
