import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';

export type GetFileParams = {
  path: string;
};

export const getStorageFile = apiFetchHandler<Blob, GetFileParams>({
  host: hosts.storage.v0,
  endpoint: ({ path }) => path,
  method: 'GET',
  errorMessage: 'Cannot get storage file',
});
