import type { Polygon } from 'geojson';

/**
 * Domain types
 */

/**
 * Application tags
 */

export enum AppTags {
  maritime = 'maritime',
  agriculture = 'agriculture',
  water_quality = 'water quality',
  feature_detection = 'feature detection',
  measurement = 'measurement',
  environment = 'environment',
  crop = 'crop',
}

export const APP_TAGS_LIST: AppTags[] = [
  AppTags.maritime,
  AppTags.agriculture,
  AppTags.environment,
  AppTags.water_quality,
  AppTags.feature_detection,
];

/**
 * IApplication contains the application information
 */

export interface IApplication {
  // Id is represented by Base64 encoded application name, description and screenshoturl substringed to 75 characters
  id: string;
  name: string;
  description: string;
  tags: AppTags[];
  inputs: IAPIAppInput[];
  values: { [key: string]: IAPIAppValue };
  provider: IAPIAppProvider;
  // A function that returns a react element. It must render the application form.
  renderer: (app: IApplication) => JSX.Element;
  shortDescription?: string;
  price?: string;
  frequencyOfData?: string;
  appScreenshotUrl?: string;
  fee?: string;
  leadTime?: string;
  additionalInfo?: string;
  acronym?: string;
  isTraceableWorkflow?: boolean;
}

/**
 * API Types
 */

/**
 * IAppInputs contains the input field name and an example
 */
export interface IAPIAppInput {
  field: string;
  example: string;
}

/**
 * IAPIAppValue contains a value that has been set for the an input to an app
 */
export interface IAPIAppValue {
  value: Date | string | undefined | Polygon[] | null | unknown;
  isError: boolean;
  message: string;
}

/**
 * IAppProvider contains information that describes the provider of the workflow
 */
export interface IAPIAppProvider {
  id: number;
  description: string;
  name: string;
  url: string;
  icon_url: string;
}

/**
 * IApplication contains information that describes a field and allows for receiver to construct a submission request
 */
export interface IAPIApplication {
  name: string;
  description: string;
  inputs: IAPIAppInput[];
  provider: IAPIAppProvider;
}

/**
 * IAppSubmitWorkflowRequest contains the information required for the user to submit a workflow
 */
export interface IAPIAppSubmitWorkflowRequest {
  name: string;
  inputs: { [key: string]: IAPIAppValue['value'] };
}

/**
 * IApplicationStatus contains the information required for the application status
 */
export interface IApplicationStatus {
  estimated_duration_s?: number;
  finished_at?: string;
  name: string;
  phase?: string;
  progress: number;
}
export interface IApplicationRunStatus {
  name: string;
  job_name: string;
  phase: string;
  progress: string;
  estimated_duration_s: number;
  started_at: string;
  finished_at: string;
  inputs: {
    field: string;
    value: string;
  }[];
  outputs: [];
  failed_steps: [];
}
