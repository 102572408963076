export type EPSG = {
  code: string;
  proj4String: string;
};

export const EPSG4326: EPSG = {
  code: 'EPSG:4326',
  proj4String: '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs',
};

export const EPSG3857: EPSG = {
  code: 'EPSG:3857',
  proj4String:
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs',
};

export const EPSG32630: EPSG = {
  code: 'EPSG:32630',
  proj4String: '+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs',
};

export const EPSG4236: EPSG = {
  code: 'Lat,Long - EPSG:4236',
  proj4String:
    '+proj=longlat +ellps=intl +towgs84=-637,-549,-203,0,0,0,0 +no_defs +type=crs',
};

export const EPSG4248: EPSG = {
  code: 'PSAD56 - EPSG:4248',
  proj4String:
    '+proj=longlat +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +no_defs +type=crs',
};

export const EPSG32718: EPSG = {
  code: 'WGS 84 - UTM 18S - EPSG:32718',
  proj4String:
    '+proj=utm +zone=18 +south +datum=WGS84 +units=m +no_defs +type=crs',
};

export const EPSG32719: EPSG = {
  code: 'WGS 84 - UTM 19S - EPSG:32719',
  proj4String:
    '+proj=utm +zone=19 +south +datum=WGS84 +units=m +no_defs +type=crs',
};

export const coordinateFormats: EPSG[] = [
  EPSG4326,
  EPSG32630,
  EPSG4236,
  EPSG4248,
  EPSG32718,
  EPSG32719,
  EPSG3857,
];
