import type { PermissionScope } from '_api/administration/permissions';
import moment from 'moment';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import useSessionStorage from 'utils/hooks/useSessionStorage';

export type PermissionContextType = ReturnType<
  typeof usePermissionCacheProvider
>;

export const PermissionCacheContext = createContext<PermissionContextType>(
  null as unknown as PermissionContextType
);

export const usePermissionCache = () => useContext(PermissionCacheContext);

type CachedScope = {
  cachedAt: string;
  hasPerm: boolean;
};

type Cache = {
  [userId: string]: {
    [type: string]: {
      [id: string]: {
        [scope: string]: CachedScope;
      };
    };
  };
};

type CacheConfig = {
  [scope in PermissionScope]?: {
    cacheForSeconds: number;
  };
};

// Wipe all non-configured cache every 30 minutes
//TODO: Perhaps change this?
const DEFAULT_CACHE_CLEAR_INTERVAL = 1000 * 60 * 30;

/**
 * This is a cache configuration object. It is used to determine how long a
 * permission should be cached for. If a permission is not in this object, it
 * will be removed from the cache every DEFAULT_CACHE_CLEAR_INTERVAL amount
 * of time.
 *
 * If no cache configuration is provided, all cache will be removed X amount of time
 * specified in DEFAULT_CACHE_CLEAR_INTERVAL.
 */
const CACHE_CONFIG: CacheConfig = {
  //TODO: Example values, to be modified and added accordingly. Intentionally left commented out.
  // 'ops:command': {
  //   cacheForSeconds: 10,
  // },
  // role: {
  //   cacheForSeconds: 10,
  // },
  // data: {
  //   cacheForSeconds: 10,
  // },
  // msd: {
  //   cacheForSeconds: 10,
  // },
  // 'role:read': {
  //   cacheForSeconds: 10,
  // },
  // organisation: {
  //   cacheForSeconds: 10,
  // },
  // user: {
  //   cacheForSeconds: 10,
  // },
};

/**
 * usePermissionCacheProvider is a hook that provides a set of functions to
 * cache and retrieve permissions. It also provides a way to remove cache
 * that has expired.
 */
const usePermissionCacheProvider = () => {
  const [cache, setCache] = useSessionStorage<Cache>('permission_cache', {});

  /**
   * cachePermission is a function that caches a permission for a user.
   * @param userId The user id to cache the permission for.
   * @param perm The permission to cache.
   * @param resHasPerm The result of the permission check.
   */
  const cachePermission = useCallback(
    (
      userId: string,
      perm: Omit<ICheckPermissions, 'routing'>,
      resHasPerm: boolean
    ) => {
      setCache((prev) => {
        const c: Cache = {
          ...prev,
          [userId]: {
            ...(prev[userId] ?? {}),
            [perm.type ?? 'no-type']: {
              ...(prev[userId]?.[perm.type ?? 'no-type'] ?? {}),
              [perm.id ?? 'no-id']: {
                ...(prev[userId]?.[perm.type ?? 'no-type']?.[
                  perm.id ?? 'no-id'
                ] ?? {}),
                [String(perm.actionScope)]: {
                  hasPerm: resHasPerm,
                  cachedAt: new Date().toISOString(),
                },
              },
            },
          },
        };

        return c;
      });
    },
    [setCache]
  );

  /**
   * getIsPermissionCached is a function that checks if a permission is
   * cached for a user.
   * @param perm The permission to check.
   * @param userId The user id to check the permission for.
   */
  const getIsPermissionCached = useCallback(
    (perm: Omit<ICheckPermissions, 'routing'>, userId: string) => {
      if (!cache[userId]) {
        return false;
      }

      const type = perm.type ?? 'global';
      const id = perm.id ?? 'no-id';
      const actionScope = String(perm.actionScope);

      return cache[userId]?.[type]?.[id]?.[actionScope] !== undefined;
    },
    [cache]
  );

  /**
   * retrievePermission is a function that retrieves a permission from the
   * cache for a user.
   * @param perm The permission to retrieve.
   * @param userId The user id to retrieve the permission for.
   */
  const retrievePermission = useCallback(
    (
      perm: Omit<ICheckPermissions, 'routing'>,
      userId: string
    ): boolean | undefined => {
      const type = perm.type ?? 'global';
      const id = perm.id ?? 'no-id';
      const actionScope = String(perm.actionScope);

      return cache[userId]?.[type]?.[id]?.[actionScope].hasPerm;
    },
    [cache]
  );

  /**
   * deletePropertyAtPath is a function that deletes a property from a Cache object
   * at a given path.
   * @param c
   * @param path
   */
  const deletePropertyAtPath = (c: Cache, path: string[]) => {
    path.forEach((p, i) => {
      if (i === path.length - 1) {
        delete c[p];
      } else {
        c = c[p] as unknown as Cache;
      }
    });
  };

  /**
   * removeConfiguredScopeFromCache is a function that removes a configured
   * scope from the cache if it has expired.
   * @param key The key to remove from the cache.
   * @param scope The scope to remove from the cache.
   * @param pathToKey The path to the key in the cache.
   */
  const removeConfiguredScopeFromCache = useCallback(
    (key: string, scope: CachedScope, pathToKey: string) => {
      const configScope = CACHE_CONFIG[key as PermissionScope];

      const cachedAt = moment(scope.cachedAt);
      const cacheUntil = cachedAt.add(configScope?.cacheForSeconds, 'seconds');
      const now = moment();

      if (now.isAfter(cacheUntil)) {
        const path = pathToKey.split('//');
        setCache((prev) => {
          const c = { ...prev };
          deletePropertyAtPath(c, path);
          return c;
        });
      }
    },
    [setCache]
  );

  /**
   * removeUnconfiguredScopeFromCache is a function that removes an unconfigured
   * scope from the cache if it has expired. This function is called when a scope
   * is not in the cache configuration object.
   * @param key The key to remove from the cache.
   * @param pathToKey The path to the key in the cache.
   */
  const removeUnconfiguredScopeFromCache = useCallback(
    (pathToKey: string) => {
      const path = pathToKey.split('//');

      setCache((prev) => {
        const c = { ...prev };
        deletePropertyAtPath(c, path);
        return c;
      });
    },
    [setCache]
  );

  /**
   * removeAllCache is a function that removes all cache.
   * This function is called when there is no cache configuration object.
   * This function is also called when the cache configuration object is empty.
   */
  const removeAllCache = useCallback(() => {
    setCache({});
  }, [setCache]);

  /**
   * removeExpiredCache is a function that removes expired cache according to the
   * expiration time in the cache configuration object.
   * It also removes cache that is not in the cache configuration object according
   * to the default cache clear interval.
   * @param c The cache to remove expired cache from.
   * @param scope The scope to remove from the cache.
   * @param path The path to the cache.
   */
  const removeExpiredCache = useCallback(
    (c: Cache, scope: string, path: string[] = []) => {
      Object.entries(c).forEach(([key, val]) => {
        const isLeaf = Object.hasOwn(val, 'hasPerm');
        if (!isLeaf) {
          removeExpiredCache(
            val as unknown as Cache,
            scope,
            path.concat([key])
          );
        } else if (key.includes(scope)) {
          removeConfiguredScopeFromCache(
            key,
            val as unknown as CachedScope,
            path.concat([key]).join('//')
          );
        } else {
          removeUnconfiguredScopeFromCache(path.concat([key]).join('//'));
        }
      });
    },
    [removeConfiguredScopeFromCache, removeUnconfiguredScopeFromCache]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (Object.keys(CACHE_CONFIG).length > 0) {
        Object.keys(CACHE_CONFIG).forEach((scope) => {
          removeExpiredCache(cache, scope);
        });
      } else {
        removeAllCache();
      }
    }, DEFAULT_CACHE_CLEAR_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [cache, removeAllCache, removeExpiredCache]);

  return {
    cachePermission,
    getIsPermissionCached,
    retrievePermission,
  };
};

type Props = {
  children: React.ReactNode;
};

const PermissionCacheProvider = ({ children }: Props) => {
  return (
    <PermissionCacheContext.Provider value={usePermissionCacheProvider()}>
      {children}
    </PermissionCacheContext.Provider>
  );
};

export default PermissionCacheProvider;
