import type { IContextMenu, IContextMenuSub } from '../map/services';
import { MapTypes } from '../../../../factory/map/mapTypes';
import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../pages/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IAstrum } from '../../../../constants/austrum/interfaces';
import { updateAstrumThunk } from '../../../../pages/shared/state/actions/astrum/thunks';
import { definedMapTypes } from '../../../../factory/map/mapTypes/definedMapType';

export const getMapTypes =
  (
    enablePlanets: string[],
    pageKey?: string
  ): ThunkAction<IContextMenu, AppState, null, Action<string>> =>
  (dispatch): IContextMenu => {
    const definedTypes: IContextMenuSub = {
      text: 'Earth',
      icon: 'geosearch',
      subItems: [],
    };

    Object.keys(definedMapTypes).map((key) => {
      const current = definedMapTypes[key];
      if (enablePlanets.find((e) => e === current.planet)) {
        definedTypes?.subItems?.push({
          text: current.text,
          clickEvent: (): void => {
            const astrum: IAstrum = {
              name: current.name,
              planet: current.planet,
              mapOption: current.mapOption,
            };
            dispatch(updateAstrumThunk(astrum, true, pageKey));
          },
          icon: current.icon,
        });
      }
    });

    const astrumContextMenu: IContextMenu = {
      text: 'Astrum',
      subItems: [],
    };
    if (definedTypes.subItems?.length) {
      astrumContextMenu.subItems.push(definedTypes);
    }

    Object.keys(MapTypes).map((key) => {
      const current = MapTypes[key];
      if (enablePlanets.find((e) => e === current.planet)) {
        astrumContextMenu.subItems.push({
          text: current.text,
          clickEvent: (): void => {
            const astrum: IAstrum = {
              name: current.name,
              planet: current.planet,
              mapOption: current.mapOption,
            };
            dispatch(updateAstrumThunk(astrum, true, pageKey));
          },
          icon: current.icon,
        });
      }
    });

    return astrumContextMenu;
  };
