import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import loading from './loading';
import groundStations from './groundStations';
import pointsOfInterest from './pointsOfInterest';
import regionsOfInterest from './regionsOfInterest';
import popUp from './popUp';
import focus from './focus';
import launchOpportunities from './launchOpportunities';
import constraints from './constraints';
import objectives from './objectives';
import user from './user';
import satelliteBalls from './satelliteBalls';
import groups from './groupSatelliteBalls';
import geometry from './geometry';
import missions from './missions';
import goals from './goals';
import satelliteOrbits from './satelliteOrbits';
import results from './results';
import astrum from './astrum';
import satellite from './satellite';
import moduleData from './moduleData';
import permission from './permission';
import moduleSelector from './ui/moduleSelector';
import platformConfigurator from './ui/platformConfigurator';

const rootReducer = combineReducers({
  loading,
  popUp,
  focus,
  groundStations,
  pointsOfInterest,
  regionsOfInterest,
  launchOpportunities,
  constraints,
  objectives,
  user,
  permission,
  satelliteBalls,
  groups,
  missions,
  geometry,
  goals,
  satelliteOrbits,
  results,
  astrum,
  satellite,
  moduleData,
  moduleSelector,
  platformConfigurator,
  form: formReducer,
});
export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
