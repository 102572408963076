import { Button, Card, Elevation } from '@blueprintjs/core';
import { useAuth } from 'services/auth/AuthWrapper';
import EmptyLayout from 'layouts/EmptyLayout';

export const LoginPage = () => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth();

  return (
    <EmptyLayout>
      <div className="layout-item login-layout no-print">
        <Card elevation={Elevation.TWO}>
          <p>Bee app</p>
          {!isAuthenticated && (
            <Button
              className="bp3"
              text="Log in"
              onClick={() => {
                void loginWithRedirect();
              }}
            />
          )}
          {user && (
            <>
              <div>
                <img className="avatar" src={user.picture} alt="avatar" />
              </div>
              <span>{user.email}</span>
            </>
          )}
        </Card>
      </div>
    </EmptyLayout>
  );
};
