import React from 'react';

interface IProps {
  children: React.ReactNode;
}

/**
 * This is hack for triggering browser native autocomplete logic
 */
const CommandPrimitiveAutocompleteWrapper = (props: IProps) => (
  <>
    <iframe
      hidden
      title="iframe-autofill-browser-hack"
      name="autofill-browser-hack"
      src="about:blank"
    />
    <form target="autofill-browser-hack" action="about:blank">
      {props.children}
      <button hidden type="submit" />
    </form>
  </>
);

export default CommandPrimitiveAutocompleteWrapper;
