/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getPopupContentBase } from './helpers';
import type { ILayerOptions } from './layer';
import { Layer, LayerSourceType } from './layer';

export function GeoJSONLayerFactory<T>(
  sourceType: LayerSourceType,
  name: string,
  data: GeoJSON.GeoJSON,
  metadata: T,
  leafletLayerMetadata: any,
  options?: Partial<ILayerOptions & IGeoJSONLayerOptions>,
  modifiers?: IGeoJSONModifiers
) {
  return new GeoJSONLayer<T>(
    sourceType,
    name,
    data,
    metadata,
    leafletLayerMetadata,
    options,
    modifiers
  );
}

export interface IGeoJSONLayerOptions {
  color: string;
  weight: number;
  fillOpacity: number;
  visible: boolean;
}

export const DefaultGeoJSONLayerOptions = {
  color: '#0000ff',
  weight: 2,
  fillOpacity: 0.2,
  visible: true,
};

export interface IGeoJSONModifiers {
  uneditable?: boolean;
}

export const DefaultGeoJSONModifiers: IGeoJSONModifiers = {
  uneditable: false,
};

export interface IColorChangable {
  setColor(color: string): void;
}

/**
 * GeoJSON Layer is an arbitrary geoJSON shape displayed on the map
 */
export class GeoJSONLayer<T> extends Layer implements IColorChangable {
  declare options: ILayerOptions & IGeoJSONLayerOptions;
  declare layerClass: string;
  name: string;
  data: GeoJSON.GeoJSON;
  leafletLayerMetadata: any;
  metadata: T;
  description: string | undefined;
  modifiers: IGeoJSONModifiers | undefined;

  /**
   * newDataCosmosLayer creates a new datacosoms layer
   */
  constructor(
    sourceType: LayerSourceType,
    name: string,
    data: GeoJSON.GeoJSON,
    metadata: T,
    leafletLayerMetadata: any,
    options?: Partial<ILayerOptions & IGeoJSONLayerOptions>,
    modifiers?: IGeoJSONModifiers
  ) {
    super(sourceType);

    this.name = name;
    this.data = data;
    this.metadata = metadata;
    this.leafletLayerMetadata = leafletLayerMetadata;
    this.options = {
      ...this.options,
      ...DefaultGeoJSONLayerOptions,
      ...options,
    };
    this.modifiers = {
      ...DefaultGeoJSONModifiers,
      ...this.modifiers,
      ...modifiers,
    };
    this.layerClass = 'GeoJSONLayer';
  }

  /**
   * Returns a copy of the current object with the selected options updates.
   */
  cloneWithOptions(options: Partial<ILayerOptions & IGeoJSONLayerOptions>) {
    const newObject = this.clone();
    newObject.options = { ...newObject.options, ...options };
    return newObject;
  }

  cloneWithModifiers(modifiers: IGeoJSONModifiers) {
    const newObject = this.clone();
    newObject.modifiers = { ...newObject.modifiers, ...modifiers };
    return newObject;
  }

  /**
   * Returns a copy of the current object with the new name.
   */
  cloneWithName(name: string) {
    const newObject = this.clone();
    newObject.name = name;
    return newObject;
  }

  cloneWithGeoJSON(data: GeoJSON.GeoJSON) {
    const newObject = this.clone();
    newObject.data = data;
    return newObject;
  }

  /**
   * Returns a copy of the current object with the new metadata.
   */
  cloneWithMetadata(metadata: T) {
    const newObject = this.clone();
    newObject.metadata = metadata;
    return newObject;
  }

  setColor(color: string): void {
    this.options.color = color;
  }

  getName() {
    return this.name || this.id;
  }

  getDescription() {
    if (this.description) {
      return this.description;
    }

    if (this.sourceType === LayerSourceType.TASKING_OPPORTUNITIES) {
      return 'Tasking Opportunity';
    }

    if (this.sourceType === LayerSourceType.TASKING_REGIONS) {
      return 'Region of interest';
    }

    return '';
  }

  getPopupContent() {
    return getPopupContentBase(this.getName(), this.getDescription());
  }

  getLeafletLayerMetadata() {
    return this.leafletLayerMetadata;
  }

  containsSTACItem() {
    return false;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra() {
    return false;
  }

  sza(): Number | undefined {
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }
}
