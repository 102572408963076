import {
  copyCommandToEditor,
  historyCommandClose,
  historyCommandOpen,
  shouldFocusLastHistoryCommand,
  showRightPanel,
} from 'actions/operate/actions';
import { useCallback } from 'react';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { useCurrentTerminalSession } from 'pages/ops/RTI/Operate/context/TerminalSessionProvider';
import type {
  CommandHistoryPayload,
  ICommandHistory,
} from './useCommandHistory';
import { find, get } from 'lodash/fp';

export const useCommandHistoryHandlers = () => {
  const {
    dispatch,
    state: { currentHistoryResponse },
  } = useOperate();

  const { availableCommands } = useCurrentTerminalSession();

  const handleCopyCommandToEditor = useCallback(
    (
      command: string,
      payload: CommandHistoryPayload,
      customName: string | undefined
    ) => {
      const commandToDispatch = find(['name', command], availableCommands);
      if (commandToDispatch) {
        dispatch(copyCommandToEditor(commandToDispatch, payload, customName));
        dispatch(showRightPanel());
      }
    },
    [dispatch, availableCommands]
  );

  const handleToggleOpenHistoryCommand = useCallback(
    (history: ICommandHistory) => {
      if (get('sequenceId', currentHistoryResponse) === history.sequenceId) {
        dispatch(historyCommandClose());
        return;
      }

      const command = find(['name', history.command], availableCommands);

      dispatch(showRightPanel());

      if (command) {
        dispatch(shouldFocusLastHistoryCommand(false));
        dispatch(historyCommandOpen(history, command));
      }
    },
    [availableCommands, currentHistoryResponse, dispatch]
  );

  return {
    handleCopyCommandToEditor,
    handleToggleOpenHistoryCommand,
  };
};
