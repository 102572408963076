import { useState } from 'react';
import {
  Button,
  Classes,
  Dialog,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { DateRange } from '@blueprintjs/datetime';

import type { Pass, PassSlot, Point, Window } from '_api/groundStation/types';
import { useApiGroundStationService } from 'pages/ops/GSScheduling/context/GroundStationProvider';
import type { IMission } from 'services/Missions';
import { useMission } from 'services/Missions';
import {
  toFormattedDateTime,
  toFormattedDateTimeUTC,
} from 'utils/common/dateUtils';
import { SORTING_ORDER } from 'constants/sortAndOrder';
import { copyToClipBoard } from 'utils/common/CommonUtils';
import GSSchedulingTable from './GSSchedulingTable';
import PolarPlot from './PolarPlotChart';
import s from '../../index.module.scss';
import type { Ephemeris } from '_api/ephemeris/types';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import { Tooltip } from 'opencosmos-ui';

interface IProps {
  isFetchingPasses: boolean;
  gsPasses: Pass[];
  cancelGSBookedPass?: (passId: number) => void;
  manualNewPassEvent?: (passId: number) => void;
  getEphemerisById: (ephemerisId: number) => void;
  ephemerisView?: Ephemeris;
  missions: IMission[];
  getGSPassesByParams: Function;
  selectedTab: string;
  pickedDate: DateRange;
  chosenStationIdList: number | number[];
}

const GSTabList = (props: IProps) => {
  const { currentMissionId } = useMission();

  const { passesSortOrder, setPassesSortOrder, gsPasses, chosenStationIdList } =
    useApiGroundStationService();

  const [isViewOpen, setViewOpen] = useState(false);
  const [currentPoints, setCurrentPoints] = useState<Point[]>();
  const [currentWindow, setCurrentWindow] = useState<Window>();
  const [basebandDeployment, setBasebandDeployment] = useState<string>();

  // TODO: Add infinite scrolling once API is updated
  // const [gsPassesLengthStore, setGsPassesLength] = useState<number>();
  // const infiniteScroll = (e: any) => {
  //   const { scrollHeight, scrollTop, clientHeight } = e.target;

  //   const bottom = scrollHeight - scrollTop - clientHeight < 5;
  //   const currentLength = gsPasses.length;

  //   if (bottom && currentLength !== gsPassesLengthStore) {
  //     setGsPassesLength(currentLength);

  //     pipe(
  //       sortBy('bookingStart'),
  //       passes =>
  //         SORTING_ORDER.DESCENDING === passesSortOrder
  //           ? head(passes)
  //           : last(passes),
  //       get('bookingStart'),
  //       getPasses
  //     )(gsPasses);
  //   }
  // };

  const { hasPermission: isAllowedToReadEphemeris } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'portal:ephemeris:read',
      id: currentMissionId,
    },
  });

  const { hasPermission: isAllowedToWritePass } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'portal:gs:pass:write',
      id: currentMissionId,
    },
  });

  if (props.isFetchingPasses || !currentMissionId)
    return (
      <div className={s.tableSpinner}>
        <Spinner />
      </div>
    );

  const handleDeleteClick = (pass: Pass) => {
    if (typeof pass.id === 'undefined') {
      throw new Error('Trying to cancel a pass without an ID');
    }

    if (typeof props.cancelGSBookedPass === 'undefined') {
      throw new Error(
        'Trying to cancel a pass but cancelGSBookedPass prop was not defined'
      );
    }

    props.cancelGSBookedPass(pass.id);
  };

  const handleViewClick = (pass: Pass) => {
    setCurrentPoints(pass.additionalInfo.points);
    setCurrentWindow(pass.window);
    setBasebandDeployment(pass.baseband_deployment);
    props.getEphemerisById(pass.ephemeris);
    setViewOpen(true);
  };

  const handleManualEventClick = (pass: Pass) => {
    if (typeof pass.id === 'undefined') {
      throw new Error('Trying to cancel a pass without an ID');
    }

    if (typeof props.manualNewPassEvent === 'undefined') {
      throw new Error(
        'Trying to cancel a pass but manualNewPassEvent prop was not defined'
      );
    }

    props.manualNewPassEvent(pass.id);
  };

  const handleCopyClick = () => {
    copyToClipBoard(
      `${props.ephemerisView?.line1 ?? ''}\n${props.ephemerisView?.line2 ?? ''}`
    );
  };

  const handleSortOrderClick = () => {
    setPassesSortOrder(
      passesSortOrder === SORTING_ORDER.ASCENDING
        ? SORTING_ORDER.DESCENDING
        : SORTING_ORDER.ASCENDING
    );
  };

  if (chosenStationIdList.length === 0)
    return (
      <div className={s.schedulingListTableContainer}>
        <NonIdealState
          icon={IconNames.CELL_TOWER}
          title="No ground station selected"
          description="You can select one from the top left of the page"
        />
      </div>
    );

  if (gsPasses.length === 0)
    return (
      <div className={s.schedulingListTableContainer}>
        <NonIdealState
          icon={IconNames.CELL_TOWER}
          title="No passes"
          description="Try selecting a different time range above or refreshing"
        />
      </div>
    );

  return (
    <>
      <GSSchedulingTable
        isFetching={props.isFetchingPasses}
        changeSortOrder={handleSortOrderClick}
        tbodyKeyId="passId"
        headerList={[
          {
            display: 'From (UTC)',
          },
          {
            display: 'To (UTC)',
          },
          {
            display: 'Site',
          },
          {
            display: 'Max elevation (°)',
          },
          {
            display: 'Antenna',
          },
          { display: 'Radio links' },
          { display: 'Booked Details' },
        ]}
        tableData={
          gsPasses.map((item) => ({
            startTime: toFormattedDateTimeUTC(item.window.start),
            endTime: toFormattedDateTimeUTC(item.window.end),
            site: item.siteName,
            maxElevation: item.additionalInfo.maxElevation.toFixed(2),
            antenna: item.antennaName,
            radioLinks: item.radio_links
              .map(
                (rl: { frequency: string; direction: string }) =>
                  `${rl.frequency} (${rl.direction})`
              )
              .join(', '),
            infoButton: (
              <div>
                {isAllowedToReadEphemeris ? (
                  <Button
                    text="Details"
                    onClick={() => handleViewClick(item)}
                  />
                ) : (
                  <Tooltip content="Insufficient permissions to view ephemeris">
                    <Button text="View" disabled />
                  </Tooltip>
                )}
              </div>
            ),
            manualButton:
              item.status !== 'CANCELLED' && props.manualNewPassEvent ? (
                <div>
                  <Tooltip content="Trigger Manual New Pass Event">
                    <Button
                      text="Pass Event"
                      onClick={() => handleManualEventClick(item)}
                    />
                  </Tooltip>
                </div>
              ) : null,
            cancelButton:
              item.status === 'CANCELLED' ? (
                <div className={s.tableButtonContainer}>
                  <Button intent={Intent.DANGER} text="Cancelled" disabled />
                </div>
              ) : (
                props.cancelGSBookedPass && (
                  <div className={s.tableButtonContainer}>
                    <Tooltip
                      isDisabled={isAllowedToWritePass}
                      content="You don't have sufficient permissions"
                    >
                      <Button
                        intent={Intent.DANGER}
                        text="Cancel"
                        loading={item.isFetching}
                        onClick={() => handleDeleteClick(item)}
                        disabled={!isAllowedToWritePass}
                      />
                    </Tooltip>
                  </div>
                )
              ),
          })) as unknown as PassSlot[]
        }
        bodyList={[
          'startTime',
          'endTime',
          'site',
          'maxElevation',
          'antenna',
          'radioLinks',
          'infoButton',
          'manualButton',
          'cancelButton',
        ]}
      />
      <Dialog
        style={{
          width: 700,
          maxHeight: 750,
          position: 'relative',
          overflow: 'hidden',
        }}
        title="Pass Info"
        isOpen={isViewOpen}
        usePortal={true}
        canOutsideClickClose={true}
        onClose={() => setViewOpen(false)}
      >
        <div
          className={`${Classes.DIALOG_BODY} ${s.ephemerisHistoryDialogBody}`}
        >
          {currentPoints && currentWindow && (
            <PolarPlot points={currentPoints} window={currentWindow} />
          )}
          <div>
            <div className="font-bold flex">
              <span className="flex-1">Ephemeris</span>
              <Button
                icon={IconNames.CLIPBOARD}
                text="Copy"
                onClick={handleCopyClick}
                minimal={true}
                small={true}
              />
            </div>
            <div className="p-4 font-mono bg-slate-200">
              {props.ephemerisView?.line1}
              <br />
              {props.ephemerisView?.line2}
            </div>
            <div className="font-light text-sm text-end">
              Created at{' '}
              {props.ephemerisView &&
                toFormattedDateTime(props.ephemerisView.creationTime)}
            </div>
            <div className="font-bold flex mt-4">
              <span className="flex-1">Baseband deployment</span>
            </div>
            <div className="p-2">{basebandDeployment}</div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default GSTabList;
