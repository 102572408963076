import { useSortable } from "@dnd-kit/sortable";
import { flexRender, Header } from "@tanstack/react-table";
import { CSSProperties } from "react";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import Icon from "../../icons/Icon";

type Props<T> = {
  header: Header<T, unknown>;
  isReorderable: boolean | undefined;
  isSticky?: boolean;
  isMinimal?: boolean;
  alignHeader?: "left" | "center" | "right";
};

const DraggableHeader = <T,>({
  header,
  isReorderable,
  isMinimal,
  isSticky,
  alignHeader,
}: Props<T>) => {
  const renderSortingChevron = (header: Header<T, unknown>) => {
    if (!header.column.getCanSort()) {
      return null;
    }

    if (!header.column.getIsSorted()) {
      return null;
    }

    return (
      <Icon
        icon={
          header.column.getIsSorted() === "asc" ? "ChevronUp" : "ChevronDown"
        }
      />
    );
  };

  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
  };

  return (
    <th
      ref={setNodeRef}
      style={style}
      {...{
        key: header.id,
        colSpan: header.colSpan,
      }}
      className={classNames(
        "py-[2px] px-1 text-center h-8 z-10 bg-header dark:bg-header-dark m-0",
        {
          "sticky -top-[1px]": isSticky,
          relative: !isSticky,
          "border border-item-contrast-inactive": !isMinimal,
        }
      )}
    >
      <div
        className={classNames("flex items-center w-full", {
          "justify-start": alignHeader === "left",
          "justify-center": alignHeader === "center",
          "justify-end": alignHeader === "right",
        })}
        onClick={header.column.getToggleSortingHandler()}
      >
        {isReorderable && (
          <button {...attributes} {...listeners} className="flex items-center">
            <Icon icon="drag-handle-vertical" size={16} />
          </button>
        )}
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
        {renderSortingChevron(header)}
      </div>
    </th>
  );
};

export default DraggableHeader;
