import React from 'react';
import { Button } from '@blueprintjs/core';
import s from './index.module.scss';
import type { OrderByType } from './index';
import classNames from 'classnames';

interface IProps {
  rowNames: string[];
  sortedBy?: string;
  orderBy?: OrderByType;
  handleSortByChange: (name: string) => void;
  getIsColumnHidden?: (columnName: string) => boolean;
  toggleColumnHiddenStatus?: (columnName: string) => void;
}

const FolderHeader = (props: IProps) => {
  const handleHeaderContextClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const arrowDirection =
    props.orderBy === 'asc' ? 'chevron-down' : 'chevron-up';

  return (
    <div className={s.folderHeader}>
      {props.rowNames.map((name, index) => (
        <div
          className={classNames('flex items-center w-full', {
            [s.folderHeaderInfo]: index !== 0,
          })}
          key={name}
        >
          <Button
            style={{
              justifyContent: index === 0 ? 'start' : 'center',
              color: props.getIsColumnHidden?.(name) ? 'lightslategray' : '',
            }}
            minimal
            rightIcon={props.sortedBy === name ? arrowDirection : null}
            onContextMenu={handleHeaderContextClick}
            onClick={() => props.handleSortByChange(name)}
            text={name}
          />
          <Button
            icon={props.getIsColumnHidden?.(name) ? 'eye-off' : 'eye-open'}
            minimal
            onClick={() => {
              props.toggleColumnHiddenStatus?.(name);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default FolderHeader;
