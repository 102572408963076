import { Tag, UL } from '@blueprintjs/core';
import type { IMission } from 'services/Missions';
import { useMission } from 'services/Missions';
import OrganisationEditableText from 'components/OrganisationConfiguration/OrganisationEditableText';
import s from 'components/OrganisationConfiguration/index.module.scss';

interface IProps {
  missions: IMission[];
  showNewMission: boolean;
  setShowNewMission: (value: number | undefined) => void;
  saveMission: (name: string) => void;
  onMissionClick?: () => void;
}

const MissionList = (props: IProps) => {
  const { getMissionById, currentMissionId, setCurrentMission } = useMission();

  const handleChooseMission = (missionId: number) => {
    const _missionId = getMissionById(missionId);
    if (_missionId !== undefined) {
      setCurrentMission(_missionId, true);
      props.onMissionClick?.();
    }
  };

  return (
    <div>
      <UL className={s.missionListContainer}>
        <OrganisationEditableText
          text="New Mission"
          showEditableText={props.showNewMission}
          hideEditableText={() => props.setShowNewMission(undefined)}
          handleSaveConfirm={(name: string) => props.saveMission(name)}
        />
        {props.missions.map((item) => (
          <li
            key={item.mission}
            className={
              currentMissionId === item.mission
                ? s.highLightChosenItem
                : undefined
            }
          >
            <div
              className={s.missionContainer}
              role="button"
              tabIndex={0}
              onKeyDown={() => handleChooseMission(item.mission)}
              onClick={() => handleChooseMission(item.mission)}
            >
              {item.name}
              <div>
                <Tag>{item.mission}</Tag>
              </div>
            </div>
          </li>
        ))}
      </UL>
    </div>
  );
};

export default MissionList;
