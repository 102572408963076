import { Suspense, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider } from 'services/auth/AuthProvider';
import { FocusStyleManager } from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import { App } from 'App';
import i18n from 'i18n/client';
import { MissionsProvider } from 'services/Missions';
import 'styles/index.scss?global';
import { FlagsProvider } from 'react-feature-flags';
import { history, ENABLE_STRICT_MODE, store } from 'config';
import { FLAGS } from 'flags';
import ThemeProvider from 'datacosmos/stores/ThemeProvider';
import PermissionCacheProvider from 'utils/context/PermissionCacheProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import OpenappNavProvider from 'pages/shared/context/OpenappNavProvider';

// Remove focus when navigation with mouse
FocusStyleManager.onlyShowFocusOnTabs();

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
});

const AppWithContext = () => (
  <Sentry.ErrorBoundary showDialog>
    <FlagsProvider value={FLAGS}>
      <Router history={history}>
        <Suspense fallback={'Loading...'}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <I18nextProvider i18n={i18n}>
                <PermissionCacheProvider>
                  <AuthProvider>
                    <MissionsProvider>
                      <ThemeProvider>
                        <OpenappNavProvider>
                          <App />
                        </OpenappNavProvider>
                      </ThemeProvider>
                    </MissionsProvider>
                  </AuthProvider>
                </PermissionCacheProvider>
              </I18nextProvider>
            </Provider>
          </QueryClientProvider>
        </Suspense>
      </Router>
    </FlagsProvider>
  </Sentry.ErrorBoundary>
);

createRoot(document.getElementById('root')!).render(
  ENABLE_STRICT_MODE ? (
    <StrictMode>
      <AppWithContext />
    </StrictMode>
  ) : (
    <AppWithContext />
  )
);
