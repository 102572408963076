import { pointOfInterestDefine } from '../pointOfInterest/options';
import { regionOfInterestDefine } from '../regionOfInterest/options';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const drawingControllerManager = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  google?: any
): google.maps.drawing.DrawingManagerOptions => {
  return {
    drawingControl: false,
    drawingControlOptions: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      position: google.maps.ControlPosition.TOP_LEFT,
      drawingModes: [
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        google.maps.drawing.OverlayType.MARKER,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        google.maps.drawing.OverlayType.POLYGON,
      ],
    },
    markerOptions: pointOfInterestDefine(),
    polygonOptions: regionOfInterestDefine(),
  };
};
