import type { IContextMenu } from '../map/services';

export const getFullScreen = (): IContextMenu => {
  return {
    text: 'Fullscreen',
    subItems: [
      {
        text: 'Toggle',
        clickEvent: (): void => {
          const container: Element =
            document.getElementsByClassName('flex-container')[0];
          container.classList.toggle('full-screen-mod');
        },
        icon: 'fullscreen',
      },
    ],
  };
};
