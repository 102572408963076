import type { CSSProperties, ReactElement, MouseEventHandler } from 'react';
import type { ISatelliteBall } from '../../constants/satelliteBalls/actionTypes';
import { BALL_STATES } from '../../constants/satelliteBalls/constants';
import { MODULE_STATE_STYLE } from './utils';

interface ISatelliteBallProps extends ISatelliteBall {
  handleChangeState: Function;
  ballsState: ISatelliteBall[];
  small?: boolean;
}

const ModuleBall = (props: ISatelliteBallProps): ReactElement => {
  const { position, size, state, text, handleChangeState } = props;

  const style: CSSProperties = {
    position: 'absolute',
    top: position.top,
    left: position.left,
    //TODO enable/disable animation
    // animation: `${text.replace(/\s/g, '_')} ${15}s linear alternate infinite`,
  };

  const labelStyle: CSSProperties = {
    top: size.height / 2 - 20,
  };

  let onClick: MouseEventHandler<HTMLElement> | null = null;
  switch (state) {
    case BALL_STATES.CURRENT: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      onClick = () => handleChangeState(text);
      break;
    }
    case BALL_STATES.COMPLETE: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      onClick = () => handleChangeState(text);
      break;
    }
    case BALL_STATES.WARNING: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      onClick = () => handleChangeState(text);
      break;
    }
    default: {
      //
    }
  }

  const comingSoon = props.comingSoon ? 'satellite-ball-coming-soon' : '';

  const moduleStateStyle = MODULE_STATE_STYLE[state];
  const moduleBallAndTextStyle =
    state !== BALL_STATES.DISABLE ? 'module-ball-and-text' : '';

  return (
    <div style={style} className="module-ball">
      <div className={moduleBallAndTextStyle}>
        {
          // line 66-93 to be deleted after initial release
          props.comingSoon ? (
            <>
              <a
                href="mailto: customer-engagement@open-cosmos.com"
                className="get-in-touch-href"
              >
                {props.comingSoon && (
                  <div className="coming-soon">Get in touch</div>
                )}
                <div
                  className={`satellite-ball satellite-ball-big satellite-ball-get-in-touch ${moduleStateStyle}`}
                  role="link"
                  tabIndex={0}
                  onKeyDown={
                    //@ts-expect-error
                    (ev) => ev.keyCode === 13 && onClick?.(null)
                  }
                >
                  <img src={props.icon} alt={props.alt} />
                </div>
                <div style={labelStyle} className="label">
                  {text}
                </div>
              </a>
            </>
          ) : (
            <>
              {props.comingSoon && (
                <div className="coming-soon">Coming soon</div>
              )}
              <div
                className={`satellite-ball satellite-ball-big ${comingSoon} ${MODULE_STATE_STYLE[state]}`}
                //@ts-expect-error
                onClick={onClick}
                role="link"
                tabIndex={0}
                //@ts-expect-error
                onKeyDown={(ev) => ev.keyCode === 13 && onClick(null)}
              >
                <img src={props.icon} alt={props.alt} />
              </div>
              <div style={labelStyle} className="label">
                {text}
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ModuleBall;
