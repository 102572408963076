import area from '@turf/area';
import Button from '_molecules/Button/Button';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import type { Polygon } from 'geojson';
import { useCallback, useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import bbox from '@turf/bbox';
import DebouncedInput from '_molecules/DebouncedInput/DebouncedInput';
import { coordinateStringToGeoJson } from 'datacosmos/utils/coordinates/coordinatesToGeoJson';
import Select2 from '_molecules/Select2/Select2';
import { Item } from 'react-stately';
import type { EPSG } from 'datacosmos/utils/coordinates/epsgCoordinates';
import {
  EPSG4326,
  coordinateFormats,
} from 'datacosmos/utils/coordinates/epsgCoordinates';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { isGeodetic } from 'datacosmos/utils/coordinates/coordinateHelpers';

type Props = {
  setAreaOfInterest: Dispatch<SetStateAction<Polygon[] | undefined>>;
};

const toDMSString = (parts: string[]) => {
  return `${parts[0].replace('°', '')}° ${parts[1].replace(
    "'",
    ''
  )}' ${parts[2].replace('"', '')}"`;
};

const SearchByPoint = ({ setAreaOfInterest }: Props) => {
  const [isCoordinateBoxOpen, setCoordinateBoxOpen] = useState<boolean>(false);
  const { removeLayersBySourceType, addLayer } = useMapLayers();
  const { setViewToFitBbox } = useMap();

  const [selectedEPSG, setSelectedEPSG] = useState<EPSG>(EPSG4326);

  const [coordinateInput, setCoordinateInput] = useState<string>();
  const [latitudeInput, setLatitudeInput] = useState<string>('');
  const [longitudeInput, setLongitudeInput] = useState<string>('');
  const [inputErrorMsg, setInputErrorMsg] = useState<string>('');

  const { translate } = useLocalisation();

  const handleCoordinateInput = useCallback(() => {
    setInputErrorMsg('');
    if (!coordinateInput) {
      return;
    }

    if (!latitudeInput.length || !longitudeInput.length) {
      return;
    }

    let parsedCoords = coordinateInput;
    const latParts = latitudeInput.split(' ').filter((x) => x);
    const lngParts = longitudeInput.split(' ').filter((x) => x);
    if (latParts.length !== lngParts.length) {
      setInputErrorMsg(
        translate('datacosmos.filters.searchByPoint.errors.mismatchedFormat')
      );
      return;
    }
    if (
      latParts.length === 3 &&
      lngParts.length === 3 &&
      isGeodetic(selectedEPSG)
    ) {
      // values in 'DD MM SS' format
      parsedCoords = `${toDMSString(lngParts)}, ${toDMSString(latParts)}`;
    }

    let geo = null;
    try {
      geo = coordinateStringToGeoJson(parsedCoords, selectedEPSG);
    } catch (error) {
      setInputErrorMsg((error as { message: string }).message);
    }

    if (!geo) {
      return;
    }

    removeLayersBySourceType(LayerSourceType.AREA_OF_INTEREST);

    addLayer(
      PolygonLayerFactory(
        LayerSourceType.AREA_OF_INTEREST,
        translate('datacosmos.layers.names.aoi'),
        geo,
        area(geo),
        undefined
      )
    );

    const polyBbox = bbox(geo);

    setViewToFitBbox(polyBbox);
    setAreaOfInterest([geo.geometry]);
  }, [
    addLayer,
    coordinateInput,
    removeLayersBySourceType,
    selectedEPSG,
    setAreaOfInterest,
    setViewToFitBbox,
    latitudeInput,
    longitudeInput,
    translate,
  ]);

  /**
   * We're handling coordinate input inside useEffect to enable search to be run again
   * when selecting a different coordinate system from the dropdown menu.
   */
  useEffect(() => {
    handleCoordinateInput();
  }, [handleCoordinateInput]);

  let xLabel = 'X';
  let yLabel = 'Y';
  let xPlaceholder = '-2.745344';
  let yPlaceholder = '26.831494';

  if (!isGeodetic(selectedEPSG)) {
    xLabel = translate('datacosmos.filters.searchByPoint.easting');
    yLabel = translate('datacosmos.filters.searchByPoint.northing');
    xPlaceholder = translate(
      'datacosmos.filters.searchByPoint.placeholder.projected.x'
    );
    yPlaceholder = translate(
      'datacosmos.filters.searchByPoint.placeholder.projected.y'
    );
  }

  if (isGeodetic(selectedEPSG)) {
    xLabel = translate('datacosmos.filters.searchByPoint.longitude');
    yLabel = translate('datacosmos.filters.searchByPoint.latitude');
    xPlaceholder = translate(
      'datacosmos.filters.searchByPoint.placeholder.geodetic.x'
    );
    yPlaceholder = translate(
      'datacosmos.filters.searchByPoint.placeholder.geodetic.y'
    );
  }

  return (
    <>
      {!isCoordinateBoxOpen && (
        <Button
          text={translate('datacosmos.buttons.searchByPoint')}
          icon="Pin"
          className="color-item h-8 w-full text-start border-2 border-item"
          onPress={() => setCoordinateBoxOpen(true)}
        />
      )}
      {isCoordinateBoxOpen && (
        <div className="flex flex-col gap-2">
          <Select2
            items={coordinateFormats}
            selectedKey={selectedEPSG.code}
            onSelectionChange={(key) => {
              const epsg = coordinateFormats.find((c) => c.code === key);
              if (!epsg) {
                return;
              }
              setSelectedEPSG(epsg);
            }}
            label={translate(
              'datacosmos.filters.searchByPoint.coordinateSystem'
            )}
          >
            {(items) => <Item key={items.code}>{items.code}</Item>}
          </Select2>

          <DebouncedInput
            debounceTimeMs={500}
            type="text"
            label={{
              position: 'top',
              text: xLabel,
            }}
            placeholder={xPlaceholder}
            onChange={(e) => {
              setLongitudeInput(e.target.value);
              setCoordinateInput(`${e.target.value},${latitudeInput}`);
            }}
          />

          <DebouncedInput
            debounceTimeMs={500}
            type="text"
            label={{
              position: 'top',
              text: yLabel,
            }}
            placeholder={yPlaceholder}
            onChange={(e) => {
              setLatitudeInput(e.target.value);
              setCoordinateInput(`${longitudeInput},${e.target.value}`);
            }}
            errorMsg={inputErrorMsg}
          />

          <div className="flex items-center">
            <Button
              text={translate('datacosmos.buttons.delete')}
              onPress={() => {
                setCoordinateBoxOpen(false);
                removeLayersBySourceType(LayerSourceType.AREA_OF_INTEREST);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SearchByPoint;
