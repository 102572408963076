import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import React from 'react';
import type { Polygon } from 'geojson';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import area from '@turf/area';
import { default as CustomBtn } from '_molecules/Button/Button';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface Props {
  aoiSourceType: LayerSourceType;
  setAreasOfInterest: React.Dispatch<
    React.SetStateAction<Polygon[] | undefined>
  >;
  buttonForApplications?: true;
  multipleAois?: true;
  onPressExtraAction?: () => void;
  aoiName?: string;
  disabled?: boolean;
  onDrawFinish?: () => void;
}

const DrawAreaOfInterest = ({
  buttonForApplications,
  setAreasOfInterest,
  aoiSourceType,
  multipleAois,
  onPressExtraAction,
  aoiName,
  disabled,
  onDrawFinish,
}: Props) => {
  const { drawPolygon } = useMap();
  const { removeLayersBySourceType, addLayer } = useMapLayers();
  const { translate } = useLocalisation();

  const getAoiName = () => {
    if (aoiName) {
      return aoiName;
    }

    if (aoiSourceType === LayerSourceType.APPLICATION_AOI) {
      return translate('datacosmos.layers.names.applicationAoi');
    }

    return translate('datacosmos.layers.names.aoi');
  };

  const handleAoiDraw = async () => {
    const { polygon, polygonMetadata } = await drawPolygon();
    !multipleAois && removeLayersBySourceType(aoiSourceType);

    const m2 = area(polygon);

    addLayer(
      PolygonLayerFactory(
        aoiSourceType,
        getAoiName(),
        polygon,
        m2,
        polygonMetadata
      )
    );

    multipleAois
      ? setAreasOfInterest((prev) => [...(prev ?? []), polygon.geometry])
      : setAreasOfInterest([polygon.geometry]);

    if (onDrawFinish) onDrawFinish();
  };

  if (buttonForApplications) {
    return (
      <CustomBtn
        text={translate('datacosmos.buttons.draw')}
        icon="AoiAdd"
        size={24}
        className="color-item h-8 w-full text-start border-2 border-item"
        onPress={() => {
          void handleAoiDraw();
          onPressExtraAction?.();
        }}
        disabled={disabled}
      />
    );
  }
  return (
    <CustomBtn
      text={translate('datacosmos.filters.drawAoi')}
      icon="AoiAdd"
      size={24}
      className="color-item h-8 w-full text-start border-2 border-item"
      onPress={() => {
        void handleAoiDraw();
        onPressExtraAction?.();
      }}
      disabled={disabled}
    />
  );
};

export default DrawAreaOfInterest;
