import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash/fp';
import { Button, ControlGroup, Intent } from '@blueprintjs/core';
import type { DateRange } from '@blueprintjs/datetime';
import { DateRangeInput } from '@blueprintjs/datetime';

import { toFormattedDateTime } from 'utils/common/dateUtils';
import { DATE_CUSTOM_SHORTCUTS } from 'constants/ops/gs_scheduling/constants';

import s from '../../index.module.scss';
import { DATETIME_FORMAT } from 'constants/datetime';

interface IProps {
  stationIdList: number[];
  pickedDate: DateRange;
  setPickedDate: (value: DateRange) => void;
  handleSearchClick: () => void;
  toggleMapVisibility: () => void;
  isGsMapVisible: boolean;
}

const GSSearchBar = (props: IProps) => (
  <div className={s.searchBarContainer}>
    <ControlGroup>
      <Button
        icon={props.isGsMapVisible ? 'list' : 'map'}
        intent={Intent.NONE}
        onClick={props.toggleMapVisibility}
        text={props.isGsMapVisible ? 'Hide map' : 'Show map'}
      />

      <DateRangeInput
        className={s.dateRageInputSearch}
        shortcuts={DATE_CUSTOM_SHORTCUTS}
        disabled={isEmpty(props.stationIdList)}
        formatDate={toFormattedDateTime}
        parseDate={(str) => moment(str, DATETIME_FORMAT).toDate()}
        endInputProps={{ readOnly: true }}
        startInputProps={{ readOnly: true }}
        timePrecision="second"
        value={props.pickedDate}
        onChange={(value) => {
          props.setPickedDate(value);
        }}
        minDate={
          new Date(
            moment()
              .subtract(1, 'year')
              .toDate()
              .toLocaleString('en-US', { timeZone: 'Etc/UTC' })
          )
        }
        maxDate={
          new Date(
            moment()
              .add(1, 'year')
              .toDate()
              .toLocaleString('en-US', { timeZone: 'Etc/UTC' })
          )
        }
      />
      <Button
        intent={Intent.NONE}
        disabled={isEmpty(props.stationIdList)}
        text="Refresh"
        onClick={props.handleSearchClick}
      />
    </ControlGroup>
  </div>
);

export default GSSearchBar;
