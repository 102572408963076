import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import roleApi from 'services/api/portal/administration/api/roleApi';
import type { PermissionType } from '_api/administration/permissions';
import type {
  ICreateRoleAttachment,
  IRole,
  IUpdateRole,
} from 'services/api/portal/administration/api/types';
import { BACKEND_BASE_URL } from 'env';
import roleAttachmentApi from '../api/roleAttachmentApi';

const PORTAL_URL = `${BACKEND_BASE_URL}/portal/v0`;

const useRoles = () => {
  const [availableRoles, setAvailableRoles] = useState<IRole[]>([]);

  const { token } = useAuth();

  const roleInstance = useMemo(() => roleApi(PORTAL_URL, token), [token]);
  const roleAttachmentInstance = token && roleAttachmentApi(PORTAL_URL, token);

  const getAllRoles = useCallback(async () => {
    setAvailableRoles(await roleInstance.getRoles());
  }, [roleInstance]);
  const getRoleAttachmentsByRoleId = async (roleID: string) => {
    if (roleAttachmentInstance) {
      return await roleAttachmentInstance.getRoleAttachments(roleID);
    }
    return [];
  };

  const createRole = async (role: IUpdateRole) => {
    await roleInstance.createRole(role);
    void getAllRoles();
  };

  const createRoleAttachment = async (
    role: ICreateRoleAttachment,
    parentRoleID: string
  ) => {
    if (roleAttachmentInstance) {
      await roleAttachmentInstance.createRoleAttachment(role, parentRoleID);
    }
  };

  const deleteRoleAttachment = async (
    attachmentId: number,
    parentRoleId: number
  ) => {
    if (roleAttachmentInstance) {
      await roleAttachmentInstance.deleteRoleAttachment(
        attachmentId,
        parentRoleId
      );
    }
  };

  const updateRole = async (role: IUpdateRole) => {
    await roleInstance.updateRole(role);
    void getAllRoles();
  };

  useEffect(() => {
    void getAllRoles();
  }, [getAllRoles]);

  return {
    availableRoles: availableRoles,
    availableRolesByType: (type: PermissionType) =>
      availableRoles.filter((role) => role.resourceType === type),
    updateRole,
    createRole,
    getRoleAttachmentsByRoleId,
    createRoleAttachment,
    deleteRoleAttachment,
  };
};

export default useRoles;
