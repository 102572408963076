import axios from 'axios';
import { showErrorMessage, showSuccessMessage } from 'utils/common/CommonUtils';
import { REQUEST_ERROR } from 'constants/errors';

type ResponseError = {
  response: {
    status?: number;
    data?: { errors?: { message?: string }[] };
  };
};

export default (url: string, missionId: number, token: string | undefined) => {
  const checkPodIsRunning = async (): Promise<boolean> => {
    try {
      await axios.get(`${url}/mission/${missionId}`, {
        params: {},
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      });
      return true;
    } catch (error) {
      const e = error as ResponseError;
      if (e?.response?.status === 404) {
        return false;
      }
      //@ts-expect-error
      showErrorMessage(e?.response?.data?.errors[0]?.message ?? REQUEST_ERROR);
      throw error;
    }
  };

  const createNewInstance = async (): Promise<string> => {
    try {
      const {
        data: { data },
      } = (await axios.put(
        `${url}/mission/${missionId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token ?? ''}`,
            'Content-Type': 'application/json',
          },
        }
      )) as { data: { data: string } };
      //@ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      showSuccessMessage(data.data.data ?? 'Successfully created an instance');
      return data;
    } catch (error) {
      const e = error as ResponseError;
      showErrorMessage(
        e?.response?.data?.errors?.[0]?.message ?? REQUEST_ERROR
      );
      throw error;
    }
  };

  const deleteAnInstance = async (): Promise<string> => {
    try {
      const {
        data: { data },
      } = (await axios.delete(`${url}/mission/${missionId}`, {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      })) as { data: { data: string } };
      //@ts-expect-error

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      showSuccessMessage(data.data.data ?? 'Successfully deleted an instance');
      return data;
    } catch (error) {
      const e = error as ResponseError;
      showErrorMessage(
        e?.response?.data?.errors?.[0]?.message ?? REQUEST_ERROR
      );
      throw error;
    }
  };

  return {
    checkPodIsRunning,
    createNewInstance,
    deleteAnInstance,
  };
};
