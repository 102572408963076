export const SORTED_LIST_MAPPING = {
  name: 'Name',
  size: 'Size',
  lastModified: 'Last Modified',
};

export const IMAGE_EXTENSIONS = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  bmp: 'image/bmp',
};

export const FTP_SATELLITE_SERVICES = [
  {
    name: 'piu_main',
    displayedName: 'Ftp - Piu Main',
    transferName: 'piu_main_backdoor',
  },
  {
    name: 'piu_main_backdoor',
    displayedName: 'Ftp - Piu Main Backdoor',
    transferName: 'piu_main_backdoor',
  },
  {
    name: 'piu_redundant',
    displayedName: 'Ftp - Piu Redundant',
    transferName: 'piu_redundant_backdoor',
  },
  {
    name: 'piu_redundant_backdoor',
    displayedName: 'Ftp - Piu Redundant Backdoor',
    transferName: 'piu_redundant_backdoor',
  },
];

export const SFTP_SATELLITE_SERVICES = [
  {
    name: 'obc',
    displayedName: 'SFTP - OBC',
    transferName: 'obc',
  },
  {
    name: 'payload',
    displayedName: 'SFTP - Payload',
    transferName: 'payload',
  },
  {
    name: 'ksat',
    displayedName: 'SFTP - KSAT',
    transferName: 'ksat',
  },
];

export const AVAILABLE_CLOUD_STORAGE = [
  {
    name: 'mission',
    displayedName: 'Mission',
  },
  {
    name: 'programme',
    displayedName: 'Programme',
  },
];

export enum EntryType {
  FILE = 'file',
  FOLDER = 'folder',
}
