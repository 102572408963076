import React from 'react';

import { ListBoxItem, Select } from 'opencosmos-ui';

type Props = {
  items: string[];
  onSelectionChange?: ((key: string) => void) | undefined;
  selectedKey: string;
  selectedItemProps?: Record<string, unknown>;
};

const SelectCell = ({
  items,
  selectedKey,
  onSelectionChange,
  selectedItemProps,
}: Props) => {
  return (
    <Select
      fill
      selectedItemProps={selectedItemProps}
      selectedKey={selectedKey}
      onSelectionChange={(key) => {
        onSelectionChange?.(key.toString());
      }}
    >
      {items.map((item) => (
        <ListBoxItem key={item} id={item}>
          {item}
        </ListBoxItem>
      ))}
    </Select>
  );
};

export default SelectCell;
