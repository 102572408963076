import { postAlertGroup, getAlertGroup, getAlertGroupIds } from './service';

export const createAlertGroupForMission = async (
  missionId: number | string | undefined
) => {
  if (!missionId) {
    return;
  }
  await postAlertGroup({
    body: {
      concurrency: 1,
      file: `/config/alert.rules.mission-${missionId}.yml`,
      interval: '1m0s',
      name: 'Mission ' + missionId,
      recording_rules: null,
      rules: [],
    },
  });
};

export const getAlertsThroughMission = async (
  missionId: number | string | undefined
) => {
  if (!missionId) {
    return;
  }
  const { data: groupIds } = await getAlertGroupIds({});
  const missionIdString = `mission-${missionId}`;
  const alertGroupForMission = groupIds?.find(
    (alertGroupId) =>
      alertGroupId.file.substr(20, missionIdString.length) === missionIdString
  );

  const { data: group } = await getAlertGroup({
    params: { id: alertGroupForMission?.id },
  });

  return group;
};
