import type { ISVGPoint } from '../../constants/missionDesignPage/interfaces';

//https://codepen.io/enxaneta/pen/fba7584b3eab0453b3de02823a5a98a7?editors=0010
export function lerp(A: ISVGPoint, B: ISVGPoint, t: number): ISVGPoint {
  // a virtual line from A to B
  // get the position of a point on a line
  // if(t == .5) the point in inthe center of the line
  return {
    x: (B.x - A.x) * t + A.x, //x
    y: (B.y - A.y) * t + A.y, //y
  };
}
