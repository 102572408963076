import { toaster } from 'toaster';

type WebsocketClientHandlerParms<Params> = {
  host: string;
  endpoint: string | ((params: Params) => string);
  errorMessage: string;
  onConnect?: () => void;
  onDisconnect?: () => void;
  onError?: (error: Event) => void;
};

export type WebSocketClientPayloadType =
  | string
  | boolean
  | number
  | { [key: string]: string | boolean | number | unknown };

export type WebSocketHandler = {
  close: () => void;
  send: (data: WebSocketClientPayloadType) => void;
  isConnected: boolean;
  socket: WebSocket | undefined;
};

export function websocketHandler(options: {
  host: string;
  endpoint: string;
}): WebSocketHandler {
  const socket = new WebSocket(`${options.host}${options.endpoint}`);
  return {
    close: () => socket.close(),
    send: (data: WebSocketClientPayloadType) =>
      socket.send(JSON.stringify(data)),
    isConnected: socket.readyState === WebSocket.OPEN,
    socket,
  };
}

/**
 * websocketClient is a generic function that returns a promise that resolves
 * to a WebSocketClientReturn object. This object contains methods to send data,
 * close the connection and the current connection state.
 * @param host the base url of the websocket server
 * @param endpoint the endpoint of the websocket server
 * @param errorMessage the message to show when the connection fails
 * @param onDisconnect optional callback function that is called when the
 * connection is closed
 * @param onError optional callback function that is called when the connection
 * fails
 * @param onConnect optional callback function that is called when the
 * connection is established
 */
export function websocketClient<
  Data extends {},
  Params extends {} | undefined = {}
>({
  host,
  endpoint,
  errorMessage,
  onDisconnect,
  onError,
  onConnect,
}: WebsocketClientHandlerParms<Params>) {
  return (options: {
    params: Params;
    onMessage?: (message: Data, event: MessageEvent<string>) => void;
  }): Promise<WebSocketHandler> =>
    new Promise((resolve, reject) => {
      let ep: string = '';
      if (typeof endpoint === 'string') {
        ep = endpoint;
      } else {
        ep = endpoint(options?.params);
      }

      const handler = websocketHandler({ host, endpoint: ep });

      if (!handler.socket) {
        reject(new Error('Failed to create websocket'));
        return;
      }

      handler.socket.onerror = (error) => {
        handler.isConnected = false;
        if (!onError) {
          toaster.show({
            message: errorMessage,
            icon: 'warning-sign',
            intent: 'danger',
          });
        } else {
          onError?.(error);
        }
      };

      handler.socket.onclose = () => {
        onDisconnect?.();
        handler.isConnected = false;
        resolve(handler);
      };

      handler.socket.onopen = () => {
        handler.isConnected = true;
        handler.close = () => handler.socket?.close();
        handler.send = (data) => {
          handler.socket?.send(JSON.stringify(data));
        };
        onConnect?.();
        resolve(handler);
      };
    });
}
