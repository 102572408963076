import { TextField } from 'opencosmos-ui';
import React, { useEffect, useState } from 'react';
import type { EventProperty } from '../utils/schema';

type Props = {
  schema: EventProperty;
  propertyKey: string;
  populateEventToSubmit: (value: unknown, propertyKey: string) => void;
};

const EventSchemaString = ({
  schema,
  propertyKey,
  populateEventToSubmit,
}: Props) => {
  const [val, setVal] = useState<string | undefined>(schema.default);

  const dbTimer = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (dbTimer.current) {
      clearTimeout(dbTimer.current);
    }

    dbTimer.current = setTimeout(() => {
      populateEventToSubmit(val, propertyKey);
    }, 500);
  }, [populateEventToSubmit, propertyKey, val]);
  return (
    <div>
      <div className="flex gap-1 items-center">
        <span>{schema.title}</span>
        {schema.isRequired && <span className="text-warning">*</span>}
      </div>
      <TextField
        value={val}
        onChange={(v) => setVal(v)}
        inputProps={{
          className: 'border-2 border-neutral-600 h-8 bg-item px-2',
        }}
      />
    </div>
  );
};

export default EventSchemaString;
