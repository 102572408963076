import { FocusTypes } from './actionTypes';

export const focusHandler = {
  [FocusTypes.GS_SUB_ID]: {
    propsName: 'groundStations',
    drawing: 'groundStationDrawing',
  },
  [FocusTypes.PI_SUB_ID]: {
    propsName: 'pointsOfInterest',
    drawing: 'pointOfInterestDrawing',
  },
  [FocusTypes.RI_SUB_ID]: {
    propsName: 'regionsOfInterest',
    drawing: 'regionOfInterestDrawing',
  },
};
