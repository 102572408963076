import type React from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

type IProps = {
  children: React.ReactNode;
  /**
   * Target element ref or id
   */
  target?: string | React.MutableRefObject<HTMLElement>;
};

export const isRef = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any
): target is React.MutableRefObject<HTMLElement> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return target?.current !== undefined;
};

const Portal = ({ children, target }: IProps) => {
  const mount = isRef(target)
    ? target.current
    : document.getElementById(target ? target : 'portal-root');
  const el = document.createElement('div');

  useEffect(() => {
    if (mount) {
      if (target) {
        mount.style.position = 'relative';
        el.style.width = '100%';
      }

      mount.appendChild(el);
      return () => mount.removeChild(el);
    }
    return () => {};
  }, [el, mount, target]);

  return createPortal(children, el);
};

export default Portal;
