import { useEffect, type ReactElement } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import Loading from 'components/common/Loading';
import PrivateRoute from 'components/security/PrivateRoute';
import { useAuth } from 'services/auth/AuthWrapper';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import type { RouteType } from 'routes';

export const App = () => {
  const location = useLocation();
  const auth0 = useAuth();

  const { setTheme } = useTheme();

  usePageTitle({ ignoredRoutes: ['data', 'msd'] });

  useEffect(() => {
    if (location.pathname.includes('data')) {
      setTheme('AUTO');
    } else {
      setTheme('LIGHT');
    }
  }, [location.pathname, setTheme]);

  if (auth0.error) {
    return (
      <div className="app">
        <div
          className="layout-item login-layout no-print"
          style={{
            display: 'block',
            textAlign: 'center',
            color: 'white',
          }}
        >
          <h1>Error: {auth0.error.name}</h1>
          <p>{auth0.error.description}</p>
          <button onClick={() => window.location.reload()}>Retry</button>
        </div>
      </div>
    );
  }

  return (
    <div className="app font-sans">
      <Loading isLoading={false} />
      <Switch location={location}>
        {routes.map((route: RouteType, i: number): ReactElement => {
          if ((route.auth && route.auth.length !== 0) || route.isPrivate) {
            return <PrivateRoute key={route.path ?? i} {...route} />;
          }
          return <Route key={route.path ?? i} {...route} />;
        })}
      </Switch>
    </div>
  );
};
