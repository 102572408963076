import type {
  CovarianceMatrixData,
  PostScatterPlotData,
} from '_api/sampling/service';
import type {
  COGMetadataForRange,
  ICOGMetadataForRange,
} from 'datacosmos/services/tilingApi/cogMetadata';
import React, { useContext, createContext, useState } from 'react';

export type ILayerToolsContext = ReturnType<typeof useLayerToolsProvider>;

export const LAYER_TOOLS = {
  SCATTER: 'scatter',
  COVARIANCE: 'covariance',
  SPECTRAL_ANALYSIS: 'histogram',
} as const;

export type LayerTool = (typeof LAYER_TOOLS)[keyof typeof LAYER_TOOLS];

type SelectedBand = { id: string; value: string };

export const LayerToolsContext = createContext<ILayerToolsContext>(
  null as unknown as ILayerToolsContext
);

export const useLayerTools = () => useContext(LayerToolsContext);

export const useLayerToolsProvider = () => {
  const [enabledTool, setEnabledTool] = useState<LayerTool | null>(null);
  const [selectedBands, setSelectedBands] = useState<SelectedBand[]>([]);
  const [data, setData] = useState<
    | PostScatterPlotData
    | CovarianceMatrixData
    | { [key: string]: COGMetadataForRange | ICOGMetadataForRange }
    | null
  >(null);

  return {
    enabledTool,
    setEnabledTool,
    data,
    setData,
    selectedBands,
    setSelectedBands,
  };
};

export const LayerToolsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <LayerToolsContext.Provider value={useLayerToolsProvider()}>
      {children}
    </LayerToolsContext.Provider>
  );
};
