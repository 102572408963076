import { useCallback, useRef } from 'react';
import { find, findIndex } from 'lodash/fp';
import { showErrorMessage } from 'utils/common/CommonUtils';
import {
  FIRE_COMMAND,
  SEND_UNARMED_COMMAND,
  UPDATE_WORKSPACE,
} from 'constants/ops/rti/oparate/constants';
import { ops } from 'constants/mixpanelAnalytics';
import type { CommandHistoryPayload } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { terminalSessionWebsocket } from '_api/terminalSession/service';
import { useWebsocket } from '_api/useWebsocket';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MessageCallback = (data: any, event?: MessageEvent) => void;

export interface ISendUnarmedCommand {
  command: string;
  payload: CommandHistoryPayload;
  customName?: string;
  procedureName?: string;
  cb?: MessageCallback;
  responseType?: string;
  timeoutCb?: Function;
  getTimeOutId?: (id: number) => void;
}

export interface ISendUnarmedCommandResponse {
  sequenceId: number;
}

export type UnarmedCommand = (
  value: ISendUnarmedCommand
) => ISendUnarmedCommandResponse;

export type UpdateWorkspace = (
  commandPile: object,
  armedCommand: object | undefined,
  isFrozen: boolean
) => void;

export type FireCommand = (
  armedCommand: ICommandPileItem,
  cb?: MessageCallback
) => {
  sequenceId: number;
};

export interface ICommandPileItem {
  arguments?: unknown[] | null;
  command: string;
  id?: string;
  customName?: string;
  payload: CommandHistoryPayload;
  procedureName: null;
}

interface ITimerCallback {
  id?: string;
  timeout?: number;
  cb?: Function;
}

export const useApiOpsConnectToTerminalSession = (
  sessionId: number,
  missionId: number | undefined,
  onMessage?: MessageCallback
) => {
  const repliers = useRef<{ [key: number]: MessageCallback }>({});
  const timers = useRef<ITimerCallback[]>([]);

  const { isConnected, send, socket } = useWebsocket(terminalSessionWebsocket, {
    params: { sessionId },
    skip: !sessionId,
    onMessage: (msg, event) => {
      const seqId = msg.sequenceId;
      const type = msg.type;
      const error = msg.data.error;
      const ack = msg.data.ack;

      if (seqId && repliers.current[seqId]) {
        repliers.current[seqId]?.(msg, event);
      }

      if (seqId && error) {
        const timer = find(
          ({ id }) => (id ?? '').includes(String(seqId)),
          timers.current
        );
        if (timer) {
          clearTimeoutCallback(timer, true);
        }
      }

      if (seqId && type && !ack) {
        const timer = find(['id', `${seqId}_${type}`], timers.current);
        if (timer) {
          clearTimeoutCallback(timer);
        }
      }

      if (onMessage) {
        onMessage(msg, event);
      }
    },
  });

  const { sendInfo } = useAnalytics();

  const clearTimeoutCallback = (timer?: ITimerCallback, isError?: boolean) => {
    if (!timer) {
      return;
    }

    clearTimeout(timer.timeout);
    const removeId = findIndex(['id', timer.id], timers.current);
    timers.current.splice(removeId, 1);
    if (isError && timer.cb) timer.cb();
  };

  const sendUnarmedCommand = useCallback(
    ({
      command,
      payload,
      customName,
      procedureName,
      cb,
      responseType,
      timeoutCb,
      getTimeOutId,
    }: ISendUnarmedCommand): ISendUnarmedCommandResponse => {
      const sequenceId = Math.floor(Math.random() * 9007199254740991);

      if (responseType && timeoutCb) {
        const timerId = setTimeout(timeoutCb, 15000);
        getTimeOutId?.(timerId);

        timers.current.push({
          id: `${sequenceId}_${responseType}`,
          cb: timeoutCb,
          timeout: timerId,
        });
      }

      if (cb) {
        repliers.current[sequenceId] = cb;
      }

      sendInfo({
        type: ops.RTI.COMMAND.SEND,
        action: 'Send command',
        item: 'RTI Command',
        module: 'OPS',
        additionalParams: {
          command,
          mission: missionId,
          method: SEND_UNARMED_COMMAND,
        },
      });

      send?.({
        action: SEND_UNARMED_COMMAND,
        sequenceId,
        payload: { command, customName, procedureName, payload },
      });

      return { sequenceId };
    },
    [missionId, send, sendInfo]
  );

  const updateWorkspace = useCallback(
    (
      commandPile: object,
      armedCommand: object | undefined,
      isFrozen: boolean | undefined,
      cb?: MessageCallback
    ) => {
      const sequenceId = Math.floor(Math.random() * 9007199254740991);

      // TODO: Add timeout
      if (cb) {
        repliers.current[sequenceId] = cb;
      }

      if (socket === null) {
        showErrorMessage(
          'Lost connection to satellite gateway. Re-connect by refreshing the page.'
        );
        return { sequenceId };
      }

      send?.({
        action: UPDATE_WORKSPACE,
        sequenceId,
        payload: {
          CommandPileWorkingSpace: commandPile,
          armedCommand: armedCommand,
          frozenPile: isFrozen,
        },
      });

      return { sequenceId };
    },
    [send, socket]
  );

  const fireCommand = useCallback(
    (armedCommand: ICommandPileItem, cb?: MessageCallback) => {
      const sequenceId = Math.floor(Math.random() * 9007199254740991);

      // TODO: Add timeout
      if (cb) {
        repliers.current[sequenceId] = cb;
      }

      sendInfo({
        type: ops.RTI.COMMAND.SEND,
        action: 'Fire command',
        item: 'RTI Command',
        module: 'OPS',
        additionalParams: {
          mission: missionId,
          command: armedCommand.command,
          method: FIRE_COMMAND,
        },
      });

      send?.({
        action: FIRE_COMMAND,
        sequenceId,
        payload: armedCommand,
      });

      return { sequenceId };
    },
    [missionId, send, sendInfo]
  );

  const cancelCallBackByTimer = useCallback(
    (timerId: number, seqId: number) => {
      clearTimeout(timerId);
      const removeId = findIndex(['id', timerId], timers.current);
      timers.current.splice(removeId, 1);
      if (repliers.current[seqId]) {
        delete repliers.current[seqId];
      }
    },
    []
  );

  return {
    isConnected,
    socket,
    sendUnarmedCommand,
    updateWorkspace,
    fireCommand,
    cancelCallBackByTimer,
  };
};
