import { useEffect } from 'react';
import type { TrackingEvent } from './useAnalytics';
import { useAnalytics } from './useAnalytics';

export default (event: TrackingEvent) => {
  const { startTracking, stopTracking } = useAnalytics();
  useEffect(() => {
    startTracking(event);
    return () => stopTracking(event);
  }, [event, startTracking, stopTracking]);
};
