import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../pages/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IMarkerWrapper } from '../../../../declarations/mapDeclarations/Marker';
import { setFocus } from '../../../../pages/shared/state/actions/focus/thunks';
import {
  removeGS,
  updateGS,
} from '../../../../pages/shared/state/actions/groundStations/thunks';
import { setPopUp } from '../../../../pages/shared/state/actions/popUp/thunks';
import MarkerForm from '../../../../components/popUp/MarkerForm';
import type { IGroundStation } from '../../../../constants/groundStations/actionTypes';
import { GROUND_STATION_FORM_NAME } from '../../../../constants/popUp/constants';
import { FocusTypes } from '../../../../constants/focus/actionTypes';

export const updateGroundStation =
  (
    groundStation: IMarkerWrapper | IGroundStation
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, state) => {
    const groundStationData = state().groundStations.list.find(
      (g): boolean => groundStation.id === g.id
    );
    dispatch(
      setFocus({
        id: String(groundStationData?.id ?? ''),
        type: FocusTypes.GS_SUB_ID,
        needScroll: true,
        withPopUp: true,
      })
    );
    dispatch(
      setPopUp({
        component: MarkerForm,
        visible: true,
        classNames: ['short-container'],
        data: groundStationData,
        otherProps: {
          type: FocusTypes.GS_SUB_ID,
          form: GROUND_STATION_FORM_NAME,
        },
        functions: {
          onSubmit: (groundStationLocal: IGroundStation): void =>
            dispatch(updateGS({ ...groundStationData, ...groundStationLocal })),
          onClose: (): void => {},
        },
      })
    );
  };

export const showGroundStation =
  (
    groundStation: IMarkerWrapper | IGroundStation
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, state) => {
    const groundStationData = state().groundStations.list.find(
      (g): boolean => groundStation.id === g.id
    );
    dispatch(
      setFocus({
        id: String(groundStationData?.id ?? ''),
        type: FocusTypes.GS_SUB_ID,
        needScroll: true,
        withPopUp: true,
      })
    );
    dispatch(
      setPopUp({
        component: MarkerForm,
        visible: true,
        classNames: ['short-container'],
        data: groundStationData,
        otherProps: {
          type: FocusTypes.GS_SUB_ID,
          form: GROUND_STATION_FORM_NAME,
        },
        functions: {
          onSubmit: (groundStationLocal: IGroundStation): void =>
            dispatch(updateGS({ ...groundStationData, ...groundStationLocal })),
          onClose: (): void => {},
        },
      })
    );
  };
export const removeGroundStation =
  (
    groundStation: IMarkerWrapper | IGroundStation
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    dispatch(removeGS(String(groundStation.id)));
  };
