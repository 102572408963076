import type { EventJsonSchema } from '_api/scripts/types';

export type EventProperty = {
  type: string | string[] | undefined;
  enum?: string[];
  isRequired: boolean;
  title: string;
  default?: string;
  items?: Record<string, EventProperty> | undefined;
};

export const dereferenceSchema = (
  schema: EventJsonSchema,
  definitions: Record<string, EventJsonSchema> | undefined
) => {
  if (!definitions || !schema) {
    return schema;
  }

  if (!schema.items) {
    return schema;
  }

  const refPathArr = schema.items?.$ref?.split('/');
  const defPath = refPathArr?.[refPathArr.length - 1];

  if (!defPath) {
    return schema;
  }

  return {
    ...schema,
    items: definitions[defPath],
  };
};

export const getParsedSchemaProperties = (
  schema: EventJsonSchema,
  p: Record<string, EventProperty> = {}
) => {
  if (!schema) {
    return {};
  }

  if (schema.type === 'object') {
    const properties = schema.properties;

    if (properties) {
      Object.keys(properties).forEach((key) => {
        const property = dereferenceSchema(properties[key], schema.$defs);
        const isRequired = schema.required?.includes(key) ?? false;

        p[key] = {
          type: property.type,
          enum: property.enum,
          isRequired,
          title: property.title ?? key,
          default: property.default,
        };

        if (property.type === 'object') {
          p[key] = {
            ...p[key],
            ...getParsedSchemaProperties(property, p),
          };
        }

        if (property.type === 'array' && property.items) {
          p[key].items = getParsedSchemaProperties(property.items, {});
        }
      });
    }
  }

  return p;
};
