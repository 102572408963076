import classNames from 'classnames';
import React from 'react';
import type { IListItemProps } from '_atoms/ListItem/ListItem';
import UnorderedList, {
  ListOrientation,
} from '_molecules/UnorderedList/UnorderedList';
import type { PressEvent } from '@react-types/shared';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import { Button, Icon } from 'opencosmos-ui';

type IProps = {
  className?: string;
  title: string;
  info:
    | React.ReactElement<IListItemProps>
    | React.ReactElement<IListItemProps>[]
    | null;
  actionElement?: JSX.Element | null;
  rightIcons?: {
    icon: IconName;
    onClick: (e: PressEvent) => void;
    shown: boolean;
    text?: string;
    isDisabled?: boolean;
  }[];
  indicators?: {
    text?: string;
    icon?: IconName;
    shown: boolean;
  }[];
  onMouseEnter?: () => {};
  onMouseLeave?: () => {};
};

const ListCard = (props: IProps) => {
  return (
    <div
      className={classNames(
        props.className,
        '!shadow-none grid grid-flow-col p-3 cursor-pointer group border-b-2',
        'dark:border-b-item-contrast dark:text-item-dark-contrast after:content-none before:content-none'
      )}
      onMouseLeave={() => {
        props.onMouseLeave?.();
      }}
      onMouseEnter={() => {
        props.onMouseEnter?.();
      }}
    >
      <div className="flex flex-col gap-2">
        <div className="w-full flex gap-4 items-center">
          <h3 className="font-normal m-0 text-inherit text-sm group-hover:text-hover-light">
            {props.title}
          </h3>

          <div className="flex gap-2">
            {props.indicators?.map(
              (ind) =>
                ind.shown && (
                  <div className="flex gap-1" key={ind.text}>
                    {ind.text && <span>{ind.text}</span>}
                    {ind.icon && (
                      <Icon
                        icon={ind.icon}
                        className="stroke-accent-900 fill-accent-900"
                      />
                    )}
                  </div>
                )
            )}
          </div>
        </div>

        {props.info && (
          <UnorderedList orientation={ListOrientation.Horizontal}>
            {props.info}
          </UnorderedList>
        )}

        <div className="w-48">{props.actionElement}</div>
      </div>

      <div className="w-full flex flex-col items-end justify-between">
        {props.rightIcons?.map(
          (icons) =>
            icons.shown && (
              <div className="flex gap-1 text-sm items-center" key={icons.icon}>
                {icons.text}
                <Button
                  icon={icons.icon}
                  onPress={icons.onClick}
                  isMinimal
                  isTransparent
                  size="base"
                  isDisabled={icons.isDisabled}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ListCard;
