import { useCallback, useEffect, useState } from 'react';
import { isNil } from 'lodash/fp';

import { useAuth } from 'services/auth/AuthWrapper';
import { showErrorMessage } from 'utils/common/CommonUtils';

import passAPI from './passAPI';
import { GROUND_STATION_SCHEDULING_SERVICE_URL } from './constants';
import type { PassState } from '_api/groundStation/types';

export interface IUsePassState {
  isFetching: boolean;
  passState: PassState;
  getPassState: Function;
}

export const usePassState = (missionId: number) => {
  const { token } = useAuth();

  const passApiInstance = passAPI(
    GROUND_STATION_SCHEDULING_SERVICE_URL,
    missionId,
    token
  );

  const [isFetching, setFetching] = useState(false);
  const [passState, setPassState] = useState<PassState>();

  const getPassState = useCallback(async () => {
    try {
      setFetching(true);

      const data = await passApiInstance.getNextPass(missionId);

      if (!isNil(data)) {
        //@ts-expect-error
        setPassState(data);
      }
    } catch (e) {
      showErrorMessage('Cannot get pass state!');
    }
    setFetching(false);
  }, [missionId, passApiInstance]);

  useEffect(() => {
    if (!isNil(missionId)) {
      void getPassState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionId]);

  return {
    isFetching,
    passState,
    getPassState,
  };
};
