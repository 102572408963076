import type { OrderLineItem } from '_api/orders/types';
import React from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  orderItem: OrderLineItem;
};

const OrderItemCard = ({ orderItem }: Props) => {
  const { translate } = useLocalisation();

  return (
    <div className="grid !shadow-none grid-cols-[1fr_1.5fr_0.5fr] gap-3 bg-item dark:bg-item-dark h-min p-2">
      <div className="flex flex-col gap-1">
        <span className="text-xs text-item-contrast dark:text-item-dark-contrast">
          {translate('datacosmos.orders.items.collection')}
        </span>
        <span className="text-item-contrast dark:text-item-dark-contrast break-all">
          {orderItem.collection}
        </span>
      </div>

      <div className="flex flex-col gap-1">
        <span className="text-xs text-item-contrast dark:text-item-dark-contrast">
          {translate('datacosmos.orders.items.item')}
        </span>
        <span className="text-item-contrast dark:text-item-dark-contrast break-all">
          {orderItem.item}
        </span>
      </div>

      <div className="flex flex-col gap-1">
        <span className="text-xs text-item-contrast dark:text-item-dark-contrast">
          {translate('datacosmos.orders.items.level')}
        </span>
        <span className="text-item-contrast dark:text-item-dark-contrast break-all">
          {orderItem.level}
        </span>
      </div>
    </div>
  );
};

export default OrderItemCard;
