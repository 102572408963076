import { Input, Popover, TextField } from 'opencosmos-ui';
import React, { useRef, useState } from 'react';
import type { UpdatedActivityValue } from '../../context/ActivitiesProvider';
import useUpdateEffect from 'utils/hooks/useUpdateEffect';

type Props = {
  inputFor?: keyof UpdatedActivityValue;
  value: string;
  activityId: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  updateActivity?: (activityId: string, value: UpdatedActivityValue) => void;
};

const InputCellWithPopover = ({
  value,
  activityId,
  readOnly,
  inputFor,
  isDisabled,
  updateActivity,
}: Props) => {
  const [val, setVal] = useState<string | undefined>(String(value));
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const popoverTrigger = useRef<HTMLInputElement | null>(null);
  const popoverContentRef = useRef<HTMLDivElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const handleBlur = (e: React.FocusEvent) => {
    const relatedTarget = e.relatedTarget as Node | null;

    // Close popover if the focus is outside both the trigger and the popover content
    if (
      popoverTrigger.current &&
      popoverContentRef.current &&
      !popoverTrigger.current.contains(relatedTarget) &&
      !popoverContentRef.current.contains(relatedTarget)
    ) {
      setIsPopoverOpen(false);
    }
  };

  useUpdateEffect(() => {
    if (val === '' || val === undefined) {
      return;
    }
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      if (!inputFor) {
        return;
      }

      updateActivity?.(activityId, {
        [inputFor]: val,
      });
    }, 500);
  }, [activityId, inputFor, updateActivity, val]);

  return (
    <>
      <TextField
        value={val}
        onChange={(v) => setVal(v)}
        inputProps={{
          inputRef: popoverTrigger,
          onClick: () => setIsPopoverOpen(!isPopoverOpen),
          className: 'bg-transparent border-none p-1 overflow-ellipsis',
          autoComplete: 'off',
        }}
        isReadOnly={readOnly ?? isPopoverOpen}
        isDisabled={isDisabled}
        onBlur={handleBlur}
      />

      <Popover
        placement="bottom"
        triggerRef={popoverTrigger}
        className="bg-item dark:bg-item-dark p-1 dark:text-item-dark-contrast"
        isOpen={isPopoverOpen}
        isNonModal={true}
        ref={popoverContentRef}
        style={{
          width: popoverTrigger.current?.clientWidth ?? 0,
        }}
      >
        <Input
          type="textarea"
          value={val}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setVal(e.target.value)}
          readOnly={readOnly}
          onBlur={handleBlur}
        />
      </Popover>
    </>
  );
};

export default InputCellWithPopover;
