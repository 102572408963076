import { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Intent,
  Icon,
  AnchorButton,
} from '@blueprintjs/core';
import s from './index.module.scss';
import { IconNames } from '@blueprintjs/icons';

import { getGrafanaUrlForAlert, getBonusGrafanaUrlForAlert } from './utilities';
import type { AlertRule } from '_api/alerts/types';
import { Tooltip } from 'opencosmos-ui';

interface IProps {
  alert: AlertRule | undefined;
  setShowNewDefinition?: (value: boolean) => void;
  editHandler: Function;
  deleteHandler: Function;
}

const reviewStatusToIntent = {
  accepted: Intent.SUCCESS,
  rejected: Intent.DANGER,
  'changed since review': Intent.PRIMARY,
  'not reviewed': Intent.NONE,
};

const reviewTextToIntent = {
  accepted: 'Accepted',
  rejected: 'Rejected',
  'changed since review': 'Changed sinced review',
  'not reviewed': 'Not reviewed',
};

const LibraryAlertsRow = (props: IProps) => {
  const [alert, setAlert] = useState<AlertRule | undefined>();

  useEffect(() => {
    if (props.alert) {
      setAlert(props.alert);
    }
  }, [props.alert]);

  if (!alert) return null;

  let reviewIntent = Intent.NONE as Intent;
  if (alert.annotations.reviewed) {
    reviewIntent = reviewStatusToIntent[alert.annotations.reviewed];
  }

  const renderReviewIcon = () => {
    const reviewText = reviewTextToIntent[alert.annotations.reviewed];

    return (
      <Tooltip content={reviewText}>
        <Icon icon={IconNames.CONFIRM} intent={reviewIntent} />
      </Tooltip>
    );
  };

  const renderEditableButtons = () => {
    const encoded = getGrafanaUrlForAlert(alert.expr);
    const bonusEncoded = getBonusGrafanaUrlForAlert(alert.expr);

    return (
      <ButtonGroup>
        <Tooltip content="Delete">
          <AnchorButton
            // TODO remove `disabled` when api will be connected
            minimal
            icon={IconNames.TRASH}
            intent={Intent.DANGER}
            // @ts-expect-error
            onClick={props.deleteHandler}
          />
        </Tooltip>
        <Tooltip content="Configure or approve in editor">
          <Button
            minimal
            icon={IconNames.EDIT}
            // @ts-expect-error
            onClick={props.editHandler}
          />
        </Tooltip>
        <Tooltip content="Show when this alert rule was firing in Grafana">
          <Button
            minimal
            icon={IconNames.LIGHTBULB}
            onClick={() => window.open(encoded)}
          />
        </Tooltip>
        {bonusEncoded && (
          <Tooltip content="Show the metric value over time and the threshold to trigger an alert in Grafana">
            <Button
              minimal
              icon={IconNames.TIMELINE_LINE_CHART}
              intent={Intent.PRIMARY}
              onClick={() => window.open(bonusEncoded)}
            />
          </Tooltip>
        )}
      </ButtonGroup>
    );
  };

  return (
    <tr key={alert.id}>
      <td className={s.tableSubsystems}>{alert.labels.system}</td>
      <td className={s.tableIds}>{alert.id || '--'}</td>
      <td className={s.tableNames}>{alert.alert}</td>
      <td className={s.tableDescriptions}>{alert.annotations.description}</td>
      <td className={s.tableExpressions}>{alert.expr}</td>
      <td className={s.tableSeverity}>{alert.labels.severity}</td>
      <td className={s.tableTeam}>{alert.labels.team}</td>
      <td className={s.tableReviewed}>{renderReviewIcon()}</td>
      <td className={s.tableButtons}>{renderEditableButtons()}</td>
    </tr>
  );
};

export default LibraryAlertsRow;
