import classNames from "classnames";
import ReactQuill, { type Value } from "react-quill";
import type { DeltaStatic, Sources } from "quill";
import "react-quill/dist/quill.snow.css";

export type onChangeType = (
  value: string,
  delta: DeltaStatic,
  source: Sources,
  editor: ReactQuill.UnprivilegedEditor
) => void;

type RichTextEditorProps = {
  label?: {
    text: string;
    position: "top" | "left" | "right";
    wrapLabelText?: boolean;
  };
  onChange: onChangeType;
} & React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const RichTextEditor = (props: RichTextEditorProps) => {
  return (
    <>
      {props.label && (
        <label
          htmlFor={props.id}
          className={classNames(" text-sm", {
            "order-1": props.label.position === "left",
            "order-2": props.label.position === "right",
            "whitespace-nowrap": !props.label?.wrapLabelText,
          })}
        >
          {props.label.text}
        </label>
      )}
      <ReactQuill
        theme="snow"
        value={props.value as Value}
        onChange={props.onChange}
        id={props.id}
        style={props.style}
        className={props.className}
      />
    </>
  );
};

export default RichTextEditor;
