import type { ISVGPoint } from '../../constants/missionDesignPage/interfaces';

/**
 * http://www.ams.org/publicoutreach/feature-column/fcarc-bezier
 * @param fP
 * @param cP
 * @param lP
 * @param t
 */
export const getQuadraticBezierCurves = (
  fP: ISVGPoint,
  cP: ISVGPoint,
  lP: ISVGPoint,
  t: number
): ISVGPoint => {
  if (t < 0 || t > 1) {
    t = t < 0 ? 0 : 1;
  }
  return {
    x: (1 - t) * (1 - t) * fP.x + 2 * (1 - t) * t * cP.x + t * t * lP.x,
    y: (1 - t) * (1 - t) * fP.y + 2 * (1 - t) * t * cP.y + t * t * lP.y,
  };
};
