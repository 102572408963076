import type {
  IPopUp,
  popUpTypes,
} from '../../../../constants/popUp/actionTypes';
import {
  RESET_POPUP,
  SET_POPUP,
} from '../../../../constants/popUp/actionTypes';

const initialState: IPopUp = {
  classNames: [],
  visible: false,
  component: null,
  data: null,
  functions: null,
};

export default function popUpReducer(
  state: IPopUp = initialState,
  action: popUpTypes
): IPopUp {
  switch (action.type) {
    case SET_POPUP: {
      return { ...action.popUp };
    }
    case RESET_POPUP: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}
