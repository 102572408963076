import type { ReactElement } from 'react';
import React, { Component } from 'react';
import type { FormErrors, InjectedFormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { Classes, H3, H6, Pre } from '@blueprintjs/core';
import renderField from '../inputField/TextField';
import renderNumericField from '../inputField/NumberField';
import {
  DRAGGABLE,
  DRAGGABLE_HEADER,
} from '../../constants/draggable/constants';
import { dragElement } from '../../services/draggableService/dragElement';
import { POINT_FORM_NAME } from '../../constants/popUp/constants';
import type { IPointOfInterest } from '../../constants/pointsOfInterest/actionTypes';
import type { IValidateError } from '../../constants/actionTypes';
import {
  biggerThanOrEqualToX,
  inBetweenInclusive,
  isNumeric,
} from '../../utils/common/CommonValidator';
import SelectField from '../inputField/SelectField';
import type { AppState } from '../../pages/shared/state/reducers/rootReducer';
import { connect } from 'react-redux';
import { selectSatelliteModes } from '../../pages/shared/utils/selectors/satellite';
import formatArrayAsSelectOptions from '../../utils/formatArrayAsSelectOptions';

function validate(values: FormData & IPointOfInterest): FormErrors {
  const errors: IValidateError = {};
  const { name, lat, lon, altitude, elevationAngle } = values;
  if (!name) {
    errors.name = errors._error = 'name_not_empty';
  }
  const isValidLet =
    !isNumeric(lat) || !inBetweenInclusive(Number(lat), -90, 90);
  if (isValidLet) {
    errors.lat = errors._error = 'lat_error';
  }
  const isValidLon =
    !isNumeric(lon) || !inBetweenInclusive(Number(lon), -180, 180);
  if (isValidLon) {
    errors.lon = errors._error = 'lng_error';
  }
  const isValidAlt = !isNumeric(altitude) || !biggerThanOrEqualToX(altitude, 0);
  if (isValidAlt) {
    errors.altitude = errors._error = 'alt_error';
  }
  const isValidAngle =
    !isNumeric(elevationAngle) ||
    !inBetweenInclusive(Number(elevationAngle), 0, 90);
  if (isValidAngle) {
    errors.elevationAngle = errors._error = 'poi_elevation_ang_error';
  }
  return errors;
}

interface IMarkerFormProps {
  satelliteModes: string[];
  resetPopUp?: Function;
  onBack?: Function;
  onClose: Function;
  t: Function;
  type?: string;
  disable?: boolean;
  modesOnly?: boolean;
}

class MarkerForm extends Component<
  IMarkerFormProps & InjectedFormProps<{}, IMarkerFormProps>
> {
  public componentDidMount(): void {
    const element = document.getElementById(DRAGGABLE);
    if (element) {
      dragElement(element);
    }
  }

  private onCloseWrapper = (): void => {
    const { initialValues } = this.props;
    const { onClose, resetPopUp } = this.props;
    onClose(initialValues);
    resetPopUp?.();
  };

  public render(): ReactElement {
    const { handleSubmit, submitSucceeded, resetPopUp, error } = this.props;
    if (submitSucceeded) {
      resetPopUp?.();
    }
    const { t, type, disable, modesOnly } = this.props;
    const errorDiv = error ? (
      <div className="bp4-callout bp4-intent-danger bp4-icon-error">
        <H6 className="bp4-heading">{t(`validation.errors.${error}`)}</H6>
      </div>
    ) : null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const saveTitle = error
      ? t(`validation.errors.${error}`)
      : t('validation.save_title');

    const satelliteModeOptions = formatArrayAsSelectOptions(
      this.props.satelliteModes
    );

    return (
      <form onSubmit={handleSubmit}>
        <div className="bp4-dialog dialog-margin-set-out">
          <div id={DRAGGABLE_HEADER} className="bp4-dialog-header">
            <H3>{t(`validation.header.${type ?? ''}`)}</H3>
            <button
              className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
              onClick={this.onCloseWrapper}
              tabIndex={0}
              onKeyDown={(ev) => ev.keyCode === 13 && this.onCloseWrapper()}
            />
          </div>
          <div className="bp4-dialog-body">
            <div>
              <Field name="id" component="input" type="hidden" />
              <Field
                name="name"
                placeholder="Name"
                component={renderField}
                disable={disable}
                type="textarea"
              />
            </div>
            {modesOnly && (
              <div>
                <Field
                  name="satelliteModeLabel"
                  component="input"
                  type="hidden"
                />
                <Field
                  name="satelliteMode"
                  title="module_msd.satellite.modes.satellite_mode"
                  onChangeState={(value: string) =>
                    this.props.change('satelliteMode', value)
                  }
                  options={satelliteModeOptions}
                  component={SelectField}
                />
              </div>
            )}
            <Pre className="flex-pre">
              <div>
                <H6>{t('validation.point.lat')}</H6>
                <Field
                  name="lat"
                  placeholder="latitude"
                  disable={disable}
                  component={renderNumericField}
                  type="number"
                />
              </div>
              <div>
                <H6>{t('validation.point.lng')}</H6>
                <Field
                  name="lon"
                  placeholder="longitude"
                  disable={disable}
                  component={renderNumericField}
                  type="number"
                />
              </div>
            </Pre>
            <Pre>
              <div className="bp4-form-group">
                <label className="bp4-label" htmlFor="form-group-input">
                  <H6>{t(`validation.text-field.names.${'altitude'}`)}</H6>
                </label>
                <div className="bp4-form-content">
                  <Field
                    name="altitude"
                    disable={disable}
                    component={renderNumericField}
                  />
                </div>
              </div>
            </Pre>
            <Pre>
              <div className="bp4-form-group">
                <label className="bp4-label" htmlFor="form-group-input">
                  <H6>
                    {t(`validation.text-field.names.${'elevationAngle'}`)}
                  </H6>
                </label>
                <div className="bp4-form-content">
                  <Field
                    name="elevationAngle"
                    disable={disable}
                    component={renderNumericField}
                  />
                </div>
              </div>
            </Pre>
            {errorDiv}
          </div>
          {!disable && (
            <div className="bp4-dialog-footer">
              <div className="bp4-dialog-footer-actions">
                {this.props.onBack && (
                  <button
                    type="button"
                    className={`bp4-button ${
                      error ? Classes.INTENT_DANGER : Classes.INTENT_WARNING
                    }`}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    onClick={() => this.props.onBack?.()}
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  title={saveTitle}
                  className={`bp4-button bp4-icon-saved  ${
                    error ? Classes.INTENT_DANGER : Classes.INTENT_SUCCESS
                  }`}
                  disabled={Boolean(error) ?? disable}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  satelliteModes: selectSatelliteModes(state),
});

export default reduxForm<{}, IMarkerFormProps>({
  form: POINT_FORM_NAME,
  validate,
})(connect(mapStateToProps)(MarkerForm));
