//http://www.cs.yorku.ca/~oz/hash.html
export const getHash = function (str: string | undefined): string {
  let hash = 0;
  let chr;
  if (!str) {
    return hash.toString();
  }
  if (str.length === 0) {
    return hash.toString();
  }
  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  //TODO try to isolate the bug; remove when close https://git.o-c.space/app/tickets/issues/67
  //console.log('input=', str, 'hash=', hash.toString());

  return hash.toString();
};
