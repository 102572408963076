export const basename = '/data';
export const routeParamProjectId = 'projectId';
export const baseProjectRedirect =
  basename + '/project/:path(catalog|application|tasking|items)';
export const baseProjectRoute = basename + '/project/:' + routeParamProjectId;
export const routeProjectItems = baseProjectRoute + '/items';
export const routeTasking = baseProjectRoute + '/tasking';
export const routeApplication = baseProjectRoute + '/application';
export const routeCatalog = baseProjectRoute + '/catalog';
export const routeApplicationStatus = routeApplication + '/status';
