import type { IMapWrapper } from '../../declarations/mapDeclarations/Map';
import { mapOptions } from '../../services/mapService/mapOption';
import { addDrawingManager } from '../../services/mapService/drawingManager/services';
import { supportingPolygonDefine } from '../../services/mapService/regionOfInterest/options';
import { supportingPointDefine } from '../../services/mapService/pointOfInterest/options';
import { registerMapType } from '../../factory/map/registerMapType';
import { MapTypes } from '../../factory/map/mapTypes';
import { supportingGroundStationDefine } from '../../services/mapService/groundStation/options';
import { ASTRUMS } from '../../constants/API/constant';
import { getAstrum } from '../common/CommonUtils';

export function initMap(
  mapId: string,
  options?: google.maps.MapOptions,
  onChangeDrawingMode?: Function
): IMapWrapper | null {
  if (!google) {
    throw new Error('Google did not load!');
  }
  const earthTypes: (google.maps.MapTypeId | string)[] = [
    'default',
    google.maps.MapTypeId.ROADMAP,
    google.maps.MapTypeId.SATELLITE,
    google.maps.MapTypeId.HYBRID,
    google.maps.MapTypeId.TERRAIN,
  ];
  const { MARKER, POLYGON } = google.maps.drawing.OverlayType;
  const container = document.getElementById(mapId);
  if (!container) {
    // eslint-disable-next-line no-console
    console.warn(`Map cannot be initialized, no container [${mapId}] found.`);
    return null;
  }
  const map: IMapWrapper = new google.maps.Map(
    container,
    mapOptions(google, options)
  );
  map.geocoder = new google.maps.Geocoder();

  map.onChangeDrawingMode = onChangeDrawingMode;
  map.drawingManager = addDrawingManager(map);
  map.groundStations = [];
  map.pointsOfInterest = [];
  map.regionsOfInterest = [];
  map.satellites = [];
  map.supportingPolygon = new google.maps.Polygon(supportingPolygonDefine());
  map.supportingPoint = new google.maps.Marker(supportingPointDefine());
  map.supportingGroundStation = new google.maps.Marker(
    supportingGroundStationDefine()
  );

  map.setDrawingMarkerMod = (): void =>
    map.drawingManager?.setDrawingMode(MARKER);

  map.isDrawingMarkerMod = (): boolean =>
    map.drawingManager?.getDrawingMode() === MARKER;

  map.setDrawingPolygonMod = (): void =>
    map.drawingManager?.setDrawingMode(POLYGON);

  map.isDrawingPolygonMod = (): boolean =>
    map.drawingManager?.getDrawingMode() === POLYGON;

  Object.keys(MapTypes).forEach((key: string) => {
    registerMapType(map, MapTypes[key]);
  });

  map.getAstrum = (): ASTRUMS => {
    const mapTypeId = map.getMapTypeId();
    if (earthTypes.includes(mapTypeId)) {
      return ASTRUMS.EARTH;
    }
    return getAstrum(mapTypeId);
  };

  // Custom default map style
  // Current style us based on: https://snazzymaps.com/style/55/subtle-greyscale-map
  const defaultMapType = new google.maps.StyledMapType(
    [
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            hue: '#000000',
          },
          {
            saturation: -100,
          },
          {
            lightness: -100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            hue: '#000000',
          },
          {
            saturation: -100,
          },
          {
            lightness: -100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
          {
            hue: '#000000',
          },
          {
            saturation: 0,
          },
          {
            lightness: -100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            hue: '#ffffff',
          },
          {
            saturation: -100,
          },
          {
            lightness: 100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
          {
            hue: '#000000',
          },
          {
            saturation: -100,
          },
          {
            lightness: -100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'all',
        stylers: [
          {
            hue: '#ffffff',
          },
          {
            saturation: -100,
          },
          {
            lightness: 100,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#ffffff',
          },
          {
            saturation: -100,
          },
          {
            lightness: 100,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [
          {
            hue: '#000000',
          },
          {
            saturation: 0,
          },
          {
            lightness: -100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [
          {
            hue: '#000000',
          },
          {
            saturation: -100,
          },
          {
            lightness: -100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#bbbbbb',
          },
          {
            saturation: -100,
          },
          {
            lightness: 26,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#dddddd',
          },
          {
            saturation: -100,
          },
          {
            lightness: -3,
          },
          {
            visibility: 'on',
          },
        ],
      },
    ],
    { name: 'default' }
  );

  // Register default map with custom style
  map.mapTypes.set('default', defaultMapType);

  return map;
}
