/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { SubmissionError } from 'redux-form';

// TODO: MOCK is always enabled because there is no real implementation at all
import MockAPI from '__mock__/api';

const API_PREFIX = '/msd/v1';

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${API_PREFIX}`,
};

const axiosInst = axios.create(axiosConfig);
MockAPI(axiosInst);

/* eslint-disable @typescript-eslint/no-explicit-any */
const CommonMsdApi = {
  groundStation: {
    get: (getConfig?: AxiosRequestConfig): Promise<any> =>
      axiosInst.get('/ground_stations', getConfig).then((res): any => res.data),
  },
  launchOpportunities: {
    get: (getConfig?: AxiosRequestConfig): Promise<any> =>
      axiosInst
        .get('/launch_opportunities', getConfig)
        .then((res): any => res.data),
  },
  missions: {
    get: (getConfig?: AxiosRequestConfig): Promise<any> =>
      axiosInst.get('/mission/', getConfig).then((res): any => res.data),
  },
  fetch: {
    get: (getConfig?: AxiosRequestConfig): Promise<any> =>
      axios
        .get('https://nominatim.openstreetmap.org/search.php', getConfig)
        .then((res): any => res.data),
  },
};

// apply interceptor on response
axiosInst.interceptors.response.use(
  (response): AxiosResponse<void> => response,
  (error): void => {
    const err = error as { response: { data: { payload: never } } };
    if (err.response) {
      throw new SubmissionError(err.response.data.payload);
    }
  }
);

export default CommonMsdApi;
