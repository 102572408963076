import { ListBoxItem, Select } from 'opencosmos-ui';
import React, { useEffect, useState } from 'react';
import type { EventProperty } from '../utils/schema';

type Props = {
  schema: EventProperty;
  propertyKey: string;
  populateEventToSubmit: (value: unknown, propertyKey: string) => void;
};

const EventSchemaEnum = ({
  schema,
  populateEventToSubmit,
  propertyKey,
}: Props) => {
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    schema.default
  );

  useEffect(() => {
    populateEventToSubmit(selectedKey, propertyKey);
  }, [populateEventToSubmit, propertyKey, selectedKey]);

  useEffect(() => {
    setSelectedKey(schema.default);
  }, [schema.default]);

  return (
    <div>
      <div className="flex gap-1 items-center">
        <span>{schema.title}</span>
        {schema.isRequired && <span className="text-warning">*</span>}
      </div>
      <Select
        selectedItemProps={{
          className: 'border-2 border-neutral-600',
        }}
        fill
        selectedKey={selectedKey}
        onSelectionChange={(k) => setSelectedKey(k.toString())}
      >
        {schema.enum?.map((val) => (
          <ListBoxItem key={val} id={val}>
            <span>{val}</span>
          </ListBoxItem>
        ))}
      </Select>
    </div>
  );
};

export default EventSchemaEnum;
