import type { GetFiringAlertsParams, GetSilencesParams } from './service';

export const formatGetSilencesUrl = (params: GetSilencesParams) => {
  const {
    active,
    // Default alertName to empty string
    alertName,
    inhibited,
    missionId,
    silenced,
  } = params;

  // No mission id provided, return all silences
  if (!missionId) {
    return '/alertmanager/silences';
  }

  // Add parameters manually because %3D (or =) does not encode easily
  let url = `/alertmanager/silences?filter=mission%3D%22${missionId}%22`;

  if (alertName) {
    // Add parameters manually because %3D (or =) does not encode easily
    url += `&filter=alertname%3D%22${alertName}%22`;
  }

  if (silenced) {
    url += `&silenced=${silenced}`;
  }

  if (inhibited) {
    url += `&inhibited=${inhibited}`;
  }

  if (active) {
    url += `&active=${active}`;
  }

  return url;
};

export const formatGetFiringAlertsUrl = (params: GetFiringAlertsParams) => {
  const { active, currentMissionId, inhibited, silenced } = params;

  if (!currentMissionId) {
    return '/alertmanager/alerts/groups';
  }

  // Add parameters manually because %3D (or =) does not encode easily
  let url = `/alertmanager/alerts/groups?filter=mission%3D%22${currentMissionId}%22`;

  if (silenced) {
    url += `&silenced=${silenced}`;
  }

  if (inhibited) {
    url += `&inhibited=${inhibited}`;
  }

  if (active) {
    url += `&active=${active}`;
  }

  return url;
};
