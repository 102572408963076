import type { IAstrum } from '../../../constants/austrum/interfaces';
import { ASTRUMS } from '../../../constants/API/constant';
import type { IContextMenuSub } from '../../../services/mapService/contextMenu/map/services';

interface IDefinedAstrums {
  [key: string]: IAstrum & IContextMenuSub;
}

const definedOption = {
  minZoom: 3,
  maxZoom: 15,
};

export const definedMapTypes: IDefinedAstrums = {
  ['default']: {
    planet: ASTRUMS.EARTH,
    name: 'default',
    mapOption: { ...definedOption },
    text: 'Default',
    icon: 'style',
  },
  ['hybrid']: {
    planet: ASTRUMS.EARTH,
    name: 'hybrid',
    mapOption: { ...definedOption },
    text: 'Hybrid',
    icon: 'globe',
  },
  ['roadmap']: {
    planet: ASTRUMS.EARTH,
    name: 'roadmap',
    mapOption: { ...definedOption },
    text: 'Roadmap',
    icon: 'drive-time',
  },
  ['satellite']: {
    planet: ASTRUMS.EARTH,
    name: 'satellite',
    mapOption: { ...definedOption },
    text: 'Satellite',
    icon: 'satellite',
  },
  ['terrain']: {
    planet: ASTRUMS.EARTH,
    name: 'terrain',
    mapOption: { ...definedOption },
    text: 'Terrain',
    icon: 'path-search',
  },
};
