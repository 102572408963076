import circle from '@turf/circle';
import type { Coordinates } from './coordinatesToGeoJson';
import bboxPoly from '@turf/bbox-polygon';

/**
 * parseAsPoint takes a coordinates array containing one element (a point)
 * and parses it into a small circle with the radius of 3km.
 * This is done to enable searching by "point" in DataCosmos which does not support
 * `Point` GeoJSON geometry
 * @param coordinates coordinate array containing one element
 * @returns circle polygon
 */
export const parseAsPoint = (coordinates: Coordinates[]) => {
  if (coordinates.length > 1) {
    return undefined;
  }

  const center: GeoJSON.Feature<GeoJSON.Point> = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [coordinates[0].lng, coordinates[0].lat],
    },
    properties: {},
  };

  return circle(center, 3, { units: 'kilometers' });
};

/**
 * parseAsPolygon takes a coordinates array containing
 * and parses it into a GeoJSON polygon
 * @param coordinates coordinate array
 * @returns a GeoJSON polygon
 */
export const parseAsPolygon = (coordinates: Coordinates[]) => {
  // Polygon is consisted of at least 3 coordinate pairs (a triangle)
  if (coordinates.length < 3) {
    return undefined;
  }

  const polygon: GeoJSON.Feature<GeoJSON.Polygon> = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [coordinates.map((c) => [c.lng, c.lat])],
    },
    properties: {},
  };

  return polygon;
};

/**
 * parseAsBbox takes a coordinate array
 * and parses it into a polygon representing a bbox, or throws errors if
 * the bbox is invalid
 * @param coordinates coordinate array
 * @returns bbox as a GeoJSON polygon
 */
export const parseAsBbox = (coordinates: Coordinates[]) => {
  if (coordinates.length !== 2) {
    return undefined;
  }

  if (coordinates[0].lng < coordinates[1].lng) {
    throw new Error(
      'Not a valid bbox - first coordinate must be greater than the third'
    );
  }

  if (coordinates[0].lat < coordinates[1].lat) {
    throw new Error(
      'Not a valid bbox - second coordinate must be greater than the fourth'
    );
  }

  return bboxPoly([
    coordinates[0].lng,
    coordinates[0].lat,
    coordinates[1].lng,
    coordinates[1].lat,
  ]);
};
