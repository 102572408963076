import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import type { Activity, ActivityParameters } from '_api/activities/types';
import type { CommonOpportunity, SwathControlData } from '_api/tasking/helpers';
import type { ActivityPriority, TaskingRequest } from '_api/tasking/types';
import moment from 'moment';
import {
  jsDateToMomentObj,
  momentObjToJsDate,
  momentObjToUnixMs,
  roundToNearestSecond,
  unixMsToMomentObj,
} from 'utils/common/dateUtils';
//TODO: Replace with the actual call to mission configuration service when it becomes available
import missionConfigProd from '__mock__/mock_mission_configs_production.json';
import missionConfigTest from '__mock__/mock_mission_configs_test.json';
import type { MissionConfiguration } from '_api/missionConfig/types';
import { useMemo } from 'react';
import { isDevMode } from 'utils/common/CommonUtils';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import AdvancedSwathControl from './AdvancedSwathControl';
import parseRefDefinitions from 'pages/ops/RTI/Operate/utils/rtiCommands/parseRefDefinitions';
import getFieldsBySchema from 'pages/ops/RTI/Operate/utils/rtiCommands/getFieldsBySchema';
import type { CommandProperties } from '_api/gateway/types';
import Select from 'opencosmos-ui/src/core/Select/Select';
import { useQuery } from '_api/useQuery';
import { getActivityParameters } from '_api/activities/service';
import { ListBoxItem, Tooltip } from 'opencosmos-ui';

//TODO: Replace with the actual call to mission configuration service when it becomes available
const getMissionConfig = (missionId: string) => {
  let conf = missionConfigProd as MissionConfiguration;

  if (isDevMode()) {
    conf = missionConfigTest as MissionConfiguration;
  }

  return conf.configs.find((c) => c.core.mission_id === missionId);
};

function hasProperty<T extends object, K extends string>(
  obj: T,
  key: K
): obj is T & Record<K, unknown> {
  return key in obj;
}
type Props = {
  swath: CommonOpportunity | Activity;
  opportunity: CommonOpportunity | TaskingRequest | undefined;
  swathControlData: SwathControlData | undefined;
  setSwathControlData: (controlData: SwathControlData) => void;
  isPrioritySchedulingAvailable?: boolean;
};

type RollSteeringLimits = {
  min: number;
  max: number;
};

type CommonMetadata = {
  rollSteeringLimits: RollSteeringLimits;
  startDateString: string;
  endDateString: string;
  availablePriorities: {
    base_price_per_km2_gbp: string;
    priorities: ActivityPriority[];
  };
};

const swathIsCommonOpportunity = (
  swath: object | undefined
): swath is CommonOpportunity => {
  return swath !== undefined && hasProperty(swath, 'Id');
};

const aoiIsCommonOpportunity = (
  aoi: object | undefined
): aoi is CommonOpportunity => {
  return aoi !== undefined && hasProperty(aoi, 'Id');
};

const aoiIsTaskingRequest = (
  aoi: object | undefined
): aoi is TaskingRequest => {
  return aoi !== undefined && hasProperty(aoi, 'id');
};

const swathIsActivity = (swath: object | undefined): swath is Activity => {
  return swath !== undefined && hasProperty(swath, 'id');
};

export const SwathControl = ({
  opportunity,
  setSwathControlData,
  swath,
  swathControlData,
  isPrioritySchedulingAvailable,
}: Props) => {
  const duration = [
    momentObjToUnixMs(jsDateToMomentObj(swathControlData?.duration.start)),
    momentObjToUnixMs(jsDateToMomentObj(swathControlData?.duration.end)),
  ];

  const config = swathIsCommonOpportunity(swath)
    ? undefined
    : getMissionConfig(swath.mission_id);

  const schema = aoiIsCommonOpportunity(opportunity) && opportunity.Schema;

  const editSchemaDefaultValues =
    aoiIsTaskingRequest(opportunity) &&
    swathIsActivity(swath) &&
    swathControlData?.parameters;

  const shouldFetchEditSchema =
    aoiIsTaskingRequest(opportunity) && swathIsActivity(swath);

  const editSchema = useQuery(getActivityParameters, {
    skip: !shouldFetchEditSchema || !swath.mission_id,
    params: {
      missionId: (swath as Activity).mission_id,
    },
    initialData: undefined,
  }).data?.activity_parameters;

  const { translate } = useLocalisation();

  const getRollLimits = (): RollSteeringLimits => {
    if (swathIsCommonOpportunity(swath)) {
      return {
        min: swath.RollSteering[0],
        max: swath.RollSteering[1],
      };
    }

    if (config) {
      return {
        min: config.platform.roll_steering[0],
        max: config.platform.roll_steering[1],
      };
    }

    return { min: 0, max: 0 };
  };

  const metadata = useMemo<CommonMetadata | undefined>(() => {
    if (
      aoiIsCommonOpportunity(opportunity) &&
      swathIsCommonOpportunity(swath)
    ) {
      return {
        rollSteeringLimits: {
          min: swath.RollSteering[0],
          max: swath.RollSteering[1],
        },
        endDateString: opportunity.End,
        startDateString: opportunity.Start,
        availablePriorities: opportunity.AvailablePriorities,
      } as CommonMetadata;
    }

    if (aoiIsTaskingRequest(opportunity) && swathIsActivity(swath)) {
      return {
        rollSteeringLimits: {
          min: config?.platform.roll_steering[0],
          max: config?.platform.roll_steering[1],
        },
        endDateString: swath.end_date,
        startDateString: swath.start_date,
      } as CommonMetadata;
    }

    return undefined;
  }, [config?.platform.roll_steering, opportunity, swath]);

  return (
    <div className="mt-2">
      {metadata?.availablePriorities?.priorities && (
        <div className="px-2 mb-2">
          <Tooltip
            content={'Highest priority activity is already scheduled'}
            placement="top"
            isDisabled={isPrioritySchedulingAvailable}
          >
            <div className="block w-full">
              <div className="text-center font-bold"> Priority</div>
              <Select
                selectedKey={swathControlData?.priority?.name}
                onSelectionChange={(item) => {
                  const selection =
                    metadata?.availablePriorities?.priorities.find(
                      (s) => s?.name === item
                    );
                  swathControlData &&
                    setSwathControlData({
                      ...swathControlData,
                      priority: selection,
                    });
                }}
                placeholder="Select priority"
                className="font-sm"
                fill
                isDisabled={!isPrioritySchedulingAvailable}
              >
                {metadata.availablePriorities?.priorities?.map((item) => (
                  <ListBoxItem id={item.name} key={item.name}>
                    {item.name}
                  </ListBoxItem>
                ))}
              </Select>
            </div>
          </Tooltip>
        </div>
      )}
      <table className="w-full">
        <colgroup>
          <col style={{ width: '20%' }}></col>
          <col style={{ width: '60%%' }}></col>
          <col style={{ width: '20%' }}></col>
        </colgroup>
        <thead>
          <tr>
            <th className="text-center"></th>
            <th className="text-center">
              {translate('datacosmos.tasking.swathControl.duration', {
                duration: moment
                  .utc(
                    moment
                      .unix(duration[1] / 1000)
                      .diff(moment.unix(duration[0] / 1000))
                  )
                  .format('m[m] ss[s]'),
              })}
            </th>
            <th className="text-center">
              {translate('datacosmos.tasking.swathControl.start')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">
              {' '}
              {moment.utc(metadata?.startDateString).format('HH:mm:ss')}
            </td>
            <td>
              <RangeSlider
                numberOfHandles={2}
                step={1000}
                minValue={moment(metadata?.startDateString)
                  .utc()
                  .subtract(6, 'seconds')
                  .valueOf()}
                maxValue={moment(metadata?.endDateString)
                  .utc()
                  .add(6, 'seconds')
                  .valueOf()}
                value={duration}
                onChange={(value) => {
                  swathControlData &&
                    setSwathControlData({
                      ...swathControlData,
                      duration: {
                        start: momentObjToJsDate(unixMsToMomentObj(value[0])),
                        end: momentObjToJsDate(unixMsToMomentObj(value[1])),
                      },
                    });
                }}
                showValuesAboveHandles
                handleLabelFormatter={(value) => {
                  return moment(roundToNearestSecond(moment.unix(value / 1000)))
                    .utc()
                    .format('mm:ss');
                }}
                showScale={false}
              />
            </td>
            <td className="text-center">
              {moment.utc(metadata?.endDateString).format('HH:mm:ss')}
            </td>
          </tr>
        </tbody>
      </table>

      <table className="w-full">
        <colgroup>
          <col style={{ width: '20%' }}></col>
          <col style={{ width: '60%' }}></col>
          <col style={{ width: '20%' }}></col>
        </colgroup>
        <thead>
          <tr>
            <th className="text-center"></th>
            <th className="text-center">
              {translate('datacosmos.tasking.swathControl.rollAngle')}
            </th>
            <th className="text-center"></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="text-center">
              {translate('datacosmos.tasking.swathControl.min', {
                min: metadata?.rollSteeringLimits.min,
              })}
            </td>
            <td>
              <RangeSlider
                numberOfHandles={1}
                step={0.1}
                minValue={getRollLimits().min}
                maxValue={getRollLimits().max}
                value={[swathControlData?.rotation ?? 0]}
                showValuesAboveHandles
                showScale={false}
                onChange={(value) => {
                  swathControlData &&
                    setSwathControlData({
                      ...swathControlData,
                      rotation: value[0],
                      parameters: {
                        ...swathControlData.parameters,
                        platform: {
                          roll_angle: value[0],
                        },
                      },
                    });
                }}
              />
            </td>
            <td className="text-center">
              {translate('datacosmos.tasking.swathControl.max', {
                max: metadata?.rollSteeringLimits.max,
              })}
            </td>
          </tr>
        </tbody>
      </table>

      {schema && (
        <div className="w-full mb-1">
          <AdvancedSwathControl
            swathControlData={swathControlData}
            handleOptionsChange={(item) => {
              swathControlData &&
                setSwathControlData({
                  ...swathControlData,
                  parameters: {
                    ...item,
                  } as ActivityParameters,
                });
            }}
            schema={getFieldsBySchema(
              parseRefDefinitions(schema.IMAGE_ACQUISITION) as CommandProperties
            )}
            defaultValues={{
              platform: {
                roll_angle: swathControlData?.rotation ?? 0,
              },
              imager: {
                name: opportunity.SensorId,
              },
            }}
          />
        </div>
      )}

      {editSchema && editSchemaDefaultValues && (
        <div className="w-full mb-1">
          <AdvancedSwathControl
            swathControlData={swathControlData}
            handleOptionsChange={(item) => {
              swathControlData &&
                setSwathControlData({
                  ...swathControlData,
                  parameters: {
                    ...item,
                  } as ActivityParameters,
                });
            }}
            schema={getFieldsBySchema(
              parseRefDefinitions(
                editSchema.IMAGE_ACQUISITION
              ) as CommandProperties
            )}
            defaultValues={editSchemaDefaultValues}
          />
        </div>
      )}
    </div>
  );
};
