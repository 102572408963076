import classNames from 'classnames';
import { Icon } from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';

export interface IListItemProps {
  icon?: IconName;
  iconStroke?: string;
  content: string | null | undefined;
  noTruncate?: true;
  className?: string;
  onClick: () => void;
  active?: boolean;
}

const ListItem = (props: IListItemProps) => {
  return (
    <li
      className={classNames(props.className, 'flex items-center gap-2', {
        'dark:text-accent text-accent-900': props.active,
      })}
      onClick={() => props.onClick()}
    >
      {props.icon && <Icon icon={props.icon} />}
      {props.content && (
        <span
          className={classNames({
            'truncate max-w-label w-min overflow-ellipsis': !props.noTruncate,
          })}
          dangerouslySetInnerHTML={{ __html: props.content }}
        ></span>
      )}
    </li>
  );
};

export default ListItem;
