import React from 'react';
import { debounce } from 'lodash';
import type { ColorResult } from 'react-color';
import { SketchPicker } from 'react-color';
import Popover, { Direction } from '_atoms/Popover/Popover';

interface IProps {
  /**
   * In the HEX RRGGBBAA format, including the alpha code
   */
  color: string;
  disabled: boolean;
  /**
   * Color is in the HEX RRGGBBAA format, including the alpha code
   */
  onChange: (newColor: string) => void;
}

/**
 * ColorControl renders a color button that, when clicked, displays a color
 * selection dialog. The onChange handler is debounced to improve performance.
 *
 * The color values use the HEX RRGGBBAA format, which includes an alpha code
 * at the end.
 */
const ColorControl = ({ color, disabled, onChange }: IProps) => {
  const debouncedOnChange = debounce((value: ColorResult): void => {
    const colorCode = value.hex;
    onChange(colorCode);
  }, 200);

  return (
    <Popover
      direction={Direction.BottomLeft}
      disabled={disabled}
      content={
        <SketchPicker
          className="bg-surface dark:bg-surface-dark dark:text-item-dark-contrast"
          color={color}
          onChangeComplete={(value) => {
            debouncedOnChange(value);
          }}
          disableAlpha
        />
      }
      outsideClickClose
    >
      <div
        className="h-5 w-5 mt-1 rounded-full"
        style={{ backgroundColor: disabled ? '#8c8c8c' : color }}
      ></div>
    </Popover>
  );
};

export default ColorControl;
