// TODO: Deprecate this in favour of NewTextField and NewTextFieldWithLabel
/* Reason is to move towards using labels instead of textboxes
   for the left handside of all text input areas*/
import { Classes, H6 } from '@blueprintjs/core';
import type { ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { WrappedFieldProps } from 'redux-form';

interface ITextFieldProps extends WithTranslation, WrappedFieldProps {
  type: string;
  disable?: boolean;
}

function renderField(field: ITextFieldProps): ReactElement {
  const { input, meta, type, t, disable } = field;
  const iconStyle = [Classes.ICON, 'bp4-icon-text-highlight'].join(' ');
  const titleStyle = [
    'bp4-input-group',
    meta.error ? Classes.INTENT_DANGER : '',
  ].join(' ');
  const errorDiv = meta.error ? (
    <div className="bp4-callout bp4-intent-danger bp4-icon-error">
      <H6 className="bp4-heading">
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          t(`validation.errors.${meta.error as string}` as any)
        }
      </H6>
    </div>
  ) : null;
  return (
    <div className="bp4-input-group bp4-vertical field-row">
      <div className="bp4-control-group bp4-fill">
        <div className={`${titleStyle} left`}>
          <div className="bp4-input bp4-overflow-list-spacer">
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              t(`validation.text-field.names.${input.name}` as any)
            }
          </div>
          <span className={iconStyle} />
        </div>
        <div className={`${titleStyle} right`}>
          <input
            className="bp4-input"
            readOnly={disable}
            type={type}
            placeholder={
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
              t(`validation.text-field.names.${input.name}` as any)
            }
            value={input.value as string}
            onChange={input.onChange}
          />
        </div>
      </div>
      {errorDiv}
    </div>
  );
}

export default withTranslation()(renderField);
