/**
 * https://developers.google.com/maps/documentation/javascript/reference/polygon?hl=ru#PolygonOptions
 */

export const regionOfInterestDefine = (): google.maps.PolygonOptions => {
  return {
    editable: false,
    draggable: false,
    strokeColor: '#FF0000',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: '#aaaaaa',
    fillOpacity: 0.3,
    geodesic: true,
  };
};

export const regionOfInterestFocused = (): google.maps.PolygonOptions => {
  return {
    editable: true,
    draggable: false,
    strokeColor: '#FF0000',
    strokeOpacity: 0.5,
    strokeWeight: 1,
    fillColor: '#32c200',
    fillOpacity: 0.3,
    geodesic: true,
  };
};

export const supportingPolygonDefine = (): google.maps.PolygonOptions => {
  return {
    editable: false,
    draggable: false,
    strokeColor: '#646464',
    strokeOpacity: 0.5,
    strokeWeight: 1,
    fillColor: '#ff0000',
    fillOpacity: 0.3,
    geodesic: true,
  };
};
