import type { IGroundStation } from '../../../constants/groundStations/actionTypes';
import type { IMapWrapper } from '../../../declarations/mapDeclarations/Map';
import type { IMarkerWrapper } from '../../../declarations/mapDeclarations/Marker';

export const addGroundStation = (
  groundStationData: IGroundStation,
  map: IMapWrapper,
  options?: google.maps.MarkerOptions
): IMarkerWrapper => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { id, lat, lon } = groundStationData;
  const groundStation: IMarkerWrapper = new google.maps.Marker({
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    id,
    position: { lat, lng: lon },
    map,
    ...options,
  });
  if (map) {
    map.groundStations?.push(groundStation);
    return groundStation;
  }
  //@ts-expect-error
  return undefined;
};

export const updateGroundStation = (
  groundStationData: IGroundStation,
  groundStation: IMarkerWrapper,
  map: IMapWrapper,
  options?: google.maps.MarkerOptions
): boolean => {
  const { name, lat, lon } = groundStationData;
  groundStation.name = name;
  const position = new google.maps.LatLng(lat, lon);
  groundStation.setPosition(position);
  if (options) {
    groundStation.setOptions(options);
  }
  return true;
};

export const removeGroundStation = (
  groundStationIndex: number,
  map: IMapWrapper
): void => {
  const groundStation = map.groundStations?.[groundStationIndex];
  groundStation?.setMap(null);
  map.groundStations?.splice(groundStationIndex, 1);
};
