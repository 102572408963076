import type { WrappedFieldArrayProps } from 'redux-form';
import { Fields } from 'redux-form';
import InputPointField from './PointField';
import { Collapse, H6, Pre } from '@blueprintjs/core';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { FunctionComponent, ReactElement, MouseEventHandler } from 'react';
import React, { useEffect, useState } from 'react';

interface IRenderPath extends WithTranslation, WrappedFieldArrayProps {
  removeRegion: MouseEventHandler;
  pathIndex: number;
  isMinPaths: boolean;
  isParentOpen: boolean;
}

const RenderPath = (props: IRenderPath): ReactElement => {
  const [isOpen, setOpen] = useState(false);
  const switchIsOpen = (): void => setOpen(!isOpen);
  const setIsOpenTrue = (): void => setOpen(true);
  const { fields, meta, isParentOpen, removeRegion, pathIndex, isMinPaths, t } =
    props;
  useEffect((): void => {
    if (isParentOpen) {
      setIsOpenTrue();
    }
  }, [isParentOpen]);
  const { error } = meta as { error: string };

  const minPoints = 3;
  const isMinPoints = fields.length <= minPoints;
  if (fields.length < minPoints) {
    fields.push({ lat: 0, lng: 0 });
    return <></>;
  }
  const addPoint = (): void => {
    fields.push({ lat: 0, lng: 0 });
    setIsOpenTrue();
  };
  const preStyle = error ? 'pre-error' : '';
  const renderPoints = fields.map((point, index): ReactElement => {
    const removePoint = (): void => fields.remove(index);
    return (
      <div
        key={point}
        className="bp4-control-group point-row bp4-intent-danger"
      >
        <Fields
          names={[`${point}.lat`, `${point}.lng`]}
          component={InputPointField}
        />
        <button
          className="bp4-button bp4-icon-remove bp4-intent-danger"
          type="button"
          disabled={isMinPoints}
          onClick={removePoint}
        />
      </div>
    );
  });

  return (
    <div>
      <div className="bp4-control-group ">
        <div className="bp4-input-group bp4-fill">
          <div
            className={`bp4-input-group ${error ? 'bp4-intent-danger' : ''}`}
          >
            <div className="bp4-input">{`${t(
              'validation.path-field.region'
            )} # ${pathIndex}`}</div>
            <div className="bp4-input-action">
              {/* eslint-disable-next-line */}
              <span
                className={`bp4-button bp4-minimal  ${
                  isOpen ? 'bp4-icon-arrow-up' : 'bp4-icon-arrow-down'
                }`}
                onClick={switchIsOpen}
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          className="bp4-button bp4-icon-add bp4-intent-primary"
          onClick={addPoint}
        />
        <button
          type="button"
          disabled={isMinPaths}
          className="bp4-button bp4-icon-remove bp4-intent-danger"
          onClick={removeRegion}
        />
      </div>
      <Collapse isOpen={isOpen} keepChildrenMounted={true}>
        <Pre className={preStyle}>
          <div className="bp4-control-group bp4-fill">
            <H6>{t('validation.point.lat')}</H6>
            <H6>{t('validation.point.lng')}</H6>
          </div>
          {renderPoints}
        </Pre>
      </Collapse>
    </div>
  );
};

export default withTranslation()(RenderPath) as FunctionComponent;
