import Icon from 'opencosmos-ui/src/icons/Icon';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import { useLocalisation } from 'utils/hooks/useLocalisation';
interface IResourceHeaderProps {
  title: string;
  description?: string;
  icon: IconName;
}

const ResourceHeader = (props: IResourceHeaderProps) => {
  const { translate } = useLocalisation();
  return (
    <div className="flex flex-col gap-1">
      <div className="mt-2 flex items-center gap-2">
        <Icon icon={props.icon} size={32} />
        <div className="font-bold text-lg">{props.title}</div>
      </div>
      {props.description && (
        <div className="text-sm mt-3">
          <span className="font-bold">
            {translate('datacosmos.resources.common.description')} :
          </span>{' '}
          {props.description}
        </div>
      )}
    </div>
  );
};

export default ResourceHeader;
