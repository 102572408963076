import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

export type IActivePageProviderContext = ReturnType<
  typeof useActivePageProvider
>;

export const ActivePageContext = createContext<IActivePageProviderContext>(
  null as unknown as IActivePageProviderContext
);

export const useActivePage = () =>
  useContext<IActivePageProviderContext>(ActivePageContext);

const useActivePageProvider = () => {
  const [activePage, setActivePage] = useState<string>();
  const { isLayersMenuOpen } = useMapLayers();
  const [isAlgebraMenuOpen, setAlgebraMenuOpen] = useState<boolean>(false);
  const [isLayerToolsOpen, setIsLayerToolsOpen] = useState<boolean>(false);
  const { selectedInstalledApp } = useApplicationCatalog();

  return {
    setActivePage,
    activePage,
    leftMenuOpen:
      activePage === 'catalog' ||
      activePage === 'tasking' ||
      activePage === 'items',
    rightMenuOpen:
      activePage === 'application' ||
      isLayersMenuOpen ||
      isAlgebraMenuOpen ||
      isLayerToolsOpen ||
      Boolean(selectedInstalledApp),
    setAlgebraMenuOpen,
    isAlgebraMenuOpen,
    isLayerToolsOpen,
    setIsLayerToolsOpen,
  };
};

const ActivePageProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ActivePageContext.Provider value={useActivePageProvider()}>
      {children}
    </ActivePageContext.Provider>
  );
};

export default ActivePageProvider;
