import type {
  IUserState,
  userTypes,
} from '../../../../constants/user/actionTypes';
import { USER_SET } from '../../../../constants/user/actionTypes';

const initialState: IUserState | null = null;

export default function userInformationReducer(
  state: IUserState | null = initialState,
  action: userTypes
): IUserState | null {
  switch (action.type) {
    case USER_SET: {
      return {
        ...action.userInformation,
      };
    }
    default: {
      return state;
    }
  }
}
