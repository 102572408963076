import React from 'react';
import {
  AnchorButton,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverPosition,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface IProps {
  handleRenameSessionClick: () => void;
  handleDeleteSessionClick: () => void;
  handleChangeUserAccessibleClick: () => void;
  userAccessible: boolean;
  writeAllSessionsPermission: boolean;
}

const SessionSettingPopover = (props: IProps) => (
  <Popover position={PopoverPosition.BOTTOM}>
    <AnchorButton icon={IconNames.SETTINGS} rightIcon={IconNames.CARET_DOWN} />
    <Menu>
      <MenuItem
        text="Rename"
        icon={IconNames.EDIT}
        onClick={props.handleRenameSessionClick}
      />
      {props.writeAllSessionsPermission && (
        <MenuItem
          text={`Make ${
            props.userAccessible ? 'in' : ''
          }accessible to customer`}
          icon={props.userAccessible ? IconNames.EYE_OFF : IconNames.EYE_OPEN}
          onClick={props.handleChangeUserAccessibleClick}
        />
      )}
      <MenuDivider />
      <MenuItem
        text="Delete"
        intent={Intent.DANGER}
        icon={IconNames.TRASH}
        onClick={props.handleDeleteSessionClick}
      />
    </Menu>
  </Popover>
);

export default SessionSettingPopover;
