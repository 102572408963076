import type { IPayload } from '../../moduleData/types';
import type {
  ISatelliteModules,
  ISatelliteModuleType,
} from '../../satellite/types';

export const MODULE_LOAD = 'MODULE_LOAD';
export const MODULE_CLEAR = 'MODULE_CLEAR';
export const PAYLOAD_UPDATE = 'PAYLOAD_UPDATE';
export const ADD_CUSTOM_PAYLOAD = 'ADD_CUSTOM_PAYLOAD';
export const REMOVE_CUSTOM_PAYLOAD = 'REMOVE_CUSTOM_PAYLOAD';
export const UPDATE_CUSTOM_PAYLOAD = 'UPDATE_CUSTOM_PAYLOAD';
export const SELECT_PAYLOAD = 'SELECT_PAYLOAD';
export const UNSELECT_PAYLOAD = 'UNSELECT_PAYLOAD';
export const CONFIRM_PAYLOAD_SELECTION = 'CONFIRM_PAYLOAD_SELECTION';
export const CANCEL_PAYLOAD_SELECTION = 'CANCEL_PAYLOAD_SELECTION';

export interface IModuleLoadActionProps {
  moduleName: ISatelliteModuleType;
  satelliteModules: ISatelliteModules;
  multipleSelectable: boolean;
}

export interface IModuleLoadAction {
  type: typeof MODULE_LOAD;
  payload: IModuleLoadActionProps;
}

export interface IModuleClearAction {
  type: typeof MODULE_CLEAR;
}

export interface IPayloadUpdateAction {
  type: typeof PAYLOAD_UPDATE;
  payloadName: string;
}

export interface IAddCustomPayloadAction {
  type: typeof ADD_CUSTOM_PAYLOAD;
  payload: IPayload;
}

export interface IUpdateCustomPayloadAction {
  type: typeof UPDATE_CUSTOM_PAYLOAD;
  payload: IPayload;
  oldName: string;
}

export interface IRemoveCustomPayloadAction {
  type: typeof REMOVE_CUSTOM_PAYLOAD;
  payloadName: string;
}

export interface ISelectPayloadAction {
  type: typeof SELECT_PAYLOAD;
  payloadName: string;
}

export interface IUnselectPayloadAction {
  type: typeof UNSELECT_PAYLOAD;
  payloadName: string;
}

export interface IConfirmPayloadSelectionAction {
  type: typeof CONFIRM_PAYLOAD_SELECTION;
}

export interface ICancelPayloadSelectionAction {
  type: typeof CANCEL_PAYLOAD_SELECTION;
}

export type IModuleSelectorActionTypes =
  | IModuleLoadAction
  | IModuleClearAction
  | IPayloadUpdateAction
  | IAddCustomPayloadAction
  | IUpdateCustomPayloadAction
  | IRemoveCustomPayloadAction
  | ISelectPayloadAction
  | IUnselectPayloadAction
  | IConfirmPayloadSelectionAction
  | ICancelPayloadSelectionAction;
