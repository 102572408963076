import { Icon, type IconName } from 'opencosmos-ui/src/icons/Icon';
import { Text } from 'ui/Text';

export type EmptyMessageProps = {
  title: string;
  icon?: IconName;
};

/*
 * A placeholder component used to display a message when there is no data to present
 */
export const EmptyMessage = ({ title, icon }: EmptyMessageProps) => {
  return (
    <div className="flex flex-col items-center justify-center mt-5">
      {icon && <Icon icon={icon} size={32} />}
      <div className="flex justify-center w-full text-center">
        <Text className="text-lg" breakLine>
          {title}
        </Text>
      </div>
    </div>
  );
};
