import classNames from 'classnames';
import { zIndexValues } from 'opencosmos-ui/constants';
import React, { useRef } from 'react';
import { DismissButton, FocusScope, useOverlay } from 'react-aria';

export type IOverlayProps = {
  overlayRef?: React.RefObject<HTMLDivElement>;
  children: JSX.Element;
  isOpen?: boolean;
  onClose: () => void;
  className?: string;
  style?: React.CSSProperties;
  isDismissable?: boolean;
  shouldCloseOnBlur?: boolean;
  shouldCloseOnInteractOutside?: (element: HTMLElement) => boolean;
  isKeyboardDismissDisabled?: boolean;
};

export const Overlay = (props: IOverlayProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const overlayRef = props.overlayRef ?? ref;

  const { overlayProps } = useOverlay(
    {
      isDismissable: props.isDismissable,
      shouldCloseOnBlur: props.shouldCloseOnBlur,
      isOpen: props.isOpen,
      onClose: props.onClose,
      shouldCloseOnInteractOutside: (element) =>
        props.shouldCloseOnInteractOutside?.(element as HTMLElement) ?? false,
      isKeyboardDismissDisabled: props.isKeyboardDismissDisabled,
    },
    overlayRef
  );

  return (
    <FocusScope restoreFocus>
      <div
        ref={overlayRef}
        className={classNames(
          props.className,
          'absolute shadow-lg border border-neutral-300 color-surface rounded-md mt-2',
          ' dark:border-none overflow-hidden',
          {
            'top-full': !props.style,
          }
        )}
        {...overlayProps}
        style={{ zIndex: zIndexValues.default, ...props.style }}
      >
        {props.children}
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
};

export default Overlay;
