import classNames from "classnames";
import React, { useState } from "react";
import { Button, Tooltip } from "..";
import type { ButtonIconSize } from "../Button/Button";

type Props = {
  /**
   * The function to call when the user clicks the card.
   */
  onClick: React.MouseEventHandler<HTMLDivElement>;
  /**
   * The content to display on the collapsed card.
   * This is the text shown before the user expands the card.
   */
  cardContent: JSX.Element | JSX.Element[];
  /**
   * The information to display in the card.
   * Consists of a text and an icon.
   * The icon size is set by the `iconSize` prop.
   */
  info: {
    text?: string;
    iconSize: ButtonIconSize;
  };
  /**
   * Whether the information should be shown on hover or on click.
   */
  showInfoOn: "hover" | "click";
  className?: string;
  /**
   * Whether the card should be positioned absolutely.
   *
   */
  absoluteInfoPosition?: true;
};

const InfoCard = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <div
      className={classNames(
        props.className,
        "flex flex-col mb-px p-2 cursor-pointer group",
        "hover:bg-item-hover hover:dark:bg-item-dark-hover hover:dark:text-item-dark-contrast"
      )}
      onClick={props.onClick}
    >
      <div className="flex gap-1 items-center">
        {props.cardContent}
        {props.info.text && (
          <Tooltip
            content={props.info.text}
            isDisabled={props.showInfoOn === "click" && !isExpanded}
          >
            <Button
              icon="Info"
              size={props.info.iconSize}
              onPress={() => {
                props.showInfoOn === "click" && setIsExpanded(!isExpanded);
              }}
              isMinimal
              isTransparent
              className={"mt-1"}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default InfoCard;
