import type { ReactElement } from 'react';
import React from 'react';

/**
 * Displayed when there is no matching route
 */
const NotFound = (): ReactElement => (
  <div className="notfound">
    <h1 className="h1">We could not find the page you were looking for</h1>
  </div>
);

export default NotFound;
