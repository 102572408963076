import type { IMapTypeOptions } from '../registerMapType';
import { moon } from './moon';
// import { mars } from './mars';
// import { earth } from './earth';

//TODO enable/disable MapTypes
export const MapTypes: { [key: string]: IMapTypeOptions } = {
  // [earth.name]: earth,
  [moon.name]: moon,
  //[mars.name]: mars,
};
