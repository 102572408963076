import { DRAGGABLE_HEADER } from '../../constants/draggable/constants';

export function dragElement(element: HTMLElement): void {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;

  function elementDrag(event: MouseEvent): void {
    pos1 = pos3 - event.clientX;
    pos2 = pos4 - event.clientY;
    pos3 = event.clientX;
    pos4 = event.clientY;
    element.style.top = element.offsetTop - pos2 + 'px';
    element.style.left = element.offsetLeft - pos1 + 'px';
  }

  function closeDragElement(): void {
    document.onmouseup = null;
    document.onmousemove = null;
  }

  function dragMouseDown(event: MouseEvent): void {
    pos3 = event.clientX;
    pos4 = event.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  const draggableHeader = document.getElementById(DRAGGABLE_HEADER);
  if (draggableHeader) {
    draggableHeader.onmousedown = dragMouseDown;
  } else {
    element.onmousedown = dragMouseDown;
  }
}
