export const PLATFORM_SIZE = 'formFactor';
export const PLATFORM_SOLAR_PANEL_CONFIG = 'solarPanelsConfiguration';
export const PLATFORM_SOLAR_PANEL_DEPLOYABLE = 'solarPanelsSize';
export const PLATFORM_SOLAR_PANEL_DIRECTION = 'solarPanelsOrientation';
export const PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE = 'mountedSolarCells';

export type IPlatformCharacteristicType =
  | typeof PLATFORM_SIZE
  | typeof PLATFORM_SOLAR_PANEL_CONFIG
  | typeof PLATFORM_SOLAR_PANEL_DEPLOYABLE
  | typeof PLATFORM_SOLAR_PANEL_DIRECTION
  | typeof PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE;

export interface IPlatformCharacteristics {
  [PLATFORM_SIZE]?: string;
  [PLATFORM_SOLAR_PANEL_CONFIG]?: string;
  [PLATFORM_SOLAR_PANEL_DEPLOYABLE]?: string;
  [PLATFORM_SOLAR_PANEL_DIRECTION]?: string;
  [PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE]?: string;
}
