import { useEffect, useRef, useState } from 'react';
import { Button, Classes, ControlGroup, Intent } from '@blueprintjs/core';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { IconNames } from '@blueprintjs/icons';
import s from 'components/OrganisationConfiguration/index.module.scss';

interface IProps {
  text: string;
  showEditableText: boolean;
  hideEditableText: () => void;
  handleSaveConfirm: (value: string) => void;
}

const OrganisationEditableText = (props: IProps) => {
  const editableNameRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (props.showEditableText) {
      editableNameRef.current?.focus();
    }
  }, [props.showEditableText]);

  const handleCancel = () => {
    props.hideEditableText();
    setValue(undefined);
  };

  const handleConfirm = () => {
    if (!value) {
      showErrorMessage('Cannot create with empty name!');
      handleCancel();
      return;
    }

    props.handleSaveConfirm(value);
    handleCancel();
  };

  return props.showEditableText ? (
    <div className={s.editableTextWrapper}>
      <ControlGroup fill>
        <input
          ref={editableNameRef}
          className={Classes.INPUT}
          placeholder={props.text}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          small
          intent={Intent.SUCCESS}
          icon={IconNames.TICK}
          onClick={handleConfirm}
        />
        <Button
          small
          intent={Intent.DANGER}
          icon={IconNames.CROSS}
          onClick={handleCancel}
        />
      </ControlGroup>
    </div>
  ) : null;
};

export default OrganisationEditableText;
