import axios from 'axios';
import type {
  IAPIAppSubmitWorkflowRequest,
  IAPIApplication,
} from 'datacosmos/types/applications';
import { BACKEND_BASE_URL } from 'env';

export default (token: string | undefined) => {
  const fetchApplications = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.get(
      `${BACKEND_BASE_URL}/data/v0/applications/workflow`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return data.data as IAPIApplication[];
  };

  const submitApplication = async (
    applicationData: IAPIAppSubmitWorkflowRequest
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.post(
      `${BACKEND_BASE_URL}/data/v0/applications/workflow/submit`,
      applicationData,
      {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return data.data;
  };

  const fetchApplicationStatus = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.get(
      `${BACKEND_BASE_URL}/data/v0/applications/workflow/status`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  };

  const fetchApplicationRunStatus = async (scenarioId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.get(
      `${BACKEND_BASE_URL}/data/v0/applications/project/${scenarioId}/workflows`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data?.data;
  };

  return {
    fetchApplications,
    submitApplication,
    fetchApplicationStatus,
    fetchApplicationRunStatus,
  };
};
