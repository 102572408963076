import type { IMission } from '../../services/Missions';

export const MISSION_LOAD = 'MISSION_LOAD';
export const MISSION_ADD = 'MISSION_ADD';
export const MISSION_UPDATE = 'MISSION_UPDATE';
export const MISSION_SET_CURRENT = 'MISSION_SET_CURRENT';

interface IMissionsLoadAction {
  type: typeof MISSION_LOAD;
  missions: IMission[];
}

interface IMissionsAddAction {
  type: typeof MISSION_ADD;
  mission: IMission;
}

interface IMissionsUpdateAction {
  type: typeof MISSION_UPDATE;
  mission: IMission;
}
interface IMissionsSetCurrentction {
  type: typeof MISSION_SET_CURRENT;
  mission: IMission;
}

export type IMissionsActionTypes =
  | IMissionsLoadAction
  | IMissionsAddAction
  | IMissionsUpdateAction
  | IMissionsSetCurrentction;
