import React, { useState } from 'react';
import type { IBreadcrumbProps } from '@blueprintjs/core';
import {
  Breadcrumbs,
  Button,
  InputGroup,
  NonIdealState,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { compact, isNil } from 'lodash';

import s from './index.module.scss';
import fileS from '../FileExplorer/index.module.scss';
import { removeExcessSlash } from 'utils/common/stringUtils';

interface IProps {
  children: JSX.Element;
  name: string; // for testing
  currentService?: string;
  isFetching: boolean;
  failedToFetch: boolean;
  options: { name: string; displayedName: string }[];
  setCurrentService?: (service: string | undefined) => void;
  showDefaultAsNone?: boolean;
  currentPath?: string;
  rootItem?: string;
  setFilePath: (path: string) => void;
}

const ServiceSelector = (props: IProps) => {
  const [currentPathValue, setCurrentPathValue] = useState<string>();
  const [isShowInputFolderPath, setIsShowInputFolderPath] =
    useState<boolean>(false);

  const onBreadcrumbPathInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setCurrentPathValue(inputValue);
  };

  const onFilePathChanged = () => {
    let path = currentPathValue;
    if (!path) return;
    if (!path.endsWith('/')) {
      path += '/';
    }
    props.setFilePath(path);
    setIsShowInputFolderPath(false);
  };

  const createBreadcrumbItems = () => {
    if (!props.currentPath) return [];

    const breadcrumbsItems = compact(props.currentPath.split('/')) || [];

    const rootPath = !isNil(props.rootItem) ? props.rootItem : '/';
    const breadcrumbs = [
      {
        text: props.rootItem ?? 'root',
        onClick: () => {
          setIsShowInputFolderPath(true);
          props.setFilePath(rootPath);
        },
      },
    ] as IBreadcrumbProps[];
    let accumulator = '/';

    breadcrumbsItems.map((item, i) => {
      accumulator += removeExcessSlash(item) + '/';
      const crumbPath = accumulator;
      breadcrumbs.push({
        text: <span>{item}</span>,
        onClick: () => {
          if (breadcrumbsItems.length - 1 === i) {
            setIsShowInputFolderPath(true);
          } else {
            props.setFilePath(crumbPath);
          }
        },
      });
    });

    return breadcrumbs;
  };

  const changeServiceHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!props.setCurrentService) return;
    if (event.target.value === 'nan') {
      props.setCurrentService(undefined);
    } else {
      props.setCurrentService(event.target.value);
    }
  };

  const getFileExplorer = () => {
    if (isNil(props.currentService)) {
      return (
        <NonIdealState
          title="No service selected"
          icon={IconNames.WARNING_SIGN}
        />
      );
    } else if (props.failedToFetch) {
      return (
        <>
          {isShowInputFolderPath ? (
            <InputGroup
              asyncControl={true}
              leftIcon={IconNames.FOLDER_OPEN}
              value={'/' + props.currentPath && props.currentPath}
              rightElement={<Button onClick={onFilePathChanged}>Go</Button>}
              onChange={(e) => onBreadcrumbPathInput(e)}
              onKeyDown={(e) => e.key === 'Enter' && onFilePathChanged()}
            />
          ) : (
            <Breadcrumbs
              className={fileS.breadcrumb}
              minVisibleItems={1}
              items={createBreadcrumbItems()}
            />
          )}
          <NonIdealState
            title="Could not retrieve folder contents"
            icon={IconNames.ERROR}
          />
        </>
      );
    }

    return props.children;
  };

  return (
    <div className={s.satelliteFileExplorer}>
      <div style={{ margin: 5 }}>
        <div className="bp4-select select bp4-fill">
          <select
            name={props.name + '-service'}
            value={props.currentService ?? 'nan'}
            onChange={changeServiceHandler}
            disabled={props.isFetching}
          >
            {props.showDefaultAsNone && (
              <option value="nan">Select service</option>
            )}
            {props.options.map((satelliteOption) => (
              <option key={satelliteOption.name} value={satelliteOption.name}>
                {satelliteOption.displayedName}
              </option>
            ))}
          </select>
        </div>
      </div>
      {getFileExplorer()}
    </div>
  );
};

export default ServiceSelector;
