import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../pages/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import { setFocus } from '../../../pages/shared/state/actions/focus/thunks';
import type { IPointOfInterest } from '../../../constants/pointsOfInterest/actionTypes';
import { FocusTypes } from '../../../constants/focus/actionTypes';

export const setFocusPointOfInterest =
  (
    pointOfInterest: IPointOfInterest
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(
      setFocus({
        type: FocusTypes.PI_SUB_ID,
        id: pointOfInterest.id as string,
        needScroll: true,
      })
    );
  };
