import { hosts } from '_api/hosts';
import type {
  Activity,
  ActivityParameters,
  ActivityStatus,
} from '_api/activities/types';
import { toaster } from 'toaster';
import { apiFetchHandler } from '_api/clientFetch';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import type { JsonSchema } from '_api/telecommands/types';

export * from '_api/activities/types';

export type GetActivitiesByMissionIdParams = {
  missionId: string;
  startDate: string;
  endDate: string;
};

export const getActivitiesByMissionId = apiFetchHandler<
  Activity[],
  GetActivitiesByMissionIdParams
>({
  host: hosts.ops.v1,
  endpoint: ({ missionId, startDate, endDate }) =>
    `/mission/${missionId}/scheduling/activities?start_date=${startDate}&end_date=${endDate}`,
  method: 'GET',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.activities.cannotGetByMissionId'
  ),
  errorDescription: (e) => e.join('; '),
});

export type PatchActivityParams = {
  missionId: string;
  activityId: string;
};

export type PatchActivityBody = {
  status?: ActivityStatus;
  start_date?: string;
  end_date?: string;
  priority?: number;
  parameters?: {
    platform?: {
      roll_angle?: number;
    };
    metrics?: {
      cloud_status?: string;
    };
  };
  operator_notes?: string;
  status_reason?: string;
};

export type PatchActivityData = {
  id: string;
  msg: string;
};

export const patchActivity = apiFetchHandler<
  PatchActivityData,
  PatchActivityParams,
  PatchActivityBody
>({
  host: hosts.ops.v1,
  endpoint: ({ missionId, activityId }) =>
    `/mission/${missionId}/scheduling/activities/${activityId}`,
  method: 'PATCH',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.activities.cannotPatch'
  ),
  onSuccess: () => {
    toaster.show({
      message: clientTranslate(
        'datacosmos.fetchSuccesses.activities.successfullyPatched'
      ),
      intent: 'success',
    });
  },
  onError: ({ state }) => {
    state.errors.map((e) => {
      toaster.show({
        message: e,
        intent: 'danger',
      });
    });
  },
  errorDescription: (e) => e.join('; '),
});

export type CreateActivitiesBody = {
  tasking_request_ids: string[];
  mission_id: string;
  start_date: string;
  end_date: string;
  type: 'IMAGE_ACQUISITION';
  priority?: number;
  parameters: ActivityParameters;
};

export const createActivities = apiFetchHandler<
  undefined,
  {},
  CreateActivitiesBody[]
>({
  endpoint: '/scheduling/activities',
  host: hosts.ops.v1,
  method: 'POST',
  errorMessage: 'Could not create activities',
  onSuccess: () => {
    toaster.show({
      message: 'Activities created successfully',
      intent: 'success',
    });
  },
  onError: ({ state }) => {
    state.errors.map((e) => {
      toaster.show({
        message: e,
        intent: 'danger',
      });
    });
  },
  errorDescription: (e) => e.join('; '),
});

export type getActivityParametersParams = {
  missionId: string;
};

export type ActivityParametersSchema = {
  activity_parameters: {
    IMAGE_ACQUISITION: JsonSchema;
  };
  status_reason?: {
    IMAGE_ACQUISITION: JsonSchema;
  };
};

export const getActivityParameters = apiFetchHandler<
  ActivityParametersSchema,
  getActivityParametersParams
>({
  host: hosts.ops.v1,
  endpoint: ({ missionId }) => `/mission/${missionId}/scheduling/configuration`,
  method: 'GET',
  errorMessage: 'Cannot get schema configuration',
  errorDescription: (e) => e.join('; '),
});
