import { useEffect } from 'react';
import Applications from 'datacosmos/components/Applications';
import ApplicationStatus from 'datacosmos/components/Applications/applicationStatus';
import { Tasking } from 'datacosmos/components/Tasking';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ResultsWrapper } from 'datacosmos/components/ResultsWrapper';
import ProjectRedirectPage from 'datacosmos/components/ProjectRedirectPage';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useImageCatalog } from 'datacosmos/stores/ImageCatalogProvider';
import * as routePath from 'datacosmos/components/routePath';
import { useLocalisation } from 'utils/hooks/useLocalisation';

export const Routes = () => {
  const [currentProject] = useLocalStorage<string | null>(
    'currentProject',
    null
  );

  const baseRoute = useRouteMatch(routePath.basename);
  const history = useHistory();
  const location = useLocation();

  const { translate } = useLocalisation();

  const shouldRedirect = currentProject && baseRoute?.isExact;

  useEffect(() => {
    if (shouldRedirect) {
      history.push(
        routePath.routeProjectItems.replace(
          ':' + routePath.routeParamProjectId,
          currentProject
        )
      );
    }
  }, [currentProject, history, shouldRedirect]);

  const { stacAdapter: projectsStacAdapter } = useProjects();
  const { stacAdapter: catalogStacAdapter } = useImageCatalog();

  // TODO: Deprecate. This is a fix for legacy tasking links.
  if (location.pathname === '/data/map/tasking') {
    return <ProjectRedirectPage />;
  }

  return (
    <Switch location={location}>
      <Route exact path={routePath.baseProjectRedirect}>
        <ProjectRedirectPage />
      </Route>
      <Route path={routePath.routeProjectItems} exact>
        <ResultsWrapper
          title={translate('datacosmos.catalogAndItems.project')}
          isCatalog={false}
          stacAdapter={projectsStacAdapter}
        />
      </Route>
      <Route path={routePath.routeApplication} exact>
        <Applications />
      </Route>
      <Route path={routePath.routeApplicationStatus}>
        <ApplicationStatus />
      </Route>
      <Route path={routePath.routeTasking} exact>
        <Tasking />
      </Route>
      <Route path={routePath.routeCatalog} exact>
        <ResultsWrapper
          title={translate('datacosmos.catalogAndItems.catalog')}
          isCatalog={true}
          stacAdapter={catalogStacAdapter}
        />
      </Route>
      <Route path="*">Page not found</Route>
    </Switch>
  );
};
