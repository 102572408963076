import area from '@turf/area';
import ListItem from '_atoms/ListItem/ListItem';
import SearchInput from '_atoms/SearchInput/SearchInput';
import Button from '_molecules/Button/Button';
import UnorderedList, {
  ListOrientation,
} from '_molecules/UnorderedList/UnorderedList';
import { getRegionInfo } from '_api/osmRegions/service';
import type { OSMRegionInfo } from '_api/osmRegions/types';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import type { Polygon } from 'geojson';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { getLargestPolygonFromMultipolygon } from 'datacosmos/utils/geojson';
import bbox from '@turf/bbox';
import { parseAsPoint } from 'datacosmos/utils/coordinates/coordinateParsers';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  setAreaOfInterest: Dispatch<SetStateAction<Polygon[] | undefined>>;
};

const SearchBySpecificRegion = ({ setAreaOfInterest }: Props) => {
  const [isSearchByRegionOpen, setSearchByRegionOpen] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [fetchedRegions, setFetchedRegions] = useState<OSMRegionInfo[]>([]);

  const { removeLayersBySourceType, addLayer } = useMapLayers();
  const { setViewToFitBbox } = useMap();

  const { translate } = useLocalisation();

  const fetchRegion = async (region: string) => {
    setIsLoading(true);
    const res = await getRegionInfo({
      params: {
        format: 'json',
        limit: 50,
        polygon_geojson: 1,
        polygon_threshold: 0.01,
        q: region,
      },
    });

    setIsLoading(false);
    return res;
  };

  return (
    <div>
      {!isSearchByRegionOpen ? (
        <Button
          text={translate('datacosmos.buttons.searchByRegion')}
          icon="globe"
          className="color-item h-8 w-full text-start border-2 border-item"
          onPress={() => {
            setSearchByRegionOpen(true);
          }}
        />
      ) : (
        <div>
          <SearchInput
            label={translate('datacosmos.filters.fetchRegion')}
            fullWidth={true}
            onSubmit={async (val) => {
              const res = await fetchRegion(val);
              if (!res.data) {
                return;
              }
              const displayNameCount: { [key: string]: number } = {};
              res.data.forEach((place) => {
                const displayName = place.display_name;
                if (displayName in displayNameCount) {
                  displayNameCount[displayName]++;
                } else {
                  displayNameCount[displayName] = 1;
                }
              });

              const updatedRegions = res.data.map((region) => {
                const count = displayNameCount[region.display_name];
                if (count > 1) {
                  return {
                    ...region,
                    display_name: `${
                      region.display_name
                    }<span class='text-sm text-item-contrast-inactive dark:text-item-dark-contrast-inactive font-bold'>
                    (${region.addresstype}${
                      region.class !== 'place' ? ` ${region.class}` : ''
                    })</span>`,
                  };
                }
                return region;
              });

              setFetchedRegions(updatedRegions);
            }}
            submitLoading={isLoading}
          />

          {!isLoading && fetchedRegions.length > 0 && (
            <UnorderedList orientation={ListOrientation.Vertical}>
              {fetchedRegions.map((region) => (
                <div
                  key={region.place_id}
                  className="flex items-center justify-between"
                >
                  <ListItem
                    content={`${region.display_name}`}
                    onClick={() => {}}
                    noTruncate
                  />
                  <Button
                    text={translate('datacosmos.filters.display')}
                    icon="Pin"
                    onPress={() => {
                      removeLayersBySourceType(
                        LayerSourceType.AREA_OF_INTEREST
                      );

                      let geo: GeoJSON.Polygon | undefined;

                      if (region.geojson.type === 'Polygon') {
                        geo = region.geojson;
                      }

                      if (region.geojson.type === 'Point') {
                        geo = parseAsPoint([
                          {
                            lat: region.geojson.coordinates[1],
                            lng: region.geojson.coordinates[0],
                          },
                        ])?.geometry;
                      }

                      if (region.geojson.type === 'MultiPolygon') {
                        geo = getLargestPolygonFromMultipolygon(region.geojson);
                      }

                      if (!geo) {
                        return;
                      }

                      setAreaOfInterest([geo]);

                      addLayer(
                        PolygonLayerFactory(
                          LayerSourceType.AREA_OF_INTEREST,
                          translate('datacosmos.layers.names.aoi'),
                          geo,
                          area(region.geojson),
                          undefined
                        )
                      );

                      setViewToFitBbox(bbox(region.geojson));
                    }}
                  />
                </div>
              ))}
            </UnorderedList>
          )}
          <Button
            text={translate('datacosmos.buttons.close')}
            onPress={() => {
              removeLayersBySourceType(LayerSourceType.AREA_OF_INTEREST);
              setSearchByRegionOpen(false);
              setFetchedRegions([]);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SearchBySpecificRegion;
