import type { GenericDetailsItem } from '_api/tasking/types';
import { Button, Popover } from 'opencosmos-ui';
import { TabularDetailsItem } from 'pages/ops/shared/components/DetailsItem';

interface AdvancedParametersInfoProps {
  isOpen: boolean;
  triggerRef: React.RefObject<HTMLButtonElement>;
  popoverContent: {
    [key: string]: unknown;
  };
  onOpenChange: (isOpen: boolean) => void;
}
const AdvancedParametersInfo = ({
  isOpen,
  onOpenChange,
  triggerRef,
  popoverContent,
}: AdvancedParametersInfoProps) => {
  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      triggerRef={triggerRef}
      placement="right bottom"
      maxHeight={300}
      className={'overflow-auto max-w-sm'}
      shouldFlip
      shouldUpdatePosition
    >
      <div className="bg-surface dark:bg-surface-dark">
        <div className="break-all font-medium bg-neutral-100 p-2 border border-b-2 text-center flex justify-between items-center">
          <div className="font-bold">Advanced parameters</div>
          <Button
            icon="Cross"
            isMinimal
            isTransparent
            onPress={() => {
              onOpenChange(false);
            }}
          />
        </div>
        <div className="dark:text-item-dark-contrast text-item-contrast bg-item-dark-contrast dark:bg-item-contrast p-2 flex flex-col gap-2">
          {popoverContent?.imager !== null && (
            <TabularDetailsItem
              title="Imager"
              value={[popoverContent?.imager] as GenericDetailsItem[]}
              isVertical={true}
            />
          )}

          {popoverContent?.ground_processing !== null && (
            <TabularDetailsItem
              title="Ground Processing"
              value={
                [popoverContent?.ground_processing] as GenericDetailsItem[]
              }
              isVertical={true}
            />
          )}

          {popoverContent?.onboard_processing !== null && (
            <TabularDetailsItem
              title="Onboard Processing"
              value={
                [popoverContent?.onboard_processing] as GenericDetailsItem[]
              }
              isVertical={true}
            />
          )}

          {popoverContent?.platform !== null && (
            <TabularDetailsItem
              title="Platform"
              value={[popoverContent?.platform] as GenericDetailsItem[]}
              isVertical={true}
            />
          )}
        </div>
      </div>
    </Popover>
  );
};

export default AdvancedParametersInfo;
