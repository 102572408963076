import type { ActivityStatus } from '_api/activities/types';

export const ACTIVITY_CATEGORY_LIST = [
  'FAILED',
  'PLANNED',
  'COMPLETED',
] as const;

export type ActivityCategory = (typeof ACTIVITY_CATEGORY_LIST)[number];

export const getActivityCategory = (
  status: ActivityStatus
): ActivityCategory => {
  if (status === 'CANCELLED' || status === 'EXPIRED' || status === 'FAILED') {
    return 'FAILED';
  }
  if (
    status === 'CONFIRMED' ||
    status === 'SCHEDULED' ||
    status === 'APPROVED'
  ) {
    return 'PLANNED';
  }
  return 'COMPLETED';
};
