import { Breadcrumbs, NonIdealState } from '@blueprintjs/core';
import mainS from 'pages/ops/RTI/index.module.scss';
import DefaultLayout from '../shared/components/DefaultLayout';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import { Row } from 'ui/RowLayout/Row';
import { AlertDialog, Button, Header, Table, Tooltip } from 'opencosmos-ui';
import { useGseData } from './hooks/useGseData';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { useCallback } from 'react';
import { DetailsItem } from '../shared/components/DetailsItem';

const GsePage = () => {
  const {
    assignedDevices,
    removeAssignedDevice,
    allDevices,
    assignDevice,
    navigateToAssignedMission,
    isUpdatingAssignedDevices,
    getShouldDisableAssignmentForDevice,
    setIsConnectDialogOpen,
    setIsDisconnectDialogOpen,
    isConnectDialogOpen,
    isDisconnectDialogOpen,
    setDeviceToConnect,
    deviceToConnect,
    deviceToDisconnect,
    setDeviceToDisconnect,
    refetchDevices,
    getDeviceAssignee,
    loadingDevices,
  } = useGseData();

  const renderAssignedDevices = () => {
    return (
      <Table<(typeof assignedDevices)[0]>
        data={assignedDevices}
        cellRenderer={(ctx, header) => {
          if (header === 'assignment.created_by') {
            const da = getDeviceAssignee(ctx.row.original);
            return da ? (
              <Tooltip content={da?.email}>
                <img className="w-8 h-8 rounded-full" src={da?.picture} />
              </Tooltip>
            ) : (
              <div />
            );
          }
          return <span className="p-2">{ctx.getValue() as string}</span>;
        }}
        hiddenColumns={['assignment.created_at', 'assignment.mission_id']}
        capitalizeTableHeaders={true}
        alignHeader="left"
        isMinimal={true}
        headerRemap={{
          'assignment.created_by': 'Assignee',
        }}
        appendColumns={[
          {
            cell: (ctx) => {
              return (
                <div className="w-full flex items-center justify-end">
                  <Button
                    icon="Trash"
                    onPress={() => {
                      setDeviceToDisconnect(ctx.row.original);
                      setIsDisconnectDialogOpen(true);
                    }}
                    variant="square"
                    className={'h-8'}
                    intent="warning"
                    isMinimal={false}
                    data-testid="disconnect-device-btn"
                  />
                </div>
              );
            },
            header: '',
            id: 'delete',
          },
        ]}
      />
    );
  };

  const renderAllDevices = useCallback(() => {
    return (
      <Table<(typeof allDevices)[0]>
        data={allDevices}
        isMinimal={true}
        cellRenderer={(ctx, header) => {
          if (header === 'assignment.mission_id') {
            return ctx.getValue() ? (
              <Tooltip content="Navigate to the mission">
                <Button
                  fill
                  isMinimal={true}
                  onPress={() => {
                    navigateToAssignedMission(ctx.getValue() as number);
                  }}
                >
                  <span className="w-full text-left">
                    {ctx.getValue() as number}
                  </span>
                </Button>
              </Tooltip>
            ) : (
              <div />
            );
          }

          if (header === 'assignment.created_by') {
            const da = getDeviceAssignee(ctx.row.original);
            return da ? (
              <Tooltip content={da?.email}>
                <img className="w-8 h-8 rounded-full" src={da?.picture} />
              </Tooltip>
            ) : (
              <div />
            );
          }
          return <span className="p-2">{ctx.getValue() as string}</span>;
        }}
        capitalizeTableHeaders={true}
        alignHeader="left"
        hiddenColumns={['assignment.created_at']}
        headerRemap={{
          'assignment.created_by': 'Assignee',
        }}
        appendColumns={[
          {
            cell: (ctx) => {
              const isDisabled = getShouldDisableAssignmentForDevice(
                ctx.row.original
              );

              return (
                <div className="w-full flex items-center justify-end">
                  <Tooltip
                    content="Device type already assigned to the mission"
                    isDisabled={!isDisabled}
                  >
                    <Button
                      icon="Plus"
                      onPress={() => {
                        setDeviceToConnect(ctx.row.original);
                        setIsConnectDialogOpen(true);
                      }}
                      variant="square"
                      className={'h-8'}
                      intent="primary"
                      isMinimal={false}
                      isDisabled={isDisabled}
                      data-testid="connect-device-btn"
                    />
                  </Tooltip>
                </div>
              );
            },
            header: '',
            id: 'delete',
          },
        ]}
      />
    );
  }, [
    allDevices,
    getDeviceAssignee,
    getShouldDisableAssignmentForDevice,
    navigateToAssignedMission,
    setDeviceToConnect,
    setIsConnectDialogOpen,
  ]);

  const withLoadingAllDevices = (content: JSX.Element) => {
    if (loadingDevices) {
      return <Spinner />;
    }

    return <>{content}</>;
  };

  const withLoadingAssigningDevice = (content: JSX.Element) => {
    if (isUpdatingAssignedDevices) {
      return <Spinner />;
    }

    return <>{content}</>;
  };

  const withNoAssignedDevices = (content: JSX.Element) => {
    if (assignedDevices.length === 0) {
      return (
        <NonIdealState
          title="No devices"
          description={'No assigned devices found'}
          icon="info-sign"
        />
      );
    }

    return <>{content}</>;
  };
  return (
    <DefaultLayout
      showMissionSelector
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'Scripts' }]} />
        </div>
      }
      className="p-10"
    >
      <RowLayout rows={3} rowRatio={[0.2, 1, 1]}>
        <Row align="start">
          <Header className="bg-transparent text-3xl !px-0">
            Ground Support Equipment
          </Header>
        </Row>
        <Row align="start" data-testid="assigned-devices">
          <RowLayout rows={2} rowRatio={[0.1, 1]}>
            <Row>
              <Header className="bg-transparent text-xl !px-0">
                Devices Assigned to Current Mission
              </Header>
            </Row>
            <Row align="start" style={{ maxHeight: '36vh', overflowY: 'auto' }}>
              {withLoadingAssigningDevice(
                withNoAssignedDevices(renderAssignedDevices())
              )}
            </Row>
          </RowLayout>
        </Row>
        <Row align="start" data-testid="all-devices">
          <RowLayout rows={2} rowRatio={[0.1, 1]}>
            <Row>
              <Header className="bg-transparent text-xl !px-0">
                All Available Devices
              </Header>
            </Row>
            <Row align="start" style={{ maxHeight: '36vh', overflowY: 'auto' }}>
              {withLoadingAllDevices(renderAllDevices())}
            </Row>
          </RowLayout>
        </Row>
      </RowLayout>
      <AlertDialog
        isOpen={isConnectDialogOpen}
        onClose={() => {
          setIsConnectDialogOpen(false);
          setDeviceToConnect(undefined);
        }}
        onAction={async () => {
          if (deviceToConnect) {
            await assignDevice(deviceToConnect);
            await refetchDevices();
          }
          setIsConnectDialogOpen(false);
        }}
        actionLabel="Connect"
        title="Connect device?"
      >
        <div className="flex flex-col gap-2">
          <span>
            You are about to connect a new device, has it been connected
            correctly?
          </span>

          <div>
            <DetailsItem title="Name" value={deviceToConnect?.name ?? ''} />
            <DetailsItem
              title="Device type"
              value={deviceToConnect?.device_type ?? ''}
            />
            <DetailsItem
              title="Device model"
              value={deviceToConnect?.device_model ?? ''}
            />
            <DetailsItem
              title="Host"
              value={deviceToConnect?.connection.config.host ?? ''}
            />
          </div>
        </div>
      </AlertDialog>

      <AlertDialog
        isOpen={isDisconnectDialogOpen}
        onClose={() => {
          setIsDisconnectDialogOpen(false);
          setDeviceToDisconnect(undefined);
        }}
        onAction={async () => {
          if (deviceToDisconnect) {
            await removeAssignedDevice(deviceToDisconnect);
            await refetchDevices();
          }
          setIsDisconnectDialogOpen(false);
        }}
        actionLabel="Disconnect"
        title="Disconnect device?"
      >
        <div className="flex flex-col gap-2">
          <span>
            You are about to disconnect a device, has the device been fully
            disconnected
          </span>

          <div>
            <DetailsItem title="Name" value={deviceToDisconnect?.name ?? ''} />
            <DetailsItem
              title="Device type"
              value={deviceToDisconnect?.device_type ?? ''}
            />
            <DetailsItem
              title="Device model"
              value={deviceToDisconnect?.device_model ?? ''}
            />
            <DetailsItem
              title="Host"
              value={deviceToDisconnect?.connection.config.host ?? ''}
            />
          </div>
        </div>
      </AlertDialog>
    </DefaultLayout>
  );
};

export default GsePage;
