import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from 'react';
import type { IMapContext } from 'datacosmos/stores/MapProvider';
import { baseProjectRoute } from 'datacosmos/components/routePath';
import { useRouteMatch } from 'react-router';

export type IMapControllerContext = ReturnType<typeof useMapControllerHook>;

export const MapControllerContext = createContext<IMapControllerContext>(
  null as unknown as IMapControllerContext
);

export const useMapController = () =>
  useContext<IMapControllerContext>(MapControllerContext);

export const useMapControllerHook = ({
  onMapZoomEnd,
  setViewToFitBbox,
}: IMapContext) => {
  const [shouldAutoZoomToFitView, setShouldZoomToFitView] = useState(true);

  const setAutoViewToFitBboxIfNecessary = useCallback(
    (bbox: number[], maxZoom?: number) => {
      if (shouldAutoZoomToFitView) {
        setViewToFitBbox(bbox, maxZoom);
        setShouldZoomToFitView(false);
      }
    },
    [setViewToFitBbox, shouldAutoZoomToFitView]
  );

  useEffect(() => {
    onMapZoomEnd(() => {
      setShouldZoomToFitView(false);
    });
  }, [onMapZoomEnd]);

  const datacosmosRouteMatch = useRouteMatch<{ projectId: string }>(
    baseProjectRoute
  );

  const projectId = datacosmosRouteMatch?.params.projectId ?? '';

  useEffect(() => {
    setShouldZoomToFitView(true);
  }, [projectId]);

  return {
    setAutoViewToFitBboxIfNecessary,
  };
};

export const MapControllerProvider = ({
  children,
  map,
}: {
  children: React.ReactNode;
  map: IMapContext;
}) => {
  return (
    <MapControllerContext.Provider value={useMapControllerHook(map)}>
      {children}
    </MapControllerContext.Provider>
  );
};
