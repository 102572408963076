import classNames from "classnames";
import React from "react";

type Props = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

/**
 * Textarea is a component that can be used to display a block of editable text.
 */
const Textarea = (props: Props) => {
  return (
    <textarea
      {...props}
      className={classNames("h-64 color-item w-full p-2", props.className)}
    ></textarea>
  );
};

export default Textarea;
