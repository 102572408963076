import { ListBoxItem } from "react-aria-components";

const MyListBoxItem: typeof ListBoxItem = (props) => {
  return (
    <ListBoxItem
      {...props}
      key={props.key ?? props.id}
      className="cursor-pointer p-1 hover:bg-item-hover dark:hover:bg-item-dark-hover focus-visible:bg-item-hover focus-visible:outline-2 focus-visible:outline-neutral-600 focus-visible:outline 
      aria-disabled:cursor-not-allowed aria-disabled:opacity-50"
    />
  );
};

export default MyListBoxItem;
