import type { AttachedRoleAssignment } from '_api/administration/permissions';
import type { IRole } from 'services/api/portal/administration/api/types';
import RoleAssignmentsThatAreGranted from './RoleAssignmentsThatAreGranted';
import RoleAssignmentsThatGrantPermission from './RollAssignmentsThatGrantPermission';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface PermissionsManagerProps {
  resourceAttachments: AttachedRoleAssignment[];
  resourceId: string;
  resourceType: string;
  getAttachmentsCallback: () => Promise<void>;
  availableRoles: IRole[];
}

const ResourcePermissionsManager = ({
  resourceAttachments,
  resourceId,
  resourceType,
  getAttachmentsCallback,
  availableRoles,
}: PermissionsManagerProps) => {
  // Role assignments that are granted to this specific resource
  const filteredResourceAttachmentsForParent = resourceAttachments.filter(
    (attachment) =>
      attachment.parentResourceId?.toString() === resourceId &&
      attachment.parentResourceType === resourceType
  );

  // Role assignments that grant permission to this resource
  const filteredResourceAttachmentsForChild = resourceAttachments.filter(
    (attachment) =>
      attachment.childResourceId?.toString() === resourceId &&
      attachment.childResourceType === resourceType
  );

  const { translate } = useLocalisation();
  return (
    <div className="mb-2">
      <h2 className="text-lg">
        {translate('datacosmos.resources.common.permissions.title')}
      </h2>
      <div className="relative flex flex-col mt-4 dark:bg-surface-dark dark:text-surface-dark-contrast w-full">
        <div className="font-semibold">
          {translate(
            'datacosmos.resources.common.permissions.userGroupPermissions'
          )}
        </div>
        <RoleAssignmentsThatGrantPermission
          attachments={filteredResourceAttachmentsForChild}
          getResourceAttachmentsCallback={getAttachmentsCallback}
          availableRoles={availableRoles}
        />
      </div>

      <div className="my-5 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />

      <div className="relative flex flex-col mt-4 dark:bg-surface-dark dark:text-surface-dark-contrast w-full">
        <div className="font-semibold">
          {translate(
            'datacosmos.resources.common.permissions.additionalLinkedPermissions'
          )}
        </div>
        {availableRoles?.map((availRole) => (
          <RoleAssignmentsThatAreGranted
            roleAttachments={filteredResourceAttachmentsForParent.filter(
              (assignment) => assignment.parentRole.name === availRole.name
            )}
            grantedTo={availRole.name}
            parentRoleId={availRole.id}
            getResourceAttachmentsCallback={getAttachmentsCallback}
            resourceId={resourceId.toString()}
            resourceType={resourceType}
            key={availRole.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ResourcePermissionsManager;
