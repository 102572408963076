import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';
import AddPermission from './AddPermission';
import ListPermissions from './ListPermissions';
import UserHeader from './UserHeader';
import s from './index.module.scss';
import type { PermissionType } from '_api/administration/permissions';
import useAssignments from 'pages/portal/Administration/User/hooks/useAssignments';
import { useAllResources } from 'pages/portal/Administration/User/hooks/useAllResources';

interface Props {
  user: IUserWithPermissions;
  getAvailableRolesByType: (type: PermissionType) => IRole[];
  setResourceId: (resourceId: string | number) => void;
  setSelectedPermission: (permission: PermissionType) => void;
  selectedPermission?: PermissionType;
  resourceId: string | number;
  assignRole: (userId: string, roleIds: number[]) => Promise<void>;
  assignGlobalRole: (userId: string, roleIds: number[]) => Promise<void>;
  deleteRoleAssignments: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => Promise<void>;
}

const User = ({
  user,
  getAvailableRolesByType,
  setResourceId,
  setSelectedPermission,
  selectedPermission,
  resourceId,
  assignRole,
  deleteRoleAssignments,
  assignGlobalRole,
}: Props) => {
  const { assignments, getAssignments } = useAssignments(user);
  const { resources } = useAllResources(user);

  return (
    <div className={s.container}>
      <UserHeader user={user} />
      <AddPermission
        getAvailableRolesByType={getAvailableRolesByType}
        user={user}
        setResourceId={setResourceId}
        setSelectedPermission={setSelectedPermission}
        selectedPermission={selectedPermission}
        resourceId={resourceId}
        assignRole={assignRole}
        assignGlobalRole={assignGlobalRole}
        getAssignments={getAssignments}
      />
      <ListPermissions
        user={user}
        userAssignments={assignments}
        deleteRoleAssignments={deleteRoleAssignments}
        resources={resources}
      />
    </div>
  );
};

export default User;
