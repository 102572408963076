import { clientTranslate } from 'utils/hooks/useLocalisation';
import type { IColorChangable } from './geojsonLayer';
import type { ILayerOptions, LayerSourceType } from './layer';
import { Layer } from './layer';

export function LineLayerFactory(
  sourceType: LayerSourceType,
  name: string,
  data: GeoJSON.GeoJSON,
  lengthInM: number,
  options?: Partial<ILayerOptions & IGeoJSONLayerOptions>
) {
  return new LineLayer(sourceType, name, data, lengthInM, options);
}

export interface IGeoJSONLayerOptions {
  color: string;
  weight: number;
  fillOpacity: number;
  visible: boolean;
}

export const DefaultGeometryLayerOptions = {
  color: '#e4695e',
  weight: 2,
  fillOpacity: 1,
  visible: true,
};

/**
 *Geometry layer is a GeoJSON layer for displaying user drawn shapes with measurements included
 */

const KM = 1000;
const ACCURACY_LIMIT = 10000;

export class LineLayer extends Layer implements IColorChangable {
  declare options: ILayerOptions & IGeoJSONLayerOptions;
  declare layerClass: string;
  name: string;
  data: GeoJSON.GeoJSON;
  lengthInM: number;
  description: string | undefined;
  editing?: unknown;

  /**
   * newDataCosmosLayer creates a new datacosoms layer
   */
  constructor(
    sourceType: LayerSourceType,
    name: string,
    data: GeoJSON.GeoJSON,
    lengthInM: number,
    options?: Partial<ILayerOptions & IGeoJSONLayerOptions>
  ) {
    super(sourceType);

    this.name = name;
    this.data = data;
    this.lengthInM = lengthInM;
    this.options = {
      ...this.options,
      ...DefaultGeometryLayerOptions,
      ...options,
    };
    this.layerClass = 'LineLayer';
  }
  setColor(color: string): void {
    this.options.color = color;
  }

  getName() {
    return this.name || this.id;
  }

  getDescription() {
    if (this.description) {
      return this.description;
    }
    return null;
  }

  getMeasurements() {
    return `${clientTranslate('datacosmos.layers.distance')}: ${Number(
      this.lengthInM / KM
    ).toFixed(2)} km`;
  }

  isAccurate() {
    return this.lengthInM / KM < ACCURACY_LIMIT;
  }

  containsSTACItem() {
    return false;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra() {
    return false;
  }

  sza(): Number | undefined {
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }
}
