import type { RequestStatus, TaskingRequest } from '_api/tasking/types';
import InfiniteScroll from 'react-infinite-scroller';
import { useState } from 'react';
import TaskingCard from '_organisms/TaskingCard/TaskingCard';
import { Loader } from 'ui/Loader';

interface PaginatedtaskingRequestsListProps {
  status: RequestStatus;
  hasMore: boolean;
  cursor?: string;
  classifiedRequests: TaskingRequest[];
  selectedRequest: TaskingRequest | undefined;
  handleTaskingRequestClick: (req: TaskingRequest) => void;
  getRequestsByStatus: (
    status: RequestStatus,
    cursor?: string
  ) => Promise<void>;
  'data-testid'?: string;
}
const PaginatedtaskingRequestsList = ({
  classifiedRequests,
  status,
  hasMore,
  getRequestsByStatus,
  cursor,
  handleTaskingRequestClick,
  selectedRequest,
  'data-testid': testID,
}: PaginatedtaskingRequestsListProps) => {
  const [isRequestsLoading, setIsRequestsLoading] = useState<boolean>(false);

  return (
    <InfiniteScroll
      initialLoad={false}
      loadMore={async () => {
        if (isRequestsLoading) {
          return;
        }
        setIsRequestsLoading(true);
        await getRequestsByStatus(status, cursor);
        setIsRequestsLoading(false);
      }}
      hasMore={hasMore}
      useWindow={false}
      className="flex flex-col h-full gap-2"
      threshold={0}
      data-testid={testID}
    >
      {classifiedRequests?.map((r) => (
        <TaskingCard
          onClick={() => handleTaskingRequestClick(r)}
          taskingRequest={r}
          key={r.id}
          active={selectedRequest?.id === r.id}
        />
      ))}
      {isRequestsLoading && <Loader />}
    </InfiniteScroll>
  );
};

export default PaginatedtaskingRequestsList;
