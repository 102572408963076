import type { ISatelliteBall } from '../satelliteBalls/actionTypes';

export const GSB_ADD = 'GSB_ADD';
export const GSB_UPDATE = 'GSB_UPDATE';
export const GSB_REMOVE = 'GSB_REMOVE';

export interface IGroup {
  label: string;
  satelliteBalls: ISatelliteBall[];
  route: string;
  redirectPath?: string;
  missionRequired?: boolean;
}

interface IGroupSBAddAction {
  type: typeof GSB_ADD;
  group: IGroup;
}

interface IGroupSBUpdateAction {
  type: typeof GSB_UPDATE;
  group: IGroup;
}

interface IGroupSBRemoveAction {
  type: typeof GSB_REMOVE;
  label: string;
}

export type GroupSatelliteBallActionTypes =
  | IGroupSBAddAction
  | IGroupSBUpdateAction
  | IGroupSBRemoveAction;
