import classNames from 'classnames';
import {
  LAYER_TOOLS,
  useLayerTools,
} from 'datacosmos/stores/LayerToolsProvider';
import { ScatterPlotChart } from './ScatterPlot';
import { Button, Tooltip } from 'opencosmos-ui';
import { forwardRef, useRef, useState } from 'react';
import { useMap } from 'datacosmos/stores/MapProvider';
import { zIndexValues } from 'opencosmos-ui/constants';
import type {
  CovarianceMatrixData,
  PostScatterPlotData,
} from '_api/sampling/service';
import { CovarianceTable } from './Covariance';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { HistogramChart } from './Histogram';
import type { COGMetadataForRange } from 'datacosmos/services/tilingApi/cogMetadata';
import { screenshot } from 'utils/screenshot';
import { downloadFilUrlAsExtension } from 'datacosmos/download/geojson';

type AbsoluteContainerProps = {
  children: React.ReactNode;
  isMaximized?: boolean;
  rightMenuOpen?: boolean;
};

const AbsoluteContainer = forwardRef<HTMLDivElement, AbsoluteContainerProps>(
  function AbsoluteContainer({ children, isMaximized, rightMenuOpen }, ref) {
    const { mapContainerRef } = useMap();
    const { clientWidth: width, clientHeight: height } =
      mapContainerRef?.current ?? {};

    return (
      <div
        ref={ref}
        className={classNames(
          'dark:bg-surface-dark bg-surface dark:text-item-dark-contrast',
          'flex flex-col h-fit w-fit min-w-[400px] absolute right-[5rem] top-[60px]'
        )}
        style={{
          zIndex: zIndexValues.popover,
          ...(isMaximized
            ? {
                width,
                height,
                right: rightMenuOpen ? 445 : undefined,
                top: 50,
              }
            : {
                right: rightMenuOpen ? 460 : '5rem',
              }),
        }}
      >
        {children}
      </div>
    );
  }
);

const LayerToolsChart = ({ rightMenuOpen }: { rightMenuOpen: boolean }) => {
  const [isMaximized, setIsMaximized] = useState<boolean>(false);
  const [isCapturingScreenshot, setIsCapturingScreenshot] =
    useState<boolean>(false);
  const { enabledTool, data, setData } = useLayerTools();
  const { translate: t } = useLocalisation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<HTMLDivElement | null>(null);

  if (!enabledTool || !data) return null;

  const handleCloseChart = () => {
    setData(null);
  };

  const handleScreenshot = async () => {
    if (!chartRef) {
      return;
    }
    setIsCapturingScreenshot(true);

    const imgString = await screenshot(chartRef.current, {
      mimeType: 'image/png',
      ignore: ['draw-aoi-btn', 'remove-aoi-btn', 'modal-title'],
    });

    downloadFilUrlAsExtension(
      imgString,
      `${t(
        `datacosmos.layers.tools.${enabledTool}.title`
      )}_${new Date().toISOString()}`,
      'png'
    );
    setIsCapturingScreenshot(false);
  };

  const dialog = (
    <AbsoluteContainer
      ref={containerRef}
      isMaximized={isMaximized}
      rightMenuOpen={rightMenuOpen}
    >
      <div className="h-full">
        <h3 className="flex justify-center color-header-sidebar border-2 border-surface dark:border-surface-dark">
          <span className="flex flex-grow justify-center">
            {t(`datacosmos.layers.tools.${enabledTool}.title`)}
          </span>
          <Tooltip content={t('datacosmos.buttons.takeScreenshot')}>
            <Button
              size="sm"
              onPress={handleScreenshot}
              icon="camera"
              className="border-none h-full"
              loading={isCapturingScreenshot}
              iconOnly
            />
          </Tooltip>
          <Button
            size="sm"
            onPress={() => setIsMaximized((prev) => !prev)}
            icon={isMaximized ? 'minimize' : 'maximize'}
            className="border-none"
          />
          <Button
            size="sm"
            onPress={handleCloseChart}
            icon="cross"
            className="border-none"
          />
        </h3>
        <div ref={chartRef} className="color-surface h-full">
          {enabledTool === LAYER_TOOLS.SCATTER && (
            <ScatterPlotChart
              data={data as PostScatterPlotData}
              isMaximized={isMaximized}
            />
          )}
          {enabledTool === LAYER_TOOLS.COVARIANCE && (
            <CovarianceTable
              data={data as CovarianceMatrixData}
              isMaximized={isMaximized}
            />
          )}
          {enabledTool === LAYER_TOOLS.SPECTRAL_ANALYSIS && (
            <HistogramChart
              data={data as { [key: string]: COGMetadataForRange }}
              isMaximized={isMaximized}
            />
          )}
        </div>
      </div>
    </AbsoluteContainer>
  );

  return dialog;
};

export default LayerToolsChart;
