import classNames from "classnames";
import React, { useMemo } from "react";
import {
  useSliderThumb,
  useFocusRing,
  VisuallyHidden,
  mergeProps,
} from "react-aria";
import type { SliderState } from "react-stately";
import type { RangeSliderProps } from "./RangeSlider";

type SliderThumbProps = Partial<RangeSliderProps> & {
  state: SliderState;
  index: number;
  trackRef: React.MutableRefObject<HTMLDivElement | null>;
};

/**
 * Thumb is a component that represents a handle on the slider.
 * It is used internally by the RangeSlider component.
 *
 * **NOT TO BE USED STANDALONE**
 */
const Thumb = ({
  state,
  index,
  trackRef,
  isDisabled,
  showValuesAboveHandles,
  numberOfHandles = 0,
  ...props
}: SliderThumbProps) => {
  const inputRef = React.useRef(null);
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const { thumbProps, inputProps, labelProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state
  );

  const { focusProps, isFocusVisible } = useFocusRing();

  const labelOffset = useMemo(() => {
    if (!labelRef.current) return { left: 0 };

    if (numberOfHandles === 1)
      return {
        left: "auto",
        right: "auto",
      };

    if (index === 0)
      return {
        right:
          state.values[index] % 1 !== 0
            ? labelRef.current.clientWidth / 2 - 6
            : 3,
      };

    if (index === numberOfHandles - 1) return { left: 3 };

    return {
      right:
        state.values[index] % 1 !== 0
          ? -3
          : state.values[index] < 10
          ? 3
          : "auto",
    };
  }, [index, numberOfHandles, state.values]);

  return (
    <div
      style={{
        bottom: 10,
        position: "absolute",
        transform: "translateX(-50%)",
        left: `${state.getThumbPercent(index) * 100}%`,
        zIndex: 30 + index,
      }}
    >
      {showValuesAboveHandles && (
        <label
          {...labelProps}
          className={classNames(
            "absolute !text-[10px] top-[-18px] whitespace-nowrap",
            {
              "text-item-contrast dark:text-item-dark-contrast": !isDisabled,
              "text-contrast-inactive": isDisabled,
            }
          )}
          style={labelOffset}
          ref={labelRef}
        >
          {props.handleLabelFormatter
            ? props.handleLabelFormatter(state.values[index])
            : state.values[index]}
        </label>
      )}
      <div
        {...thumbProps}
        className={classNames("w-[12px] h-[12px] rounded-full", {
          "border-2 border-accent": isFocusVisible,
          "bg-item-contrast dark:bg-item-dark-contrast": !isDisabled,
          "bg-disabled-light": isDisabled,
        })}
        data-testid="slider-handle-container"
      >
        <VisuallyHidden>
          <input
            type="range"
            ref={inputRef}
            {...mergeProps(inputProps, focusProps)}
            data-testid="slider-handle"
          />
        </VisuallyHidden>
      </div>
    </div>
  );
};

export default Thumb;
