import moment from 'moment';
import type { IReply } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';

/**
 * Types of responses that need to be aggregated into a single response
 */
export const AGGREGATABLE_RESPONSE_TYPES = ['FTPTransferUpdate'];

/**
 * Types of responses that have a timestamp for the purpose
 * of throwing a warning if the response is outdated (older than a minute)
 * Includes every possible path to a timestamp in the response
 */
export const TIMESTAMPED_REPSPONSES_WITH_PATH = {
  'ocv2.telemetryrequest.TelemetryGetSetResponse': [
    ['data', 'telemetryPoint', 'timestamp'],
    ['data', 'telemetryPoint', 'compoundValue', 'timestamp'],
  ],
};

/**
 * getLatestResponse returns a response that is the latest based on the createdAt field
 *
 * Depending on whether we're getting a response from the websocket or cmd history api
 * they are sorted in different ways, so we need to check which one is the latest
 */
export const getLatestResponse = (replies: IReply[]) => {
  const firstRes = replies[0];
  const lastRes = replies[replies.length - 1];

  return firstRes?.createdAt > lastRes?.createdAt ? firstRes : lastRes;
};

/**
 * isReplyOutdated checks if the latest response timestamp is older than a minute by comparing the reply timestamp to the command createdAt field
 * @param replies - list of replies to check
 * @param cmdCreatedAt - createdAt field of the command
 * @returns true if the latest response is older than a minute
 */
export const isReplyOutdated = (
  replies: IReply[] | undefined,
  cmdCreatedAt: string
) => {
  if (!replies) {
    return false;
  }
  const responseTypes = Object.keys(TIMESTAMPED_REPSPONSES_WITH_PATH);

  const timestampedResponses = replies?.filter((r) => {
    return responseTypes.includes(r.type);
  });

  if (timestampedResponses.length === 0) {
    return false;
  }

  const latestTimestampedResponse = getLatestResponse(timestampedResponses);

  const resPaths =
    TIMESTAMPED_REPSPONSES_WITH_PATH[
      latestTimestampedResponse.type as keyof typeof TIMESTAMPED_REPSPONSES_WITH_PATH
    ];

  const timestamp = resPaths
    .map((p) => {
      return p.reduce((acc: unknown, curr) => {
        return acc?.[curr as keyof typeof acc];
      }, latestTimestampedResponse);
    })
    .filter((t) => t !== undefined)[0];

  if (!timestamp) {
    return false;
  }

  const timestampMoment = moment(timestamp);
  const createdAt = moment(cmdCreatedAt);

  return Math.abs(createdAt.diff(timestampMoment, 'seconds')) > 60;
};
