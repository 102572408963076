import type { IRegionOfInterest } from '../../../constants/regionsOfInterest/actionTypes';
import type { IMapWrapper } from '../../../declarations/mapDeclarations/Map';
import type { IPolygonWrapper } from '../../../declarations/mapDeclarations/Polygon';

export const addRegionOfInterest = (
  regionOfInterestData: IRegionOfInterest,
  map: IMapWrapper,
  options?: google.maps.PolygonOptions
): IPolygonWrapper => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { id, name, elevationAngle, paths } = regionOfInterestData;
  const regionOfInterest: IPolygonWrapper = new google.maps.Polygon({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    id,
    name,
    elevationAngle,
    //@ts-expect-error
    paths,
    map,
    ...options,
  });
  if (map) {
    map.regionsOfInterest?.push(regionOfInterest);
    return regionOfInterest;
  }
  //@ts-expect-error
  return undefined;
};

export const updateRegionOfInterest = (
  regionOfInterestData: IRegionOfInterest,
  regionOfInterest: IPolygonWrapper,
  map: IMapWrapper,
  options?: google.maps.PolygonOptions
): IPolygonWrapper => {
  const { name, paths, elevationAngle } = regionOfInterestData;
  regionOfInterest.name = name;
  regionOfInterest.setPaths(paths as google.maps.LatLngLiteral[][]);
  regionOfInterest.orderIndex = regionOfInterestData.orderIndex;
  regionOfInterest.elevationAngle = elevationAngle;
  if (options) {
    regionOfInterest.setOptions(options);
  }
  return regionOfInterest;
};

export const removeRegionOfInterest = (
  regionOfInterestIndex: number,
  map: IMapWrapper
): void => {
  const regionOfInterest: IPolygonWrapper | undefined =
    map.regionsOfInterest?.[regionOfInterestIndex];
  regionOfInterest?.setMap(null);
  map.regionsOfInterest?.splice(regionOfInterestIndex, 1);
};
