import type { IMissionsActionTypes } from '../../constants/missions/actionTypes';
import {
  MISSION_LOAD,
  MISSION_UPDATE,
  MISSION_SET_CURRENT,
} from '../../constants/missions/actionTypes';
import type { IMission } from '../../services/Missions';

export function loadMissions(missions: IMission[]): IMissionsActionTypes {
  return {
    type: MISSION_LOAD,
    missions,
  };
}

export function updateMission(mission: IMission): IMissionsActionTypes {
  return {
    type: MISSION_UPDATE,
    mission,
  };
}

export function setCurrentMission(mission: IMission): IMissionsActionTypes {
  return {
    type: MISSION_SET_CURRENT,
    mission,
  };
}
