const ORGANISATION = {
  GET: {
    LIST: {
      ERROR: 'Portal Organisation Get Error',
    },
  },
  CREATE: {
    ERROR: 'Portal Organisation Create Error',
  },
};

const PROGRAMME = {
  GET: {
    LIST: {
      ERROR: 'Portal Programme Get Error',
    },
  },
  CREATE: {
    ERROR: 'Portal Programme Create Error',
  },
};

const MISSION = {
  GET: {
    LIST: {
      ERROR: 'Portal Mission Get Error',
    },
  },
  CREATE: {
    ERROR: 'Portal Mission Create Error',
  },
};

export default {
  ORGANISATION,
  PROGRAMME,
  MISSION,
};
