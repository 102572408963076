import { NumericInput } from '@blueprintjs/core';
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { WrappedFieldProps } from 'redux-form';

interface INumberFieldProps extends WithTranslation, WrappedFieldProps {
  disable?: boolean;
}

function NumberField(field: INumberFieldProps): ReactElement {
  const { input, meta, disable, t } = field;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const errorTitle = meta.error
    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-explicit-any
      t(`validation.errors.${meta.error}` as any)
    : '';

  const [value, setValue] = useState(`${input.value as string}`);
  const [invalid, setInvalid] = useState(false);

  return (
    <div title={errorTitle as string} className="bp4-fill bp4-vertical">
      <NumericInput
        placeholder={'0'}
        fill={true}
        value={value}
        disabled={disable}
        leftIcon="geolocation"
        intent={meta.error || invalid ? 'danger' : 'none'}
        onValueChange={(valueAsNumber: number, _valueAsString: string) => {
          setValue(_valueAsString);

          if (!Number.isNaN(valueAsNumber)) {
            input.onChange(valueAsNumber);
            setInvalid(false);
          } else {
            setInvalid(true);
          }
        }}
        onBlur={(event) => {
          const val = parseFloat(event.target.value);
          if (!Number.isNaN(val)) {
            input.onChange(val);
          } else {
            input.onChange('NaN');
          }
        }}
        onFocus={input.onFocus}
      />
    </div>
  );
}

export default withTranslation()(NumberField);
