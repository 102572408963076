import { StacItem } from 'datacosmos/types/stac-types';
import { createContext, useCallback, useContext, useState } from 'react';
import { toaster } from 'toaster';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { createOrder } from '_api/orders/service';
import type { PaymentMethod } from '_api/orders/types';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type CartContextType = ReturnType<typeof useCartProvider>;

export const CartContext = createContext(null as unknown as CartContextType);

export const useCart = () => useContext(CartContext);

const useCartProvider = () => {
  const [cartItems, setCartItems] = useState<StacItem[]>([]);
  const [externalPaymentId, setExternalPaymentId] = useState<string>('');
  const { sendInfo } = useAnalytics();

  const addToCart = useCallback(
    (image: StacItem) => {
      /* Since items added to cart directly from their individual pages *
        they are being passed as IStacItem, without methods needed * for
        fetching title and such. This just ensures everything * is a StacItem
        class before calling appropriate methods */
      if (cartItems.some((i) => i.id === image.id)) {
        setCartItems((prev) =>
          prev.filter((p) => p.id !== image.id).map((p) => new StacItem(p))
        );

        toaster.show({
          message: clientTranslate('datacosmos.cart.imageRemoved', {
            id: image.id,
            title: image.title(),
          }),
          intent: 'none',
          icon: 'info-sign',
        });
        sendInfo({
          type: `Image removed from cart: ${image.id} - ${image.title()}`,
          action: 'Add',
          item: 'STAC item',
          module: 'DataCosmos',
          additionalParams: {
            image,
          },
        });
      } else {
        setCartItems((prev) => [...prev, image].map((p) => new StacItem(p)));
        sendInfo({
          type: `Image added to cart: ${image.id} - ${image.title()} `,
          action: 'Add',
          item: 'STAC item',
          module: 'DataCosmos',
          additionalParams: {
            image,
          },
        });
        toaster.show({
          message: clientTranslate('datacosmos.cart.imageAdded', {
            id: image.id,
            title: image.title(),
          }),
          intent: 'none',
          icon: 'info-sign',
        });
      }
    },
    [cartItems, sendInfo]
  );

  const createOrderWithItemsInCart = useCallback(
    async (paymentMethod: PaymentMethod, currentOrganisation: number) => {
      if (!cartItems || cartItems.length === 0) {
        throw new Error(
          clientTranslate('datacosmos.cart.errors.emptyCartPurchase')
        );
      }

      const { data: createdOrder } = await createOrder({
        body: {
          type: 'IMAGE',
          data: {
            order_line_items: cartItems.map((img) => ({
              collection: img.collection ?? '',
              item: img.id ?? '',
              level: img.properties['processing:level'] ?? '',
            })),
            payment_method: paymentMethod,
          },
          external_payment_id: externalPaymentId,
          organisation: currentOrganisation,
        },
      });

      if (typeof createdOrder === 'undefined') {
        throw new Error(
          clientTranslate('datacosmos.cart.errors.responseError')
        );
      }

      return createdOrder;
    },
    [cartItems, externalPaymentId]
  );

  const clearCart = useCallback(() => setCartItems([]), []);

  const isInCart = useCallback(
    (image: StacItem) => {
      return cartItems.some(
        (i) => i.id === image.id && i.collection === image.collection
      );
    },
    [cartItems]
  );

  return {
    addToCart,
    createOrderWithItemsInCart,
    clearCart,
    cartItems,
    isInCart,
    setExternalPaymentId,
    externalPaymentId,
  };
};

type Props = {
  children: React.ReactNode;
};
const CartProvider = ({ children }: Props) => {
  const cart = useCartProvider();
  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};

export default CartProvider;
