import { useRef } from 'react';
import { useButton } from '@react-aria/button';
import classNames from 'classnames';
import type { AriaButtonProps } from '@react-types/button';
import { Icon } from 'opencosmos-ui';
import type { IconName, IconProps } from 'opencosmos-ui/src/icons/Icon';

export type ButtonProps = Omit<AriaButtonProps, 'isDisabled'> &
  Partial<IconProps> & {
    text?: string | JSX.Element;
    iconPlacement?: 'right' | 'left';
    disabled?: boolean;
    'data-testid'?: string;
    className?: string;
    textHoverClassName?: {};
    iconHoverClassName?: {};
    fill?: boolean;
    ref?: React.RefObject<HTMLButtonElement>;
    noBackground?: boolean;
    icon?: IconName;
  };

export const Button = (props: ButtonProps) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    { ...props, isDisabled: props.disabled },
    props.ref ? props.ref : btnRef
  );
  return (
    <button
      {...buttonProps}
      className={classNames(props.className, 'max-h-8 group text-sm', {
        'color-item p-1': !props.noBackground,
        'w-full': props.fill,
        'hover:bg-item-hover dark:hover:bg-item-dark-hover': !props.disabled,
      })}
      ref={props.ref ? props.ref : btnRef}
      data-testid={props['data-testid']}
    >
      <div className="flex items-center gap-1">
        {props.icon && (
          <Icon
            icon={props.icon}
            size={props.size}
            className={classNames(
              {
                'stroke-item-contrast-inactive dark:stroke-item-dark-contrast-inactive':
                  props.disabled,
                'order-1': props.iconPlacement === 'left',
                'order-2': props.iconPlacement === 'right',
              },
              props.iconHoverClassName
            )}
          />
        )}
        {props.text && (
          <span
            className={classNames(
              'whitespace-nowrap block px-1 p-0 m-0 text-center w-full',
              {
                'text-item-contrast-inactive dark:text-item-dark-contrast-inactive':
                  props.disabled,
                'order-2': props.iconPlacement === 'left',
                'order-1': props.iconPlacement === 'right',
              },
              props.textHoverClassName
            )}
          >
            {props.text}
          </span>
        )}
      </div>
    </button>
  );
};

export default Button;
