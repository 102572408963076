import type {
  IConstraints,
  IConstraintsActionTypes,
} from '../../../../../constants/constraintsTable/actionTypes';
import {
  CONSTRAINTS_REMOVE,
  CONSTRAINTS_UPDATE,
} from '../../../../../constants/constraintsTable/actionTypes';

export function updateConstraints(
  constraints: IConstraints
): IConstraintsActionTypes {
  return {
    type: CONSTRAINTS_UPDATE,
    constraints,
  };
}

export function removeConstraints(): IConstraintsActionTypes {
  return {
    type: CONSTRAINTS_REMOVE,
  };
}
