import type {
  ISatelliteActionTypes,
  ISatelliteData,
  ISTState,
} from '../../../../constants/satelliteOrbits/actionTypes';
import {
  ST_ADD,
  ST_REMOVE,
  ST_REMOVE_ALL,
  ST_UPDATE,
} from '../../../../constants/satelliteOrbits/actionTypes';

const initialState: ISTState = {
  list: [],
};

export default function satelliteOrbitsReducer(
  state: ISTState = initialState,
  action: ISatelliteActionTypes
): ISTState {
  switch (action.type) {
    case ST_ADD: {
      return {
        list: [...state.list, action.satellite],
      };
    }
    case ST_UPDATE: {
      return {
        list: state.list.map((satellite): ISatelliteData => {
          if (satellite.id === action.satellite.id) {
            return { ...action.satellite };
          }
          return satellite;
        }),
      };
    }
    case ST_REMOVE: {
      return {
        list: state.list.filter(
          (satellite): boolean => satellite.id !== action.id
        ),
      };
    }
    case ST_REMOVE_ALL: {
      return initialState;
    }
    //TODO add other reducers

    // case PI_UPLOAD: {
    //   return action.pointsOfInterest;
    // }
    default: {
      return state;
    }
  }
}
