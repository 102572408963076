import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { setUserInformation } from './action';
import type { AppState } from '../../pages/shared/state/reducers/rootReducer';
import type { IUserInformation } from '../../constants/user/actionTypes';
import { ENABLE_MIXPANEL } from 'env';

export const setUserThunks =
  (
    userInformation: IUserInformation
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(setUserInformation(userInformation));

    if (!ENABLE_MIXPANEL) {
      return;
    }

    if (!userInformation.sub) {
      return;
    }

    mixpanel.identify(userInformation.sub);

    mixpanel.people.set({
      $first_name: userInformation.given_name,
      $last_name: userInformation.family_name,
      nickname: userInformation.nickname,
      name: userInformation.name,
      $avatar: userInformation.picture,
      locale: userInformation.locale,
      updated_at$: userInformation.updated_at,
      $email: userInformation.email,
      email_verified: userInformation.email_verified,
      sub: userInformation.sub,
      token: userInformation.token,
    });
    /* eslint-enable */

    mixpanel.track('Login', { client: 'frontend' });
  };
