import { useState, useCallback } from 'react';

type LocalStorageType = string | number | undefined | object | boolean;

const useLocalStorage = <Type = LocalStorageType>(
  key: string,
  initialValue: Type
) => {
  const [storedValue, setStoredValue] = useState<Type>(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item !== null) {
        return JSON.parse(item) as Type;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return initialValue;
  });

  const setValue = useCallback(
    (param: Type) => {
      setStoredValue((prev) => {
        const actualValue =
          typeof param === 'function' ? (param(prev) as Type) : param;

        try {
          window.localStorage.setItem(key, JSON.stringify(actualValue));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          return prev;
        }
        return actualValue;
      });
    },
    [key]
  );

  return [storedValue, setValue] as [Type, typeof setStoredValue];
};

export default useLocalStorage;
