import type IGeometryObject from '../../constants/IGeometryObject';

interface IObjects {
  list: IGeometryObject[];
}

export const filterByAstrum = <T extends IObjects>(
  input: IObjects,
  planet: string
): T => {
  const list: IGeometryObject[] = input.list.filter(
    (i: IGeometryObject) => i.astrum === planet
  );
  return { list } as T;
};
