import { useState, useEffect, useCallback } from 'react';
import { TABLET_WIDTH, PHONE_WIDTH } from 'constants/screen';

export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => {
    if (!isClient) {
      return { width: 0, height: 0, isTablet: false, isPhone: false };
    }

    return {
      width: window.innerWidth,
      height: window.innerHeight,
      isTablet: window.innerWidth <= TABLET_WIDTH,
      isPhone: window.innerWidth <= PHONE_WIDTH,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return undefined;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]);

  return windowSize;
};
