import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import React from 'react';
import BottomColorBar from './BottomColorBar';

interface IProps {
  isMinimapExpanded: boolean;
}

const BottomColorBarsList = ({ isMinimapExpanded }: IProps) => {
  const { layers } = useMapLayers();

  const stacLayers = layers.filter(
    (l) => l instanceof SingleBandSTACLayer
  ) as SingleBandSTACLayer[];
  const colormapLayers = stacLayers.filter(
    (l) => l.getAsset()['opencosmos:colourmap'] !== undefined
  );

  return colormapLayers.length > 0 ? (
    <div
      className="flex flex-col mb-6 gap-8"
      style={{ width: isMinimapExpanded ? `calc(100% - 267px)` : '100%' }}
      data-testid="colorbar-list"
    >
      {colormapLayers.map((l) => (
        <BottomColorBar key={l.id} layer={l} />
      ))}
    </div>
  ) : null;
};

export default BottomColorBarsList;
