import type { Intent } from '@blueprintjs/core';
import { ProgressBar } from '@blueprintjs/core';
import classNames from 'classnames';

export type FtpTransferFile = {
  destination_path: string;
  file_name: string;
  file_size: number;
  metadata: {
    [key: string]: unknown;
  };
  source_path: string;
  stage: string;
  stage_progress_percentage: number;
  stage_status: string;
};

export type FtpTransferUpdateResponse = {
  files: FtpTransferFile[];
  metadata: null | {
    [key: string]: unknown;
  };
  stages: string[];
  status: string;
};

type Props = {
  data: FtpTransferUpdateResponse;
};

const FTPTransferUpdateInfo = ({ data }: Props) => {
  if (!data.files || data.files.length === 0) {
    return null;
  }

  const getProgressBarStyleBasedOnStatus = (
    status: string
  ): { intent: Intent; animated: boolean; stripes: boolean } => {
    switch (status) {
      case 'succeeded':
        return {
          intent: 'success',
          animated: false,
          stripes: false,
        };
      case 'failed':
        return {
          intent: 'danger',
          animated: false,
          stripes: false,
        };
      case 'pending':
        return {
          intent: 'none',
          animated: true,
          stripes: true,
        };
      case 'ongoing':
        return {
          intent: 'success',
          animated: true,
          stripes: true,
        };
      case 'cancelled':
        return {
          intent: 'danger',
          animated: false,
          stripes: false,
        };
      case 'partial_failure':
        return {
          intent: 'danger',
          animated: false,
          stripes: false,
        };
      default:
        return {
          intent: 'none',
          animated: false,
          stripes: false,
        };
    }
  };

  return (
    <div className="flex flex-col gap-1 mb-2 bg-neutral-200 p-2">
      {data.files.map((f) => (
        <div key={f.file_name} className="flex flex-col gap-2">
          <div className="flex flex-col">
            <span className="w-fit self-center">
              {f.stage_progress_percentage.toFixed(2)}%
            </span>
            <div className="flex items-center">
              <ProgressBar
                value={f.stage_progress_percentage / 100}
                className={classNames('mr-1 ml-1', {
                  'bg-warning-500': f.stage_status === 'failed',
                  'bg-success-500': f.stage_status === 'succeeded',
                })}
                intent={getProgressBarStyleBasedOnStatus(f.stage_status).intent}
                animate={
                  getProgressBarStyleBasedOnStatus(f.stage_status).animated
                }
                stripes={
                  getProgressBarStyleBasedOnStatus(f.stage_status).stripes
                }
              />

              <span className="whitespace-nowrap">
                {data.stages.findIndex((s) => s === f.stage) + 1} /{' '}
                {data.stages.length}
              </span>
            </div>
          </div>

          <span className="break-words">{f.file_name}</span>

          <span className="break-words">Destination: {f.destination_path}</span>
        </div>
      ))}
    </div>
  );
};

export default FTPTransferUpdateInfo;
