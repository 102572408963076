import { ContextMenu, Menu } from '@blueprintjs/core';
import { type Layer } from 'datacosmos/entities/layer';
import type { Polygon } from 'geojson';
import type { LeafletMouseEvent } from 'leaflet';
import CopyCoordinates from './CopyCoordinates';
import DrawCircle from './DrawCircle';
import { DrawLine } from './DrawLine';
import { DrawPolygon } from './DrawPolygon';
import SelectLayer from './SelectLayer';
import { DrawRectangle } from './DrawRectangle';
import { DrawPolyline } from './DrawPolyline';
import UploadRegionMenu from './UploadRegionMenu';
import type { IFiltersProviderContext } from 'datacosmos/stores/FiltersProvider';
import type { IMapLayers } from 'datacosmos/stores/MapLayersProvider';
import DownloadLayerMenu from './DownloadLayer';

interface IMenuFunctions {
  addLayer: (...newLayers: Layer[]) => void;
  measure: (addLineToMap: boolean) => Promise<number>;
  drawPolygon: (options?: { color: string; weight: number }) => Promise<{
    polygon: GeoJSON.Feature<Polygon>;
    polygonMetadata: unknown;
  }>;
  drawCircle: (options?: { color: string; weight: number }) => Promise<{
    circleMetadata: unknown;
    center: GeoJSON.Point;
    radius: number;
  }>;
  drawRectangle: (options?: { color: string; weight: number }) => Promise<{
    rectangle: GeoJSON.Feature<Polygon>;
    rectangleMetadata: unknown;
  }>;
  drawPolyline: (options?: { color: string; weight: number }) => Promise<{
    polyline: GeoJSON.Feature<GeoJSON.LineString>;
    polylineMetadata: unknown;
  }>;
  getFilterContext: () => IFiltersProviderContext;
  getLayersContext: () => IMapLayers;
}

interface ILayerMenuFunctions {
  selectLayer: (l: Layer, addToCurrentSection: boolean) => void;
}

interface IProps {
  e: LeafletMouseEvent;
  menuFunctions: IMenuFunctions | ILayerMenuFunctions;
  layer: Layer | undefined;
}

const isLayerMenuFunctions = (
  functions: ILayerMenuFunctions | IMenuFunctions
): functions is ILayerMenuFunctions => {
  return Boolean((functions as ILayerMenuFunctions).selectLayer);
};

const RightClickMenu = ({ e, menuFunctions, layer }: IProps) => {
  if (isLayerMenuFunctions(menuFunctions)) {
    return (
      <Menu className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface">
        <CopyCoordinates e={e} />
        <SelectLayer layer={layer} selectLayer={menuFunctions.selectLayer} />
        <DownloadLayerMenu
          layer={layer}
          className="px-2 py-1 flex items-center gap-2"
          defaultValue={'geojson'}
        />
      </Menu>
    );
  }

  return (
    <Menu className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface">
      <CopyCoordinates e={e} />
      <DrawLine measure={menuFunctions.measure} />
      <DrawPolygon
        addLayer={menuFunctions.addLayer}
        drawPolygon={menuFunctions.drawPolygon}
      />
      <DrawCircle
        addLayer={menuFunctions.addLayer}
        drawCircle={menuFunctions.drawCircle}
      />
      <DrawRectangle
        addLayer={menuFunctions.addLayer}
        drawRectangle={menuFunctions.drawRectangle}
      />
      <DrawPolyline
        addLayer={menuFunctions.addLayer}
        drawPolyline={menuFunctions.drawPolyline}
      />
      <UploadRegionMenu
        filters={menuFunctions.getFilterContext()}
        layers={menuFunctions.getLayersContext()}
      />
    </Menu>
  );
};

const showMenu = (
  e: LeafletMouseEvent,
  menuFunctions: IMenuFunctions | ILayerMenuFunctions,
  layer: Layer | undefined
) => {
  ContextMenu.show(
    <RightClickMenu e={e} menuFunctions={menuFunctions} layer={layer} />,
    {
      left: e.originalEvent.clientX,
      top: e.originalEvent.clientY,
    }
  );
};

export default showMenu;
