import moment from 'moment';
import type { Ephemeris, JSONEphemerisData } from './types';

export const fromJsonTLE = (mission: number) => {
  return (data: JSONEphemerisData | undefined): Ephemeris => {
    if (!data) {
      return {
        id: 0,
        type: 'TLE',
        mission,
        creationTime: '',
        line1: '',
        line2: '',
      };
    }

    const { epoch, line1, line2 } = data;

    return {
      id: moment(epoch).unix(),
      type: 'TLE',
      mission,
      creationTime: epoch,
      line1,
      line2,
    };
  };
};
