import React, { useEffect, useRef, useState } from 'react';
import { Spinner, NonIdealState, Alert, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useAlerts } from 'services/api/telemetry/AlertsProvider';
import LibraryAlertsHeader from './LibraryAlertsHeader';
import LibraryAlertsTable from './LibraryAlertsTable';
import InitialAlertGroupCard from './InitialAlertGroupCard';
import CreateAlert from './CreateAlert';

import generalS from '../LibraryTelemetry/index.module.scss';
import s from './index.module.scss';
import type { AlertRule } from '_api/alerts/types';

const LibraryAlerts = () => {
  const {
    alerts,
    isFetching,
    alertGroupDoesntExist,
    enableAlerts,
    requestDeleteAlertRule,
    alertGroup,
  } = useAlerts();

  const ref = useRef(null);
  const [currentAlertList, setCurrentAlertList] = useState<
    AlertRule[] | undefined
  >([]);
  const [filteredAlertList, setFilteredAlertList] = useState<
    AlertRule[] | undefined
  >();
  const [deleteDialogOpen, setDeleteDialogueOpen] = useState<boolean>(false);
  const [alertRuleToDelete, setAlertRuleToDelete] = useState<
    string | undefined
  >(undefined);

  const showAlertForRuleDeletion = (id: string) => {
    setDeleteDialogueOpen(true);
    setAlertRuleToDelete(id);
  };

  const alertList = filteredAlertList ?? currentAlertList;

  const renderAlertList = alertList?.length ? (
    <LibraryAlertsTable
      list={alertList}
      showAlertForRuleDeletion={showAlertForRuleDeletion}
    />
  ) : (
    <NonIdealState icon={IconNames.NOTIFICATIONS} title="No alert rules" />
  );

  const renderAlertsOrLoading = isFetching ? (
    <div className={generalS.tableSpinner}>
      <Spinner />
    </div>
  ) : (
    renderAlertList
  );

  useEffect(() => {
    if (alerts) {
      setCurrentAlertList(alerts);
    }
  }, [alerts]);

  return (
    <div ref={ref} className={s.libraryAlerts}>
      <Alert
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogueOpen(false)}
        onConfirm={() =>
          requestDeleteAlertRule(alertGroup?.id, alertRuleToDelete)
        }
      >
        Are you sure you want to delete this alert rule?
      </Alert>
      <CreateAlert />
      <LibraryAlertsHeader
        title="Alert rules"
        alerts={alerts}
        handleFilteredTelemetries={(values) => setFilteredAlertList(values)}
      />
      <div className={s.alertsBody}>
        {!alertGroupDoesntExist ? (
          renderAlertsOrLoading
        ) : (
          <>
            <NonIdealState
              icon={IconNames.NOTIFICATIONS}
              title="No alert rules"
            />
            <div className={s.libraryCardContainer}>
              <InitialAlertGroupCard
                handleEnable={enableAlerts}
                saving={isFetching}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LibraryAlerts;
