import type { IAstrum } from 'constants/austrum/interfaces';
import type { IAstrumActionTypes } from 'constants/austrum/actionTypes';
import { ASTRUM_UPDATE } from 'constants/austrum/constant';

export function updateAstrum(key: string, astrum: IAstrum): IAstrumActionTypes {
  return {
    type: ASTRUM_UPDATE,
    astrum,
    key,
  };
}
